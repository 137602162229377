import {
  Typography,
  Grid,
  Button,
  FormControlLabel,
  Switch,
  Chip,
  IconButton,
} from '@mui/material';

import React, { useState } from 'react';
import MyCountryList from '../../Common/Form/MyCountryList';
import useInput from '../../Common/Form/use-input';
import CancelIcon from '@mui/icons-material/Cancel';
import { Countries } from '../../../Util/Util';
import MyTooltip from '../../Common/Tooltip/MyTooltip';
import Agent from '../../../api/agent';

// const isNotEmptyTrim = (value) => value && value.trim() !== '';
const isNotEmpty = (value) => value && value !== '';
// const isNotEmptyNum = (value) => value && value > 0;

const AgentTerritory = ({ agent }) => {
  const [countries, setCountries] = useState(
    agent?.agentCountries?.map((country) => country.countryId) || [] // Extracting countryId from agentCountries
  );
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  //console.log(agent.agentCountries);

  const onChangeHandler = (name, value) => {
    agent = {
      ...agent,
      ...{
        [name]: value,
      },
    };
  };

  //console.log(agent);

  const { obj: regionalAgreement } = useInput(
    agent.regionalAgreement,
    isNotEmpty,
    null,
    onChangeHandler
  );

  const onCountrySelection = (name, value) => {
    setSelectedCountry(value);
  };

  const { obj: country } = useInput(
    selectedCountry,
    () => true,
    onCountrySelection
  );

  // useEffect(() => {
  //   saveCountries();
  // },[]);

  const saveCountries = () => {
    Agent.Agents.saveAgentTerritory({
      agentId: agent?.id,
      countries: countries, // Passing the selected country IDs
      regionalAgreement: regionalAgreement.value,
    })
      .then((res) => {
        console.log('Updated', res);
        setResponseMessage('Territory saved successfully.');
        setErrorMessage('');
      })
      .catch((error) => {
        console.log(error);
        setErrorMessage('Failed to save territory.', error);
        setResponseMessage('');
      });
  };

  const assignCountry = () => {
    if (selectedCountry == null) return;
    setCountries([...countries, selectedCountry]); // Adding selected country ID to the list
    setSelectedCountry(null);
  };

  const handleDelete = (countryId) => {
    setCountries(countries.filter((c) => c !== countryId));
  };

  return (
    <Grid container item spacing={1} direction="column">
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={!regionalAgreement.value}
              onChange={(e) =>
                regionalAgreement.valueChangeHandler(
                  !e.target.checked,
                  'regionalAgreement'
                )
              }
              name="regionalAgreement"
            />
          }
          label={regionalAgreement.value ? 'Not Global' : 'Global'}
        />
      </Grid>
      {regionalAgreement?.value && countries?.length > 0 ? (
        <Grid item style={{ marginBottom: 30 }}>
          {countries.filter((item)=>item.id!=0).map((countryId, index) => (
            <Chip
              key={index}
              color="primary"
              label={Countries.find(({ id }) => id === countryId)?.name}
              onDelete={() => handleDelete(countryId)}
              deleteIcon={
                <MyTooltip
                  title={`Click to remove ${
                    Countries.find(({ id }) => id === countryId)?.name
                  }`}
                >
                  <IconButton color="primary" size="large">
                    <CancelIcon />
                  </IconButton>
                </MyTooltip>
              }
              variant="outlined"
            />
          ))}
        </Grid>
      ) : null}

      {regionalAgreement?.value && (
        <Grid item container spacing={2}>
          <Grid item xs={2}>
            <Typography>Assign Country</Typography>
          </Grid>
          <Grid item xs={5}>
            <MyCountryList
              name="country"
              label="Country"
              value={country.value}
              handleChange={country.valueChangeHandler}
              handleBlur={country.valueInputBlurHandler}
              isInvalid={false}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography>
              <Button
                className="bg-sky-400 hover:bg-sky-500"
                variant="contained"
                onClick={assignCountry}
              >
                Add to List
              </Button>
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid item className="flex justify-center items-center">
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={saveCountries}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded md:py-1 md:px-3" // Responsive padding for smaller screens
          style={{ maxWidth: '250px' }}
        >
          Save Territory
        </Button>
      </Grid>

      {responseMessage && (
        <Grid item>
          <Typography variant="body1" style={{ color: 'green' }}>
            {responseMessage}
          </Typography>
        </Grid>
      )}

      {errorMessage && (
        <Grid item>
          <Typography variant="body1" style={{ color: 'red' }}>
            {errorMessage}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AgentTerritory;
