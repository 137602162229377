import React from 'react'
import Step from '@mui/material/Step'
import {styled} from '@mui/material/styles'
import Stepper from '@mui/material/Stepper'
import StepButton from '@mui/material/StepButton'
import Typography from '@mui/material/Typography'

const PREFIX = 'Steppers'

const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
  completed: `${PREFIX}-completed`,
  instructions: `${PREFIX}-instructions`,
}

const Root = styled('div')(({theme}) => ({
  [`&.${classes.root}`]: {
    width: '95%',
    paddingTop: '15px',
  },

  [`& .${classes.button}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.backButton}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.completed}`]: {
    display: 'inline-block',
  },

  [`& .${classes.instructions}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

function getSteps() {
  return [
    'Create Profile',
    'Complete General Info',
    'Add Education',
    'Add Language',
    'Upload Documents',
    'Submit Profile',
  ]
}

export default function Steppers({
  activeStep,
  setActiveStep,
  completed,
  setTab,
}) {
  const steps = getSteps()

  // const totalSteps = () => {
  //   return getSteps().length
  // }

  const isStepOptional = (step) => {
    // return step === 1;
    return false
  }

  function isStepComplete(step) {
    return completed.has(step)
  }

  return (
    <Root className={classes.root}>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        style={{paddingTop: 5, paddingBottom: 45}}
      >
        {steps.map((label, index) => {
          const stepProps = {}
          const buttonProps = {}
          if (isStepOptional(index)) {
            buttonProps.optional = (
              <Typography variant='caption'>Optional</Typography>
            )
          }
          return (
            <Step key={label} {...stepProps}>
              <StepButton
                onClick={() => {
                  setActiveStep(index)
                  if (index == 0) return
                  if (index == steps.length - 1) setTab(0)
                  else setTab(index - 1)
                }}
                completed={isStepComplete(index)}
                {...buttonProps}
              >
                {label}
              </StepButton>
            </Step>
          )
        })}
      </Stepper>
    </Root>
  )
}
