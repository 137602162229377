import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Agent from '../../../api/agent';
import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import MyTooltip from '../../Common/Tooltip/MyTooltip';
import useWindowSize from '../../Students/common/useWindowSize';
import { AttendedStudentOverview } from './AttendedStudentOverview';

const EventAttendanceForm = () => {
  const [notAvailable, setNotAvailable] = useState(true);
  const [id, setId] = useState('');
  const [studentName, setStudentName] = useState('');
  const [eventName, setEventName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [studentData, setStudentData] = useState([]);
  const [leadId, setLeadId] = useState('');
  const [allStudentData, setAllStudentData] = useState([]);
  const [studentFound, setStudentFound] = useState(false);
  const [allStudentFound, setAllStudentFound] = useState(false);
  const [isEventAddendence, setIsEventAddendence] = useState(false);
  const [checkOnChange, setCheckOnChange] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  let [selectedLeadStudId, setSelectedLeadStudId] = useState();
  let [successResponse, setSuccessResponse] = useState(false);
  const [events, setEventList] = useState([]);
  const [eventId, setEventFilter] = useState(0);
  const [ready, setReady] = useState(false);

  const handleNotAvailibility = () => {
    setNotAvailable(false);
    setStudentName('');
    setEmail('');
    setPhoneNumber('');
    setId('');
    setStudentFound(false);
    setStudentData([]);
    setAllStudentFound(false);
  };
  const handleAvailibility = () => {
    debugger;
    setNotAvailable(true);
    setStudentName('');
    setEmail('');
    setPhoneNumber('');
    setId('');
    setStudentData([]);
    setStudentFound(false);
    setAllStudentFound(false);
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('id', filter ? id.trim() : '');
    params.append('name', filter ? studentName.trim() : '');
    params.append('email', filter ? email.trim() : '');
    params.append('phoneNumber', filter ? phoneNumber.trim() : '');
    params.append('eventName', filter ? eventName.trim() : '');
    return params;
  };
  const getData = (filter = false) => {
    Agent.Events.eventAttendancelist(getParams(filter))
      .then((data) => {
        console.log('res_data', data);
        if (
          id==null &&
          studentName==null &&
          email==null &&
          phoneNumber==null
        ) {
          setStudentFound(false);
          setStudentData([]);
        } else {
          setStudentFound(true);
          setStudentData(data);
        }
        setCheckOnChange(false);
        getRows(data);
        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetAllData = () => {
    getAllAttendanceList(true);
    setStudentFound(false);
    setStudentName('');
    setEmail('');
    setPhoneNumber('');
    setId('');
  };
  const getAllAttendanceList = (filter = false) => {
    Agent.Events.eventAttendancelist(getParams(filter))
      .then((data) => {
        let tempData = data.filter((item) => {
          if (item.isEventAddendence) {
            return item.isEventAddendence;
          }
        });
        setAllStudentFound(true);
        setAllStudentData(tempData);
        // setCheckOnChange(false);
        getRowsAllAttend(tempData);
        return tempData;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (!isNaN(selectedLeadStudId)) {
      Agent.Events.updateAttendance(
        selectedLeadStudId,
        isEventAddendence,
        eventId
      )
        .then((data) => {
          console.log('update_data', data);
          if (isEventAddendence) {
            setSuccessMessage('Attendance Recorded');
          } else {
            setSuccessMessage('Attendance Unchecked');
          }

          setSuccessResponse(true);
          setSuccessOpen(true);
        })
        .catch((error) => {
          console.log(error);
          setErrorMessage(error);
        });
    }
  }, [selectedLeadStudId, isEventAddendence]);

  const handleRowSelection = (rowId, isAttend) => {
    if (!eventId) {
      setErrorMessage('Event Field is Required!!');
    } else {
      setCheckOnChange(true);
      const selectedIndex = selectedRows.indexOf(rowId);
      let newSelectedRows = [];
      let selectedStudentId = 0;
      if (selectedIndex === -1) {
        newSelectedRows = [...selectedRows, rowId];
        selectedStudentId = studentData.find(
          (student) => student.id === rowId
        ).id;
        setSelectedLeadStudId(selectedStudentId);
      } else {
        newSelectedRows = selectedRows.filter((id) => id !== rowId);
      }
      if (isAttend && selectedStudentId == rowId) {
        setIsEventAddendence(false);
      }
      if (!isAttend && selectedStudentId == rowId) {
        setIsEventAddendence(true);
      }
      if (successResponse) {
        isAttend = false;
      }
    }
  };

  const renderCheckbox = (params) => (
    <Checkbox
      checked={
        checkOnChange && selectedLeadStudId == params.row.id
          ? isEventAddendence
            ? (params.row.isEventAddendence = true)
            : (params.row.isEventAddendence = false)
          : params.row.isEventAddendence
      }
      onChange={() =>
        handleRowSelection(params.row.id, params.row.isEventAddendence)
      }
    />
  );
  const renderCheckboxAllAttend = (params) => (
    <Checkbox checked={params.row.isEventAddendence} />
  );
  const getRows = (rows) => {
    let studentRows = [];
    rows.map((student) => {
      studentRows.push({
        id: student.id,
        studentName: student.firstName + ' ' + student.lastName,
        isEventAddendence: student.isEventAddendence,
        phoneNumber: student.phoneNumber,
        email: student.email,
        eventName: student.eventName,
      });
    });
    setStudentData(studentRows);
  };

  function CustomToolbar(show, isInternal) {
    return (
      <GridToolbarContainer>
        {isInternal && <GridToolbarColumnsButton />}
        {isInternal && <GridToolbarFilterButton />}
        <GridToolbarDensitySelector />
        {show && <GridToolbarExport />}
      </GridToolbarContainer>
    );
  }
  const getRowsAllAttend = (rows) => {
    let studentRows = [];
    rows &&
      rows.map((student) => {
        studentRows.push({
          id: student.id,
          studentName: student.firstName + ' ' + student.lastName,
          email: student.email,
          phoneNumber: student.phoneNumber,
          eventName: student.eventName,
          isEventAddendence: student.isEventAddendence,
        });
      });
    setAllStudentData(studentRows);
  };
  const handleGetData = () => {
    getData(true);
  };
  const handleEventFilter = (event) => {
    setEventFilter(event.target.value);
  };
  useEffect(() => {
    Agent.Events.getEventList().then((res) => {
      res.sort(function (a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (a.id < b.id) {
          return 1;
        }
        return 0;
      });
      setEventList(res);
    });
  }, []);
  console.log('event list', events);
  let attendColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'studentName',
      headerName: 'Student Name',
      width: 220,
      renderCell: (params) => (
        <MyTooltip title={params.row.studentName} color="primary">
          <Typography className="clickable">
            {params.row.studentName}
          </Typography>
        </MyTooltip>
      ),
    },

    {
      field: 'eventName',
      headerName: 'Event Name',
      width: 280,
      headerAlign: 'left',
    },
    {
      field: 'isEventAddendence',
      headerName: 'Attendance',
      width: 100,
      renderCell: renderCheckbox,
      headerAlign: 'left',
    },
  ];
  let attendColumnsMob = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'studentName',
      headerName: 'Student Name',
      width: 200,
      renderCell: (params) => (
        <MyTooltip title={params.row.studentName} color="primary">
          <Typography className="clickable">
            {params.row.studentName}
          </Typography>
        </MyTooltip>
      ),
    },
    {
      field: 'isEventAddendence',
      headerName: 'Attendance',
      width: 100,
      renderCell: renderCheckbox,
      headerAlign: 'left',
    },
    {
      field: 'eventName',
      headerName: 'Event Name',
      width: 200,
      headerAlign: 'left',
    },

  ];
  let allAttendColumns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'studentName',
      headerName: 'Student Name',
      width: 220,
    },
   
    {
      field: 'eventName',
      headerName: 'Event Name',
      width: 280,
      headerAlign: 'left',
    },
    {
      field: 'isEventAddendence',
      headerName: 'Attendance',
      width: 100,
      renderCell: renderCheckboxAllAttend,
      headerAlign: 'left',
    },
  ];
  //console.log('student Lead', getData);
  const successDialogStyles = {
    backgroundColor: 'bg-green-400',
  };
  const errorDialogStyles = {
    backgroundColor: 'bg-red-400',
  };
  const handleOnCellClick = (params) => {
    setLeadId(params.row.id);
    setReady(true);
  };
  const size = useWindowSize();

  return (
    <>
      {size.width > 844 ? (
        notAvailable ? (
          <>
            <Dialog
              open={successOpen}
              onClose={() => setSuccessOpen(false)}
              aria-labelledby="success-dialog-title"
              PaperProps={{
                className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
              }}
            >
              {/* <DialogTitle id="success-dialog-title">Success</DialogTitle> */}
              <DialogContent>
                <DialogContentText>{successMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setSuccessOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={!!errorMessage}
              onClose={() => setErrorMessage('')}
              aria-labelledby="error-dialog-title"
              PaperProps={{
                className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
              }}
            >
              <DialogTitle id="error-dialog-title">Error</DialogTitle>
              <DialogContent>
                <DialogContentText>{errorMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setErrorMessage('')} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <div className="relative justify-center pt-4 text-center text-xl text-gray-900">
              Event Attendance Form{' '}
            </div>
            <div className="relative w-full flex justify-center items-center mt-6">
              <div className="relative flex min-w-[40%]">
                <div>
                  <FormControl fullWidth>
                    {/* <InputLabel>Event List</InputLabel> */}
                    <Select
                      required
                      size="small"
                      label="Event List"
                      value={eventId}
                      onChange={handleEventFilter}
                      className="bg-white w-64"
                      variant="outlined"
                    >
                      <option value={0} className="pl-4">
                        Event List
                      </option>
                      {events.map((event) => (
                        <MenuItem key={event.id} value={event.id}>
                          {event.eventName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <input
                  type="text"
                  value={id}
                  name="id"
                  onChange={(e) => setId(e.target.value)}
                  className="bg-white border border-gray-400 text-sm rounded-lg focus:ring-blue-500 block w-[120%] p-2.5 dark:placeholder-gray-900"
                  placeholder="Reference ID"
                  required
                />
                <button
                  type="submit"
                  className="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  onClick={handleGetData}
                >
                  <svg
                    className="w-8 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </button>
              </div>
              &nbsp;&nbsp;
              <button
                type="button"
                className="text-white bg-gradient-to-r from-cyan-500 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                onClick={handleNotAvailibility}
              >
                Not Available
              </button>
              <button
                type="button"
                className="text-white bg-gradient-to-r from-sky-800 via-sky-800 to-sky-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                onClick={handleGetAllData}
              >
                All Attended List
              </button>
            </div>
            {studentFound ? (
              <div className="h-96 w-full flex justify-center items-center mt-6">
                <div className="h-96 w-3/5 flex">
                  <DataGridPro
                    rows={studentData}
                    columns={attendColumns}
                    // loading={loading}
                    onCellClick={(params, event) => {
                      event.defaultMuiPrevented = true;
                      if (params.colDef.field === 'studentName') {
                        handleOnCellClick(params);
                      }
                      // if (params.colDef.field === 'studentName') {
                      //   window.open(
                      //     `/leads/${params.row.id}/LeadProfile`,
                      //     '_blank'
                      //   );
                      // }
                    }}
                    hideFooter
                    rowHeight={80}
                    autoPageSize
                  />
                </div>
              </div>
            ) : (
              allStudentFound && (
                <div className="h-96 w-full flex justify-center items-center mt-6">
                  <div className="h-96 w-3/5 inline-flex">
                    <DataGridPro
                      rows={allStudentData}
                      columns={allAttendColumns}
                      // loading={loading}
                      rowHeight={55}
                      autoPageSize
                      components={{
                        Toolbar: () => CustomToolbar(true, true),
                      }}
                    />
                  </div>
                </div>
              )
            )}
          </>
        ) : (
          <>
            <Dialog
              open={successOpen}
              onClose={() => setSuccessOpen(false)}
              aria-labelledby="success-dialog-title"
              PaperProps={{
                className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
              }}
            >
              <DialogTitle id="success-dialog-title">Success</DialogTitle>
              <DialogContent>
                <DialogContentText>{successMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setSuccessOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={!!errorMessage}
              onClose={() => setErrorMessage('')}
              aria-labelledby="error-dialog-title"
              PaperProps={{
                className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
              }}
            >
              <DialogTitle id="error-dialog-title">Error</DialogTitle>
              <DialogContent>
                <DialogContentText>{errorMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setErrorMessage('')} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <div className="relative justify-center pt-4 text-center text-xl text-gray-900">
              Event Attendance Form{' '}
            </div>
            <div className="relative w-full flex justify-center items-center mt-6">
              <div className="relative  flex w-[62%]">
                <div>
                  <FormControl fullWidth>
                    {/* <InputLabel>Event List</InputLabel> */}
                    <Select
                      required
                      size="small"
                      label="Event List"
                      value={eventId}
                      onChange={handleEventFilter}
                      className="bg-white w-64"
                      variant="outlined"
                    >
                      <option value={0} className="pl-4">
                        Event List
                      </option>
                      {events.map((event) => (
                        <MenuItem key={event.id} value={event.id}>
                          {event.eventName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <input
                  type="text"
                  value={studentName}
                  onChange={(e) => setStudentName(e.target.value)}
                  className="bg-white border border-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-400 dark:placeholder-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Student Name"
                />
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="bg-white border border-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:border-gray-400 dark:placeholder-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Email"
                />
                <input
                  type="text"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="bg-white border border-gray-400 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[120%] p-2.5 dark:border-gray-400 dark:placeholder-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Phone No"
                />

                <button
                  type="submit"
                  onClick={handleGetData}
                  className="absolute top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  <svg
                    className="w-8 h-4"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </button>
              </div>
              &nbsp;&nbsp;
              <button
                type="button"
                className="text-white bg-gradient-to-r from-cyan-500 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                onClick={handleAvailibility}
              >
                Available
              </button>
              <button
                type="button"
                className="text-white bg-gradient-to-r from-sky-800 via-sky-800 to-sky-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                onClick={handleGetAllData}
              >
                All Attended List
              </button>
            </div>
            {studentFound ? (
              <div className="h-96 w-full flex justify-center items-center mt-6">
                <div className="h-96 w-3/5 inline-flex">
                  <DataGridPro
                    rows={studentData}
                    columns={attendColumns}
                    // loading={loading}
                    onCellClick={(params, event) => {
                      event.defaultMuiPrevented = true;
                      if (params.colDef.field === 'studentName') {
                        handleOnCellClick(params);
                      }
                      // if (params.colDef.field === 'studentName') {
                      //   window.open(
                      //     `/leads/${params.row.id}/LeadProfile`,
                      //     '_blank'
                      //   );
                      // }
                    }}
                    hideFooter
                    rowHeight={80}
                    autoPageSize
                  />
                </div>
              </div>
            ) : (
              allStudentFound && (
                <div className="h-96 w-full flex justify-center items-center mt-6">
                  <div className="h-96 w-3/5 inline-flex">
                    <DataGridPro
                      rows={allStudentData}
                      columns={allAttendColumns}
                      // loading={loading}
                      rowHeight={55}
                      autoPageSize
                      components={{
                        Toolbar: () => CustomToolbar(true, true),
                      }}
                    />
                  </div>
                </div>
              )
            )}
          </>
        )
      ) : notAvailable ? (
        <>
          <Dialog
            open={successOpen}
            onClose={() => setSuccessOpen(false)}
            aria-labelledby="success-dialog-title"
            PaperProps={{
              className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
            }}
          >
            <DialogTitle id="success-dialog-title">Success</DialogTitle>
            <DialogContent>
              <DialogContentText>{successMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSuccessOpen(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={!!errorMessage}
            onClose={() => setErrorMessage('')}
            aria-labelledby="error-dialog-title"
            PaperProps={{
              className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
            }}
          >
            <DialogTitle id="error-dialog-title">Error</DialogTitle>
            <DialogContent>
              <DialogContentText>{errorMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setErrorMessage('')} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <div className="relative justify-center mt-6 text-center text-xl text-gray-900">
            Event Attendance Form{' '}
          </div>
          <div className="relative w-full flex flex-col justify-center items-center mt-6">
            <div className="relative flex w-full px-2 pr-4">
              <div>
                <FormControl fullWidth>
                  <InputLabel>Event List</InputLabel>
                  <Select
                    required
                    size="small"
                    label="Event List"
                    value={eventId}
                    onChange={handleEventFilter}
                    className="bg-white w-56"
                    variant="outlined"
                  >
                    <option value={0} className="pl-4">
                      Event List
                    </option>
                    {events.map((event) => (
                      <MenuItem key={event.id} value={event.id}>
                        {event.eventName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <input
                type="text"
                value={id}
                name="id"
                onChange={(e) => setId(e.target.value)}
                className="bg-white border border-gray-400 text-sm rounded-lg w-full p-2.5"
                placeholder="Reference ID"
                required
              />
            </div>

            <div className="relative w-full flex flex-row pl-4 mt-4">
              <button
                type="button"
                className="text-white bg-gradient-to-r from-cyan-500 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                onClick={handleNotAvailibility}
              >
                Not Available
              </button>
              <button
                type="button"
                className="text-white bg-gradient-to-r from-sky-800 via-sky-800 to-sky-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                onClick={handleGetAllData}
              >
                All Attended List
              </button>
              <button
                type="submit"
                className=" top-0 right-0 p-2.5 text-sm font-medium h-full text-white bg-blue-700 rounded-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                onClick={handleGetData}
              >
                <svg
                  className="w-8 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </button>
            </div>
          </div>
          {studentFound ? (
            <div className="h-96 w-full flex justify-center items-center mt-6">
              <div className="h-96 w-full flex">
                <DataGridPro
                  rows={studentData}
                  columns={attendColumnsMob}
                  // loading={loading}
                  onCellClick={(params, event) => {
                    event.defaultMuiPrevented = true;
                    if (params.colDef.field === 'studentName') {
                      handleOnCellClick(params);
                    }
                    // if (params.colDef.field === 'studentName') {
                    //   window.open(`/leads/${params.row.id}/LeadProfile`, '_blank');
                    // }
                  }}
                  hideFooter
                  rowHeight={80}
                  autoPageSize
                />
              </div>
            </div>
          ) : (
            allStudentFound && (
              <div className="h-96 w-full flex justify-center items-center mt-6">
                <div className="h-96 w-full inline-flex">
                  <DataGridPro
                    rows={allStudentData}
                    columns={allAttendColumns}
                    // loading={loading}
                    rowHeight={55}
                    autoPageSize
                    components={{
                      Toolbar: () => CustomToolbar(true, true),
                    }}
                  />
                </div>
              </div>
            )
          )}
        </>
      ) : (
        <>
          <Dialog
            open={successOpen}
            onClose={() => setSuccessOpen(false)}
            aria-labelledby="success-dialog-title"
            PaperProps={{
              className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
            }}
          >
            <DialogTitle id="success-dialog-title">Success</DialogTitle>
            <DialogContent>
              <DialogContentText>{successMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setSuccessOpen(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={!!errorMessage}
            onClose={() => setErrorMessage('')}
            aria-labelledby="error-dialog-title"
            PaperProps={{
              className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
            }}
          >
            <DialogTitle id="error-dialog-title">Error</DialogTitle>
            <DialogContent>
              <DialogContentText>{errorMessage}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setErrorMessage('')} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <div className="relative justify-center mt-6 text-center text-xl text-gray-900">
            Event Attendance Form{' '}
          </div>
          <div className="relative w-full flex flex-col justify-center items-center mt-6">
            <div className="relative  flex w-full px-2">
              <div>
                <FormControl fullWidth>
                  <InputLabel>Event List</InputLabel>
                  <Select
                    required
                    size="small"
                    label="Event List"
                    value={eventId}
                    onChange={handleEventFilter}
                    className="bg-white w-64"
                    variant="outlined"
                  >
                    <option value={0} className="pl-4">
                      Event List
                    </option>
                    {events.map((event) => (
                      <MenuItem key={event.id} value={event.id}>
                        {event.eventName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <input
                type="text"
                value={studentName}
                onChange={(e) => setStudentName(e.target.value)}
                className="bg-white border border-gray-400 pl-1 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-2 p-2.5 dark:border-gray-400 dark:placeholder-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Student Name"
              />
            </div>
            <div className="relative  flex w-full px-2">
              <input
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="bg-white border border-gray-400 pl-1 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-2 p-2.5 dark:border-gray-400 dark:placeholder-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Email"
              />
              <input
                type="text"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="bg-white border border-gray-400 pl-1 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-2 p-2.5 dark:border-gray-400 dark:placeholder-gray-900 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Phone"
              />
            </div>
            <div className="relative w-full flex flex-row justify-center items-center mt-4">
              <button
                type="button"
                className="text-white bg-gradient-to-r from-cyan-500 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                onClick={handleAvailibility}
              >
                Available
              </button>
              <button
                type="button"
                className="text-white bg-gradient-to-r from-sky-800 via-sky-800 to-sky-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
                onClick={handleGetAllData}
              >
                All Attended List
              </button>

              <button
                type="submit"
                onClick={handleGetData}
                className=" top-0 right-0 p-2.5 rounded-lg text-sm font-medium h-full text-white bg-blue-700 rounded-r-lg border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                <svg
                  className="w-12 h-5 "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 20"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                </svg>
              </button>
            </div>
          </div>
          {studentFound ? (
            <div className="h-96 w-full flex justify-center items-center mt-6">
              <div className="h-96 w-full inline-flex">
                <DataGridPro
                  rows={studentData}
                  columns={attendColumnsMob}
                  // loading={loading}
                  onCellClick={(params, event) => {
                    event.defaultMuiPrevented = true;
                    if (params.colDef.field === 'studentName') {
                      handleOnCellClick(params);
                    }
                    // if (params.colDef.field === 'studentName') {
                    //   window.open(`/leads/${params.row.id}/LeadProfile`, '_blank');
                    // }
                  }}
                  hideFooter
                  rowHeight={80}
                  autoPageSize
                />
              </div>
            </div>
          ) : (
            allStudentFound && (
              <div className="h-96 w-full flex justify-center items-center mt-6">
                <div className="h-96 w-full inline-flex">
                  <DataGridPro
                    rows={allStudentData}
                    columns={allAttendColumns}
                    // loading={loading}
                    rowHeight={55}
                    autoPageSize
                    components={{
                      Toolbar: () => CustomToolbar(true, true),
                    }}
                  />
                </div>
              </div>
            )
          )}
        </>
      )}
      {ready && (
        <AttendedStudentOverview leadId={leadId} setReady={setReady}/>
      )}
    </>
  );
};

export default EventAttendanceForm;
