import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Grid,
  Typography,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import 'tailwindcss/tailwind.css';
import 'tailwindcss/tailwind.css';
import { connect } from 'react-redux';
import {
  DataGridPro,
  GridPagination,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import Agent from '../../../../../api/agent';
import { Countries } from '../../../../../Util/Util';
import CountdownLoader from '../../../StudentLeadComms/LeadComUtils/CountdownLoader';
import AgentComFilters from '../../AgentComFilters/AgentComFilters';
import { AGENTSOURCES, AGENTSTATUS } from '../../../../../Util/Constants';
import AgentBulkSummaryDialog from '../../AgentBulkSummaryDialog/AgentBulkSummaryDialog';

function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {isInternal && <GridToolbarColumnsButton />}
      {/* {isInternal && <GridToolbarFilterButton />} */}
      <GridToolbarDensitySelector />
      {hide && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}
const AgentBulkEmail = ({ appUserId, users }) => {
  const [agentId, setAgentId] = useState('');
  const [bdmId, setBdmId] = useState('');
  const [company, setCompany] = useState('');
  const [status, setStatus] = useState(0);
  const [appUpdateEmail, setAppUpdateEmail] = useState(0);
  const [companyHq, setCompanyHq] = useState(0);
  const [showCountdownLoader, setShowCountdownLoader] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalSelected, setTotalSelected] = useState(0);
  const [fromMail, setFromEmail] = useState(0);
  const [selectTemplateId, setSelectTemplateId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [agentIdList, setAgentIdList] = useState([]);
  const [fromMailList, setFromMailList] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [dialogueCommunicationName, setDialogueCommunicationName] =
    useState('');
  const [mailSubject, setMailSubject] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showData, setShowData] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(800);
  const [totalrows, setTotalRows] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [totalPages, setTotalPages] = useState();
  const [isApplicationEmail, setIsApplicationEmail] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCountdownComplete = () => {
    setShowCountdownLoader(false);
  };

  const handleApplyFilters = () => {
    setShowData(true);
    setPageNumber(1);
    fetchData(true);
  };

  const handleSendMail = async () => {
    handleCloseConfirmDialog();
    setShowCountdownLoader(true);
    console.log('payload', payload);
    try {
      await Agent.Communication.bulkEmailSendAgent(payload).then((response) => {
        setOpenDialog(false);
        setSuccessMessage('Mail sent successfully!', response);
        setShowCountdownLoader(false);
      });
    } catch (error) {
      console.error('payload error', error);
      setErrorMessage('Post data', error);
      setShowErrorMessage(true);
    }
  };

  const handleCompanyHqChange = (event, value) => {
    setCompanyHq(value ? value.id : 0);
  };

  const handleAddAgentId = (selectedAgentId) => {
    setAgentIdList((prevList) => [...prevList, { Id: selectedAgentId }]);
  };

  const closeErrorMessage = () => {
    setShowErrorMessage(false);
  };

  const ErrorMessageDisplay = () =>
    showErrorMessage && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-red-800 text-white p-6 rounded-lg shadow-lg z-50">
          <button
            className="btn btn-warning float-right"
            onClick={closeErrorMessage}
          >
            Close
          </button>
          <p>{errorMessage}</p>
        </div>
      </div>
    );

  const payload = {
    Agents: agentIdList,
    CommunicationName: dialogueCommunicationName,
    TemplateId: selectTemplateId,
    EmailSubject: mailSubject,
    FromEmail: fromMail,
    AppUserId: appUserId,
    NumberOfStudents: totalSelected,
    IsApplicationUpdateEmail: isApplicationEmail,
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    setSelectedRowCount(selectedRows.length);
  }, [selectedRows]);

  useEffect(() => {
    setTotalSelected(selectedRows.length);
  }, [selectedRows]);

  const fetchTemplates = async () => {
    try {
      const response = await Agent.Communication.getAgentEmailTemplates();
      setTemplates(response);
      console.log('tempalte list', response);
    } catch (error) {
      console.error(error);
    }
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', pageSize.toString());

    params.append('id', filter ? agentId.trim() : '');
    params.append('company', filter ? company.trim() : '');
    params.append('status', filter ? status.toString() : '0');
    params.append('BusinessDevAppUserDetailsId', filter ? bdmId.trim() : '');
    if (filter) {
      params.append('Country', companyHq);
    }
    if (filter) {
      params.append('AppUpdateEmail', appUpdateEmail);
    }
    // params.append('Country', filter ? companyHq.trim() : null);
    // params.append('AgentNationality', filter ? bdmId.trim() : '');

    return params;
  };

  useEffect(() => {
    Agent.Communication.getFromEmails().then(setFromMailList);
  }, []);

  const fetchData = async (filter = false) => {
    setLoading(true);
    try {
      const params = getParams(filter);
      const dataset = await Agent.Communication.listAgent(params);
     // console.log('dataset', dataset);
      const { pagination, data } = dataset;
      setTotalPages(pagination.totalPages);
      setTotalRows(pagination.totalItems); // Reflects the count of filtered data
      setDataLoaded(true);
      setAgents(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(true); // Initial fetch with filters
  }, []); 

  const handleSelectTemplateIdChange = (event) => {
    setSelectTemplateId(event.target.value);
  };
  const handleCommunicationNameChange = (event) => {
    setDialogueCommunicationName(event.target.value);
  };

  const handleMailSubjectChange = (event) => {
    setMailSubject(event.target.value);
  };
  const handleFromMailChange = (event) => {
    setFromEmail(event.target.value);
  };
  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    // If Select All is checked, select all students on the current page
    if (!selectAll) {
      const selectedIds = agents.map((agent) => ({
        Id: agent.id,
      }));
      setAgentIdList(selectedIds);
      setSelectedRows(selectedIds.map((student) => student.Id));
    } else {
      setAgentIdList([]);
      setSelectedRows([]);
    }
  };

  const renderCheckbox = (params) => (
    <Checkbox
      checked={selectedRows.includes(params.row.id) || selectAll}
      onChange={(event) => handleRowSelection(event, params.row.id)}
    />
  );

  let agentComColumns = [
    {
      field: 'checkbox',
      headerName: 'Select',
      width: 100,
      renderCell: renderCheckbox,
      headerAlign: 'center',
      renderHeader: () => (
        <Checkbox
          checked={selectAll}
          onChange={handleSelectAll}
          indeterminate={
            selectedRows.length > 0 && selectedRows.length < agents.length
          }
        />
      ),
    },
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
      hide: true,
    },

    {
      field: 'company',
      headerName: 'Company Name',
      width: 300,
      renderCell: (params) => <Typography>{params.row.companyName}</Typography>,
    },
    {
      field: 'country',
      headerName: 'Company HQ',
      width: 160,
      hide: true,
      valueGetter: (params) => {
        const country = params.row.country;
        const countryName = Countries.find((sta) => sta.id == country);
        return countryName ? countryName.name : '';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 160,
      valueGetter: (params) => {
        const status = params.row.status;
        const agentStatus = AGENTSTATUS.find((sta) => sta.id == status);
        return agentStatus ? agentStatus.type : '';
      },
    },
    {
      field: 'admin',
      headerName: 'Admin',
      width: 200,
      renderCell: (params) => <Typography>{params.row.adminName}</Typography>,
    },
    {
      field: 'businessDevAppUserDetailsId',
      headerName: 'BDM',
      width: 220,
      valueGetter: (params) => {
        const bdm = params.row.businessDevAppUserDetailsId;
        const bdmName =
          bdm && users.length > 0
            ? users.find(({ id }) => id == parseInt(bdm))
            : '';
        return bdmName ? bdmName.name : '';
      },
    },
    {
      field: 'noOfStudents',
      headerName: 'Student Count',
      width: 120,
      hide: true,
      renderCell: (params) => (
        <Typography className="pl-8">{params.row.noOfStudents}</Typography>
      ),
    },
    {
      field: 'companyEmail',
      headerName: 'Company Email',
      width: 250,
      hide: true,
      renderCell: (params) => (
        <Typography>{params.row.companyEmail}</Typography>
      ),
    },
    {
      field: 'applicationUpdateEmail',
      headerName: 'application Update Email',
      width: 260,
      hide: false,
      renderCell: (params) => (
        <Typography>{params.row.applicationUpdateEmail}</Typography>
      ),
    },

    {
      field: 'agentNationality',
      headerName: 'Nationality',
      width: 200,
      valueGetter: (params) => {
        const nationality = params.row.agentNationality;
        const nationalityName = nationality
          ? Countries.find(({ id }) => id == nationality)
          : '';
        return nationalityName ? nationalityName.name : '';
      },
    },

    {
      field: 'source',
      headerName: 'Source',
      width: 200,
      valueGetter: (params) => {
        const source = params.row.source;
        const sourceName = source
          ? AGENTSOURCES.find(({ id }) => id == source)
          : '';
        return sourceName ? sourceName.type : '';
      },
    },
    {
      field: 'created',
      headerName: 'Created On',
      width: 120,
      hide: true,
      valueGetter: (params) => {
        const createdOn = params.row.created;
        return createdOn ? createdOn.split('T')[0] : '';
      },
    },
  ];

  const handleClearFilters = () => {
    setBdmId('');
    setAgentId('');
    setCompany('');
    setAppUpdateEmail(0);
    setCompanyHq(0);
    setStatus(0);
    setSelectedRows([]);
    setAgentIdList([]);
    setShowData(false);
    setSelectAll(false);
    fetchData(false);
  };

  const handleRowSelection = (event, rowId) => {
    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowId];
      const selectedAgentId = agents.find((agent) => agent.id === rowId).id;
      handleAddAgentId(selectedAgentId);
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
    }

    setSelectedRows(newSelectedRows);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDialogueCommunicationName('');
  };

  const ConfirmDialog = () => (
    <Dialog
      open={openConfirmDialog}
      onClose={handleCloseConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Confirm Sending Mail'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description ">
          Are you sure you want to send the mail?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="flex justify-around">
        <Button
          onClick={handleCloseConfirmDialog}
          color="primary"
          className="btn-primary"
        >
          No
        </Button>
        <Button
          onClick={handleSendMail}
          color="success"
          className="btn-success"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <div className="h-96 w-full">
      <div className="bg-white px-4 mb-4 shadow-sm">
        <AgentComFilters
          users={users}
          companyHq={companyHq}
          setCompanyHQ={setCompanyHq}
          bdmId={bdmId}
          setBdmId={setBdmId}
          agentId={agentId}
          status={status}
          setAgentId={setAgentId}
          setStatus={setStatus}
          setAppUpdateEmail={setAppUpdateEmail}
          appUpdateEmail={appUpdateEmail}
          company={company}
          handleCompanyHqChange={handleCompanyHqChange}
          handleClearFilters={handleClearFilters}
          handleApplyFilters={handleApplyFilters}
          handleSendBulkEmail={handleOpenDialog}
          totalSelected={totalSelected}
        />

        {showData ? (
          dataLoaded ? (
            <Grid item style={{ height: 730, width: '100%' }}>
              <DataGridPro
                rows={agents}
                columns={agentComColumns}
                totalPageSize={totalPages}
                loading={loading}
                pagination={true}
                paginationMode="server"
                pageSize={pageSize}
                pageSizeOptions={[800]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowCount={totalrows}
                onPageChange={(newPage) => setPageNumber(newPage + 1)}
                page={pageNumber - 1}
                components={{
                  Toolbar: CustomToolbar,
                  Pagination: GridPagination,
                }}
              />
            </Grid>
          ) : (
            <div>Loading data...</div>
          )
        ) : (
          <div className="pb-4">
            No data to display. Please apply filters to show data.
          </div>
        )}

        <AgentBulkSummaryDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          totalSelected={totalSelected}
          templates={templates}
          fromMailList={fromMailList}
          dialogueCommunicationName={dialogueCommunicationName}
          setDialogueCommunicationName={setDialogueCommunicationName}
          selectTemplateId={selectTemplateId}
          setSelectTemplateId={setSelectTemplateId}
          mailSubject={mailSubject}
          setMailSubject={setMailSubject}
          fromMail={fromMail}
          isApplicationEmail={isApplicationEmail}
          setIsApplicationEmail={setIsApplicationEmail}
          setFromMail={setFromEmail}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          handleSelectTemplateIdChange={handleSelectTemplateIdChange}
          handleFromMailChange={handleFromMailChange}
          handleMailSubjectChange={handleMailSubjectChange}
          handleCommunicationNameChange={handleCommunicationNameChange}
        />

        {successMessage && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-blue-500 text-black p-4 rounded shadow-lg">
              <p>{successMessage}</p>
              <button
                className="btn btn-warning mt-4"
                onClick={() => setSuccessMessage('')}
              >
                Close
              </button>
            </div>
          </div>
        )}

        <ErrorMessageDisplay />
        <ConfirmDialog />

        {showCountdownLoader && (
          <CountdownLoader
            totalSelected={totalSelected}
            onCountdownComplete={handleCountdownComplete}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
    users: state.users,
  };
};

export default connect(mapStateToProps, null)(AgentBulkEmail);
