/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Divider, FormControl, Grid, Paper, Select, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import Draggable from 'react-draggable';
import { APPLICATION_FILE_STATUSES, COMPLIANCE_STATUS, STUDENTSTATUS } from '../../../Util/Constants';


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const ApplicationAssignment = ({
  users,
  action,
  role,
  cancel,
  application,
}) => {
  users.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const [ready, setReady] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [openConverstion, setOpenConverstion] = useState(false);
  const [openCom, setOpenCom] = useState(false);
  const [openComplianceStatus, setopenComplianceStatus] = useState(false);
  const [openVirtualTeam, setopenVirtualTeam] = useState(false);

  const [conversionRecord, setConversionRecord] = useState(
    users.find(({ id }) => id == application.conversionTeamId)
  );
  const [complianceRecord, setComplianceRecord] = useState(
    users.find(({ id }) => id == application.complianceTeamId)
  );
  const [complianceStatus, setComplianceStatus] = useState(
    COMPLIANCE_STATUS.find(({ id }) => id == application.complianceStatus)
  );
  const [complianceStatusChanged, setComplianceStatusChanged] = useState(false)
  const [virtualTeamRecord, setVirtualTeamRecord] = useState(
    users.find(({ id }) => id == (application.virtualTeamId))
  );
  const [status, setStatus] = useState(application.fileStatus || 1);
  const handleClose = () => {
    setDialogOpen(false);
    cancel(false);
  };

  const confirm = () => {
    action({
      fileStatus: status,
      conversionTeamId: conversionRecord ? conversionRecord.id : null,
      conversion: conversionRecord ? conversionRecord.name : '',
      complianceTeamId: complianceRecord ? complianceRecord.id : null,
      compliance: complianceRecord ? complianceRecord.name : '',
      virtualTeamId: virtualTeamRecord ? virtualTeamRecord.id : null,
      virtualTeam: virtualTeamRecord ? virtualTeamRecord.name : '',
      complianceStatus: complianceStatus ? complianceStatus.id: null,
      complianceStatusName:  complianceStatus 
        ? COMPLIANCE_STATUS.find(({ id }) => id === complianceStatus.id)?.type
        : '',
      complianceStatusChanged: complianceStatusChanged,
    });
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        open={dialogOpen}
        maxWidth="sm"
        fullWidth
        PaperComponent={PaperComponent}
      >
        <BootstrapDialogTitle onClose={handleClose} style={{ cursor: 'move' }}>
          <Typography align="center" color="secondary" variant="h6">
            {application.name}
          </Typography>
          <Divider />
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ paddingTop: 4 }}
          >
            <Grid item container spacing={3}>
              <Grid item xs={2}>
                <Typography>Status</Typography>
              </Grid>
              <Grid item xs={10}>
                <Select
                  fullWidth
                  size="small"
                  native
                  label="Status"
                  value={status}
                  onChange={(e) => {
                    debugger;
                    setStatus(e.target.value);
                    setReady(true);
                  }}
                >
                  {APPLICATION_FILE_STATUSES.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.type}
                    </option>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Grid item>
              <Autocomplete
                fullWidth
                size="small"
                open={openConverstion}
                onOpen={() => {
                  setOpenConverstion(true);
                }}
                onClose={() => {
                  setOpenConverstion(false);
                }}
                value={conversionRecord}
                onChange={(_, newVal) => {
                  setConversionRecord(newVal);
                  setReady(true);
                }}
                getOptionLabel={(user) => `${user.name}`}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Grid
                      container
                      direction="column"
                      sx={{
                        backgroundColor: (theme) => theme.palette.grey[200],
                        borderRadius: 1,
                        padding: 1,
                      }}
                      spacing={1}
                    >
                      <Grid item>
                        <Typography color="primary">{option.name}</Typography>
                      </Grid>
                    </Grid>
                  </li>
                )}
                options={users}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Conversion"
                    label="Conversion"
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: 'new-password',
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item>
              <Autocomplete
                fullWidth
                size="small"
                open={openCom}
                onOpen={() => {
                  setOpenCom(true);
                }}
                onClose={() => {
                  setOpenCom(false);
                }}
                value={complianceRecord}
                onChange={(_, newVal) => {
                  setComplianceRecord(newVal);
                  setReady(true);
                }}
                getOptionLabel={(user) => `${user.name}`}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Grid
                      container
                      direction="column"
                      sx={{
                        backgroundColor: (theme) => theme.palette.grey[200],
                        borderRadius: 1,
                        padding: 1,
                      }}
                      spacing={1}
                    >
                      <Grid item>
                        <Typography color="primary">{option.name}</Typography>
                      </Grid>
                    </Grid>
                  </li>
                )}
                options={users}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Compliance"
                    label="Compliance"
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: 'new-password',
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={complianceStatus}
                        open={openComplianceStatus}
                        onOpen={() => {
                          setopenComplianceStatus(true)
                        }}
                        onClose={() => {
                          setopenComplianceStatus(false)
                        }}
                        onChange={(_, newVal) => {
                          setComplianceStatus(newVal);
                          setComplianceStatusChanged(true);
                          setReady(true);
                        }}
                        defaultValue={COMPLIANCE_STATUS.find(
                          ({id}) => id == (application ? application.complianceStatus : null)
                        )}
                        options={COMPLIANCE_STATUS}
                        // className="w-56"
                        getOptionLabel={(COMPLIANCE_STATUS) =>COMPLIANCE_STATUS.type}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Compliance Status"
                            variant="outlined"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
            <Grid item>
              <Autocomplete
                fullWidth
                size="small"
                open={openVirtualTeam}
                onOpen={() => {
                  setopenVirtualTeam(true);
                }}
                onClose={() => {
                  setopenVirtualTeam(false);
                }}
                value={virtualTeamRecord}
                onChange={(_, newVal) => {
                  setVirtualTeamRecord(newVal);
                  setReady(true);
                }}
                getOptionLabel={(user) => `${user.name}`}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Grid
                      container
                      direction="column"
                      sx={{
                        backgroundColor: (theme) => theme.palette.grey[200],
                        borderRadius: 1,
                        padding: 1,
                      }}
                      spacing={1}
                    >
                      <Grid item>
                        <Typography color="primary">{option.name}</Typography>
                      </Grid>
                    </Grid>
                  </li>
                )}
                options={users.filter((item) => item.userType == 2)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="University Representative"
                    label="University Representative"
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: 'new-password',
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button disabled={!ready} autoFocus onClick={confirm}>
            Confirm Selection
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};