import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  FormControl,
  MenuItem,
  Container,
  IconButton,
} from '@mui/material';
import {
  LEADORIGINS,
} from '../../../Util/Constants';

import { Done, NotInterested } from '@mui/icons-material';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';

import Agent from '../../../api/agent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { DataGridPro } from '@mui/x-data-grid-pro';
import EditIcon from '@mui/icons-material/Edit';
import { addHours, addMonths, format, startOfDay } from 'date-fns';
import { EditAnnouncementType } from './EditAnnouncementType';
import MyTooltip from '../../Common/Tooltip/MyTooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const AnnouncementType = ({ appUserId }) => {
    const [announcementType, setAnnouncementType] = useState({});
  const [selectedlLeadOrigin, setSelectedlLeadOrigin] = useState('');
  const [announcementName, setAnnouncementName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [annTypeRowList, setAnnTypeRowList] = useState([]);
  const [startDate, setStartDate] = useState(addMonths(new Date(), -1));
  const [endDate, setEndDate] = useState(new Date());
  const [ready, setReady] = useState(false);
  const [reload,setReload]=useState(false);

  const handleSaveLead = async () => {
    try {
        const payload = {
        name:announcementName
        };
        Agent.Announcement.saveAnnouncementtype(payload).then((res)=>{
          setSuccessMessage('Annoumcement type saved successfully!');
          setSuccessOpen(true);
          clearForm();
          getData(true);
        })
    } catch (error) {
      console.error(error);
    }
  };
  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('StartDate','' );
    params.append('EndDate','' );
    // if (filter) {
    //   if (startDate) {
    //     params.append('StartDate', new Date(startDate).toDateString());
    //   }
    // } else {
    //   params.append('StartDate', addMonths(new Date(), -1).toDateString());
    // }
    // if (filter) {
    //   if (endDate) {
    //     params.append('EndDate', new Date(endDate).toDateString());
    //   }
    // } else {
    //   params.append('EndDate', new Date().toDateString());
    // }

    return params;
  };
  const showEditOptions = (row) => {
    setAnnouncementType(row);
    console.log('Row', row);
     setReady(true);
  };
  const actionSelectedUser = (record) => {
    console.log('record',record);
    const assignDto = {
      id: record.id,
      name: record.name,
      isInactive:record.isInactive,
    }

        Agent.Announcement.saveAnnouncementtype(assignDto).then((res)=>{
          getData(true);
          setReady(false);
        })
       .catch((error)=>{
      console.log(error);
      })
  }
  const getRows = (rows) => {
    let annTypeRows = rows.map((annTypeRows) => ({
      id: annTypeRows.id,
      name: annTypeRows.name,
      isInactive:annTypeRows.isInactive

    }));
    setAnnTypeRowList(annTypeRows);

  };

  useEffect(() => {
      getData(true);
  }, []);

  const getData = (filter = false) => {
    Agent.Announcement.getAnnouncementType(getParams(filter))
      .then((res) => {
         console.log(res);
        getRows(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const clearForm = () => {
    setAnnouncementName('');
  };
 
  let columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      hide: true,
    },
    
    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      align: 'left',
      headerAlign: 'left',
      // renderCell: (params) => (
      //   <Link to={`/templates/${params.row.id}`}>{params.value}</Link>
      // ),
    },
    {
      field: 'isInactive',
      headerName: 'IsActive',
      width: 110,
      align: 'center',
      headerAlign:'center',
      renderCell: (params) => (
        <strong>
          {!params.row.isInactive ? (
            <MyTooltip
              icon={<CheckCircleIcon className="bg-green-500" />}
              title="Active Announcement Type"
            >
              <IconButton size="large">
                <CheckCircleIcon className="text-green-500" />
              </IconButton>
            </MyTooltip>
          ) : (
            <MyTooltip icon={<NotInterested />} title="Not Active Announcement Type">
              <IconButton size="large">
                <NotInterested className="text-red-600" />
              </IconButton>
            </MyTooltip>
          )}
        </strong>
      ),
    },
    {
      field: 'edit',
      align: 'center',
      headerAlign: 'center',
      width: 90,
      resizable: true,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <IconButton
          //disabled={!isInternal}
          onClick={() => showEditOptions(params.row)}
          size="large"
        >
         <EditIcon/>
        </IconButton>
      ),
    },

  ];
  
  const successDialogStyles = {
    backgroundColor: 'bg-green-400',
  };

  const errorDialogStyles = {
    backgroundColor: 'bg-red-400',
  };


  return (
        <div className="relative px-4 py-0 bg-white mx-8 md:mx-0 shadow-lg rounded-3xl">
          <h2 className="text-3xl text-center font-bold mb-8 mt-0">
            Announcement Type
          </h2>
          <Grid container spacing={1}>
            
           <Grid container direction="column" spacing={1} className='pl-2'>
            <Grid item xs={6} sm={6} md={6} className='relative'>
                <TextField
                  fullWidth
                  label="Announcement Type"
                  native
                  size="small"
                  value={announcementName}
                  onChange={(e)=>setAnnouncementName(e.target.value)}
                >
                </TextField>
            </Grid>

            <Grid item xs={6} >
              <Button
                variant="contained"
                onClick={handleSaveLead}
                color="success"
                className="min-w-fit py-2 px-6 bg-indigo-500 hover:bg-indigo-600 focus:ring-indigo-400 focus:ring-offset-indigo-200 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                endIcon={<Done />}
              >
                Add
              </Button>
            </Grid>
            </Grid>
            <Grid item style={{ height: 650, width: '100%' }}>
          <DataGridPro
            paginationMode="client"
            rows={annTypeRowList}
            columns={columns}
            density="compact"
            rowHeight={70}
          />
        </Grid>
            {ready && (
            <EditAnnouncementType
            announcementType={announcementType}
            action={actionSelectedUser}
            cancel={setReady}
            setReload={setReload}
            />
              )}
            <Dialog
              open={successOpen}
              onClose={() => setSuccessOpen(false)}
              aria-labelledby="success-dialog-title"
              PaperProps={{
                className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
              }}
            >
              <DialogTitle id="success-dialog-title">Success</DialogTitle>
              <DialogContent>
                <DialogContentText>{successMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setSuccessOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            <Dialog
              open={!!errorMessage}
              onClose={() => setErrorMessage('')}
              aria-labelledby="error-dialog-title"
              PaperProps={{
                className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
              }}
            >
              <DialogTitle id="error-dialog-title">Error</DialogTitle>
              <DialogContent>
                <DialogContentText>{errorMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setErrorMessage('')} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    appUserId: state.auth.id,
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementType);
