/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import {
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
  CardContent,
  CardActions,
  CardActionArea,
  Box,
  Grid,
} from '@mui/material';
import Agent from '../../api/agent';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { values } from 'lodash';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';
import { socialMedia, authStyles } from './AuthStyle';
import DialogTitle from '@mui/material/DialogTitle';
import MyTextInput from '../Common/Form/MyTextInput';
import * as actions from '../../store/actions/index';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Visibility from '@mui/icons-material/Visibility';
import { motion } from 'framer-motion';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DialogContentText from '@mui/material/DialogContentText';
import Branding from '../../assets/images/logo-helper-text.png';
import LoginArtwork from '../../assets/images/login-artwork.png';
import LinearIndeterminate from '../Common/Progress/LinearIndeterminate';
import Logo from '../../assets/images/tclnewlogo.png';
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import { GEO_LOCATION_URL, VERIFICATION_COOKIE } from '../../Util/Constants';

const Auth = ({
  onAuthStart,
  onAuthSuccess,
  onSetLocalStorageInfo,
  onAuthFail,
  location,
}) => {
  const history = useNavigate();
  const [screenWidth, setScreenWidth] = useState();
  const verificationCode =
    localStorage.getItem(VERIFICATION_COOKIE) ||
    cookies.get(VERIFICATION_COOKIE) ||
    '';
  const [browserUserInfo, setBrowserUserInfo] = useState({
    ipAddress: '',
    city: '',
    country: '',
    userAgent: navigator.userAgent.toString(),
    verificationCode,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogCode, setOpenDialogCode] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [verificationEmail, setVerificationEmail] = useState();
  const [verificationPhone, setVerificationPhone] = useState();
  const [code, setCode] = useState();
  const [newCode, setNewCode] = useState();
  const [remember, setRemember] = useState(true);
  const [verificationError, setVerificationError] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  // creating function to load ip address from the API
  const getData = async () => {
    try {
      const res = await axios.get(GEO_LOCATION_URL);
      console.log(res.data);
      if (res.status == 200) {
        setBrowserUserInfo({
          ipAddress: res.data.IPv4,
          city: res.data.city,
          country: res.data.country_name,
          userAgent: navigator.userAgent.toString(),
          verificationCode: cookies.get(VERIFICATION_COOKIE),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCode(
      localStorage.getItem(VERIFICATION_COOKIE) ||
        cookies.get(VERIFICATION_COOKIE)
    );
    getData();
  }, []);

  //Drop Shadow logic for show and hide
  useEffect(() => {
    setScreenWidth(
      window.innerWidth ? window.innerWidth : screen.width ? screen.width : 1350
    );
  }, [window.innerWidth, screen.width]);

  const dropShadow = { initial: {} };

  Object.assign(dropShadow.initial, {
    boxShadow: `${
      screenWidth > 900
        ? '0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 6px 20px 0 rgba(0, 0, 0, 0.08)'
        : '0'
    }`,
    marginTop: `${screenWidth > 1350 ? '120px' : '0px'}`,
  });

  // Toastify Logic

  const handleSuccessClick = (data) => {
    console.log(data);
  };

  const handleForgotPasswordEmailOnChange = (event) => {
    setForgotPasswordEmail(event.target.value);
  };
  const handleCodeOnChange = (event) => {
    setNewCode(event.target.value);
  };
  const handleClickDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleCodeDialogClose = () => {
    setOpenDialogCode(false);
  };
  const handleForgotPassword = () => {
    Agent.Account.forgotPassword(forgotPasswordEmail)
      .then((response) => {
        handleSuccessClick(response);
        setSuccessMessage(response);
        setSuccessOpen(true); 
      })
      .catch((error) => {
        console.log(error);
      });
    setOpenDialog(false);
  };

  // state and functions for password
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const handleClickShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email')
      .required('Email address is required'),
    password: Yup.string().min(8, 'Must be at least 8 characters').required(),
  });
  const handleCode = async () => {
    onAuthStart();
    await Agent.Account.login2f(email, password, {
      ...browserUserInfo,
      verificationCode: newCode || '',
    })
      .then((response) => {
        //get cookie
        if (remember && newCode) {
          cookies.set(VERIFICATION_COOKIE, response.verificationCode, {
            path: '/',
            maxAge: 15552000, // 6 months in seconds
          });
          localStorage.setItem(VERIFICATION_COOKIE, response.verificationCode);
        }
        handleLoginSuccess(response);
        setOpenDialogCode(false);
      })
      .catch((error) => {
        console.log(error);
        debugger;
        setVerificationError(true);
      });
  };

  const handleLoginSuccess = (response) => {
    onSetLocalStorageInfo(response);
    onAuthSuccess(
      response.name,
      response.phoneNumber,
      response.email,
      response.applicationUpdateEmail,
      response.token,
      response.role,
      response.image,
      response.id,
      response.isInternal,
      response.appUserDetailsId
    );
    history(location);
  };

  async function handleFormSubmit(user) {
    onAuthStart();
    await Agent.Account.login(user.email, user.password, browserUserInfo)
      .then((response) => {
        if (response.newDevice) {
          setEmail(user.email);
          setPassword(user.password);
          setVerificationEmail(response.verificationEmailAddress);
          setVerificationPhone(response.verificationPhoneNumber);
          setOpenDialogCode(true);

        } else {
          handleLoginSuccess(response);
        }
      })
      .catch((error) => {
        onAuthFail(error);
        // const errorMessage =error.response.data;
        // setLoginError(errorMessage);
        setLoginError(error);

      });
  }
  const successDialogStyles = {
    backgroundColor: 'bg-gray-100', // Green color for success
  };
  let initValues = {
    email: '',
    password: '',
    error: '',
  };
  return (
    <>
      <Dialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        aria-labelledby="success-dialog-title"
        PaperProps={{
          className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
        }}
      >
        <DialogTitle id="success-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText className='text-gray-900'>{successMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.6,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
      >
        <Box
          display="flex"
          justifyContent="center" // Center horizontally
          alignItems="center" // Center vertically
          height="90vh" // Make the container cover the entire viewport height
        >
          <Box px={{ xs: 0, lg: 45 }} sx={authStyles.root}>
            <Box sx={authStyles.root2} style={dropShadow.initial}>
              <Box
                display={{ xs: 'none', lg: 'flex' }}
                sx={authStyles.firstHalfRoot}
              >
                <Box
                  className="box1"
                  style={{ cursor: 'pointer' }}
                  pt={{ xs: 10, lg: 5, xl: 13 }}
                >
                  <img
                    src={Branding}
                    alt="Logo"
                    // maxWidth={{ xs: '5px' }}
                    style={authStyles.maxWidth}
                  />
                </Box>
                <Box className="box1" style={{}}>
                  <img src={LoginArtwork} alt="Artwork" />
                </Box>
              </Box>
              <Box
                sx={authStyles.secondHalfRoot}
                className="box1"
                width={{ xs: '100%', lg: '50%' }}
              >
                <Box width={{ xs: '50%', sm: '30%', lg: '50%' }}>
                  <img
                    src={Logo}
                    alt="Logo"
                    width={50}
                    height={50}
                    style={{ marginBottom: '10px', padding: '10px' }} // Adjust the margin values as needed
                  />
                </Box>
                <Box
                  sx={authStyles.formRoot}
                  px={{ xs: 0, sm: 15, lg: 0 }}
                  className="box1"
                >
                  <Formik
                    enableReinitialize
                    validationSchema={validationSchema}
                    initialValues={initValues}
                    onSubmit={(values, { setErrors, setSubmitting }) => {
                      handleFormSubmit(values).catch((errors) => {
                        setSubmitting(false);
                        let err = [];
                        if (typeof errors === 'string') {
                          err.push(errors);
                        } else {
                          err = errors;
                        }
                        setErrors({
                          error: err,
                        });
                      });
                    }}
                  >
                    {({
                      handleSubmit,
                      isValid,
                      isSubmitting,
                      dirty,
                      errors,
                    }) => (
                      <Form onSubmit={handleSubmit} autoComplete="off">
                        <Box style={{ paddingTop: '16px' }}>
                          <Box style={{ paddingBottom: '10px' }}>
                            <MyTextInput
                              label="Email"
                              name="email"
                              placeholder="Email Address"
                              type="email"
                              InputLabelProps={{ shrink: true }}
                              style={{ border: 0 }}
                            />
                          </Box>
                          <Box style={{ paddingBottom: '10px' }}>
                            <MyTextInput
                              label="Password"
                              name="password"
                              placeholder="Password"
                              value={values.password}
                              type={passwordVisibility ? 'text' : 'password'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      size="large"
                                    >
                                      {passwordVisibility ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                          {!code && false && (
                            <Box>
                              <motion.div
                                whileTap={{
                                  scale: 0.7,
                                  transition: { duration: 0.3 },
                                }}
                              >
                                <Typography
                                  variant="caption"
                                  color={'secondary'}
                                >
                                  Note: 2 factor verification will be required
                                  for this login
                                </Typography>
                              </motion.div>
                            </Box>
                          )}
                          <Box>
                            <motion.div
                              whileTap={{
                                scale: 0.7,
                                transition: { duration: 0.3 },
                              }}
                            >
                              <button
                                // disabled={isSubmitting || !dirty || !isValid}
                                type="submit"
                                style={authStyles.btn.loginButton}
                              >
                                <Typography variant="body1">LOGIN</Typography>
                              </button>
                            </motion.div>
                          </Box>
                          <Box
                            style={{
                              paddingRight: 10,
                              paddingTop: 4,
                              height: 6,
                              weight: '100%',
                            }}
                          >
                            {isSubmitting && (
                              <Box>
                                <LinearIndeterminate />
                              </Box>
                            )}
                          </Box>
                        </Box>
                        {/* Step 3: Display the error message */}
                        {loginError && (
                          <Box my={2}>
                            <Typography color="error">{loginError}</Typography>
                          </Box>
                        )}
                        <Box style={{ marginTop: 0 }}>
                          <Typography align="center" color="textPrimary">
                            <Button
                              variant="text"
                              onClick={handleClickDialogOpen}
                            >
                              Reset Password
                            </Button>
                          </Typography>
                        </Box>
                        <Box>
                          <div>
                            <CardActionArea style={{ cursor: 'default' }}>
                              <CardContent
                                style={{
                                  textAlign: 'center',
                                  color: '#E42D26',
                                }}
                              >
                                <Typography
                                  style={{ lineHeight: 1, fontSize: '20px' }}
                                >
                                  {`  Don't Have an account?`}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  color="textSecondary"
                                  component="p"
                                >
                                  {` Sign up as a `}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                            <CardActions
                              sx={{ display: 'flex', justifyContent: 'center' }}
                            >
                              <motion.div
                                whileHover={{
                                  scale: '0.95',
                                  transition: { duration: 1.2 },
                                }}
                              >
                                <Button
                                  component={Link}
                                  to={{ pathname: '/register/partner' }}
                                  size="small"
                                  color="primary"
                                  variant="outlined"
                                  sx={authStyles.btn.partnerButton}
                                >
                                  PARTNER
                                </Button>
                              </motion.div>
                              <motion.div
                                whileHover={{
                                  scale: '0.95',
                                  transition: { duration: 1.2 },
                                }}
                              >
                                <Button
                                  component={Link}
                                  to={{ pathname: '/register/student' }}
                                  size="small"
                                  color="primary"
                                  variant="outlined"
                                  sx={authStyles.btn.studentButton}
                                >
                                  STUDENT
                                </Button>
                              </motion.div>
                              <motion.div
                                whileHover={{
                                  scale: '0.95',
                                  transition: { duration: 1.2 },
                                }}
                              >
                                <Button
                                  component={Link}
                                  to={{ pathname: '/referral/signup' }}
                                  size="small"
                                  color="primary"
                                  variant="outlined"
                                  sx={authStyles.btn.refButton}
                                >
                                  REFERRAL
                                </Button>
                              </motion.div>
                            </CardActions>
                            <Box>
                              <hr style={{ borderColor: '#34B3F1 ' }}></hr>

                              <Box style={authStyles.socialMedia}>
                                {socialMedia.map((item) => (
                                  <Box item key={item.id} sx={{ p: 0.5 }}>
                                    <motion.div
                                      whileHover={{
                                        scale: 1.1,
                                        color: 'rgba(3, 201, 136, 0.1)',
                                      }}
                                      whileTap={{ scale: 1 }}
                                    >
                                      <a href={item.href}>{item.icon}</a>
                                    </motion.div>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          </div>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Dialog
          open={openDialog}
          onClose={handleDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              Enter your email address to reset your password. The email address
              needs to be the one that was used to register the account.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="forgotpasswordemail"
              label="Email Address"
              type="email"
              fullWidth
              onChange={handleForgotPasswordEmailOnChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="secondary"  className='bg-red-400'>
              Cancel
            </Button>
            <Button
              disabled={!forgotPasswordEmail}
              onClick={handleForgotPassword}
              color="primary"
              className='bg-sky-400'
            >
              Reset Password
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openDialogCode}
          onClose={handleCodeDialogClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Device Verification</DialogTitle>
          <DialogContent dividers>
            <DialogContentText>
              {`Enter your the verification code sent to the email adress: ${verificationEmail} and
              press Submit`}
            </DialogContentText>
            <TextField
              size="small"
              autoFocus
              margin="dense"
              id="forgotpasswordemail"
              label="2 Factor Code"
              type="email"
              fullWidth
              onChange={handleCodeOnChange}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={remember}
                  onChange={(e) => setRemember(e.target.checked)}
                  name="Status"
                />
              }
              label={
                remember
                  ? 'This device will be remembered by the system'
                  : 'Click to remember this device'
              }
            />
          </DialogContent>
          <DialogActions>
            <Grid container direction={'column'}>
              {verificationError && (
                <Grid item>
                  <Typography color={'secondary'}>
                    Invalid verification code
                  </Typography>
                </Grid>
              )}

              <Grid item container justifyContent={'space-between'}>
                <Grid item>
                  <Button onClick={handleCodeDialogClose} color="primary">
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={!newCode}
                    onClick={handleCode}
                    color="primary"
                    variant="contained"
                    className='bg-sky-400'
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </motion.div>
    </>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    location: ownProps ? ownProps.location : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthStart: () => dispatch(actions.authStart()),
    onAuthSuccess: (
      name,
      phoneNumber,
      email,
      applicationUpdateEmail,
      token,
      role,
      image,
      id,
      isInternal,
      appUserDetailsId
    ) =>
      dispatch(
        actions.authSuccess(
          name,
          phoneNumber,
          email,
          applicationUpdateEmail,
          token,
          role,
          image,
          id,
          isInternal,
          appUserDetailsId
        )
      ),
    onSetLocalStorageInfo: (response) => actions.setLocalStorageInfo(response),
    onAuthFail: (error) => dispatch(actions.authFail(error)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
