import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @mui/material components
// @mui/icons-material

// core components

// import styles from '../../../assets/jss/material-dashboard-react/components/cardStyle'
// const useStyles = makeStyles(styles);

// #MIGRATION: Variable below used as a error bypass for makeStyles is not a function and temporary fix for the error. useStyles is commented above aswell.
var useStyles

export default function Card(props) {
  // const classes = useStyles()
  const {className, children, plain, profile, chart, ...rest} = props

  return (
    <div
      className={
        'flex flex-wrap text-gray-600 gap-2 items-center justify-between pt-0 '
      }
      {...rest}
    >
      {children}
    </div>
  )
}

Card.propTypes = {
  className: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  chart: PropTypes.bool,
  children: PropTypes.node,
}
