import React from 'react';

import SearchIcon from '@mui/icons-material/Search';
const ProgramSearchBox = ({
  placeholderText,
  initialValue,
  returnValue,
  isDisabled,
}) => {
  return (
    <div className="group flex relative bg-white rounded-lg">
      <input
        disabled={isDisabled}
        type="text"
        value={initialValue}
        onChange={(e) => returnValue(e.target.value)}
        className={`mt-0.5 py-2 w-60 px-2 rounded-lg shadow-md ring-offset-0 outline-none text-sm font-semibold ${
          isDisabled ? 'text-gray-400 ' : 'text-indigo-800'
        }`}
        placeholder={`search ${placeholderText}`}
      />
      <SearchIcon
        size={'32px'}
        className={`absolute ${
          isDisabled ? 'text-indigo-200' : 'text-indigo-600 '
        }  right-0.5 top-1 `}
      />
    </div>
  );
};

export default ProgramSearchBox;
