import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  Switch,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import { Countries, getNext3Years } from '../../../../Util/Util';
import {
  DATASET_CATEGORIES,
  INTAKES,
  STUDY_LEVELS,
  StudentLead_Activities,
  StudentLead_Status,
  yearList,
} from '../../../../Util/Constants';
import { AiFillMail } from 'react-icons/ai';
import { startOfDay, addHours, addMonths } from 'date-fns';
import DatePicker, { registerLocale } from 'react-datepicker';
import Agent from '../../../../api/agent';
//import { intakeYearList } from '../../../Report/Helper/HelperFunction';

const LeadComFiltersOld = ({
  citizenship,
  university,
  course,
  intakeMonth,
  intakeYear,
  studyLevelFilter,
  Name,
  email,
  handleCitizenshipFilterChange,
  handleUniversityChange,
  handleCourseChange,
  handleIntakeMonthChange,
  handleIntakeYearChange,
  handleStudyLevelFilterChange,
  handleNameChange,
  handleClearFilters,
  handleApplyFilters,
  handleApplyFiltersApp,
  handleSendBulkEmail,
  handleEmailChange,
  totalSelected,
  dataSetGroup,
  dataSetGroupFilter,
  handleDatasetGroupFilterChange,
  handleStartDateFilter,
  handleEndDateFilter,
  setStartDateFilter,
  setEndDateFilter,
  startDateFilter,
  endDateFilter,
  handleDatasetChange,
  datasetOption,
}) => {
  const [universityList, setUniversityList] = useState([]);
 
  const Years = getNext3Years();

  useEffect(() => {
    Agent.Universities.list()
      .then((res) => {
        setUniversityList(res);
        console.log('University List', res);
      })
      .catch((err) => {
        console.log('university err', err);
      });
  }, [datasetOption]);
  console.log('datasetOption', datasetOption);
  return (
    <>
      <div className="flex justify-between items-center gap-4">
        <div className="flex flex-row gap-4">
          <div className="flex flex-col">
            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth>
                <Autocomplete
                  value={DATASET_CATEGORIES.find(
                    (dataSet) => dataSet.id == parseInt(datasetOption)
                  )}
                  onChange={handleDatasetChange}
                  options={DATASET_CATEGORIES}
                  className="w-56"
                  getOptionLabel={(DATASET_CATEGORIES) =>
                    DATASET_CATEGORIES.name
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label="Select Dataset"
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <div className="h-24"></div>
          </div>
          {datasetOption != 0 && <div className="h-20 w-1 bg-sky-400"></div>}
        </div>
        <Grid container spacing={6} className="mb-0">
          {datasetOption == 1 ? (
            <div className="flex flex-col ml-12">
              <div className="flex justify-between items-center gap-6">
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      label="Name"
                      size="small"
                      value={Name}
                      onChange={handleNameChange}
                      variant="outlined"
                      fullWidth
                      className="w-64"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      label="Email"
                      size="small"
                      value={email}
                      onChange={handleEmailChange}
                      variant="outlined"
                      fullWidth
                      className="w-64"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        Countries.find(
                          (country) => country.id == citizenship
                        ) || null
                      }
                      onChange={handleCitizenshipFilterChange}
                      options={Countries}
                      className="w-56"
                      getOptionLabel={(country) => country.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Citizenship"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        dataSetGroup.find(
                          (dataSetGroup) =>
                            dataSetGroup.id == parseInt(dataSetGroupFilter)
                        ) || null
                      }
                      onChange={handleDatasetGroupFilterChange}
                      options={dataSetGroup}
                      className="w-64"
                      getOptionLabel={(dataSetGroup) => dataSetGroup.groupName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="DatasetGroup"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </div>
              <div className="flex justify-between items-center mt-4">
                <div className="flex flex-end gap-4">
                  <div>
                    <Grid item xs={12} sm={6} md={2}>
                      <FormControl fullWidth>
                        <DatePicker
                          autoComplete="off"
                          locale="enGB"
                          dateFormat="dd-MM-yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          error={true}
                          onChange={(newVal) => {
                            if (newVal) {
                              newVal = addMonths((newVal), -1);
                            }
                            setStartDateFilter(newVal);
                          }}
                          customInput={
                            <TextField
                              label="Start Date"
                              fullWidth
                              value={startDateFilter}
                              variant="outlined"
                              size="small"
                              className="w-56"
                            />
                          }
                          selected={
                            (startDateFilter && new Date(startDateFilter)) ||
                            null
                          }
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </div>
                  <div>
                    <Grid item xs={12} sm={6} md={2}>
                      <FormControl fullWidth>
                        <DatePicker
                          autoComplete="off"
                          locale="enGB"
                          dateFormat="dd-MM-yyyy"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          error={true}
                          onChange={(newVal) => {
                            if (newVal) {
                              newVal = addMonths((newVal), -1);
                            }
                            setEndDateFilter(newVal);
                          }}
                          customInput={
                            <TextField
                              label="End Date"
                              fullWidth
                              value={endDateFilter}
                              variant="outlined"
                              size="small"
                              className="w-56"
                            />
                          }
                          selected={
                            (endDateFilter && new Date(endDateFilter)) || null
                          }
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </FormControl>
                    </Grid>
                  </div>
                  <Button
                    variant="outlined"
                    className="ml-2"
                    onClick={handleClearFilters}
                  >
                    Clear Filters
                  </Button>

                  <Button
                    variant="contained"
                    className="bg-blue-500 text-white ml-2"
                    onClick={handleApplyFilters}
                  >
                    Apply Filters
                  </Button>

                  <Button
                    variant="contained"
                    className="bg-sky-800 text-white"
                    onClick={handleSendBulkEmail}
                    disabled={totalSelected === 0}
                  >
                    <AiFillMail className="mr-2 text-lg" />
                    Send Bulk
                  </Button>
                </div>
              </div>
            </div>
          ) : datasetOption == 2 ? (
            <div className="flex flex-col items-center mt-0 ml-0">
              <div className="flex justify-between items-center gap-2 ml-12">
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        Countries.find(
                          (country) => country.id == citizenship
                        ) || null
                      }
                      onChange={handleCitizenshipFilterChange}
                      options={Countries}
                      className="w-56"
                      getOptionLabel={(country) => country.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Citizenship"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        universityList.find((univ) => univ.id == university) ||
                        null
                      }
                      onChange={handleUniversityChange}
                      options={universityList}
                      className="w-64"
                      getOptionLabel={(universityList) => universityList.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="select University"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        STUDY_LEVELS.find(
                          (dataSetGroup) =>
                            dataSetGroup.id == parseInt(studyLevelFilter)
                        ) || null
                      }
                      onChange={handleStudyLevelFilterChange}
                      options={STUDY_LEVELS}
                      className="w-56"
                      getOptionLabel={(STUDY_LEVELS) => STUDY_LEVELS.type}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Study Level"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        INTAKES.find(
                          (intake) =>
                          intake.id == parseInt(intakeMonth)
                        ) || null
                      }
                      onChange={handleIntakeMonthChange}
                      options={INTAKES}
                      className="w-48"
                      getOptionLabel={(INTAKES) => INTAKES.type}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Intake Month"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                  <FormControl fullWidth >
                    {/* <InputLabel>Intake Year</InputLabel>
                    <Select
                      size="small"
                      label="Intake Year"
                      className="w-48 ml-2"
                      value={intakeYear}
                      onChange={handleIntakeYearChange}
                      variant="outlined"
                    >
                      {yearList.map((intakeYear) => (
                        <MenuItem key={intakeYear.id} value={intakeYear.year}>
                          {intakeYear.year}
                        </MenuItem>
                      ))}
                    </Select> */}
                     <Autocomplete
                      value={
                        yearList.find(
                          (intake) =>
                          intake.year == parseInt(intakeYear)
                        ) || null
                      }
                      onChange={handleIntakeYearChange}
                      className="w-48"
                      options={yearList}
                      getOptionLabel={(yearList) => yearList.year}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Intake Year"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </div>
              <div className="flex flex-row mt-4 ml-10">
                <div className="flex justify-between items-center gap-2 mr-36">
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <DatePicker
                        autoComplete="off"
                        locale="enGB"
                        dateFormat="dd-MM-yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        error={true}
                        onChange={(newVal) => {
                          if (newVal) {
                            newVal = addMonths((newVal), -1);
                          }
                          setStartDateFilter(newVal);
                        }}
                        customInput={
                          <TextField
                            label="Start Date"
                            fullWidth
                            value={startDateFilter}
                            variant="outlined"
                            size="small"
                            className="w-44 ml-2"
                          />
                        }
                        selected={
                          (startDateFilter && new Date(startDateFilter)) || null
                        }
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <DatePicker
                        autoComplete="off"
                        locale="enGB"
                        dateFormat="dd-MM-yyyy"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        error={true}
                        onChange={(newVal) => {
                          if (newVal) {
                            newVal = addMonths((newVal), -1);
                          }
                          setEndDateFilter(newVal);
                        }}
                        customInput={
                          <TextField
                            label="End Date"
                            fullWidth
                            value={endDateFilter}
                            variant="outlined"
                            size="small"
                            className="w-44 ml-2"
                          />
                        }
                        selected={
                          (endDateFilter && new Date(endDateFilter)) || null
                        }
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={3}>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={
                          dataSetGroup.find(
                            (dataSetGroup) =>
                              dataSetGroup.id == parseInt(dataSetGroupFilter)
                          ) || null
                        }
                        onChange={handleDatasetGroupFilterChange}
                        options={dataSetGroup}
                        className="w-56"
                        getOptionLabel={(dataSetGroup) =>
                          dataSetGroup.groupName
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="DatasetGroup"
                            variant="outlined"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid> */}
                  {/* </div>
                <div className="flex flex-row justify-between gap-2 ml-2"> */}
                  <Button
                    variant="outlined"
                    className="ml-2 w-48"
                    onClick={handleClearFilters}
                  >
                    Clear Filters
                  </Button>

                  <Button
                    variant="contained"
                    className="bg-blue-500 text-white ml-0 w-48"
                    onClick={handleApplyFiltersApp}
                  >
                    Apply Filters
                  </Button>

                  <Button
                    variant="contained"
                    className="bg-sky-800 text-white w-48"
                    onClick={handleSendBulkEmail}
                    disabled={totalSelected === 0}
                  >
                    <AiFillMail className="mr-2 text-lg" />
                    Send Bulk
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </Grid>
      </div>
    </>
  );
};

export default LeadComFiltersOld;
