import React, { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import {
  FormControlLabel,
  DialogActions,
  DialogContent,
  Autocomplete,
  DialogTitle,
  IconButton,
  TextField,
  Divider,
  Button,
  Dialog,
  Switch,
  Paper,
} from '@mui/material';

import Agent from '../../../../api/agent';
import LinearIndeterminate from '../../../Common/Progress/LinearIndeterminate';
import * as actions from '../../../../store/actions/index';
import ConfirmationModal from '../../../Common/Modal/ConfirmationModal';

const PREFIX = 'CourseDetails';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
  chip: `${PREFIX}-chip`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  [`& .${classes.chip}`]: {
    marginTop: 10,
    marginRight: 5,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const CourseDetails = ({
  course,
  setAddMode,
  updateSingleCourse,
  deleteSingleCourse,
  courses,
  departments,
  loadDepts,
}) => {
  const [open, setOpen] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [courseName, setCourseName] = useState(course.name);
  const [courseDuration, setCourseDuration] = useState(course.duration);
  const [courseDescription, setCourseDescription] = useState(
    course.description
  );
  const [status, setStatus] = useState(course.isInactive ? false : true);
  const [isTop, setTop] = useState(course.isTop ? true : false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [universities, setUniversities] = useState([]);
  const [dept, setDept] = useState(course.departmentId);

  useEffect(() => {
    if (departments.length == 0) {
      loadDepts()
        .then(() => {
          setDept(departments.find((item) => item.id == course.departmentId));
        })
        .catch((error) => {
          console.log(error);
          debugger;
        });
    } else {
      setDept(departments.find((item) => item.id == course.departmentId));
    }
  }, [departments]);

  useEffect(() => {
    if (course.id && course.id > 0) {
      Agent.Courses.getCourseGlobal(course.id)
        .then((courseDetails) => {
          setUniversities(courseDetails.universities);
        })
        .catch((error) => {
          console.log(error);
          debugger;
        });
    }
  }, []);

  function handleClose() {
    setOpen(false);
    setAddMode(false);
  }

  const handleFormCancel = () => {
    setOpen(false);
    setAddMode(false);
  };

  const confirmDelete = () => {
    setOpenConfirmDelete(true);
  };

  const deleteCourse = () => {
    setUpdating(true);
    const modifiedCourse = {
      saveCourseDto: {
        id: course.id,
        name: courseName.trim(),
        duration: courseDuration,
        description: courseDescription,
        isInactive: !status,
        isTop: course.isTop,
        toBeDeleted: true,
      },
    };

    Agent.Courses.saveGlobal(modifiedCourse)
      .then(() => {
        deleteSingleCourse(modifiedCourse.saveCourseDto.id);
        setUpdating(false);
        setOpen(false);
        setAddMode(false);
        setOpenConfirmDelete(false);
      })
      .catch((error) => {
        console.log(error);
        setUpdating(false);
        debugger;
      });
  };

  const saveCourse = (event) => {
    event.preventDefault();

    // also check if this course already exists under this uni. check redux store
    if (
      courses.some(
        (item) =>
          item.name.toLowerCase().trim() == courseName.toLowerCase().trim() &&
          item.id != course.id
      )
    ) {
      alert(
        'Another course found with the same name. Check the main list including any disabled course'
      );
      return;
    }

    setUpdating(true);
    const modifiedCourse = {
      saveCourseDto: {
        id: course.id,
        name: courseName.trim(),
        departmentId: dept.id,
        duration: courseDuration,
        description: courseDescription,
        isInactive: !status,
        isTop: isTop,
        toBeDeleted: false,
      },
    };

    Agent.Courses.saveGlobal(modifiedCourse)
      .then(() => {
        updateSingleCourse({
          id: modifiedCourse.saveCourseDto.id,
          name: modifiedCourse.saveCourseDto.name,
          departmentId: dept.id,
          duration: modifiedCourse.saveCourseDto.duration,
          description: modifiedCourse.saveCourseDto.description,
          isInactive: !status,
          isTop: isTop,
        });
        setUpdating(false);
        setOpen(false);
        setAddMode(false);
      })
      .catch((error) => {
        console.log(error);
        setUpdating(false);
        debugger;
      });
  };

  return (
    <Root>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperComponent}
        maxWidth="sm"
        fullWidth
      >
        <>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <div className="flex items-center justify-between">
              <div className="font-semibold text-xl text-indigo-600">
                Edit Course
              </div>
              <div>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleFormCancel}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            <Divider />
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col gap-3">
              <div className="pt-2">
                <TextField
                  label="Course Name"
                  fullWidth
                  onChange={(e) => {
                    setCourseName(e.target.value);
                  }}
                  value={courseName}
                  variant="outlined"
                  size="small"
                />
              </div>
              <div className="flex gap-2 ">
                {departments.length > 0 && (
                  <div className="w-[70%] ">
                    <Autocomplete
                      style={{ width: '100%' }}
                      size="small"
                      value={dept}
                      onChange={(_, newValue) => {
                        setDept(newValue);
                      }}
                      options={departments}
                      getOptionLabel={(option) => option.name || ''}
                      sx={{ width: 300 }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          label="Department/Subject"
                        />
                      )}
                    />
                  </div>
                )}
                <div className="w-[30%]">
                  <TextField
                    label="Course Duration"
                    fullWidth
                    type="number"
                    InputProps={{
                      inputProps: {
                        max: 10,
                        min: 0,
                      },
                    }}
                    onChange={(e) => {
                      setCourseDuration(e.target.value);
                    }}
                    value={courseDuration}
                    variant="outlined"
                    size="small"
                  />
                </div>
              </div>
              <div className="w-[100%] ">
                <TextField
                  label="Course Description"
                  fullWidth
                  minRows={2}
                  maxRows={3}
                  multiline={true}
                  onChange={(e) => {
                    setCourseDescription(e.target.value);
                  }}
                  value={courseDescription}
                  variant="outlined"
                />
              </div>

              <div>
                <div className="flex flex-col gap-1 p-0">
                  <FormControlLabel
                    className="w-36 "
                    control={
                      <Switch
                        checked={isTop}
                        onChange={(e) => setTop(e.target.checked)}
                        name="isTop"
                      />
                    }
                    label={isTop ? 'Marked top' : 'Mark top'}
                  />
                  <FormControlLabel
                    className="w-12 "
                    control={
                      <Switch
                        checked={status}
                        onChange={(e) => setStatus(e.target.checked)}
                        name="Status"
                      />
                    }
                    label={status ? 'Active' : 'Inactive'}
                  />
                </div>
              </div>
            </div>
            {universities.length > 0 && (
              <div>
                <div className="text-indigo-700 font-medium">
                  {`${
                    universities.length == 1
                      ? 'Following university is linked to this course'
                      : 'Following ' +
                        universities.length +
                        ' universities are linked to this course'
                  }`}
                </div>

                {universities.map((uni) => (
                  <div className="text-gray-700 font-semibold" key={uni.id}>
                    {uni.name}
                  </div>
                ))}
              </div>
            )}
            {updating && (
              <div>
                <LinearIndeterminate />
              </div>
            )}
            <DialogActions>
              <div className="flex justify-between w-full pt-4">
                <div>
                  <Button
                    onClick={confirmDelete}
                    variant="contained"
                    className="bg-rose-200 hover:bg-rose-400 text-gray-600 hover:text-gray-800"
                  >
                    Delete Course
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={saveCourse}
                    variant="contained"
                    className="bg-sky-400 hover:bg-sky-500"
                  >
                    Submit Changes
                  </Button>
                </div>
              </div>
            </DialogActions>
          </DialogContent>
        </>
      </Dialog>
      <ConfirmationModal
        open={openConfirmDelete}
        cancel={setOpenConfirmDelete}
        confirm={deleteCourse}
        confirmText="Confirm Deletion"
        content={
          'Are you sure you would like to remove this course from the system?'
        }
      />
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    departments: state.departments,
    courses: state.courses,
    isInternal: state.auth.isInternal,
    role: state.auth.roleName,
    course: ownProps.course,
    universityId: ownProps.universityId,
    setAddMode: ownProps.setAddMode,
    updateParent: ownProps.updateParent,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSingleCourse: (course) =>
      dispatch(actions.updateSingleCourse(course)),
    deleteSingleCourse: (id) => dispatch(actions.deleteSingleCourse(id)),
    loadDepts: () => dispatch(actions.loadDepts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);
