import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SchoolIcon from '@mui/icons-material/School';
import DescriptionIcon from '@mui/icons-material/Description';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import DoneIcon from '@mui/icons-material/Done';
import BlockIcon from '@mui/icons-material/Block';
import CancelIcon from '@mui/icons-material/Cancel';

import Agent from '../../../../api/agent';
import CustomisedCard from '../../../Common/Card/CustomisedCardAgent';
import { AnimateZoomIn } from '../../../Common/Animation/Animation';
import { Typography } from '@mui/material';

const SummaryCounts = () => {
  const navigate = useNavigate();

  const [studentCount, setStudentCount] = useState();
  const [studentLoading, setStudentLoading] = useState(true);
  const [Applications, setApplications] = useState();
  const [applicationCount, setApplicationCount] = useState();
  const [applicationLoading, setApplicationLoading] = useState(true);
  const [conversionCount, setConversionCount] = useState();
  const [conversionLoading, setConversionLoading] = useState(true);

  const iconSize = '5em';

  useEffect(() => {
    Agent.Students.listdashboard()
      .then(({ total }) => {
        setStudentCount(total);
        setStudentLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setStudentLoading(false);
      });
  }, []);

  useEffect(() => {
    Agent.Applications.listdashboardAgent()
      .then(({ total, records, conversionApplications }) => {
        setApplicationCount(total);
        setApplications(records);
        setApplicationLoading(false);
        setConversionCount(conversionApplications.length);
        setConversionLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setApplicationLoading(false);
      });
  }, []);

  return (
    <div className='flex flex-wrap gap-8'>
      <div className='w-[50%] sm:w-[45%] lg:w-[30%] lg:max-w-[330px]'>
        <CustomisedCard className='bg-blue-100'>
          <div className='text-sky-500'>
            <SchoolIcon size={iconSize} />
          </div>
          <div className='inline-flex items-end flex-col font-semibold text-lg'>
            <p>Student Count</p>
            {!studentLoading ? (
              <AnimateZoomIn>
                <button
                  className='text-5xl font-bold text-sky-500'
                  onClick={() => navigate('/students')}
                >
                  {studentCount}
                </button>
              </AnimateZoomIn>
            ) : (
              <Typography color='secondary' variant='caption'>
                Loading..
              </Typography>
            )}
          </div>
        </CustomisedCard>
      </div>
      <div className='w-[45%] lg:w-[30%] lg:max-w-[330px]'>
        <CustomisedCard className='bg-green-100'>
          <div className='text-sky-500'>
            <DescriptionIcon size={iconSize} />
          </div>
          <div className='inline-flex items-end flex-col font-semibold text-lg'>
            <p>App Count</p>
            {!applicationLoading ? (
              <AnimateZoomIn>
                <button
                  className='text-5xl font-bold text-sky-500'
                  onClick={() => navigate('/applications')}
                >
                  {applicationCount}
                </button>
              </AnimateZoomIn>
            ) : (
              <Typography color='secondary' variant='caption'>
                Loading..
              </Typography>
            )}
          </div>
        </CustomisedCard>
      </div>
      <div className='w-[45%] lg:w-[30%] lg:max-w-[330px]'>
        <CustomisedCard className='bg-yellow-100'>
          <div className='text-sky-500'>
            <FlightTakeoffIcon size={iconSize} />
          </div>
          <div className='inline-flex items-end flex-col font-semibold text-lg'>
            <p>Total Conversion</p>
            {!conversionLoading ? (
              <AnimateZoomIn>
                <button
                  className='text-5xl font-bold text-sky-500'
                  onClick={() => navigate('/students')}
                >
                  {conversionCount}
                </button>
              </AnimateZoomIn>
            ) : (
              <Typography color='secondary' variant='caption'>
                Loading..
              </Typography>
            )}
          </div>
        </CustomisedCard>
      </div>
      <div className='w-[45%] lg:w-[30%] lg:max-w-[330px]'>
        <CustomisedCard className='bg-purple-100'>
          <div className='text-sky-500'>
            <DoneIcon size={iconSize} />
          </div>
          <div className='inline-flex items-end flex-col font-semibold text-lg'>
            <p>Offer Holder</p>
            {!applicationLoading ? (
              <AnimateZoomIn>
                <button
                  className='text-5xl font-bold text-sky-500'
                  onClick={() => navigate('/students')}
                >
                  {Applications.filter(
                    (app) => app.status === 2 || app.status === 3
                  ).length}
                </button>
              </AnimateZoomIn>
            ) : (
              <Typography color='secondary' variant='caption'>
                Loading..
              </Typography>
            )}
          </div>
        </CustomisedCard>
      </div>
      <div className='w-[45%] lg:w-[30%] lg:max-w-[330px]'>
        <CustomisedCard className='bg-red-100'>
          <div className='text-sky-500'>
            <BlockIcon size={iconSize} />
          </div>
          <div className='inline-flex items-end flex-col font-semibold text-lg'>
            <p>Visa Rejected</p>
            {!applicationLoading ? (
              <AnimateZoomIn>
                <button
                  className='text-5xl font-bold text-sky-500'
                  onClick={() => navigate('/students')}
                >
                  {Applications.filter((app) => app.status === 18).length}
                </button>
              </AnimateZoomIn>
            ) : (
              <Typography color='secondary' variant='caption'>
                Loading..
              </Typography>
            )}
          </div>
        </CustomisedCard>
      </div>
      <div className='w-[45%] lg:w-[30%] lg:max-w-[330px]'>
        <CustomisedCard className='bg-gray-100'>
          <div className='text-sky-500'>
            <CancelIcon size={iconSize} />
          </div>
          <div className='inline-flex items-end flex-col font-semibold text-lg'>
            <p>App Rejected</p>
            {!applicationLoading ? (
              <AnimateZoomIn>
                <button
                  className='text-5xl font-bold text-sky-500'
                  onClick={() => navigate('/students')}
                >
                  {Applications.filter((app) => app.status === 4).length}
                </button>
              </AnimateZoomIn>
            ) : (
              <Typography color='secondary' variant='caption'>
                Loading..
              </Typography>
            )}
          </div>
        </CustomisedCard>
      </div>
    </div>
  );
};

export default SummaryCounts;
