import React, { useEffect, useState } from 'react';
/* eslint-disable react/display-name */
import { Typography, Grid, Button, IconButton, Link } from '@mui/material';
// import Agent from '../../../../api/agent';
import ConfirmationModal from '../../../Common/Modal/ConfirmationModal';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import AddIcon from '@mui/icons-material/Add';
import AddEditMethod from './AddEditMethod';
import {
  Application_Methods,
  ApplicationMethodPortalTypes,
  MUI_LICENSE_KEY,
} from '../../../../Util/Constants';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddForm from './AddForm';
import { DownloadCloudFile } from '../../../../Util/FileProcessing';
import { LicenseInfo, DataGridPro } from '@mui/x-data-grid-pro';
import Agent from '../../../../api/agent';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

const ApplicationMethods = ({
  universityId,
  courseLevels,
  loadCourseLevels,
  role,
  permissions,
}) => {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [methods, setMethods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addMode, setAddMode] = useState(false);
  const [addFormMode, setAddFormMode] = useState(false);
  const [method, setMethod] = useState({
    id: 0,
    universityId,
    courseLevelId: 1,
    portalType: 1,
    method: 1,
  });

  useEffect(() => {
    if (selectionModel.length > 0) {
      const mtd = methods.find((x) => x.id == selectionModel[0]);
      setMethod(mtd);
    }
  }, [selectionModel]);

  useEffect(() => {
    if (courseLevels.length == 0) {
      loadCourseLevels();
    }
  }, [courseLevels]);

  useEffect(() => {
    if (courseLevels.length > 0) {
      getRecords();
    }
  }, [courseLevels]);

  const getRecords = () => {
    Agent.Universities.listApplicationMethods(universityId)
      .then((list) => {
        let rows = [];
        list.map((item) => {
          rows.push({
            id: item.id,
            universityId: universityId,
            courseLevelId: item.courseLevelId,
            level: courseLevels.find(({ id }) => id === item.courseLevelId)
              .name,
            method: item.method,
            methodName: Application_Methods.find(({ id }) => id === item.method)
              .name,
            portalType: item.portalType,
            type: ApplicationMethodPortalTypes.find(
              ({ id }) => id === item.portalType
            ).name,
            url: item.url,
            login: item.login,
            password: item.password,
            applicationForm: item.applicationForm,
            description: item.description,
          });
        });
        setMethods(rows);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        debugger;
      });
  };

  let columns = [
    {
      field: 'level',
      headerName: 'Course Level',
      flex: .5,
    },
    {
      field: 'methodName',
      headerName: 'Method',
      flex: .5,
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: .5,
    },
    {
      field: 'url',
      headerName: 'Credentials',
      flex: 2,
      renderCell: (params) =>
        params.row.url && (
          <Grid container direction="column">
            <Grid item>
              <Typography
                component={Link}
                href={params.row.url}
                target="_blank"
              >
                Click Here
              </Typography>
            </Grid>
            {(params.row.login || params.row.password) && (
              <Grid item container>
                <Grid item>
                  <Typography style={{ fontSize: '.75rem' }}>
                    Username:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="title" color="inherit" noWrap>
                    &nbsp;&nbsp;
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography color="secondary" style={{ fontSize: '.75rem' }}>
                    {params.row.login}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="title" color="inherit" noWrap>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography style={{ fontSize: '.75rem' }}>
                    Password:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="title" color="inherit" noWrap>
                    &nbsp;&nbsp;
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography color="secondary" style={{ fontSize: '.75rem' }}>
                    {params.row.password}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
        ),
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
    },
    {
      field: 'manage',
      headerName: 'Form',
      flex: 1,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <strong>
          {params.row.applicationForm == null ? (
            (role.isSuperAdmin || permissions.CanManageUniversity) &&
            params.row.method == 3 ? (
              <IconButton
                // color="primary"
                aria-label="close"
                onClick={() => {
                  setMethod(params.row);
                  setAddFormMode(true);
                }}
                size="large"
              >
                <CloudUploadIcon />
              </IconButton>
            ) : null
          ) : (
            <Grid container>
              <Grid item xs={6}>
                <IconButton
                  color="primary"
                  aria-label="close"
                  onClick={() =>
                    DownloadCloudFile(
                      params.row.applicationForm.fileNameLocal,
                      params.row.applicationForm.fileName,
                      params.row.applicationForm.containerName
                    )
                  }
                  size="large"
                >
                  <CloudDownloadIcon />
                </IconButton>
              </Grid>
              {(role.isSuperAdmin || permissions.CanManageUniversity) && (
                <Grid item xs={6}>
                  <IconButton
                    color="secondary"
                    aria-label="close"
                    onClick={() => {
                      setMethod(params.row);
                      setOpenConfirm(true);
                    }}
                    size="large"
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          )}
        </strong>
      ),
    },
  ];

  const addFile = (id, file) => {
    setAddFormMode(false);
    let temp = [];
    console.log('methods',methods)
    methods.forEach((m) => {
      console.log('methods',m)

      if (m.id == id) {
        m.applicationForm = {
          fileName: file.fileName,
          fileNameLocal: file.fileNameLocal,
          containerName: file.containerName,
        };
      }
      temp.push(m);
    });
    setMethods(temp);
  };

  const deleteForm = () => {
      Agent.CloudFiles.delete(method?.applicationForm.fileName,
        0,
        0,
        0,
        universityId,
        method.id)
        .then(() => {
          setOpenConfirm(false);
          let temp = [];
          methods.forEach((m) => {
            if (m.id == method.id) {
              m.applicationForm = null;
            }
            temp.push(m);
          });
          setMethods(temp);
        })
        .catch((error) => {
          console.log(error);
          //debugger;
        });
  };

  return (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography color="primary" textAlign="center">
            Application Methods
          </Typography>
        </Grid>
        <Grid item container justifyContent="space-between">
          {(role.isSuperAdmin || permissions.CanManageUniversity) && (
            <Grid item>
              <Button
                LinkComponent
                variant="contained"
                className="bg-red-900"
                startIcon={<AddIcon />}
                onClick={() => {
                  setMethod({
                    id: 0,
                    universityId,
                    courseLevelId: 1,
                    portalType: 1,
                    method: 1,
                  });
                  setAddMode(true);
                }}
              >
                Add Application Method
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              disabled={method.id == 0}
              LinkComponent
              variant="contained"
              color="primary"
              className="bg-yellow-700"
              startIcon={<AddIcon />}
              onClick={() => setAddMode(true)}
            >
              View/Edit
            </Button>
          </Grid>
        </Grid>
        {/* {!loading && (
          <Grid item container direction="column">
            {methods.map((mtd) => (
              <Grid item key={mtd.id} container>
                <Grid item>{`${mtd.url} ${mtd.courseLevelId}`}</Grid>
              </Grid>
            ))}
          </Grid>
        )} */}
        <Grid item>
          <DataGridPro
            rowHeight={60}
            autoHeight
            // disableSelectionOnClick
            onSelectionModelChange={setSelectionModel} //onRowSelection use for datagridpro v6 and onselection is use for current datagrid 
            selectionModel={selectionModel}
            rows={methods}
            columns={columns}
            empt
          />
        </Grid>
      </Grid>
      {addMode && (
        <AddEditMethod
          methodParent={method}
          setAddMode={setAddMode}
          updateParent={getRecords}
        />
      )}
      {addFormMode && (
        <AddForm
          setAddMode={setAddFormMode}
          method={method}
          addToParent={addFile}
        />
      )}
      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={deleteForm}
        confirmText="Confirm Deletion"
        content="Are you sure that you would like to delete this document?"
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    universityId: ownProps.universityId,
    courseLevels: state.management.courseLevels,
    role: state.auth.role,
    permissions: state.auth.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadCourseLevels: () => dispatch(actions.loadCourseLevels()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationMethods);
