import React, { useEffect, useState, useContext } from 'react';

import DefaultUniImage from '../../../../assets/images/student-portal/defaultuniversity.png';
import MySnackbarAlert from '../../../Common/AlertMessage/MySnackbarAlert';
import { INTAKES, STUDY_LEVELS } from '../../../../Util/Constants';
import HeadlessModal from '../../../Custom/Modal/headlessModal';
import UserContext from '../../../../context/user-context';
import { Countries } from '../../../../Util/Util';
import Agent from '../../../../api/agent';


const ProgramDetails = ({ program = {},student }) => {
  const [studyOptions, setStudyOptions] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);

  //#snackbar stuff
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [alertMsg, setAlertMsg] = useState('Hello from snackbar');
  //#endregion
console.log("student",student);
  const userContext = useContext(UserContext);

  useEffect(() => {
    const updatedStudyOptions = [];

    if (program.intake) {
      updatedStudyOptions.push({
        name: 'intake',
        value: INTAKES.find(({ id }) => id == program.intake).type,
      });
    }
    if (program.countryId) {
      updatedStudyOptions.push({
        name: 'country',
        value: Countries.find(({ id }) => id == program.countryId).name,
      });
    }
    if (program.duration) {
      updatedStudyOptions.push({
        name: 'duration',
        value: `${program.duration} ${
          program.duration == 1 ? 'year' : 'years'
        }`,
      });
    }
    if (program.tuitionFee) {
      updatedStudyOptions.push({
        name: 'tuition Fee',
        value: program.tuitionFee,
      });
    }
    if (program.studyLevelId) {
      updatedStudyOptions.push({
        name: 'study Level',
        value: STUDY_LEVELS.find(({ id }) => id == program.studyLevelId).type,
      });
    }
    if (program.isScholarshipAvailable) {
      updatedStudyOptions.push({
        name: 'Scholarship',
        value: program.isScholarshipAvailable,
      });
    }

    setStudyOptions(updatedStudyOptions);
  }, [program]);

  const openApplyModal = () => {
    setModalOpen(true);
  };

  const handleApply = () => {
    console.log(program);
    const applicationId = userContext.applicationId;
    const studentId = 0;

    Agent.Applications.add(studentId, program.courseAllocationId, applicationId)
      .then((id) => {
        setOpenSuccess(true);
        setOpenFailure(false);
        setAlertMsg('Application Successfully Submitted');
        console.log('Successful Application, id:', id);
      })
      .catch((error) => {
        console.log(error);
        const errorStatus = error.split('-')[1];
        setOpenFailure(true);
        setOpenSuccess(false);
        setAlertMsg(`${errorStatus}`);
      });

    setModalOpen(false);
  };

  return (
    <div>
      <div className="flex flex-col items-center gap-5">
        <div className="flex gap-3 items-center text-xl font-medium ">
          <div className="bg-sky-00 w-12 h-12 flex items-center">
            {program?.fileUrl && (
              <img alt="Uni Logo" src={program.fileUrl} className="rounded " />
            )}
          </div>
          <div>{program?.universityName}</div>
        </div>
        <div className="text-4xl font-semibold text-gray-800 max-w-[90%] text-center">
          {program?.courseName}
        </div>
        <div className="text-xl font-medium text-gray-800 max-w-[90%] text-center flex justify-center">
          <img
            alt="Uni Logo"
            src={
              program.fileUrlBackground
                ? program.fileUrlBackground
                : DefaultUniImage
            }
            className=" w-[800px] h-60 object-none rounded-xl drop-shadow-xl"
          />
        </div>
        <button
          className="inline-flex justify-center rounded-md border border-transparent bg-violet-100 px-7 py-2 text font-semibold text-violet-800 hover:bg-violet-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-violet-500 focus-visible:ring-offset-2 text-xl "
          onClick={openApplyModal}
        >
          Start Application
        </button>
        <div className="flex flex-col items-center gap-1">
          <div className="text-lg font-medium">Study Options</div>
          <div className="w-8 h-1.5 rounded-full bg-sky-400"></div>
          <div className="grid grid-cols-3 content-center  gap-5 text-xl font-medium min-h-[220px] xl:w-[800px] p-4 shadow-lg rounded-xl bg-indigo-50 mt-2">
            {studyOptions.map((option, index) => {
              return (
                <div key={index}>
                  <div className="cursor-default bg-indigo-100 text-indigo-800 p-1.5 xl:p-3 rounded-xl min-w-[140px] flex flex-col items-center capitalize">
                    <div className="font-semibold">{`${option.name}`}</div>
                    <div className="text-center text-gray-600">{`${option.value}`}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col items-center gap-1">
          <div className="text-lg font-medium">Course Details</div>
          <div className="w-8 h-1.5 rounded-full bg-sky-400"></div>
          <div className=" xl:w-[800px] px-3 text-justify">
            {program?.description}
          </div>
          {program.infoLink && (
            <div className="p-3  text-indigo-500 hover:text-indigo-600  transition-all duration-150 py-2 text-gray-0 text-xl font-medium">
              <a target="_blank" href={program.infoLink} rel="noreferrer">
                Visit course link for more info
              </a>
            </div>
          )}
        </div>
        {isModalOpen && (!student.profileComplete? 
        <HeadlessModal
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            program={program}
            secondaryText={program.courseName}
            primaryText={'Please Complete Your Profile'}
            
          />:
          <HeadlessModal
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            program={program}
            secondaryText={program.courseName}
            primaryText={'Start your application process for'}
            handleChange={handleApply}
          />
        )}
       
      </div>
      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
        />
      )}
     
    </div>
     
  );
};

export default ProgramDetails;
