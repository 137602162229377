import * as actionTypes from './actionTypes';
import Agent from '../../api/agent';

const loadPartnersSuccess = (partners) => {
  return {
    type: actionTypes.MANAGEMENT_PARTNERS_LOAD,
    payload: {
      partners,
    },
  };
};

export const loadPartners = () => async (dispatch) => {
  const partners = await Agent.Management.listPartners();
  dispatch(loadPartnersSuccess(partners));
  return partners;
};

const loadCourseLevelsuccess = (courseLevels) => {
  return {
    type: actionTypes.MANAGEMENT_COURSE_LEVELS_LOAD,
    payload: {
      courseLevels,
    },
  };
};

const loadLanguageTestsSuccess = (languageTests) => {
  return {
    type: actionTypes.MANAGEMENT_LANGUAGE_TESTS_LOAD,
    payload: {
      languageTests,
    },
  };
};

export const loadCourseLevels = () => async (dispatch) => {
  const courseLevels = await Agent.Management.listCourseLevels();
  dispatch(loadCourseLevelsuccess(courseLevels));
  return courseLevels;
};

export const loadLanguageTests = () => async (dispatch) => {
  const languageTests = await Agent.Management.listLanguageTests();
  dispatch(loadLanguageTestsSuccess(languageTests));
  return languageTests;
};
