// LOGIC TO GET REGINAL AND DESTINATION WISE DATA FROM API CALL
export const RegionalData = (data) => {
  var tempResult = {};

  for (let { universityCountry } of data)
    tempResult[universityCountry] = {
      universityCountry,
      count: tempResult[universityCountry]
        ? tempResult[universityCountry].count + 1
        : 1,
    };
  let result = Object.values(tempResult);
  return result.sort((a, b) => {
    return b.count - a.count;
  });
};

export const DestinationData = (arr) => {
  var tempResult = {};

  for (let { studentCountry } of arr)
    tempResult[studentCountry] = {
      studentCountry,
      count: tempResult[studentCountry]
        ? tempResult[studentCountry].count + 1
        : 1,
    };
  let result = Object.values(tempResult);
  return result.sort((a, b) => {
    return b.count - a.count;
  });
};
