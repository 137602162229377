import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Agent from '../../../api/agent';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import ContactTopNavBar from './ContactTopNavBar';
import ContactSideNav from './ContactSideNav';
import GeneralInfo from './GeneralInfo';
import MessageDisplay from '../../Leads/LeadUtils/MessageDisplay/MessageDisplay ';
import ContactNotes from './ContactNotes';
import ContactDocuments from './ContactDocuments/ContactDocuments';
import ContactMail from './ContactMail';

const ContactProfile = () => {
  const [activeComponent, setActiveComponent] = useState('basicinfo');
  const [userData, setUserData] = useState(null);
  const { id } = useParams();
  const numId = parseInt(id);
  const [users, setUsers] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [contactNotes, setContactNotes] = useState([]);

  const [contactNumber, setContactNumber] = useState(
    userData?.contactNumber || ''
  );

  //console.log('contactprofile', users);
  useEffect(() => {
    Agent.Users.list()
      .then((res) => {
        setUsers(res);
        // console.log('user List', res);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await Agent.GlobalContacts.getContactProfile(numId);
        console.log(response);
        setUserData(response);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [numId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    setIsEditMode(true);
  };

  const handlePhoneNumberChange = (value) => {
    setContactNumber(value);
    setUserData({ ...userData, contactNumber: value });
  };

  const handleUpdateClick = async () => {
    try {
      const payload = {
        Id: numId,
        ContactName: userData?.contactName,
        EmailAddress: userData?.emailAddress,
        Email: userData?.email,
        Address: userData?.address,
        Website: userData?.website,
        IsDisabled: userData?.isDisabled || false,
        CountryId: userData?.countryId,
        TypeOfContact: userData?.typeOfContact,
        ServiceType: userData?.serviceType,
        IsMeetingAtEvent: userData?.isMeetingAtEvent,
        EventName: userData?.eventName,
        EventLocation: userData?.eventLocation,
        EventYear: userData?.eventYear,
        IsEventAttendee: userData?.isEventAttendee,
        ContactPerson: userData?.contactPerson,
        Designation: userData?.designation,
        ContactNumber: userData?.contactNumber,
        FollowUpMeetingNote: userData?.followUpMeetingNote,
        Description: userData?.description,
        Department: userData?.department,
        CreatedBy: userData?.createdBy,
        CreatedOn: userData?.createdOn,
        UpdatedBy: userData?.updatedBy,
        UpdatedOn: userData?.updatedOn,
        LastEmailSentDate: userData?.lastEmailSentDate,
        EmailSentBy: userData?.emailSentBy,
        AssessmentTeamId1: userData?.assessmentTeamId1,
        AssessmentTeamId2: userData?.assessmentTeamId2,
        AssessmentTeamId3: userData?.assessmentTeamId3,
        AssessmentTeamId4: userData?.assessmentTeamId4,
        AssessmentTeamId5: userData?.assessmentTeamId5,
        AssessmentTeamId6: userData?.assessmentTeamId6,
        AssessmentTeamId7: userData?.assessmentTeamId7,
        ContactManagementNotes: userData?.contactManagementNotes,
        ContactManagementCloudFiles: userData?.contactManagementCloudFiles,
        ContactLeadStatus: userData?.contactLeadStatus,
      };

      const response = await Agent.GlobalContacts.updateContactProfile(payload);

      if (response) {
        setSuccessMessage('Contact profile updated successfully');
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
        setIsEditMode(false);
      } else {
        console.error('Failed to update profile');
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      setErrorMessage('Error updating profile:', error.message);
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-sky-200 to-sky-300">
      <ContactTopNavBar
        contactName={userData?.contactName}
        users={users}
        salesUser={userData?.assessmentTeamId1}
        assessmentTeamLeader={userData?.assessmentTeamId3}
        contactLeadStatus={userData?.contactLeadStatus}
        handleInputChange={handleInputChange}
        userData={userData}
        setUserData={setUserData}
      />
      <div className="flex flex-col md:flex-row min-h-screen bg-sky-100 p-5">
        <div className="w-full md:w-1/6 pr-0 md:pr-4 mb-4 md:mb-0">
          <ContactSideNav setActiveComponent={setActiveComponent} />
        </div>

        <div className="w-full md:w-4/5 pl-0 md:pl-4">
          <div>
            <main className="bg-white p-8 rounded-md shadow-lg">
              {activeComponent === 'basicinfo' && (
                <GeneralInfo
                  userData={userData}
                  handleInputChange={handleInputChange}
                  handleEditClick={handleEditClick}
                  isEditMode={isEditMode}
                  handlePhoneNumberChange={handlePhoneNumberChange}
                  handleUpdateClick={handleUpdateClick}
                />
              )}

              {activeComponent === 'documents' && (
                <ContactDocuments
                  contactManagementId={numId}
                  studentLeadCloudFiles={userData?.contactManagementCloudFiles}
                />
              )}

              {activeComponent === 'email' && (
                // <ContactNotes
                //   summmaryNoteRecords={userData?.contactManagementNotes?.filter(
                //     (item) => item.noteType == 2
                //   )}
                //   handleAddSummaryRecord={setContactNotes}
                //   leadId={numId}
                //   users={users}
                //   userData={userData}
                // />
                <ContactMail
                  contactId={numId}
                  messageRecords={userData?.contactManagementNotes?.filter(
                    (item) => item.noteType == 1
                  )}
                  users={users}
                  contactName={userData?.contactName}
                  handleAddSummaryRecord={setContactNotes}
                />
              )}
              {activeComponent === 'notes' && (
                <ContactNotes
                  summmaryNoteRecords={userData?.contactManagementNotes?.filter(
                    (item) => item.noteType == 2
                  )}
                  handleAddSummaryRecord={setContactNotes}
                  leadId={numId}
                  users={users}
                  userData={userData}
                />
              )}
              {/* 
              {activeComponent !== 'documents' &&
                activeComponent !== 'summaryinfo' &&
                activeComponent !== 'externalNotes' &&
                activeComponent !== 'accountsNotes' &&
                activeComponent !== 'leadMessages' &&
                activeComponent !== 'destinationInfo' &&
                activeComponent !== 'activity' &&
                activeComponent !== 'chats' && (
                  <button
                    type="button"
                    className="min-w-fit py-2 px-6 mt-6 bg-indigo-500 hover:bg-indigo-600 focus:ring-indigo-400 focus:ring-offset-indigo-200 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={handleUpdateClick}
                  >
                    Update Contact Profile
                  </button>
                )} */}

              {successMessage && (
                <MessageDisplay
                  type="success"
                  message="Profile updated successfully!"
                  duration={3000}
                  onClose={() => setSuccessMessage(false)}
                />
              )}

              {errorMessage && (
                <MessageDisplay
                  type="error"
                  message={`Failed to update lead` + errorMessage}
                  duration={5000}
                  onClose={() => setErrorMessage('')}
                />
              )}
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    departments: state.departments,
    name: state.auth.name,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactProfile);
