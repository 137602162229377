import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import { Grid, Typography } from '@mui/material';
import Agent from '../../../../api/agent';

const AgentAssignment = ({ userId }) => {
  const [agents, setAgents] = useState([]);
  const [agentLoaded, setAgentLoaded] = useState(false);

  useEffect(() => {
    Agent.UserAssignment.listUserAgents(userId)
      .then((agents) => {
        setAgents(agents);
        setAgentLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setAgentLoaded(true);
        debugger;
      });
  }, []);

  return (
    <Grid container>
      <Grid item>
        {agents.length > 0
          ? agents.map((agent, index) => (
              <Chip key={index} label={agent} color="secondary" />
            ))
          : null}
      </Grid>
      {agents.length == 0 && agentLoaded && (
        <Grid item>
          <Typography>No agents assigned to this user yet</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AgentAssignment;
