import * as actionTypes from '../actions/actionTypes';
// import { updateObject } from '../utility';
import initialState from './initialState';

const reducer = (state = initialState.courses, action) => {
  switch (action.type) {
    case actionTypes.COURSES_LOADING_SUCCESS:
      return action.payload.courses;
    case actionTypes.COURSES_ADD:
      return addCourses(state, action.payload);
    case actionTypes.COURSES_UPDATE_COURSE:
      return updateSingleCourse(state, action.payload);
    case actionTypes.COURSES_DELETE:
      return deleteSingleCourse(state, action.payload);
    default:
      return state;
  }
};

const addCourses = (state, payload) => {
  return state.concat(payload.courses);
};

const updateSingleCourse = (state, payload) => {
  if (state.length === 0) return state;
  var updatedState = state.map((item) => {
    if (item.id === payload.course.id) {
      return payload.course;
    }
    return item;
  });

  return updatedState;
};

const deleteSingleCourse = (state, payload) => {
  if (state.length === 0) return state;
  return state.filter((item) => item.id != payload.id);
};

export default reducer;
