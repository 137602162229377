import React from "react";

import ProfileHeader from "../../common/profileHeader";
import MainSection from "./MainSection";
import SideNav from "./SideNav";

const StudentHomePage = () => {
  return ( <> 
  
      <MainSection />
     
    </>
  );
};

export default StudentHomePage;
