import classNames from 'classnames'
import React from 'react'

const CustomisedCard = (props) => {
  const {children, agentDashboard = false} = props

  return (
    <div
      className={` w-full bg-gradient-to-l to-gray-200/80 from-gray-300/70 rounded-md mb-0 p-4`}
    >
      <div className='flex justify-between'> {children}</div>
    </div>
  )
}

export default CustomisedCard
