import React, { useState, useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import AvatarMui from '../Common/Image/AvatarMui';
import { Email, LocationOn } from '@mui/icons-material';
import Agent from '../../api/agent';
import PhoneIcon from '@mui/icons-material/Phone';
import { Countries } from '../../Util/Util';

const PREFIX = 'MessageList';

const classes = {
  dialog: `${PREFIX}-dialog`,
};

const Root = styled('div')({
  [`& .${classes.dialog}`]: {
    // position: 'absolute',
    // right: 10,
    // top: 50,
  },
});

const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <Root className={classes.root} {...other}>
      <Typography variant="h6" component="span">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </Root>
  );
};

const MessageProfile = ({ agent, userProfile, openMessage, isMessageOpen }) => {
  const [agentProfileImage, setAgentProfileImage] = useState({});

  console.log('agent prof', agent);
  console.log('userProfile prof', userProfile);

  const downloadFile = async (fileNameLocal, fileName, containerName) => {
    await Agent.CloudFiles.download(fileNameLocal, fileName, containerName)
      .then((response) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: 'agent/octet-stream' })
        );
        link.download = fileNameLocal ? fileNameLocal : fileName;
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(link);
        }, 200);
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  useEffect(() => {
    setAgentProfileImage(
      agent?.agentCloudFiles?.filter((item) => item.documentType == 4)
    );
  }, [agent]);

  return (
    <>
      {' '}
      {isMessageOpen && (
        <>
          {agent.id ? (
            <>
              <div className="flex bg-blue-100 pt-12 justify-between items-top h-[840px]">
                <div></div>
                <div>
                  <AvatarMui
                    imageUrl={
                      agentProfileImage?.length > 0
                        ? agentProfileImage[agentProfileImage?.length - 1]
                            ?.fileUrl
                        : ''
                    }
                    //fullName={agent?.userProfile?.name}
                    size={10}
                    className="border-2"
                  />
                  <br />
                  <span className="text-xl font-semibold">{agent.companyName}</span>
                  <br />
                  <span ><LocationOn className='text-gray-600' size={6} /> {agent.city+', '}</span>
                  <span>
                     {Countries.find((sta) => sta.id == agent.country).name}
                  </span>
                  <br />
                  <span>
                    <PhoneIcon className='text-gray-600' size={6} /> {agent.companyPhoneNumber}
                  </span>
                  <br />
                  <span>
                    <Email className='text-gray-600' size={6} /> {agent.companyEmail}
                  </span>
                </div>
               <div></div>
              </div>
             
            </>
          ) : (
            <>
              <div className="flex bg-blue-100 pt-12 pl-2 justify-between items-center">
                <div></div>
                <div>
                  <AvatarMui
                    imageUrl={userProfile?.image}
                    //fullName={agent?.userProfile?.name}
                    size={10}
                    className="border-2"
                  />
                  <br />
                  <span className="text-xl font-semibold">{userProfile.name}</span>
                  <br />
                  <span>
                    <PhoneIcon className='text-gray-600' size={6} /> {userProfile.phoneNumber}
                  </span>
                  <br />
                  <span>
                    <Email className='text-gray-600' size={6} /> {userProfile.applicationUpdateEmail}
                  </span>
                </div>
                <div></div>
              </div>
              <div className="flex-col rounded-sm h-[610px] bg-blue-100 w-[100%]">
                <br />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default MessageProfile;
