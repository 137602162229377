/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import EditorDraftJs from '../../Common/Editor/EditorDraftJs';
import Agent from '../../../api/agent';
import { format } from 'date-fns';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Send as SendIcon, Cancel as CancelIcon } from '@mui/icons-material';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import MailOutline from '@mui/icons-material/MailOutline';

const ContactMail = ({
  messageRecords,
  contactId,
  handleAddSummaryRecord,
  contactName,
  users,
}) => {
  const [addNoteMode, setAddNoteMode] = useState(false);
  const [subject, setSubject] = useState(`${contactId} - ${contactName}`);
  const [newNote, setNewNote] = useState({
    subject: subject,
    content: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);
  const [apiError, setApiError] = useState('');

  const handleAddNoteClick = () => {
    setIsMessageDialogOpen(true);
  };

  const toggleMessageDialog = () => {
    setIsMessageDialogOpen(!isMessageDialogOpen);
    if (isMessageDialogOpen) {
      setApiError('');
    }
  };

  const getUserName = (appUserDetailsId) => {
    const user = users.find(
      (user) => user.appUserDetailsId === appUserDetailsId
    );
    return user ? user.name : '';
  };

  const handleAddNote = async () => {
    if (!newNote.content) {
      return;
    }
    const currentDate = new Date().toISOString();

    try {
      const payload = {
        subject: subject,
        content: newNote.content,
        ContactManagementId: contactId,
        sendEmail: true,
        noteType: 1,
      };

      await Agent.GlobalContacts.sendSingleContactMail(payload);

      const updatedRecords = [
        ...messageRecords,
        {
          subject: newNote.subject,
          content: newNote.content,
          dateSent: currentDate,
        },
      ];

      handleAddSummaryRecord(updatedRecords);

      setSuccessMessage('Mail sent successfully! Reload to see changes.');
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);

      setNewNote({
        subject: subject,
        content: '',
      });
      setAddNoteMode(false);
      toggleMessageDialog();

      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <div>
        <h2 className="text-xl text-center font-semibold mb-1 text-gray-700">
          Contact Communication (Email)
        </h2>

        <div className="m-4">
          {!addNoteMode && (
            // <button
            //   type="button"
            //   onClick={handleAddNoteClick}
            //   className="bg-blue-500 text-white px-4 mt-2 py-2 rounded-md hover:bg-blue-600"
            // >
            //   Send Mail
            // </button>

            <Button
              onClick={handleAddNoteClick}
              endIcon={<SendIcon />}
              variant="contained"
              className="text-md font-semibold px-auto bg-sky-600"
            >
              Send Mail
            </Button>
          )}
        </div>

        <div className="space-y-4">
          {messageRecords
            ?.slice()
            .reverse()
            .map((record, index) => (
              <div key={index} className="p-4 bg-sky-100 shadow-xl rounded-md">
                <div className="mb-2">
                  <strong className="text-gray-800">Subject</strong>{' '}
                  {record.subject}
                </div>
                <div className="mb-2">
                  <strong className="text-gray-800">Content:</strong>
                  <div
                    className="text-gray-600"
                    dangerouslySetInnerHTML={{
                      __html: record.content,
                    }}
                  />
                </div>

                <div className="flex justify-between">
                  <div>
                    <strong className="text-gray-800">Date:</strong>{' '}
                    {format(new Date(record.dateSent), 'yyyy-MM-dd')}
                  </div>

                  <div>
                    <strong className="text-gray-800">Sent by:</strong>{' '}
                    {getUserName(record?.appUserDetailsId)}
                  </div>
                </div>
              </div>
            ))}
        </div>

        {addNoteMode && (
          <div className="mt-6">
            <div className="mb-4">
              <label className="block font-bold mb-1">Subject</label>
              <TextField
                fullWidth
                size="small"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                label="Subject"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block font-bold mb-1">Content</label>
              <EditorDraftJs
                editorOnChange={(html, valid) =>
                  setNewNote({
                    ...newNote,
                    content: html,
                  })
                }
                height={200}
                placeHolder="Write your message here"
                html={newNote.content}
              />
            </div>

            <div className="mb-4">
              <button
                type="button"
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                onClick={handleAddNote}
              >
                Send Now
              </button>
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={isMessageDialogOpen}
        onClose={toggleMessageDialog}
        fullWidth
        maxWidth="md"
      >
 

<DialogTitle className="bg-blue-500 text-white p-4 flex justify-center">
    <MailOutline className="mr-2 text-2xl" /> 
    <h2 className="text-xl font-bold">Send New Mail</h2> 
</DialogTitle>

        <DialogContent>
          <label className="block font-bold text-gray-700 mb-2">Subject</label>
          <TextField
            fullWidth
            size="medium"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            // label="Message Subject"
            variant="outlined"
            required
            className="my-1"
          />
          <div className="mb-8">
            <label className="block font-bold text-gray-700 mb-2">
              Content
            </label>
            <EditorDraftJs
              editorOnChange={(html) =>
                setNewNote({ ...newNote, content: html })
              }
              height={300}
              placeHolder="Write your message here"
              html={newNote.content}
            />
          </div>
        </DialogContent>
        <DialogActions className="flex justify-between">
          <Button
            onClick={toggleMessageDialog}
            startIcon={<CancelIcon />}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddNote}
            endIcon={<SendIcon />}
            variant="contained"
            className="text-md font-semibold px-auto bg-sky-600"
          >
            Send Mail
          </Button>
        </DialogActions>
        {apiError && (
          <div className="text-center text-lg text-red-600 mt-4">
            <h2>{apiError}</h2>
          </div>
        )}
      </Dialog>

      {successMessage && (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50 p-4 mb-4 bg-green-500 text-white rounded-md shadow-lg">
          {successMessage}
        </div>
      )}

      {errorMessage && (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50 p-4 mb-4 bg-red-500 text-white rounded-md shadow-lg">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    departments: state.departments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactMail);
