import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Box, Typography } from '@mui/material';
import LoadingView from '../Common/Loading/LoadingView';
// import { classNames } from 'classnames';
import CommunicationHistory from './StudentCom/CommunicationHistory';
import StudentsLeadCommsHistory from './StudentLeadComms/StudentsLeadCommsHistory';
import DatasetCommsHistory from './StudentLeadCommsOld/DatasetCommsHistory';
import AgentCommsHistory from './AgentComms/AgentCommsHistory';
import ReferralCommsHistory from './ReferralComms/ReferralCommsHistory';
import ContactComsHistory from './ContactComms/ContactComsHistory';

const CommunicationsMenu = () => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);

  const handleTabChange = (e, newVal) => {
    setTab(newVal);
  };

  return (
    <>
      {loading ? (
        <div style={{ marginTop: '0px', fontWeight: 'lighter' }}>
          <LoadingView />
        </div>
      ) : (
        <div>
          <Box>
            <Box>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#0081C9',
                    height: '5px',
                    borderRadius: '50px',
                  },
                }}
              >
                {tabItems.map((item, index) => {
                  return (
                    <Tab className="text-black" label={item} key={index} />
                  );
                })}
              </Tabs>
              <br />
              <Box>
                <TabPanel value={tab} index={0}>
                  <StudentsLeadCommsHistory />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <CommunicationHistory />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                  <AgentCommsHistory />
                </TabPanel>
                <TabPanel value={tab} index={3}>
                  <ReferralCommsHistory />
                </TabPanel>
                <TabPanel value={tab} index={4}>
                  <DatasetCommsHistory />
                </TabPanel>
                <TabPanel value={tab} index={5}>
                  <ContactComsHistory />
                </TabPanel>
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default CommunicationsMenu;

const TabPanel = (props) => {
  const { children, value, index } = props;

  return <div>{value === index && <Box>{children}</Box>}</div>;
};

const tabItems = [
  'Lead Communication',
  'Student Communication',
  'Agent Communication',
  'Referral Communication',
  'Dataset Communication',
  'Global Contacts',
];
