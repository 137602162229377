import {
  Button,
  Checkbox,
  Chip,
  Fab,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import useInput from '../../../Common/Form/use-input';
import MyInput from '../../../Common/Form/MyInput';
import MyInputField from '../../../Common/Form/MyInputField';
import DoneIcon from '@mui/icons-material/Done';
import MyCountryList from '../../../Common/Form/MyCountryList';
import PhotoWidgetDropzone from '../../../Common/ImageUpload/PhotoWidgetDropzone';
import Agent from '../../../../api/agent';
import MySelectInput from '../../../Common/Form/MySelectInput';
import { UNI_TYPES } from '../../../../Util/Constants';
import { ResizeFile } from '../../../../Util/ResizeFile';
import user from '../../../../assets/images/user_placeholder.png';
import { IconButton } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ButtonGroup } from '@mui/material';
import { DownloadCloudFile } from '../../../../Util/FileProcessing';
import MyDateInput from '../../../Common/Form/MyDateInput';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import MySnackbarAlert from '../../../Common/AlertMessage/MySnackbarAlert';
import EditorDraftJs from '../../../Common/Editor/EditorDraftJs';
import ConfirmationModal from '../../../Common/Modal/ConfirmationModal';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import CancelIcon from '@mui/icons-material/Cancel';
import { Countries } from '../../../../Util/Util';
import { tr } from 'date-fns/locale';
const PREFIX = 'UniversityProfile';

const deepBlueColor = '#645CAA';
const classes = {
  root: `${PREFIX}-root`,
  fieldset: `${PREFIX}-fieldset`,
  legend: `${PREFIX}-legend`,
  extendedIcon: `${PREFIX}-extendedIcon`,
  fabPosition: `${PREFIX}-fabPosition`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },
  [`& .${classes.fieldset}`]: {
    borderColor: deepBlueColor,
    borderRadius: 5,
    marginBottom: 20,
  },
  [`& .${classes.legend}`]: {
    margin: 'auto',
    padding: 10,
  },
  [`& .${classes.extendedIcon}`]: {
    marginRight: theme.spacing(1),
  },
  [`& .${classes.fabPosition}`]: {
    margin: 0,
    top: 'auto',
    right: 24,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
}));

const monthNames = {
  Jan: 1,
  Feb: 2,
  Mar: 4,
  Apr: 8,
  May: 16,
  Jun: 32,
  Jul: 64,
  Aug: 128,
  Sep: 256,
  Oct: 512,
  Nov: 1024,
  Dec: 2048,
};

const isOptional = () => true;
const isNotEmptyTrim = (value) => value && value.trim() !== '';
const isNotEmpty = (value) => value && value !== '';
const isNotEmptyNum = (value) => value && value > 0;
const isEmail = (value) => value && value.includes('@');

const UniversityProfile = ({
  profile,
  updateProfileParent,
  role,
  permissions,
  setAlert,
}) => {
  const [isInValid, setIsInValid] = useState(true);

  //intakes
  const allIntakes = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const [selectedIntakes, setSelectedIntakes] = useState(
    profile.intakeList || []
  );

  // useEffect(() => {
  //   if (partners.length == 0) {
  //     loadPartners();
  //   }
  // }, [partners]);

  useEffect(() => {
    let totalIntakes = 0;
    selectedIntakes.forEach((intake) => {
      totalIntakes += monthNames[intake];
    });
    profile = {
      ...profile,
      ...{
        intakes: totalIntakes,
      },
    };
    updateProfileParent(profile);
  }, [selectedIntakes]);

  //intakes
  const [openConfirm, setOpenConfirm] = useState(false);
  const [summaryHtml, setSummaryHtml] = useState(profile.summary);
  const [scholarshipHtml, setScholarshipHtml] = useState(
    profile.scholarshipInfo
  );
  // const [isTopUniversity, setTopUniversity] = useState(
  //   profile.isTop ? true : false
  // );
  // const [showPartner, setShowPartner] = useState(
  //   profile.applicationPartnerId > 0
  // );

  const [updating, setUpdating] = useState(false);
  const [editorValidStateSummary, setEditorValidStateSummary] = useState(false);
  const [editorValidStateScholarship, setEditorValidStateScholarship] =
    useState(false);

  const editorOnChangeSummary = (rawHtml, valid) => {
    setSummaryHtml(rawHtml);
    setEditorValidStateSummary(valid);
  };
  const editorOnChangeScholarship = (rawHtml, valid) => {
    setScholarshipHtml(rawHtml);
    setEditorValidStateScholarship(valid);
  };

  useEffect(() => {
    profile = {
      ...profile,
      ...{
        summary: summaryHtml,
        scholarshipInfo: scholarshipHtml,
      },
    };
    updateProfileParent(profile);
  }, [summaryHtml, scholarshipHtml]);

  const onChangeHandler = (name, value) => {
    profile = {
      ...profile,
      ...{
        [name]: value,
      },
    };
    updateProfileParent(profile);
  };
  const handleSave = () => {
    setUpdating(true);
    Agent.Universities.saveUniversity({ university: profile })
      .then((id) => {
        setUpdating(false);
        setAlert();
        updateProfileParent({ id: id });
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
        setAlert(
          'Error: Failed to save the university. Check university name along with other information!',
          false
        );
        debugger;
      });
  };
  const { obj: name } = useInput(
    profile.name,
    isNotEmptyTrim,
    null,
    onChangeHandler
  );
  const { obj: city } = useInput(
    profile.city,
    isNotEmptyTrim,
    null,
    onChangeHandler
  );
  const { obj: additionalInfoLink } = useInput(
    profile.additionalInfoLink,
    isNotEmptyTrim,
    null,
    onChangeHandler
  );
  const { obj: countryId } = useInput(
    profile.countryId,
    isNotEmpty,
    null,
    onChangeHandler
  );
  const { obj: applicationPartnerId } = useInput(
    profile.applicationPartnerId,
    isNotEmpty,
    null,
    onChangeHandler
  );

  const { obj: type } = useInput(
    profile.type,
    isNotEmpty,
    null,
    onChangeHandler
  );
  const { obj: isScholarshipAvailable } = useInput(
    profile.isScholarshipAvailable,
    isNotEmpty,
    null,
    onChangeHandler
  );
  const { obj: isTop } = useInput(
    profile.isTop,
    isNotEmpty,
    null,
    onChangeHandler
  );

  useEffect(() => {
    setIsInValid(
      updating ||
        !profile.name ||
        profile.name.length == 0 ||
        profile.type == null ||
        !profile.city ||
        profile.city.length == 0 ||
        profile.countryId == null
    );
  }, [profile]);

  const deleteUniversity = () => {
    Agent.Universities.deleteUniversity(profile.id)
      .then(() => {
        debugger;
        setOpenConfirm(false);
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  return (
    <Root>
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>
          {' '}
          <Typography variant="h6" align="center" sx={{ color: deepBlueColor }}>
            General Info
          </Typography>
        </legend>
        <Grid container spacing={6} >
          <Grid item xs={12} container direction="row" spacing={2}>
            <Grid item xs={4}>
              <MyInputField
                obj={name}
                label="Institution Name"
                name="name"
                handleChange={name.valueChangeHandler}
                handleBlur={name.valueInputBlurHandler}
                errorMessage="First name is invalid"
                isInvalid={false}
                required
              />
            </Grid>
            <Grid item xs={2}>
              <MySelectInput
                options={UNI_TYPES}
                name="type"
                label="Institution Type"
                value={type.value}
                handleChange={type.valueChangeHandler}
                handleBlur={type.valueInputBlurHandler}
                errorMessage="Type is invalid"
                isInvalid={false}
                required
              />
            </Grid>
            <Grid item xs={3}>
              <MyInputField
                obj={city}
                label="City"
                name="city"
                handleChange={city.valueChangeHandler}
                handleBlur={city.valueInputBlurHandler}
                errorMessage="City is invalid"
                isInvalid={false}
                required
              />
            </Grid>
            {/* <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={showPartner}
                    onChange={(e) => {
                      setShowPartner(e.target.checked);
                      if (!e.target.checked) {
                        profile = {
                          ...profile,
                          ...{
                            applicationPartnerId: 0,
                          },
                        };
                        updateProfileParent(profile);
                      }
                    }}
                  />
                }
                label={showPartner ? 'Hide Partners' : 'Via a Partner?'}
              />
               {showPartner && (
                <MySelectInput
                  options={partners}
                  name="applicationPartnerId"
                  label="Application Partner"
                  value={applicationPartnerId.value}
                  handleChange={applicationPartnerId.valueChangeHandler}
                  handleBlur={applicationPartnerId.valueInputBlurHandler}
                  errorMessage="Partner is invalid"
                  isInvalid={false}
                />
              )} 
            </Grid> */}
            <Grid item xs={3}>
              <MySelectInput
                options={Countries.sort(function (a, b) {
                  if (a.order < b.order) {
                    return -1;
                  }
                  if (a.order > b.order) {
                    return 1;
                  }
                  return 0;
                })}
                name="countryId"
                label="Country"
                value={countryId.value}
                handleChange={countryId.valueChangeHandler}
                handleBlur={countryId.valueInputBlurHandler}
                errorMessage="Country is invalid"
                isInvalid={false}
                required
              />
            </Grid>
            <Grid item xs={9} container direction="row" spacing={2}>
            <Grid item>
              <EditorDraftJs
                editorOnChange={editorOnChangeSummary}
                height={250}
                placeHolder="University summary goes here"
                html={summaryHtml}
              />
              
            </Grid>
          </Grid>
          </Grid>
          
        </Grid>
      </fieldset>
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>
          <Typography variant="h6" align="center" sx={{ color: deepBlueColor }}>
            Scholarship Info
          </Typography>
        </legend>

        <Grid container spacing={6}>
          <Grid item xs={6} container >
            <FormControlLabel
              control={
                <Switch
                  checked={isTop.value}
                  onChange={(e) =>
                    isTop.valueChangeHandler(e.target.checked, 'isTop')
                  }
                  name="isTop"
                />
              }
              label={
                isTop.value
                  ? 'Marked as Top University'
                  : 'Unmarked as Top University'
              }
            />
          </Grid>
          <Grid item xs={6} container>
            <FormControlLabel
              control={
                <Switch
                  checked={isScholarshipAvailable.value}
                  onChange={(e) =>
                    isScholarshipAvailable.valueChangeHandler(
                      e.target.checked,
                      'IsScholarshipAvailable'
                    )
                  }
                  name="IsScholarshipAvailable"
                />
              }
              label={
                isScholarshipAvailable.value
                  ? 'Scholarship Available'
                  : 'Is Scholarship Available?'
              }
            />
          </Grid>
          {isScholarshipAvailable.value && (
            <Grid item xs={6} container>
              <Grid item>
                {' '}
                <EditorDraftJs
                  editorOnChange={editorOnChangeScholarship}
                  height={100}
                  placeHolder="Scholarship info goes here"
                  html={scholarshipHtml}
                />{' '}
              </Grid>
            </Grid>
          )}
        </Grid>
      </fieldset>
      <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>
          {' '}
          <Typography variant="h6" align="center" sx={{ color: deepBlueColor }}>
            Intakes
          </Typography>
        </legend>

        <Grid container item spacing={2}>
          <Grid item xs={2}>
            <Select
              label="Intakes"
              placeholder="Intakes"
              fullWidth
              size="small"
              multiple
              value={selectedIntakes}
              onChange={(e) => {
                setSelectedIntakes(e.target.value);
              }}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => 'Select Intakes'}
            >
              {allIntakes
                .filter((item) => !selectedIntakes.includes(item))
                .map((intake) => (
                  <MenuItem key={intake} value={intake}>
                    <Checkbox
                      checked={selectedIntakes.some((item) => item == intake)}
                    />
                    <ListItemText primary={intake} />
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item xs={8}>
            {selectedIntakes.length > 0 &&
              selectedIntakes
                .sort(function (a, b) {
                  return monthNames[a] - monthNames[b];
                })
                .map((item) => (
                  <Chip
                    key={item}
                    label={item}
                    className={classes.chip}
                    deleteIcon={<CancelIcon />}
                    color={
                      item == 'Jan' || item == 'May' || item == 'Sep'
                        ? 'secondary'
                        : 'primary'
                    }
                    onDelete={() => {
                      setSelectedIntakes(
                        selectedIntakes.filter((intake) => intake != item)
                      );
                    }}
                    onClick={() => {
                      setSelectedIntakes(
                        selectedIntakes.filter((intake) => intake != item)
                      );
                    }}
                  />
                ))}
          </Grid>
        </Grid>
      </fieldset>
      {/* <fieldset className={classes.fieldset}>
        <legend className={classes.legend}>
          {' '}
          <Typography variant="h6" align="center" color="primary">
            Contact Info
          </Typography>
        </legend>

        <Grid container spacing={6}>
          <Grid item xs={6} container direction="column" spacing={1}>
            <Grid item container>
              <Grid item></Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} container direction="column" spacing={1}>
            <Grid item></Grid>
          </Grid>
        </Grid>
      </fieldset> */}
      {(role.isSuperAdmin || permissions.CanManageUniversity) && (
        <div className={classes.fabPosition}>
          <Fab
            size="small"
            variant="extended"
            className="bg-sky-400 hover:bg-sky-500 text-white px-3 pr-5"
            style={{ marginRight: 10 }}
            onClick={() => handleSave(false)}
            disabled={isInValid}
          >
            <DoneIcon className={classes.extendedIcon} />
            {updating && (
              <>
                <CircularProgress size={14} />
                <span style={{ marginLeft: 5 }}>Saving</span>
              </>
            )}
            {!updating && 'Save'}
          </Fab>
        </div>
      )}
      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={deleteUniversity}
        confirmText="Confirm Deletion"
        content="Are you sure that you would like to remove this university from the system?"
      />
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    profile: ownProps.profile,
    updateProfileParent: ownProps.updateProfileParent,
    // partners: state.management.partners,
    role: state.auth.role,
    permissions: state.auth.permissions,
    setAlert: ownProps.setAlert,
  };
};
// const mapDispatchToProps = (dispatch) => {
//   return {
//     loadPartners: () => dispatch(actions.loadPartners()),
//   };
// };

export default connect(mapStateToProps, null)(UniversityProfile);
