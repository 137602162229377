import Agent from '../api/agent'
// import mime from 'mime-types'

export const DownloadEmployeeFile = async (bucketName, fileName) => {
  await Agent.General.downloadDoc(bucketName, fileName)
    .then((response) => {
      ProcessDownloadedFile(response, fileName)
    })
    .catch((error) => {
      debugger
      console.log(error)
      return Promise.reject(error)
    })
}

// export const ProcessDownloadedFile = async (fileData, fileName) => {
//   var link = document.createElement('a');
//   var array = new Uint8Array(fileData.data[0].data);
//   link.href = window.URL.createObjectURL(
//     new Blob([array], {
//       type: mime.lookup(fileName),
//     })
//   );
//   link.download = fileName;
//   document.body.appendChild(link);
//   link.click();
//   setTimeout(function () {
//     window.URL.revokeObjectURL(link);
//     return Promise.resolve();
//   }, 200);
// };

// #MIGRATION: The above code is commented out to avoid Polyfill error and replaced by the code below. Not tested yet.
export const ProcessDownloadedFile = async (fileData, fileName) => {
  var link = document.createElement('a')
  var array = new Uint8Array(fileData.data[0].data)
  var blob = new Blob([array])

  if (typeof link.download === 'undefined') {
    // For browsers that do not support the download attribute
    var url = window.URL.createObjectURL(blob)
    window.location.href = url
  } else {
    // For browsers that support the download attribute
    link.href = window.URL.createObjectURL(blob)
    link.download = fileName
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  setTimeout(function () {
    window.URL.revokeObjectURL(link.href)
    return Promise.resolve()
  }, 200)
}

export const DownloadCloudFile = async (
  fileNameLocal,
  fileName,
  containerName
) => {
  await Agent.CloudFiles.download(fileNameLocal, fileName, containerName)
    .then((response) => {
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(
        new Blob([response.data], {type: 'application/octet-stream'})
      )
      link.download = fileNameLocal ? fileNameLocal : fileName
      document.body.appendChild(link)
      link.click()
      setTimeout(function () {
        window.URL.revokeObjectURL(link)
        return Promise.resolve()
      }, 200)
    })
    .catch((error) => {
      debugger
      console.log(error)
      return Promise.reject(error)
    })
}
