import * as actionTypes from './actionTypes';
import Agent from '../../api/agent';

const loadCoursesSuccess = (courses) => {
  return {
    type: actionTypes.COURSES_LOADING_SUCCESS,
    payload: {
      courses,
    },
  };
};

export const addCourses = (courses) => {
  return {
    type: actionTypes.COURSES_ADD,
    payload: {
      courses,
    },
  };
};

export const loadCourses =
  (showAll = false) =>
  async (dispatch) => {
    //dispatch(loadUsersStart());
    const courses = await Agent.Courses.listglobal(showAll);
    dispatch(loadCoursesSuccess(courses));
    return courses;
  };

export const updateSingleCourse = (course) => {
  return {
    type: actionTypes.COURSES_UPDATE_COURSE,
    payload: {
      course,
    },
  };
};

export const deleteSingleCourse = (id) => {
  return {
    type: actionTypes.COURSES_DELETE,
    payload: {
      id,
    },
  };
};
