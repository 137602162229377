import React from 'react';

const userContext = React.createContext({
  openDrawer: false,
  unReadMessagesCount: 0,
  notificationCount: 0,
  notifications: [],
  backgroundColor: '#eeeeee',
  agentId: 0,
  status: null,
  applicationId: 0,
  studentId: 0,
  studentName: null,
  draft: '',
});

export default userContext;
