import * as actionTypes from '../actions/actionTypes';
import initialState from './initialState';

const reducer = (state = initialState.departments, action) => {
  switch (action.type) {
    case actionTypes.DEPARTMENTS_LOADING_SUCCESS:
      return action.payload.depts;
    case actionTypes.DEPARTMENTS_ADD:
      return addDept(state, action.payload);
    case actionTypes.DEPARTMENTS_UPDATE_DEPARTMENT:
      return updateSingleDept(state, action.payload);
    default:
      return state;
  }
};

const addDept = (state, payload) => {
  return state.concat(payload.dept);
};

const updateSingleDept = (state, payload) => {
  if (state.length === 0) return state;
  var updatedState = state.map((item) => {
    if (item.id === payload.dept.id) {
      return payload.dept;
    }
    return item;
  });

  return updatedState;
};

export default reducer;
