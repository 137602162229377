import React from 'react';
import companyLogo from '../../assets/images/TCL_Main.svg';
import companyLogoWithoutBottomText from '../../assets/images/tclnewlogo.png';
import companyIcon from '../../assets/images/TCL_Icon.svg';

export const CompanyLogo = (props) => {
  return <img alt="company logo" src={companyLogo} {...props} />;
};
export const CompanyLogoWithoutBottomText = (props) => {
  return (
    <img alt="company logo" src={companyLogoWithoutBottomText} {...props} />
  );
};
export const CompanyIcon = (props) => {
  return (
    <img
      alt="company icon"
      src={companyIcon}
      {...props}
      style={{ width: '100%', height: 'auto' }}
    />
  );
};
