// Update MessageDisplay.js
import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

const MessageDisplay = ({ type, message, duration=3000, onClose, error }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration]);

  const getColorClass = () => {
    if (type === 'success') {
      return 'bg-green-500 text-white';
    } else if (type === 'error') {
      return 'bg-red-500 text-white';
    }
    return ''; // Default class if type is not recognized
  };

  return (
    isVisible && (
      <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50">
        <div
          className={`p-4 rounded-md shadow-lg ${getColorClass()} w-64 text-center`}
        >
          {type === 'error' && (
            <button
              className="absolute top-2 right-2 text-white"
              onClick={onClose}
            >
              <FaTimes />
            </button>
          )}
          <p className="font-semibold mb-1">
            {type === 'success' ? 'Success' : 'error'}
          </p>
          <p className="text-sm">{message}</p>
        </div>
      </div>
    )
  );
};

export default MessageDisplay;
