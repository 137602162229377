import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { Grid, Typography } from '@mui/material';

const PREFIX = 'MyTooltip';

const classes = {
  tooltip: `${PREFIX}-tooltip`,
};

const StyledCustomTooltip = styled(Tooltip)(({ theme }) => ({
  [`& .${classes.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const MyTooltip = ({ title, icon, children, ...rest }) => {
  return (
    <StyledCustomTooltip
      classes={classes}
      {...rest}
      title={
        <Grid container spacing={1}>
          {icon && <Grid item>{icon}</Grid>}
          <Grid item>
            <Typography>{title}</Typography>
          </Grid>
        </Grid>
      }
    >
      {children}
    </StyledCustomTooltip>

    // <CustomTooltip
    //   {...rest}
    //   title={
    //     <Grid container spacing={1}>
    //       {icon && <Grid item>{icon}</Grid>}
    //       <Grid item>
    //         <Typography>{title}</Typography>
    //       </Grid>
    //     </Grid>
    //   }
    // >
    //   {children}
    // </CustomTooltip>
  );
};

export default MyTooltip;
