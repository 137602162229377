import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import PhotoWidgetDropzone from './PhotoWidgetDropzone';

import Agent from '../../../api/agent';
import LinearIndeterminate from '../Progress/LinearIndeterminate';

const PREFIX = 'PhotoUploadWidget';

const classes = {
  circularProgress: `${PREFIX}-circularProgress`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.circularProgress}`]: {
    marginLeft: 0,
    marginRight: theme.spacing(3),
  },
}));

export default function PhotoUploadWidget({
  setLogoVisibility,
  setLogoUrl,
  agentId,
  studentId,
}) {
  const [progressBar, setProgressBar] = useState(false);

  const uploadToCloud = (file) => {
    setProgressBar(true);
    Agent.CloudFiles.upload(file, 4, agentId, studentId)
      .then((response) => {
        setLogoUrl(response.blobUri);
        var file = {
          fileUrl: response.blobUri,
          fileName: response.blobName,
          fileNameLocal: response.fileNameLocal,
        };
        setLogoVisibility(true, file);
        setProgressBar(false);
      })
      .catch((error) => {
        console.log(error);
        setLogoVisibility(false);
        setProgressBar(false);
      });
  };

  return (
    <Root>
      <Grid container spacing={3} style={{ marginBottom: 5 }}>
        <Grid item>
          <PhotoWidgetDropzone
            crop={false}
            upload={uploadToCloud}
            message="Drop Your Company Logo Here"
          />
        </Grid>
      </Grid>
      {progressBar && <LinearIndeterminate />}
    </Root>
  );
}
