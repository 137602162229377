import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Box, Typography } from '@mui/material';
import LoadingView from '../../../Common/Loading/LoadingView';
import ReferralBulkEmail from './ReferralBulkEmail/ReferralBulkEmail';
import ReferralBulkWhatsapp from './LeadBulkWhatsapp/ReferralBulkWhatsapp';

const ReferralComOptions = () => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);

  const handleTabChange = (e, newVal) => {
    setTab(newVal);
  };

  return (
    <>
      {loading ? (
        <div style={{ marginTop: '0px', fontWeight: 'lighter' }}>
          <LoadingView />
        </div>
      ) : (
        <div>
          {/* <h3 style={{ fontWeight: 'bolder',marginTop:'-1%'}}>Reports and Analytics </h3> */}
          <Box>
            <Box>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#0081C9',
                    height: '5px',
                    borderRadius: '50px',
                  },
                }}
              >
                {tabItems.map((item, index) => {
                  return (
                    <Tab className="text-black" label={item} key={index} />
                  );
                })}
              </Tabs>
              <br />
              <Box>
                <TabPanel value={tab} index={0}>
                  <ReferralBulkEmail />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <ReferralBulkWhatsapp />
                </TabPanel>
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default ReferralComOptions;

const TabPanel = (props) => {
  const { children, value, index } = props;

  return <div>{value === index && <Box>{children}</Box>}</div>;
};

const tabItems = ['Email', 'Whatsapp'];
