import * as actionTypes from '../actions/actionTypes'
import {updateObject} from '../utility'
import * as constants from '../../Util/Constants'
import initialState from './initialState'
import {getUserPermissionsFromToken} from '../../Util/Util'

const updateUserName = (state, payload) => {
  const {name, phoneNumber, applicationUpdateEmail} = payload
  return updateObject(state, {name, phoneNumber, applicationUpdateEmail})
}

const profilePhotoChanged = (state, imageUrl) => {
  return updateObject(state, {image: imageUrl})
}
const profilePhotoDeleted = (state) => {
  return updateObject(state, {image: null})
}
const authStart = (state) => {
  return updateObject(state, {error: null, loading: true})
}

const authSuccess = (state, action) => {
  let newRoleName = ''
  let newRole = {
    isSuperAdmin: false,
    isSeniorAdmin: false,
    isAdmin: false,
    isAgentStaff: false,
    isAgent: false,
    isStudent: false,
    isApplicationTeam: false,
    isApplicationTeamLeader: false,
    isReferral: false,
  }
  switch (action.role) {
    case constants.SUPERADMIN:
      newRole.isSuperAdmin = true
      newRoleName = 'Super Admin'
      break
    case constants.SENIORADMIN:
      newRole.isSeniorAdmin = true
      newRoleName = 'Senior Admin'
      break
    case constants.ADMIN:
      newRole.isAdmin = true
      newRoleName = 'Admin'
      break
    case constants.AGENTSTAFF:
      newRole.isAgentStaff = true
      newRoleName = 'Agent Staff'
      break
    case constants.AGENT:
      newRole.isAgent = true
      newRoleName = 'Agent'
      break
    case constants.STUDENT:
      newRole.isStudent = true
      newRoleName = 'Student'
      break
    case constants.APPLICATIONTEAM:
      newRole.isApplicationTeam = true
      newRoleName = 'Application Team'
      break
    case constants.APPLICATIONTEAMLEADER:
      newRole.isApplicationTeamLeader = true
      newRoleName = 'Application Team Leader'
      break
    case constants.REFERRAL:
      newRole.isReferral = true
      newRoleName = 'Referral'
      break
  }
  return updateObject(state, {
    isInternal: action.isInternal,
    id: action.id,
    appUserDetailsId: action.appUserDetailsId,
    name: action.name,
    applicationUpdateEmail: action.applicationUpdateEmail,
    phoneNumber: action.phoneNumber,
    image: action.image,
    token: action.token,
    roleName: newRoleName,
    role: newRole,
    isLoggedIn: true,
    error: null,
    loading: false,
    permissions: getUserPermissionsFromToken(action.token),
  })
}

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    isLoggedIn: false,
    roleName: null,
    isInternal: false,
    name: null,
    permissions: constants.USER_PERMISSIONS_OBJECT_INITIAL,
  })
}

const authLogout = (state) => {
  return updateObject(state, {
    token: null,
    role: null,
    isLoggedIn: false,
    roleName: null,
    isInternal: false,
    name: null,
    permissions: constants.USER_PERMISSIONS_OBJECT_INITIAL,
  })
}

const reducer = (state = initialState.currentUser, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state)
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action)
    case actionTypes.AUTH_FAIL:
      return authFail(state, action)
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state)
    case actionTypes.PROFILE_UPLOADING_SUCCESS:
      return profilePhotoChanged(state, action.imageUrl)
    case actionTypes.PROFILE_DELETING_SUCCESS:
      return profilePhotoDeleted(state)
    case actionTypes.USERS_USER_NAME_UPDATE:
      return updateUserName(state, action.payload)
    default:
      return state
  }
}

export default reducer
