/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Autocomplete,
  Chip,
  Divider,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import { Countries } from '../../../Util/Util';
import Draggable from 'react-draggable';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const Search = ({ users, action, title, cancel }) => {
  users.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const [dialogOpen, setDialogOpen] = useState(true);
  const [open, setOpen] = useState(false);
  const [record, setRecord] = useState();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const handleClose = () => {
    setDialogOpen(false);
    cancel(false);
  };

  const confirm = () => {

    action(record);
    setShowSuccessMessage(true);
    console.log('Selected record:', record);

  };
  const handleSuccessMessageClose = () => {
    setShowSuccessMessage(false);
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        open={dialogOpen}
        maxWidth="sm"
        fullWidth
        PaperComponent={PaperComponent}
      >
        <BootstrapDialogTitle onClose={handleClose} style={{ cursor: 'move' }}>
          <Typography align="center" color="secondary" variant="h6">
            {' '}
            {title || 'Pick user and confirm selection'}
          </Typography>
          <Divider />
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            {/* <Grid item>
              <Typography>Start typing to see suggestions</Typography>
            </Grid> */}
            <Grid item>
              <Autocomplete
                fullWidth
                size="small"
                // sx={{ width: 500 }}
                open={open}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                value={record}
                onChange={(_, newVal) => {
                  setRecord(newVal);
                }}
                // getOptionSelected={(option, value) =>
                //   option.firstName === value.firstName
                // }
                // isOptionEqualToValue={(option, value) =>
                //   option.title === value.title
                // }
                getOptionLabel={(user) => `${user.name}`}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Grid
                      container
                      direction="column"
                      sx={{
                        backgroundColor: (theme) => theme.palette.grey[200],
                        borderRadius: 1,
                        padding: 1,
                      }}
                      spacing={1}
                    >
                      <Grid item>
                        <Typography color="primary">{option.name}</Typography>
                      </Grid>
                      {option.countries && option.countries.length > 0 ? (
                        <Grid item>
                          {option.countries.filter((item)=>item.id!=0).map((country, index) => (
                            <Chip
                              color="secondary"
                              key={index}
                              label={
                                Countries.filter((item)=>item.id!=0).find(({ id }) => id == country).name
                              }
                              variant="outlined"
                            />
                          ))}
                        </Grid>
                      ) : null}
                    </Grid>
                  </li>
                )}
                options={users}
                // PaperComponent={({ children }) => (
                //   <Paper color="secondary" style={{ fontWeight: 'bold' }}>
                //     {children}
                //   </Paper>
                // )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="Start typing to get suggesstions"
                    placeholder="Start typing to get suggestions"
                    InputProps={{
                      ...params.InputProps,
                      autoComplete: 'new-password',
                      endAdornment: <>{params.InputProps.endAdornment}</>,
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
        <DialogActions>
        <Button autoFocus onClick={confirm}>
          Confirm Selection
        </Button>
      </DialogActions>

      {/* Success Message */}
      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={3000}
        onClose={handleSuccessMessageClose}
      >
        <Alert onClose={handleSuccessMessageClose} severity="success">
          Assigned successfully!
        </Alert>
      </Snackbar>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
