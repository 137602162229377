import React, { useState } from 'react';
import Agent from '../../../api/agent';
import { connect } from 'react-redux';

const UnsubscribeMailInput = ({ appUserId }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState('');
  const [emailInfo, setEmailInfo] = useState({
    EmailAddress: '',
    IsBulk: false,
    IsNormal: false,
    AddedOn: '',
    AddedBy: appUserId,
  });

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    return now.toISOString(); 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(emailInfo.EmailAddress)) {
      setEmailValidationMessage('Please enter a valid email address.');
      return;
    }

    setEmailValidationMessage('');

    const emailInfoWithDate = {
      ...emailInfo,
      AddedOn: getCurrentDateTime(),
    };

    try {
      const response = await Agent.Communication.unsubscribedEmail(emailInfoWithDate);
      setSuccessMessage('Email unsubscribed successfully');
      setErrorMessage('');
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        setEmailInfo({
          EmailAddress: '',
          IsBulk: false,
          IsNormal: false,
          AddedOn: '',
          AddedBy: appUserId,
        });
      }, 5000);
    } catch (error) {
      setErrorMessage(`Warning! ${error.message}`);
      setSuccessMessage('');
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 5000);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setEmailInfo((prevData) => ({
      ...prevData,
      [name]: val,
    }));
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-b from-sky-200 to-sky-300">
      <div className="max-w-lg w-full bg-white p-8 rounded-lg shadow-lg flex flex-col items-center">
        <h2 className="text-2xl font-semibold mb-4">Unsubscribe Email</h2>
        <form onSubmit={handleSubmit} className="w-full max-w-sm">
          <div className="mb-4 w-full">
            <label
              htmlFor="EmailAddress"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Email Address
            </label>
            <input
              type="email"
              name="EmailAddress"
              id="EmailAddress"
              value={emailInfo.EmailAddress}
              onChange={handleChange}
              className={`p-2 border ${
                emailValidationMessage ? 'border-red-500' : 'border-gray-300'
              } rounded-md w-full focus:outline-none focus:ring focus:border-blue-500`}
              required
            />
            {emailValidationMessage && (
              <p className="text-red-500 text-sm mt-1">
                {emailValidationMessage}
              </p>
            )}
          </div>
          <div className="mb-4 w-full flex items-center">
            <input
              type="checkbox"
              name="IsBulk"
              id="IsBulk"
              checked={emailInfo.IsBulk}
              onChange={handleChange}
              className="mr-2 leading-tight"
            />
            <label
              htmlFor="IsBulk"
              className="text-sm font-medium text-gray-700"
            >
              Unsubscribe from Bulk Email
            </label>
          </div>
          <div className="mb-4 w-full flex items-center">
            <input
              type="checkbox"
              name="IsNormal"
              id="IsNormal"
              checked={emailInfo.IsNormal}
              onChange={handleChange}
              className="mr-2 leading-tight"
            />
            <label
              htmlFor="IsNormal"
              className="text-sm font-medium text-gray-700"
            >
              Unsubscribe from Single Email
            </label>
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-full"
          >
            Unsubscribe Email
          </button>
        </form>

        {showModal && (
          <div className="fixed z-10 inset-0 flex items-center justify-center overflow-y-auto">
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            <div className="relative bg-white rounded-lg px-8 py-6">
              <div className="flex items-start justify-between">
                <h3
                  className={`text-lg font-semibold ${
                    successMessage ? 'text-green-600' : 'text-red-500'
                  }`}
                >
                  {successMessage ? 'Success' : 'Alert'}
                </h3>
                <button
                  onClick={() => setShowModal(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Close</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <p
                className={`mt-2 text-xl ${
                  successMessage ? 'text-green-600' : 'text-red-500'
                }`}
              >
                {successMessage || errorMessage}
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
    users: state.users,
  };
};

export default connect(mapStateToProps, null)(UnsubscribeMailInput);
