import React from 'react';

const registerContext = React.createContext({
  currentPage: 0,
  firstName: '',
  lastName: '',
  nationality: null,
  phoneNumber: '',
  email: '',
  password: '',
  selectedCountries: [],
  preferenceStudyLevelId: null,
  year: null,
  funding: null,
  subjects: [],
});

export default registerContext;
