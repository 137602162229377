/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import { CampaignOutlined } from '@mui/icons-material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { htmlToText } from 'html-to-text';
import parse from 'html-react-parser';

const AnnouncementCard = ({ title, content, publishDate, type }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const truncateContent = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + ' ' + '...';
  };
  const truncatedContent = truncateContent(htmlToText(content), 220);

  return (
    <>
      <div className="flex flex-col w-full mb-4">
        <div className="relative grid h-28 card bg-gradient-to-r from-indigo-100 to-cyan-100 rounded-box place-items-center p-4 shadow-md">
          <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-teal-500 to-cyan-500 rounded-t-lg">
            {' '}
          </div>
          <div className="flex justify-between items-center w-full mt-1">
            <div className="flex items-center">
              <CampaignOutlined className="text-sky-500 mr-2 text-3xl animate-bounce" />
              <div>
                <Typography className="text-gray-900 font-poppins text-xl font-bold subpixel-antialiased">
                  {title}
                </Typography>
                <Typography className="text-gray-800 font-roboto text-justify font-medium leading-relaxed antialiased">
                  {truncatedContent}
                </Typography>
              </div>
            </div>
            <div className="flex flex-col items-end w-[20%] md:w-[25%]">
              <Typography className="text-gray-600 text-sm mb-1 pr-1">
                {format(new Date(publishDate), 'MMMM d, yyyy  HH:mm')}
              </Typography>
              <button
                onClick={handleOpen}
                className="btn bg-gradient-to-r from-cyan-500 to-teal-500 text-white font-semibold px-4 py-0 rounded-md hover:bg-gradient-to-l hover:from-sky-600 hover:to-teal-600 transition transform hover:scale-105"
              >
                Read More
              </button>
            </div>
          </div>
        </div>
        <div className="divider"></div>
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box className="relative bg-white rounded-lg p-8 shadow-2xl w-[50%] mx-auto mt-20 overflow-y-auto h-[800px]">
          <div className="flex justify-end mb-6">
            <div className="flex justify-end">
              <button
                onClick={handleClose}
                className="btn bg-gradient-to-r from-green-500 to-teal-500 text-white font-semibold py-0 px-4 rounded-md hover:bg-gradient-to-l hover:from-green-600 hover:to-teal-600 transition"
              >
                Close
              </button>
            </div>
            <Typography className="absolute mt-2 top-6 left-8 bg-gradient-to-r from-cyan-500 to-teal-500 text-white font-poppins text-sm font-semibold px-2 py-1 rounded-md">
              {type}
            </Typography>
          </div>
          <Typography
            variant="h4"
            className="text-2xl font-poppins md:text-2xl font-bold text-gray-800 mb-4"
          >
            {title}
          </Typography>
          <Typography className="text-gray-500 text-sm mb-4">
            Published on {format(new Date(publishDate), 'MMMM d, yyyy HH:mm')}
          </Typography>
          <div className="divider"></div>
           {parse(content)}
        </Box>
      </Modal>
    </>
  );
};

export default AnnouncementCard;
