import React, { useEffect } from 'react';
// import Agent from '../../../../../../api/agent';
import Requirement from './Requirement';
import Agent from '../../../../../api/agent';

const Requirements = ({
  requirements,
  isInternal,
  studentLeadId,
  setRequirements,
  edit,
  showAlert,
}) => {
  useEffect(() => {}, []);

  const update = (item) => {
    if (item) {
      let temp = [];
      requirements.forEach((element) => {
        if (element.id == item.requirementId) {
          let tempItems = [];
          element.requirementItems.forEach((el) => {
            if (el.id == item.id) {
              tempItems.push(item);
            } else {
              tempItems.push(el);
            }
          });
          element.requirementItems = tempItems;
        } else {
          temp.push(element);
        }
      });
    } else {
      Agent.Requirements.list(studentLeadId).then(setRequirements);
    }
    showAlert(true);
  };

  return requirements.map((req, index) => (
    <Requirement
      key={index}
      requirement={req}
      isInternal={isInternal}
      studentLeadId={studentLeadId}
      setRequirements={setRequirements}
      edit={edit}
      showAlert={showAlert}
      updateParent={update}
    />
  ));
};

export default Requirements;
