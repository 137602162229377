import React, { useState, useEffect } from 'react';
import paperFlyAnimation from './../../../../assets/images/mail.png';

const CountdownLoader = ({ totalSelected, onCountdownComplete }) => {
  const [count, setCount] = useState(totalSelected);
  
  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(
        () => setCount((prevCount) => prevCount - 1),
        1000
      );
      return () => clearTimeout(timer);
    } else {
      onCountdownComplete();
    }
  }, [count, onCountdownComplete]);

  const percentage = ((totalSelected - count) / totalSelected) * 100;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-[1500] bg-opacity-50 bg-black">
      <div className="bg-blue-500 p-4 rounded shadow-lg text-white">
        <p className="text-lg text-center" aria-live="polite">
          Processing, please wait! Remaining: {count}
        </p>
        <label htmlFor="progress-bar" className="block text-center mb-2">
          Progress:
        </label>
        <progress
          id="progress-bar"
          className="progress progress-info w-full"
          value={percentage}
          max="100"
        >
          {percentage}%
        </progress>

        <div className="relative flex justify-center items-center">
          <div className="rounded-full h-36 w-36 flex items-center justify-center border-4 border-white">
            <img
              src={paperFlyAnimation}
              className="h-24 w-24 animate-pulse"
              alt="Loading Animation"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountdownLoader;
