import * as actionTypes from './actionTypes';
import { uploadProfilePhotoAction } from './user';
import Agent from '../../api/agent';
import { history } from '../..';

import { VERIFICATION_COOKIE } from '../../Util/Constants';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const updateUserPersonalInfo = (
  name,
  phoneNumber,
  applicationUpdateEmail
) => {
  return {
    type: actionTypes.USERS_USER_NAME_UPDATE,
    payload: {
      name,
      phoneNumber,
      applicationUpdateEmail,
    },
  };
};

export const deleteProfilePhotoAction = () => {
  return {
    type: actionTypes.PROFILE_DELETING_SUCCESS,
  };
};
export const deleteProfilePhoto = (imageUrl) => {
  var parts = imageUrl.split('/');
  var fileName = parts[parts.length - 1];
  return async (dispatch) => {
    await Agent.CloudFiles.delete(fileName, 0, 0, 0)
      .then((response) => {
        dispatch(deleteProfilePhotoAction());
        const user = JSON.parse(localStorage.getItem('tcl_user'));
        dispatch(updateUserImageUrl(user.id, null));
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};

export const uploadProfilePhoto = (file) => {
  return async (dispatch) => {
    await Agent.CloudFiles.upload(file, 3, 0, 0)
      .then((response) => {
        dispatch(uploadProfilePhotoAction(response.blobUri));
        const user = JSON.parse(localStorage.getItem('tcl_user'));
        dispatch(updateUserImageUrl(user.id, response.blobUri));
        return Promise.resolve(response.blobUri);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };
};
export const updateUserImageUrl = (id, url) => {
  return {
    type: actionTypes.USERS_USER_AVATAR_UPDATE,
    payload: {
      id,
      url,
    },
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (
  name,
  phoneNumber,
  email,
  applicationUpdateEmail,
  token,
  role,
  image,
  id,
  isInternal,
  appUserDetailsId
) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token,
    role,
    name,
    phoneNumber,
    email,
    applicationUpdateEmail,
    image,
    id,
    isInternal,
    appUserDetailsId,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout()); // make sure you execute the function with () so that it dispatchs the action
    }, expirationTime * 1000); // expiration time is in miliseconds
  };
};

export const setLocalStorageInfo = (response) => {
  var expirationDate = new Date();
  expirationDate.setHours(expirationDate.getHours() + 4);
  response.expirationDate = expirationDate;
  localStorage.setItem('tcl_user', JSON.stringify(response));
};

// export const logout = () => {
//   localStorage.removeItem('tcl_user');
//   return {
//     type: actionTypes.AUTH_LOGOUT,
//   };
// };

export const logout = (browserUserInfo) => {
  return async (dispatch) => {
    // Get user details from local storage
    const user = JSON.parse(localStorage.getItem('tcl_user'));

    // Check if user is logged in
    if (user && user.token) {
      try {
        // Call the logOutActivity API
        await Agent.UserActivity.logOutActivity(user.appUserDetailsId, {
          userAgent: navigator.userAgent.toString(),
          ...browserUserInfo,
        });
        
      } catch (error) {
        // Handle error if the logOutActivity API call fails
        console.error('Error logging out activity:', error);
      } finally {
        // Clear local storage
        localStorage.removeItem('tcl_user');

        // Dispatch the logout action
        dispatch({
          type: actionTypes.AUTH_LOGOUT,
        });
      }
    } else {
      // If the user is not logged in, just dispatch the logout action
      dispatch({
        type: actionTypes.AUTH_LOGOUT,
      });
    }
  };
};

export const register = (
  email,
  password,
  firstName,
  lastName,
  role,
  isEmailConfirmed,
  isInternal,
  companyName,
  browserUserInfo
) => {
  return async (dispatch) => {
    dispatch(authStart());
    if (isInternal) {
      await Agent.Account.registerinternal(
        email,
        password,
        firstName,
        lastName,
        role,
        isEmailConfirmed,
        companyName,
        browserUserInfo
      )
        .then((response) => {
          if (isEmailConfirmed) {
            history.push('/Users/load');
          } else {
            history.push(`/registerSuccess?email=${email}`);
          }

          return Promise.resolve(response);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    } else {
      await Agent.Account.register(
        email,
        password,
        firstName,
        lastName,
        role,
        companyName,
        browserUserInfo
      )
        .then((response) => {
          history.push(`/registerSuccess?email=${email}`);
          return Promise.resolve(response);
        })
        .catch((error) => {
          dispatch(authFail(error));
          return Promise.reject(error);
        });
    }
  };
};

export const auth = (email, password, browserUserInfo) => {
  return async (dispatch) => {
    dispatch(authStart());
    const verificationCode =
      localStorage.getItem(VERIFICATION_COOKIE) ||
      cookies.get(VERIFICATION_COOKIE);

    await Agent.Account.login(email, password, {
      ...browserUserInfo,
      userAgent: navigator.userAgent.toString(),
      verificationCode,
    })
      .then((response) => {
        dispatch(
          authSuccess(
            response.name,
            response.phoneNumber,
            response.email,
            response.applicationUpdateEmail,
            response.token,
            response.role,
            response.image,
            response.id,
            response.isInternal,
            response.appUserDetailsId
          )
        );
        setLocalStorageInfo(response);
        return Promise.resolve(response);
      })
      .catch((error) => {
        dispatch(authFail(error)); // for some reason, we can't just pass error. it fails
        return Promise.reject(error);
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const user = JSON.parse(localStorage.getItem('tcl_user'));
    if (!user || !user.token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(user.expirationDate);
      if (expirationDate > new Date()) {
        dispatch(
          authSuccess(
            user.name,
            user.phoneNumber,
            user.email,
            user.applicationUpdateEmail,
            user.token,
            user.role,
            user.image,
            user.id,
            user.isInternal,
            user.appUserDetailsId
          )
        );
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      } else {
        dispatch(logout());
      }
    }
  };
};
