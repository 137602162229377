import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditorDraftJs from '../../../../Common/Editor/EditorDraftJs';
import { LoadingButton } from '@mui/lab';
import Conversations from '../../../../Messages/Conversations';
import Agent from '../../../../../api/agent';
import { format, addMinutes } from 'date-fns';
import { useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

const Messages = ({
  studentId,
  name,
  isInternal,
  records,
  setRecords,
  studentName,
  draftMessageParent,
  setDraftMessageParent,
  agentName,
  role,
}) => {
  const [addNodeMode, setAddNodeMode] = useState(draftMessageParent.length > 0);
  const [rawHtml, setRawHtml] = useState(draftMessageParent);
  const [templates, setTemplates] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [updating, setUpdating] = useState(false);
  const [apiError, setApiError] = useState('');
  const [editorValidState, setEditorValidState] = useState(
    draftMessageParent.length > 0
  );
  const [subject, setSubject] = useState(`${studentId} - ${studentName}`);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  // const [errorMessage, setErrorMessage] = useState('');
  const [file, setFile] = useState('');
  const editorOnChange = (rawHtml, valid) => {
    setRawHtml(rawHtml);
    setDraftMessageParent(rawHtml);
    setEditorValidState(valid);
  };


// console.log(agentName)

  const addNote = () => {
    var msg = {
      content: rawHtml,
      studentId: studentId,
      sendEmail: true,
      file: file,
      attachmentFileName:'',
      noteType: 1,
      subject,
    };
    setUpdating(true);
    Agent.Messages.addStudentNoteAtt(msg)
      .then(() => {
        let temp = records.concat({
          content: rawHtml,
          from: name,
          noteType: 1,
          subject,
          attachmentFileName:file.name,
          dateSent: format(
            addMinutes(new Date(), new Date().getTimezoneOffset()),
            'MMMM d, yyyy h:mm a'
          ),
        });
        temp.sort(function (a, b) {
          return new Date(b.dateSent) - new Date(a.dateSent);
        });
        setRecords(temp);
        setFile('');
        setUpdating(false);
        setAddNodeMode(false);
        setEditorValidState(false);
        setDraftMessageParent('');
        setSuccessMessage(true);
        setTimeout(() => {
          setSuccessMessage(false);
        }, 3000);
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
        // setErrorMessage('An error occurred while sending the message.');
      });
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await Agent.Communication.getSingleEmailTemplate();
      setTemplates(response);
    } catch (error) {
      console.error(error);
      // setErrorMessage('An error occurred while fetching templates.');
    }
  };

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
  };

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
    if (isDialogOpen) {
      setApiError('');
    }
  };

  const sendFinalEmailWithTemp = async () => {
    const payload = {
      StudentId: studentId,
      TemplateId: selectedTemplate,
      noteType: 1,
    };
    try {
      await Agent.Messages.addemailWithTemplateStudentId(payload);
      toggleDialog();
    } catch (error) {
      console.error(error);
      setApiError(error.message);
    }
  };

  const handleSendEmail = () => {
    if (selectedTemplate) {
      setConfirmDialogOpen(true);
    } else {
      setApiError('Please select a template.');
    }
  };

  const handleConfirmSendWithTemplates = async () => {
    setConfirmDialogOpen(false);
    try {
      await sendFinalEmailWithTemp();
      setSuccessMessage(true);
      setTimeout(() => {
        setSuccessMessage(false);
      }, 3000);
    } catch (error) {
      console.error(error);
      // setErrorMessage(error.message || 'An error occurred while sending the email.');
    }
  };

  const handleSendWithTemplates = () => {
    if (templates.length > 0) {
      setIsDialogOpen(true);
    } else {
      setApiError('No templates available.');
    }
  };

  const handleCancelSendWithTemplates = () => {
    setConfirmDialogOpen(false);
  };

  return (
    <div className="w-full flex flex-col items-start ">
      <div className="bg-gray-100 px-4 py-10 rounded-lg  flex flex-col gap-0 items-center justify-center w-full  mt-4">
        <div className="flex flex-col gap-2 text-center text-2xl font-semibold pb-3 pt-3 w-2/3 divide-y-2 divide-slate-400/70">
          <div>
            {isInternal
              ? `Communications with ${
                  agentName?.length == 0 ? 'Student' : 'Agent'
                }`
              : 'Communications with TCL'}
          </div>
          <div></div>
        </div>

        <div className="w-full flex flex-col items-center pt-10">
          <div className="w-full flex flex-col items-right">
            <div className="flex justify-between w-full">
              <Button
                className="my-2 bg-indigo-100 hover:bg-indigo-100 hover:text-indigo-800 text-indigo-600 px-6 drop-shadow-md w-56 mb-6"
                endIcon={!addNodeMode ? <AddIcon /> : null}
                onClick={() => {
                  setAddNodeMode((prevState) => !prevState);
                }}
              >
                {addNodeMode ? 'Hide  Option' : 'Send  Email'}
              </Button>

              {!role.isStudent && !role.isAgent && (
                <Button
                  className="my-2 bg-indigo-100 hover:bg-indigo-100 hover:text-indigo-800 text-sky-600 px-6 drop-shadow-md w-56 mb-6"
                  endIcon={!isDialogOpen ? <AddIcon /> : null}
                  onClick={handleSendWithTemplates}
                >
                  {isDialogOpen ? 'Hide  Option' : 'Send With Templates'}
                </Button>
              )}
            </div>

            {addNodeMode && (
              <div className="flex flex-col gap-3 w-full ">
                <div className="">
                  <TextField
                    fullWidth
                    size="small"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    label="Subject"
                    required
                  />
                </div>
                <div>
                  <EditorDraftJs
                    editorOnChange={editorOnChange}
                    height={200}
                    html={draftMessageParent}
                  />
                </div>
                {/* <div>
                  <LoadingButton
                    loading={updating}
                    disabled={!editorValidState || !subject}
                    onClick={addNote}
                    className="bg-indigo-100 hover:bg-indigo-100 hover:text-indigo-800 text-indigo-600 px-6 drop-shadow-md"
                  >
                    Send Mail
                  </LoadingButton>
                </div> */}
                  <div className='flex flex-row justify-between'>
                    <div className="mb-4">
                      <LoadingButton
                            loading={updating}
                            disabled={!editorValidState || !subject}
                            onClick={addNote}
                            className="bg-indigo-100 hover:bg-indigo-100 hover:text-indigo-800 text-indigo-600 px-6 drop-shadow-md"
                          >
                            Send Mail
                          </LoadingButton>
                    </div>
                    {isInternal  &&  agentName?.length === 0   && (<div className="mb-4 mt-0 text-gray-700 px-2 py-1 rounded-md ">
                    <span className='font-semibold text-gray-700'>Attachment &nbsp;</span>
                    <input
                      //className='font-semibold text-gray-100 px-2 py-1 rounded-md bg-cyan-600'
                      type="file"
                      onChange={(e) =>
                        setFile(e.target.files[0])
                      }
                    />
                  </div>)}
                  <div className='w-[30%]'></div>
                  </div>
              </div>
            )}

            <Dialog open={isDialogOpen} onClose={toggleDialog}>
              <DialogTitle>Send Mail Using Preferred Template</DialogTitle>
              <DialogContent>
                <Select
                  id="template-select"
                  onChange={handleTemplateChange}
                  size="large"
                  label="Template Name"
                  focused
                  fullWidth
                  className="template-select"
                >
                  <MenuItem default value={''}>
                    Select
                  </MenuItem>
                  {templates.map((template) => (
                    <MenuItem key={template.id} value={template.templateId}>
                      {template.templateName}
                    </MenuItem>
                  ))}
                </Select>
              </DialogContent>
              <DialogActions>
                <Button onClick={toggleDialog}>Cancel</Button>
                <Button onClick={handleSendEmail}>Send</Button>
              </DialogActions>

              {apiError && (
                <div className="mb-4 text-center text-xl text-red-600">
                  <h2>{apiError}</h2>
                </div>
              )}
            </Dialog>

            <Dialog
              open={confirmDialogOpen}
              onClose={handleCancelSendWithTemplates}
            >
              <DialogTitle>Confirm Send With Templates</DialogTitle>
              <DialogContent>
                <p>Are you sure you want to send with templates?</p>
              </DialogContent>

              <DialogActions className="flex justify-between mt-4">
                <Button
                  onClick={handleCancelSendWithTemplates}
                  className="btn btn-warning px-4 py-2"
                >
                  No
                </Button>
                <Button
                  onClick={handleConfirmSendWithTemplates}
                  className="btn btn-success px-4 py-2"
                >
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          <div className="w-full">
            <Conversations
              messages={records.filter((item) => item.noteType == 1)}
              user={name}
              hideReplyOption={true}
            />
          </div>

          {successMessage && (
            <div className="fixed bottom-4 left-50 z-50 p-4 mb-4 mx-auto bg-green-500 text-white rounded-md shadow-lg">
              Email sent successfully!Please refresh  to see changes.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Messages;
