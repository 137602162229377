import React, { useState } from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

const MySnackbarAlert = ({
  opnSuccess,
  opnFailure,
  message,
  opnSuccessParent,
  opnFailureParent,
  autoHideDuration = 6000,
}) => {
  const [openSuccess, setOpenSuccess] = useState(opnSuccess);
  const [openFailure, setOpenFailure] = useState(opnFailure);

  const handleCloseSuccess = (event, reason) => {
    opnSuccessParent(false);
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    opnFailureParent(false);
    if (reason === 'clickaway') {
      return;
    }
    setOpenFailure(false);
  };
  return (
    <>
      {' '}
      <Snackbar
        open={openFailure}
        autoHideDuration={10000}
        onClose={handleCloseFailure}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert onClose={handleCloseFailure} severity="error">
          {message}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openSuccess}
        autoHideDuration={autoHideDuration}
        onClose={handleCloseSuccess}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Alert onClose={handleCloseSuccess} severity="success">
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MySnackbarAlert;
