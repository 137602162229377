import * as actionTypes from '../actions/actionTypes';
// import { updateObject } from '../utility';
import initialState from './initialState';

const reducer = (state = initialState.students, action) => {
  switch (action.type) {
    case actionTypes.ADD_STUDENT: {
      const newStudent = {
        ...action.studentData,
        id: action.studentId,
      };
      //return updateObject(state, )
      return {
        ...state,
        students: state.students.concat(newStudent),
      };
    }
    case actionTypes.FETCH_STUDENT: {
      console.log(action.payload.students);
      return action.payload.students;
      // return updateObject(state, {
      //   students: action.payload.students,
      // });
    }
    default: {
      return state;
    }
  }
};

export default reducer;
