/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import EditorDraftJs from '../../Common/Editor/EditorDraftJs';
import Agent from '../../../api/agent';
import { addMinutes, format } from 'date-fns';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import parse from 'html-react-parser';

const LeadMessages = ({
  messageRecords,
  studentLeadId,
  handleAddSummaryRecord,
  studentLeadName,
  users,
  setStudentLeadsNotes,
  appUserId,
  appUserDetailsId,
}) => {
  const [addNoteMode, setAddNoteMode] = useState(false);
  const [subject, setSubject] = useState(
    `${studentLeadId} - ${studentLeadName}`
  );
  const [newNote, setNewNote] = useState({
    subject: subject,
    content: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [templates, setTemplates] = useState([]);
  const [isMessageDialogOpen, setIsMessageDialogOpen] = useState(false);
  const [apiError, setApiError] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [file, setFile] = useState('');
  const handleAddNoteClick = () => {
    setAddNoteMode(true);
    //setIsMessageDialogOpen(true);
  };
  console.log('messageRecords', messageRecords);
  const toggleMessageDialog = () => {
    setIsMessageDialogOpen(!isMessageDialogOpen);
    if (isMessageDialogOpen) {
      setApiError('');
    }
  };

  const getUserName = (appUserDetailsId) => {
    const user = users.find(
      (user) => user.appUserDetailsId === appUserDetailsId
    );
    return user ? user.name : '';
  };

  const handleAddNote = async () => {
    if (!newNote.content) {
      return;
    }
    const currentDate = new Date().toISOString();

    try {
      const payload = {
        subject: subject,
        content: newNote.content,
        studentLeadId: studentLeadId,
        file: file,
        attachmentFileName: '',
        sendEmail: true,
        noteType: 1,
      };
      console.log('payload', payload);
      await Agent.Messages.addStudentLeadNote(payload)
        .then((res) => {
          setSuccessMessage('Mail sent successfully! Reload  to see changes.');
          let temp = messageRecords.concat({
            content: newNote.content,
            noteType: 1,
            subject,
            attachmentFileName: file.name,
            appUserDetailsId: appUserDetailsId,
            dateSent: format(
              addMinutes(new Date(), new Date().getTimezoneOffset()),
              'MMMM d, yyyy h:mm a'
            ),
          });

          setStudentLeadsNotes(temp);
          setNewNote({
            subject: subject,
            content: '',
          });
          setFile('');
          setAddNoteMode(false);
          toggleMessageDialog();
          setTimeout(() => {
            setSuccessMessage(false);
          }, 3000);
        })
        .catch((error) => {
          console.log('Mail Sent Error: ', error);
        });

      const updatedRecords = [
        ...messageRecords,
        {
          subject: newNote.subject,
          content: newNote.content,
          dateSent: currentDate,
        },
      ];

      //handleAddSummaryRecord(updatedRecords);

      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);

      setNewNote({
        subject: subject,
        content: '',
      });
      setAddNoteMode(false);
      toggleMessageDialog();

      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const toggleDialog = () => {
    setIsDialogOpen(!isDialogOpen);
    if (isDialogOpen) {
      setApiError('');
    }
  };

  const handleTemplateChange = (event) => {
    setSelectedTemplate(event.target.value);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const response = await Agent.Communication.getSingleEmailTemplate();
      setTemplates(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSendEmail = async () => {
    if (selectedTemplate) {
      setConfirmDialogOpen(true);
    } else {
      setApiError('Please select a template.');
    }
  };

  const handleConfirmSendWithTemplate = async () => {
    setConfirmDialogOpen(false);
    try {
      const payload = {
        studentLeadId: studentLeadId,
        TemplateId: selectedTemplate,
        noteType: 1,
      };
      await Agent.Messages.addemailWithTemplateStudentsLead(payload);
      setSuccessMessage('Email sent successfully! Reload  to see changes.');

      toggleDialog();
      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error(error);
      setApiError(
        error.message || 'An error occurred while sending the email.'
      );
    }
  };

  return (
    <div>
      <div>
        <h2 className="text-2xl text-center font-semibold mb-1 text-gray-700">
          Communications with student
        </h2>

        {addNoteMode && (
          <div className="mt-6">
            <div className="block mb-4">
              <TextField
                fullWidth
                size="small"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                label="Message Subject"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block font-bold mb-1">Content</label>
              <EditorDraftJs
                editorOnChange={(html, valid) =>
                  setNewNote({
                    ...newNote,
                    content: html,
                  })
                }
                height={200}
                placeHolder="Write your message here"
                html={newNote.content}
              />
            </div>
            <div className="flex flex-row justify-between">
              <div className="mb-4">
                <button
                  type="button"
                  className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                  onClick={handleAddNote}
                >
                  Send Now
                </button>
              </div>
              <div className="mb-4 mt-0 text-gray-700 px-2 py-1 rounded-md ">
                <span className="font-semibold text-gray-700">
                  Attachment &nbsp;
                </span>
                <input
                  //className='font-semibold text-gray-100 px-2 py-1 rounded-md bg-cyan-600'
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
              <div className="w-[40%]"></div>
            </div>
          </div>
        )}
        <div className="flex justify-around mb-4">
          {!addNoteMode && (
            <button
              type="button"
              onClick={handleAddNoteClick}
              className="bg-blue-500 text-white px-4 mt-2 py-2 rounded-md hover:bg-blue-600"
            >
              Send Mail
            </button>
          )}
          {!addNoteMode && (
            <button
              type="button"
              onClick={toggleDialog}
              className="bg-sky-500 text-white px-4 mt-2 py-2 rounded-md hover:bg-sky-700"
            >
              Mail with template
            </button>
          )}
        </div>

        <div className="space-y-4">
          {messageRecords
            .slice()
            .reverse()
            .map((record, index) => (
              <div
                key={index}
                className="flex flex-col p-4 bg-white shadow-md rounded-lg"
              >
                <div className="flex justify-between">
                  <div className="mb-2 text-blue-700">
                    {/* <strong className="text-gray-800">Subject / Template Name:</strong>{' '} */}
                    {record.subject}
                  </div>
                  <div className="text-blue-700">
                    {getUserName(record?.appUserDetailsId)} ,&nbsp;
                    {format(new Date(record.dateSent), 'yyyy-MM-dd')}
                  </div>
                </div>
                <div className="mb-2">
                  {/* <strong className="text-gray-800">Content:</strong> */}
                  <div
                    className="text-gray-600"
                    dangerouslySetInnerHTML={{
                      __html: record.content,
                    }}
                  />
                </div>
                <div>
                  {record?.attachmentFileName ? (
                    <span className="text-blue-600">
                      {' '}
                      <strong className="text-teal-600">
                        Attachment:
                      </strong>{' '}
                      {record?.attachmentFileName}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      <Dialog open={isDialogOpen} onClose={toggleDialog}>
        <DialogTitle>Send Mail Using Preferred Template</DialogTitle>
        <DialogContent>
          <Select
            id="template-select"
            onChange={handleTemplateChange}
            size="large"
            label="Template Name"
            focused
            fullWidth
            className="template-select"
          >
            <MenuItem default value={''}>
              Select
            </MenuItem>
            {templates.map((template) => (
              <MenuItem key={template.id} value={template.templateId}>
                {template.templateName}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog}>Cancel</Button>
          <Button onClick={handleSendEmail}>Send</Button>
        </DialogActions>
        {apiError && (
          <div className="mb-4 text-center text-xl text-red-600">
            <h2>{apiError}</h2>
          </div>
        )}
      </Dialog>
      {/* <Dialog open={isMessageDialogOpen} onClose={toggleMessageDialog}>
        <DialogTitle>Send New Mail</DialogTitle>
        <DialogContent>
          <label className="block font-bold mb-1">Subject</label>
          <TextField
            className="block font-bold mt-6"
            fullWidth
            size="small"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            label="Message Subject"
            required
          />
          <label className="block font-bold mb-1">Content</label>
          <EditorDraftJs
            editorOnChange={(html) => setNewNote({ ...newNote, content: html })}
            height={200}
            placeHolder="Write your message here"
            html={newNote.content}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleMessageDialog}>Cancel</Button>
          <Button onClick={handleAddNote}>Send Message</Button>
        </DialogActions>
        {apiError && (
          <div className="mb-4 text-center text-xl text-red-600">
            <h2>{apiError}</h2>
          </div>
        )}
      </Dialog> */}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirm Sending Email</DialogTitle>
        <DialogContent>
          <p className="text-gray-700">
            Are you sure you want to send the email?
          </p>
        </DialogContent>
        <DialogActions className="flex justify-between mt-4">
          <Button
            className="btn btn-warning px-4 py-2"
            onClick={() => setConfirmDialogOpen(false)}
          >
            NO
          </Button>
          <Button
            className="btn btn-success px-4 py-2"
            onClick={handleConfirmSendWithTemplate}
          >
            YES
          </Button>
        </DialogActions>
      </Dialog>

      {successMessage && (
        <div className="fixed bottom-4 left-50 z-50 p-4 mb-4 mx-auto bg-green-500 text-white rounded-md shadow-lg">
          {successMessage}
        </div>
      )}

      {errorMessage && (
        <div className="fixed bottom-4 left-50 z-50 p-4 mb-4 mx-auto bg-red-500 text-white rounded-md shadow-lg">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    departments: state.departments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadMessages);
