import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Grid, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import { Countries } from '../../../Util/Util';
import { EDUCATION_LEVELS, INTAKES } from '../../../Util/Constants';
import { yearList } from '../MockData/MockData';
import {
  AutocompleteSelectionMenu,
  DatePickerField,
  intakeYearList,
  SelectionMenu,
  FormatTitle,
  getRows,
} from '../Helper/HelperFunction';
import Agent from '../../../api/agent.js';

const ModifiedIntakeMonth = [
  { id: 0, type: 'All Intakes', typeShort: 'All' },
  ...INTAKES.map((item) => item),
];
const ModifiedEducationLevels = [
  { id: 0, type: 'All Levels' },
  ...EDUCATION_LEVELS.map((item) => item),
];

const appTypes = [
  'applicationSubmitted',
  'offerHolder',
  'rejection',
  'conversion',
  'pendingApplication',
  'cancellation',
];

const WorkGroupSummary = () => {
  const [appData, setAppData] = useState();
  const [branchList, setBranchList] = useState();
  const [AgentList, setAgentList] = useState();
  const [isApiLoading, setApiLoading] = useState(true);
  const [universities, setUniversities] = useState([]);
  const [cosmosAdminUsers, setCosmosAdminUsers] = useState([]);
  const [pickedDate, setPickedDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [payload, setPayload] = useState({
    year: 2022,
    intakeMonth: 0,
    intakeYear: 0,
    agent: 0,
    branch: 0,
    university: 0,
    courseLevel: 0,
    admin: 0,
    nationalities: [],
    startDate: null,
    endDate: null,
    // startDate: '2022-01-01',
    // endData: '2023-01-01',
  });

  // const handleFilterValue = (e) => {
  //   setPayload({ ...payload, [e.target.name]: e.target.value });
  // };

  // const classes = useStyles();

  //API CALL TO GET REPORT DATA
  useEffect(() => {
    Agent.Report.applicationSummary(payload).then((res) => {
      setAppData(res.data.applicationSummaryData);
      setApiLoading(false);
    });
    Agent.Cache.getBranchOffices().then(setBranchList);
    Agent.Agents.list().then(setAgentList);
  }, [payload]);

  // API CALL TO GET ADMIN FILTER DATA

  useEffect(() => {
    (async () => {
      Agent.Cosmos.getUsers()
        .then((users) => {
          setCosmosAdminUsers(
            users.filter((item) => {
              return (
                (item.usertype == 1 ||
                  item.usertype == 2 ||
                  item.usertype == 3) &&
                !item.isdisabled
              );
            })
          );
        })
        .catch((err) => {
          console.log('err', err);
        });
    })();
    Agent.Universities.list()
      .then((req) => {
        setUniversities(req);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  //Column Field Logic Starts
  const columns = [
    {
      field: 'title',
      headerName: '',
      width: 220,
      renderCell: (params) => (
        <Typography color="#0081C9" fontSize={18} fontWeight={500}>
          {params.row.title}
        </Typography>
      ),
    },
    ...INTAKES.map((month) => ({
      field: month.typeShort,
      headerName: month.typeShort,
      width: 100,
    })),
    { field: 'Total', headerName: 'Total', width: 120 },
  ];

  //Row Field Logic Starts
  let rowData = [];

  const getRowData = () => {
    appTypes.forEach((item) => {
      let temp = getRows(item, appData);
      rowData.push({ [item]: temp });
    });
  };
  console.log('rowData', rowData);
  !isApiLoading && getRowData();

  const rows = rowData.map((item) => {
    const key = Object.keys(item)[0];
    const data = item[key];
    return { id: key, title: FormatTitle(key), ...data };
  });

  // HANDLE RETURN DATA FROM SELECT MENU FILTER COMPONENT
  const handleSelectionMenu = (name, value) => {
    setPayload((prev) => {
      return { ...prev, [name]: Number(value) || 0 };
    });
    // console.log('name', name);
    // console.log('value', value);
  };
  // HANDLE RETURN DATA FROM AUTOCOMPLETE FILTER COMPONENT
  const handleSelect = (name, value) => {
    setPayload((prev) => {
      return { ...prev, [name]: Number(value.id) || 0 };
    });
  };

  // HANDLE RETURN DATA FOR MULTIPLE NATIONALITIES FROM FILTER COMPONENT
  const handleNationSelect = (name, value) => {
    console.log(value, value);
    const temp = [...value.map((item) => item.id)];
    !value == 0
      ? setPayload((prev) => {
          return { ...prev, nationalities: temp };
        })
      : setPayload((prev) => {
          return { ...prev, nationalities: [] };
        });
  };

  // HANDLE DATE PICKER

  const handleStartDateChange = (date) => {
    setEnable();
    setPayload({
      ...payload,
      startDate: date,
    });
  };
  const handleEndDateChange = (date) => {
    setEnable();
    setPayload({
      ...payload,
      endDate: date,
    });
  };

  const setEnable = () => {
    setPayload({ ...payload, year: 0 });
  };

  return (
    <div style={{ width: '100%' }}>
      <Grid container direction={'column'}>
        <Grid item>
          <h4
            style={{
              textTransform: 'uppercase',
              color: '#282A3A',
              textAlign: 'center',
            }}
          >{`Work Group Summary`}</h4>
        </Grid>
        <Grid
          container
          justifyContent={'center'}
          alignItems="center"
          sx={{ px: 2 }}
        >
          <Grid item>
            <AutocompleteSelectionMenu
              name="admin"
              label="Admin"
              options={cosmosAdminUsers}
              onChange={handleSelect}
            />
          </Grid>
          <Grid item>
            <AutocompleteSelectionMenu
              name="nationalities"
              label="Nationalities"
              options={Countries}
              onChange={handleNationSelect}
              multiple={true}
            />
          </Grid>
          <Grid item>
            <SelectionMenu
              name="intakeMonth"
              label="Intake Month"
              options={ModifiedIntakeMonth}
              onChange={handleSelectionMenu}
            ></SelectionMenu>
          </Grid>
          <Grid item>
            <SelectionMenu
              name="intakeYear"
              label="Intake Year"
              options={intakeYearList}
              onChange={handleSelectionMenu}
            ></SelectionMenu>
          </Grid>
          <Grid item>
            <SelectionMenu
              name="year"
              label={payload.year}
              options={yearList}
              onChange={handleSelectionMenu}
            ></SelectionMenu>
          </Grid>
          <Grid item>
            <AutocompleteSelectionMenu
              name="university"
              label="University"
              options={universities}
              onChange={handleSelect}
            />
          </Grid>
          <Grid item>
            <AutocompleteSelectionMenu
              name="branch"
              label="Branch"
              options={branchList}
              onChange={handleSelect}
            />
          </Grid>
          <Grid item>
            <AutocompleteSelectionMenu
              item="companyName"
              name="agent"
              label="Agents"
              options={AgentList}
              onChange={handleSelect}
            ></AutocompleteSelectionMenu>
          </Grid>
          <Grid item>
            <AutocompleteSelectionMenu
              name="studyDestination"
              label="Study Destination"
              options={Countries}
              onChange={handleSelect}
            />
          </Grid>
          <Grid item>
            <SelectionMenu
              name="courseLevel"
              label="Course Level"
              options={ModifiedEducationLevels}
              onChange={handleSelectionMenu}
            ></SelectionMenu>
          </Grid>
          <Grid item>
            <DatePickerField
              name="startDate"
              label="Start Date"
              selected={payload.startDate}
              // options={ModifiedEducationLevels}
              onChange={handleStartDateChange}
            ></DatePickerField>
          </Grid>
          <Grid item>
            <DatePickerField
              name="endDate"
              label="End Date"
              selected={payload.endDate}
              // options={ModifiedEducationLevels}
              onChange={handleEndDateChange}
            ></DatePickerField>
          </Grid>
        </Grid>
        <Grid sx={{ mt: 1 }}>
          <DataGrid
            rows={rows}
            columns={columns}
            hideFooter
            ColumnFilteredIcon
            autoHeight
            disableColumnFilter
            // className={classes.root}
            style={{
              minHeight: '400px',
              borderRadius: '16px',
              fontSize: '17px',
              color: '#263159',
              marginBottom: '20px',
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default WorkGroupSummary;
