import React from "react";
import { Skeleton } from "@mui/material";

const LoadingView = () => {
  return (
    <div>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
    </div>
  );
};

export default LoadingView;
