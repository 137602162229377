import { Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import LoadingView from '../Common/Loading/LoadingView';
import MessageList from './MessageList';
import Conversations from './Conversations';
import { connect } from 'react-redux';
import Agent from '../../api/agent';
import MessageProfile from './MessageProfile';
import Chat from './Chat';
import { isEmpty } from 'lodash';

const Message = ({ name, appUserId, isInternal }) => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState({ contents: [], subject: '' });
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [agent, setAgent] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [userList, setUserList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalPages, setTotalPages] = useState(1);
  const [totalrows, setTotalRows] = useState(0);

  const getParams = () => {
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', pageSize.toString());
    return params;
  };
  useEffect(() => {
    Agent.Messages.totalMsgCount(appUserId)
    .then((res)=>{
      console.log('msg count',res);
      setTotalRows(res);
    })
  },[])

  useEffect(() => {
    const params = getParams();
    Agent.Messages.allMsgDetailsInfo(params)
      .then((msg) => {
        console.log('msg list', msg);
        //const { pagination, data } = msg;
        //setTotalPages(pagination.totalPages);
        //setTotalRows(pagination.totalItems);
        setMessages(msg);
        setLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setLoaded(true);
      });
  }, [pageNumber]);

  useEffect(() => {
    Agent.Users.allUserList()
      .then((usrs) => {
        console.log('users', usrs);
        setUserList(usrs);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  async function openMessage(id) {
    setLoading(true);
    await Agent.Messages.getAllAgentInfoMsg(id)
      .then((msg) => {
        console.log('msg details', msg);
        if (msg.contents[0]?.fromId == appUserId) {
          if (isInternal) {
            Agent.Agents.getAgentByAppUserId(msg.contents[0].toId)
              .then((agent) => {
                setAgent(agent);
              })
              .catch((error) => {
                console.log(error);
                setAgent('');
                setUserProfile('');
                if (error.search('Agent Not Found!!')) {
                  Agent.Users.getUser(msg.contents[0]?.toId)
                    .then((user) => {
                      setUserProfile(user);
                      console.log('appUser details', user);
                    })
                    .catch((error) => {
                      console.log(error);
                      setUserProfile('');
                      setAgent('');
                    });
                }
              });
          } else {
            Agent.Users.getUser(msg.contents[0]?.toId)
              .then((user) => {
                setUserProfile(user);
                console.log('appUser details', user);
              })
              .catch((error) => {
                console.log(error);
                setUserProfile('');
                setAgent('');
              });
          }
        } else {
          if (isInternal) {
            Agent.Agents.getAgentByAppUserId(msg.contents[0].fromId)
              .then((agent) => {
                setAgent(agent);
              })
              .catch((error) => {
                console.log('agent error', error);
                setAgent('');
                setUserProfile('');
                if (error.search('Agent Not Found!!')) {
                  Agent.Users.getUser(msg.contents[0]?.fromId)
                    .then((user) => {
                      setUserProfile(user);
                      console.log('appUser details', user);
                    })
                    .catch((error) => {
                      console.log(error);
                      setUserProfile('');
                      setAgent('');
                    });
                }
              });
          } else {
            Agent.Users.getUser(msg.contents[0]?.fromId)
              .then((user) => {
                setUserProfile(user);
                console.log('appUser details', user);
              })
              .catch((error) => {
                console.log(error);
                setUserProfile('');
                setAgent('');
              });
          }
        }

        let temp = msg.contents;
        temp.sort(function (a, b) {
          return new Date(b.dateSent) - new Date(a.dateSent);
        });
        setMessage({ contents: temp, subject: msg.subject });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        //debugger;
      });
  }

  return loaded ? (
    messages?.length > 0 ? (
      <div className="flex flex-start justify-between gap-4 w-full h-full pb-8">
        <div className="relative w-[23%]">
          <div>
            <MessageList
              messages={messages}
              userList={userList}
              openMessage={openMessage}
              appUserId={appUserId}
              isInternal={isInternal}
              setIsMessageOpen={setIsMessageOpen}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              pageSize={pageSize}
              setPageSize={setPageSize}
              totalPages={totalPages}
              setTotalPages={setTotalPages}
              totalrows={totalrows}
              setTotalRows={setTotalRows}
            />
          </div>
        </div>
        <div className="relative w-[55%]">
          {loading ? (
            <Grid>
              <LoadingView />
            </Grid>
          ) : (
            <div className="relative">
              <Chat
                messages={message.contents}
                mainMessageSubject={message.subject}
                user={name}
                isMessageOpen={isMessageOpen}
              />
            </div>
            // <Grid >
            //   <Conversations
            //     messages={message.contents}
            //     mainMessageSubject={message.subject}
            //     user={name}
            //   />
            // </Grid>
          )}
        </div>
        <div className="relative w-[22%]">
          <MessageProfile
            agent={agent}
            userProfile={userProfile}
            openMessage={openMessage}
            isMessageOpen={isMessageOpen}
          />
        </div>
      </div>
    ) : (
      <Typography>There are no messages for you at the moment</Typography>
    )
  ) : (
    <div>Loading..</div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    isInternal: state.auth.isInternal,
    name: state.auth.name,
  };
};

export default connect(mapStateToProps, null)(Message);
