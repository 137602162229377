export const newStudent = {
  agentAppUserId: 0,
  createdByAppUserId: 0,
  countryOfResidence: null,
  profile: {
    id: 0,
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    issueDate: null,
    expiryDate: null,
    profileComplete: false,
  },
  educationRecords: [],
  languageRecords: [],
};
export const initialStudentState = {
  agentAppUserId: 0,
  createdByAppUserId: 0,
  countryOfResidence: null,
  profile: {
    id: 0,
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    issueDate: null,
    expiryDate: null,
    profileComplete: false,
  },
  educationRecords: [],
  languageRecords: [],
};
