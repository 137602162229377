/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { AGENTSOURCES, MUI_LICENSE_KEY } from '../../Util/Constants';
// import { useStyles } from '../Common/useStyles/useStyles';
import {
  LicenseInfo,
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import * as actions from '../../store/actions/index';
// import { withRouter } from 'react-router-dom';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { connect } from 'react-redux';

import { Countries, getAgentStatusNameFromNumber } from '../../Util/Util';
import AddMessage from '../Messages/AddMessage';
// import Agent from '../../api/agent';
import LoadingView from '../Common/Loading/LoadingView';
import MyTooltip from '../Common/Tooltip/MyTooltip';
import { AGENTSTATUS } from '../../Util/Constants';
import { Description, RestoreTwoTone } from '@mui/icons-material';
import AgentNotes from './AgentNotes/AgentNotes';
import DatePicker, { registerLocale } from 'react-datepicker';
import { startOfDay, addHours, addMonths, format } from 'date-fns';
// eslint-disable-next-line import/namespace, import/default
import Agent from '../../api/agent';
// import Agent from './../../api/agent';

function CustomToolbar(show) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {show && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

const AgentList = ({
  isInternal,
  role,
  permissions,
  users,
  loadUsers,
  userName,
}) => {
  // const classes = useStyles();

  const [id, setId] = useState('');
  const [company, setCompany] = useState('');
  const [ownerName, setOwnerName] = useState('');
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [agentLoaded, setAgentLoaded] = useState(false);
  const [openAddMessage, setOpenAddMessage] = useState(false);

  const [agent, setAgent] = useState();
  const [agents, setAgents] = useState([]);
  const [openAddNote, setOpenAddNote] = useState(false);
  const [agentId, setAgentId] = useState('');
  const [start, setStart] = useState(addMonths(new Date(), -1));
  const [end, setEnd] = useState(new Date());
  const [dateType, setDateType] = useState(1);

  const addMessage = (agentId, name) => {
    setAgent({ ...agent, id: agentId, companyName: name });
    setOpenAddMessage(true);
  };

  const getData = (filter = false) => {
    setLoading(true);
    Agent.Agents.list(getParams(filter)).then((records) => {
      getRows(records);
    });
  };
  const handleNote = (row) => {
    console.log('row', row);
    setOpenAddNote(true);
    setAgentId(row.id);
    //setMsgName(row.firstName + ' ' + row.lastName);
  };
  const resetFilter = () => {
    setId('');
    setStart(addMonths(new Date(), -1));
    setEnd(new Date());
    setDateType(1);
    setStatus(0);
    setCompany('');
    setOwnerName('');
    getData(false);
  };

  //const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v })); handle way to update an object state
  const getParams = (filter) => {
    const params = new URLSearchParams();
    //const params = new useSearchParams();
    // const token = searchParams.get('token');
    params.append('id', filter ? id.trim() : '');
    params.append('company', filter ? company.trim() : '');
    params.append('ownerName', filter ? ownerName.trim() : '');
    params.append('status', filter ? status.toString() : '0');
    if (filter) {
      if (start) {
        params.append('startDate', new Date(start).toDateString());
      }
    } else {
      params.append('startDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (end) {
        params.append('endDate', new Date(end).toDateString());
      }
    } else {
      params.append('endDate', new Date().toDateString());
    }
    params.append('dateType', dateType);
    return params;
  };

  useEffect(() => {
    if (isInternal) {
      if (users.length == 0) {
        loadUsers()
          .then(() => {
            //  getData();
          })
          .catch((error) => {
            console.log(error);
            //debugger;
          });
      } else {
        getData();
      }
    } else {
      getData();
    }
  }, [users]);

  useEffect(() => {
    getData();
  }, []);

  const getRows = (rows) => {
    let agentRows = [];
    rows.map((agent) => {
      agentRows.push({
        id: agent.id,
        status: agent.status,
        statusDesc: getAgentStatusNameFromNumber(agent.status),
        created: format(new Date(agent.created), 'dd/MM/yy'),
        adminName: agent.adminName,
        bdm:
          agent.businessDevAppUserDetailsId && users.length > 0
            ? users.find(({ id }) => id === agent.businessDevAppUserDetailsId)
                .name
            : '',
        companyName: agent.companyName,
        ownerName: agent.ownerName,
        companyEmail: agent.companyEmail,
        applicationUpdateEmail: agent.applicationUpdateEmail,
        ownerEmailAddress: agent.ownerEmailAddress,
        source: agent.source
          ? AGENTSOURCES.find(({ id }) => id == agent.source).type
          : '',
        student: agent.noOfStudents == 0 ? '' : agent.noOfStudents,
        agentNationality:
          agent.agentNationality && agent.agentNationality > 0
            ? Countries.find(({ id }) => id == agent.agentNationality).name
            : '',
        country:
          agent.country && agent.country > 0
            ? Countries.find(({ id }) => id === agent.country).name
            : '',
      });
    });
    setAgents(agentRows);
    setAgentLoaded(true);
    setLoading(false);
  };

  let columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 60,
      hide: true,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => params.value,
    },
    {
      field: 'companyName',
      headerName: 'Company',
      hide: false,
      width: 360,
      renderCell: (params) => (
        <MyTooltip title={params.row.companyName} color="primary">
          <Typography className="clickable">
            {params.row.companyName}
          </Typography>
        </MyTooltip>
      ),
    },
    {
      field: 'ownerName',
      headerName: 'Owner',
      hide: false,
      width: 360,
      renderCell: (params) => (
        <MyTooltip title={params.row.ownerName} color="primary">
          <Typography className="clickable">{params.row.ownerName}</Typography>
        </MyTooltip>
      ),
    },
    {
      field: 'agentNationality',
      headerName: 'Agent Nationality',
      width: 150,
      hide: true,
    },
    { field: 'country', headerName: 'Company HQ', width: 220 },
    { field: 'created', headerName: 'Added', width: 80, hide: true },
    {
      field: 'statusDesc',
      headerName: 'Status',
      width: 200,
      hide: false,
    },
    { field: 'adminName', headerName: 'Admin', width: 180, hide: false },
    { field: 'bdm', headerName: 'BDM', width: 180, hide: false },
    { field: 'source', headerName: 'Source', width: 120, hide: true },
    { field: 'companyEmail', headerName: 'Company Email', hide: true },
    { field: 'ownerEmailAddress', headerName: 'Owner Email', hide: true },
    { field: 'applicationUpdateEmail', headerName: 'Update Email', hide: true },
    {
      field: 'student',
      headerName: 'Student',
      width: 120,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <Grid container>
          {/* {!(role.isApplicationTeam || role.isApplicationTeamLeader) && ( */}
          <Grid item xs={4}>
            <Link to={`/students?agentid=${params.row.id}`}>
              {params.row.student}
            </Link>
          </Grid>
          {/* )} */}
          {isInternal && params.row.status == 5 && (
            <Grid item xs={8}>
              <strong>
                <MyTooltip
                  icon={<AddIcon color="success" />}
                  title="Add New Student"
                >
                  <IconButton
                    as={Link}
                    to={`/students/register/${params.row.id}`}
                    size="large"
                  >
                    <AddIcon color="success" />
                  </IconButton>
                </MyTooltip>
              </strong>
            </Grid>
          )}
        </Grid>
      ),
    },
    {
      field: 'message',
      headerName: 'Message',
      width: 130,
      hide: role.isApplicationTeam || role.isApplicationTeamLeader,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <strong>
          <Button
            disabled={role.isApplicationTeam || role.isApplicationTeamLeader}
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<EmailIcon />}
            onClick={() => addMessage(params.row.id, params.row.companyName)}
          >
            Message
          </Button>
        </strong>
      ),
    },
    {
      field: 'note',
      headerName: 'Note',
      headerAlign: 'center',
      align: 'center',
      width: 120,
      hide: !isInternal,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <IconButton
          disabled={!isInternal}
          onClick={() => handleNote(params.row)}
          size="large"
        >
          <Description />
        </IconButton>
      ),
    },
  ];

  return agentLoaded ? (
    <>
      <Grid container direction="column" spacing={2}>
        {/* First Row */}
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              size="small"
              label="Partner Id"
              value={id}
              onChange={(e) => setId(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              size="small"
              label="Company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              size="small"
              label="Owner Name"
              value={ownerName}
              onChange={(e) => setOwnerName(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                size="small"
                native
                label="Status"
                placeholder="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value={0}>All</option>
                {role.isApplicationTeam || role.isApplicationTeamLeader
                  ? AGENTSTATUS.filter((item) => item.displayInApp).map(
                      (item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      )
                    )
                  : AGENTSTATUS.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.type}
                      </option>
                    ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
  
        {/* Second Row */}
        <Grid item container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={2}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(start && new Date(start)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setStart(newVal);
              }}
              customInput={
                <TextField
                  label="From Date"
                  fullWidth
                  value={start}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(end && new Date(end)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setEnd(newVal);
              }}
              customInput={
                <TextField
                  label="To Date"
                  fullWidth
                  value={end}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>
          {isInternal && (
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <InputLabel>Date Type</InputLabel>
                <Select
                  size="small"
                  native
                  label="Date Type"
                  placeholder="Date Type"
                  value={dateType}
                  onChange={(e) => setDateType(e.target.value)}
                >
                  <option value={1}>Agent Added</option>
                  <option value={2}>Profile Modified</option>
                  <option value={3}>Status Changed</option>
                </Select>
              </FormControl>
            </Grid>
          )}
  
          <Grid item xs={12} sm={6} md={2} className='mx-6'>
            <Button
              onClick={getData}
              variant="contained"
              className="bg-sky-700 hover:bg-sky-600"
              fullWidth
            >
              Apply Filters
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Button
              onClick={resetFilter}
              className="bg-teal-600 hover:bg-teal-500 text-white"
              fullWidth
            >
              <RestoreTwoTone />
              Reset Filters
            </Button>
          </Grid>
        </Grid>
  
        {/* DataGrid */}
        <Grid item style={{ height: 800, width: '100%' }}>
          <DataGridPro
            rows={agents}
            columns={columns}
            loading={loading}
            density="compact"
            hideFooterSelectedRowCount
            components={{
              Toolbar: () => CustomToolbar(permissions.CanDownloadData),
            }}
            onCellClick={(params, event) => {
              event.defaultMuiPrevented = true;
              if (
                params.colDef.field === 'companyName' &&
                !role.isApplicationTeam &&
                !role.isApplicationTeamLeader
              ) {
                window.open(`/agents/${params.id}`, '_blank');
              }
            }}
          />
        </Grid>
      </Grid>
  
      {/* AddMessage and AgentNotes modals */}
      {openAddMessage && (
        <AddMessage
          open={openAddMessage}
          cancel={setOpenAddMessage}
          agentId={agent.id}
          toId={0}
          toName={agent.companyName}
        />
      )}
      {openAddNote && (
        <AgentNotes
          open={openAddNote}
          cancel={setOpenAddNote}
          agentId={agentId}
          toName={userName}
        />
      )}
    </>
  ) : (
    <LoadingView />
  );
  
};

const mapStateToProps = (state) => {
  return {
    isInternal: state.auth.isInternal,
    role: state.auth.role,
    permissions: state.auth.permissions,
    users: state.users,
    userName: state.auth.name,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentList);
