import * as actionTypes from '../actions/actionTypes';
// import { updateObject } from '../utility';
import initialState from './initialState';

const reducer = (state = initialState.summary, action) => {
  switch (action.type) {
    case actionTypes.SUMMARY_AGENT_COUNT: {
      return {
        ...state,
        agentCount: action.payload.count,
      };
    }
    case actionTypes.SUMMARY_STUDENT_COUNT: {
      return {
        ...state,
        studentCount: action.payload.count,
      };
    }
    case actionTypes.SUMMARY_APPLICATION_COUNT: {
      return {
        ...state,
        applicationCount: action.payload.count,
      };
    }
    case actionTypes.SUMMARY_ADMIN_COUNT: {
      return {
        ...state,
        adminCount: action.payload.count,
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
