/* eslint-disable import/named */
// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div style={{ marginBottom: 20 }}>
        Oops - We have looked everywhere and could not find this.
      </div>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => navigate('/')}
      >
        Return to Home Page
      </Button>
    </>
  );
};
export default NotFound;
