import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import initialState from './initialState';

const reducer = (state = initialState.common, action) => {
  switch (action.type) {
    case actionTypes.SERVER_ERRORS:
      return setServerError(state, action);
    case actionTypes.SUMMARY_INFO:
      return setSummaryInfo(state, action);

    default:
      return state;
  }
};

const setServerError = (state, action) => {
  return updateObject(state, { serverError: action.errors });
};

const setSummaryInfo = (state, action) => {
  let summary = state.summary;
  summary = action.summary;
  var obj = { ...state, summary };
  return obj;
};

export default reducer;
