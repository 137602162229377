/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';

import StudyLevelFilter from './ProgramSearchFilters/StudyLevelFilter';
import ProgramSearchBox from './ProgramSearchFilters/ProgramSearchBox';
import InstituteSearchBox from './ProgramSearchFilters/InstituteSearchBox';
import IntakeFilter from './ProgramSearchFilters/IntakeMonthFilter';
import { INTAKES, STUDY_LEVELS } from '../../../../Util/Constants';
import CountryFilter from './ProgramSearchFilters/CountryFilter';
import LoadingView from '../../../Common/Loading/LoadingView';
import ProfileHeader from '../../common/profileHeader';
import { GridSearchIcon } from '@mui/x-data-grid-pro';
import ProgramDetails from './ProgramDetails';
import Agent from '../../../../api/agent';

const ProgramList = ({ role }) => {
  const [search, setSearch] = useState('');
  const [students, setStudents] = useState('');
  const [programs, setPrograms] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState({});
  const [selectedProgramId, setSelectedProgramId] = useState(0);
  const [universityName, setUniversityName] = useState('');
  const [screenHeight, setScreenHeight] = useState(screen.height - 200);

  const [studyLevel, setStudyLevel] = useState(0);
  const [country, setCountry] = useState(0);
  const [selectedInstitution, setSelectedInstitution] = useState();
  const [allInstitutions, setAllInstitutions] = useState([]);
  const [loadingInitial, setLoadingInitial] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [totalItems, setTotalItems] = useState();
  const [basedOnPreferences, setBasedOnPreferences] = useState(role.isStudent);

  const [intakes, setIntakes] = useState({
    January: false,
    February: false,
    March: false,
    April: false,
    May: false,
    June: false,
    July: false,
    August: false,
    September: false,
    October: false,
    November: false,
    December: false,
    allIntake: false,
  });
  const [tempIntakes, setTempIntakes] = useState([]);

  const getOptionsDelayed = useCallback(
    debounce((countryId, text, callback) => {
      setAllInstitutions([]);
      Agent.Universities.search(countryId, text).then(callback);
    }, 300),
    []
  );

  useEffect(() => {
    setAllInstitutions([]);
    if (universityName) {
      getOptionsDelayed(country, universityName, (options) =>
        setAllInstitutions(options)
      );
    }
  }, [universityName]);

  const handleIntakeChange = () => {
    const updatedIntakes = { ...intakes };
    const hasTempIntakes = tempIntakes.length > 0;
    updatedIntakes.allIntake = !hasTempIntakes;
    for (const intake in updatedIntakes) {
      if (intake != 'allIntake')
        updatedIntakes[intake] = tempIntakes.includes(intake);
    }
    setIntakes(updatedIntakes);
  };

  useEffect(() => {
    handleIntakeChange();
  }, [tempIntakes]);

  const { state } = useParams('state');
  const navigate = useNavigate();

  useEffect(() => {
    Agent.Students.getStudent(0)
      .then((std) => {
        setStudents(std);
        // if (!std.profileComplete) {
        //   navigate('/profile');
        // }
      })
      .catch((error) => {
        debugger;
        console.log(error);
      });

    handleGetPrograms(true);
  }, []);

  const getParams = (initial) => {
    const params = new URLSearchParams();
    params.append('studentId', state ? state : 0);
    params.append('pageNumber', initial ? '1' : currentPage.toString());
    params.append('pageSize', '10');
    params.append('search', search);
    params.append('studyLevelId', studyLevel.toString());
    params.append('country', country.toString());
    params.append(
      'university',
      selectedInstitution ? selectedInstitution.id : '0'
    );
    //let's get id for intake strings
    let tempIntakes = '';
    for (const intake in intakes) {
      if (intake == 'allIntake') continue;
      if (intakes[intake] == true) {
        tempIntakes =
          tempIntakes + ',' + INTAKES.find(({ type }) => type == intake).id;
      }
    }
    params.append('intakes', tempIntakes);
    if (basedOnPreferences) {
      params.append('basedOnPreferences', basedOnPreferences.toString());
    }
    return params;
  };

  const handleGetPrograms = (initial) => {
    setLoading(true);
    if (initial) {
      setCurrentPage(1);
      setLoadingInitial(true);
    }
    Agent.Programs.list(getParams(initial))
      .then((courses) => {
        setCurrentPage((prev) => prev + 1);
        const { pagination, data } = courses;
        setTotalPages(pagination.totalPages);
        setTotalItems(pagination.totalItems);
        if (initial) {
          setPrograms(data);
          setSelectedProgram(data[0]);
        } else {
          setPrograms(programs.concat(data));
        }
        setLoadingInitial(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoadingInitial(true);
        setLoading(false);
        console.log(error);
      });
  };
  
  const handleShowMore = () => {
    handleGetPrograms(false);
  };
  const handleGetNext = () => {
    handleGetPrograms();
  };

  const handleSelectProgram = (program, idx) => {
    setSelectedProgramId(idx);
    setSelectedProgram(program);
  };

  return (
    <div className="w-full max-w-[1400px] pt-2 lg:ml-48 xl:ml-48">
      <div className="pb-5">
        <ProfileHeader
          studentFirstName={''}
          subTitle="These are course suggestions based on your preferences "
        />
      </div>
      <div className="head-stu-programSearch studentSidebarBgGradient1">
        Program Search Page
      </div>
      <div className="head-stu-filter ">
        <div className="head-stu-filter-top ">
          <div>
            <ProgramSearchBox
              placeholderText="Programs"
              initialValue={search}
              returnValue={setSearch}
              isDisabled={basedOnPreferences}
            />
          </div>
          <div>
            <InstituteSearchBox
              isDisabled={basedOnPreferences}
              selectedInstitution={selectedInstitution}
              setSelectedInstitution={setSelectedInstitution}
              country={country}
              allInstitutions={allInstitutions}
              universityName={universityName}
              setUniversityName={setUniversityName}
            />
          </div>
          <div className="">
            <StudyLevelFilter
              returnValue={setStudyLevel}
              isDisabled={basedOnPreferences}
            />
          </div>
        </div>
        <div className="head-stu-filter-bottom ">
        <div>
            <IntakeFilter
              returnValue={setTempIntakes}
              isDisabled={basedOnPreferences}
             
            />
          </div>
          <div className="">
            <CountryFilter
              returnValue={setCountry}
              clearValue={setUniversityName}
              isDisabled={basedOnPreferences}
            />
          </div>
          <div>
            <button
              className="filter-stu-search-btn"
              onClick={() => handleGetPrograms(true)}
            >
              <GridSearchIcon />
              Search Programs
            </button>
          </div>
          <div className="inline-flex justify-end">
            <FormControlLabel
              className="text-indigo-800 font-semibold text-xl"
              control={
                <Checkbox
                  className="text-indigo-500 font-semibold"
                  checked={basedOnPreferences}
                  onChange={() => {
                    setSelectedInstitution(null);
                    setBasedOnPreferences((prevState) => !prevState);
                  }}
                />
              }
              label={
                state
                  ? 'Based on student preferences'
                  : 'Based on my preferences'
              }
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4 w-full text-gray-800 h-screen lg:flex-row xl:flex-row">
        <div className="lg:sidebar-stu-programList xl:sidebar-stu-programList lg:h-[500px] lg:hidden-scroll-bar xl:sidebar-stu-programList xl:h-[500px]">
          {/* <InfiniteScroll
            height={screenHeight}
            dataLength={programs.length}
            next={handleGetNext}
            hasMore={!isLoading && currentPage <= totalPages}
            className="bg-gray-20 rounded-lg h-screen p-3 mozilla-scroller"
          > */}
          <div className="flex flex-col gap-3">
            {!isLoading && programs.length == 0 ? (
              <div className="text-center p-3 font-medium text-lg text-indigo-800">
                {'No Programs Found based on your preferences'}
              </div>
            ) : (
              programs.map((program, idx) => {
                return (
                  <div
                    onClick={() => handleSelectProgram(program, idx)}
                    key={idx}
                    className={`sidebar-stu-program-card ${
                      idx == selectedProgramId ? 'bg-indigo-400 text-white' : ''
                    }`}
                  >
                    <div className="font-medium text-lg truncate">
                      {program.courseName}
                    </div>
                    <div className="flex gap-4 justify-between">
                      <div className=" w-[150px] truncate">
                        {STUDY_LEVELS.filter(
                          (appStatus) => appStatus.id === program.studyLevelId
                        ).map((appStatus) => appStatus.type)}
                      </div>
                      <div className=" w-[50px] truncate">{program.year}</div>
                      <div className=" w-[230px] truncate">
                        {program.universityName}
                      </div>
                    </div>
                  </div>
                );
              })
            )}
            {(isLoading || loadingInitial) && (
              <button onClick={handleShowMore}>
                {/* <LoadingView /> */}
                Show More
              </button>
            )}
          </div>
          {/* </InfiniteScroll>*/}
        </div>
        <div className="w-full rounded-lg h-screen p-3">
          <ProgramDetails program={selectedProgram} student={students}/>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
    isInternal: state.auth.isInternal,
  };
};

export default connect(mapStateToProps, null)(ProgramList);
