import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PREFIX = 'Agreements';

const classes = {
  root: `${PREFIX}-root`,
  panelHeader: `${PREFIX}-panelHeader`,
  penelHeaderTicked: `${PREFIX}-penelHeaderTicked`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.panelHeader}`]: {
    backgroundColor: 'lightgray',
  },

  [`& .${classes.penelHeaderTicked}`]: {
    backgroundColor: theme.palette.common.lightBlue,
  },
}));

const Agreements = ({
  termsAgreed,
  setTermsAgreed,
  dataPrivacyAgreed,
  setDataPrivacyAgreed,
}) => {
  return (
    <Root>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
          className={
            termsAgreed ? classes.penelHeaderTicked : classes.panelHeader
          }
        >
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Checkbox
                checked={termsAgreed}
                onChange={() => {
                  setTermsAgreed(!termsAgreed);
                }}
              />
            }
            label="I agree with the terms and conditions detailed here (expand to see terms and condtions)"
          />
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
            <Grid container direction="column" spacing={3}>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography align="center" variant="h5">
                  Terms and Conditions
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography variant="h6">INTRODUCTION</Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  Welcome to the Talib Consultancy UK Ltd privacy notice.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                Talib Consultancy UK Ltd, a trading name of Talib Consultancy UK Ltd
                 and its subsidiary companies, respects your privacy and
                  is committed to protecting your personal data.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  This privacy notice is provided in a layered format so you can
                  click through to a specific privacy policy.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  This website is not intended for children and we do not
                  knowingly collect data relating to children on this website.
                  However where Talib Consultancy UK Ltd processes personal data of
                  children in its business operations, we ensure that
                  appropriate safeguards and consents are obtained from parents
                  or caregivers as applicable [and that we follow relevant
                  guidance from the Information Commissioner’s Office in
                  relation to the processing of children’s data]. You may
                  contact support@tclglobal.co.uk for further
                  information about this.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  It is important that you read the privacy notice together with
                  any other privacy notice or fair processing notice we may
                  provide on specific occasions when we are collecting or
                  processing personal data about you so that you are fully aware
                  of how and why we are using your data.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography variant="h6">
                  IMPORTANT INFORMATION AND WHO WE ARE
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography variant="h6">DATA CONTROLLER</Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                 Talib Consultancy UK Ltd is a company registered in The
                  United kingdom and we have our registered office and our main
                  trading address at (see below)
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  Notification details can be accessed by searching the Data
                  Protection Public Register.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  The Talib Consultancy UK Ltd group of companies is made up
                  of different legal entities, details of which can be found
                  here https://www.tclglobal.co.uk/About
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  We have appointed a data protection officer (DPO) who is
                  responsible for overseeing questions in relation to this
                  privacy notice. If you have any questions about this privacy
                  notice, including any requests to exercise your legal rights,
                  please contact the DPO using the details set out below.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography variant="h6">CONTACT DETAILS</Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>Our full details are:</Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  Full name of data controller legal entity:
                  Talib Consultancy UK Ltd Services UK Limited
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>FAO Data Protection Officer</Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>Email address: support@tclglobal.co.uk</Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  Postal address: Unit 3, St. Georges Business Centre, 
                  St. Georges Square, Portsmouth, PO1 3AX, United Kingdom
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>Telephone number: +44 (0) 23 9283 9210</Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  You have the right to make a complaint at any time to the
                  Talib Consultancy UK Ltd complaint office. We consider that the CO
                  would also be the lead supervisory authority for
                  Talib Consultancy UK Ltd since the UK is the place of the majority
                  of our operations. For data protection issues in other
                  countries, you have the right to complain at any time to the
                  supervisory authority for that country.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  We would always prefer that you come to us to help address any
                  concerns of a privacy nature however before you go to the CO
                  or other applicable supervisory authority, so please contact
                  us in the first instance.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography variant="h6">
                  CHANGES TO THE PRIVACY NOTICE AND YOUR DUTY TO INFORM US OF
                  CHANGES
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  This version was last updated September 2020 and historic
                  versions can be obtained by contacting us.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  We may from time to time change the details in this notice.
                  Any changes we may make in the future will be posted on this
                  page. Please check back frequently to see any such updates or
                  changes.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  It is important that the personal data we hold about you is
                  accurate and current. Please keep us informed if your personal
                  data changes during your relationship with us.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography variant="h6">THIRD-PARTY LINKS</Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  This website may include links to third-party websites,
                  plug-ins and applications. Clicking on those links or enabling
                  those connections may allow third parties to collect or share
                  data about you. We do not control these third-party websites
                  and are not responsible for their privacy statements. When you
                  leave our website, we encourage you to read the privacy notice
                  of every website you visit.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography variant="h6">COOKIES</Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  You can set your browser to refuse all or some browser
                  cookies, or to alert you when websites set or access cookies.
                  If you disable or refuse cookies, please note that some parts
                  of this website may become inaccessible or not function
                  properly. For more information about the cookies we use,
                  please see here.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography variant="h6">GLOSSARY</Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography variant="h6">LAWFUL BASIS</Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  Legitimate Interest means the interest of our business in
                  conducting and managing our business to enable us to give you
                  the best service/product and the best and most secure
                  experience. We make sure we consider and balance any potential
                  impact on you (both positive and negative) and your rights
                  before we process your personal data for our legitimate
                  interests. We do not use your personal data for activities
                  where our interests are overridden by the impact on you
                  (unless we have your consent or are otherwise required or
                  permitted to by law). You can obtain further information about
                  how we assess our legitimate interests against any potential
                  impact on you in respect of specific activities by contacting
                  our DPO (noreply@tclglobal.co.uk).
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  Contractual necessity means processing your data where it is
                  necessary for the performance of a contract to which you are a
                  party or to take preliminary pre-contractual steps at your
                  request before entering into such a contract.
                </Typography>
              </Grid>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography>
                  Comply with a legal or regulatory obligation means processing
                  your personal data where it is necessary for compliance with a
                  legal or regulatory obligation that we are subject to.
                </Typography>
              </Grid>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions2-content"
          id="additional-actions2-header"
          className={
            dataPrivacyAgreed ? classes.penelHeaderTicked : classes.panelHeader
          }
        >
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Checkbox
                checked={dataPrivacyAgreed}
                onChange={() => {
                  setDataPrivacyAgreed(!dataPrivacyAgreed);
                }}
              />
            }
            label="I acknowledge the data protection and confidentiality terms (expand to see terms and condtions)"
          />
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
            <Grid container direction="column" spacing={3}>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography align="center" variant="h5">
                  Privacy Policy
                </Typography>
              </Grid>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>Last Updated: January 24, 2024</Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                tclglobal Consulting Inc. (“Tcl global”, “we”, “us” or “our”)
                takes the privacy of personal information seriously, including
                personal information that we process on our client’s behalf.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                This Privacy Policy has been prepared by Tcl global Consulting
                Inc. and sets out the manner in which Tcl global collects, uses,
                discloses and otherwise manages personal information, including
                information collected directly from you or from our advisors or
                through our website located at https://www.tclglobal.co.uk
                (“Website”).
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography variant="h6">
                COLLECTION AND USE OF PERSONAL INFORMATION
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                “Personal information” means information about an identifiable
                individual, including your name, address, email address,
                telephone number, and other information relating to you. We may
                collect personal information when you:
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <ul>
                <li>create an account</li>
                <li>
                  create a student profile and/or apply to an educational
                  program
                </li>
                <li>post on our Website</li>
                <li>communicate with us</li>
                <li>provide information to third parties who we work with</li>
                <li>sign up for marketing emails and other communications</li>
                <li>apply to become an advisor</li>
              </ul>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Create an Account: To access certain features on the Website,
                you are required to create an account and provide your email
                address and password. Your login information is used to create
                your account, and otherwise provide and administer the service
                you requested.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Create a Student Profile and/or Apply to an Educational Program:
                In order to apply to an educational program, you (or the advisor
                acting on your behalf) are required to create a student profile
                on our Website. The personal information we collect in your
                student profile helps us match you with schools and programs
                that fit your preferences and qualifications. This information
                may also be required by the school for the purposes of
                processing your application. This information may include your
                first and last name, email address, gender, date of birth, home
                and mailing address, passport details, marital status, emergency
                contact information, English or French language test score(s),
                educational preferences (such as preferred study destination and
                educational fields of interest), education history, volunteer
                experience, and other educational information.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Throughout the application process, you have the option to
                provide additional information such as documentation of awards
                and school projects. This information is not required to process
                your application but is recommended to enhance the success of
                your application.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                We may use your profile information and other information about
                your use of the Website (such as search queries or other links
                you click on) to provide you with tailored school
                recommendations through the Website.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Post on Our Website: You may have the ability to post
                information (e.g. questions, comments and opinions) through
                tclglobal’s services or on social media platforms, such as
                Facebook, Twitter, LinkedIn, or Instagram. Please be advised
                that any personal information that you voluntarily post to any
                public forum will be available to other individuals and you
                should exercise caution when posting any personal information.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Communicate with Us: When you contact us with a comment,
                question or complaint, you may be asked for information that
                identifies you (such as your name, email address and a phone
                number) along with additional information we need to help us
                promptly answer your question or respond to your comment or
                complaint. We may also retain this information to assist you in
                the future and to improve our customer service, product and
                service offerings, and events and promotions.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Provide Information to Third Parties Who We Work With: We may
                obtain personal information about you through our advisors who
                you are working with and who are using our services to process
                your application to schools. Occasionally, tclglobal will obtain
                information about you from third parties, such as mailing list
                providers and third-party service providers. Third-party
                providers are responsible for obtaining consent to provide
                personal information to us.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Sign Up for Marketing Email and Other Communications: We may
                offer you the ability to sign up to receive Tcl global marketing
                and promotional communications through the Website, on third
                party websites (including through social media sites), or
                educational events and information sessions. In these
                circumstances, we collect certain personal information (such as
                first and last name, email address or mobile phone number) which
                we use to send you email and other communications containing Tcl
                global updates, tips, and other content that may be of interest
                to you. You can opt out of receiving promotional email from us
                by following the opt-out or “unsubscribe” instructions provided
                in your email or text message, or by contacting us as set out
                below. Please note that you may continue to receive messages
                from us regarding your application or other transactional and
                account-related electronic messages from us.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Apply to Become an Tcl global Advisor: In connection with
                applying to become a potential advisor with Tcl global, you may
                provide us with certain personal information such as your first
                name, last name, address, email address, and personal
                information about your education and employment background. We
                use this information for the purpose of processing and
                responding to your application to become an tclglobal Advisor.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography variant="h6">
                SHARING OF PERSONAL INFORMATION
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                We will not disclose, trade, rent, sell or otherwise transfer
                your personal information, without your consent, except as set
                out herein.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Schools: We share the information in your application with the
                schools that you are applying to (either directly or through a
                third party on your behalf, within the UK or abroad). Once Tcl
                global has provided your information to these schools for your
                application for admittance, Tcl global does not have any control
                over the use of such information by these schools and recommends
                that you contact them if you have any questions about the manner
                in which your personal information is treated.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Partners: From time to time, we may partner with third parties
                to provide benefits to registered members of the Website. With
                your consent, we may provide certain personal information to
                third parties so that they can contact you with information
                about the benefits and/or we may share aggregated,
                non-identifiable profile and usage data with third party
                partners for marketing and analytics purposes.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Business Transactions: We may transfer any information we have
                about you as an asset in connection with a proposed or completed
                merger or sale (including transfers made as part of insolvency
                or bankruptcy proceedings) involving all or part of Tcl global
                or as part of a corporate reorganization or other change in
                corporate control.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Service Providers: We may transfer (or otherwise make available)
                your personal information to third parties who provide services
                on our behalf. For example, we may use service providers to send
                our emails, host our website and operate certain of its
                features. Your personal information may be maintained and
                processed by third party service providers in the UK, or other
                jurisdictions. Our service providers are given the information
                they need to perform their designated functions, and we do not
                authorize them to use or disclose personal information for their
                own marketing or other purposes.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Legal Requirements: Tcl global and our British, and other
                service providers may provide your personal information in
                response to a search warrant or other legally valid inquiry or
                order, or to an organization in the case of a breach of an
                agreement or contravention of law, or as otherwise required or
                permitted by applicable British, or other law. We may also
                disclose personal information where necessary for the
                establishment, exercise or defence of legal claims, to detect,
                suppress or prevent fraud, and to investigate or prevent actual
                or suspected loss or harm to persons or property.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography variant="h6">
                SECURITY OF PERSONAL INFORMATION
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                We understand that data security is a critical issue for our
                clients, and we are committed to safeguarding the personal
                information in our custody and under our control. We have
                implemented reasonable administrative, technical and physical
                safeguards in an effort to protect against unauthorized access,
                use, modification and disclosure of personal information in our
                custody and control, including limiting access to our database
                to legitimate users.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Retention of Personal Information We have personal information
                retention processes designed to retain personal information for
                no longer than necessary for the purposes of providing services
                to our clients or, with respect to the Website, for the purposes
                set out in this Privacy Policy or to otherwise meet legal
                requirements.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Access to Personal Information You may access, update and
                correct inaccuracies in your personal information in our custody
                or control at any time, subject to limited exceptions prescribed
                by law. Simply log into your account, go to your account
                profile, and make the necessary changes.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                You can request access, corrections or updates to other personal
                information in our records by contacting us as set out below. We
                may request certain personal information for the purposes of
                verifying the identity of the individual seeking access to their
                personal information records.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography variant="h6">
                COOKIES AND ADDITIONAL INFORMATION ABOUT OUR WEBSITE
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Visiting our Website: In general, you can visit our Website
                without telling us who you are or submitting any personal
                information. However, we collect the IP (Internet protocol)
                addresses of all visitors to our Website and other related
                information such as page requests, browser type, operating
                system and average time spent on our Website. We use this
                information to help us understand our Website activity, and to
                monitor and improve our Website.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Cookies: Our Website uses a technology called “cookies”. A
                cookie is a tiny element of data that our Website can send to
                your browser, which may then be stored on your hard drive so we
                can recognize you when you return. You may set your Web browser
                to notify you when you receive a cookie or to not accept certain
                cookies. However, if you decide not to accept cookies from our
                Website, you may not be able to take advantage of all of the
                features of our Website.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Our website also uses web analytics services such as Google
                Analytics. These services use cookies to help us analyze how
                visitors use the website. For example, with Google Analytics,
                the information generated by the cookie about your use of the
                website (including your IP address) will be transmitted to and
                stored by a Google server. Google uses this information for the
                purpose of evaluating your use of the website, compiling reports
                on website activity for website operators, and providing website
                operators with other services relating to website activity and
                internet usage. You can prevent the storage of data relating to
                your use of the website by Google, as well as the processing of
                this data by Google, by downloading and installing the browser
                plug-in available at the following link:
                https://tools.google.com/dlpage/gaoptout?hl=en.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                You can also obtain additional information on Google Analytics’
                collection and processing of data and data privacy and security
                at the following links:
                https://policies.google.com/technologies/partner-sites; and
                https://support.google.com/analytics/topic/2919631
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Tracer Tags & Web Beacons: Our Website may also use a technology
                called “tracer tags” or “Web Beacons”. This technology allows us
                to understand which pages you visit on our Website. These tracer
                tags are used to help us optimize and tailor our Website for you
                and other future visitors to our Website.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Links to Other Websites: Our Website may contain links to other
                websites that Tcl global does not own or operate, including the
                websites of third-party partners. Also, links to our website may
                be featured on third party websites on which we advertise.
                Except as provided herein, we will not provide any of your
                personal information to these third parties without your
                consent. We provide links to third party websites as a
                convenience to the user. These links are not intended as an
                endorsement of or referral to the linked websites. The linked
                websites have separate and independent privacy statements,
                notices and terms of use, which we recommend you read carefully.
                We do not have any control over such websites, and therefore we
                have no responsibility or liability for the manner in which the
                organizations that operate such linked websites may collect, use
                or disclose, secure and otherwise treat your personal
                information.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                Social Media: We may offer you the opportunity to engage with
                our content on or through third-party social networking
                websites, plug-ins and applications. When you engage with our
                content on or through third-party social networking websites,
                plug-ins and applications, you may allow us to have access to
                certain information associated with your social media account
                (e.g., name, username, email address, profile picture, gender)
                to deliver the content or as part of the operation of the
                website, plug-in or application. When you provide information
                from your social media account, we may use this information to
                personalize your experience on the websites and on the
                third-party social networking websites, plug-ins and
                applications, and to provide you with other products or services
                you may request.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography variant="h6">
                CHANGES TO OUR PRIVACY POLICY
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
                This Privacy Policy may be updated periodically to reflect
                changes to our personal information practices. The revised
                Privacy Policy will be posted on the Website. We strongly
                encourage you to please refer to this Privacy Policy often for
                the latest information about our personal information practices.
              </Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>Please contact our Privacy Officer if:</Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <ul>
                <li>
                  you have any questions or comments about this Privacy Notice,
                </li>
                <li>
                  you otherwise have a question or complaint about the manner in
                  which we treat your personal information.
                </li>
                <li>
                  You may contact our Privacy Officer by email at
                  support@tclglobal.co.uk
                </li>
              </ul>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>Or by mail at the following address:</Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>
              Unit 3, St. Georges Business Centre, St. Georges Square
              </Typography>
              <Typography>Portsmouth, PO1 3AX, United Kingdom</Typography>
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              
            </Grid>
            <Grid item style={{ marginBottom: 15 }}>
              <Typography>Last updated: January 24, 2024</Typography>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    </Root>
  );
};

export default Agreements;
