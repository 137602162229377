import React from 'react'
import {connect} from 'react-redux'

const ProfileHeader = (props) => {
  return (
    <div className='w-[95%]'>
      <div className='w-full'>
        <div className='text-3xl font-medium'>{`Hello ${props.name}`}</div>
        <div className='divide-y-2 divide-gray- w-full h-full'>
          <div className='text-xl pb-3'>{props.subTitle}</div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    name: state.auth.name,
    role: state.auth.role,
    roleName: state.auth.roleName,
  }
}

export default connect(mapStateToProps)(ProfileHeader)
