import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import React, { useState, useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import Agent from '../../../../api/agent';
import { getPermissionStructureAsArray } from '../../../../Util/Util';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import { connect } from 'react-redux';

const PermissionAssignment = ({ user, role }) => {
  const [permissions, setPermissions] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [saved, setSaved] = useState(false);
  useEffect(() => {
    setPermissions([]);
    let tempPermissions = getPermissionStructureAsArray();
    Agent.Users.getClaims(user.appUserId)
      .then((response) => {
        response.forEach((p) => {
          let permission = _.find(tempPermissions, function (obj) {
            if (obj.type == p.type) {
              obj.status = true;
            }
          });
        });
        setPermissions(tempPermissions);
      })
      .catch((error) => {
        debugger;
      });
  }, []);

  const handleChange = (event, index) => {
    let tempPermissions = permissions.splice(0, permissions.length);
    var permission = tempPermissions[index];
    permission.status = event.target.checked;
    tempPermissions[index] = permission;
    setPermissions(tempPermissions);
    //setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleSave = () => {
    // back to database
    setUpdating(true);
    var claims = [];
    permissions.map((permission) => {
      if (permission.status == true) {
        claims.push(permission.type);
      }
    });
    Agent.Users.setClaims(user.appUserId, claims)
      .then((response) => {
        setUpdating(false);
        setSaved(true);
        // setPermissions(response);
      })
      .catch((error) => {
        setUpdating(false);
        debugger;
      });
  };

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <FormControl component="fieldset">
          <FormGroup>
            {permissions.map((permission, i) => {
              return (
                <FormControlLabel
                  key={i}
                  control={
                    <Switch
                      checked={permission.status}
                      onChange={() => {
                        handleChange(event, i);
                        setSaved(false);
                      }}
                      name={permission.type}
                      color="primary"
                    />
                  }
                  label={permission.value}
                />
              );
            })}
          </FormGroup>
          {role.isSuperAdmin && (
            <FormHelperText>
              Click to turn blue to give permission
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      {role.isSuperAdmin ? (
        <Grid item container spacing={2}>
          <Grid item>
            <LoadingButton
              loading={updating}
              onClick={handleSave}
           className='bg-red-800'
              variant="contained"
            >
              Save Permissions
            </LoadingButton>
          </Grid>
          {saved && (
            <Grid item>
              <Typography color="primary">Successfully Saved!</Typography>
            </Grid>
          )}
        </Grid>
      ) : (
        <Typography color="primary" style={{ paddingLeft: 5, paddingTop: 5 }}>
          Only Super Admin has ability to assign/change permissions
        </Typography>
      )}
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    role: state.auth.role,
    user: ownProps.user,
  };
};

export default connect(mapStateToProps, null)(PermissionAssignment);
