import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Grid,
  Typography,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton
} from '@mui/material';
import 'tailwindcss/tailwind.css';
import { connect } from 'react-redux';
import {
  DataGridPro,
  GridPagination,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import Agent from '../../../../../api/agent';
import MyTooltip from '../../../../Common/Tooltip/MyTooltip';
import {
  AGENTSOURCES,
  AGENTSTATUS,
} from '../../../../../Util/Constants';
import { Countries } from '../../../../../Util/Util';
import { HiCheckCircle } from 'react-icons/hi2';
import CountdownLoader from '../../../StudentLeadComms/LeadComUtils/CountdownLoader';
import WhatsappSummaryDialog from '../../../StudentLeadComms/SendBulkMailDialog/WhatsappSummaryDialog';
import ReferralComFilters from '../../ReferralComFilters/ReferralComFilters';

function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {isInternal && <GridToolbarColumnsButton />}
      <GridToolbarDensitySelector />
      {hide && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}
const ReferralBulkWhatsapp = ({ appUserId,users }) => {
  const [showCountdownLoader, setShowCountdownLoader] = useState(false);
  const [LeadListName, setLeadListName] = useState('');
  const [Name, setLeadFirstName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [studentsLead, setStudentsLead] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [citizenship, setCitizenshipFilter] = useState(0);
  const [eventId, setEventFilter] = useState(0);
  const [LeadActivity, setLeadActivityFilter] = useState(0);
  // const [statusFilter, setStatusFilter] = useState(0);

  const [Status, setStatusFilter] = useState(0);
  const [BranchOfficeId, setBranchOfficeFilter] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalSelected, setTotalSelected] = useState(0);
  const [selectTemplateId, setSelectTemplateId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [referralIdList, setReferralIdList] = useState([]);
  const [offices, setOffices] = useState([]);
  const [fromMailList, setFromMailList] = useState([]);
  const [openWhatsappConfirmDialog, setWhatsappOpenConfirmDialog] =
    useState(false);
  const [dialogueCommunicationName, setDialogueCommunicationName] =
    useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showData, setShowData] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(800);
  const [profileStatusFilter, setProfileStatusFilter] = useState(null);
  const [totalrows, setTotalRows] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [events, setEventList] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [referrals, setReferrals] = useState([]);
  const [referralId, setReferralId] = useState("");
  const [referralName, setReferralName] = useState("");
  const [status, setStatus] = useState(0);
  const [referralList, setReferralList] = useState([]);

  const handleCommunicationNameChange = (event) => {
    setDialogueCommunicationName(event.target.value);
  };
  const handleSelectTemplateIdChange = (event) => {
    setSelectTemplateId(event.target.value);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleWhatsappCloseConfirmDialog = () => {
    setWhatsappOpenConfirmDialog(false);
  };
  const handleCountdownComplete = () => {
    setShowCountdownLoader(false); 
  };

  const handleApplyFilters = () => {
    setShowData(true);
    setPageNumber(1); // Reset to first page when applying new filters
    fetchData(true); 
  };
  const handleOpenConfirmDialog = () => {
    setWhatsappOpenConfirmDialog(true);
  };
  const handleSendWhatsapp = async () => {
    handleWhatsappCloseConfirmDialog();
    setShowCountdownLoader(true); 

    try {
      const response = await Agent.Communication.SendbulkWhatsappReferral(payloadWhatsapp).then(
        (response) => {
          setShowCountdownLoader(false); 
          setOpenDialog(false);
          setSuccessMessage('Successfully sent WhatsApp messages!', response);
        })
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to send whatsapp.', error);
      setShowErrorMessage(true);
    }
  };

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  useEffect(() => {
    Agent.Events.getEventList().then(setEventList);
  }, []);

  const handleAddStudentsLeadId = (selectedStudentsLeadId) => {
    setReferralIdList((prevList) => [
      ...prevList,
      { Id: selectedStudentsLeadId },
    ]);
  };

  const closeErrorMessage = () => {
    setShowErrorMessage(false);
  };

  const ErrorMessageDisplay = () =>
    showErrorMessage && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-red-800 text-white p-6 rounded-lg shadow-lg z-50">
          <button
            className="btn btn-warning float-right"
            onClick={closeErrorMessage}
          >
            Close
          </button>
          <p>{errorMessage}</p>
        </div>
      </div>
    );

  const payloadWhatsapp = {
    Referrals: referralIdList,
    CommunicationName: dialogueCommunicationName,
    AppUserId: appUserId,
    NumberOfStudents: totalSelected,
    TemplateId: selectTemplateId,
  };

  useEffect(() => {
    // fetchData();
    fetchTemplates();
  }, []);

  useEffect(() => {
    setSelectedRowCount(selectedRows.length);
  }, [selectedRows]);

  useEffect(() => {
    setTotalSelected(selectedRows.length);
  }, [selectedRows]);

  const fetchTemplates = async () => {
    try {
      const response = await Agent.Communication.getReferralWhatsappTemplates();
      setTemplates(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', pageSize.toString());

    params.append("id", filter ? referralId.trim() : "");
    params.append("Name", filter ? referralName.trim() : "");
    params.append("status", filter ? status.toString() : "0");

    return params;
  };

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  useEffect(() => {
    Agent.Communication.getFromEmails().then(setFromMailList);
  }, []);

  const fetchData = async (filter = false) => {
    setLoading(true);
    try {
      const params = getParams(filter);
      const leads = await Agent.Communication.whatsapplistReferral(params);
      const { pagination, data } = leads;
      // console.log(leads);
      setTotalPages(pagination.totalPages);
      setTotalRows(pagination.totalItems);
      setDataLoaded(true);
      setReferrals(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  

  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    // If Select All is checked, select all students on the current page
    if (!selectAll) {
      const selectedIds = referrals.map((ref) => ({
        Id: ref.id,
      }));
      setReferralIdList(selectedIds);
      setSelectedRows(selectedIds.map((refId) => refId.Id));
    } else {
      setReferralIdList([]);
      setSelectedRows([]);
    }
  };

  const renderCheckbox = (params) => (
    <Checkbox
      checked={selectedRows.includes(params.row.id) || selectAll}
      onChange={(event) => handleRowSelection(event, params.row.id)}
    />
  );

    let referralComColumns = [
      {
        field: 'checkbox',
        headerName: 'Select',
        width: 100,
        renderCell: renderCheckbox,
        headerAlign: 'center',
        renderHeader: () => (
          <Checkbox
            checked={selectAll}
            onChange={handleSelectAll}
            indeterminate={
              selectedRows.length > 0 && selectedRows.length < referrals.length
            }
          />
        ),
      },
      {
        field: 'id',
        headerName: 'Id',
        width: 80,
        hide: true,
      },
   
      {
        field: 'name',
        headerName: 'Referral Name',
        width: 270,
        renderCell: (params) => <Typography>{params.row.firstName+' '+params.row.lastName}</Typography>,
      },
    
      {
        field: 'phoneNumber',
        headerName: 'phone Number',
        width: 170,
        renderCell: (params) => <Typography>{params.row.phoneNumber}</Typography>,
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 250,
        hide:true,
        renderCell: (params) => <Typography>{params.row.email}</Typography>,
      },
      {
        field: 'country',
        headerName: 'Country',
        width: 160,
        hide:false,
        valueGetter: (params) => {
          const country = params.row.nationality;
          const countryName = Countries.find(
            (sta) => sta.id == country
          );
          return countryName ? countryName.name : '';
        },
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 160,
        valueGetter: (params) => {
          const status = params.row.status;
          const agentStatus = AGENTSTATUS.find(
            (sta) => sta.id == status
          );
          return agentStatus ? agentStatus.type : '';
        },
      },
      {
        field: 'isStudent',
        headerName: 'Student',
        hide: false,
        width: 100,
        align: 'left',
        renderCell: (params) => (
          <strong>
            {params.row.isStudent ? (
              <MyTooltip
                icon={<HiCheckCircle color="success" />}
                title="This Referral has Student User Also"
              >
                <IconButton size="large">
                  <HiCheckCircle color="#03C988" />
                </IconButton>
              </MyTooltip>
            ) : null}
          </strong>
        ),
      },
      {
        field: 'admin',
        headerName: 'Admin',
        width: 200,
        renderCell: (params) => <Typography>{params.row.adminName}</Typography>,
      },
      {
        field: 'businessDevAppUserDetailsId',
        headerName: 'BDM',
        width: 220,
        hide:true,
        valueGetter: (params) => {
          const bdm = params.row.businessDevAppUserDetailsId;
          const bdmName = bdm && users.length>0 ? users.find(
            ({ id }) => id == parseInt(bdm)
          ):'';
          return bdmName ? bdmName.name : '';
        },
      },
      {
        field: 'noOfStudents',
        headerName: 'Student Count',
        width: 120,
        hide:true,
        renderCell: (params) => <Typography className='pl-8'>{params.row.noOfStudents}</Typography>,
      },
       
      {
        field: 'source',
        headerName: 'Source',
        width: 200,
        valueGetter: (params) => {
          const source = params.row.source;
          const sourceName = source ? AGENTSOURCES.find(
            ({ id }) => id == source
          ):'';
          return sourceName ? sourceName.type : '';
        },
      },
      {
        field: 'createdOn',
        headerName: 'Created On',
        width: 120,
        hide: true,
        valueGetter: (params) => {
          const createdOn = params.row.createdOn;
          return createdOn? createdOn.split('T')[0]:'';
        },
      }
  ];

  const handleClearFilters = () => {
    setReferralId('');
    setReferralName('');
    setStatus(0);
    setSelectedRows([]);
    setReferralList([]);
    setShowData(false);
    setSelectAll(false);
    fetchData(false); // Refetch the data after clearing filters
  };

  const handleRowSelection = (event, rowId) => {
    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowId];
      const selectedStudensLeadtId = referrals.find(
        (referral) => referral.id === rowId
      ).id;
      handleAddStudentsLeadId(selectedStudensLeadtId);
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
    }

    setSelectedRows(newSelectedRows);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDialogueCommunicationName('');
  };

  const WhatsappConfirmDialog = () => (
    <Dialog
      open={openWhatsappConfirmDialog}
      onClose={handleWhatsappCloseConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Confirm Sending Whatsapp'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description ">
          Are you sure you want to send the whatsapp?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="flex justify-around">
        <Button
          onClick={handleWhatsappCloseConfirmDialog}
          color="primary"
          className="btn-primary"
        >
          No
        </Button>
        <Button
          onClick={handleSendWhatsapp}
          color="success"
          className="btn-success"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <div className="h-96 w-full">
      <div className="bg-white p-4 mb-4 shadow-sm">
      <ReferralComFilters
          referralId={referralId}
          referralName={referralName}
          status={status}
          setReferralId={setReferralId}
          setReferralName={setReferralName}
          setStatus={setStatus}
          handleClearFilters={handleClearFilters}
          handleApplyFilters={handleApplyFilters}
          handleSendBulkEmail={handleOpenDialog}
          totalSelected={totalSelected}
        
        />

        {showData ? (
          // Show the DataGrid only when showData is true (filters are applied)
          dataLoaded ? ( <Grid item style={{ height: 730, width: '100%' }}>
            <DataGridPro
              rows={referrals}
              columns={referralComColumns}
              totalPageSize={totalPages}
              loading={loading}
              pagination={true}
              paginationMode="server"
              pageSize={pageSize}
              pageSizeOptions={[800]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowCount={totalrows}
              onPageChange={(newPage) => setPageNumber(newPage + 1)}
              page={pageNumber - 1}
              components={{
                Toolbar: CustomToolbar,
                Pagination: GridPagination,
              }}
            /></Grid>
          ) : (
            <div>Loading data...</div>
          )
        ) : (
          <div>No data to display. Please apply filters to show data.</div>
        )}

        <WhatsappSummaryDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          totalSelected={totalSelected}
          templates={templates}
          dialogueCommunicationName={dialogueCommunicationName}
          selectTemplateId={selectTemplateId}
          handleSelectTemplateIdChange={handleSelectTemplateIdChange}
          handleCommunicationNameChange={handleCommunicationNameChange}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          // fromphoneList={Phone_From}
          // setFromNumber={setFromNumber}
        />

        {successMessage && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-blue-500 text-black p-4 rounded shadow-lg">
              <p>{successMessage}</p>
              <button
                className="btn btn-warning mt-4"
                onClick={() => setSuccessMessage('')}
              >
                Close
              </button>
            </div>
          </div>
        )}

        <ErrorMessageDisplay />
        <WhatsappConfirmDialog />

        {showCountdownLoader && (
          <CountdownLoader
            totalEmails={totalSelected}
            onCountdownComplete={handleCountdownComplete}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
    users: state.users,
  };
};

export default connect(mapStateToProps, null)(ReferralBulkWhatsapp);
