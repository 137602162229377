import React from 'react'
import {styled} from '@mui/material/styles'
// import GoogleFontLoader from 'react-google-font-loader';
// import NoSsr from '@mui/material/NoSsr'
// import Avatar from '@mui/material/Avatar'
// import AvatarGroup from '@mui/material/AvatarGroup'
// import Box from '@mui/material/Box'
// import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
// import AvatarMui from '../Common/Image/AvatarMui'
import AgentSvg from '../../assets/images/dashboardagents.svg'
// import {Link} from 'react-router-dom'

const PREFIX = 'TeamCardDemo'

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  logo: `${PREFIX}-logo`,
  avatar: `${PREFIX}-avatar`,
  join: `${PREFIX}-join`,
}

const Root = styled('div')(() => ({
  [`& .${classes.root}`]: {
    height: '100%',
    transition: '0.3s',
    position: 'relative',
    '&:before': {
      transition: '0.2s',
      position: 'absolute',
      width: '100%',
      height: '100%',
      content: '""',
      display: 'block',
      backgroundColor: '#d9daf1',
      borderRadius: '1rem',
      zIndex: 0,
      bottom: 0,
    },
    '&:hover': {
      '&:before': {
        bottom: -6,
      },
      '& $card': {
        boxShadow: '-12px 12px 64px 0 #bcc3d6',
      },
    },
  },

  [`& .${classes.card}`]: {
    zIndex: 1,
    position: 'relative',
    borderRadius: '1rem',
    boxShadow: '0 6px 20px 0 #dbdbe8',
    backgroundColor: '#fff',
    transition: '0.4s',
    height: '100%',
  },

  [`& .${classes.logo}`]: {
    width: 96,
    height: 96,
    borderRadius: '0.75rem',
  },

  [`& .${classes.avatar}`]: {
    fontFamily: 'Ubuntu',
    fontSize: '0.875rem',
    backgroundColor: '#6d7efc',
  },

  [`& .${classes.join}`]: {
    background: 'linear-gradient(to top, #638ef0, #82e7fe)',
    '& > *': {
      textTransform: 'none !important',
    },
  },
}))

const CustomCard = ({
  appUsers,
  thumbnail,
  title,
  subtitle,
  description,
  goToLink,
}) => {
  return (
    <Root className={classes.root}>
      {/* <Column className={styles.card}>
        <Row p={2} gap={2}>
          <Avatar className={styles.logo} variant={'rounded'} src={thumbnail} />
          <Info position={'middle'}>
            <InfoTitle>{title}</InfoTitle>
            <InfoSubtitle>{subtitle}</InfoSubtitle>
          </Info>
        </Row>
        <Box
          pb={1}
          px={2}
          color={'grey.600'}
          fontSize={'0.875rem'}
          fontFamily={'Ubuntu'}
        >
          {description}
        </Box>
        {appUsers && (
          <Row p={2} gap={2} position={'bottom'}>
            <Item>
              <AvatarGroup max={4} classes={{ avatar: styles.avatar }}>
                {appUsers.map((user, index) => {
                  if (user.image) {
                    return <Avatar key={index} src={user.image} />;
                  }
                })}
                {appUsers.map((user, index) => {
                  if (user.image == null) {
                    return (
                      <AvatarMui
                        imageUrl={null}
                        fullName={`${user.firstName} ${user.lastName}`}
                        size={5}
                        key={index}
                      />
                    );
                  }
                })}

              </AvatarGroup>
            </Item>
            <Item position={'middle-right'}>
              <Button
                component={Link}
                to={goToLink}
                className={styles.join}
                variant={'contained'}
                color={'primary'}
                disableRipple
              >
                View
              </Button>
            </Item>
          </Row>
        )}
      </Column> */}
    </Root>
  )
}

export const TeamCardDemo = React.memo(function TeamCard({appUsers, groups}) {
  // const userInfo = {
  //   userType,
  // };

  return (
    <>
      {/* <NoSsr>
        <GoogleFontLoader fonts={[{ font: 'Ubuntu', weights: [400, 700] }]} />
      </NoSsr> */}
      <Grid container spacing={4}>
        {groups.map((group, index) => {})}

        <Grid item xs={12} md={6} lg={4}>
          <CustomCard
            appUsers={appUsers}
            thumbnail={AgentSvg}
            title={'Recruitment Partners'}
            subtitle={'Click view to manage'}
            description={
              appUsers.length > 1
                ? `${appUsers[0].firstName + ' ' + appUsers[0].lastName} and ${
                    appUsers.length - 1
                  } others are agents in your list`
                : null
            }
            goToLink={'/agents'}
          />
        </Grid>
        {/* <Grid item xs={12} md={6} lg={4}>
          <CustomCard
            appUsers={appUsers}
            joined
            thumbnail={
              'https://cm1.narvii.com/7153/05204b8d8dcbb652dd1a8ceaafde997bc1909468_00.jpg'
            }
            title={'League of Legends Official'}
            subtitle={'Created by LoL'}
            description={
              'You are already a member of this group since April 5th 2019.'
            }
          />
        </Grid> */}
        {/* <Grid item xs={12} md={6} lg={4}>
          <CustomCard
            appUsers={appUsers}
            thumbnail={'https://avatarfiles.alphacoders.com/537/53765.jpg'}
            title={'Overwatch official'}
            subtitle={'Created by Bliz'}
            description={
              <>
                <b>RainBOW</b> and 3 others are already members of this group.
              </>
            }
          />
        </Grid> */}
      </Grid>
    </>
  )
})
export default TeamCardDemo
