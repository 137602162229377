import React, { useEffect, useState, useRef } from 'react';

import { Grid, IconButton, TextField, Typography } from '@mui/material';
import Agent from '../../../../../../api/agent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import MyTooltip from '../../../../../Common/Tooltip/MyTooltip';
import { styled } from '@mui/material/styles';

const PREFIX = 'Requirement';

const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
  panelHeader: `${PREFIX}-panelHeader`,
  panelBody: `${PREFIX}-panelBody`,
};
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
    color: 'white',
  },

  [`& .${classes.panelHeader}`]: {
    backgroundColor: theme.palette.primary.light,
    minHeight: '10px !important',
    height: '50px',
  },

  [`& .${classes.panelBody}`]: {
    paddingTop: 10,
  },
}));

const RequirementItemMob = ({
  item,
  isInternal,
  studentId,
  showAlert,
  isEven,
  updateGrandParent,
}) => {
  const [file, setFile] = useState();
  const [type, setType] = useState(2); // agent/student import = 2, internal import = 1
  const fileInput1 = useRef();
  const fileInput2 = useRef();
  const [isComplete, setIsComplete] = useState(item.isComplete);

  useEffect(() => {
    if (file) {
      Agent.Requirements.upload(file, type, studentId, item.id)
        .then((id) => {
          if (type == 1) {
            item = { ...item, downloadCloudFileId: id };
          } else {
            item = { ...item, uploadCloudFileId: id };
          }
          updateGrandParent(item);
        })
        .catch((error) => {
          console.log(error);
          showAlert(false);
          debugger;
        });
    }
  }, [file]);

  const initialFileUpload1 = () => {
    fileInput1.current.click();
  };
  const initialFileUpload2 = () => {
    fileInput2.current.click();
  };

  const handleInfoUpdate = (id, info) => {
    var req = {
      id,
      info,
      isComplete,
    };
    update(req);
  };

  const handleIsCompleteUpdate = (id, info) => {
    var req = {
      id,
      info,
      isComplete: !isComplete,
    };
    update(req);
  };

  const update = (item) => {
    Agent.Requirements.saveItem(item)
      .then(() => {
        item = { ...item, isComplete: !isComplete };
        updateGrandParent(item);
      })
      .catch((error) => {
        console.log(error);
        showAlert(false);
        debugger;
      });
  };

  const deleteItem = (id) => {
    Agent.Requirements.deleteItem(id, item.requirementId)
      .then(() => {
        updateGrandParent(null);
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  return (
    <div className="flex flex-col justify-center items-center ">
      <Grid item xs={5} className='flex justify-center items-center'>
        <Grid item>
          <Typography color="primary">{item.name}</Typography>
        </Grid>
      </Grid>
      {item.type == 1 && !item.uploadCloudFileId && (
        <Grid item xs={5}>
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => {
              setType(2);
              setFile(e.target.files[0]);
            }}
            ref={fileInput1}
          />
          <IconButton onClick={initialFileUpload1}>
            <MyTooltip title="Upload the required file for TCL">
            <span className='text-gray-900 text-sm'>Upload the required file for TCL </span> <br/> <CloudUploadIcon fontSize="large" />
            </MyTooltip>
          </IconButton>
          <hr/>
        </Grid>
      )}
     {item.type == 1 && isInternal && !item.downloadCloudFileId && (
          <Grid item xs={3}>
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => {
                setType(1);
                setFile(e.target.files[0]);
              }}
              ref={fileInput2}
            />
            <MyTooltip title="Upload file for agent">
              <span className='text-gray-900 text-sm'>Upload file for agent</span> <br/>
              <IconButton className='pl-14' onClick={initialFileUpload2}>
                <CloudUploadIcon fontSize="large" />
              </IconButton>
            </MyTooltip>
            <hr/>
          </Grid>
        )}
      {item.type == 1 && item.uploadCloudFileId && (
        <Grid item xs={5} className='flex justify-center items-center'>
          <Grid item>
          <Typography color="secondary">TCL required file </Typography>
         </Grid>
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={() => {}}
            ref={fileInput1}
          />
          <IconButton
            onClick={() => {
              setType(2);
              Agent.Requirements.download(item.id, 2).then((response) => {
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                  new Blob([response.data], {
                    type: 'application/octet-stream',
                  })
                );
                link.download = response.headers.filename;
                document.body.appendChild(link);
                link.click();
                // setDownloading(false);
                setTimeout(function () {
                  window.URL.revokeObjectURL(link);
                }, 200);
              });
            }}
          >
            <DownloadIcon fontSize="large" />
          </IconButton>
         
        </Grid>
      )}

      {item.type == 1 && item.downloadCloudFileId && (
          <Grid item xs={3} className='flex justify-center items-center'>
             <Grid item>
          <Typography color="secondary">Agent required file </Typography>
         </Grid>
            <MyTooltip title="Download sample file">
              <IconButton
                onClick={() => {
                  setType(1);
                  Agent.Requirements.download(item.id, 1).then((response) => {
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(
                      new Blob([response.data], {
                        type: 'application/octet-stream',
                      })
                    );
                    link.download = response.headers.filename;
                    document.body.appendChild(link);
                    link.click();
                    setTimeout(function () {
                      window.URL.revokeObjectURL(link);
                    }, 200);
                  });
                }}
              > 
                <DownloadIcon fontSize="large" />
              </IconButton>
            </MyTooltip>
          </Grid>
        )}
        
      {item.type == 2 && (
        <Grid item xs={5} container alignItems={'center'}>
          <TextField
            placeholder="Enter the value here"
            fullWidth
            size="small"
            defaultValue={item.info}
            onBlur={(e) => handleInfoUpdate(item.id, e.target.value)}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
      )}
      <Grid className="flex flex-row justify-between gap-4 items-center">
        
        <Grid item xs={3}>
          <IconButton
            disabled={!isInternal}
            onClick={() => {
              handleIsCompleteUpdate(item.id, item.info);
              setIsComplete(!isComplete);
            }}
          >
            <MyTooltip
              title={
                isComplete ? 'Mark this as incomplete' : 'Mark this as complete'
              }
            >
              <CheckCircleIcon
                color={isComplete ? 'success' : ''}
                fontSize="large"
              />
            </MyTooltip>
          </IconButton>
        </Grid>
        {isInternal && (
          <Grid item xs={3}>
            <IconButton onClick={() => deleteItem(item.id)}>
              <MyTooltip title="Remove this requirement task">
                <RemoveCircleRoundedIcon color="secondary" fontSize="large" />
              </MyTooltip>
            </IconButton>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default RequirementItemMob;
