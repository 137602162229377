import {Typography, Grid} from '@mui/material'
// import { styled } from '@mui/material/styles';
import React, {useState} from 'react'
import Agent from '../../../../api/agent'
import {LoadingButton} from '@mui/lab'
import MySnackbarAlert from '../../../Common/AlertMessage/MySnackbarAlert'
import ConfirmationModal from '../../../Common/Modal/ConfirmationModal'
import DeleteIcon from '@mui/icons-material/Delete'
import BlockIcon from '@mui/icons-material/Block'
import RotateRightIcon from '@mui/icons-material/RotateRight'
import {useNavigate} from 'react-router-dom'

// const PREFIX = 'UniversityAction';

// const classes = {
//   root: `${PREFIX}-root`,
//   fieldset: `${PREFIX}-fieldset`,
//   legend: `${PREFIX}-legend`,
//   extendedIcon: `${PREFIX}-extendedIcon`,
//   fabPosition: `${PREFIX}-fabPosition`,
// };

// const Root = styled('div')(({ theme }) => ({
//   [`& .${classes.root}`]: {
//     flexGrow: 1,
//   },
//   [`& .${classes.fieldset}`]: {
//     borderColor: theme.palette.common.lightBlue,
//     borderRadius: 5,
//     marginBottom: 20,
//   },
//   [`& .${classes.legend}`]: {
//     margin: 'auto',
//     padding: 10,
//   },
//   [`& .${classes.extendedIcon}`]: {
//     marginRight: theme.spacing(1),
//   },
//   [`& .${classes.fabPosition}`]: {
//     margin: 0,
//     top: 'auto',
//     right: 24,
//     bottom: 20,
//     left: 'auto',
//     position: 'fixed',
//   },
// }));

const UniversityAction = ({profile, updateProfileParent}) => {
  const navigate = useNavigate()
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false)
  const [openConfirmActivate, setOpenConfirmActivate] = useState(false)
  const [inActiveUpdating, setInActiveUpdating] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [activate, setActivate] = useState(false)
  const [confirmText, setConfirmText] = useState(
    'Are you sure that you would like to remove this university from the system?'
  )

  //#snackbar stuff
  const [isInValid, setIsInValid] = useState(true)
  const [openSuccess, setOpenSuccess] = useState(false)
  const [openFailure, setOpenFailure] = useState(false)
  const [alertMsg, setAlertMsg] = useState()
  //#endregion

  const deleteUniversity = () => {
    setDeleting(true)
    Agent.Universities.deleteUniversity(profile.id)
      .then(() => {
        setDeleting(false)
        setOpenConfirmDelete(false)
        navigate('/universities')
      })
      .catch((error) => {
        console.log(error)
        debugger
      })
  }

  const actionActivation = () => {
    setInActiveUpdating(true)
    profile.isInactive = !activate
    Agent.Universities.saveUniversity({university: profile})
      .then((id) => {
        setInActiveUpdating(false)
        setOpenConfirmActivate(false)
        setOpenSuccess(true)
        setOpenFailure(false)
        setAlertMsg('Action successful')
        updateProfileParent({id: id, isInactive: !activate})
      })
      .catch((error) => {
        setInActiveUpdating(false)
        console.log(error)
        setOpenSuccess(false)
        setOpenFailure(true)
        setAlertMsg(
          'Error: Failed to save the university. Refresh the page and try again!',
          false
        )
        debugger
      })
  }

  const confirmDelete = () => {
    setConfirmText(
      'Are you sure that you would like to remove this university from the system?'
    )
    setOpenConfirmDelete(true)
  }
  const confirmDeactivate = () => {
    setConfirmText(
      'Are you sure that you would like to make this university inactive?'
    )
    setActivate(false)
    setOpenConfirmActivate(true)
  }

  const confirmReactivate = () => {
    setConfirmText(
      'Are you sure that you would like to re-activate this university?'
    )
    setActivate(true)
    setOpenConfirmActivate(true)
  }

  return (
    <>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Typography textAlign='center' color='primary'>
            University Actions
          </Typography>
        </Grid>
        <Grid item>
          <LoadingButton
            loading={deleting}
            onClick={confirmDelete}
            startIcon={<DeleteIcon />}
            variant='contained'
            className='bg-rose-400 hover:bg-rose-500 text-white'
          >
            {' '}
            Delete University
          </LoadingButton>
        </Grid>
        <Grid item>
          <LoadingButton
            loading={inActiveUpdating}
            onClick={profile.isInactive ? confirmReactivate : confirmDeactivate}
            startIcon={profile.isInactive ? <RotateRightIcon /> : <BlockIcon />}
            variant='outlined'
          >
            {profile.isInactive
              ? 'Re-activate University'
              : 'Make University Inactive'}
          </LoadingButton>
        </Grid>
      </Grid>

      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
        />
      )}
      <ConfirmationModal
        open={openConfirmDelete}
        cancel={setOpenConfirmDelete}
        confirm={deleteUniversity}
        confirmText='Confirm Deletion'
        content={confirmText}
      />
      <ConfirmationModal
        open={openConfirmActivate}
        cancel={setOpenConfirmActivate}
        confirm={actionActivation}
        confirmText={
          profile.isInactive ? 'Confirm Reactivation' : 'Confirm Deactivation'
        }
        content={confirmText}
      />
    </>
  )
}

export default UniversityAction
