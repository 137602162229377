/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
// import { format } from 'date-fns';

import LoadingView from '../../../Common/Loading/LoadingView';
import ProfileHeader from '../../common/profileHeader';

import {
  APPLICATION_STATUSES,
  INTAKES,
  STUDY_LEVELS,
} from '../../../../Util/Constants';
import Agent from '../../../../api/agent';

const StudentAppList = () => {
  const [applications, setApplications] = useState([]);
  const [applicationsLoading, setApplicationsLoading] = useState(true);

  // const navigate = useNavigate();

  useEffect(() => {
    Agent.Students.getStudent(0)
      .then((std) => {
        const { studentCloudFiles, ...rest } = std;
        setApplications(rest.applications);
      })
      .catch((error) => {
        debugger;
        console.log(error);
      });

    // Agent.Applications.list().then((data) => {
    //   setApplications(data);
    // });
    setApplicationsLoading(false);
  }, []);

  const handleOpenAppPage = (student) => {
    window.open(
      `/students/${student.studentId}/applications/${student.id}`,
      '_blank'
    );
  };

  return (
    <div className="w-full max-w-[1400px] pt-2">
      <div className="pb-5">
        <ProfileHeader
          studentFirstName={''}
          subTitle="View all your applications here"
        />
      </div>

      <div className="w-full text-xl text-gray-700 shadow-xl font-semibold studentSidebarBgGradient1 rounded-lg p-4 cursor-default">
        Applications Page
      </div>
      {/* APPLICATIONS TABLE */}
      {applicationsLoading ? (
        <div className="pb-10">
          <LoadingView />
        </div>
      ) : (
        <div className="p-6 px-2 bg-gray-200  mt-6 rounded-lg shadow-xl">
          <div className="overflow-x-auto app-scrollbar min-h-[400px]">
            <div className="w-full min-w-max ">
              <div className="flex gap-1 m- p-2 h-12 w-full text-lg font-semibold px-2 ">
                <div className={`w-[200px] xl:w-[350px]`}>Institution</div>
                <div className={`w-[200px] xl:w-[350px]`}>Course</div>
                <div className={`w-[120px]`}>Status</div>
                <div className={`w-[120px]`}>Year</div>
                <div className={`w-[150px]`}>Intake</div>
                <div className={`w-[150px]`}>Level</div>
                {/* <div className={`w-[150px]`}>Added</div>
                <div className={`w-[150px]`}>Applied</div> */}
              </div>

              {applications.map((item, idx) => {
                return (
                  <div key={idx} onClick={() => handleOpenAppPage(item)}>
                    <div className="flex items-center gap-1 bg-white text-gray-700 rounded-lg p-2 my-2 h-12  hover:bg-sky-400 hover:text-white cursor-pointer transition-all duration-100 text-base ">
                      <div className={`w-[200px] xl:w-[350px] truncate px-2`}>
                        {item.universityName}
                      </div>
                      <div className={`w-[200px] xl:w-[350px] truncate`}>
                        {item.courseName}
                      </div>
                      <div className={`w-[120px]`}>
                        {APPLICATION_STATUSES.filter(
                          (appStatus) => appStatus.id === item.status
                        ).map((appStatus) => appStatus.type)}
                      </div>
                      <div className={`w-[120px]`}>{item.year}</div>
                      <div className={`w-[150px]`}>
                        {INTAKES.find(({ id }) => id == item.intake).type}
                      </div>
                      <div className={`w-[150px]`}>
                        {
                          STUDY_LEVELS.find(({ id }) => id == item.studyLevelId)
                            .type
                        }
                      </div>
                      {/* <div className={`w-[150px]`}>
                        {format(new Date(item.appliedOn), 'dd-MM-yyyy')}
                      </div>
                      <div className={`w-[150px]`}>
                        {item.appliedOnActual
                          ? format(new Date(item.appliedOnActual), 'dd-MM-yyyy')
                          : null}
                      </div> */}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StudentAppList;
