/* eslint-disable react/jsx-no-target-blank */
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { Typography, Grid, Divider } from '@mui/material';
import { Button } from '@mui/material';
import Agent from '../../../../api/agent';
import LoadingView from '../../../Common/Loading/LoadingView';
import GroupIcon from '@mui/icons-material/Group';
import AddEditStaff from './AddEditStaff';
import AvatarMui from '../../../Common/Image/AvatarMui';
import PasswordIcon from '@mui/icons-material/Password';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ConfirmationModal from '../../../Common/Modal/ConfirmationModal';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import SettingsIcon from '@mui/icons-material/Settings';

const PREFIX = 'Documents';

const classes = {
  size: `${PREFIX}-size`,
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`,
  panelHeader: `${PREFIX}-panelHeader`,
};
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.size}`]: {
    width: (props) => theme.spacing(props.size ? props.size : 10),
    height: (props) => theme.spacing(props.size ? props.size : 10),
  },
  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(2),
  },
  [`& .${classes.panelHeader}`]: {
    backgroundColor: theme.palette.common.lightBlue,
    minHeight: '0px !important',
    height: 40,
  },
}));
const Staff = ({ agent, isInternal }) => {
  const [loading, setLoading] = useState(false);
  const [staff, setStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState({ agentId: agent.id });
  const [error, setError] = useState();
  const [addMode, setAddMode] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [disableTitle, setDisableTitle] = useState('');
  const [disableMsg, setDisableMsg] = useState('');
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    getStaff();
  }, []);

  const getStaff = async () => {
    await Agent.Agents.getStaff(agent.id)
      .then((records) => {
        //setStaff(records);
        let temp = [
          {
            id: 0,
            agentId: agent.id,
            appUserId: agent.appUserId,
            appUserDetailsId: agent.appUserDetailsId,
            firstName: agent.userProfile.firstName,
            lastName: agent.userProfile.lastName,
            fileUrl: agent.userProfile.image,
            position: 'Principal Account',
            email: agent.userProfile.email,
            applicationUpdateEmail: agent.applicationUpdateEmail,
            password: null,
            principalAccount: true,
          },
        ];
        setStaff(temp.concat(records));
      })
      .catch((error) => {
        debugger;
        console.log(error);
      });
  };

  const disableToggleAgentUser = async () => {
    await Agent.Agents.disableToggleAgentUser(selectedStaff)
      .then(() => {
        setLoading(false);
        setOpenConfirm(false);
        getStaff();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setOpenConfirm(false);
      });
  };

  return loading ? (
    <LoadingView />
  ) : (
    <Root>
      {addMode && (
        <AddEditStaff
          staff={selectedStaff}
          setRecordAddMode={setAddMode}
          getStaff={getStaff}
        />
      )}
      <Grid container direction="column">
        <Grid
          item
          style={{ marginBottom: 30 }}
          container
          spacing={2}
          justifyContent={'end'}
        >
          <Grid item container>
            <Grid item xs={8}>
              <Typography color={'secondary'} variant={'h5'} align="center">
                {isInternal
                  ? "Agent's Portal Access Management"
                  : 'Portal Access Management'}
              </Typography>
            </Grid>
            <Grid item xs={4} container justifyContent={'flex-end'} spacing={1}>
              {staff.filter((s) => !s.isDisabled).length < 6 && (
                <Grid item>
                  {' '}
                  <Button
                    color="secondary"
                    variant="outlined"
                    className={classes.button}
                    startIcon={<GroupIcon />}
                    onClick={() => {
                      setSelectedStaff({
                        agentId: agent.id,
                        id: 0,
                        action: 'new',
                      });
                      setAddMode(true);
                    }}
                  >
                    Add Staff
                  </Button>
                </Grid>
              )}
              <Grid item>
                {' '}
                <Button
                  variant="outlined"
                  className={classes.button}
                  startIcon={<SettingsIcon />}
                  onClick={() => {
                    setShowOptions(!showOptions);
                  }}
                >
                  {`${showOptions ? 'Hide' : 'Show'} Advanced Options`}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {staff.map((member, index) => (
            <Grid item container key={index} direction="column">
              <Grid item container spacing={6} style={{ marginBottom: 10 }}>
                <Grid
                  item
                  xs={8}
                  container
                  spacing={3}
                  style={{ marginBottom: 0 }}
                >
                  <Grid item xs={10} container direction="column" spacing={1}>
                    <Grid item container spacing={2}>
                      <Grid item xs={2}>
                        <AvatarMui
                          imageUrl={member.fileUrl}
                          fullName={`${member.firstName} ${member.lastName}`}
                          size={10}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{ marginTop: 5 }}
                        container
                        direction={'column'}
                        xs={10}
                      >
                        <Grid item>
                          {' '}
                          <Typography
                            textAlign="left"
                            as={Button}
                            style={{
                              padding: 0,
                              textDecoration: 'underline',
                              textTransform: 'none',
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              setSelectedStaff({ ...member, action: 'basic' });
                              setAddMode(true);
                            }}
                          >
                            {`${member.firstName} ${member.lastName}`}
                          </Typography>
                        </Grid>
                        {member.position && (
                          <Grid item>
                            <Typography>{member.position}</Typography>
                          </Grid>
                        )}
                        {member.isDisabled && (
                          <Grid item>
                            <Typography color={'secondary'}>
                              Account Disabled
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item style={{ paddingTop: 0 }}>
                      <Typography variant="caption"></Typography>
                    </Grid>
                  </Grid>
                </Grid>
                {showOptions && (
                  <Grid item xs={3} container direction={'column'} spacing={1}>
                    <Grid item>
                      {' '}
                      <Button
                        fullWidth
                        size="small"
                        disabled={error}
                        className='bg-red-800 w-50'
                        variant="contained"
                        onClick={() => {
                          setSelectedStaff({ ...member, action: 'email' });
                          setAddMode(true);
                        }}
                        startIcon={<AccountBoxIcon />}
                      >
                        Change Login Email
                      </Button>
                    </Grid>
                    <Grid item>
                      {' '}
                      <Button
                        fullWidth
                        size="small"
                        className='bg-yellow-700 w-50 '
                        disabled={error}
                        variant="contained"
                        onClick={() => {
                          setSelectedStaff({ ...member, action: 'password' });
                          setAddMode(true);
                        }}
                        startIcon={<PasswordIcon />}
                      >
                        Change Password
                      </Button>
                    </Grid>
                    {!member.principalAccount && (
                      <Grid item>
                        {' '}
                        <Button
                          fullWidth
                          className='bg-red-500 w-50 '
                          size="small"
                          disabled={error}
                          variant="contained"
                          onClick={() => {
                            setSelectedStaff({
                              agentId: member.agentId,
                              id: member.id,
                            });
                            setDisableTitle(
                              member.isDisabled
                                ? 'Confirm Reactivation'
                                : 'Confirm Disable'
                            );
                            setDisableMsg(
                              `Are you sure that you would like to ${
                                member.isDisabled ? 'reactivate' : 'disable'
                              } the user account of '${member.firstName} ${
                                member.lastName
                              }'?`
                            );
                            setOpenConfirm(true);
                          }}
                          startIcon={
                            member.isDisabled ? (
                              <AccountBoxIcon />
                            ) : (
                              <NoAccountsIcon />
                            )
                          }
                        >
                          {member.isDisabled
                            ? 'Reactivate Account'
                            : 'DISABLE Account'}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid item style={{ marginBottom: 10 }}>
                <Divider />
              </Grid>
            </Grid>
          ))}
          <ConfirmationModal
            open={openConfirm}
            cancel={setOpenConfirm}
            confirm={disableToggleAgentUser}
            confirmText={disableTitle}
            content={disableMsg}
            loading={loading}
          />
        </Grid>
      </Grid>
    </Root>
  );
};
export default Staff;
