import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Grid, Typography, Button } from '@mui/material';

import Agent from '../../../api/agent';
import { Countries } from '../../../Util/Util';
import { INTAKES, STUDY_LEVELS } from '../../../Util/Constants';
import { yearList } from '../MockData/MockData';
import { connect } from 'react-redux';
import {
  AutocompleteSelectionMenu,
  DatePickerField,
  intakeYearList,
  SelectionMenu,
  FormatTitle,
  getRows,
  formatDate,
  MultiSelectionMenu,
  StudentTypeList,
} from '../Helper/HelperFunction';
import AppReportStatus from './AppReportStatus';

const ModifiedIntakeMonth = [
  { id: 0, type: 'All Intakes', typeShort: 'All' },
  ...INTAKES.map((item) => item),
];

const ModifiedIntakeYear = [
  { id: 0, year: 'All Intakes' },
  ...intakeYearList.map((item) => item),
];
const ModifiedEducationLevels = [
  { id: 0, type: 'All Levels' },
  ...STUDY_LEVELS.map((item) => item),
];
const ModifiedStudentType = [...StudentTypeList.map((item) => item)];

const appTypes = [
  'applicationSubmitted',
  'actualApplied',
  'offerHolder',
  'rejection',
  'payment',
  'conversion',
  'pendingApplication',
  'cancellation',
];

const ApplicationReport = ({
  appUserId,
  appUserDetailsId,
  role,
  permissions,
}) => {

  const [appData, setAppData] = useState();
  const [branchList, setBranchList] = useState();
  const [AgentList, setAgentList] = useState();
  const [isApiLoading, setApiLoading] = useState(true);
  const [universities, setUniversities] = useState([]);
  const [cosmosAdminUsers, setCosmosAdminUsers] = useState([]);
  const [payload, setPayload] = useState({
    year: 2023,
    intakeMonth: 0,
    intakeYear: 0,
    agent: 0,
    branch: 0,
    university: 0,
    courseLevel: 0,
    admin: 0,
    nationalities: [],
    startDate: null,
    endDate: null,
    studentType: 0,
    appUserId,
    appUserDetailsId,
    role,
    canManageAll: permissions.CanManageAllAgents,
  });

  //API CALL TO GET REPORT DATA
  useEffect(() => {
    Agent.Report.applicationSummary(payload).then((res) => {
      setAppData(res.data.applicationSummaryData);
      setApiLoading(false);
    });
    Agent.Cache.getBranchOffices().then(setBranchList);
    Agent.Agents.list().then(setAgentList);
  }, [payload]);

  // API CALL TO GET ADMIN FILTER DATA
  useEffect(() => {
    (async () => {
      Agent.Cosmos.getUsers()
        .then((users) => {
          setCosmosAdminUsers(
            users.filter((item) => {
              return (
                (item.usertype == 1 ||
                  item.usertype == 2 ||
                  item.usertype == 3) &&
                !item.isdisabled
              );
            })
          );
        })
        .catch((err) => {
          console.log('err', err);
        });
    })();
    Agent.Universities.list()
      .then((req) => {
        setUniversities(req);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  //COLUMN FIELD LOGIC
  const columns = [
    {
      field: 'title',
      headerName: '',
      width: 200,
      renderCell: (params) => (
        <Typography color="#0081C9" fontSize={18} fontWeight={500}>
          {params.row.title}
        </Typography>
      ),
    },
    ...INTAKES.map((month) => ({
      field: month.typeShort,
      headerName: month.typeShort,
      width: 100,
    })),
    { field: 'Total', headerName: 'Total', width: 100 },
  ];

  //ROW FIELD LOGIC
  let rowData = [];

  const getRowData = () => {
    appTypes.forEach((item) => {
      let temp = getRows(item, appData);
      rowData.push({ [item]: temp });
    });
  };

  !isApiLoading && getRowData();

  const row = rowData.map((item) => {
    const key = Object.keys(item)[0];
    const data = item[key];
    return { id: key, title: FormatTitle(key), ...data };
  });

  // HANDLE RETURN DATA FROM SELECT MENU FILTER COMPONENT
  const handleSelectionMenu = (name, value) => {
    setPayload((prev) => {
      return { ...prev, [name]: Number(value) || 0 };
    });
  };

  // HANDLE RETURN DATA FROM AUTOCOMPLETE FILTER COMPONENT
  const handleSelect = (name, value) => {
    setPayload((prev) => {
      return { ...prev, [name]: Number(value.id) || 0 };
    });
  };

  // HANDLE RETURN DATA FOR MULTIPLE NATIONALITIES FROM FILTER COMPONENT
  const handleNationSelect = (e) => {
    const val = [...e];

    val.length != 0
      ? setPayload((prev) => {
          return { ...prev, nationalities: val };
        })
      : setPayload((prev) => {
          return { ...prev, nationalities: [] };
        });
  };

  //HANDLE YEAR CHANGE
  const handleYearChange = (_, value) => {
    setPayload((prev) => {
      return {
        ...prev,
        year: Number(value) || 0,
        startDate: null,
        endDate: null,
      };
    });
  };

  // HANDLE DATA FROM DATE PICKER
  const handleDateChange = (date, name, secondName) => {
    const currentYear = new Date().getFullYear();
    let newDate = null;

    if (date != null) {
      newDate = formatDate(date);
      console.log('newDate', newDate);

      setPayload({
        ...payload,
        [name]: `${newDate[0]}-${newDate[1]}-${newDate[2]}`,
        year: 0,
      });
    } else if (date == null) {
      setPayload({
        ...payload,
        [name]: null,
        year: payload[secondName] == null ? currentYear : 0,
      });
    }
  };
  // const handleReset = () => {
  //   setPayload({
  //     year: 0,
  //     intakeYear: 0,
  //     startDate: null,
  //     endDate: null,
  //   });
  // };

  //console.log('payload', payload);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Grid container direction={'column'}>
        <Grid item>
          <h4
            style={{
              textTransform: 'uppercase',
              color: '#282A3A',
              textAlign: 'center',
            }}
          >{`Application Summary`}</h4>
        </Grid>
        <Grid
          container
          justifyContent={'center'}
          alignItems="center"
          sx={{ px: 2 }}
        >
          <Grid item>
            <AutocompleteSelectionMenu
              name="admin"
              label="Admin"
              options={cosmosAdminUsers}
              onChange={handleSelect}
            />
          </Grid>
          <Grid item>
            <MultiSelectionMenu
              selection={payload.nationalities}
              name="nationalities"
              label="Nationalities"
              options={Countries}
              onChange={handleNationSelect}
            />
          </Grid>
          <Grid item>
            <SelectionMenu
              name="intakeMonth"
              label="Intake Month"
              options={ModifiedIntakeMonth}
              onChange={handleSelectionMenu}
            ></SelectionMenu>
          </Grid>
          <Grid item>
            <SelectionMenu
              name="intakeYear"
              label="Intake Year"
              options={ModifiedIntakeYear}
              onChange={handleSelectionMenu}
            ></SelectionMenu>
          </Grid>

          <Grid item>
            <SelectionMenu
              name="year"
              label="Year"
              val={payload.year}
              options={yearList}
              onChange={handleYearChange}
            ></SelectionMenu>
          </Grid>

          <Grid item>
            <AutocompleteSelectionMenu
              name="university"
              label="University"
              options={universities}
              onChange={handleSelect}
            />
          </Grid>

          <Grid item>
            <AutocompleteSelectionMenu
              name="branch"
              label="Branch"
              options={branchList}
              onChange={handleSelect}
              disabled={payload.studentType == 1 ? true : false}
            />
          </Grid>
          <Grid item>
            <AutocompleteSelectionMenu
              item="companyName"
              name="agent"
              label="Agents"
              options={AgentList}
              onChange={handleSelect}
              disabled={payload.studentType == 2 ? true : false}
            ></AutocompleteSelectionMenu>
          </Grid>

          <Grid item>
            <SelectionMenu
              // className={classes.root}
              name="courseLevel"
              label="Course Level"
              options={ModifiedEducationLevels}
              onChange={handleSelectionMenu}
            ></SelectionMenu>
          </Grid>
          <Grid item>
            <DatePickerField
              name="startDate"
              secondName="endDate"
              label="Start Date"
              selected={payload.startDate}
              onChange={handleDateChange}
            ></DatePickerField>
          </Grid>
          <Grid item>
            <DatePickerField
              name="endDate"
              secondName="startDate"
              label="End Date"
              selected={payload.endDate}
              onChange={handleDateChange}
            ></DatePickerField>
          </Grid>
          <Grid item>
            <SelectionMenu
              name="studentType"
              label="Student Type"
              options={ModifiedStudentType}
              onChange={handleSelectionMenu}
            ></SelectionMenu>
          </Grid>

          {/* <Grid item>
            <FormControl fullWidth>
              <Button
              onClick={handleReset}
                variant="outlined"
                sx={{
                  width: '230px',
                  mx: 1,
                  height: '40px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  maxWidth: '200px',
                }}
              >
                Reset All
              </Button>
            </FormControl>
          </Grid> */}
        </Grid>

        <Grid sx={{ mt: 0 }}>
          <DataGrid
            rows={row}
            columns={columns}
            hideFooter
            ColumnFilteredIcon
            autoHeight
            disableColumnFilter
            components={{ Toolbar: GridToolbar }}
            // className={classes.root}
            style={{
              borderRadius: '16px',
              fontSize: '17px',
              color: '#263159',
              margin: 0,
              padding: 0,
            }}
          />
        </Grid>
      </Grid>

      <AppReportStatus />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(ApplicationReport);
