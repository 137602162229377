import React from 'react';
import {
  Grid,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  Switch,
  InputLabel,
  FormHelperText,
} from '@mui/material';
import {
  AGENTSTATUS,
} from '../../../../Util/Constants';
import { AiFillMail } from 'react-icons/ai';


const ReferralComFilters = ({
  referralId,
  referralName,
  status,
  setReferralId,
  setReferralName,
  setStatus,
  handleClearFilters,
  handleApplyFilters,
  handleSendBulkEmail,
  totalSelected,

}) => {
  
  return (
    <>
      <div className="flex justify-between items-center gap-4">
        <Grid container spacing={6} className="mb-0">
          <div className="flex flex-col items-center mt-6 pb-4 ml-0">
            <div className="flex flex-row mt-4 ml-10">
              <div className="flex justify-between items-center gap-2 mr-36">
                <Grid item>
                  <TextField
                    size="small"
                    label="Referral Id"
                    value={referralId}
                    onChange={(e) => setReferralId(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    size="small"
                    label="Referral Name"
                    value={referralName}
                    onChange={(e) => setReferralName(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <FormControl>
                    <InputLabel>Status</InputLabel>
                    <Select
                      size="small"
                      native
                      label="Status"
                      placeholder="Status"
                      className='w-48'
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      <option value={0}>All</option>
                      {AGENTSTATUS.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.type}
                            </option>
                          ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Button
                  variant="outlined"
                  className="ml-2 w-48"
                  onClick={handleClearFilters}
                >
                  Clear Filters
                </Button>

                <Button
                  variant="contained"
                  className="bg-blue-500 text-white ml-0 w-48"
                  onClick={handleApplyFilters}
                >
                  Apply Filters
                </Button>

                <Button
                  variant="contained"
                  className="bg-sky-800 text-white w-48"
                  onClick={handleSendBulkEmail}
                  disabled={totalSelected === 0}
                >
                  <AiFillMail className="mr-2 text-lg" />
                  Send Bulk
                </Button>
              </div>
            </div>
          </div>
        </Grid>
      </div>
    </>
  );
};

export default ReferralComFilters;
