import React from 'react';
// import Agent from '../../../../api/agent';

import { Countries } from '../../../../Util/Util';
import { format } from 'date-fns';
import {
  StudentLead_Activities,
  StudentLead_Status,
} from '../../../../Util/Constants';

const Summary = ({ student }) => {
  // NOTE: Below code is commented out because it is not used. If used in the future, remove this comment.

  // const [editMode, setEditMode] = useState(false);
  // const [status, setStatus] = useState(student.status);
  // const changeStatus = async (newStatus) => {
  //   await Agent.Students.setStatus(student.id, newStatus)
  //     .then(() => {
  //       setStatus(newStatus);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       debugger;
  //     });
  // };
  return (
    <div className="studentSidebarBgGradient3 p-4 rounded-lg drop-shadow-md my-0">
      <div className="text-3xl font-semibold text-white drop-shadow-md">
        {student.firstName} {student.lastName}
      </div>
      {console.log(student)}
      {/* {editMode ? (
            <Grid item xs={1} container justifyContent="flex-end">
              <Select
                size="small"
                native
                label="Status"
                value={status}
                onChange={(e) => {
                  changeStatus(e.target.value);
                }}
              >
                {STUDENTSTATUS.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.type}
                  </option>
                ))}
              </Select>
            </Grid>
          ) : (
            <Grid item xs={1} container justifyContent="flex-end">
              <Typography
                color={student.status == 1 ? 'primary' : null}
                variant="h5"
              >
                {STUDENTSTATUS.find(({ id }) => id === student.status).type}
              </Typography>
            </Grid>
          )} */}
      {/* 
          <Grid item xs={1}>
            <MyTooltip
              icon={<EditIcon color="success" />}
              title="Change Status"
            >
              <IconButton onClick={() => setEditMode(true)} size="large">
                <EditIcon color="success" />
              </IconButton>
            </MyTooltip>
          </Grid> */}
      <div className="text-gray-800 text-xl font-medium drop-shadow-">
        {student.id} | {student.email} | {student.phoneNumber} |{' '}
        {Countries.find(({ id }) => id === student.citizenship).name} |{' '}
        {format(new Date(student.dateOfBirth), 'MMMM dd, yyyy')}
      </div>

      {student.agentName && (
        <div className="text-gray-800 text-xl font-medium drop-shadow-">
          Agent: {student.agentName} | {student.agentEmail} |{' '}
          {student.agentPhone} |{' '}
          {student.agentCountry > 0
            ? Countries.find(({ id }) => id === student.agentCountry).name
            : ''}
        </div>
      )}
      <div className="text-gray-800 text-xl font-medium drop-shadow-">
        {` ${student.adminName ? `Admin: ${student.adminName}` : ''}  ${
          student.seniorAdminName
            ? `| Senior Admin: ${student.seniorAdminName}`
            : ''
        }`}
      </div>
      <div className="text-gray-800 text-lg text-end   font-medium drop-shadow-">
        Lead Status:
        {StudentLead_Status.find(({ id }) => id === student.leadStatus)?.name || 
          'N/A'}
      </div>
      <div className="text-gray-800 text-lg text-end   font-medium drop-shadow-">
        Lead Activity: 
        {StudentLead_Activities.find(({ id }) => id === student.leadActivity)
          ?.name || 'N/A'}{' '}
      </div>
    </div>
  );
};

export default Summary;
