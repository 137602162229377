import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import {
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Card,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddMessage from '../../../Messages/AddMessage';
import { motion } from 'framer-motion';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { STATUS_APPROVED, STATUS_INCOMPLETE } from '../../../../Util/Constants';
import Agent from '../../../../api/agent';
import CustomModal from '../../../Custom/Modal/CustomModal.js';
import AddQuickStudent from './AddQuickStudentModal';
import RegionalSales from './RegionalSales';
import SummaryCounts from './SummaryCount';
import ManagerProfile from './ManagerProfile';
import { NextPlan } from '@mui/icons-material';
import ShowAnnouncementList from '../../../AdminPanel/Announcement/ShowAnnouncementList.js';

const Dashboard = ({ appUserId, users, loadUsers,announcementList,role }) => {
  // const classes = useStyles();
  const navigate = useNavigate();
  const [agent, setAgent] = useState({});
  const [agentLoading, setAgentLoading] = useState(true);
  const [addQuickStudent, setAddQuickStudent] = useState();
  const [businessDev, setBusinessDev] = useState({
    name: '',
    appUserId: 0,
    email: '',
    phone: '',
    image: '',
  });
  const [openAddMessage, setOpenAddMessage] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const successDialogStyles = {
    backgroundColor: 'bg-green-500', // Green color for success
  };

  useEffect(() => {
    if (users.length == 0) {
      loadUsers().catch((error) => {
        console.log(error);
        debugger;
      });
    }
    if (users.length > 0 && !agentLoading) {
      if (agent.businessDevAppUserDetailsId > 0) {
        const businessUser = users.find(
          ({ id }) => id == agent.businessDevAppUserDetailsId
        );
        setBusinessDev({
          name: businessUser.name,
          appUserId: agent.businessDevAppUserDetailsId,
          email: businessUser.applicationUpdateEmail,
          phone: businessUser.phoneNumber,
          image: businessUser.image,
        });
      } else {
        setBusinessDev({
          name: 'Not Assigned Yet',
          appUserId: 0,
          email: '',
          phone: '',
          image: '',
        });
      }
    }
  }, [users]);

  useEffect(() => {
    Agent.Agents.getAgent(0, appUserId)
      .then((agent) => {
        setAgent(agent);
        setAgentLoading(false);
        if (users.length > 0) {
          if (agent.businessDevAppUserDetailsId > 0) {
            const businessUser = users.find(
              ({ id }) => id == agent.businessDevAppUserDetailsId
            );
            setBusinessDev({
              name: businessUser.name,
              appUserId: agent.businessDevAppUserDetailsId,
              email: businessUser.applicationUpdateEmail,
              phone: businessUser.phoneNumber,
              image: businessUser.image,
            });
          } else {
            setBusinessDev({
              name: 'Not Assigned Yet',
              appUserId: 0,
              email: '',
              phone: '',
              image: '',
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  }, []);

  const handleAddQuickStudent = () => setAddQuickStudent(true);

  const inComplete = (
    <motion.div
      initial={{ scale: 0.8, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.8, opacity: 0 }}
      transition={{ type: 'spring', stiffness: 400, damping: 20 }}
      className="fixed inset-0 flex justify-center items-center z-50 bg-zinc-500 bg-opacity-80"
    >
      <Card className="max-w-xl p-auto bg-white rounded-lg shadow-lg">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
          spacing={2}
        >
          <Grid item>
            <h3 className="text-orange-500	 text-center text-3xl font-bold m-4">
              <AccountCircleIcon fontSize="large" /> Your profile is not
              complete yet!
            </h3>
          </Grid>
          <Grid item>
            <Typography className="text-center text-sky-600 mt-2 text-xl font-semibold">
              Please complete your profile so that you can get started with
              managing students and applications.
            </Typography>
          </Grid>
          <Grid item>
            <motion.div whileHover={{ scale: 1.05 }} className="mt-6">
              <Button
                variant="outlined"
                startIcon={<AccountCircleIcon />}
                onClick={() => navigate('/Profile')}
                disabled={agent.status === STATUS_APPROVED}
                className="btn btn-warning m-2"
              >
                Complete Profile <NextPlan/>
              </Button>
            </motion.div>
          </Grid>
        </Grid>
      </Card>
    </motion.div>
  );

  return !agentLoading && agent.status == STATUS_INCOMPLETE ? (
    inComplete
  ) : !agentLoading ? (
    <>
      <Dialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        aria-labelledby="success-dialog-title"
        PaperProps={{
          className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
        }}
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>{successMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className="lg:min-w-[1080px] flex mb-8">
        <div className="lg:mt-12">
          <SummaryCounts />
        </div>
        <div className="flex flex-col gap-1 items-end">
          <div>
            <motion.div whileHover={{ scale: 1.051 }}>
              <Button
                size="large"
                className="bg-sky-400 hover:bg-sky-500 text-white"
                variant="contained"
                startIcon={<AddCircleIcon />}
                onClick={() => handleAddQuickStudent()}
                // onClick={() => navigate('/students/register')}

                disabled={agent.status !== STATUS_APPROVED}
              >
                Add Quick Student
              </Button>
            </motion.div>
          </div>
          <ManagerProfile
            businessDev={businessDev}
            setOpenAddMessage={setOpenAddMessage}
          />
        </div>
      </div>

      <div>
      <ShowAnnouncementList
        announcementList={announcementList}
        role={role}
      />
      </div>
      <div>
        <RegionalSales />
      </div>
      {openAddMessage && (
        <AddMessage
          open={openAddMessage}
          cancel={setOpenAddMessage}
          toId={businessDev.appUserId}
          agentId={0}
          toName={businessDev.name}
        />
      )}
      {addQuickStudent && (
        <CustomModal setTrue={addQuickStudent} setFalse={setAddQuickStudent}>
          <AddQuickStudent
            setAddQuickStudent={setAddQuickStudent}
            setSuccessMessage={setSuccessMessage}
            setSuccessOpen={setSuccessOpen}
          />
        </CustomModal>
      )}
    </>
  ) : (
    ''
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    users: state.users,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateAgent: (count) => dispatch(actions.updateAgent(count)),
    updateStudent: (count) => dispatch(actions.updateStudent(count)),
    updateApplication: (count) => dispatch(actions.updateApplication(count)),
    updateAdmin: (count) => dispatch(actions.updateAdmin(count)),
    loadUsers: () => dispatch(actions.loadUsers()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
