/* eslint-disable import/no-unresolved */
import React, { useState, useContext, useRef } from 'react';
import { styled } from '@mui/material/styles';
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
// import StudentContext from '../../../../Students/Context/student-context'
// import {STUDENT_DOCUMENT_TYPES} from '../../../../../Util/Constants'
import Draggable from 'react-draggable';
// import LinearIndeterminate from '../../../../Common/Progress/LinearIndeterminate'
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { IconButton } from '@mui/material';
import { ButtonGroup } from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Select } from '@mui/material';
// import PhotoWidgetDropzone from '../../../../Common/ImageUpload/PhotoWidgetDropzone'
import Agent from './../../../../api/agent';
import StudentContext from '../../../Students/Context/student-context';
import { STUDENT_DOCUMENT_TYPES, TEMP_STU_DOCUMENT } from '../../../../Util/Constants';
import LinearIndeterminate from '../../../Common/Progress/LinearIndeterminate';
import PhotoWidgetDropzone from '../../../Common/ImageUpload/PhotoWidgetDropzone';
import StudentLead from '../StudentLead';

const PREFIX = 'Add';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Add = ({
  addRecordToParent,
  id,
  setStudentIdParent,
  setRecordAddMode,
  studentLeadId,
  studentLeadCloudFiles,
}) => {
  let studentContext = useContext(StudentContext);
  const [open, setOpen] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [file, setFile] = useState();
  const [error, setError] = useState();

  const documentTypeRef = useRef();
  const descriptionRef = useRef();

  function handleClose() {
    setOpen(false);
    setRecordAddMode(false);
  }

  const handleFormCancel = () => {
    setOpen(false);
    setRecordAddMode(false);
  };

  const uploadToCloud = () => {
    if (documentTypeRef.current.value == '') {
      documentTypeRef.current.focus();
      return;
    }

    if (documentTypeRef.current.value == 18) {
      // 18 is other category
      if (descriptionRef.current.value == '') {
        descriptionRef.current.focus();
        return;
      }
    }

    let fileZie = file.size / 1024 / 2024;
    if (fileZie > 5) {
      return;
    }
    setUpdating(true);
    if (studentLeadId > 0) {
      completeUpload(studentLeadId);
    } else {
      var studentLeadData = {
        SaveStudentLeadsDto: {
          StudentLead: {
            id: 0,
            //studentLeadCloudFiles
          },

          agentAppUserId: studentContext.agentAppUserId,
          createdByAppUserId: studentContext.createdByAppUserId,
        },
      };

      Agent.StudentLeads.saveStudentUpdateLead(studentLeadData)
        .then((id) => {
          //debugger
          //setStudentIdParent(id)
          return id;
        })
        .then(completeUpload)
        .catch((error) => {
          debugger;
          setUpdating(false);
          console.log(error);
          setError(
            'Error: Failed to upload. Check file type/size etc and try again!'
          );
        });
    }
  };

   const valueGetter=(stu_value)=>{
    for(const val in TEMP_STU_DOCUMENT) {
      if (TEMP_STU_DOCUMENT.hasOwnProperty.call(TEMP_STU_DOCUMENT, val)) {
        const element = TEMP_STU_DOCUMENT[val];
        if (stu_value==element) {
          return stu_value
        }
      }
    }
   }
   const FILTERED_STUDENT_DOCUMENT_TYPES=STUDENT_DOCUMENT_TYPES.filter(stu=>{
      return valueGetter(stu.id)
   })

  const completeUpload = (studentLeadId) => {
    Agent.CloudFiles.upload(
      file,
      documentTypeRef.current.value,
      0,
      0,
      descriptionRef.current.value,
      0,
      0,
      0,
      studentLeadId
    )
      .then((response) => {
        var file = {
          fileUrl: response.blobUri,
          fileName: response.blobName,
          fileNameLocal: response.fileNameLocal,
          containerName: response.containerName,
          documentType: parseInt(documentTypeRef.current.value),
          description: descriptionRef.current.value || '',
          documentTypeDesc: STUDENT_DOCUMENT_TYPES.find(
            ({ id }) => id == parseInt(documentTypeRef.current.value)
          ).type,
        };
        addRecordToParent(file);
        setUpdating(false);
        documentTypeRef.current.value = '';
        descriptionRef.current.value = '';
        setFile(null);
        handleFormCancel();
      })
      .catch((error) => {
        console.log(error);
        setUpdating(false);
        setError(
          'Error: Failed to upload. Check file type/size etc and try again!'
        );
      });
  };

  const upload = (file) => {
    const harmful = ['exe', 'pif', 'bat'];
    setFile(file);
    let fileZie = file.size / 1024 / 2024;
    if (fileZie > 2) {
      setError('Error: File size cannot be more than 2 MB');
      return;
    }
    const extension = file.name.split('.').pop();
    if (harmful.includes(extension.toLowerCase())) {
      setError(
        'Error: Unsupported file type. You can only upload a valid document file type'
      );
      return;
    }
    setError(null);
  };

  return (
    <StyledDialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      aria-labelledby="draggable-dialog-title"
      PaperComponent={PaperComponent}
      maxWidth="md"
    >
      <>
        <DialogTitle
          style={{ cursor: 'move', minWidth: 600 }}
          id="draggable-dialog-title"
        >
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h6" color="primary" align="center">
                New Document
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleFormCancel}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider />
        </DialogTitle>
        <DialogContent style={{ overflowY: 'hidden' }}>
          <Grid container direction="column" spacing={3}>
            {updating && (
              <Grid item>
                <LinearIndeterminate />
              </Grid>
            )}
            <Grid item container direction="column" spacing={1}>
              <Grid item style={{ marginTop: 5 }}>
                <FormControl
                  required
                  style={{ margin: 0 }}
                  variant="outlined"
                  className={classes.formControl}
                  size="small"
                  fullWidth
                >
                  <InputLabel htmlFor="outlined-type-native-simple">
                    Document Type
                  </InputLabel>
                  <Select
                    inputRef={documentTypeRef}
                    native
                    label="Document Type"
                    inputProps={{
                      name: 'documentType',
                      id: 'outlined-type-native-simple',
                    }}
                  >
                    <option aria-label="None" value="" />
                    {FILTERED_STUDENT_DOCUMENT_TYPES.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.type}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  inputRef={descriptionRef}
                  fullWidth
                  id="standard-multiline-flexible"
                  label="Document Description"
                  multiline
                  maxRows={4}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
              <Grid item xs={6}>
                <PhotoWidgetDropzone
                  crop={false}
                  upload={upload}
                  message="Drop your document here"
                />
              </Grid>
              {file && (
                <Grid item xs={6} container direction="column" spacing={4}>
                  <Grid item container direction="column">
                    {error && (
                      <Grid
                        item
                        style={{
                          color: 'red',
                          marginBottom: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        {error}
                      </Grid>
                    )}
                    <Grid item>
                      <Typography>{`File name: ${file.name}`}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{`File Size ${
                        file.size / 1024
                      } KiB`}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <ButtonGroup
                      orientation="vertical"
                      size="large"
                      color="primary"
                      aria-label="large outlined primary button group"
                    >
                      <Button
                        onClick={() => {
                          setFile(null);
                        }}
                      >
                        CHOOSE ANOTHER
                      </Button>
                      <Button
                        disabled={error}
                        variant="contained"
                        className="bg-sky-400 hover:bg-sky-500"
                        onClick={uploadToCloud}
                      >
                        SAVE
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          {updating && (
            <Grid item style={{ marginBottom: 20 }}>
              <LinearIndeterminate />
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button onClick={() => handleFormCancel()}>Cancel</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    </StyledDialog>
  );
};

export default Add;
