/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { MUI_LICENSE_KEY } from '../../../Util/Constants';
import { LicenseInfo, DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import { connect } from 'react-redux';
import Agent from '../../../api/agent';
import LoadingView from '../../Common/Loading/LoadingView';
import { Grid, Typography } from '@mui/material';
import Employee from './Employee/Employee';
import * as actions from '../../../store/actions/index';
import { getDateDifference, getDateDifferenceInDays } from '../../../Util/Util';
import AvatarMui from '../../Common/Image/AvatarMui';
import AvatarMuiWithBadge from '../../Common/Image/AvatarMuiWithBadge';

const Employees = ({ users, loadUsers, appUserId, role }) => {
  const [employees, setEmployees] = useState([]);
  const [emp, setEmp] = useState();
  const [openEmployee, setOpenEmployee] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (employees.length > 0) {
      Agent.Employees.getLogins()
        .then((logins) => {
          let list = [];
          employees.forEach((emp) => {
            let login = logins.filter((login) => login.employeeId == emp.id);
            if (login.length == 0) {
              list.push(emp);
            } else {
              console.log('Employee ID: ' + login[0].employeeId);
              console.log('LogIn Time: ' + login[0].time);
              console.log(
                'Difference: ' +
                  getDateDifferenceInDays(new Date(login[0].time))
              );
              list.push({
                ...emp,
                ...{
                  time: login[0].time,
                  online:
                    getDateDifferenceInDays(new Date(login[0].time)) < 1
                      ? true
                      : false,
                },
              });
            }
          });
          setEmployees(list);
        })
        .catch((error) => {
          console.log(error);
          debugger;
        });
    }
  }, [loading]);

  useEffect(() => {
    if (users.length == 0) {
      loadUsers()
        .then()
        .catch((error) => {
          console.log(error);
          debugger;
        });
    }
  }, [users]);

  const getData = (filter = false) => {
    setLoading(true);
    Agent.Employees.getEmployees().then((records) => {
      getRows(records);
    });
  };

  useEffect(() => {
    if (users.length > 0) {
      getData();
    }
  }, [users]);

  const getRows = (rows) => {
    let allRows = [];
    rows.map((row) => {
      let managerList = [];
      const managers = users.filter((user) =>
        row.managers.includes(user.appUserDetailsId)
      );
      managers.forEach((man) => {
        managerList.push(man.name);
      });
      allRows.push({
        id: row.id,
        appUserDetailsId: row.appUserDetailsId,
        fullName: row.fullName,
        firstName: row.firstName,
        lastName: row.lastName,
        jobTitle:
          row.workDetails && row.workDetails.jobTitle
            ? row.workDetails.jobTitle
            : '',
        logoUrl: row.logoUrl,
        isDisabled: row.isDisabled ? 'Disabled' : '',
        managers: row.managers,
        managerList: managerList.join(', '),
        time: '',
        online: false,
      });
    });
    setEmployees(allRows);
    setLoading(false);
  };

  const manageEmployee = (row) => {
    setEmp(row);
    setOpenEmployee(true);
  };

  let columns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
    },
    {
      field: 'logoUrl',
      headerName: '',
      width: 100,
      renderCell: (params) =>
        params.row.online ? (
          <AvatarMuiWithBadge
            imageUrl={params.value}
            fullName={params.row.fullName}
            size={5}
          />
        ) : (
          <AvatarMui
            imageUrl={params.value}
            fullName={params.row.fullName}
            size={5}
          />
        ),
    },
    {
      field: 'fullName',
      headerName: 'Name',
      width: 350,
      renderCell: (params) => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <span
          onClick={() => manageEmployee(params.row)}
          style={{ marginLeft: '10px', cursor: 'pointer' }}
        >
          {params.value}
        </span>
      ),
    },
    {
      field: 'jobTitle',
      headerName: 'Job Title',
      width: 150,
    },
    {
      field: 'managerList',
      headerName: 'Line Manager',
      width: 350,
    },
    {
      field: 'time',
      headerName: 'Login',
      width: 150,
      renderCell: (params) => getDateDifference(params.row.time, false),
    },
    {
      field: 'isDisabled',
      headerName: 'Account Status',
      width: 150,
    },
    // {
    //   field: 'jobTitle',
    //   headerName: 'Job Title',
    //   width: 200,
    // },
  ];
  return loading || users.length == 0 ? (
    <LoadingView />
  ) : (
    <>
      <Grid container>
        <Grid item xs={12}>
          <div style={{ height: 730, width: '100%' }}>
            <DataGridPro
              disableSelectionOnClick
              rows={employees}
              columns={columns}
              components={{
                Toolbar: GridToolbar,
              }}
              hideFooterSelectedRowCount
              // density="compact"
            />
          </div>
        </Grid>
      </Grid>
      {openEmployee && <Employee emp={emp} setOpenEmployee={setOpenEmployee} />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users.filter((user) => user.roleId !== 5),
    appUserId: state.auth.id,
    role: state.auth.role,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Employees);
