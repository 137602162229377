import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../../../Common/Form/MyTextInput';
import { v4 as uuid } from 'uuid';
import Agent from '../../../../../api/agent';
import CloseIcon from '@mui/icons-material/Close';
import StudentContext from '../../../../Students/Context/student-context';
import MySelectInputFormik from '../../../../Common/Form/MySelectInputFormik';
import { EDUCATION_LEVELS } from '../../../../../Util/Constants';
import MyCountryListFormik from '../../../../Common/Form/MyCountryListFormik';
import Draggable from 'react-draggable';
import LinearIndeterminate from '../../../../Common/Progress/LinearIndeterminate';
import MyDate from '../../../../Common/Form/MyDate';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { IconButton } from '@mui/material';

const PREFIX = 'Add';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Add = ({
  record,
  eduRecords,
  setEduRecordsParent,
  setRecordAddMode,
  id,
  setStudentIdParent,
  isLocked,
}) => {
  let studentContext = useContext(StudentContext);
  const [open, setOpen] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [showDescription, setShowDescription] = useState(
    record.educationLevel == 9 // others category
  );

  const handleFormCancel = () => {
    setOpen(false);
    setRecordAddMode(false);
  };

  const validationSchema = Yup.object({
    institute: Yup.string()
      .required('Institute name is required')
      .min(3, 'Must be 3 characters or more')
      .max(50, 'Must be 50 characters or less')
      .typeError('Institute name is required'),
    programName: Yup.string()
      .required('Program name is required')
      .min(3, 'Must be 3 characters or more')
      .max(50, 'Must be 50 characters or less')
      .typeError('Program name is required'),
    degree: Yup.string()
      .required('Degree is required')
      .min(3, 'Must be 3 characters or more')
      .max(50, 'Must be 50 characters or less')
      .typeError('Degree is required'),
    passingYear: Yup.string()
      .required('Passing Year is required')
      .min(4, 'Must be 4 characters')
      .max(4, 'Must be 4 characters'),
    educationLevel: Yup.string().required('Education level is required'),
    description: Yup.string()
      .nullable()
      .when('educationLevel', {
        is: '9',
        then: () =>
          Yup.string()
            .required('Education level name is required')
            .min(3, 'Must be 3 characters or more')
            .max(50, 'Must be 50 characters or less')
            .typeError('Education level name is required'),
      }),
    country: Yup.string()
      .matches(/^\d+$/)
      .typeError('Country selection is required'),
    // startOfStudies: Yup.date().required('Start date is required').nullable(),
    studyLanguage: Yup.string()
      .required('Study Language is required')
      .min(3, 'Must be 3 characters or more')
      .max(50, 'Must be 50 characters or less')
      .typeError('Study Language is required'),
    gpa: Yup.string()
      .nullable()
      .test(
        'len',
        'GPA is too large',
        (val) => !val || val.toString().length <= 15
      ),
    maximumGpa: Yup.string()
      .nullable()
      .test(
        'len',
        'Maximum GPA is too large',
        (val) => !val || val.toString().length <= 15
      ),
  });
  return (
    <Root>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleFormCancel();
          }
        }}
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperComponent}
        maxWidth="sm"
        fullWidth
      >
        <>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h6" color="primary" align="center">
                  Education
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleFormCancel}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Divider />
          </DialogTitle>
          <DialogContent>
            <Formik
              enableReinitialize
              initialValues={record}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                let newRecords = [];
                if (record.id == 'new') {
                  newRecords = eduRecords.concat({
                    ...values,
                    ...{ id: uuid(), order: eduRecords.length + 1 },
                  });
                } else {
                  eduRecords.forEach((item) => {
                    if (item.id === record.id) {
                      newRecords.push(values);
                    } else {
                      newRecords.push(item);
                    }
                  });
                }
                var studentData = {
                  saveStudentDto: {
                    student: {
                      id: id,
                      educationRecords: newRecords,
                    },
                    agentAppUserId: studentContext.agentAppUserId,
                    createdByAppUserId: studentContext.createdByAppUserId,
                    saveMode: 1,
                  },
                };
                setUpdating(true);
                Agent.Students.saveStudent(studentData)
                  .then((studentId) => {
                    if (id > 0) {
                      setStudentIdParent(id);
                      setEduRecordsParent(newRecords);
                    }
                    setUpdating(false);
                    handleFormCancel();
                  })
                  .catch((error) => {
                    debugger;
                    setUpdating(false);
                  });
              }}
              validationSchema={validationSchema}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleSubmit,
                  isValid,
                  setFieldValue,
                } = props;
                return (
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <Grid item container spacing={3}>
                      <Grid
                        item
                        xs={6}
                        container
                        direction="column"
                        spacing={2}
                      >
                        <Grid item style={{ marginTop: 5 }}>
                          <MySelectInputFormik
                            fullWidth
                            label="Education Level"
                            name="educationLevel"
                            placeholder="Select Education Level"
                            items={EDUCATION_LEVELS}
                            size="small"
                            onChangeParent={(val) => {
                              setShowDescription(val == 9);
                            }}
                            required
                          />
                        </Grid>
                        {showDescription && (
                          <Grid item>
                            <MyTextInput
                              label="Education Level Name"
                              name="description"
                              placeholder="Education Level Name"
                              required
                            />
                          </Grid>
                        )}
                        <Grid item>
                          <MyTextInput
                            label="Institute"
                            name="institute"
                            placeholder="Institue"
                            required
                          />
                        </Grid>
                        <Grid item>
                          <MyTextInput
                            label="Program"
                            name="programName"
                            placeholder="Program Name"
                            required
                          />
                        </Grid>
                        <Grid item>
                          <MyTextInput
                            label="Degree"
                            name="degree"
                            placeholder="Degree"
                            required
                          />
                        </Grid>
                        <Grid item>
                          <MyTextInput 
                          label="Passing Year"
                          name="passingYear"
                          placeholder="Passing Year"
                          required
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        container
                        direction="column"
                        spacing={2}
                      >
                        <Grid item style={{ marginTop: 5 }}>
                          <MyDate
                            label="Start Date"
                            placeholderText="Start Date"
                            name="startOfStudies"
                            dateFormat="dd-MM-yyyy"
                            error={
                              touched.startOfStudies &&
                              Boolean(errors.startOfStudies)
                            }
                          />
                        </Grid>
                        <Grid item>
                          <MyDate
                            label="Expected End Date"
                            placeholderText="Expected End Date"
                            name="expectedGraduation"
                            dateFormat="dd-MM-yyyy"
                          />
                        </Grid>                        
                        <Grid item>
                          <MyCountryListFormik
                            label="Country"
                            name="country"
                            value={values.country}
                            setFieldValue={setFieldValue}
                            required={true}
                            errorMessage="Country selection is required"
                          />
                        </Grid>
                        <Grid item>
                          <MyTextInput
                            label="Study Language"
                            name="studyLanguage"
                            placeholder="Study Language"
                            required
                          />
                        </Grid>
                        <Grid item>
                          <MyTextInput
                            label="GPA/Average Grade"
                            name="gpa"
                            placeholder="GPA/Average Grade"
                          />
                        </Grid>
                        <Grid item>
                          <MyTextInput
                            label="Maxium GPA/Grade Possible"
                            name="maximumGpa"
                            placeholder="Maxium GPA/Grade Possible"
                          />
                        </Grid>
                        <Grid item></Grid>
                      </Grid>
                    </Grid>

                    <DialogActions>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Button onClick={() => handleFormCancel()}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            type="submit"
                            disabled={
                              isSubmitting || !isValid || !dirty || isLocked
                            }
                            variant="contained"
                            className="bg-blue-500"
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </DialogActions>
                  </form>
                );
              }}
            </Formik>
            {updating && (
              <Grid item style={{ marginBottom: 20 }}>
                <LinearIndeterminate />
              </Grid>
            )}
          </DialogContent>
        </>
      </Dialog>
    </Root>
  );
};

export default Add;
