export const applicationSummaryData = [
  {
    id: 1,
    month: 'Jan',
    applicationSummary: '',
    applicationSubmitted: 4212,
    offerHolder: 1512,
    rejection: 35,
    conversion: 123512,
    pendingApplication: 123512,
    cancellation: 1212,
  },

  {
    id: 2,
    month: 'Feb',
    applicationSummary: '',
    applicationSubmitted: 3512,
    offerHolder: 123512,
    rejection: 35,
    conversion: 123512,
    pendingApplication: 123512,
    cancellation: 123512,
  },
  {
    id: 3,
    month: 'Mar',
    applicationSummary: '',
    applicationSubmitted: 123512,
    offerHolder: 123512,
    rejection: 35,
    conversion: 123512,
    pendingApplication: 123512,
    cancellation: 123512,
  },
  {
    id: 4,
    month: 'Apr',
    applicationSummary: '',
    applicationSubmitted: 123512,
    offerHolder: 123512,
    rejection: 35,
    conversion: 123512,
    pendingApplication: 123512,
    cancellation: 123512,
  },
  {
    id: 5,
    month: 'May',
    applicationSummary: '',
    applicationSubmitted: 123512,
    offerHolder: 123512,
    rejection: 35,
    conversion: 123512,
    pendingApplication: 123512,
    cancellation: 123512,
  },
  {
    id: 6,
    month: 'Jun',
    applicationSummary: '',
    applicationSubmitted: 123512,
    offerHolder: 123512,
    rejection: 35,
    conversion: 123512,
    pendingApplication: 123512,
    cancellation: 123512,
  },
  {
    id: 7,
    month: 'Jul',
    applicationSummary: '',
    applicationSubmitted: 123512,
    offerHolder: 123512,
    rejection: 35,
    conversion: 123512,
    pendingApplication: 123512,
    cancellation: 123512,
  },
  {
    id: 8,
    month: 'Aug',
    applicationSummary: '',
    applicationSubmitted: 123512,
    offerHolder: 123512,
    rejection: 35,
    conversion: 123512,
    pendingApplication: 123512,
    cancellation: 123512,
  },
  {
    id: 9,
    month: 'Sep',
    applicationSummary: '',
    applicationSubmitted: 123512,
    offerHolder: 123512,
    rejection: 35,
    conversion: 123512,
    pendingApplication: 123512,
    cancellation: 123512,
  },
  {
    id: 10,
    month: 'Oct',
    applicationSummary: '',
    applicationSubmitted: 123512,
    offerHolder: 123512,
    rejection: 35,
    conversion: 123512,
    pendingApplication: 123512,
    cancellation: 123512,
  },

  {
    id: 11,
    month: 'Nov',
    applicationSummary: '',
    applicationSubmitted: 123512,
    offerHolder: 123512,
    rejection: 35,
    conversion: 123512,
    pendingApplication: 123512,
    cancellation: 123512,
  },

  {
    id: 12,
    month: 'Dec',
    applicationSummary: '',
    applicationSubmitted: 123512,
    offerHolder: 123512,
    rejection: 35,
    conversion: 123512,
    pendingApplication: 123512,
    cancellation: 123512,
  },
];

export const appStatusData = [
  {
    id: 1,
    month: 'Jan',
    docsMissing: 122,
    defaultStudent: 4212,
    pending: 1512,
    review: 35,
    applied: 123512,
    conditional: 123512,
    unconditional: 1212,
    rejected: 122,
    cancel: 4212,
    payment: 1512,
    casIssued: 35,
    visaReceived: 1212,
    enrolled: 122,
    deadEnrolled: 4212,
    deferral: 1512,
    refund: 35,
    hold: 35,
    appWithdraw: 1212,
    visaRejected: 122,
    notEligible: 4212,
  },
  {
    id: 2,
    month: 'Feb',
    docsMissing: 122,
    defaultStudent: 4212,
    pending: 1512,
    review: 35,
    applied: 123512,
    conditional: 123512,
    unconditional: 1212,
    rejected: 122,
    cancel: 4212,
    payment: 1512,
    casIssued: 35,
    visaReceived: 1212,
    enrolled: 122,
    deadEnrolled: 4212,
    deferral: 1512,
    refund: 35,
    hold: 35,
    appWithdraw: 1212,
    visaRejected: 122,
    notEligible: 4212,
  },

  {
    id: 3,
    month: 'Mar',
    docsMissing: 122,
    defaultStudent: 4212,
    pending: 1512,
    review: 35,
    applied: 123512,
    conditional: 123512,
    unconditional: 1212,
    rejected: 122,
    cancel: 4212,
    payment: 1512,
    casIssued: 35,
    visaReceived: 1212,
    enrolled: 122,
    deadEnrolled: 4212,
    deferral: 1512,
    refund: 35,
    hold: 35,
    appWithdraw: 1212,
    visaRejected: 122,
    notEligible: 4212,
  },
  {
    id: 4,
    month: 'Apr',
    docsMissing: 122,
    defaultStudent: 4212,
    pending: 1512,
    review: 35,
    applied: 123512,
    conditional: 123512,
    unconditional: 1212,
    rejected: 122,
    cancel: 4212,
    payment: 1512,
    casIssued: 35,
    visaReceived: 1212,
    enrolled: 122,
    deadEnrolled: 4212,
    deferral: 1512,
    refund: 35,
    hold: 35,
    appWithdraw: 1212,
    visaRejected: 122,
    notEligible: 4212,
  },
  {
    id: 5,
    month: 'May',
    docsMissing: 122,
    defaultStudent: 4212,
    pending: 1512,
    review: 35,
    applied: 123512,
    conditional: 123512,
    unconditional: 1212,
    rejected: 122,
    cancel: 4212,
    payment: 1512,
    casIssued: 35,
    visaReceived: 1212,
    enrolled: 122,
    deadEnrolled: 4212,
    deferral: 1512,
    refund: 35,
    hold: 35,
    appWithdraw: 1212,
    visaRejected: 122,
    notEligible: 4212,
  },
  {
    id: 6,
    month: 'Jun',
    docsMissing: 122,
    defaultStudent: 4212,
    pending: 1512,
    review: 35,
    applied: 123512,
    conditional: 123512,
    unconditional: 1212,
    rejected: 122,
    cancel: 4212,
    payment: 1512,
    casIssued: 35,
    visaReceived: 1212,
    enrolled: 122,
    deadEnrolled: 4212,
    deferral: 1512,
    refund: 35,
    hold: 35,
    appWithdraw: 1212,
    visaRejected: 122,
    notEligible: 4212,
  },
  {
    id: 7,
    month: 'Jul',
    docsMissing: 122,
    defaultStudent: 4212,
    pending: 1512,
    review: 35,
    applied: 123512,
    conditional: 123512,
    unconditional: 1212,
    rejected: 122,
    cancel: 4212,
    payment: 1512,
    casIssued: 35,
    visaReceived: 1212,
    enrolled: 122,
    deadEnrolled: 4212,
    deferral: 1512,
    refund: 35,
    hold: 35,
    appWithdraw: 1212,
    visaRejected: 122,
    notEligible: 4212,
  },
  {
    id: 8,
    month: 'Aug',
    docsMissing: 122,
    defaultStudent: 4212,
    pending: 1512,
    review: 35,
    applied: 123512,
    conditional: 123512,
    unconditional: 1212,
    rejected: 122,
    cancel: 4212,
    payment: 1512,
    casIssued: 35,
    visaReceived: 1212,
    enrolled: 122,
    deadEnrolled: 4212,
    deferral: 1512,
    refund: 35,
    hold: 35,
    appWithdraw: 1212,
    visaRejected: 122,
    notEligible: 4212,
  },
  {
    id: 9,
    month: 'Sep',
    docsMissing: 122,
    defaultStudent: 4212,
    pending: 1512,
    review: 35,
    applied: 123512,
    conditional: 123512,
    unconditional: 1212,
    rejected: 122,
    cancel: 4212,
    payment: 1512,
    casIssued: 35,
    visaReceived: 1212,
    enrolled: 122,
    deadEnrolled: 4212,
    deferral: 1512,
    refund: 35,
    hold: 35,
    appWithdraw: 1212,
    visaRejected: 122,
    notEligible: 4212,
  },
  {
    id: 10,
    month: 'Oct',
    docsMissing: 122,
    defaultStudent: 4212,
    pending: 1512,
    review: 35,
    applied: 123512,
    conditional: 123512,
    unconditional: 1212,
    rejected: 122,
    cancel: 4212,
    payment: 1512,
    casIssued: 35,
    visaReceived: 1212,
    enrolled: 122,
    deadEnrolled: 4212,
    deferral: 1512,
    refund: 35,
    hold: 35,
    appWithdraw: 1212,
    visaRejected: 122,
    notEligible: 4212,
  },

  {
    id: 11,
    month: 'Nov',
    docsMissing: 122,
    defaultStudent: 4212,
    pending: 1512,
    review: 35,
    applied: 123512,
    conditional: 123512,
    unconditional: 1212,
    rejected: 122,
    cancel: 4212,
    payment: 1512,
    casIssued: 35,
    visaReceived: 1212,
    enrolled: 122,
    deadEnrolled: 4212,
    deferral: 1512,
    refund: 35,
    hold: 35,
    appWithdraw: 1212,
    visaRejected: 122,
    notEligible: 4212,
  },

  {
    id: 12,
    month: 'Dec',
    docsMissing: 122,
    defaultStudent: 4212,
    pending: 1512,
    review: 35,
    applied: 123512,
    conditional: 123512,
    unconditional: 1212,
    rejected: 122,
    cancel: 4212,
    payment: 1512,
    casIssued: 35,
    visaReceived: 1212,
    enrolled: 122,
    deadEnrolled: 4212,
    deferral: 1512,
    refund: 35,
    hold: 35,
    appWithdraw: 1212,
    visaRejected: 122,
    notEligible: 4212,
  },
];
  

export const studetnSummaryData = [
  {
    registered: 500,
    profileCompleted: 100,
    applied: 183,
    outstanding: 10,
    id: 1,
    month: 'Jan',
  },
  {
    registered: 500,
    profileCompleted: 100,
    applied: 183,
    outstanding: 10,
    id: 2,
    month: 'Feb',
  },
  {
    registered: 500,
    profileCompleted: 100,
    applied: 183,
    outstanding: 10,
    id: 3,
    month: 'Mar',
  },
  {
    registered: 500,
    profileCompleted: 100,
    applied: 183,
    outstanding: 10,
    id: 4,
    month: 'Apr',
  },
  {
    registered: 500,
    profileCompleted: 100,
    applied: 183,
    outstanding: 10,
    id: 5,
    month: 'May',
  },
  {
    registered: 500,
    profileCompleted: 100,
    applied: 183,
    outstanding: 10,
    id: 6,
    month: 'Jun',
  },
  {
    registered: 500,
    profileCompleted: 100,
    applied: 183,
    outstanding: 10,
    id: 7,
    month: 'Jul',
  },
  {
    registered: 500,
    profileCompleted: 100,
    applied: 183,
    outstanding: 10,
    id: 8,
    month: 'Aug',
  },
  {
    registered: 500,
    profileCompleted: 100,
    applied: 183,
    outstanding: 10,
    id: 9,
    month: 'Sep',
  },
  {
    registered: 500,
    profileCompleted: 100,
    applied: 183,
    outstanding: 10,
    id: 10,
    month: 'Oct',
  },
  {
    registered: 500,
    profileCompleted: 100,
    applied: 183,
    outstanding: 10,
    id: 11,
    month: 'Nov',
  },
  {
    registered: 500,
    profileCompleted: 100,
    applied: 183,
    outstanding: 10,
    id: 12,
    month: 'Dec',
  },
];

export const yearList = [
  {
    id: 1,
    year: 2021,
  },

  {
    id: 2,
    year: 2022,
  },

  {
    id: 3,
    year: 2023,
  },
  {
    id: 4,
    year: 2024,
  },
  {
    id: 5,
    year: 2025,
  },
  {
    id: 6,
    year: 2026,
  },
  {
    id: 7,
    year: 2027,
  },
  {
    id: 8,
    year: 2028,
  },

];


// export const intakYearList = [
//   {
//     id: 1,
//     year: 2020,
//   },

//   {
//     id: 2,
//     year: 2021,
//   },

//   {
//     id: 3,
//     year: 2022,
//   },
//   {
//     id: 4,
//     year: 2023,
//   },
//   {
//     id: 5,
//     year: 2024,
//   },
// ];



// const currentYear = new Date().getFullYear(); // Get the current year

const previousyear=2022;//  Year starts with 2022 as required
export const intakeYearList = Array.from({length: 8}, (_, i) => ({
  id: i + 1,
  year: previousyear + i,
}));







// export const studyDestination = [
//   {
//     id: 1,
//     name: "UK",
//   },

//   {
//     id: 2,
//     name:"Canada",
//   },

//   {
//     id: 3,
//     name: "",
//   },
//   {
//     id: 4,
//     name:"",
//   },
//   {
//     id: 5,
//     name: "",
//   },
// ];

// export const appDataNew = [
//   { id: 1, appSummary: 'Application Submitted', jan: 100, feb: 200, mar: 150, apr: 300, may: 250, jun: 400, jul: 350, aug: 500, sep: 450, oct: 600, nov: 550, dec: 700 },
//   { id: 2, appSummary: 'Offer Holder', jan: 50, feb: 100, mar: 75, apr: 150, may: 125, jun: 200, jul: 175, aug: 250, sep: 225, oct: 300, nov: 275, dec: 350 },
//   { id: 3, appSummary: 'App3', jan: 200, feb: 300, mar: 250, apr: 400, may: 350, jun: 500, jul: 450, aug: 600, sep: 550, oct: 700, nov: 650, dec: 800 },
// ];
