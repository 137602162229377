import React, {useEffect, useState} from 'react'
// import { styled } from '@mui/material/styles';
// eslint-disable-next-line no-use-before-define
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Switch,
  FormControlLabel,
} from '@mui/material'
import Agent from '../../../api/agent'
import CloseIcon from '@mui/icons-material/Close'
import Draggable from 'react-draggable'
import LinearIndeterminate from '../../Common/Progress/LinearIndeterminate'
import {Typography} from '@mui/material'
import {Divider} from '@mui/material'
import {IconButton} from '@mui/material'
import {connect} from 'react-redux'
import * as actions from '../../../store/actions/index'

// const PREFIX = 'CourseDetails'

// const classes = {
//   closeButton: `${PREFIX}-closeButton`,
//   chip: `${PREFIX}-chip`,
// }

// const Root = styled('div')(({ theme }) => ({
//   [`& .${classes.closeButton}`]: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
//   [`& .${classes.chip}`]: {
//     marginTop: 10,
//     marginRight: 5,
//   },
// }));

function PaperComponent(props) {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const DepartmentAddEdit = ({dept, setAddMode, departments, loadDepts}) => {
  const [open, setOpen] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [deptName, setDeptName] = useState(dept.name)
  const [status, setStatus] = useState(dept.isInactive ? false : true)
  const [isPopular, setIsPopular] = useState(dept.isPopular)

  useEffect(() => {
    if (departments.length == 0) {
      loadDepts()
        .then((list) => {})
        .catch((error) => {
          console.log(error)
          debugger
        })
    } else {
      //
    }
  }, [departments])

  function handleClose() {
    setOpen(false)
    setAddMode(false)
  }

  const handleFormCancel = () => {
    setOpen(false)
    setAddMode(false)
  }

  const saveDept = (event) => {
    event.preventDefault()
    setUpdating(true)

    Agent.Courses.saveDept({
      id: dept.id,
      name: deptName.trim(),
      isInactive: !status,
      isPopular,
    })
      .then(() => {
        setUpdating(false)
        setOpen(false)
        setAddMode(false)
      })
      .catch((error) => {
        console.log(error)
        setUpdating(false)
        debugger
      })
  }

  return (
    // <Root>
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        aria-labelledby='draggable-dialog-title'
        PaperComponent={PaperComponent}
        maxWidth='md'
        fullWidth
      >
        <>
          <DialogTitle style={{cursor: 'move'}} id='draggable-dialog-title'>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant='h6' color='primary' align='center'>
                  Edit Course
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label='close'
                  // className={classes.closeButton}
                  onClick={handleFormCancel}
                  size='large'
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Divider />
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              item
              direction='column'
              spacing={2}
              style={{marginTop: 5}}
            >
              <Grid item>
                <TextField
                  label='Department/Subject Name'
                  fullWidth
                  onChange={(e) => {
                    setDeptName(e.target.value)
                  }}
                  value={deptName}
                  variant='outlined'
                  size='small'
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={status}
                      onChange={(e) => setStatus(e.target.checked)}
                      name='Status'
                    />
                  }
                  label={status ? 'Active' : 'Inactive'}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isPopular}
                      onChange={(e) => setIsPopular(e.target.checked)}
                      name='Is Popular'
                    />
                  }
                  label={isPopular ? 'Popular' : 'Not Popular'}
                />
              </Grid>
            </Grid>
            {updating && (
              <Grid item style={{marginBottom: 20}}>
                <LinearIndeterminate />
              </Grid>
            )}
            <DialogActions>
              <Grid container justifyContent='flex-end' style={{marginTop: 25}}>
                <Grid item>
                  <Button
                    onClick={saveDept}
                    variant='contained'
                    className='bg-sky-400 hover:bg-sky-500'
                  >
                    Submit Changes
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </DialogContent>
        </>
      </Dialog>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    departments: state.departments,
    allocations: state.courses,
    isInternal: state.auth.isInternal,
    role: state.auth.roleName,
    course: ownProps.course,
    universityId: ownProps.universityId,
    setAddMode: ownProps.setAddMode,
    updateParent: ownProps.updateParent,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    updateSingleCourse: (course) =>
      dispatch(actions.updateSingleCourse(course)),
    deleteSingleCourse: (id) => dispatch(actions.deleteSingleCourse(id)),
    loadDepts: () => dispatch(actions.loadDepts()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DepartmentAddEdit)
