import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Typography,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import 'tailwindcss/tailwind.css';
import 'tailwindcss/tailwind.css';
import { connect } from 'react-redux';
import {
  DataGridPro,
  GridPagination,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import Agent from '../../../../../api/agent';
import MyTooltip from '../../../../Common/Tooltip/MyTooltip';
import {
  INTAKES,
  STUDY_LEVELS,
} from '../../../../../Util/Constants';
import { Countries } from '../../../../../Util/Util';
import LeadComFiltersOld from '../../../StudentLeadCommsOld/LeadComFilters/LeadComFiltersOld';
import CountdownLoader from '../../../StudentLeadComms/LeadComUtils/CountdownLoader';
import BulkSummaryDialog from '../../../StudentLeadComms/SendBulkMailDialog/BulkSummaryDialog';
import {  addMonths } from 'date-fns';

function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {isInternal && <GridToolbarColumnsButton />}
      {/* {isInternal && <GridToolbarFilterButton />} */}
      <GridToolbarDensitySelector />
      {hide && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}
const StudentsLeadBulkEmailOld = ({ appUserId }) => {
  const [showCountdownLoader, setShowCountdownLoader] = useState(false);
  const [LeadListName, setLeadListName] = useState('');
  const [Name, setLeadFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [university, setUniversity] = useState(0);
  const [course, setCourse] = useState(0);
  const [intakeMonth, setIntakeMonth] = useState(0);
  const [intakeYear, setIntakeYear] = useState(0);
  const [studyLevelFilter, setStudyLevelFilter] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [studentsLead, setStudentsLead] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [citizenship, setCitizenshipFilter] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalSelected, setTotalSelected] = useState(0);
  const [fromMail, setFromEmail] = useState(0);
  const [selectTemplateId, setSelectTemplateId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [studentsLeadIdList, setStudentsLeadIdList] = useState([]);
  const [offices, setOffices] = useState([]);
  const [fromMailList, setFromMailList] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [dialogueCommunicationName, setDialogueCommunicationName] =
    useState('');
  const [mailSubject, setMailSubject] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showData, setShowData] = useState(false);
  const [showDataApp, setShowDataApp] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(800);
  const [totalrows, setTotalRows] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [events, setEventList] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [dataSetGroup, setDataSetGroup] = useState([]);
  const [dataSetApplications, setDataSetApplications] = useState([]);
  const [dataSetGroupFilter, setDataSetGroupFilter] = useState(0);
  const [datasetOption, setDatasetOption] = useState(0);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const [startDateFilter, setStartDateFilter] = useState(
    addMonths(new Date(),-1).toISOString().split('T')[0]
  ); // Initialize with ISO 8601 format;
  const [endDateFilter, setEndDateFilter] = useState(
    new Date().toISOString().split('T')[0]
  );

  const handleCountdownComplete = () => {
    setShowCountdownLoader(false); // Hide countdown loader
  };
  const handleDatasetChange = (event, value) => {
    handleClearFilters();
    setDatasetOption(value ? value.id : 0);
    setDataSetApplications([]);
    setStudentsLead([]);
  };
  const handleStartDateFilter =(event)=>{
    const selectedDate = event.target.value;
    if (selectedDate) {
      const formattedDate = new Date(selectedDate).toISOString().split('T')[0];
      console.log('formattedDate',formattedDate);
      setStartDateFilter(formattedDate);
    } else {
      setStartDateFilter(null); // Clear the start date filter
    }
  }
  const handleEndDateFilter =(event)=>{
    const selectedDate = event.target.value;
    if (selectedDate) {
      const formattedDate = new Date(selectedDate).toISOString().split('T')[0];
      setEndDateFilter(formattedDate);
    } else {
      setEndDateFilter(null); // Clear the end date filter
    }
  }
  
  const handleApplyFiltersApp = () => {
    setShowData(false);
    setShowDataApp(true);
    setPageNumber(1);
    fetchDataApp(true);
  };
  const handleApplyFilters = () => {
    setShowDataApp(false);
    setShowData(true);
    setPageNumber(1);
    fetchData(true);
  };
  const handleSendMail = async () => {
    handleCloseConfirmDialog();
    setShowCountdownLoader(true); // Show countdown loader
    console.log('payload', payload);
    try {
      await Agent.Communication.bulkEmailStudentsLeadDataset(payload).then(
        (response) => {
          setOpenDialog(false);
          setSuccessMessage('Mail sent successfully!', response);
          setShowCountdownLoader(false);
        }
      );
    } catch (error) {
      console.error('payload error', error);
      setErrorMessage('Post data', error);
      setShowErrorMessage(true);
    }
  };
  useEffect(() => {
    Agent.Communication.listDatasetGroups().then((data) => {
      console.log('parent dataSetGroup', data);
      setDataSetGroup(data);
    });
  }, []);

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  useEffect(() => {
    Agent.Events.getEventList().then(setEventList);
  }, []);

  const handleAddStudentsLeadId = (selectedStudentsLeadId) => {
    setStudentsLeadIdList((prevList) => [
      ...prevList,
      { Id: selectedStudentsLeadId },
    ]);
  };

  const closeErrorMessage = () => {
    setShowErrorMessage(false);
  };

  const ErrorMessageDisplay = () =>
    showErrorMessage && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-red-800 text-white p-6 rounded-lg shadow-lg z-50">
          <button
            className="btn btn-warning float-right"
            onClick={closeErrorMessage}
          >
            Close
          </button>
          <p>{errorMessage}</p>
        </div>
      </div>
    );

  const payload = {
    DataSetStudents: studentsLeadIdList,
    CommunicationName: dialogueCommunicationName,
    TemplateId: selectTemplateId,
    EmailSubject: mailSubject,
    FromEmail: fromMail,
    AppUserId: appUserId,
    NumberOfStudents: totalSelected,
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    setSelectedRowCount(selectedRows.length);
  }, [selectedRows]);

  useEffect(() => {
    setTotalSelected(selectedRows.length);
  }, [selectedRows]);

  const fetchTemplates = async () => {
    try {
      const response = await Agent.Communication.getEmailTemplates();
      setTemplates(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', pageSize.toString());
    //params.append('id', filter ? id.trim() : '');
    params.append('name', filter ? Name.trim() : '');

    if (filter) {
      params.append('email', email);
    }
    if (filter) {
      params.append('citizenship', citizenship);
    }
    if (filter) {
      params.append('DataSetGroupId', dataSetGroupFilter);
    }
    if (filter) {
      if (startDateFilter) {
        params.append('startDate', new Date(startDateFilter).toDateString());
      }
    } else {
      params.append('startDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (endDateFilter) {
        params.append('EndDate', new Date(endDateFilter).toDateString());
      }
    } else {
      params.append('EndDate', new Date().toDateString());
    } 
    return params;
  };
  
  const getParamsApp = (filter) => {
    const params = new URLSearchParams();
    console.log('StudyLevelId',studyLevelFilter);
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', pageSize.toString());
    if (filter) {
      params.append('UniversityId', parseInt(university));
    }
    if (filter) {
      params.append('Intake', parseInt(intakeMonth));
    }
    if (filter) {
      params.append('Year', intakeYear);
    }
    if (filter) {
      params.append('StudyLevelId', parseInt(studyLevelFilter));
    }
    if (filter) {
      params.append('Citizenship', citizenship);
    }  
    if (filter) {
      if (startDateFilter) {
        params.append('startDate', new Date(startDateFilter).toDateString());
      }
    } else {
      params.append('startDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (endDateFilter) {
        params.append('endDate', new Date(endDateFilter).toDateString());
      }
    } else {
      params.append('endDate', new Date().toDateString());
    } 
    return params;
  };

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  useEffect(() => {
    Agent.Communication.getFromEmails().then(setFromMailList);
  }, []);

  const fetchData = async (filter = false) => {
    setLoading(true);
    try {
      const params = getParams(filter);
      console.log('params',params);
      const dataset = await Agent.Communication.listStudentOld(params);
      console.log('dataset', dataset);
      const { pagination, data } = dataset;
      setTotalPages(pagination.totalPages);
      setTotalRows(pagination.totalItems); // Reflects the count of filtered data
      setDataLoaded(true);
      setStudentsLead(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchDataApp = async (filter = false) => {
    setLoading(true);
    try {
      const params = getParamsApp(filter);
      console.log('params',params);
      const dataset = await Agent.Communication.listStudentApp(params);
      console.log('dataset App', dataset);
      const { pagination, data } = dataset;
      setTotalPages(pagination.totalPages);
      setTotalRows(pagination.totalItems); // Reflects the count of filtered data
      setDataLoaded(true);
      setDataSetApplications(data);
      //getRows(dataset);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(true); // Initial fetch with filters
  }, [pageNumber, pageSize, Name, email, citizenship, dataSetGroupFilter]); // Refetch data when pageNumber or pageSize or any filter status changes

  const handleSelectTemplateIdChange = (event) => {
    setSelectTemplateId(event.target.value);
  };

  const handleCommunicationNameChange = (event) => {
    setDialogueCommunicationName(event.target.value);
  };

  const handleMailSubjectChange = (event) => {
    setMailSubject(event.target.value);
  };
  const handleFromMailChange = (event) => {
    setFromEmail(event.target.value);
  };
  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    // If Select All is checked, select all students on the current page
    if (!selectAll) {
      const selectedIds = studentsLead.map((studentLead) => ({
        Id: studentLead.id,
      }));
      setStudentsLeadIdList(selectedIds);
      setSelectedRows(selectedIds.map((student) => student.Id));
    } else {
      setStudentsLeadIdList([]);
      setSelectedRows([]);
    }
  };
  const handleSelectAllApp = () => {
    setSelectAll(!selectAll);

    // If Select All is checked, select all students on the current page
    if (!selectAll) {
      const selectedIds = dataSetApplications.map((studentLead) => ({
        Id: studentLead.id,
      }));
      setStudentsLeadIdList(selectedIds);
      setSelectedRows(selectedIds.map((student) => student.Id));
    } else {
      setStudentsLeadIdList([]);
      setSelectedRows([]);
    }
  };

  const renderCheckbox = (params) => (
    <Checkbox
      checked={selectedRows.includes(params.row.id) || selectAll}
      onChange={(event) => handleRowSelection(event, params.row.id)}
    />
  );
  const renderCheckboxApp = (params) => (
    <Checkbox
      checked={selectedRows.includes(params.row.id) || selectAll}
      onChange={(event) => handleRowSelectionApp(event, params.row.id)}
    />
  );

  let studentsLeadColumns = [
    {
      field: 'checkbox',
      headerName: 'Select',
      width: 100,
      renderCell: renderCheckbox,
      headerAlign: 'center',
      renderHeader: () => (
        <Checkbox
          checked={selectAll}
          onChange={handleSelectAll}
          indeterminate={
            selectedRows.length > 0 && selectedRows.length < studentsLead.length
          }
        />
      ),
    },
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      renderCell: (params) => (
        <MyTooltip title={params.row.firstName + ' '+params.row.lastName} color="primary">
          <Typography className="clickable">{params.row.firstName + ' '+params.row.lastName}</Typography>
        </MyTooltip>
      ),
    },
    {
      field: 'email',
      headerName: 'To Email',
      width: 300,
      renderCell: (params) => <Typography>{params.row.email}</Typography>,
    },
    {
      field: 'citizenship',
      headerName: 'Citizenship',
      width: 250,
      valueGetter: (params) => {
        const citizenship = params.row.citizenship;
        const country = Countries.find(
          (citi) => citi.id == citizenship
        );
        return country ? country.name : '';
      },
    },
    {
      field: 'groupName',
      headerName: 'Dataset Group',
      width: 250,
      renderCell: (params) => <Typography>{params.row.groupName}</Typography>,
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      width: 120,
      hide: false,
      valueGetter: (params) => {
        const createdOn = params.row.createdOn;
        return createdOn? createdOn.split('T')[0]:'';
      },
    },
  ];

  let studentsLeadColumnsApp = [
    {
      field: 'checkbox',
      headerName: 'Select',
      width: 100,
      renderCell: renderCheckboxApp,
      headerAlign: 'center',
      renderHeader: () => (
        <Checkbox
          checked={selectAll}
          onChange={handleSelectAllApp}
          indeterminate={
            selectedRows.length > 0 && selectedRows.length < dataSetApplications.length
          }
        />
      ),
    },
    // {
    //   field: 'id',
    //   headerName: 'Id',
    //   width: 80,
    //   hide:true,
    // },
    {
      field: 'name',
      headerName: 'Name',
      width: 250,
      renderCell: (params) => (
        <MyTooltip title={params.row.firstName+' '+params.row.firstName} color="primary">
          <Typography className="clickable">{params.row.lastName+' '+params.row.lastName}</Typography>
        </MyTooltip>
      ),
    },
    {
      field: 'email',
      headerName: 'To Email',
      width: 300,
    },
    
    {
      field: 'universityName',
      headerName: 'University',
      width: 250,
    },

    {
      field: 'courseName',
      headerName: 'Course',
      width: 250,
      hide:true,
    },
    {
      field: 'citizenship',
      headerName: 'Citizenship',
      width: 170,
      valueGetter: (params) => {
        const citizenship = params.row.citizenship;
        const citizenshipName = Countries.find(
          (country) => country.id == citizenship
        );
        return citizenshipName ? citizenshipName.name : null;
      },
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      width: 120,
      hide: true,
      valueGetter: (params) => {
        const createdOn = params.row.createdOn;
        return createdOn? createdOn.split('T')[0]:'';
      },
    },
    {
      field: 'intake',
      headerName: 'Intake Month',
      width: 150,
      valueGetter: (params) => {
        const intake = params.row.intake;
        const intakeMonth = INTAKES.find(
          (inta) => inta.id == intake
        );
        return intakeMonth ? intakeMonth.type : null;
      },
    },
    {
      field: 'year',
      headerName: 'Intake Year',
      width: 150,

    },
    {
      field: 'studyLevelId',
      headerName: 'Study Level',
      width: 170,
      valueGetter: (params) => {
            const studyLevelId = params.row.studyLevelId;
            const studyLevel = STUDY_LEVELS.find(
              (stu) => stu.id == studyLevelId
            );
            return studyLevel ? studyLevel.type : null;
          },
    },
    
  ];

  const handleCitizenshipFilterChange = (event, value) => {
    setCitizenshipFilter(value ? value.id : 0);
  };
  const handleUniversityChange = (event, value) => {
    setUniversity(value ? value.id : 0);
  };
  const handleCourseChange = (event, value) => {
    setCourse(value ? value.id : 0);
  };
  const handleIntakeMonthChange = (event, value) => {
    setIntakeMonth(value ? value.id : 0);
  };
  const handleIntakeYearChange = (event, value) => {
    setIntakeYear(value ? value.year : 0);
  };
  const handleStudyLevelFilterChange = (event, value) => {
    setStudyLevelFilter(value ? value.id : 0);
  };
  const handleDatasetGroupFilterChange = (event, value) => {
    setDataSetGroupFilter(value ? value.id : 0);
    //console.log('DataSetGroupFilter',dataSetGroupFilter)
  };

  const handleNameChange = (event) => {
    const selectedValue = event.target.value;
    setLeadFirstName(selectedValue);
  };
  const handleEmailChange = (event) => {
    const selectedValue = event.target.value;
    setEmail(selectedValue);
  };

  const handleClearFilters = () => {
    setCitizenshipFilter(0);
    setStartDateFilter(addMonths(new Date(),-1).toISOString().split('T')[0]); 
    setEndDateFilter(new Date().toISOString().split('T')[0]); 
    setUniversity(0);
    setCourse(0);
    setIntakeMonth(0);
    setIntakeYear(0);
    setStudyLevelFilter(0);
    setDataSetGroupFilter(null);
    setLeadFirstName('');
    setLeadListName('');
    setEmail('');
    setSelectedRows([]);
    setStudentsLeadIdList([]);
    setShowData(false);
    setSelectAll(false);
    fetchData(false); // Refetch the data after clearing filters
  };

  const handleRowSelection = (event, rowId) => {
    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowId];
      const selectedStudensLeadtId = studentsLead.find(
        (studentLead) => studentLead.id === rowId
      ).id;
      handleAddStudentsLeadId(selectedStudensLeadtId);
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
    }

    setSelectedRows(newSelectedRows);
  };

  const handleRowSelectionApp = (event, rowId) => {
    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowId];
      const selectedDataSetAppId = dataSetApplications.find(
        (dataset) => dataset.id === rowId
      ).dataSetStudentId;
      handleAddStudentsLeadId(selectedDataSetAppId);
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
    }

    setSelectedRows(newSelectedRows);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDialogueCommunicationName('');
  };

  const ConfirmDialog = () => (
    <Dialog
      open={openConfirmDialog}
      onClose={handleCloseConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Confirm Sending Mail'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description ">
          Are you sure you want to send the mail?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="flex justify-around">
        <Button
          onClick={handleCloseConfirmDialog}
          color="primary"
          className="btn-primary"
        >
          No
        </Button>
        <Button
          onClick={handleSendMail}
          color="success"
          className="btn-success"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <div className="h-96 w-full">
      <div className="bg-white px-4 mb-4 shadow-sm">
        <LeadComFiltersOld
          citizenship={citizenship}
          university={university}
          course={course}
          intakeMonth={intakeMonth}
          intakeYear={intakeYear}
          studyLevelFilter={studyLevelFilter}
          Name={Name}
          email={email}
          handleCitizenshipFilterChange={handleCitizenshipFilterChange}
          handleUniversityChange={handleUniversityChange}
          handleCourseChange={handleCourseChange}
          handleIntakeMonthChange={handleIntakeMonthChange}
          handleIntakeYearChange={handleIntakeYearChange}
          handleStudyLevelFilterChange={handleStudyLevelFilterChange}
          handleNameChange={handleNameChange}
          handleClearFilters={handleClearFilters}
          handleApplyFilters={handleApplyFilters}
          handleSendBulkEmail={handleOpenDialog}
          handleEmailChange={handleEmailChange}
          handleApplyFiltersApp={handleApplyFiltersApp}
          totalSelected={totalSelected}
          dataSetGroup={dataSetGroup}
          dataSetGroupFilter={dataSetGroupFilter}
          handleDatasetGroupFilterChange={handleDatasetGroupFilterChange}
          handleStartDateFilter={handleStartDateFilter}
          handleEndDateFilter={handleEndDateFilter}
          setStartDateFilter={setStartDateFilter}
          setEndDateFilter={setEndDateFilter}
          startDateFilter={startDateFilter}
          endDateFilter={endDateFilter}
          handleDatasetChange={handleDatasetChange}
          datasetOption={datasetOption}
        />

        {showData && datasetOption==1 ? (
          // Show the DataGrid only when showData is true (filters are applied)
          dataLoaded ? ( <Grid item style={{ height: 660, width: '100%' }}>
            <DataGridPro
              rows={studentsLead}
              columns={studentsLeadColumns}
              totalPageSize={totalPages}
              loading={loading}
              pagination={true}
              paginationMode="server"
              pageSize={pageSize}
              pageSizeOptions={[800]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowCount={totalrows}
              onPageChange={(newPage) => setPageNumber(newPage + 1)}
              page={pageNumber - 1}
              components={{
                Toolbar: CustomToolbar,
                Pagination: GridPagination,
              }}
            /></Grid>
          ) : (
            <div>Loading data...</div>
          )
        ) : showDataApp && datasetOption==2 ? (
          // Show the DataGrid only when showData is true (filters are applied)
          dataLoaded ? ( <Grid item style={{ height: 660, width: '100%' }}>
            <DataGridPro
              rows={dataSetApplications}
              columns={studentsLeadColumnsApp}
              totalPageSize={totalPages}
              loading={loading}
              pagination={true}
              paginationMode="server"
              pageSize={pageSize}
              pageSizeOptions={[800]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowCount={totalrows}
              onPageChange={(newPage) => setPageNumber(newPage + 1)}
              page={pageNumber - 1}
              components={{
                Toolbar: CustomToolbar,
                Pagination: GridPagination,
              }}
            /></Grid>
          ) : (
            <div>Loading data...</div>
          )
        ) : (
          datasetOption != 0 && <div>No data to display. Please apply filters to show data.</div>
        )}

        <BulkSummaryDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          totalSelected={totalSelected}
          templates={templates}
          fromMailList={fromMailList}
          dialogueCommunicationName={dialogueCommunicationName}
          setDialogueCommunicationName={setDialogueCommunicationName}
          selectTemplateId={selectTemplateId}
          setSelectTemplateId={setSelectTemplateId}
          mailSubject={mailSubject}
          setMailSubject={setMailSubject}
          fromMail={fromMail}
          setFromMail={setFromEmail}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          handleSelectTemplateIdChange={handleSelectTemplateIdChange}
          handleFromMailChange={handleFromMailChange}
          handleMailSubjectChange={handleMailSubjectChange}
          handleCommunicationNameChange={handleCommunicationNameChange}
        />

        {successMessage && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-blue-500 text-black p-4 rounded shadow-lg">
              <p>{successMessage}</p>
              <button
                className="btn btn-warning mt-4"
                onClick={() => setSuccessMessage('')}
              >
                Close
              </button>
            </div>
          </div>
        )}

        <ErrorMessageDisplay />
        <ConfirmDialog />

        {showCountdownLoader && (
          <CountdownLoader
            totalSelected={totalSelected}
            onCountdownComplete={handleCountdownComplete}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(StudentsLeadBulkEmailOld);
