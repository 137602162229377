import React, { useEffect, useState } from "react";
import { Tab, Tabs, Box } from "@mui/material";
import LoadingView from "../Common/Loading/LoadingView";
import StudentReport from "./StudentReport/StudentReport";

// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import Agent from '../../api/agent';
// import { getAgentData, getBranchData } from './applicationFilters';
// import { TbBorderRadius } from 'react-icons/tb';;
import Application from "../Applications/Application";
// import ApplicationReport from './ApplicationReport2';
import ApplicationReport from "./ApplicationReport/ApplicationReport";
import AppCap from "./AppCap/AppCap";
import SalesReport from "./SalesReport/SalesReport";
import PendingReport from "./PendingReport/PendingReport";
import B2B from "./B2B/B2B";
import AutomatedReports from "./AutomatedReports/AutomatedReports";

const Reports = ({ manageAutomatedReport }) => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);

  const handleTabChange = (e, newVal) => {
    setTab(newVal);
  };

  return (
    <>
      {loading ? (
        <div style={{ marginTop: "0px", fontWeight: "lighter" }}>
          <LoadingView />
        </div>
      ) : (
        <div>
          {/* <h3 style={{ fontWeight: 'bolder',marginTop:'-1%'}}>Reports and Analytics </h3> */}
          <Box>
            <Box>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#0081C9",
                    height: "5px",
                    borderRadius: "50px",
                  },
                }}
              >
                {tabItems.map((item, index) => {
                  return (
                    <Tab
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1em",
                      }}
                      label={item}
                      key={index}
                    />
                  );
                })}
                {manageAutomatedReport && (
                  <Tab
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1em",
                    }}
                    label={"Automated Reports"}
                    key={4}
                  />
                )}
              </Tabs>

              <Box>
                <TabPanel value={tab} index={0}>
                  <StudentReport />
                </TabPanel>
                <TabPanel value={tab} index={1}>
                  <ApplicationReport />
                </TabPanel>
                <TabPanel value={tab} index={2}>
                  <SalesReport />
                </TabPanel>
                <TabPanel value={tab} index={3}>
                  <PendingReport />
                </TabPanel>
                {manageAutomatedReport && (
                  <TabPanel value={tab} index={4}>
                    <AutomatedReports />
                  </TabPanel>
                )}
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};
export default Reports;

const TabPanel = (props) => {
  const { children, value, index } = props;

  return <div>{value === index && <Box>{children}</Box>}</div>;
};

const tabItems = [
  "Student Report",
  "Application Report",
  "Sales Report",
  "Pending Report",
  // 'Comparison',
];
