import React from 'react';
import { Grid, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const MyTabLabel = ({ label,icon, showWarning = false }) => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
      <div className="flex text-lg font-semibold items-start">{icon }&nbsp;&nbsp;{label}</div>
      </Grid>

      <Grid
        item
        xs={3}
        container
        style={{ visibility: showWarning ? null : 'hidden' }}
      >
        <Grid item>
          <ErrorIcon color="error" fontSize="small" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MyTabLabel;
