import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from '@mui/material';
import { connect } from 'react-redux';
import Agent from '../../../../api/agent';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import LinearIndeterminate from '../../../Common/Progress/LinearIndeterminate';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { IconButton } from '@mui/material';
import { ButtonGroup } from '@mui/material';
import PhotoWidgetDropzone from '../../../Common/ImageUpload/PhotoWidgetDropzone';

const PREFIX = 'Add';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const AddManual = ({
  agentId,
  setRecordAddMode,
  getAgreementsParent,
  sender,
}) => {
  const [open, setOpen] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [file, setFile] = useState();
  const [error, setError] = useState();

  function handleClose() {
    setOpen(false);
    setRecordAddMode(false);
  }

  const handleFormCancel = () => {
    setOpen(false);
    setRecordAddMode(false);
  };

  const uploadToCloud = () => {
    Agent.Agents.addManualAgreement(file, agentId, sender)
      .then(() => {
        getAgreementsParent();
        setUpdating(false);
        setFile(null);
        handleFormCancel();
      })
      .catch((error) => {
        console.log(error);
        setUpdating(false);
        setError(
          'Error: Failed to upload. Check file type/size etc and try again!'
        );
      });
  };

  const upload = (file) => {
    const harmful = ['exe', 'pif', 'bat'];
    setFile(file);
    let fileZie = file.size / 1024 / 2024;
    if (fileZie > 2) {
      setError('Error: File size cannot be more than 2 MB');
      return;
    }
    const extension = file.name.split('.').pop();
    if (harmful.includes(extension.toLowerCase())) {
      setError(
        'Error: Unsupported file type. You can only upload a valid document file type'
      );
      return;
    }
    setError(null);
  };

  return (
    <StyledDialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      aria-labelledby="draggable-dialog-title"
      PaperComponent={PaperComponent}
      maxWidth="md"
    >
      <>
        <DialogTitle
          style={{ cursor: 'move', minWidth: 600 }}
          id="draggable-dialog-title"
        >
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h5" color="primary" align="center">
                Upload Signed Agreement
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleFormCancel}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider />
        </DialogTitle>
        <DialogContent style={{ overflowY: 'hidden' }}>
          <Grid container direction="column" spacing={3}>
            {updating && (
              <Grid item>
                <LinearIndeterminate />
              </Grid>
            )}
            <Grid item container spacing={3}>
              <Grid item xs={6}>
                <PhotoWidgetDropzone
                  crop={false}
                  upload={upload}
                  message="Drop your document here"
                />
              </Grid>
              {file && (
                <Grid item xs={6} container direction="column" spacing={4}>
                  <Grid item container direction="column">
                    {error && (
                      <Grid
                        item
                        style={{
                          color: 'red',
                          marginBottom: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        {error}
                      </Grid>
                    )}
                    <Grid item>
                      <Typography>{`File name: ${file.name}`}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{`File Size ${
                        file.size / 1024
                      } KiB`}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <ButtonGroup
                      orientation="vertical"
                      size="large"
                      color="primary"
                      aria-label="large outlined primary button group"
                    >
                      <Button
                        onClick={() => {
                          setFile(null);
                        }}
                      >
                        CHOOSE ANOTHER
                      </Button>
                      <Button
                        disabled={error}
                        variant="contained"
                        className='bg-sky-400 hover:bg-sky-500'
                        onClick={uploadToCloud}
                      >
                        SAVE
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          {updating && (
            <Grid item style={{ marginBottom: 20 }}>
              <LinearIndeterminate />
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button onClick={() => handleFormCancel()}>Cancel</Button>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    </StyledDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    sender: state.auth.name,
  };
};

export default connect(mapStateToProps, null)(AddManual);
