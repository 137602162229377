import React, {useState} from 'react'
import {styled} from '@mui/material/styles'
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Draggable from 'react-draggable'
import {Typography} from '@mui/material'
import {Divider} from '@mui/material'
import {IconButton} from '@mui/material'
import LinearIndeterminate from '../../Common/Progress/LinearIndeterminate'
import Agent from '../../../api/agent'
import {LoadingButton} from '@mui/lab'
import DatePicker, {registerLocale} from 'react-datepicker'
import enGB from 'date-fns/locale/en-GB' // the locale you want
registerLocale('en-GB', enGB) // register it with the name you want
import 'react-datepicker/dist/react-datepicker.css'
import {addHours, startOfDay} from 'date-fns'
const PREFIX = 'Commission'

const classes = {
  closeButton: `${PREFIX}-closeButton`,
  reactDatepickerPopper: `${PREFIX}-reactDatepickerPopper`,
}

const StyledDialog = styled(Dialog)(({theme}) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  [`& .${classes.reactDatepickerPopper}`]: {
    zIndex: 3,
  },
}))

function PaperComponent(props) {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const Commission = ({commissionParent, setAddMode, updateParent}) => {
  const [open, setOpen] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [commission, setCommission] = useState(commissionParent)
  function handleClose() {
    setOpen(false)
    setAddMode(false)
  }

  const handleFormCancel = () => {
    setOpen(false)
    setAddMode(false)
  }

  const handleChange = (e) => {
    setCommission({...commission, [e.target.name]: e.target.value})
  }

  const save = () => {
    setUpdating(true)
    Agent.Agents.saveCommission(commission)
      .then(() => {
        setUpdating(false)
        updateParent()
        handleFormCancel()
      })
      .catch((error) => {
        setUpdating(false)
        console.log(error)
        debugger
      })
  }

  return (
    <StyledDialog
      fullWidth
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }}
      aria-labelledby='draggable-dialog-title'
      PaperComponent={PaperComponent}
      maxWidth='sm'
    >
      <>
        <DialogTitle style={{cursor: 'move'}} id='draggable-dialog-title'>
          <Grid container>
            <Grid item xs={11}>
              <Typography
                className='bg-sky-400 hover:bg-sky-500'
                variant='contained'
                align='center'
              >
                {commission.id > 0 ? 'Save Commission' : 'Add Commission'}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label='close'
                className={classes.closeButton}
                onClick={handleFormCancel}
                size='large'
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider />
        </DialogTitle>
        <DialogContent style={{overflowY: 'hidden'}}>
          <Grid container direction='column' spacing={2}>
            {updating && (
              <Grid item>
                <LinearIndeterminate />
              </Grid>
            )}
            <Grid item container direction='column' spacing={1}>
              <Grid item style={{marginTop: 10}}>
                <TextField
                  fullWidth
                  label='Intake'
                  name='intake'
                  value={commission.intake}
                  onChange={handleChange}
                  variant='outlined'
                  size='small'
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label='Student Enrolled'
                  name='studentEnrolled'
                  value={commission.studentEnrolled}
                  onChange={handleChange}
                  variant='outlined'
                  size='small'
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label='Commission Value'
                  name='commissionValue'
                  value={commission.commissionValue}
                  onChange={handleChange}
                  variant='outlined'
                  size='small'
                />
              </Grid>
              <Grid item>
                <DatePicker
                  popperClassName={classes.reactDatepickerPopper}
                  autoComplete='off'
                  locale='enGB'
                  dateFormat='dd-MM-yyyy'
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  error={true}
                  selected={
                    (commission.dueDate && new Date(commission.dueDate)) || null
                  }
                  onChange={(newValue) => {
                    if (newValue) {
                      newValue = addHours(startOfDay(newValue), 12)
                    }
                    setCommission({...commission, dueDate: newValue})
                  }}
                  customInput={
                    <TextField
                      label='Due Date'
                      fullWidth
                      value={commission.dueDate}
                      variant='outlined'
                      size='small'
                    />
                  }
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label='Other Student'
                  name='otherStudent'
                  value={commission.otherStudent}
                  onChange={handleChange}
                  variant='outlined'
                  size='small'
                />
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  label='Expected Commission'
                  name='expectedCommission'
                  value={commission.expectedCommission}
                  onChange={handleChange}
                  variant='outlined'
                  size='small'
                />
              </Grid>
            </Grid>
          </Grid>

          {updating && (
            <Grid item style={{marginBottom: 20}}>
              <LinearIndeterminate />
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justifyContent='space-between'
            style={{marginTop: 90}}
          >
            <Grid item>
              <Button onClick={() => handleFormCancel()}>Cancel</Button>
            </Grid>
            <Grid item>
              <LoadingButton
                loading={updating}
                type='submit'
                variant='contained'
                className='bg-sky-400 hover:bg-sky-500'
                onClick={save}
              >
                {commission.id > 0 ? 'Save' : 'Add'}
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    </StyledDialog>
  )
}

export default Commission
