import React from 'react';
import { MdPerson, MdDescription, MdFileUpload } from 'react-icons/md';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Email } from '@mui/icons-material';

function ContactSideNav({ activeComponent, setActiveComponent }) {
  const ButtonItem = ({ label, value, icon }) => {
    const isActive = activeComponent === value;

    return (
      <button
        className={`flex items-center px-4 py-2 rounded-lg w-full text-left transition-all duration-300 transform hover:scale-105 focus:outline-none ${
          isActive
            ? 'bg-blue-600 text-white shadow-md'
            : 'bg-white text-gray-700 hover:bg-gray-100 hover:text-gray-900'
        }`}
        onClick={() => setActiveComponent(value)}
      >
        {icon}
        <span className="ml-2 text-lg">{label}</span>
      </button>
    );
  };

  return (
    <div className="w-full md:w-auto pr-0 md:pr-auto mb-4 md:mb-0">
      <ul className="space-y-6 list-none">
        <li>
          <ButtonItem
            label="General"
            value="basicinfo"
            icon={<MdPerson className="text-lg" />}
          />
        </li>

        <li>
          <ButtonItem
            label="Documents"
            value="documents"
            icon={<MdFileUpload className="text-lg" />}
          />
        </li>
        <li>
          <ButtonItem
            label="Email"
            value="email"
            icon={<Email className="text-lg" />}
          />
        </li>
        <li>
          <ButtonItem
            label="Notes"
            value="notes"
            icon={<MdDescription className="text-lg" />}
          />
        </li>
      </ul>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ContactSideNav);
