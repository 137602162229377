import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import LoadingView from '../../Common/Loading/LoadingView';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { User_Activity_Type } from '../../../Util/Constants';
import Agent from '../../../api/agent';
import { addMilliseconds, format } from 'date-fns';
function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {show && <GridToolbarColumnsButton />}
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const DetailModal = ({ open, onClose, detailData }) => {
  const renderDetail = (data, indent = 0) => {
    if (typeof data !== 'object' || data === null) {
      // Splitting the stringified data into lines and mapping them to JSX elements
      return JSON.stringify(data, null, 2)
        .split('\n')
        .map((line, index) => (
          <div key={index} style={{ marginLeft: `${indent * 20}px` }}>
            {line}
          </div>
        ));
    }

    return Object.entries(data).map(([key, value]) => (
      <div key={key} style={{ marginLeft: `${indent * 20}px` }}>
        <strong>{key}:</strong>
        {renderDetail(value, indent + 1)}
      </div>
    ));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className="text-center text-xl">
        Activity Details
      </DialogTitle>
      <Button
        onClick={onClose}
        className="flex align-right"
        style={{ position: 'absolute', right: 0, top: 0 }}
      >
        Close
      </Button>
      <DialogContent
        dividers={true}
        style={{ maxHeight: '70vh', overflowY: 'auto' }}
      >
        <div className="p-2 text-center">{renderDetail(detailData)}</div>
      </DialogContent>
    </Dialog>
  );
};

const StudentActivites = ({ users, isInternal, studentId, loadUsers }) => {
  const [activityList, setActivityList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailData, setDetailData] = useState({});

  useEffect(() => {
    setLoading(true);
    Agent.UserActivity.getSingleStudentActivity(studentId)
      .then((res) => {
       // console.log(res);
        const formattedData = res.map((activity) => ({
          id: activity.id,
          userActivityType: activity.userActivityType,
          recordId: activity.recordId,
          appUserDetailsId: activity.appUserDetailsId,
          name: activity.name,
          activity: activity.activity,
          adminName:activity.adminName,
          activityDate: activity.activityDate,
          logIn: activity.logIn,
          logOut: activity.logOut,
        }));
        setActivityList(formattedData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [studentId]);

  useEffect(() => {
    if (isInternal) {
      if (users.length == 0) {
        loadUsers().catch((error) => {
          console.log(error);
        });
      }
    }
  }, [users]);

  const handleShowDetails = (activityLink) => {
    setDetailData(activityLink);
    setModalOpen(true);
  };
  let columns = [
    {
      field: 'userActivityType',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      renderHeader: () => <strong>{'Activity Type'}</strong>,
      valueGetter: (params) => {
        const userActivityType = params.row.userActivityType;
        const user = User_Activity_Type.find(
          (user) => user.id == userActivityType
        );
        return user ? user.type : '';
      },
    },
 

    {
      field: 'name',
      width: 150,
      headerAlign: 'center',
      align: 'left',
      renderHeader: () => <strong>{'Action Name'}</strong>,
    },
   

    {
      field: 'activityDate',
      renderHeader: () => <strong>{'Activity Date'}</strong>,
      align: 'center',
      headerAlign: 'center',
      width: 250,
      renderCell: (params) => {
        const activityDate = new Date(params.row.activityDate);
        if (!activityDate.getTime() || activityDate.getFullYear() === 1) {
          return <Typography></Typography>; // Render empty for null or default dates
        }
        return (
          <Typography>
            {format(
              addMilliseconds(activityDate, -new Date().getTimezoneOffset()),
              'MMMM d, yyyy h:mm a'
            )}
          </Typography>
        );
      },
    },

    {
      field: 'adminName',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>{'Admin Name'}</strong>,
    },


    {
      field: 'activity',
      headerName: 'Activity',
      renderHeader: () => <strong>{'Activity Details'}</strong>,
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Button
          variant="contained"
          className="bg-sky-700"
          onClick={() => handleShowDetails(params.row.activity)}
        >
          Show Details
        </Button>
      ),
    },
  ];

  return (
    <div>
      {loading ? (
        <LoadingView />
      ) : (
        <Grid container direction="column">
          <Grid
            item
            style={{
              height: 800,
              width: '95%',
              marginLeft: 10,
              marginTop: 10,
              borderRadius: 20,
              overflow: 'hidden',
            }}
          >
            <DataGridPro
              paginationMode="client"
              pagination
              rows={activityList}
              columns={columns}
              loading={loading}
              density="compact"
              rowHeight={80}
              components={{
                Toolbar: () => CustomToolbar(isInternal),
              }}
            />
          </Grid>
        </Grid>
      )}

      <DetailModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        detailData={detailData}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentActivites);
