import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  FormControl,
  Container,
  Autocomplete,
  // FormHelperText,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';

import { Countries } from '../../../Util/Util';
import { Done, ErrorOutline } from '@mui/icons-material';
import * as yup from 'yup';
import Agent from '../../../api/agent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import PhoneInput from 'react-phone-number-input';
import { useLocation } from 'react-router-dom';
import backgroundImage from '../../../assets/images/web-login_expo.jpg';
// import backgroundImage from '../../../assets/images/CRM-Cover-UK-june-event.jpg';
import logoImage from './../../../assets/images/web-login_expo_mobile.jpg';

import {
  EDUCATION_LEVELS,
  GENDER,
  HaveIELTS,
  STUDY_LEVELS,
} from '../../../Util/Constants';

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'minimum character 2')
    .max(100, 'maximum character 100')
    .required('First Name is required'),
  lastName: yup
    .string()
    .min(2, 'minimum character 2')
    .max(100, 'minimum character 100')
    .required('Last Name is required'),
  email: yup.string().required('Email address is required'),
  PhoneNumber: yup.string().required('Phone Number is required'),
  cgpa: yup.string().required('your cgpa is required'),
  gender: yup.string().required('gender is required'),
  studyLevelFilter: yup.string().required('Study level is required'),
  prefferedDepartment: yup
    .array()
    .min(1, 'Select at least one preferred field of study')
    .required('Preferred field of study is required'),
});

const PublicLeadForm = () => {
  const [studyLevelFilter, setStudyLevelFilter] = useState('');
  const [lastEducation, setLastEducation] = useState('');
  const [deptList, setDepartmentList] = useState([]);
  const [gender, setGender] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [PhoneNumber, setPhoneNo] = useState('');
  const [cgpa, setCGPA] = useState('');
  const [preferredUniversity, setPreferredUniversity] = useState('');
  const [passingYear, setPassingYear] = useState('');
  const [passport, setPassport] = useState(false);
  const [havingIELTS, setHavingIELTS] = useState('');
  const [preferredDestinations, setPreferredDestinations] = useState([]);
  const [prefferedDepartment, setPrefferredDepartment] = useState([]);
  const [formResetKey, setFormResetKey] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    PhoneNumber: '',
    cgpa: '',
    studyLevelFilter: '',
    gender: '',
  });

  const [phoneError, setPhoneError] = useState('');
  const [GuId, setEventGuid] = useState('');
  const { search } = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(search);
    const event = params.get('event');
    setEventGuid(event || '');
  }, [search]);

  useEffect(() => {
    Agent.Courses.listDepartments()
      .then((res) => {
        setDepartmentList(res);
      })
      .catch((err) => {
        console.log('dept err', err);
      });
  }, []);

  const handleGender = (event) => {
    setGender(event.target.value);
    setFieldErrors({ ...fieldErrors, gender: '' });
  };

  const handleStudyLevelFilterChange = (event) => {
    setStudyLevelFilter(event.target.value);
    setFieldErrors({ ...fieldErrors, studyLevelFilter: '' });
  };

  const handleLastEducation = (event) => {
    setLastEducation(event.target.value);
    setFieldErrors({ ...fieldErrors, lastEducation: '' });
  };

  const handlePassport = (event) => {
    setPassport(event.target.value);
    setFieldErrors({ ...fieldErrors, passport: '' });
  };

  const handleHavingIELTS = (event) => {
    setHavingIELTS(event.target.value);
    setFieldErrors({ ...fieldErrors, havingIELTS: '' });
  };

  const handlePrefferedDepartments = (event, newValue) => {
    setPrefferredDepartment(newValue);
    setFieldErrors({ ...fieldErrors, prefferedDepartment: '' });
  };

  const handleSaveLead = async () => {
    try {
      await validationSchema.validate(
      
        {
          firstName,
          lastName,
          email,
          PhoneNumber,
          prefferedDepartment,
          gender,
          cgpa,
          studyLevelFilter,
        },
        { abortEarly: false }
      );

      const payload = {
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        PhoneNumber: PhoneNumber,
        Gender: gender,
        PassingPercentageOrCGPA: cgpa,
        PassingYear: passingYear,
        LastEducation: lastEducation,
        PreferenceStudyLevelId: studyLevelFilter,
        IELTS: havingIELTS,
        IsPassport: passport,
        PreferredUniversities: preferredUniversity,
        PreferredDepartments: prefferedDepartment.map((dept) => dept.id),
        PreferredDestinations: preferredDestinations.map(
          (destination) => destination.id
        ),
        GuId,
      };
      const response = await Agent.StudentLeads.savePulicLeadFormData(payload);
      console.log(response);
      setSuccessMessage(
        'Successfully Registered, Thank You! Registered Id:' + response
      );
      setSuccessOpen(true);
      clearForm();
    } catch (error) {
      console.error(error);
      const errors = {};
      error?.inner?.forEach((e) => {
        errors[e.path] = e.message;
      });
      setFieldErrors(errors);
      setErrorMessage('Please check your input fields.' + error);
    }
  };
  const clearForm = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNo(''); 
    setPhoneError(''); // Clear phone number error state
    setPassport(false);
    setHavingIELTS('');
    setGender('');
    setCGPA('');
    setPassingYear('');
    setStudyLevelFilter('');
    setLastEducation('');
    setPrefferredDepartment([]);
    setPreferredDestinations([]);
    setPreferredUniversity('');
    setFormResetKey((prevKey) => prevKey + 1);
    // Clear phone number field error
    setFieldErrors({ ...fieldErrors, PhoneNumber: '' });
  };
  

  const handleLeadFirstName = (event) => {
    setFirstName(event.target.value);
    setFieldErrors({ ...fieldErrors, firstName: '' });
  };

  const handleLeadLastName = (event) => {
    setLastName(event.target.value);
    setFieldErrors({ ...fieldErrors, lastName: '' });
  };

  const handleLeadEmail = (event) => {
    setEmail(event.target.value);
    setFieldErrors({ ...fieldErrors, email: '' });
  };

  const handlePhoneChange = (value, country) => {
    if (value) {
      if (/^0/.test(value)) {
        setPhoneError('Please select your country code');
        setPhoneNo('');
        setFieldErrors({
          ...fieldErrors,
          PhoneNumber: 'Please select your country code',
        });
      } else if (/^\+[1-9]\d{1,14}$/.test(value)) {
        setPhoneNo(value);
        setPhoneError('');
        setFieldErrors({ ...fieldErrors, PhoneNumber: '' });
      } else {
        setPhoneNo(null);
        setPhoneError(
          'Invalid phone number format. Include country code (+) and digits.'
        );
        setFieldErrors({
          ...fieldErrors,
          PhoneNumber: 'Invalid phone number format',
        });
      }
    } else {
      setPhoneNo('');
      setPhoneError('');
      setFieldErrors({ ...fieldErrors, PhoneNumber: '' });
    }
  };

  const handlePreferredDestination = (event, newValue) => {
    setPreferredDestinations(newValue);
    fieldErrors.preferredDestinations = '';
  };

  const handleCgpa = (event) => {
    setCGPA(event.target.value);
    setFieldErrors({ ...fieldErrors, cgpa: '' });
  };

  const successDialogStyles = {
    backgroundColor: '#4caf50',
  };

  const errorDialogStyles = {
    backgroundColor: '#f44336',
  };

  return (
    <div
      className="min-h-screen flex justify-end items-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',

      }}
    >
    <Container className="w-full max-w-xl p-2 sm:m-2 laptop14:p-6 laptop15:p-6 xl:p-12 mt-0 bg-white rounded-lg shadow-lg lg:w-1/2 laptop14:m-6 laptop15:m-6 sm:ml-56 lg:ml-48 xl:ml-120 2xl:ml-150">
        <div className="relative px-0">
          <div>
            <div className="logo-container">
              <div className="heading-logo-container m-1 rounded-xl">
                <img src={logoImage} alt="Logo" className="logo" />
              </div>
              <div className="heading-text">
                <h2 className="text-xl lg:text-2xl font-bold text-gray-800 text-center mb-2 mt-0">
                  Education Expo Registration
                </h2>
                <h2 className="text-lg text-center text-underline mb-6 mt-0">
                  Please fill out the form below
                </h2>
              </div>
            </div>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                required
                error={!!fieldErrors.firstName}
                helperText={fieldErrors.firstName}
                value={firstName}
                onChange={handleLeadFirstName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                required
                error={!!fieldErrors.lastName}
                helperText={fieldErrors.lastName}
                value={lastName}
                onChange={handleLeadLastName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                label="Email"
                required
                variant="outlined"
                error={!!fieldErrors.email}
                helperText={fieldErrors.email}
                fullWidth
                value={email}
                onChange={handleLeadEmail}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                <PhoneInput
                  required
                  value={PhoneNumber}
                  onChange={handlePhoneChange}
                  // inputComponent={PhoneInput}
                  error={!!fieldErrors.PhoneNumber || !!phoneError}
                />
                {(!!fieldErrors.PhoneNumber || !!phoneError) && (
                  <FormHelperText error>
                    {fieldErrors.PhoneNumber
                      ? fieldErrors.PhoneNumber
                      : phoneError}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth required>
                {/* Use the imported PhoneInput component */}
                <PhoneInput
                  className="w-full px-3 py-4 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="phoneNumber"
                  placeholder="Enter phone no."
                  onChange={handlePhoneChange}
                  // onChange={(value) => {
                  //   setPhoneNo(value); // Assuming 'setPhoneNo' is the state setter for phone number
                  // }}
                />
                {fieldErrors.PhoneNumber && (
                  <span className="text-xs text-red-500">
                    This field requires a valid international phone number with
                    the country code.
                  </span>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  required
                  error={!!fieldErrors.gender}
                  helperText={fieldErrors.gender}
                  label="Gender"
                  value={gender}
                  onChange={handleGender}
                  variant="outlined"
                >
                  {GENDER.map((gender) => (
                    <MenuItem key={gender.id} value={gender.id}>
                      {gender.type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  error={!!fieldErrors.lastEducation}
                  helperText={fieldErrors.lastEducation}
                  label="Last Education"
                  name="last education"
                  variant="outlined"
                  value={lastEducation}
                  onChange={handleLastEducation}
                >
                  {EDUCATION_LEVELS.map((lastEducation) => (
                    <MenuItem key={lastEducation.id} value={lastEducation.id}>
                      {lastEducation.type}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Passing Year"
                placeholder="Your Passing year"
                variant="outlined"
                fullWidth
                value={passingYear}
                onChange={(e) => setPassingYear(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="CGPA"
                error={!!fieldErrors.cgpa}
                helperText={fieldErrors.cgpa}
                placeholder="Your passing percentage/CGPA"
                variant="outlined"
                fullWidth
                value={cgpa}
                onChange={handleCgpa}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Have Passport?</InputLabel>
                <Select
                  label="Have Passport?"
                  value={passport}
                  onChange={handlePassport}
                  variant="outlined"
                >
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  required
                  error={!!fieldErrors.studyLevelFilter}
                  helperText={fieldErrors.studyLevelFilter}
                  label="Preferred Study Level"
                  name="studyLevelFilter"
                  variant="outlined"
                  value={studyLevelFilter}
                  onChange={handleStudyLevelFilterChange}
                >
                  {STUDY_LEVELS.map((studyLevel) => (
                    <MenuItem key={studyLevel.id} value={studyLevel.id}>
                      {studyLevel.type}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Autocomplete
                key={formResetKey}
                multiple
                options={deptList}
                onChange={handlePrefferedDepartments}
                value={prefferedDepartment}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    required
                    label="Preferred Field of Study (Department)"
                    placeholder="Your Preferred Field of Study/Departments"
                    error={!!fieldErrors.prefferedDepartment}
                    helperText={fieldErrors.prefferedDepartment}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  label="Have IELTS"
                  name="haveIELTS"
                  variant="outlined"
                  value={havingIELTS}
                  onChange={handleHavingIELTS}
                >
                  {HaveIELTS.map((haveIELTS) => (
                    <MenuItem key={haveIELTS.id} value={haveIELTS.id}>
                      {haveIELTS.type}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Preferred University"
                placeholder="Write Your Preferred University"
                variant="outlined"
                fullWidth
                value={preferredUniversity}
                onChange={(e) => setPreferredUniversity(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                key={formResetKey}
                multiple
                options={Countries}
                onChange={handlePreferredDestination}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Preferred Destinations"
                    placeholder="Select Preferred Destinations"
                    error={!!fieldErrors.preferredDestinations}
                    helperText={
                      fieldErrors.preferredDestinations
                        ? 'Preferred Destination is required'
                        : ''
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className="flex justify-center">
              <Button
                variant="contained"
                onClick={handleSaveLead}
                className="min-w-full py-3 px-8 mt-4 font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                endIcon={<Done />}
                style={{ backgroundColor: '#44c7f4' }}
                disabled={!!fieldErrors.PhoneNumber || !!phoneError}
              >
                Submit
              </Button>
            </Grid>
            <Dialog
              open={successOpen}
              onClose={() => setSuccessOpen(false)}
              aria-labelledby="success-dialog-title"
              PaperProps={{
                className: successDialogStyles.backgroundColor,
              }}
            >
              <DialogTitle id="success-dialog-title">
                <Done color="success" fontSize="large" />
                Success
              </DialogTitle>
              <DialogContent>
                <DialogContentText>{successMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setSuccessOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={!!errorMessage}
              onClose={() => setErrorMessage('')}
              aria-labelledby="error-dialog-title"
              PaperProps={{
                className: errorDialogStyles.backgroundColor,
              }}
            >
              <DialogTitle id="error-dialog-title">
                <ErrorOutline color="error" fontSize="large" />
                Error
              </DialogTitle>
              <DialogContent>
                <DialogContentText>{errorMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setErrorMessage('')} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </div>
      </Container>

      <style>
        {`
          // CSS for mobile and tablet view
          .logo-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px; /* Adjust margin as needed */
          }
          
          .heading-logo-container {
            margin-bottom: 10px; /* Adjust margin as needed */
          }
          
          .heading-logo-container img {
            max-width: 100%;
            height: auto;
            border-radius: 5%; /* Make the image rounded */
            object-fit: fill;
          }
          
          /* Hide logo for views larger than mobile */
          @media (min-width: 768px ) {
            .logo {
              display: none;
            }
          }
        `}
      </style>
    </div>
  );
};

export default PublicLeadForm;
