import React, {useState, useContext, useEffect} from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import {Typography} from '@mui/material'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import CSSTransition from 'react-transition-group/CSSTransition'

import RegisterContext from '../../../../context/register-context'
import {FUNDINGS, STUDY_LEVEL_GROUPS} from '../../../../Util/Constants'
import styles from '../../StudentRegistration/CSS/registration.module.css'
const Step4Others = ({goNext}) => {
  const registerContext = useContext(RegisterContext)
  const [selectedLevel, setSelectedLevel] = useState(
    registerContext.preferenceStudyLevelId
  )
  const [selectedYear, setSelectedYear] = useState(registerContext.year)
  const [selectedFunding, setSelectedFunding] = useState(
    registerContext.funding
  )

  const currentYear = new Date().getFullYear()
  const years = [currentYear, currentYear + 1, currentYear + 2]

  const setLevel = (val) => {
    setSelectedLevel(val)
    registerContext.setRegisterContext({
      ...registerContext,
      ...{
        preferenceStudyLevelId: val,
      },
    })
  }
  const setYear = (val) => {
    setSelectedYear(val)
    registerContext.setRegisterContext({
      ...registerContext,
      ...{
        year: val,
      },
    })
  }
  const setFunding = (val) => {
    setSelectedFunding(val)
    registerContext.setRegisterContext({
      ...registerContext,
      ...{
        funding: val,
      },
    })
  }
  return (
    <>
    <div className="flex items-top justify-center min-h-screen min-w-screen">
      <div className="flex justify-center items-top ">
       
      <Grid >

          <Grid item>
            <Typography textAlign={'center'}>
              Which study level are you interested in?
            </Typography>
          </Grid>
          <Grid item>
            <TransitionGroup component='ul' className='step2-list'>
              {STUDY_LEVEL_GROUPS.map((item, index) => (
                <CSSTransition key={index} classNames='fade' timeout={300}>
                  <li className={styles['step-listItem']}>
                    {
                      <Button
                        size='large'
                        variant={
                          item.id == selectedLevel ? 'contained' : 'outlined'
                        }
                        fullWidth
                        className={
                          item.id == selectedLevel
                            ? 'bg-amber-400 hover:bg-amber-500'
                            : ''
                        }
                        onClick={() => setLevel(item.id)}
                      >
                        {item.type}
                      </Button>
                    }
                  </li>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </Grid>

        <Grid >
          <Grid
              textAlign={'center'}
          >
            <Typography>Which year do you want to study abroad?</Typography>
          </Grid>
          <Grid item>
            <TransitionGroup component='ul' className='step2-list'>
              {years.map((item, index) => (
                <CSSTransition key={index} classNames='fade' timeout={300}>
                  <li className={styles['step-listItem']}>
                    {
                      <Button
                        size='large'
                        variant={
                          item == selectedYear ? 'contained' : 'outlined'
                        }
                        fullWidth
                        onClick={() => setYear(item)}
                        className={
                          item == selectedYear
                            ? 'bg-amber-400 hover:bg-amber-500'
                            : ''
                        }
                      >
                        {item}
                      </Button>
                    }
                  </li>
                </CSSTransition>
              ))}
             
            </TransitionGroup>
          </Grid>
        </Grid>
        <Grid >
          <Grid item>
            <Typography textAlign={'center'}>Are you?</Typography>
          </Grid>
          <Grid item>
            <TransitionGroup component='ul' className='step2-list'>
              {FUNDINGS.map((item, index) => (
                <CSSTransition key={index} classNames='fade' timeout={300}>
                  <li className={styles['step-listItem']}>
                    {
                      <Button
                        size='large'
                        variant={
                          item.id == selectedFunding ? 'contained' : 'outlined'
                        }
                        fullWidth
                        onClick={() => setFunding(item.id)}
                        className={
                          item.id == selectedFunding
                            ? 'bg-amber-400 hover:bg-amber-500'
                            : ''
                        }
                      >
                        {item.type}
                      </Button>
                    }
                  </li>
                </CSSTransition>
              ))}
               <CSSTransition
                classNames='fade'
                timeout={300}
                style={{marginTop: 20}}
              >
                <li className={styles['step-listItem']}>
                  {
                    <Button
                      disabled={
                        !selectedLevel || !selectedYear || !selectedFunding
                      }
                      fullWidth
                      variant='contained'
                      className='bg-sky-400 hover:bg-sky-500'
                      onClick={goNext}
                    >
                      Next
                    </Button>
                  }
                </li>
              </CSSTransition>
            </TransitionGroup>
          </Grid>
        </Grid>
      </Grid>
      </div>
      </div>
    </>
  )
}

export default Step4Others
