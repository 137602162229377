/* eslint-disable import/namespace */
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';
// eslint-disable-next-line import/default
import AgentGeneralInfo from './Agent/AgentGeneralInfo';
import { useParams } from 'react-router';
import AdminActions from './Agent/Manage/AdminActions';
import { Grid, IconButton } from '@mui/material';
import AvatarMui from '../Common/Image/AvatarMui';
import { getUserProfileStatus } from '../../Util/Util';
import PhoneIcon from '@mui/icons-material/Phone';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MySnackbarAlert from '../Common/AlertMessage/MySnackbarAlert';
import Commissions from './Commissions/Commissions';
import AgentDocument from './Agent/AgentDocuments';
import AddMessage from '../Messages/AddMessage';
import * as actions from '../../store/actions';
import ContractDocuments from './Agent/Documents/Documents';
import Staff from './Agent/Staff/Staff';
import AgentNotes from './Agent/Notes/AgentNotes';
import Chat from '../Common/Chat/chat';
import AgentTerritory from './AgentTerritory/AgentTerritory';
import AccountNotes from './Agent/Notes/AccountsNote';
import Agent from '../../api/agent';
const PREFIX = 'AgentProfile';
const classes = {
  root: `${PREFIX}-root`,
  tabs: `${PREFIX}-tabs`,
  tab: `${PREFIX}-tab`,
  selectedTab: `${PREFIX}-selectedTab`,
  summaryRow: `${PREFIX}-summaryRow`,
  accountManager: `${PREFIX}-accountManager`,
};
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    // height: 1000, //test
  },
  [`& .${classes.tabs}`]: {
    borderRight: `1px solid ${theme.palette.divider}`,
    height: '100%',
  },
  [`& .${classes.tab}`]: theme.typography.tab,
  [`& .${classes.selectedTab}`]: {
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
    },
  },
  [`& .${classes.summaryRow}`]: {
    // backgroundColor: '#eff2f7',
    backgroundColor: '#28697b',
    color: 'white',
    display: 'grid',
    gridTemplateColumns: 'repeat(3,1fr)',
    gap: '2%',
  },

  [`& .${classes.accountManager}`]: {
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(2,0.6fr)',
    // height: '100%',
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} boxShadow={5}>
          {children}
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const AgentProfile = (props) => {
  // const [university, setUniveristy] = useState([]);
  //#snackbar stuff


  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);

  const [alertMsg, setAlertMsg] = useState();
  //#endregion
  const [value, setValue] = useState('profile');
  const [agent, setAgent] = useState({});
  const [status, setStatus] = useState('loading...');
  const [adminName, setAdminName] = useState();
  const [accountAppUserId, setAccountAppUserId] = useState();
  const [accountName, setAccountName] = useState();
  const [accountEmail, setAccountEmail] = useState();
  const [accountPhoneNumber, setAccountPhoneNumber] = useState();
  const [unsaved, setUnsaved] = useState(false);
  const [openAddMessage, setOpenAddMessage] = useState(false);
  const [accountsNote, setAccountNote] = useState([]);
  const [notes, setNotes] = useState([]);
  const handleChange = (event, newValue) => {
    if (!unsaved) {
      setValue(newValue);
    } else {
      if (
        window.confirm(
          'Are you sure you want to leave? Any unsaved data will be lost'
        )
      ) {
        setUnsaved(false);
        setValue(newValue);
      }
    }
  };
  const setAgentStatus = (status) => {
    setStatus(getUserProfileStatus(status));
  };
  const { agentId } = useParams();

  useEffect(() => {
    if (props.users.length == 0) {
      props.loadUsers().catch((error) => {
        console.log(error);
        //debugger;
      });
    }
  }, [props.users]);

  // useEffect(() => {
  //   Agent.Universities.list()
  //     .then((res) => {
  //       setUniveristy(res);
  //       console.log('University List', res);
  //     })
  //     .catch((err) => {
  //       console.log('university err', err);
  //     });
  // }, []);

  useEffect(() => {
    if (props.appUserId && props.users.length > 0) {
      let isCancelled = false;
      Agent.Agents.getAgent(agentId ? parseInt(agentId) : 0, props.appUserId)
        .then((agent) => {
          if (!isCancelled) {
            setAgentStatus(agent.status);
            setAgent(agent);
            setAccountAppUserId(agent.businessDevAppUserDetailsId);
            var allNotes = agent.agentNotes.sort(function (a, b) {
              return new Date(b.dateSent) - new Date(a.dateSent);
            });
            setNotes(allNotes);

            let allAccountNotes = agent.accountsNotes.sort(function (a, b) {
              return new Date(b.dateSent) - new Date(a.dateSent);
            });
            setAccountNote(allAccountNotes);

            if (agent.businessDevAppUserDetailsId) {
              const businessUser = props.users.find(
                ({ id }) => id == agent.businessDevAppUserDetailsId
              );
              setAccountName(businessUser.name);
              setAccountEmail(businessUser.applicationUpdateEmail);
              setAccountPhoneNumber(businessUser.phoneNumber);
            }
            Agent.UserAssignment.listAssignments(
              agent.userProfile.id,
              'assignedTo'
            )
              .then((users) => {
                if (users && users.length > 0) {
                  setAdminName(`${users[0].name}`);
                  if (!agent.businessDevAppUserDetailsId) {
                    setAccountName(`${users[0].name}`);
                    setAccountEmail(users[0].email);
                    setAccountPhoneNumber(users[0].phoneNumber);
                  }
                }
              })
              .catch((error) => {
                console.log(error);
                //debugger;
              });
          }
        })
        .catch((error) => {
          // debugger;
          console.log(error);
        });
      return () => {
        isCancelled = true;
      };
    }
  }, [props.users]);
  const updateStatus = (status) => {
    setAgentStatus(status);
  };
  const updateAdminInfo = (user) => {
    setAdminName(user.name);
  };
  const updateAccountInfo = (user) => {
    setAccountName(user.name);
    setAccountEmail(user.email);
    setAccountPhoneNumber(user.phoneNumber);
  };
  const updateAlert = (msg, success) => {
    setAlertMsg(msg);
    if (success) {
      setOpenSuccess(true);
    } else {
      setOpenFailure(true);
    }
  };

  return (
    <Root className={classes.root}>
      <Grid container direction="column">
        {agent && agent.id && (
          <Grid item container className={classes.summaryRow}>
            <Grid item container xs={12} alignItems="start" className="py-4">
              <Grid item xs={4} container>
                <Grid item alignItems="center" margin={2}>
                  <AvatarMui
                    imageUrl={agent.userProfile.image}
                    fullName={agent.userProfile.name}
                    size={10}
                  />
                  {/* <Grid item></Grid> */}
                </Grid>
              </Grid>

              <Grid
                item
                container
                direction="column"
                xs={6}
                justifyContent="center"
              >
                <Grid item>
                  <Typography variant="h6">Owner Info</Typography>
                </Grid>

                <Grid item>
                  <Typography variant="p">
                    {status == 'Active'
                      ? `ID:  ${agent.id}`
                      : `ID:  ${agent.id}
                       (Status: ${status})`}
                  </Typography>
                  <Typography variant="subtitle2">
                    {agent.userProfile.name}
                  </Typography>
                  <Typography variant="subtitle2">
                    {' '}
                    {agent.ownerPhone}
                  </Typography>
                  <Typography variant="subtitle2"> {agent.email}</Typography>
                </Grid>
              </Grid>
            </Grid>
            {}

            <Grid item container xs={12} alignItems="start">
              <Grid
                item
                container
                direction="column"
                xs={12}
                justifyContent="center"
                className="py-2"
              >
                <Grid item>
                  <Typography variant="h6">Second Point Of Contact</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2">
                    {agent.firstName2}
                  </Typography>
                  <Typography variant="subtitle2">
                    {/* {agent.designation} */}
                  </Typography>
                  <Typography variant="subtitle2"> {agent.contact2}</Typography>
                </Grid>
              </Grid>
            </Grid>

            {accountName && (
              <Grid item container xs={12} alignItems="center">
                <Grid
                  item
                  container
                  direction="column"
                  xs={12}
                  style={{ paddingLeft: 0 }}
                >
                  <Grid>
                    <Typography variant="h6">Account Manager</Typography>
                  </Grid>

                  <Grid item container xs={12}>
                    <Grid item>
                      {' '}
                      <Typography variant="subtitle2">{accountName}</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction="row"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item style={{ paddingTop: 0 }}>
                        {accountEmail}
                      </Grid>
                      <Grid item>
                        <CopyToClipboard text={accountEmail}>
                          <IconButton size="small">
                            <FileCopyIcon />
                          </IconButton>
                        </CopyToClipboard>
                      </Grid>
                    </Grid>

                    {accountPhoneNumber && (
                      <Grid
                        item
                        container
                        direction="row"
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>
                          <PhoneIcon color="primary" />
                        </Grid>
                        <Grid item style={{ paddingTop: 1 }}>
                          {accountPhoneNumber}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {!props.isInternal && (
                    <div className="mb-4">
                      {' '}
                      <button
                        onClick={() => setOpenAddMessage(true)}
                        className="bg-blue-500 text-white px-4 py-2 rounded-md inline-flex items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          className="w-4 h-4 mr-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                          ></path>
                        </svg>
                        Send Message
                      </button>
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
        )}

        <Grid item container style={{ marginTop: 2 }}>
          <Grid item xs={12}>
            <Tabs
              orientation="horizontal"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs"
              className={classes.tabs}
            >
              <Tab
                classes={{ root: classes.selectedTab }}
                className={classes.tab}
                value="profile"
                label="General Information"
                {...a11yProps(0)}
              />
              {!props.role.isAdmin && props.isInternal && (
                <Tab
                  value="commissions"
                  classes={{ root: classes.selectedTab }}
                  className={classes.tab}
                  label="Commissions"
                  {...a11yProps(1)}
                />
              )}
              {props.isInternal && !props.role.isAdmin && (
                <Tab
                  value="agreements"
                  classes={{ root: classes.selectedTab }}
                  className={classes.tab}
                  label="Agreements"
                  {...a11yProps(2)}
                />
              )}
              {props.isInternal && !props.role.isAdmin && (
                <Tab
                  value="manage"
                  classes={{ root: classes.selectedTab }}
                  className={classes.tab}
                  label="Manage Agent"
                  {...a11yProps(3)}
                />
              )}

{((props.permissions.CanAccessAgentContracts) || (props.role.isAgent && agent.status == 5)) && (
                  <Tab
                    value="contracts"
                    classes={{ root: classes.selectedTab }}
                    className={classes.tab}
                    label="Contracts"
                    {...a11yProps(4)}
                  />
                )}


              {(props.role.isSuperAdmin || props.role.isAgent) &&
                agent.status == 5 && (
                  <Tab
                    value="staff"
                    classes={{ root: classes.selectedTab }}
                    className={classes.tab}
                    label={props.isInternal ? 'Staff' : 'Your Users'}
                    {...a11yProps(5)}
                  />
                )}

              {props.role.isSuperAdmin && (
                <Tab
                  value="territory"
                  classes={{ root: classes.selectedTab }}
                  className={classes.tab}
                  label={
                    props.isInternal ? 'Agent Territory' : 'Your Territory'
                  }
                  {...a11yProps(6)}
                />
              )}
              {props.isInternal && (
                <Tab
                  value="notes"
                  classes={{ root: classes.selectedTab }}
                  className={classes.tab}
                  label={'Internal Notes'}
                  {...a11yProps(7)}
                />
              )}

              {props.isInternal &&
                props.permissions.CanManageAgentAccountsNote && (
                  <Tab
                    value="accountsnote"
                    classes={{ root: classes.selectedTab }}
                    className={classes.tab}
                    label={'Accounts Note'}
                    {...a11yProps(8)}
                  />
                )}

              {((props.permissions.CanAccessAgentChat) || (props.role.isAgent && agent.status ==5))   && (
                <Tab
                  value="chat"
                  classes={{ root: classes.selectedTab }}
                  className={classes.tab}
                  label={'Chat'}
                  {...a11yProps(9)}
                />
              )}

            </Tabs>
          </Grid>

          <Grid item xs={12}>
            <TabPanel value={value} index={'profile'}>
              {agent && agent.id && (
                <AgentGeneralInfo
                  agent={agent}
                  setAgentParent={setAgent}
                  updateAlert={updateAlert}
                  setUnsaved={setUnsaved}
                  isInternal={props.isInternal}
                  role={props.role}
                />
              )}
            </TabPanel>

            <TabPanel value={value} index={'commissions'}>
              <Commissions id={agent.id} />
            </TabPanel>

            <TabPanel value={value} index={'agreements'}>
              <AgentDocument
                agent={agent}
                setAgentParent={setAgent}
                admin={props.isInternal}
              />
            </TabPanel>

            <TabPanel value={value} index={'manage'}>
              <AdminActions
                agent={agent}
                appUserId={props.appUserId}
                updateStatus={updateStatus}
                adminName={adminName}
                updateAdminInfo={updateAdminInfo}
                accountName={accountName}
                updateAccountInfo={updateAccountInfo}
                updateAlert={updateAlert}
              />
            </TabPanel>

            <TabPanel value={value} index={'contracts'}>
              {agent && agent.id && (
                <ContractDocuments
                  agentId={agent.id}
                  agent={agent}
                  isInternal={props.isInternal}
                />
              )}
            </TabPanel>

            <TabPanel value={value} index={'staff'}>
              <Staff agent={agent} isInternal={props.isInternal} />
            </TabPanel>

            <TabPanel value={value} index={'territory'}>
              <AgentTerritory
                agent={agent}
                isInternal={props.isInternal}
                // agentId={agent.id}
                // setAgent={setAgent}
                // updateAlert={updateAlert}

                // university={agent.agentCountries}
                // regionalAgreement={agent.regionalAgreement}
                // updateAlert={updateAlert}

                //updateAlert={updateAlert}
                // university={university}
                // updateParent={props.updateProfileParent}
                //  setAlertMsg={setAlertMsg}
              />
            </TabPanel>

            {console.log(agent, 'agentprofile obj')}
            <TabPanel value={value} index={'notes'}>
              <AgentNotes
                records={notes}
                setRecords={setNotes}
                agentId={agent.id}
                name={props.name}
              />
            </TabPanel>
            <TabPanel value={value} index={'accountsnote'}>
              <AccountNotes
                records={accountsNote}
                setRecords={setAccountNote}
                agentId={agent.id}
                name={props.name}
              />
            </TabPanel>

            <TabPanel value={value} index={'chat'}>
              <Chat
                recordId={agent.id}
                recipientName={`${agent.userProfile?.firstName} ${agent.userProfile?.lastName}`}
                phone={agent.agentPhoneNumber}
                type="agent"
                country={agent.agentNationality || 76}
                appUserId={accountAppUserId}
                name={name}
              />
            </TabPanel>
          </Grid>
        </Grid>
      </Grid>
      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
        />
      )}
      {openAddMessage && (
        <AddMessage
          open={openAddMessage}
          cancel={setOpenAddMessage}
          toId={accountAppUserId}
          agentId={0}
          agentSub={
            agent
              ? agent.id +
                ' - ' +
                agent.userProfile.firstName +
                ' ' +
                agent.userProfile.lastName
              : ''
          }
          toName={accountName}
        />
      )}
    </Root>
  );
};
const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    isInternal: state.auth.isInternal,
    role: state.auth.role,
    users: state.users,
    name: state.auth.name,
    permissions: state.auth.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentProfile);
