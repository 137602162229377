import Resizer from 'react-image-file-resizer';

export const ResizeFile = (
  file,
  maxWidth = 140,
  maxHeight = 180,
  minWidth = 70,
  minHeight = 90,
  fileType = 'JPEG'
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      fileType,
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'file',
      minWidth,
      minHeight
    );
  });
