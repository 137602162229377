import React, { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  Select,
  InputLabel,
} from '@mui/material';
import { AGENTSTATUS } from '../../../../Util/Constants';
import { connect } from 'react-redux';
import Agent from '../../../../api/agent';
import { Countries } from '../../../../Util/Util';
import { Mail } from '@mui/icons-material';


const AgentComFilters = ({
  bdmId,
  setBdmId,
  agentId,
  company,
  status,
  setAgentId,
  setCompany,
  setStatus,
  appUpdateEmail,
  setAppUpdateEmail,
  handleClearFilters,
  handleApplyFilters,
  handleSendBulkEmail,
  totalSelected,
  handleCompanyHqChange,
  companyHq,

}) => {
  console.log(bdmId);

  const [bdmusers, setBdmUsers] = useState([]);

  useEffect(() => {
    Agent.Users.list()
      .then((res) => {
        var tempUser=res.filter((item) => !item.isDisabled)
        setBdmUsers(tempUser);
        console.log('user List', tempUser);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  return (
    <>
      <div className="flex justify-between items-center gap-4">
        <Grid container spacing={4} className="mb-0">
          <div className="flex flex-col items-center mt-6 pb-4 ml-0">
            <div className="flex flex-row mt-4 ml-10">
              <div className="flex justify-between items-center gap-2 mr-2">
                <Grid item>
                  <TextField
                    size="small"
                    label="Partner Id"
                    value={agentId}
                    onChange={(e) => setAgentId(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    size="small"
                    label="Company Name"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <FormControl>
                    <InputLabel>Status</InputLabel>
                    <Select
                      size="small"
                      native
                      label="Status"
                      placeholder="Status"
                      className="w-48"
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      <option value={0}>All</option>
                      {AGENTSTATUS.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl>
                    {/* <InputLabel>BDM</InputLabel> */}
                    <Select
                      size="small"
                      native
                      label="BDM"
                      className="w-48"
                      value={bdmId}
                      onChange={(e) => {
                        setBdmId(e.target.value);
                      }}
                    >
                      <option value={0}>BDM</option>
                      {bdmusers.map((bdm) => (
                        <option key={bdm.id} value={bdm.id}>
                          {bdm.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <Autocomplete
                      value={
                        Countries.find((country) => country.id == companyHq) ||
                        null
                      }
                      onChange={handleCompanyHqChange}
                      options={Countries}
                      getOptionLabel={(country) => country.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          label="Company HQ"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <FormControl fullWidth>
                    <InputLabel>Application Update Email</InputLabel>
                    <Select
                      size="small"
                      native
                      label="Application Update Email"
                      placeholder="Application Update Email"
                      value={appUpdateEmail}
                      onChange={(e) => setAppUpdateEmail(e.target.value)}
                    >
                      <option value={0}>All</option>
                      <option value={1}> Update Email Exist</option>
                      <option value={2}> Update Email Not Exist</option>
                    </Select>
                  </FormControl>
                </Grid>
                </div>
                <div className="flex justify-between items-center gap-2 mr-6">
                <Button
                  variant="outlined"
                  className="ml-2 w-48"
                  onClick={handleClearFilters}
                >
                  Clear Filters
                </Button>

                <Button
                  variant="contained"
                  className="bg-blue-500 text-white ml-0 w-48"
                  onClick={handleApplyFilters}
                >
                  Apply Filters
                </Button>

                <Button
                  variant="contained"
                  className="bg-sky-800 text-white w-48"
                  onClick={handleSendBulkEmail}
                  disabled={totalSelected === 0}
                >
                  <Mail className="mr-2 text-lg" />
                  Send Bulk
                </Button>
              </div>
            </div>
          </div>
        </Grid>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
    users: state.users,
  };
};

export default connect(mapStateToProps, null)(AgentComFilters);
