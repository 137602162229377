import React, { useState, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Divider } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import LinearIndeterminate from '../../../../Common/Progress/LinearIndeterminate';
import ConfirmationModal from '../../../../Common/Modal/ConfirmationModal';
import StudentContext from '../../../../Students/Context/student-context';
import { QUALIFICATIONS } from '../../../../../Util/Constants';
import Add from '../Language/Add';
import { format } from 'date-fns';
import Agent from '../../../../../api/agent';

const Language = ({
  records,
  setRecordsGrandParent,
  id,
  setStudentIdParent,
  isLocked,
}) => {
  let studentContext = useContext(StudentContext);
  const [recordAddMode, setRecordAddMode] = useState(false);
  const [record, setRecord] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [langRecords, setLangRecords] = useState(records);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [recordIdToDelete, setRecordIdToDelete] = useState();

  const setLangRecordsParent = (records) => {
    setLangRecords(records);
    setRecordsGrandParent(records);
  };

  const addRecord = (record) => {
    setRecord(record);
    setRecordAddMode(true);
  };
  const deleteRecord = () => {
    var studentData = {
      saveStudentDto: {
        student: {
          id: id,
          languageRecords: langRecords.filter(
            (item) => item.id != recordIdToDelete
          ),
        },
        agentAppUserId: studentContext.agentAppUserId,
        createdByAppUserId: studentContext.createdByAppUserId,
        saveMode: 2,
      },
    };
    setUpdating(true);
    Agent.Students.saveStudent(studentData)
      .then(() => {
        if (id > 0) {
          setStudentIdParent(id);
          setLangRecords(studentData.saveStudentDto.student.languageRecords);
          setRecordsGrandParent(
            studentData.saveStudentDto.student.languageRecords
          );
        }
        setUpdating(false);
        setOpenConfirm(false);
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
        debugger;
      });
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    if (startIndex == endIndex) return;
    let temp = langRecords.slice();
    const [removed] = temp.splice(startIndex, 1);
    temp.splice(endIndex, 0, removed);

    let newOrder = 0;
    temp.forEach(function (obj) {
      newOrder = newOrder + 1;
      obj.order = newOrder;
    });

    var studentData = {
      saveStudentDto: {
        student: {
          id: id,
          LanguageRecords: temp,
        },
        agentAppUserId: studentContext.agentAppUserId,
        createdByAppUserId: studentContext.createdByAppUserId,
        saveMode: 2,
      },
    };
    setUpdating(true);
    Agent.Students.saveStudent(studentData)
      .then((id) => {
        setLangRecordsParent(temp);
        setUpdating(false);
      })
      .catch((error) => {
        console.log(error);
        debugger;
        setUpdating(false);
      });
  };

  return (
    <div className="w-full flex flex-col items-start">
      {recordAddMode && (
        <Add
          record={record}
          langRecords={langRecords}
          setLangRecordsParent={setLangRecordsParent}
          setRecordAddMode={setRecordAddMode}
          id={id}
          setStudentIdParent={setStudentIdParent}
          isLocked={isLocked}
        />
      )}
      <div className="bg-gray-100 px-4 py-10 rounded-lg flex flex-col gap-0 items-center justify-start w-full">
        {updating && (
          <div>
            <LinearIndeterminate />
          </div>
        )}
        <div className="flex flex-col gap-2 text-center text-4xl font-semibold pb-3 pt-3 w-2/3 divide-y-2 divide-slate-400/70">
          <div> Language</div>
          <div></div>
        </div>

        <div className="text-base font-medium text-gray-600 text-justify ">
          Please describe your foreign language skills.
        </div>

        <div className="text-lg font-medium text-gray-600 text-justify pt-6 px-10">
          If you have taken any relevant tests that assert your language
          proficiency, please also list the tests and associated scores in the
          appropriate field. Note that you can add as many languages as you
          like.
        </div>
        {!(id > 0) && (
          <div>
            <div color="secondary">
              You must save the student first (General Info) before you can add
              the language info
            </div>
          </div>
        )}
        <div className="py-2">
          <Button
            disabled={!(id > 0)}
            className="min-w-[250px] bg-indigo-100 hover:bg-indigo-100 hover:text-indigo-800 text-indigo-600 px-6 drop-shadow-md"
            startIcon={<AddIcon />}
            onClick={() => addRecord({ id: 'new' })}
          >
            {records.length > 0 ? 'Add Another' : 'Add Language Info'}
          </Button>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="w-full"
              >
                {langRecords.map((record, index) => (
                  <Draggable
                    key={record.id}
                    draggableId={record.id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div key={record.id}>
                          <div className="flex justify-between gap-4 ">
                            <div>
                              <div className="text-lg font-medium text-indigo-600">
                                {`${
                                  QUALIFICATIONS.find(
                                    ({ id }) => id === record.qualification
                                  ).type
                                } ${
                                  record.qualification == 7 &&
                                  record.description
                                    ? ' (' + record.description + ')'
                                    : ''
                                }
                                `}
                              </div>
                              <div>
                                <div>{`Exam Date: ${format(
                                  new Date(record.examDate),
                                  'MMMM d, yyyy'
                                )}`}</div>
                                <div>{`Band: ${record.band}`}</div>
                                <div>{`Reading: ${record.reading}, Listening: ${record.listening}, writing: ${record.writing}, Speaking: ${record.speaking}`}</div>
                              </div>
                            </div>
                            <div>
                              <div className="flex flex-col gap-2">
                                <Button
                                  className="bg-indigo-100 hover:bg-indigo-100 hover:text-indigo-800 text-indigo-600 px-6 drop-shadow-md"
                                  onClick={() => addRecord(record)}
                                  startIcon={
                                    isLocked ? <VisibilityIcon /> : <EditIcon />
                                  }
                                >
                                  {isLocked ? 'View' : 'Edit'}
                                </Button>
                                <Button
                                  disabled={isLocked}
                                  variant="contained"
                                  className="bg-rose-400 hover:bg-rose-500 text-white"
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setRecordIdToDelete(record.id);
                                  }}
                                  startIcon={<DeleteForeverIcon />}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Divider className="py-2" />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {langRecords.length > 1 && (
          <div className="text-3xl font-semibold text-gray-700 pb-5 text-center">
            You can optionally change the order in which your education records
            appear above by dragging a record up or down
          </div>
        )}
      </div>
      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={deleteRecord}
        confirmText="Confirm Deletion"
        content="Are you sure that you would like to delete this record?"
      />
    </div>
  );
};

export default React.memo(Language);
