import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Select,
  MenuItem,
} from '@mui/material';
import AvatarMui from '../../../Common/Image/AvatarMui';
import Agent from '../../../../api/agent';
import AgentAssignment from './AgentAssignment';
import CountryAssignment from './CountryAssignment';
import UserAssignment from './UserAssignment';
import PermissionAssignment from './PermissionAssignment';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationModal from '../../../Common/Modal/ConfirmationModal';
import MySnackbarAlert from '../../../Common/AlertMessage/MySnackbarAlert';
import LeadCountryAssignment from './LeadCountryAssignment';
import {
  ADMIN,
  APPLICATIONTEAM,
  APPLICATIONTEAMLEADER,
  SENIORADMIN,
} from '../../../../Util/Constants';
import TwoFactor from './TwoFactor';
import ReferralCountryAssignment from './ReferralCountryAssignment';
const PREFIX = 'ManageUser';

const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
  panelHeader: `${PREFIX}-panelHeader`,
  panelBody: `${PREFIX}-panelBody`,
  summaryRow: `${PREFIX}-summaryRow`,
  dialogCustomizedWidth: `${PREFIX}-dialogCustomizedWidth`,
};

const MyDialogGrid = styled(Grid)(({ theme }) => ({
  // backgroundColor: '#eff2f7',
  backgroundColor: theme.palette.common.lightBlue,
  // color: 'white',
  padding: 5,
}));

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },
  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
    color: 'white',
  },
  [`& .${classes.panelHeader}`]: {
    backgroundColor: theme.palette.primary.light,
  },
  [`& .${classes.panelBody}`]: {
    paddingTop: 15,
  },
  [`& .${classes.summaryRow}`]: {
    // backgroundColor: '#eff2f7',
    backgroundColor: theme.palette.common.lightBlue,
    // color: 'white',
    padding: 5,
  },
  [`& .${classes.dialogCustomizedWidth}`]: {
    'max-width': '50%',
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const ManageUser = ({
  currentUser,
  allUsers,
  setOpenParent,
  cancel,
  loggedInUserRole,
}) => {
  const [open, setOpen] = useState(setOpenParent);
  const [user, setUser] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [role, setRole] = useState(currentUser.role);
  //#snackbar stuff
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [alertMsg, setAlertMsg] = useState();
  //#endregion
  const [action, setAction] = useState();
  const [confirmMessage, setConfirmMessage] = useState();
  const [confirmTitle, setConfirmTitle] = useState();
  const [updating, setUpdating] = useState();

  function handleClose() {
    setOpen(false);
    cancel(false);
  }

  useEffect(() => {
    Agent.Users.getUser(currentUser.appUserId)
      .then((user) => {
        setUser(user);
        //console.log('Users:', user);
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  }, [currentUser]);

  const disableUser = () => {
    setUpdating(true);
    Agent.Account.disableUser(currentUser.id)
      .then(() => {
        setUpdating(false);
        setOpenConfirm(false);
        setAlertMsg(
          'User de-activated. Refresh the page if you want to see it disappear from the list'
        );
        setOpenSuccess(true);
        setOpenFailure(false);
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
        debugger;
      });
  };
  const enableUser = () => {
    setUpdating(true);
    Agent.Account.enableUser(currentUser.id)
      .then(() => {
        setUpdating(false);
        setOpenConfirm(false);
        setAlertMsg(
          'User re-activated. Refresh the page if you want to see the updated user list'
        );
        setOpenSuccess(true);
        setOpenFailure(false);
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
        // debugger;
      });
  };

  const alert = (msg, success) => {
    if (success) {
      setOpenSuccess(true);
      setOpenFailure(false);
      setAlertMsg(msg);
    }
  };

  const submitRoleChange = () => {
    setUpdating(true);
    Agent.Account.changeRole(currentUser.appUserId, role)
      .then(() => {
        setUpdating(false);
        setOpenConfirm(false);
        setAlertMsg(
          'Role changed. Please refresh the page to see change and also ask the user to log out and log back in'
        );
        setOpenSuccess(true);
        setOpenFailure(false);
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
        setAlertMsg('Role changed failed. Please try again');
        setOpenSuccess(false);
        setOpenFailure(true);
        debugger;
      });
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
      PaperComponent={PaperComponent}
      maxWidth={'lg'}
      // classes={{ paperFullWidth: classes.dialogCustomizedWidth }}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <Grid container>
          <Grid item xs={11}>
            <Typography textAlign="center" variant="h6" color="secondary">
              User Administration
            </Typography>
          </Grid>
          <Grid item xs={1} container justifyContent="flex-end">
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          {user && (
            <MyDialogGrid item container>
              <Grid item xs={2}>
                <AvatarMui
                  imageUrl={currentUser.image}
                  fullName={currentUser.name}
                  size={10}
                />
              </Grid>
              <Grid
                item
                container
                direction="column"
                xs={6}
                justifyContent="center"
              >
                <Grid item>
                  <Typography variant="h5">{currentUser.name}</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6">{currentUser.role}</Typography>
                </Grid>

                {/* <Grid item>
                    <Typography>{user.countries.join(', ')}</Typography>
                  </Grid> */}
              </Grid>
            </MyDialogGrid>
          )}

          <Grid item style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Root>
              {currentUser.role != 'Student' && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.panelHeader}
                  >
                    <Typography className={classes.heading}>Agents</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.panelBody}>
                    <AgentAssignment userId={currentUser.id} />
                  </AccordionDetails>
                </Accordion>
              )}
              {currentUser.role != 'Student' && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className={classes.panelHeader}
                  >
                    <Typography className={classes.heading}>
                      Agent Assignment for Countries
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.panelBody}>
                    {user && <CountryAssignment currentUser={user} />}
                  </AccordionDetails>
                </Accordion>
              )}
              {currentUser.role != 'Student' && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className={classes.panelHeader}
                  >
                    <Typography className={classes.heading}>
                      Referral Assignment for Countries
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.panelBody}>
                    {user && <ReferralCountryAssignment currentUser={user} />}
                  </AccordionDetails>
                </Accordion>
              )}
              {currentUser.role != 'Student' && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className={classes.panelHeader}
                  >
                    <Typography className={classes.heading}>
                      Lead Assignment for Countries
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.panelBody}>
                    {user && <LeadCountryAssignment currentUser={user} />}
                  </AccordionDetails>
                </Accordion>
              )}
              {user && currentUser.roleId === 2 && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className={classes.panelHeader}
                  >
                    <Typography className={classes.heading}>
                      Admin Assignment
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.panelBody}>
                    <UserAssignment currentUser={user} users={allUsers} />
                  </AccordionDetails>
                </Accordion>
              )}
              {currentUser.role != 'Student' && (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    className={classes.panelHeader}
                  >
                    <Typography className={classes.heading}>
                      Permission Assignment
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.panelBody}>
                    <PermissionAssignment user={currentUser} />
                  </AccordionDetails>
                </Accordion>
              )}
              {loggedInUserRole.isSuperAdmin && (
                <>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      className={classes.panelHeader}
                    >
                      <Typography className={classes.heading}>
                        Other Actions
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.panelBody}>
                      <Grid container direction={'column'} spacing={3}>
                        {currentUser.role != 'SuperAdmin' && (
                          <Grid
                            item
                            container
                            alignItems={'center'}
                            spacing={2}
                          >
                            <Grid item>
                              <Typography>Select New Role</Typography>
                            </Grid>
                            <Grid item>
                              <Select
                                value={role}
                                onChange={(e) => setRole(e.target.value)}
                                size="medium"
                              >
                                <MenuItem value={SENIORADMIN}>
                                  {SENIORADMIN}
                                </MenuItem>
                                <MenuItem value={ADMIN}>{ADMIN}</MenuItem>
                                <MenuItem value={APPLICATIONTEAMLEADER}>
                                  {APPLICATIONTEAMLEADER}
                                </MenuItem>
                                <MenuItem value={APPLICATIONTEAM}>
                                  {APPLICATIONTEAM}
                                </MenuItem>
                              </Select>
                            </Grid>
                            <Grid item>
                              <Button
                                color="secondary"
                                disabled={role == currentUser.role}
                                onClick={() => {
                                  setAction(() => submitRoleChange);
                                  setConfirmTitle('Confirm User Role Change');
                                  setConfirmMessage(
                                    `Are you sure that you would like to assign '${role}' role to this user?`
                                  );
                                  setOpenConfirm(true);
                                }}
                              >
                                Change User Role
                              </Button>
                            </Grid>
                          </Grid>
                        )}

                        <Grid className="flex justify-between m-4">
                          <Grid item>
                            <Button
                              variant="contained"
                              color="error"
                              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                              onClick={() => {
                                setAction(() => disableUser);
                                setConfirmTitle('Confirm De-activation');
                                setConfirmMessage(
                                  'Are you sure that you would like to de-activate this user? Once de-activated, they will not be able to log into the system again'
                                );
                                setOpenConfirm(true);
                              }}
                            >
                              De-activate User
                            </Button>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="contained"
                              color="primary"
                              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                              onClick={() => {
                                setAction(() => enableUser);
                                setConfirmTitle('Confirm Re-activation');
                                setConfirmMessage(
                                  'Are you sure that you would like to re-activate this user? Once re-activated, they will be able to log into the system again'
                                );
                                setOpenConfirm(true);
                              }}
                            >
                              Re-activate User
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                      className={classes.panelHeader}
                    >
                      <Typography className={classes.heading}>
                        2 Factor Authentication
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.panelBody}>
                      <TwoFactor currentUser={currentUser} alert={alert} />
                    </AccordionDetails>
                  </Accordion>
                </>
              )}
              <ConfirmationModal
                open={openConfirm}
                cancel={setOpenConfirm}
                confirm={action}
                confirmText={confirmTitle}
                content={confirmMessage}
                loading={updating}
              />
              {(openSuccess || openFailure) && (
                <MySnackbarAlert
                  opnSuccess={openSuccess}
                  opnFailure={openFailure}
                  message={alertMsg}
                  opnSuccessParent={setOpenSuccess}
                  opnFailureParent={setOpenFailure}
                />
              )}
            </Root>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ManageUser;
