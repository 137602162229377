/* eslint-disable react/display-name */
import React, {useState, useEffect} from 'react'
import {styled} from '@mui/material/styles'
import {LicenseInfo, DataGridPro} from '@mui/x-data-grid-pro'
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY)
import {Button, Grid, TextField, Typography} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import LoadingView from '../../Common/Loading/LoadingView'
import Agent from '../../../api/agent'
import * as actions from '../../../store/actions/index'
import {connect} from 'react-redux'
import DepartmentAddEdit from './DepartmentAddEdit'
import {MUI_LICENSE_KEY} from '../../../Util/Constants'

const PREFIX = 'Courses'
const classes = {
  // courseArea: `${PREFIX}-courseArea`,
  // studentArea: `${PREFIX}-studentArea`,
}

const StyledDiv = styled('div')(() => ({
  // [`& .${classes.courseArea}`]: {
  //   borderRight: '1px solid gray',
  //   marginTop: 5,
  // },
  // [`& .${classes.studentArea}`]: {
  //   marginTop: 5,
  //   paddingLeft: 24,
  // },
}))

const Departments = ({ departments, loadDepts}) => {
  const [loading, setLoading] = useState(true)
  const [addEditMode, setAddEditMode] = useState(false)
  const [dept, setDept] = useState()
  const [name, setName] = useState('')
  const [deptRows, setDeptRows] = useState([]);
  const pageSize = 50
  const applyFilter = () => {
    setDeptRows(
      departments.filter((item) =>
        item.name
          .replace(' ', '')
          .toLowerCase()
          .includes(name.replace(' ', '').toLowerCase())
      )
    );
  };
  const resetFilter = () => {
    setDeptRows(departments);
    setName('');
  };
  useEffect(() => {
      loadDepts()
        .then((list) => {
          setDeptRows(list);
          setLoading(false)
        })
        .catch((error) => {
          console.log(error)
          debugger
        })
  }, [])
  
  let columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 85,
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Department',
      width: 800,
      renderCell: (params) => (
        // <MyTooltip title={params.row.name}>
        <Button
          // variant="standard"
          style={{textTransform: 'none'}}
          // as={Button}
          onClick={() => {
            setDept(params.row)
            setAddEditMode(true)
          }}
        >
          {params.row.name}
        </Button>
        // </MyTooltip>
      ),
    },
    {
      field: 'isPopular',
      headerName: 'Popular',
      width: 100,
      renderCell: (params) => (
        <Typography>{params.row.isPopular ? 'Popular' : ''}</Typography>
      ),
    },
    {
      field: 'isInactive',
      headerName: 'Status',
      width: 100,
      renderCell: (params) => (
        <Typography>{params.row.isInactive ? 'Not Active' : ''}</Typography>
      ),
    },
  ]
  //Countries.find(({ id }) => id === agent.country).name
  return loading ? (
    <LoadingView />
  ) : (
    <StyledDiv>
      {addEditMode && (
        <DepartmentAddEdit dept={dept} setAddMode={setAddEditMode} />
      )}
      <Grid
        container
        direction='column'
        spacing={1}
        style={{backgroundColor: 'white'}}
      >
        <Grid item container justifyContent='flex-end'>
          <Grid item></Grid>
          <Grid item>
            <Button
              LinkComponent
              variant='contained'
              className='bg-sky-400 hover:bg-sky-500'
              startIcon={<AddIcon />}
              onClick={() => {
                setDept({
                  id: 0,
                  name: '',
                  isInactive: false,
                  isPopular: false,
                })
                setAddEditMode(true)
              }}
            >
              Add New Department
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              size='small'
              label='Department Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
        <Grid item xs={2}>
            <Button
              fullWidth
              onClick={applyFilter}
              variant="contained"
              className="bg-sky-400 hover:bg-sky-500"
            >
              Apply Filters
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              onClick={resetFilter}
              className="bg-rose-200 hover:bg-rose-300 text-gray-600 hover:text-gray-700"
            >
              Reset Filters
            </Button>
          </Grid>
          </Grid>
        <Grid item>
          <DataGridPro
            disableSelectionOnClick
            rows={deptRows}
            pagination
            pageSize={pageSize}
            columns={columns}
            loading={loading}
            disableColumnFilter
            autoHeight
            density='compact'
          />
        </Grid>
      </Grid>
    </StyledDiv>
  )
}

const mapStateToProps = (state) => {
  return {
    departments: state.departments,
    courses: state.courses,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadCourses: () => dispatch(actions.loadCourses()),
    loadDepts: () => dispatch(actions.loadDepts()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Departments)
