import React, { useState, useContext, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from '../../assets/images/logo.png';
import UserContext from '../../context/user-context';
import * as actions from '../../store/actions/index';
import Agent from '../../api/agent';
import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import AvatarMui from '../Common/Image/AvatarMui';
import { Button } from '@mui/material';
import useWindowSize from '../Students/common/useWindowSize';
import MainNavigation from './MainNavigation';

const ReferralNavbar = ({ logout, referralName, imageUrl, role }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dashboardClick, setDashboardClick] = useState(false);
  const [profileClick, setProfileClick] = useState(false);
  const [contractClick, setContractClick] = useState(false);
  const location = useLocation();
  const refUrl = location.pathname;
  const refUrlName = refUrl.split('/');

  useEffect(() => {
    if (refUrlName[1] == 'home') {
      setDashboardClick(true);
    } else if (refUrlName[2] == 'dashboard') {
      setDashboardClick(true);
    } else if (refUrlName[2] == 'profile') {
      setProfileClick(true);
    } else if (refUrlName[2] == 'contract') {
      setContractClick(true);
    }
  }, []);
  
  const handleOnClickMenu = (onClickMenuName) => {
    setDashboardClick(false);
    setProfileClick(false);
    setContractClick(false);

    if (onClickMenuName == 'dashboard') {
      setDashboardClick(true);
    } else if (onClickMenuName == 'profile') {
      setProfileClick(true);
    } else if (onClickMenuName == 'contract') {
      setContractClick(true);
    }
  };
  const handleProfileOnClick = () => {
    navigate('/referral/profile');
    handleOnClickMenu('profile');
  };
  const handleContractOnClick = () => {
    navigate('/referral/contract');
    handleOnClickMenu('contract');
  };
  const handleDashboardOnClick = () => {
    navigate('/referral/dashboard');
    handleOnClickMenu('dashboard');
  };
  const navigation = [
    { name: 'Dashboard', href: '/referral/dashboard', current: false },
    { name: 'Profile', href: '/referral/profile', current: false },
    { name: 'Contract', href: '/referral/contract', current: false },
  ];
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };
  let userContext = useContext(UserContext);
  const navigate = useNavigate();

  const handleClickNotificationItem = (item) => {
    console.log('item', item);
    Agent.Common.deletenotification(item.id)
      .then(() => {
        userContext.setUserContext({
          ...userContext,
          notificationCount: userContext.notificationCount - 1,
          notifications: userContext.notifications.filter(function (
            notification
          ) {
            return notification.id != item.id;
          }),
        });
        navigate(item.link);
      })
      .catch((error) => {
        debugger;
        console.log(error);
      });
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMyAccountSettings = () => {
    handleMenuClose();
    //navigate('/settings');
  };

  const handleOnClick = () => {
    navigate('/referral/signin');
  };

  const handleLogOut = () => {
    logout();
    handleMenuClose();
    navigate('/');
  };

  const referralNameSplit = referralName?.split(' ');
  const firstName = referralNameSplit[0]?.split('');
  const lastName = referralNameSplit[1]?.split('');
  const size = useWindowSize();
  return (
    <>
      <Disclosure
        as="nav"
        className="fixed justify-center items-center w-full px-2 z-50 bg-gray-800"
      >
        {({ open }) => (
          <>
            <div className="relative mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <button>
                      <Link to="/home">
                        <img
                          alt="company logo"
                          className="w-28 h-16 pr-2"
                          src={Logo}
                        />
                      </Link>
                    </button>
                  </div>
                  <div className="hidden pt-3 sm:ml-6 sm:block">
                    <div className="flex space-x-4">
                      {/* {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-gray-900 text-white'
                                : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                              'rounded-md px-3 py-2 text-sm font-medium'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            {item.name}
                          </Link>
                        ))} */}
                      <div className="flex flex-start justify-between gap-4 text-white py-2 pt-4 text-sm font-medium md:px-0 lg:px-2">
                        <button onClick={handleDashboardOnClick}>
                          {dashboardClick ? (
                            <span className="bg-gray-600 px-2 py-1 rounded-sm">
                              Dashboard
                            </span>
                          ) : (
                            <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                              Dashboard
                            </span>
                          )}
                        </button>
                        <button onClick={handleProfileOnClick}>
                          {profileClick ? (
                            <span className="bg-gray-600 px-2 py-1 rounded-sm">
                              Profile
                            </span>
                          ) : (
                            <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                              Profile
                            </span>
                          )}
                        </button>
                        <button onClick={handleContractOnClick}>
                          {contractClick ? (
                            <span className="bg-gray-600 px-2 py-1 rounded-sm">
                              Contract
                            </span>
                          ) : (
                            <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                              Contract
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-6 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <button
                    type="button"
                    className="relative rounded-full px-2 bg-gray-800 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  &nbsp;
                  {/* {isLoggedIn?( 
                  <button
                    onClick={handleOnClick}
                    type="button"
                    className="relative rounded-full px-2 bg-gray-700 text-white text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    Sign In
                  </button>*/}
                  {/* ):''} */}
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-1">
                    <div>
                      <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <div className="avatar placeholder">
                          <div className="bg-gray-600 text-neutral-content rounded-full w-9">
                            <AvatarMui
                              imageUrl={imageUrl}
                              fullName={referralName}
                              size={5}
                            />
                            {/* <span className="text-xl uppercase"> {firstName[0] + lastName[0]}</span> */}
                          </div>
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/settings"
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Account Settings
                            </Link>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/"
                              onClick={handleLogOut}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Log out
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    className={classNames(
                      item.current
                        ? 'bg-gray-900 text-white'
                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    <Link to={item.href}>{item.name}</Link>
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    referralName: state.auth.name,
    status: state.auth.status,
    role: state.auth.role,
    imageUrl: state.auth.image,
    appUserId: state.auth.id,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    setDrawerOpen: ownProps.setDrawerOpen,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralNavbar);
