import React, {useEffect, useState, useRef} from 'react'
import {styled} from '@mui/material/styles'
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Chip,
} from '@mui/material'
import Agent from '../../../api/agent'
import CloseIcon from '@mui/icons-material/Close'
import {INTAKES, STUDY_LEVELS} from '../../../Util/Constants'
import Draggable from 'react-draggable'
import LinearIndeterminate from '../../Common/Progress/LinearIndeterminate'
import {Typography} from '@mui/material'
import {Divider} from '@mui/material'
import {IconButton} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
// import {NumberFormatBase} from 'react-number-format';
import {parse} from 'date-fns'
import {LoadingButton} from '@mui/lab'
import {connect} from 'react-redux'
import * as actions from '../../../store/actions/index'

const PREFIX = 'UniversityCourse'

const classes = {
  closeButton: `${PREFIX}-closeButton`,
  chip: `${PREFIX}-chip`,
}

const Root = styled('div')(({theme}) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  [`& .${classes.chip}`]: {
    marginTop: 10,
    marginRight: 5,
  },
}))

function PaperComponent(props) {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const AddCourses = ({
  course,
  setAddMode,
  updateParent,
  courses,
  addCourses,
  departments,
  loadDepts,
}) => {
  const [open, setOpen] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [newCourses, setNewCourses] = useState([])
  const [courseName, setCourseName] = useState(null)
  const [dept, setDept] = useState()

  useEffect(() => {
    if (departments.length == 0) {
      loadDepts()
        .then((list) => {})
        .catch((error) => {
          console.log(error)
          debugger
        })
    } else {
      //
    }
  }, [departments])

  function handleClose() {
    setOpen(false)
    setAddMode(false)
  }

  const handleFormCancel = () => {
    setOpen(false)
    setAddMode(false)
  }

  const saveCourses = (event) => {
    event.preventDefault()
    Agent.Courses.addCourses({newCourses: newCourses, departmentId: dept.id})
      .then((list) => {
        addCourses(list)
        setOpen(false)
        setAddMode(false)
      })
      .catch((error) => {
        console.log(error)
        debugger
      })
  }

  return (
    <Root>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        aria-labelledby='draggable-dialog-title'
        PaperComponent={PaperComponent}
        maxWidth='md'
        fullWidth
      >
        <>
          <DialogTitle style={{cursor: 'move'}} id='draggable-dialog-title'>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant='h6' color='primary' align='center'>
                  Add Courses
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label='close'
                  className={classes.closeButton}
                  onClick={handleFormCancel}
                  size='large'
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Divider />
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              item
              direction='column'
              spacing={2}
              style={{marginTop: -5}}
            >
              <Grid item>
                {departments.length > 0 && (
                  <Grid item xs={6}>
                    <Autocomplete
                      style={{width: '100%'}}
                      size='small'
                      value={dept}
                      onChange={(_, newValue) => {
                        setDept(newValue)
                      }}
                      options={departments}
                      getOptionLabel={(option) => option.name}
                      sx={{width: 300}}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          label='Department/Subject'
                        />
                      )}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item container>
                <>
                  <Grid item container spacing={1} style={{marginTop: 5}}>
                    <Grid item xs={10}>
                      <TextField
                        label='Enter course name'
                        value={courseName}
                        onChange={(e) => setCourseName(e.target.value)}
                        size='small'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <LoadingButton
                        loading={updating}
                        fullWidth
                        disabled={!courseName}
                        // disabled={
                        //   isSubmitting || !dirty || !isValid || isLocked
                        // }
                        onClick={() => {
                          if (
                            newCourses.some(
                              (item) =>
                                item.toLowerCase() ==
                                courseName.trim().toLowerCase()
                            )
                          )
                            return
                          // also check if this course already exists under this uni. check redux store
                          if (
                            courses.some(
                              (item) =>
                                item.name.toLowerCase().trim() ==
                                courseName.toLowerCase().trim()
                            )
                          )
                            return
                          setNewCourses(newCourses.concat(courseName.trim()))
                          setCourseName('')
                        }}
                        variant='contained'
                        className='bg-sky-400 hover:bg-sky-500'
                      >
                        Add to list
                      </LoadingButton>
                    </Grid>
                  </Grid>
                </>
              </Grid>
              {newCourses.length > 0 && (
                <Grid item container direction='column' style={{marginTop: 5}}>
                  <Grid item>
                    <Typography
                      color='secondary'
                      textAlign='center'
                    >{`Following ${
                      newCourses.length == 1 ? 'course' : 'courses'
                    } will be added`}</Typography>
                    <Divider style={{marginBottom: 5}} />
                  </Grid>
                  {newCourses.map((course, index) => (
                    <>
                      <Grid item container key={index}>
                        <Grid item xs={10}>
                          <Typography color='primary'>{course}</Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            style={{paddingTop: 0}}
                            onClick={() => {
                              setNewCourses(
                                newCourses.filter((item) => item != course)
                              )
                            }}
                            size='large'
                          >
                            <CancelIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Divider />
                    </>
                  ))}
                </Grid>
              )}
            </Grid>

            {updating && (
              <Grid item style={{marginBottom: 20}}>
                <LinearIndeterminate />
              </Grid>
            )}
            <DialogActions>
              <Grid
                container
                justifyContent='space-between'
                style={{marginTop: 25}}
              >
                <Grid item>
                  <Button onClick={() => handleFormCancel()}>Cancel</Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={newCourses.length == 0}
                    onClick={saveCourses}
                    variant='contained'
                    className='bg-sky-400 hover:bg-sky-500'
                  >
                    Add Course
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </DialogContent>
        </>
      </Dialog>
    </Root>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    departments: state.departments,
    courses: state.courses,
    allocations: state.courseallocationss,
    isInternal: state.auth.isInternal,
    role: state.auth.roleName,
    course: ownProps.course,
    universityId: ownProps.universityId,
    setAddMode: ownProps.setAddMode,
    updateParent: ownProps.updateParent,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addCourses: (courses) => dispatch(actions.addCourses(courses)),
    loadDepts: () => dispatch(actions.loadDepts()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCourses)
