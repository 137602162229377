import React, { useState } from 'react';
import Agent from '../../../api/agent';

const CreateFromEmail = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [emailValidationMessage, setEmailValidationMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(emaiInfo.Name)) {
      setEmailValidationMessage('Please enter a valid email address.');
      return;
    }

    try {
      const response = await Agent.Communication.createFromEmail(emaiInfo);
      setSuccessMessage('Email created successfully');
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
        setEmailName({
          Name: '',
        });
      }, 3000);
    } catch (error) {
      setErrorMessage('Error creating Email: ' + error);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 3000);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setEmailName({
      Name: value,
    });
    setEmailValidationMessage(''); // Reset validation message when input changes
  };

  const [emaiInfo, setEmailName] = useState({
    Name: '',
  });

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-b from-sky-200 to-sky-300">
      <div className="max-w-md w-full bg-white p-8 rounded shadow-lg">
        <h2 className="text-xl text-center font-semibold mb-4">Create Email</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="Name"
              className="block text-sm font-medium text-gray-700"
            >
              Email Name
            </label>
            <input
              type="email" 
              name="Name"
              id="Name"
              value={emaiInfo.Name}
              onChange={handleChange}
              className={`mt-1 p-2 border ${
                emailValidationMessage ? 'border-red-500' : 'border-gray-300'
              } rounded-md w-full focus:outline-none focus:ring focus:border-blue-500`}
              required
            />
            {emailValidationMessage && (
              <p className="text-red-500 text-sm mt-1">{emailValidationMessage}</p>
            )}
          </div>
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-full"
          >
            Create Email
          </button>
        </form>

        {showModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4">
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <div className="relative bg-white rounded-lg px-8 py-6">
                <div className="flex items-start justify-between">
                  <h3 className="text-lg font-semibold text-gray-900">
                    {successMessage ? 'Success' : 'Error'}
                  </h3>
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  {successMessage || errorMessage}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateFromEmail;
