import * as actionTypes from './actionTypes';
import Agent from '../../api/agent';

export const updateUserCountryAction = (id, country, predicate, operation) => {
  return {
    type: actionTypes.USERS_USER_COUNTRY_UPDATE,
    payload: {
      id,
      country,
      predicate,
      operation,
    },
  };
};

export const updatePersonalDetailsAction = (
  id,
  name,
  phoneNumber,
  applicationUpdateEmail
) => {
  return {
    type: actionTypes.USERS_USER_PERSONAL_UPDATE,
    payload: {
      id,
      name,
      phoneNumber,
      applicationUpdateEmail,
    },
  };
};

export const uploadProfilePhotoAction = (imageUrl) => {
  return {
    type: actionTypes.PROFILE_UPLOADING_SUCCESS,
    imageUrl,
  };
};

export const loadUsersStart = () => {
  return {
    type: actionTypes.USERS_LOADING_START,
  };
};

export const loadUsersSuccess = (users) => {
  return {
    type: actionTypes.USERS_LOADING_SUCCESS,
    users,
  };
};

export const loadUsersFail = (error) => {
  return {
    type: actionTypes.USERS_LOADING_FAIL,
    error: error,
  };
};

export const updateChildSuccess = (
  parentUserId,
  parentName,
  childUserId,
  childName,
  operation
) => {
  return {
    type: actionTypes.USERS_USER_CHILD_UPDATE_SUCCESS,
    payload: {
      parentUserId,
      parentName,
      childUserId,
      childName,
      operation,
    },
  };
};
export const addChildFail = (user) => {
  return {
    type: actionTypes.USERS_USER_CHILD_ADD_FAIL,
  };
};

// export const loadUsers = () => async (dispatch) => {
//   //dispatch(loadUsersStart());
//   const users = await Agent.Users.list();
//   dispatch(loadUsersSuccess(users));
//   return users;
// };

//improve caching logic

export const loadUsers = () => async (dispatch, getState) => {
  const { users } = getState(); 
  console.log(users)
  if (users && users.length > 0) {
    return users;
  }

  const fetchedUsers = await Agent.Users.list();
  dispatch(loadUsersSuccess(fetchedUsers));
  return fetchedUsers;
};







export const addChildToUser = (
  parentUserId,
  parentName,
  childUserId,
  childName
) => {
  return async (dispatch) => {
    await Agent.UserAssignment.assign(parentUserId, childUserId)
      .then(() => {
        dispatch(
          updateChildSuccess(
            parentUserId,
            parentName,
            childUserId,
            childName,
            'ADD'
          )
        );
        return Promise.resolve();
      })
      .catch((error) => {
        debugger;
        //dispatch(loadUsersFail(error)); // for some reason, we can't just pass error. it fails
        return Promise.reject(error);
      });
  };
};

export const removeChildFromUser = (
  parentUserId,
  parentName,
  childUserId,
  childName
) => {
  return async (dispatch) => {
    await Agent.UserAssignment.delete(parentUserId, childUserId)
      .then(() => {
        dispatch(
          updateChildSuccess(
            parentUserId,
            parentName,
            childUserId,
            childName,
            'DELETE'
          )
        );
        return Promise.resolve();
      })
      .catch((error) => {
        debugger;
        //dispatch(loadUsersFail(error)); // for some reason, we can't just pass error. it fails
        return Promise.reject(error);
      });
  };
};
