import React, { useState, useContext, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import UserContext from '../../context/user-context';
import { format, addMinutes } from 'date-fns';
import { DataGridPro } from '@mui/x-data-grid-pro';

const MessageList = ({
  messages,
  openMessage,
  appUserId,
  isInternal,
  userList,
  pageNumber,
  setPageNumber,
  pageSize,
  setPageSize,
  totalPages,
  setTotalPages,
  totalrows,
  setTotalRows,
  setIsMessageOpen,
}) => {
  const userContext = useContext(UserContext);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    let messageRows = [];
    messages.map((msg) => {
      messageRows.push({
        id: msg.messageId,
        companyName: msg.companyName,
        subject: msg.subject,
        appUserId: msg.appUserId,
        isInternal: msg.isInternal,
        unread: !msg.isAcknowledged,
        agentLogo: msg.agentFileUrl,
        fromId: msg.fromId,
        toId: msg.toId,
        firstName: msg.firstName,
        lastName: msg.lastName,
        fileUrl: msg.fileUrl,
        lastUpdatedOn: format(
          addMinutes(
            new Date(msg.lastUpdatedOn),
            -new Date().getTimezoneOffset()
          ),
          'MMMM d, yyyy h:mm a'
        ),
      });
    });
    setRows(messageRows);
  }, [messages]);

  const handleOpenMessage = (id) => {
    setIsMessageOpen(true);
    openMessage(id).then(() => {
      let temp = [];
      rows.forEach((row) => {
        if (row.id == id) {
          if (row.unread == true) {
            userContext.setUserContext({
              ...userContext,
              unReadMessagesCount:
                userContext.unReadMessagesCount > 0
                  ? userContext.unReadMessagesCount - 1
                  : userContext.unReadMessagesCount,
            });
            row.unread = false;
          }
        }
        temp.push(row);
      });
      setRows(temp);
    });
  };

  let userColumns = [
    {
      field: 'unread',
      headerName: 'Message',
      width: 350,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <button
          onClick={() => {
            handleOpenMessage(params.row.id);
          }}
          className={
            params.row.unread
              ? 'flex flex-start bg-gray-300 justify-between gap-2 h-[100%] w-[100%]'
              : 'flex flex-start justify-between gap-2 h-[100%] w-[100%]'
          }
        >
          <div className="relative w-[15%]">
            <div className="flex flex-shrink-0 items-center pl-1">
              {params.row.fileUrl != null && params.row.agentLogo != null ? (
                <img
                  className="w-10 h-10 rounded-full"
                  alt="Agent"
                  src={isInternal ? params.row.agentLogo : params.row.fileUrl}
                />
              ) : (
                <div className="relative pt-3 bg-cyan-800 w-10 h-10 rounded-full items-center">
                  {params.row.fromId != appUserId
                    ? userList
                        ?.find((user) => user.appUserId == params.row.fromId)
                        ?.name.split(' ')[0][0] +
                      userList
                        ?.find((user) => user.appUserId == params.row.fromId)
                        ?.name.split(' ')[1][0]
                    : params.row.firstName[0] + params.row.lastName[0]}
                </div>
              )}
            </div>
          </div>
          <Grid align="left" className="w-[85%]">
            <div className="flex justify-between">
              <Typography className="w-[80%] font-semibold">
                <span>
                  {' '}
                  {isInternal
                    ? params.row.companyName ? params.row.companyName : userList?.find(
                      (user) =>
                        params.row.fromId != appUserId? user.appUserId == params.row.fromId :
                        user.appUserId == params.row.toId
                    )?.name
                    : userList?.find(
                        (user) =>
                          params.row.fromId != appUserId? user.appUserId == params.row.fromId :
                          user.appUserId == params.row.toId
                      )?.name}
                </span>
              </Typography>
              {params.row.unread && (
                <span className="rounded-md w-[20%] mr-1 pl-1 text-sm text-red-600 font-semibold">
                  unread
                </span>
              )}
            </div>
            <div>
              <Typography variant="caption">
                <span>{params.row.lastUpdatedOn}</span>
              </Typography>
            </div>
          </Grid>
        </button>
      ),
    },
  ];

  return (
    <>
      <div style={{ height: 840, width: '100%' }}>
        <DataGridPro
          disableColumnFilter
          rowHeight={60}
          rows={rows}
          pagination
          totalPageSize={totalPages}
          paginationMode="server"
          pageSize={pageSize}
          pageSizeOptions={[20]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowCount={totalrows}
          onPageChange={(newPage) => setPageNumber(newPage + 1)}
          page={pageNumber - 1}
          columns={userColumns}
        />
      </div>
    </>
  );
};

export default MessageList;