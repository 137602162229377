import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import {
  MUI_LICENSE_KEY,
  TASKS_GROUPS,
  TASKS_PRIORITIES,
  TASKS_STATUSES,
  TASKS_TYPES,
} from '../../Util/Constants';
import { LicenseInfo, DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import { format, addMinutes } from 'date-fns';
import { connect } from 'react-redux';

import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // the locale you want
registerLocale('en-GB', enGB); // register it with the name you want
import { startOfDay, addHours, addMonths } from 'date-fns';
import { useNavigate } from 'react-router';
import Agent from '../../api/agent';
import Task from './Task';

const Tasks = ({ isInternal, role, studentId, taskFor, journey }) => {
  const navigate = useNavigate();
  const [addMode, setAddMode] = useState(false);
  const [task, setTask] = useState({});
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cosmosAdminUsers, setCosmosAdminUsers] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize = 50;
  const [reset, setReset] = useState();
  const [start, setStart] = useState(addMonths(new Date(), -1));
  const [end, setEnd] = useState(new Date());
  const [showAll, setShowAll] = useState(false);
  const [showOption, setShowOption] = useState(0);

  useEffect(() => {
    Agent.Cosmos.getUsers()
      .then((usrs) => {
        setCosmosAdminUsers(
          usrs.filter((item) => {
            return (
              item.usertype == 1 ||
              item.usertype == 2 ||
              item.usertype == 3 ||
              item.usertype == 7 ||
              item.usertype == 8
            );
          })
        );
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  }, []);

  useEffect(() => {
    if (cosmosAdminUsers.length > 0) {
      getData();
    }
  }, [cosmosAdminUsers]);

  const getData = (filter = false, reset = false) => {
    setLoading(true);
    Agent.UserTask.list(getParams(filter, reset)).then((data) => {
      setTasks(data);
      setLoading(false);
    });
  };

  const getParams = (filter, reset) => {
    const params = new URLSearchParams();
    params.append('pageNumber', page.toString());
    params.append('pageSize', pageSize.toString());
    params.append('studentId', studentId ? studentId.toString() : '0');
    if (filter) {
      if (start) {
        params.append('startDate', new Date(start).toDateString());
      }
    } else {
      params.append('startDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (end) {
        params.append('endDate', new Date(end).toDateString());
      }
    } else {
      params.append('endDate', new Date().toDateString());
    }
    if (filter) {
      params.append('showAll', showAll ? 'true' : 'false');
    } else {
      params.append('showAll', 'false');
    }
    if (filter) {
      params.append('option', showOption);
    } else {
      params.append('option', '0');
    }
    return params;
  };

  const applyFilter = () => {
    getData(true, false);
    setReset(false);
  };

  const resetFilter = () => {
    setReset(true);
    setStart(addMonths(new Date(), -1));
    setEnd(new Date());
    setShowAll(false);
    setShowOption(0);
    getData(false, true);
  };

  const handleTaskUpdate = (task) => {
    setAddMode(false);
    let newItem = true;
    let temp = [];
    tasks.forEach((item) => {
      if (item.id == task.id) {
        temp.push(task);
        newItem = false;
      } else {
        temp.push(item);
      }
    });
    if (newItem) {
      temp.unshift(task);
    }
    setTasks(temp);
  };

  const editRecord = (row) => {
    if (row.studentId && !journey) {
      navigate(`/students/${row.studentId}/manage/?tab=tasks`);
    } else {
      setTask({
        id: row.id,
        studentId: studentId,
        createdBy: row.createdBy,
        admin1AppUserDetailsId: row.admin1AppUserDetailsId,
        admin2AppUserDetailsId: row.admin2AppUserDetailsId,
        admin3AppUserDetailsId: row.admin3AppUserDetailsId,
      });
      setAddMode(true);
    }
  };

  let columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 85,
      hide: true,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 300,
      renderCell: (params) => (
        <Typography color={'primary'}>{params.row.title}</Typography>
      ),
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      hide: journey == 'student',
      width: 250,
      renderCell: (params) => (
        <span>
          {cosmosAdminUsers.find(({ id }) => id == params.row.createdBy)?.name}
        </span>
      ),
    },
    {
      field: 'admin1AppUserDetailsId',
      headerName: 'Admin',
      width: 250,
      renderCell: (params) => (
        <span>
          {
            cosmosAdminUsers.find(
              ({ id }) => id == params.row.admin1AppUserDetailsId
            )?.name
          }
        </span>
      ),
    },
    {
      field: 'admin2AppUserDetailsId',
      headerName: 'Assisting Person',
      width: 200,
      hide: true,
      renderCell: (params) => (
        <span>
          {params.row.admin2AppUserDetailsId
            ? cosmosAdminUsers.find(
                ({ id }) => id == params.row.admin2AppUserDetailsId
              )?.name
            : ''}
        </span>
      ),
    },
    {
      field: 'admin3AppUserDetailsId',
      headerName: 'Tag Person',
      width: 200,
      hide: true,
      renderCell: (params) => (
        <span>
          {params.row.admin3AppUserDetailsId
            ? cosmosAdminUsers.find(
                ({ id }) => id == params.row.admin3AppUserDetailsId
              ).name
            : ''}
        </span>
      ),
    },
    {
      field: 'group',
      headerName: 'Group',
      width: 130,
      hide: true,
      renderCell: (params) => (
        <span>
          {params.row.group
            ? TASKS_GROUPS.find(({ id }) => id == params.row.group).type
            : ''}
        </span>
      ),
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
      renderCell: (params) => (
        <span>
          {params.row.type
            ? TASKS_TYPES.find(({ id }) => id == params.row.type).type
            : ''}
        </span>
      ),
    },
    {
      field: 'priority',
      headerName: 'Priority',
      renderCell: (params) => (
        <span>
          {params.row.priority
            ? TASKS_PRIORITIES.find(({ id }) => id == params.row.priority).type
            : ''}
        </span>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params) => (
        <span>
          {params.row.status
            ? TASKS_STATUSES.find(({ id }) => id == params.row.status).type
            : ''}
        </span>
      ),
    },
    {
      field: 'createdOn',
      headerName: 'Created',
      renderCell: (params) => (
        <span>{format(new Date(params.row.createdOn), 'dd/MM/yy')}</span>
      ),
    },
    {
      field: 'deadline',
      headerName: 'Deadline',
      renderCell: (params) => (
        <span>
          {params.row.deadline == null
            ? ''
            : format(new Date(params.row.deadline), 'dd/MM/yy')}
        </span>
      ),
    },
  ];
  return loading ? null : (
    <>
      <Grid container direction={'column'} spacing={3} style={{ marginTop: 5 }}>
        {studentId == 0 ||
          (!studentId && (
            <Grid item container direction={'column'}>
              <Grid item container spacing={2}>
                <Grid item xs={2}>
                  {' '}
                  <DatePicker
                    // popperClassName={classes.reactDatepickerPopper}
                    autoComplete="off"
                    locale="enGB"
                    dateFormat="dd-MM-yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    error={true}
                    selected={(start && new Date(start)) || null}
                    onChange={(newVal) => {
                      if (newVal) {
                        newVal = addHours(startOfDay(newVal), 12);
                      }
                      setStart(newVal);
                    }}
                    customInput={
                      <TextField
                        label="Created Start Date"
                        fullWidth
                        // onChange={onChange}
                        value={start}
                        variant="outlined"
                        size="small"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <DatePicker
                    // popperClassName={classes.reactDatepickerPopper}
                    autoComplete="off"
                    locale="enGB"
                    dateFormat="dd-MM-yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    error={true}
                    selected={(end && new Date(end)) || null}
                    onChange={(newVal) => {
                      if (newVal) {
                        newVal = addHours(startOfDay(newVal), 12);
                      }
                      setEnd(newVal);
                    }}
                    customInput={
                      <TextField
                        label="Created End Date"
                        fullWidth
                        // onChange={onChange}
                        value={start}
                        variant="outlined"
                        size="small"
                      />
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                      value={showAll ? 1 : 0}
                      onChange={(e) => {
                        setShowAll(e.target.value == 0 ? false : true);
                      }}
                      size="small"
                      label="Status"
                    >
                      <MenuItem value={0}>Incomplete</MenuItem>
                      <MenuItem value={1}>Show All</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel>Assignee</InputLabel>
                    <Select
                      value={showOption}
                      onChange={(e) => {
                        setShowOption(e.target.value);
                      }}
                      size="small"
                      label="Assignee"
                    >
                      <MenuItem value={0}>Created for Me</MenuItem>
                      <MenuItem value={1}>Created by Me</MenuItem>
                      <MenuItem value={2}>Show All</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    onClick={applyFilter}
                    variant="contained"
                    className="bg-sky-400 hover:bg-sky-500"
                  >
                    Apply Filters
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    fullWidth
                    onClick={resetFilter}
                    className="bg-rose-200 hover:bg-rose-300 text-gray-600 hover:text-gray-700"
                  >
                    Reset Filters
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ))}
        <Grid item style={{ height: 710, width: '100%' }}>
          <DataGridPro
            //className={classes.root}
            rowHeight={90}
            disableSelectionOnClick
            rows={tasks}
            columns={columns}
            loading={loading}
            density="compact"
            components={{
              Toolbar: GridToolbar,
            }}
            onCellClick={(params, event) => {
              event.defaultMuiPrevented = true;
              if (params.colDef.field === 'title') {
                editRecord(params.row);
              }
            }}
          />
        </Grid>
      </Grid>
      {addMode && (
        <Task
          record={task}
          setAddMode={setAddMode}
          taskFor={taskFor}
          cosmosAdminUsersParent={cosmosAdminUsers}
          updateParent={handleTaskUpdate}
        />
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isInternal: state.auth.isInternal,
    role: state.auth.role,
    studentId: ownProps.studentId,
    taskFor: ownProps.taskFor,
  };
};

//record, setRecordParent, setAddMode
export default connect(mapStateToProps, null)(Tasks);
