import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Box } from '@mui/material';
import LoadingView from '../../../Common/Loading/LoadingView';
import WhatsappCountry from './WhatsappCountry';
import { connect } from 'react-redux';

const WhatsAppWrapper = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [tabReady, setTabReady] = useState(false);
  const [tab, setTab] = useState('India');
  const [tabItems, setTabItems] = useState([]);

  useEffect(() => {
    let items = [];
    if (permissions.CanManageWhatsAppUK) {
      items.push('UK');
    }
    if (permissions.CanManageWhatsAppBD) {
      items.push('Bangladesh');
    }
    if (permissions.CanManageWhatsAppIndia) {
      items.push('India');
    }
    if (permissions.CanManageWhatsAppPak) {
      items.push('Pakistan');
    }
    if (permissions.CanManageWhatsAppSA) {
      items.push('Saudi Arabia');
    }
    if (permissions.CanManageWhatsAppMor) {
      items.push('Morocco');
    }

    if (permissions.CanManageWhatsAppMor) {
      setTab('Morocco');
    }
    if (permissions.CanManageWhatsAppSA) {
      setTab('Saudi Arabia');
    }
    if (permissions.CanManageWhatsAppPak) {
      setTab('Pakistan');
    }
    if (permissions.CanManageWhatsAppIndia) {
      setTab('India');
    }
    if (permissions.CanManageWhatsAppBD) {
      setTab('Bangladesh');
    }
    if (permissions.CanManageWhatsAppUK) {
      setTab('UK');
    }

    setTabItems(items);
    setTabReady(true);
  }, [permissions]);

  //const tabItems = ['BD', 'UK', 'India'];

  const handleTabChange = (e, newVal) => {
    setTab(newVal);
  };

  return (
    <>
      {loading ? (
        <div style={{ marginTop: '0px', fontWeight: 'lighter' }}>
          <LoadingView />
        </div>
      ) : (
        <div>
          {/* <h3 style={{ fontWeight: 'bolder',marginTop:'-1%'}}>Reports and Analytics </h3> */}
          <Box>
            <Box>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#0081C9',
                    height: '5px',
                    borderRadius: '50px',
                  },
                }}
              >
                {tabReady &&
                  tabItems.map((item, index) => {
                    return (
                      <Tab
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '1em',
                        }}
                        label={item}
                        key={index}
                        value={item}
                      />
                    );
                  })}
              </Tabs>

              <Box>
                <TabPanel value={tab} index={'UK'}>
                  <WhatsappCountry country={'UK'} />
                </TabPanel>
                <TabPanel value={tab} index={'Bangladesh'}>
                  <WhatsappCountry country={'Bangladesh'} />
                </TabPanel>
                <TabPanel value={tab} index={'India'}>
                  <WhatsappCountry country={'India'} />
                </TabPanel>
                <TabPanel value={tab} index={'Pakistan'}>
                  <WhatsappCountry country={'Pakistan'} />
                </TabPanel>
                <TabPanel value={tab} index={'Saudi Arabia'}>
                  <WhatsappCountry country={'Saudi Arabia'} />
                </TabPanel>
                <TabPanel value={tab} index={'Morocco'}>
                  <WhatsappCountry country={'Morocco'} />
                </TabPanel>
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

const TabPanel = (props) => {
  const { children, value, index } = props;

  return <div>{value === index && <Box>{children}</Box>}</div>;
};

const mapStateToProps = (state) => {
  return {
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(WhatsAppWrapper);
