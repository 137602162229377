import React, { useState, useEffect } from 'react';
import Agent from '../../../api/agent';
import Select from '@mui/material/Select';
import { Templates_TYPE } from '../../../Util/Constants';
import { useParams } from 'react-router';
import { Countries } from '../../../Util/Util';

const UpdateTemplate = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const numId = parseInt(id);

  useEffect(() => {
    Agent.ManageTemplats.getUpdateTempProfile(numId)
      .then((res) => {
        console.log('res', res);
        setTemplateData(() => ({
          Id: res.id,
          TemplateId: res.templateId,
          TemplateName: res.templateName,
          TemplateType: res.templateType,
          IsDropdown: res.isDropdown,
          IsWhatsApp: res.isWhatsApp,
          CountryId: res.countryId,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [numId]);

  const [templateData, setTemplateData] = useState({
    Id: 0,
    TemplateId: '',
    TemplateName: '',
    TemplateType: 0,
    IsDropdown: false,
    IsWhatsApp: false,
    CountryId: 0,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await Agent.ManageTemplats.createTemplate(templateData);
      setSuccessMessage('Template updated successfully');
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 3000);
    } catch (error) {
      console.error(error); 
      const errorMessage = error.response
        ? error.response.data.message
        : error.message;
      setErrorMessage('Error updating template: ' + errorMessage);
      setShowModal(true);
      setTimeout(() => {
        setShowModal(false);
      }, 3000);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val =
      type === 'checkbox'
        ? checked
        : type === 'number'
        ? parseInt(value)
        : value;
    setTemplateData((prevData) => ({
      ...prevData,
      [name]: val,
    }));
  };

  const isWhatsAppChecked = templateData.IsWhatsApp;

  return (
    <div className="flex justify-center items-center min-h-screen bg-gradient-to-b from-sky-200 to-sky-300">
      <div className="max-w-md w-full bg-white p-8 rounded shadow-lg">
        <h2 className="text-xl text-center font-semibold mb-4">
          Update Template
        </h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label
              htmlFor="TemplateId"
              className="block text-sm font-medium text-gray-700"
            >
              Template ID
            </label>
            <input
              type="text"
              name="TemplateId"
              id="TemplateId"
              value={templateData.TemplateId}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label
              htmlFor="TemplateName"
              className="block text-sm font-medium text-gray-700"
            >
              Template Name
            </label>
            <input
              type="text"
              name="TemplateName"
              id="TemplateName"
              value={templateData.TemplateName}
              onChange={handleChange}
              className="mt-1 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring focus:border-blue-500"
              required
            />
          </div>

          <Select
            fullWidth
            size="small"
            native
            value={templateData.TemplateType}
            onChange={(e) =>
              handleChange({
                target: {
                  name: 'TemplateType',
                  value: parseInt(e.target.value),
                },
              })
            }
            className="mt-1 p-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500 w-full" // Add w-full class for full width
          >
            <option value={0}>All Types</option>
            {Templates_TYPE.map((item) => (
              <option key={item.id} value={item.id}>
                {item.type}
              </option>
            ))}
          </Select>

          <div className="mb-4">
            <input
              type="checkbox"
              name="IsDropdown"
              id="IsDropdown"
              checked={templateData.IsDropdown}
              onChange={handleChange}
              className="mr-2"
            />
            <label
              htmlFor="IsDropdown"
              className="text-sm font-medium text-gray-700"
            >
              Is Dropdown (For One to One Comms)
            </label>
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              name="IsWhatsApp"
              id="IsWhatsApp"
              checked={templateData.IsWhatsApp}
              onChange={handleChange}
              className="mr-2 focus:ring-blue-500"
            />
            <label
              htmlFor="IsWhatsApp"
              className="text-sm font-medium text-gray-700"
            >
              Is WhatsApp (Checked for WhatsApp template)
            </label>
          </div>
          {isWhatsAppChecked && (
            <div>
              <label
                htmlFor="CountryId"
                className="block text-sm font-medium text-gray-700"
              >
                Country ID
              </label>
              <Select
                fullWidth
                size="small"
                native
                value={templateData.CountryId}
                onChange={(e) =>
                  handleChange({
                    target: { name: 'CountryId', value: e.target.value },
                  })
                }
                className="mt-1 p-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500 w-full" // Add w-full class for full width
              >
                <option value={0}>Select a Country</option>
                {Countries.filter((item)=>item.id!=0).map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Select>
            </div>
          )}
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded w-full"
          >
            Update Template
          </button>
        </form>

        {showModal && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen px-4">
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              ></div>
              <div className="relative bg-white rounded-lg px-8 py-6">
                <div className="flex items-start justify-between">
                  <h3 className="text-lg font-semibold text-gray-900">
                    {successMessage ? 'Success' : 'Error'}
                  </h3>
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <span className="sr-only">Close</span>
                    <svg
                      className="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <p className="mt-2 text-sm text-gray-500">
                  {successMessage || errorMessage}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpdateTemplate;
