import React, { useEffect, useState } from 'react';
import { Button, Grid, TextField, Typography } from '@mui/material';

import { APPLICATION_STATUSES, INTAKES } from '../../../Util/Constants';
import { connect } from 'react-redux';
import { MultiSelectionMenu } from '../Helper/HelperFunction';
import { LoadingButton } from '@mui/lab';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import Agent from '../../../api/agent';
const AutomatedReports = () => {
  const [savingAccountReport1, setSavingAccountReport1] = useState(false);
  const [sendingAccountReport1, setSendingAccountReport1] = useState(false);
  const [apiResponse, setApiResponse] = useState();
  const [enableAccountReport1Saving, setEnableAccountReport1Saving] =
    useState(false);

  const [accountReport1Payload, setAccountReport1Payload] = useState({
    intakes: [],
    statuses: [],
  });

  // HANDLE RETURN DATA FOR MULTIPLE NATIONALITIES FROM FILTER COMPONENT

  const handleIntakeSelect = (e) => {
    const val = [...e];
    val.length != 0
      ? setAccountReport1Payload((prev) => {
          return { ...prev, intakes: val };
        })
      : setAccountReport1Payload((prev) => {
          return { ...prev, intakes: [] };
        });
  };
  const handleStatusSelect = (e) => {
    const val = [...e];
    val.length != 0
      ? setAccountReport1Payload((prev) => {
          return { ...prev, statuses: val };
        })
      : setAccountReport1Payload((prev) => {
          return { ...prev, statuses: [] };
        });
  };
  useEffect(() => {
    Agent.AutoReportConfigs.getConfig('accountapplicationreport')
      .then((data) => {
        if (data.intakes) {
          const intakesLocal = data.intakes.split(',');
          const statusesLocal = data.statuses.split(',');
          setAccountReport1Payload({
            ...data,
            intakes: intakesLocal.map((item) => parseInt(item)),
            statuses: statusesLocal.map((item) => parseInt(item)),
          });
        }
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  }, []);

  useEffect(() => {
    let years = [];
    let validYears = true;
    if (accountReport1Payload.years) {
      years = accountReport1Payload.years.split(',');
      years.forEach((year) => {
        if (isNaN(year)) validYears = false;
      });
    }
    let emails = [];
    let validEmails = true;
    if (accountReport1Payload.to) {
      emails = accountReport1Payload.to.split(',');
      emails.forEach((email) => {
        if (email.indexOf('@') < 0 || email.indexOf(';') > 0)
          validEmails = false;
      });
    }
    if (
      accountReport1Payload.intakes.length == 0 ||
      accountReport1Payload.statuses.length == 0 ||
      !validYears ||
      !validEmails
    ) {
      setEnableAccountReport1Saving(false);
    } else {
      setEnableAccountReport1Saving(true);
    }
  }, [accountReport1Payload]);

  const saveReportTemplate1 = () => {
    setSavingAccountReport1(true);
    //check validation
    if (!enableAccountReport1Saving) return;
    Agent.AutoReportConfigs.updateConfig('accountapplicationreport', {
      ...accountReport1Payload,
      intakes: accountReport1Payload.intakes.toString(),
      statuses: accountReport1Payload.statuses.toString(),
    })
      .then(() => {
        setSavingAccountReport1(false);
        setApiResponse('Configurations Saved');
      })
      .catch((error) => {
        setSavingAccountReport1(false);
        setApiResponse(
          'Failed to save, please ensure inputs are valid based on instructions'
        );
        console.log(error);
        debugger;
      });
  };

  const sendReportNow = () => {
    setSendingAccountReport1(true);
    setApiResponse('');
    Agent.AutoReportConfigs.sendAccountReport1()
      .then((response) => {
        setSendingAccountReport1(false);
        setApiResponse(response);
      })
      .catch((error) => {
        console.log(error);
        setApiResponse(
          'Something has gone wrong. Please check your report configurations'
        );
        setSendingAccountReport1(false);
        debugger;
      });
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Grid container direction={'column'}>
        <Grid item style={{ marginTop: 20 }}>
          <Typography color={'secondary'} variant="body1">
            Student Application Status Report (Accounts)
          </Typography>
        </Grid>
        <hr />
        <Grid
          container
          direction={'column'}
          // justifyContent={'center'}
          // alignItems="center"
        >
          <Grid
            item
            sx={12}
            style={{ width: '500' }}
            container
            alignItems={'center'}
          >
            <Grid item xs={1}>
              <Typography>Intakes</Typography>
            </Grid>
            <Grid item xs={11}>
              <MultiSelectionMenu
                selection={accountReport1Payload.intakes}
                name="intakes"
                placeholder="Intakes"
                options={INTAKES.map((intake) => ({
                  ...intake,
                  name: intake.type,
                }))}
                onChange={handleIntakeSelect}
                width="700"
              />
            </Grid>
          </Grid>
          <Grid
            item
            sx={12}
            style={{ width: '500' }}
            container
            alignItems={'center'}
          >
            <Grid item xs={1}>
              <Typography>Application Status</Typography>
            </Grid>
            <Grid item xs={11}>
              <MultiSelectionMenu
                selection={accountReport1Payload.statuses}
                name="statuses"
                placeholder="Application Status"
                options={APPLICATION_STATUSES.map((status) => ({
                  ...status,
                  name: status.type,
                }))}
                onChange={handleStatusSelect}
                width="850"
              />
            </Grid>
          </Grid>
          <Grid item container alignItems={'center'}>
            <Grid item xs={1}>
              <Typography>Report Year</Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField
                fullWidth
                value={accountReport1Payload.years}
                onChange={(e) =>
                  setAccountReport1Payload({
                    ...accountReport1Payload,
                    years: e.target.value,
                  })
                }
                // placeholder="separated by comma"
                placeholder="Report Year/s"
                type="text"
                // value={startDateFilter}
                // onChange={handleStartDateFilterChange}
                size="small"
                variant="outlined"
                className="w-80 border-gray-300 focus:border-primary-500 focus:ring-primary-500 rounded-md px-3 py-2 outline-none"
              />
            </Grid>
          </Grid>
          <Grid item container alignItems={'center'}>
            <Grid item xs={1}>
              <Typography>Email address</Typography>
            </Grid>
            <Grid item xs={11} direction={'column'}>
              <Grid item>
                <TextField
                  fullWidth
                  value={accountReport1Payload.to}
                  onChange={(e) =>
                    setAccountReport1Payload({
                      ...accountReport1Payload,
                      to: e.target.value,
                    })
                  }
                  // placeholder="separated by comma"
                  placeholder="Receivers"
                  type="text"
                  // value={startDateFilter}
                  // onChange={handleStartDateFilterChange}
                  size="small"
                  variant="outlined"
                  className="w-160 border-gray-300 focus:border-primary-500 focus:ring-primary-500 rounded-md px-3 py-2 outline-none"
                />
              </Grid>
              <Grid
                item
                style={{ paddingLeft: 12, paddingTop: 0, marginTop: -7 }}
              >
                <Typography variant="caption" color={'secondary'}>
                  For multiple email addresses, separate each email address by a
                  comma
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          // alignItems={'center'}
          justifyContent={'space-between'}
          direction={'column'}
          style={{ marginTop: 20 }}
          spacing={1}
        >
          <Grid item>
            <LoadingButton
              disabled={!enableAccountReport1Saving}
              loading={savingAccountReport1}
              onClick={saveReportTemplate1}
              startIcon={<DataUsageIcon />}
              variant="outlined"
            >
              Save Configurations
            </LoadingButton>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={sendingAccountReport1}
              onClick={sendReportNow}
              startIcon={<AttachEmailIcon />}
              variant="outlined"
            >
              Receive Report NOW
            </LoadingButton>
          </Grid>
          <Grid item>
            <Typography color={'secondary'}>{apiResponse}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(AutomatedReports);
