import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  FormControl,
  MenuItem,
  Container,
  FormHelperText,
} from '@mui/material';
import { Done } from '@mui/icons-material';
import { connect } from 'react-redux';
import * as yup from 'yup';
import Agent from '../../../api/agent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import PhoneInput from 'react-phone-number-input';
// import phoneInput from './phoneInput';
import { CONTACT_SERVICE_TYPE, CONTACT_TYPE } from '../../../Util/Constants';
import { Countries } from '../../../Util/Util';

const AddContact = ({ appUserId }) => {
  const [contactName, setContactName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [personEmail, setpersonEmail] = useState('');
  const [address, setAddress] = useState('');
  const [website, setWebsite] = useState('');
  const [countryId, setCountryId] = useState('');
  const [typeOfContact, setTypeOfContact] = useState(0);
  const [serviceType, setServiceType] = useState(0);
  const [isMeetingAtEvent, setIsMeetingAtEvent] = useState(false);
  const [eventName, setEventName] = useState('');
  const [eventLocation, setEventLocation] = useState('');
  const [eventYear, setEventYear] = useState(0);
  const [isEventAttendee, setIsEventAttendee] = useState(false);
  const [contactPerson, setContactPerson] = useState('');
  const [designation, setDesignation] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [followUpMeetingNote, setFollowUpMeetingNote] = useState('');
  const [description, setDescription] = useState('');
  const [department, setDepartment] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [phoneError, setPhoneError] = useState('');

  const validationSchema = yup.object().shape({
    contactName: yup
      .string()
      .min(2, 'Minimum character length is 2')
      .required('Contact Name is required'),
      emailAddress: yup
      .string()
      .email('Invalid email address')
      .test('valid-email', 'Invalid, please provide standard mail format.', function (value) {
        if (!value) return true; // If value is empty, skip validation
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value);
      })
      .nullable(),
    personEmail: yup
      .string()
      .email('Invalid email address')
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        'Invalid, please provide standard mail format.'
      )
      .required('Person Email is required'),
    contactPerson: yup.string().required('Person Name is required'),
    countryId: yup.string().required('Country is required'),
    typeOfContact: yup
      .number()
      .min(1, 'Type of Contact is required')
      .required('Type of Contact is required'),
    serviceType: yup
      .number()
      .min(1, 'Service Type is required')
      .required('Service Type is required'),
  });

  const handleTypeOfContactChange = (event) => {
    setTypeOfContact(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      typeOfContact: '',
    }));
  };

  const handleServiceTypeChange = (event) => {
    setServiceType(event.target.value);
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      serviceType: '',
    }));
  };

  const handleSaveContact = async () => {
    try {
      await validationSchema.validate(
        {
          contactName,
          emailAddress,
          personEmail,
          //address,
          // website,
          countryId,
          typeOfContact,
          serviceType,
          // eventName,
          // eventLocation,
          contactPerson,
        },
        { abortEarly: false }
      );

      const payload = {
        ContactName: contactName,
        EmailAddress: emailAddress,
        Email: personEmail,
        Address: address,
        Website: website,
        CountryId: countryId,
        TypeOfContact: typeOfContact,
        ServiceType: serviceType,
        IsMeetingAtEvent: isMeetingAtEvent,
        EventName: eventName,
        EventLocation: eventLocation,
        EventYear: eventYear,
        IsEventAttendee: isEventAttendee,
        ContactPerson: contactPerson,
        Designation: designation,
        ContactNumber: contactNumber,
        FollowUpMeetingNote: followUpMeetingNote,
        Description: description,
        Department: department,
        UserId: appUserId,
      };
      console.log('countryId', countryId);
      // const response =
      await Agent.GlobalContacts.addContact(payload)
        .then(() => {
          // console.log('response', response);
          setSuccessMessage('Contact Added! Thank You');
          setSuccessOpen(true);
          clearForm();
        })
        .catch((error) => {
          setErrorMessage(error);
          setSuccessOpen(false);
        });
    } catch (error) {
      setSuccessOpen(false);
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setFieldErrors(errors);
    }
  };
  const handlePersonEmail = (event) => {
    setpersonEmail(event.target.value);
    fieldErrors.personEmail = '';
  };
  const handleCountryId = (event) => {
    setCountryId(event.target.value);
    fieldErrors.countryId = '';
  };
  const handleEmailAddress = (event) => {
    setEmailAddress(event.target.value);
    fieldErrors.emailAddress = '';
  };

  const handleContactPerson = (event) => {
    setContactPerson(event.target.value);
    fieldErrors.contactPerson = '';
  };
  const handleContactName = (event) => {
    setContactName(event.target.value);
    fieldErrors.contactName = '';
  };

  const handleContactNumber = (value) => {
    fieldErrors.contactNumber = '';

    if (value) {
      if (/^\+[1-9]\d{1,14}$/.test(value)) {
        setContactNumber(value);
        setPhoneError('');
      } else {
        setContactNumber(null);
        setPhoneError(
          'Invalid phone number format. Include country code (+) and digits.'
        );
      }
    } else {
      // Clear any previous error and reset the phone number here
      setContactNumber(null);
      setPhoneError('');
    }
  };
  const clearForm = () => {
    setContactName('');
    setEmailAddress('');
    setpersonEmail('');
    setAddress('');
    setWebsite('');
    setCountryId(0);
    setTypeOfContact(0);
    setServiceType(0);
    setIsMeetingAtEvent(false);
    setEventName('');
    setEventLocation('');
    setEventYear(0);
    setIsEventAttendee(false);
    setContactPerson('');
    setDesignation('');
    setContactNumber('');
    setFollowUpMeetingNote('');
    setDescription('');
    setDepartment('');
  };
  const successDialogStyles = {
    backgroundColor: 'bg-green-400',
  };
  return (
    <div className="bg-gradient-to-r from-sky-100 to-sky-200 min-h-screen flex justify-center items-top">
      <Container maxWidth="md" className="pt-4 pb-2">
        <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow-lg rounded-lg sm:p-10">
          <h2 className="text-3xl text-center font-bold mb-6 mt-0">
            Add Contact
          </h2>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Business / Contact Name"
                variant="outlined"
                fullWidth
                required
                value={contactName}
                onChange={handleContactName}
                error={!!fieldErrors.contactName}
                helperText={fieldErrors.contactName}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Person Email (For Communication)"
                variant="outlined"
                fullWidth
                required
                value={personEmail}
                onChange={handlePersonEmail}
                error={!!fieldErrors.personEmail}
                helperText={fieldErrors.personEmail}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth error={!!fieldErrors.typeOfContact}>
                <TextField
                  required
                  label="Type of Contact"
                  variant="outlined"
                  select
                  fullWidth
                  value={typeOfContact}
                  onChange={handleTypeOfContactChange}
                >
                  <MenuItem value={0}>Select Type of Contact</MenuItem>
                  {CONTACT_TYPE.map((contact) => (
                    <MenuItem key={contact.id} value={contact.id}>
                      {contact.type}
                    </MenuItem>
                  ))}
                </TextField>
                {fieldErrors.typeOfContact && (
                  <FormHelperText>{fieldErrors.typeOfContact}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth error={!!fieldErrors.serviceType}>
                <TextField
                  required
                  label="Service Type"
                  variant="outlined"
                  select
                  fullWidth
                  value={serviceType}
                  onChange={handleServiceTypeChange}
                >
                  <MenuItem value={0}>Select Service Type</MenuItem>
                  {CONTACT_SERVICE_TYPE.map((service) => (
                    <MenuItem key={service.id} value={service.id}>
                      {service.type}
                    </MenuItem>
                  ))}
                </TextField>
                {fieldErrors.serviceType && (
                  <FormHelperText>{fieldErrors.serviceType}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                required
                select
                fullWidth
                label="Country"
                value={countryId}
                error={!!fieldErrors.countryId}
                helperText={fieldErrors.countryId}
                onChange={handleCountryId}
                variant="outlined"
                type="number"
              >
                {Countries.filter((item)=>item.id!=0).map((charge) => (
                  <MenuItem key={charge.id} value={charge.id}>
                    {charge.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                //required
                value={emailAddress}
                onChange={handleEmailAddress}
                error={!!fieldErrors.emailAddress}
                helperText={fieldErrors.emailAddress}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                //required
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                // error={!!errorMessage['Address']}
                // helperText={errorMessage['Address']}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Website"
                variant="outlined"
                fullWidth
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                // error={!!errorMessage['Website']}
                // helperText={errorMessage['Website']}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Event Name / Source"
                variant="outlined"
                fullWidth
                //required
                value={eventName}
                onChange={(e) => setEventName(e.target.value)}
                // error={!!errorMessage['EventName']}
                // helperText={errorMessage['EventName']}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Event Location"
                variant="outlined"
                fullWidth
                //required
                value={eventLocation}
                onChange={(e) => setEventLocation(e.target.value)}
                // error={!!errorMessage['EventLocation']}
                // helperText={errorMessage['EventLocation']}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Person Name"
                variant="outlined"
                fullWidth
                required
                value={contactPerson}
                onChange={handleContactPerson}
                error={!!fieldErrors.contactPerson}
                helperText={fieldErrors.contactPerson}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Designation"
                variant="outlined"
                fullWidth
                value={designation}
                onChange={(e) => setDesignation(e.target.value)}
                // error={!!errorMessage['Designation']}
                // helperText={errorMessage['Designation']}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <PhoneInput
                  className="py-4 border bg-white-100 shadow-sm "
                  placeholder="Contact Number"
                  error={!!fieldErrors.contactNumber}
                  helperText={fieldErrors.contactNumber}
                  value={contactNumber}
                  //required
                  onChange={handleContactNumber}
                />
                {phoneError && (
                  <FormHelperText error>{phoneError}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <TextField
                label="Follow-Up Meeting Note"
                variant="outlined"
                fullWidth
                value={followUpMeetingNote}
                onChange={(e) => setFollowUpMeetingNote(e.target.value)}
                // error={!!errorMessage['FollowUpMeetingNote']}
                // helperText={errorMessage['FollowUpMeetingNote']}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                // error={!!errorMessage['Description']}
                // helperText={errorMessage['Description']}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Department"
                variant="outlined"
                fullWidth
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                // error={!!errorMessage['Department']}
                // helperText={errorMessage['Department']}
              />
            </Grid>

            <Grid item xs={12} className="flex justify-center">
              <Button
                variant="contained"
                onClick={handleSaveContact}
                color="success"
                className="min-w-fit py-2 px-6 mt-6 bg-indigo-500 hover:bg-indigo-600 focus:ring-indigo-400 focus:ring-offset-indigo-200 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                endIcon={<Done />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
      <Dialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          className: successDialogStyles.backgroundColor, // Apply errorDialogStyles here
        }}
      >
        <DialogTitle id="alert-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {successMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  appUserId: state.auth.userId,
});

export default connect(mapStateToProps)(AddContact);
