/* eslint-disable react/jsx-no-target-blank */
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { Typography, Grid, Divider } from '@mui/material';
import { ButtonGroup } from '@mui/material';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Avatar } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { DownloadEmployeeFile } from '../../../../../Util/FileProcessing';
import DescriptionIcon from '@mui/icons-material/Description';
import ConfirmationModal from '../../../../Common/Modal/ConfirmationModal';
import { LoadingButton } from '@mui/lab';
// import {format, addMinutes} from 'date-fns'
import MySnackbarAlert from '../../../../Common/AlertMessage/MySnackbarAlert';
import LoadingView from '../../../../Common/Loading/LoadingView';
import PhotoWidgetDropzone from '../../../../Common/ImageUpload/PhotoWidgetDropzone';
import Agent from '../../../../../api/agent';

const PREFIX = 'EmployeeDocuments';

const classes = {
  size: `${PREFIX}-size`,
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`,
  panelHeader: `${PREFIX}-panelHeader`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.size}`]: {
    width: (props) => theme.spacing(props.size ? props.size : 10),
    height: (props) => theme.spacing(props.size ? props.size : 10),
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.panelHeader}`]: {
    backgroundColor: theme.palette.common.lightBlue,
    minHeight: '0px !important',
    height: 40,
  },
}));

const EmployeeDocuments = ({ employeeId, displayError, isLocked }) => {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [fileSelected, setFileSelected] = useState();
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState();
  const [error, setError] = useState();
  const [recordAddMode, setRecordAddMode] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [fileNameToDelete, setFileNameToDelete] = useState();
  //requirements
  const [requirements, setRequirements] = useState([]);

  //#snackbar stuff
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [alertMsg, setAlertMsg] = useState();
  //#endregion
  // const showAlert = (success) => {
  //   setAlertMsg(success ? 'Successfully actioned' : 'Failed to action');
  //   setOpenSuccess(success);
  //   setOpenFailure(!success);
  // };

  useEffect(async () => {
    Agent.Employees.getFiles(employeeId)
      .then((records) => {
        setFile(records);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        debugger;
      });
    setLoading(false);
  }, []);

  const upload = (file) => {
    const harmful = ['exe', 'pif', 'bat'];
    setFile(file);
    let fileZie = file.size / 1024 / 2024;
    if (fileZie > 2) {
      setError('Error: File size cannot be more than 2 MB');
      return;
    }
    const extension = file.name.split('.').pop();
    if (harmful.includes(extension.toLowerCase())) {
      setError(
        'Error: Unsupported file type. You can only upload a valid document file type'
      );
      return;
    }
    setError(null);
  };
  const completeUpload = (id) => {
    setUpdating(true);
    Agent.Employees.uploadDoc(file, 'test-bucket-mir', 'emp', employeeId)
      .then((response) => {
        setUpdating(false);
        setOpenConfirm(false);
        debugger;
      })
      .catch((error) => {
        debugger;
        console.log(error);
        setUpdating(false);
        setError(
          'Error: Failed to upload. Check file type/size etc and try again!'
        );
      });
  };

  const deleteFile = () => {
    setUpdating(true);
    debugger;
    Agent.Employees.deleteDoc(employeeId, fileNameToDelete)
      .then(() => {
        debugger;
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  return loading ? (
    <LoadingView />
  ) : (
    <Root>
      <Grid container direction="column">
        <Grid item style={{ marginBottom: 10 }}>
          <Typography
            align={requirements.length > 0 ? 'left' : 'center'}
            variant={requirements.length > 0 ? 'h5' : 'h4'}
            color="primary"
          >
            Documents
          </Typography>
        </Grid>
        <Grid item style={{ marginBottom: 30 }} container spacing={2}>
          <Grid item>
            <Button
              color="secondary"
              variant="outlined"
              className={classes.button}
              startIcon={<AddIcon />}
              onClick={() => setRecordAddMode(true)}
            >
              {files.length > 0 ? 'Add Another Document' : 'Add Document'}
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          {files.map((file, index) => (
            <Grid item container key={index} direction="column">
              <Grid item container spacing={6} style={{ marginBottom: 10 }}>
                <Grid
                  item
                  xs={8}
                  container
                  spacing={3}
                  style={{ marginBottom: 0 }}
                >
                  <Grid item xs={2}>
                    <Avatar alt="doc" className={classes.size} variant="square">
                      <DescriptionIcon color="primary" />
                    </Avatar>
                  </Grid>
                  <Grid item xs={10} container direction="column" spacing={1}>
                    <Grid item>
                      <Typography color="secondary">{file.url}</Typography>
                    </Grid>
                    {/* <Grid item>
                      <Typography
                        textAlign="left"
                        as={Button}
                        style={{
                          padding: 0,
                          textDecoration: 'underline',
                          textTransform: 'none',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(file.fileUrl, '_blank');
                        }}
                      >
                        {file.fileNameLocal}
                      </Typography>
                      {file.description && (
                        <Typography>{`Description: ${file.description}`}</Typography>
                      )}
                    </Grid> */}
                    {/* <Grid item style={{ paddingTop: 0 }}>
                      <Typography variant="caption">
                        {`Added On: ${
                          file.addedOn
                            ? format(
                                addMinutes(
                                  new Date(file.addedOn),
                                  -new Date().getTimezoneOffset()
                                ),
                                'MMMM d, yyyy h:mm a'
                              )
                            : format(new Date(), 'MMMM d, yyyy h:mm a')
                        }`}
                      </Typography>
                    </Grid> */}
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <ButtonGroup
                    size="small"
                    color="primary"
                    aria-label="large outlined primary button group"
                  >
                    <LoadingButton
                      loading={loading && fileSelected == file.fileName}
                      onClick={() => {
                        setFileSelected(file.fileName);
                        setLoading(true);
                        DownloadEmployeeFile(
                          'test-bucket-mir',
                          file.fileName
                        ).finally(() => {
                          setLoading(false);
                        });
                      }}
                      startIcon={<CloudDownloadIcon />}
                    >
                      Download
                    </LoadingButton>
                    <Button
                      disabled={error || isLocked}
                      variant="contained"
                      className="bg-rose-400 hover:bg-rose-500 text-white"
                      onClick={() => {
                        setOpenConfirm(true);
                        setFileNameToDelete(file.url);
                      }}
                      startIcon={<DeleteForeverIcon />}
                    >
                      Delete
                    </Button>
                  </ButtonGroup>
                </Grid>
              </Grid>
              <Grid item style={{ marginBottom: 10 }}>
                <Divider />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={deleteFile}
        confirmText="Confirm Deletion"
        content="Are you sure that you would like to delete this document?"
      />
      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
          autoHideDuration={1000}
        />
      )}
      {recordAddMode && (
        <Grid container direction={'column'}>
          <Grid item xs={6}>
            <PhotoWidgetDropzone
              crop={false}
              upload={upload}
              message="Drop your document here"
            />
          </Grid>
          <Grid item>
            <LoadingButton loading={updating} onClick={completeUpload}>
              Upload
            </LoadingButton>
          </Grid>
        </Grid>
      )}
    </Root>
  );
};

export default EmployeeDocuments;
