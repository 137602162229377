import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import StudentSummary from './StudentSummary';
import WorkGroupSummary from './WorkGroupSummary';
import Agent from '../../../api/agent';

const StudentReport = () => {
  const [admins, setAdmins] = useState([]);

  useEffect(() => {
    Agent.Cosmos.getUsers().then((users) => {
      setAdmins(
        users.filter((item) => {
          return (
            (item.usertype == 1 || item.usertype == 2 || item.usertype == 3) &&
            !item.isdisabled
          );
        })
      );
    });
  }, []);
  return (
    <>
      <Box sx={{ width: 'full', borderRadius: '10px', height: '100%', mb: 2 }}>
        <StudentSummary />
      </Box>
      <Box sx={{ pb: 1 }}></Box>
    </>
  );
};

export default StudentReport;
