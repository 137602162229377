import { FormHelperText, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const PREFIX = 'MyInput';

const classes = {
  inputContainer: `${PREFIX}-inputContainer`,
  label: `${PREFIX}-label`,
  error: `${PREFIX}-error`,
};

const Root = styled('div')(() => ({
  [`&.${classes.inputContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 15px',
  },

  [`& .${classes.label}`]: {
    margin: '0 0 6px 0',
    fontSize: '1.1rem',
  },

  [`& .${classes.error}`]: {
    color: 'red',
  },
}));

function MyInput(props) {
  const {
    label,
    type = 'text',
    name,
    handleChange,
    handleBlur,
    errorMessage,
    isInvalid,
    value,
    rows = 1,
  } = props;

  return (
    <Root className={classes.inputContainer}>
      <TextField
        type={type}
        label={label}
        name={name}
        multiline={rows > 1}
        rows={rows}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        onBlur={(e) => {
          handleBlur(e.target.name);
        }}
        autoComplete="new-password"
        value={value}
        variant="outlined"
        size="small"
        error={errorMessage && isInvalid}
        {...props}
      />
      {/* {errorMessage && isInvalid && (
        <span className={classes.error}>{errorMessage}</span>
      )} */}
      {errorMessage && isInvalid && (
        <FormHelperText className={classes.error}>
          {errorMessage}
        </FormHelperText>
      )}
    </Root>
  );
}

export default React.memo(MyInput);
