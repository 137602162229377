/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useParams } from 'react-router';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  Box,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
} from '@mui/material';
import LoadingView from '../../Common/Loading/LoadingView';
import Agent from '../../../api/agent';
import { newUniversity } from '../Constants/Constants';
import parse from 'html-react-parser';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { UNI_TYPES } from '../../../Util/Constants';
import { Countries } from '../../../Util/Util';

const PREFIX = 'University';
const classes = {
  // courseArea: `${PREFIX}-courseArea`,
  // studentArea: `${PREFIX}-studentArea`,
};
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  [`& .${classes.chip}`]: {
    marginTop: 10,
    marginRight: 5,
  },
}));

const UniversitySummary = ({ id, setShow }) => {
  const [open, setOpen] = useState(true);
  // const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [university, setUniversity] = useState();

  useEffect(() => {
    if (id && id != 0) {
      Agent.Universities.getUniversity(id)
        .then((uni) => {
          setUniversity(uni);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          debugger;
        });
    } else {
      setUniversity(newUniversity);
      setLoading(false);
    }
  }, []);

  function handleClose() {
    setOpen(false);
    setShow(false);
  }

  return loading ? (
    <LoadingView />
  ) : (
    <Root>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperComponent}
        maxWidth="xl"
        fullWidth
      >
        <>
          <DialogTitle
            style={{ cursor: 'move', paddingTop: 0, paddingBottom: 0 }}
            id="draggable-dialog-title"
          >
            <Grid container justifyContent="flex-end">
              <Grid item xs={1}>
                <IconButton
                  style={{ float: 'right' }}
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleClose}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                container
                direction="column"
                spacing={1}
              >
                <Grid item>
                  {' '}
                  <Typography textAlign="center" variant="h5" color="secondary">
                    {university.name}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography textAlign="center">{`${university.city}, ${
                    Countries.find(({ id }) => id === university.countryId)
                      ?.name
                  } `}</Typography>
                </Grid>
                <Grid item>
                  <Typography textAlign="center">{`Type: ${
                    UNI_TYPES.find(({ id }) => id == university.type).type
                  } `}</Typography>
                </Grid>
                {university.fileUrl && (
                  <Grid item style={{ margin: '0 auto', paddingTop: '20px' }}>
                    {' '}
                    <img
                      alt="uni logo"
                      height={200}
                      width={200}
                      src={university.fileUrl}
                      // style={{ backgroundColor: 'white' }}
                    />
                  </Grid>
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={8} style={{ paddingLeft: '20px' }}>
                <hr />
                <Grid container direction="column">
                  <Grid item>
                    <Typography color="primary" variant="h6">
                      Overview
                    </Typography>
                  </Grid>
                  <Grid item style={{ maxHeight: '800px', overflowY: 'auto' }}>
                    {university.summary && (
                      <Typography>{parse(university.summary)}</Typography>
                    )}
                  </Grid>
                </Grid>
                {university.isScholarshipAvailable && (
                  <Grid
                    item
                    container
                    direction="column"
                    style={{ marginTop: '20px' }}
                  >
                    <Typography color="primary" variant="h6">
                      Scholarship
                    </Typography>
                    <Typography>
                      {university.scholarshipInfo
                        ? parse(university.scholarshipInfo)
                        : 'Scholarship available'}
                    </Typography>
                  </Grid>
                )}
                {/* {university.testList.length > 0 && (
    <Grid item container direction="column" style={{ marginTop: '20px' }}>
      <Typography color="primary" variant="h6">
        Accepted English Tests
      </Typography>
      <Grid item container spacing={1}>
        {university.testList.map((test) => (
          <Grid item key={test}>
            <Chip color="primary" label={test} className={classes.chip} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )} */}
              </Grid>
            </Grid>
          </DialogContent>
        </>
      </Dialog>
    </Root>
  );
};

export default UniversitySummary;