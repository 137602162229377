import { Grid } from '@mui/material';
import React from 'react';
import Course from './Course';

const CourseList = ({ courses }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <div className="flex flex-col gap-4 ">
          {courses.map((course, index) => (
            <div key={index} className="bg-slate-200 rounded-lg shadow-md p-4">
              <Course course={course} />
            </div>
          ))}
        </div>
      </Grid>
    </Grid>
  );
};

export default CourseList;
