/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState, useEffect } from 'react';
import { MUI_LICENSE_KEY } from '../../Util/Constants';
import { LicenseInfo, DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);

// import {withRouter} from 'react-router-dom' // Commented out due to migration concerns.
import { Button, Typography, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';

import AvatarMui from '../Common/Image/AvatarMui';
import ManageUser from './User/Manage/ManageUser';
import AddMessage from '../Messages/AddMessage';
import EmailIcon from '@mui/icons-material/Email';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MyTooltip from '../Common/Tooltip/MyTooltip';
import Task from '../Tasks/Task';
import Agent from '../../api/agent';
import CompanyDetails from '../Profile/CompanyDetails';
import { Block, CheckCircle } from '@mui/icons-material';

const UserList = ({ users, appUserId, role }) => {
  const [openEmployee, setOpenEmployee] = useState(false);
  const [addTask, setAddTask] = useState(false);
  const [taskRecord, setTaskRecord] = useState();
  const [manageMode, setManageMode] = useState(false);
  const [currentUser, setCurrentUser] = useState();
  const [openAddMessage, setOpenAddMessage] = useState(false);
  const [to, setTo] = useState();
  const [cosmosAdminUsers, setCosmosAdminUsers] = useState([]);
  const [loading, setLoading] = useState(true); 

  useEffect(() => {
    setLoading(true); 
    Agent.Cosmos.getUsers()
      .then((usrs) => {
        setCosmosAdminUsers(
          usrs.filter((item) => item.usertype === 1 || item.usertype === 2 || item.usertype === 3)
        );
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false); 
      });
  }, []);

  const userList = users || [];
  console.log(users)

  const manageUser = (user) => {
    setManageMode(true);
    setCurrentUser(user.row);
  };

  const addMessage = (adminId, name) => {
    setTo({ id: adminId, name });
    setOpenAddMessage(true);
  };

  const userRows = [];
  const userColumns = [
    {
      field: 'name',
      headerName: 'User',
      width: 350,
      renderCell: (params) => (
        <>
          <AvatarMui imageUrl={params.row.image} fullName={params.value} size={5} />
          <span onClick={() => setOpenEmployee(true)} style={{ marginLeft: '10px' }}>
            {params.value}
          </span>
        </>
      ),
    },
    {
      field: 'email',
      headerName: 'Login Email',
      width: 340,
      renderCell: (params) => (
        <MyTooltip title={params.row.email}>
          <Typography>{params.row.email}</Typography>
        </MyTooltip>
      ),
    },
    {
      field: 'isDisabled',
      headerName: 'Active Status',
      headerAlign: 'center',
      align: 'center',
      width: 150,
      renderCell: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {params.row.isDisabled ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Block color="error" />
              <span style={{ marginLeft: 8, color: '#f44336', fontWeight: 'bold' }}>Inactive</span>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle color="success" />
              <span style={{ marginLeft: 8, color: '#4caf50', fontWeight: 'bold' }}>Active</span>
            </div>
          )}
        </div>
      ),
    },
    {
      field: 'role',
      headerName: 'Role',
      headerAlign: 'center',
      align: 'center',
      width: 150,
    },
    {
      field: 'noOfCountries',
      headerName: 'Agent Countries',
      headerAlign: 'center',
      align: 'center',
      width: 120,
    },
    {
      field: 'noOfReferralCountries',
      headerName: 'Referral Countries',
      headerAlign: 'center',
      align: 'center',
      width: 130,
    },
    {
      field: 'noOfLeadCountries',
      headerName: 'Lead Countries',
      headerAlign: 'center',
      align: 'center',
      width: 120,
    },
    {
      field: 'agentCount',
      headerName: 'Agents',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      renderCell: (params) => (params.value === 0 ? ' ' : params.value),
    },
    {
      field: 'requireVerification',
      headerName: 'Require Verification',
      headerAlign: 'center',
      align: 'center',
      width: 180,
      renderCell: (params) => (
        <Typography>{params.row.requireVerification ? 'Yes' : 'No'}</Typography>
      ),
    },
    {
      field: 'verificationPhoneNumber',
      headerName: 'Verification Phone',
      headerAlign: 'center',
      align: 'center',
      width: 200,
    },
    {
      field: 'verificationEmailAddress',
      headerName: 'Verification Email',
      headerAlign: 'center',
      align: 'center',
      width: 240,
    },
    {
      field: 'manage',
      headerName: 'Manage',
      width: 110,
      renderCell: (params) => (
        <strong>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className="bg-sky-400"
            onClick={() => manageUser(params)}
          >
            Manage
          </Button>
        </strong>
      ),
    },
    {
      field: 'message',
      headerName: 'Message',
      width: 130,
      renderCell: (params) => (
        <strong>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<EmailIcon />}
            onClick={() => addMessage(params.row.appUserId, params.row.name)}
            disabled={params.row.appUserId === appUserId}
          >
            Message
          </Button>
        </strong>
      ),
    },
    {
      field: 'appUserId',
      headerName: 'Task',
      width: 130,
      renderCell: (params) => (
        <strong>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<AssignmentIcon />}
            onClick={() => {
              setTaskRecord({
                id: 0,
                admin1AppUserDetailsId: params.row.appUserDetailsId,
              });
              setAddTask(true);
            }}
          >
            Task
          </Button>
        </strong>
      ),
    },
  ];

  // Populate userRows from userList.
  userList.forEach((user) => {
    userRows.push({
      id: user.id,
      appUserId: user.appUserId,
      appUserDetailsId: user.appUserDetailsId,
      name: user.name,
      image: user.image,
      email: user.email,
      role: user.role,
      isDisabled: user.isDisabled,
      noOfCountries: user.countries.length === 0 ? '' : user.countries.length,
      noOfLeadCountries: user.leadCountries.length === 0 ? '' : user.leadCountries.length,
      noOfReferralCountries: user.referralCountries.length === 0 ? '' : user.referralCountries.length,
      agentCount: user.childUsers.filter((user) => user.childUserType === 5).length,
      requireVerification: user.requireVerification,
      verificationPhoneNumber: user.verificationPhoneNumber,
      verificationEmailAddress: user.verificationEmailAddress,
    });
  });

  // If loading, display the spinner, else display the table
  if (loading) {
    return (
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div style={{ height: 810, width: '100%' }}>
        <DataGridPro
          disableSelectionOnClick
          rows={userRows}
          columns={userColumns}
          components={{
            Toolbar: GridToolbar,
          }}
          hideFooterSelectedRowCount
        />
      </div>

      {manageMode && (
        <ManageUser
          setOpenParent={manageMode}
          currentUser={currentUser}
          cancel={setManageMode}
          allUsers={users}
          loggedInUserRole={role}
        />
      )}
      {openAddMessage && (
        <AddMessage
          open={openAddMessage}
          cancel={setOpenAddMessage}
          agentId={0}
          toId={to.id}
          toName={to.name}
        />
      )}
      {addTask && (
        <Task
          record={taskRecord}
          setAddMode={setAddTask}
          cosmosAdminUsersParent={cosmosAdminUsers}
          path={2}
        />
      )}
      {openEmployee && <CompanyDetails />}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users.filter(
      (user) =>
        user.roleId !== 5 &&
        user.roleId !== 6 &&
        user.roleId !== 9 &&
        user.roleId !== 10
    ),
    appUserId: state.auth.id,
    role: state.auth.role,
  };
};

// export default connect(mapStateToProps, null)(withRouter(UserList))
export default connect(mapStateToProps, null)(UserList);
