// DRY not maintained for Logo and Background Image Logics here due to limited time. Need to be addressed on a later time.
import React, { useEffect, useState } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { LoadingButton } from '@mui/lab';
import { ButtonGroup, Button, IconButton } from '@mui/material';

import PhotoWidgetDropzone from '../../../Common/ImageUpload/PhotoWidgetDropzone';
import MySnackbarAlert from '../../../Common/AlertMessage/MySnackbarAlert';
import ConfirmationModal from '../../../Common/Modal/ConfirmationModal';
import { DownloadCloudFile } from '../../../../Util/FileProcessing';
import { ResizeFile } from '../../../../Util/ResizeFile';
import Agent from '../../../../api/agent';

const UniversityImages = ({ profile }) => {
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState();
  const [logoTemp, setLogoTemp] = useState();
  const [coverImage, setCoverImage] = useState();
  const [coverImageTemp, setCoverImageTemp] = useState();
  // const [imageTemp, setImageTemp] = useState();
  const [openConfirm, setOpenConfirm] = useState();
  const [isLogo, setIsLogo] = useState(true);
  //#snackbar stuff
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [alertMsg, setAlertMsg] = useState();
  //#end region

  useEffect(() => {
    if (profile.fileName && profile.fileUrl) {
      setLogo({
        fileName: profile.fileName,
        fileNameLocal: profile.fileNameLocal,
        fileUrl: profile.fileUrl,
        containerName: profile.containerName,
        fileSize: profile.fileSize,
      });
    }
    if (profile.fileNameBackground && profile.fileUrlBackground) {
      setCoverImage({
        fileName: profile.fileNameBackground,
        fileNameLocal: profile.fileNameLocalBackground,
        fileUrl: profile.fileUrlBackground,
        containerName: profile.containerNameBackground,
        fileSize: profile.fileSizeBackground,
      });
    }
  }, []);

  //Logo Logic
  const deleteLogo = () => {
    // logic to delete logo
    isLogo
      ? Agent.CloudFiles.delete(logo.fileName, 0, 0, 0, profile.id)
          .then(() => {
            setLogoTemp(null);
            setLogo(null);
            setOpenConfirm(false);
            setAlert('Logo deleted successfully');
          })
          .catch((error) => {
            console.log(error);
            setAlert(
              'Failed to delete logo. Refresh the page and try again',
              false
            );
          })
      : Agent.CloudFiles.delete(coverImage.fileName, 0, 0, 0, profile.id)
          .then(() => {
            setCoverImageTemp(null);
            setCoverImage(null);
            setOpenConfirm(false);

            setAlert('Cover Image deleted successfully');
          })
          .catch((error) => {
            console.log(error);
            setAlert(
              'Failed to delete Cover Image. Refresh the page and try again',
              false
            );
          });

    // logic to delete cover image
  };

  // Logo Logic
  const uploadLogo = (file) => {
    let fileSize = file.size / 1024 / 2024;
    if (fileSize > 1) {
      return;
    }
    ResizeFile(file, 250, 250, 250, 250, 'png').then(setTempImageState);
  };
  const setTempImageState = (file) => {
    setLogoTemp(file);
  };
  const deleteTempLogo = () => {
    setLogoTemp(null);
    setLogo(null);
  };

  // Cover Image Logic
  const uploadCoverImage = (file) => {
    let fileSize = file.size / 1024 / 2024;
    if (fileSize > 1) {
      return;
    }
    ResizeFile(file, 800, 200, 800, 200, 'png').then(setTempCoverImageState);
  };
  const setTempCoverImageState = (file) => {
    setCoverImageTemp(file);
  };
  const deleteTempCoverImage = () => {
    setCoverImageTemp(null);
    setCoverImage(null);
  };

  // Logo Logic
  const saveLogo = () => {
    Agent.CloudFiles.upload(logoTemp, 21, 0, 0, '', 0, profile.id, 0)
      .then((file) => {
        setLogoTemp(null);
        setLogo({
          fileName: file.blobName,
          fileNameLocal: file.fileNameLocal,
          fileUrl: file.blobUri,
          containerName: file.containerName,
          fileSize: file.fileSize,
        });
        setAlert('Logo saved successfully');
      })
      .catch((error) => {
        console.log(error);
        setAlert('Failed to save logo. Check the logo and try again', false);
      });
  };

  // Cover Image Logic
  const saveCoverImage = () => {
    Agent.CloudFiles.upload(coverImageTemp, 22, 0, 0, '', 0, profile.id, 0)
      .then((file) => {
        setCoverImageTemp(null);
        setCoverImage({
          fileName: file.blobName,
          fileNameLocal: file.fileNameLocalBackground,
          fileUrl: file.blobUri,
          containerName: file.containerNameBackground,
          fileSize: file.fileSizeBackground,
        });
        setAlert('Cover Image saved successfully');
      })
      .catch((error) => {
        console.log(error);
        setAlert(
          'Failed to save Cover Image. Check the logo and try again',
          false
        );
      });
  };

  // Logo Logic
  const setAlert = (msg = 'Successfully updated', isSuccess = true) => {
    setAlertMsg(msg);
    setOpenSuccess(isSuccess);
    setOpenFailure(!isSuccess);
  };

  return (
    <div className="p-2 text-gray-700 bg-gray-200 ">
      <div className="w-full border p-3 rounded-lg shadow-md text-center ">
        <div className="pt-6">
          <div className="font-medium text-lg xl:text-xl text-indigo-600 pb-2 text-center">
            Please note the following:
          </div>
          <div className="text-sm xl:text-base  text-center">
            <div>
              &#8226; Upload only <b>PNG</b> file with transparent background
            </div>
            <div>
              &#8226; File must be <b>250px</b> by <b>250px</b> (square)
            </div>
            <div>
              {' '}
              &#8226; File should not be more than <b>60KB</b>
            </div>
          </div>
        </div>
        <div className="flex gap-4 py-8 max-h-[300px]">
          <div className="w-full drop-shadow-md flex flex-col gap-2 lg:flex-row xl:flex-row">
            <div className="text-center">
              <div className="text-2xl font-semibold text-indigo-600 ">
                University Logo
              </div>
              <div className="text-lg font-medium ">
                Please upload a uniersity logo
              </div>

              <PhotoWidgetDropzone
                className="shadow-md "
                crop={false}
                upload={uploadLogo}
                message="Drop university logo here"
                height={180}
                disabled={logo || logoTemp}
              />
            </div>
            <div>
              <LoadingButton
                variant="contained"
                className="bg-indigo-600 hover:bg-indigo-700 text-lg"
                fullWidth
                size="large"
                disabled={!logoTemp}
                onClick={saveLogo}
              >
                Save Logo
              </LoadingButton>
              {/* </div>
          </div>
          <div className="w-1/5">
            <div className=" flex flex-col items-center"> */}
              {(logo || logoTemp) && (
                <div className="flex flex-col items-center ">
                  <img
                    className="rounded-lg "
                    alt="student"
                    src={
                      logoTemp ? URL.createObjectURL(logoTemp) : logo.fileUrl
                    }
                    style={{
                      maxHeight: '170px',
                      maxWidth: '200px',
                      objectFit: 'contain',
                    }}
                  />
                  <div className="font-medium ">{`File Size: ${
                    logoTemp ? parseInt(logoTemp.size / 1024) : logo.fileSize
                  } KB`}</div>
                </div>
              )}

              <ButtonGroup
                size="small"
                className="flex justify-center items-center"
              >
                {logo && (
                  <>
                    <LoadingButton
                      variant="text"
                      size="large"
                      loading={loading}
                      startIcon={<CloudDownloadIcon />}
                      onClick={() => {
                        setLoading(true);
                        DownloadCloudFile(
                          logo.fileNameLocal,
                          logo.fileName,
                          logo.containerName
                        ).finally(() => {
                          setLoading(false);
                        });
                      }}
                    ></LoadingButton>
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        setIsLogo(true);
                        setOpenConfirm(true);
                      }}
                      color="secondary"
                      size="large"
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </>
                )}
                {logoTemp && (
                  <div>
                    <Button
                      onClick={deleteTempLogo}
                      variant="contained"
                      className="bg-indigo-500 hover:bg-indigo-600"
                    >
                      CHANGE
                    </Button>
                  </div>
                )}
              </ButtonGroup>
            </div>
          </div>
        </div>
      </div>

      {/* Cover Image Logic */}

      {/* <div className="p-2 mt-12 text-gray-700 bg-gray-200"> */}

      <div className="w-full border p-3 mt-64 rounded-lg shadow-md lg:mt-0 xl:mt-0">
        <div className="pt-6">
          <div className="font-medium text-lg xl:text-xl text-indigo-600 pb-2 text-center">
            Please note the following:
          </div>
          <div className="text-sm xl:text-base text-center">
            <div>
              &#8226; Upload only <b>PNG</b> or <b>JPG</b> file
            </div>
            <div>
              &#8226; File must be <b>900px</b> by <b>285px</b> (wide)
            </div>
            <div>
              {' '}
              &#8226; File should not be more than <b>600KB</b>
            </div>
          </div>
        </div>
        <div className="w-full mt-6 drop-shadow-md flex flex-col gap-2 lg:flex-row xl:flex-row">
          <div className="text-center">
            <div className="text-2xl font-semibold text-indigo-600 ">
              University Cover Image
            </div>
            <div className="text-lg font-medium ">
              Please upload a university cover Image
            </div>

            <PhotoWidgetDropzone
              className="shadow-md "
              crop={false}
              upload={uploadCoverImage}
              message="Drop university Cover Image here"
              height={180}
              disabled={coverImage || coverImageTemp}
            />
          </div>
          <div>
            <LoadingButton
              variant="contained"
              className="bg-indigo-600 hover:bg-indigo-700 text-lg"
              fullWidth
              size="large"
              disabled={!coverImageTemp}
              onClick={saveCoverImage}
            >
              Save Cover Image
            </LoadingButton>
            {/* </div>
          </div>
          <div className="w-1/5">
            <div className=" flex flex-col items-center"> */}
            {(coverImage || coverImageTemp) && (
              <div className="flex flex-col items-center ">
                <img
                  className="rounded-lg "
                  alt="student"
                  src={
                    coverImageTemp
                      ? URL.createObjectURL(coverImageTemp)
                      : coverImage.fileUrl
                  }
                  style={{
                    maxHeight: '170px',
                    maxWidth: '200px',
                    objectFit: 'contain',
                  }}
                />
                <div className="font-medium ">{`File Size: ${
                  coverImageTemp
                    ? parseInt(coverImageTemp.size / 1024)
                    : coverImage.fileSize
                } KB`}</div>
              </div>
            )}

            <ButtonGroup
              size="small"
              className="flex justify-center items-center"
            >
              {coverImage && (
                <>
                  <LoadingButton
                    variant="text"
                    size="large"
                    loading={loading}
                    startIcon={<CloudDownloadIcon />}
                    onClick={() => {
                      setLoading(true);
                      DownloadCloudFile(
                        coverImage.fileNameLocal,
                        coverImage.fileName,
                        coverImage.containerName
                      ).finally(() => {
                        setLoading(false);
                      });
                    }}
                  ></LoadingButton>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setIsLogo(false);
                      setOpenConfirm(true);
                    }}
                    color="secondary"
                    size="large"
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </>
              )}
              {coverImageTemp && (
                <div>
                  <Button
                    onClick={deleteTempCoverImage}
                    variant="contained"
                    className="bg-indigo-500 hover:bg-indigo-600"
                  >
                    CHANGE
                  </Button>
                </div>
              )}
            </ButtonGroup>
          </div>
        </div>



      </div>

      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={() => deleteLogo(isLogo)}
        confirmText="Confirm Action"
        content={`Are you sure that you would like to delete the ${
          isLogo === true ? 'logo' : 'cover Image'
        }?`}
      />

      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
        />
      )}
    </div>
  );
};

export default UniversityImages;
