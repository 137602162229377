/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Draggable from 'react-draggable';
import {
  CONTACT_SERVICE_TYPE,
  CONTACT_TYPE,
  contactEventYear,
// eslint-disable-next-line import/namespace
} from '../../../Util/Constants';
import Agent from '../../../api/agent';
import { Countries } from '../../../Util/Util';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const ContactManagementAssign = ({
  users,
  role,
  cancel,
  contact,
  leadEvent,
  action,
}) => {
  console.log('contact', contact);
  const [ready, setReady] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [countryIdChanged, setCountryIdChanged] = useState(false);
  const [typeOfContactChanged, setTypeOfContactChanged] = useState(false);
  const [serviceTypeChanged, setServiceTypeChanged] = useState(false);
  const [openAssess1, setOpenAssess1] = useState(false);
  const [assessmentRecord1, setAssessmentRecord1] = useState(
    users.find(({ id }) => id == (contact ? contact.assessmentTeamId1 : null))
  );
  const [assessmentTeamChanged1, setAssessmentTeamChanged1] = useState(false);
  const [openAssess2, setOpenAssess2] = useState(false);
  const [assessmentRecord2, setAssessmentRecord2] = useState(
    users.find(({ id }) => id == (contact ? contact.assessmentTeamId2 : null))
  );
  const [assessmentTeamChanged2, setAssessmentTeamChanged2] = useState(false);
  const [openAssess3, setOpenAssess3] = useState(false);
  const [assessmentRecord3, setAssessmentRecord3] = useState(
    users.find(({ id }) => id == (contact ? contact.assessmentTeamId3 : null))
  );
  const [assessmentTeamChanged3, setAssessmentTeamChanged3] = useState(false);
  const [openAssess4, setOpenAssess4] = useState(false);
  const [openAssess5, setOpenAssess5] = useState(false);
  const [openAssess6, setOpenAssess6] = useState(false);
  const [openAssess7, setOpenAssess7] = useState(false);

  const [assessmentRecord4, setAssessmentRecord4] = useState(
    users.find(({ id }) => id == (contact ? contact.assessmentTeamId4 : null))
  );
  const [assessmentTeamChanged4, setAssessmentTeamChanged4] = useState(false);

  const [assessmentRecord5, setAssessmentRecord5] = useState(
    users.find(({ id }) => id == (contact ? contact.assessmentTeamId5 : null))
  );
  const [assessmentTeamChanged5, setAssessmentTeamChanged5] = useState(false);

  const [assessmentRecord6, setAssessmentRecord6] = useState(
    users.find(({ id }) => id == (contact ? contact.assessmentTeamId6 : null))
  );

  const [assessmentTeamChanged6, setAssessmentTeamChanged6] = useState(false);

  const [assessmentRecord7, setAssessmentRecord7] = useState(
    users.find(({ id }) => id == (contact ? contact.assessmentTeamId7 : null))
  );

  const [assessmentTeamChanged7, setAssessmentTeamChanged7] = useState(false);

  const [countryId, setCountryId] = useState(contact ? contact.countryId : 0);
  const [typeOfContact, setTypeOfContact] = useState(
    contact ? contact.typeOfContact : 0
  );
  const [serviceType, setServiceType] = useState(
    contact ? contact.serviceType : 0
  );
  const [eventYear, setEventYear] = useState(
    contact ? contact.eventYear : 0
  );
  const [eventYearChanged, setEventYearChanged] = useState(false);

  const [contactName, setContactName] = useState(
    contact?.contactName ? contact?.contactName : ''
  );
  const [contactNameChanged, setContactNameChanged] = useState(false);
  
  const [id, setId] = useState(leadEvent ? leadEvent.id : '');

  const handleClose = () => {
    setDialogOpen(false);
    cancel(false);
  };

  // function formatDateToISO(dateString) {
  //   if (!dateString) return 'null';

  //   const parsedDate = new Date(dateString);

  //   if (isNaN(parsedDate)) {
  //     // Handle invalid date strings
  //     return null;
  //   }

  //   return parsedDate.toISOString().split('T')[0];
  // }

  const [offices, setOffices] = useState([]);
 
  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);
  const confirm = () => {
    //need to check first if agent has changed. if not stick to original value.
    //because if some users don't have access to assigned agents (for any reason), the system won't find the agent to pass it back to the caller and therefore will be null
    action({
      id: id ? id : '',
      contactName: contactName ? contactName : '',
      ContactNameChanged: contactNameChanged,
      countryId: countryId ? parseInt(countryId) : null,
      countryIdChanged: countryIdChanged,
      typeOfContact: typeOfContact ? parseInt(typeOfContact) : null,
      typeOfContactChanged: typeOfContactChanged,
      serviceType: serviceType ? parseInt(serviceType) : null,
      eventYear: eventYear? parseInt(eventYear): 0,
      eventYearChanged: eventYearChanged,
      serviceTypeChanged: serviceTypeChanged,
      assessmentTeamChanged1: assessmentTeamChanged1,
      AssessmentTeamId1: assessmentRecord1?.id,
      assessmentTeamChanged2: assessmentTeamChanged2,
      AssessmentTeamId2: assessmentRecord2?.id,
      assessmentTeamChanged3: assessmentTeamChanged3,
      AssessmentTeamId3: assessmentRecord3?.id,
      assessmentTeamChanged4: assessmentTeamChanged4,
      AssessmentTeamId4: assessmentRecord4?.id,
      assessmentTeamChanged5:assessmentTeamChanged5,
      AssessmentTeamId5:assessmentRecord5?.id,
      assessmentTeamChanged6:assessmentTeamChanged6,
      AssessmentTeamId6:assessmentRecord6?.id,
      assessmentTeamChanged7:assessmentTeamChanged7,
      AssessmentTeamId7:assessmentRecord7?.id,
    });
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        open={dialogOpen}
        maxWidth="sm"
        fullWidth
        PaperComponent={PaperComponent}
      >
        <BootstrapDialogTitle onClose={handleClose} style={{ cursor: 'move' }}>
          <Typography align="center" color="secondary" variant="h6">
            {contactName}
          </Typography>
          <Divider />
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ paddingTop: 4 }}
          >
            {role?.isSuperAdmin && (
              <>
                <br />
                <Grid item xs={2}>
                <TextField
                  fullWidth
                  native
                  size="small"
                  label="Contact Name"
                  value={contactName}
                  onChange={(e) => {
                    setContactName(e.target.value);
                    setContactNameChanged(true);
                    setReady(true);
                  }}
                />
              </Grid>

                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel> Country</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Country"
                      value={countryId}
                      onChange={(e) => {
                        setCountryId(e.target.value);
                        setCountryIdChanged(true);
                        setReady(true);
                      }}
                    >
                      <option value={0}>--Select Country-- </option>
                      {Countries.filter((item)=>item.id!=0).map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel> Type Of Contact</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Type Of Contact"
                      value={typeOfContact}
                      onChange={(e) => {
                        setTypeOfContact(e.target.value);
                        setTypeOfContactChanged(true);
                        setReady(true);
                      }}
                    >
                      {/* <option value={0}>Unknown</option> */}
                      {CONTACT_TYPE.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel> Service Type</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Service Type"
                      value={serviceType}
                      onChange={(e) => {
                        setServiceType(e.target.value);
                        setServiceTypeChanged(true);
                        setReady(true);
                      }}
                    >
                      {/* <option value={0}>Unknown</option> */}
                      {CONTACT_SERVICE_TYPE.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel> Event Year</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Event Year"
                      value={eventYear}
                      onChange={(e) => {
                        setEventYear(e.target.value);
                        setEventYearChanged(true);
                        setReady(true);
                      }}
                    >
                      {contactEventYear.map((item) => (
                        <option key={item.id} value={item.year}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item>
                  <Autocomplete
                    fullWidth
                    size="small"
                    open={openAssess1}
                    onOpen={() => {
                      setOpenAssess1(true);
                    }}
                    onClose={() => {
                      setOpenAssess1(false);
                    }}
                    value={assessmentRecord1}
                    onChange={(_, newVal) => {
                      setAssessmentRecord1(newVal);
                      setAssessmentTeamChanged1(true);
                      setReady(true);
                    }}
                    getOptionLabel={(user) => `${user?.name}`}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Grid
                          container
                          direction="column"
                          sx={{
                            backgroundColor: (theme) => theme.palette.grey[200],
                            borderRadius: 1,
                            padding: 1,
                          }}
                          spacing={1}
                        >
                          <Grid item>
                            <Typography color="primary">
                              {option?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    options={users}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Sales users"
                        label="Sales users"
                        InputProps={{
                          ...params.InputProps,
                          //autoComplete: 'new-password',
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    fullWidth
                    size="small"
                    open={openAssess2}
                    onOpen={() => {
                      setOpenAssess2(true);
                    }}
                    onClose={() => {
                      setOpenAssess2(false);
                    }}
                    value={assessmentRecord2}
                    onChange={(_, newVal) => {
                      setAssessmentRecord2(newVal);
                      setAssessmentTeamChanged2(true);
                      setReady(true);
                    }}
                    getOptionLabel={(user) => `${user?.name}`}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Grid
                          container
                          direction="column"
                          sx={{
                            backgroundColor: (theme) => theme.palette.grey[200],
                            borderRadius: 1,
                            padding: 1,
                          }}
                          spacing={1}
                        >
                          <Grid item>
                            <Typography color="primary">
                              {option?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    options={users}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Follow up"
                        label="Follow up"
                        InputProps={{
                          ...params.InputProps,
                          //autoComplete: 'new-password',
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    fullWidth
                    size="small"
                    open={openAssess3}
                    onOpen={() => {
                      setOpenAssess3(true);
                    }}
                    onClose={() => {
                      setOpenAssess3(false);
                    }}
                    value={assessmentRecord3}
                    onChange={(_, newVal) => {
                      setAssessmentRecord3(newVal);
                      setAssessmentTeamChanged3(true);
                      setReady(true);
                    }}
                    getOptionLabel={(user) => `${user?.name}`}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Grid
                          container
                          direction="column"
                          sx={{
                            backgroundColor: (theme) => theme.palette.grey[200],
                            borderRadius: 1,
                            padding: 1,
                          }}
                          spacing={1}
                        >
                          <Grid item>
                            <Typography color="primary">
                              {option?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    options={users}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Team leader"
                        label=" Team leader"
                        InputProps={{
                          ...params.InputProps,
                          //autoComplete: 'new-password',
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    fullWidth
                    size="small"
                    open={openAssess4}
                    onOpen={() => {
                      setOpenAssess4(true);
                    }}
                    onClose={() => {
                      setOpenAssess4(false);
                    }}
                    value={assessmentRecord4}
                    onChange={(_, newVal) => {
                      setAssessmentRecord4(newVal);
                      setAssessmentTeamChanged4(true);
                      setReady(true);
                    }}
                    getOptionLabel={(user) => `${user?.name}`}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Grid
                          container
                          direction="column"
                          sx={{
                            backgroundColor: (theme) => theme.palette.grey[200],
                            borderRadius: 1,
                            padding: 1,
                          }}
                          spacing={1}
                        >
                          <Grid item>
                            <Typography color="primary">
                              {option?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    options={users}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Manager"
                        label="Manager"
                        InputProps={{
                          ...params.InputProps,
                          //autoComplete: 'new-password',
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    fullWidth
                    size="small"
                    open={openAssess5}
                    onOpen={() => {
                      setOpenAssess5(true);
                    }}
                    onClose={() => {
                      setOpenAssess5(false);
                    }}
                    value={assessmentRecord5}
                    onChange={(_, newVal) => {
                      setAssessmentRecord5(newVal);
                      setAssessmentTeamChanged5(true);
                      setReady(true);
                    }}
                    getOptionLabel={(user) => `${user?.name}`}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Grid
                          container
                          direction="column"
                          sx={{
                            backgroundColor: (theme) => theme.palette.grey[200],
                            borderRadius: 1,
                            padding: 1,
                          }}
                          spacing={1}
                        >
                          <Grid item>
                            <Typography color="primary">
                              {option?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    options={users}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Country Manager"
                        label="Country Manager"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    fullWidth
                    size="small"
                    open={openAssess6}
                    onOpen={() => {
                      setOpenAssess6(true);
                    }}
                    onClose={() => {
                      setOpenAssess6(false);
                    }}
                    value={assessmentRecord6}
                    onChange={(_, newVal) => {
                      setAssessmentRecord6(newVal);
                      setAssessmentTeamChanged6(true);
                      setReady(true);
                    }}
                    getOptionLabel={(user) => `${user?.name}`}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Grid
                          container
                          direction="column"
                          sx={{
                            backgroundColor: (theme) => theme.palette.grey[200],
                            borderRadius: 1,
                            padding: 1,
                          }}
                          spacing={1}
                        >
                          <Grid item>
                            <Typography color="primary">
                              {option?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    options={users}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Regional Manager"
                        label="Regional Manager"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    fullWidth
                    size="small"
                    open={openAssess7}
                    onOpen={() => {
                      setOpenAssess7(true);
                    }}
                    onClose={() => {
                      setOpenAssess7(false);
                    }}
                    value={assessmentRecord7}
                    onChange={(_, newVal) => {
                      setAssessmentRecord7(newVal);
                      setAssessmentTeamChanged7(true);
                      setReady(true);
                    }}
                    getOptionLabel={(user) => `${user?.name}`}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Grid
                          container
                          direction="column"
                          sx={{
                            backgroundColor: (theme) => theme.palette.grey[200],
                            borderRadius: 1,
                            padding: 1,
                          }}
                          spacing={1}
                        >
                          <Grid item>
                            <Typography color="primary">
                              {option?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    options={users}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Director"
                        label="Director"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!ready}
            autoFocus
            onClick={confirm}
            variant="contained"
            className="bg-sky-400 hover:bg-sky-500"
          >
            Confirm Selection
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
