import React, { useEffect, useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import Agent from '../../../api/agent';
import { connect } from 'react-redux';
import { Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { COMMUNICATION_CATEGORIES } from '../../../Util/Constants';

const CommunicationHistory = ({ appUserId }) => {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await Agent.Communication.getCommunicationActivities(
        appUserId
      );
      console.log('history', response);
      setData(response);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    Agent.Users.list()
      .then((res) => {
        setUsers(res);
        console.log('user List', res);
      })
      .catch((err) => {
        console.log('err', err);
      });
  }, []);

  const columns = [
    { field: 'communicationName', headerName: 'Communication Name', flex: 1 },
    {
      field: 'communicationCategory',
      headerName: ' Types of Communication',
      align:'center',
      flex: 1,
      valueGetter: (params) => {
        const catagoryId = params.row.communicationCategory;
        const catagoryName = COMMUNICATION_CATEGORIES.find(
          (cat) => cat.id === catagoryId
        );
        return catagoryName ? catagoryName.type : '';
      },
    },
    { field: 'emailSubject', headerName: 'Email Subject', flex: 1 },
    { field: 'fromEmail', headerName: 'From Email', flex: 1 },
    { field: 'numberOfStudents', headerName: 'Number Of Students', flex: 1 },
    {
      field: 'isSent',
      headerName: 'Sent Status',
      flex: 1,
      renderCell: (params) => (
        <>
          {params.value ? (
            <CheckIcon style={{ color: 'green' }} />
          ) : (
            <CloseIcon style={{ color: 'red' }} />
          )}
        </>
      ),
    },
    {
      field: 'sentBy',
      headerName: 'Sent By',
      flex: 1,
      valueGetter: (params) => {
        const createdByUserId = params.row.sentBy;
        const user = users.find((user) => user.appUserId === createdByUserId);
        return user ? user.name : '';
      },
    },

    {
      field: 'sentDatedOn',
      headerName: 'Sent Date',
      flex: 1,
      valueGetter: (params) => {
        const sentDatedOn = params.row.sentDatedOn;
        return new Date(sentDatedOn).toLocaleDateString();
      },
    },
  ];

  const handleNewCommunicationClick = () => {
    navigate('/studentcomsmenu');
  };

  return (
    <div className="w-full h-full">
      <Typography className="text-center text-2xl text-black">
        Students Communication Activity Records
      </Typography>
      <div className="flex justify-end mb-4">
        <button
          className="px-4 py-2 bg-blue-500 text-white rounded"
          onClick={handleNewCommunicationClick}
        >
          New Student Communication
        </button>
      </div>
      <DataGridPro
        autoHeight
        rows={data}
        columns={columns}
        slots={{
          Toolbar: GridToolbar,
        }}
        slotProps={{
          toolbar: {
            highlightSelectedColumns: true,
          },
        }}
        pagination
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(CommunicationHistory);
