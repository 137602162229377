import LoadingView from '../../Common/Loading/LoadingView';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState, useRef } from 'react';
import Agent from '../../../api/agent';
import useWindowSize from '../../Students/common/useWindowSize';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import ReferralFooter from '../Footer/ReferralFooter';
import ReferralFooterMob from '../Footer/ReferralFooterMob';
import { useNavigate } from 'react-router-dom';
import {
 
  Button,
  Grid,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from '@mui/material';
import { DownloadCloudFile } from '../../../Util/FileProcessing';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Divider } from '@mui/material';
import ConfirmationModal from '../../Common/Modal/ConfirmationModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  
  Upload,

} from '@mui/icons-material';
import MyTooltip from '../../Common/Tooltip/MyTooltip';


const Contracts = ({ appUserId }) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmAgreement, setOpenConfirmAgreement] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [isResetAgreement, setIsResetAgreement] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [id, setId] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [additionalEmail, setAdditionalEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [additionalPhoneNumber, setAdditionalPhoneNumber] = useState();
  const [nationality, setNationality] = useState();
  const [postcode, setPostalCode] = useState();
  const [city, setCity] = useState();
  const [streetAddress, setStreetAddress] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [error, setError] = useState();
  const [termsAgreed, setTermsAgreed] = useState(0);
  const [fileSelected, setFileSelected] = useState();
  const [dataPrivacyAgreed, setDataPrivacyAgreed] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referralUserInfo, SetReferralUserInfo] = useState({});
  const [fileNameToDelete, setFileNameToDelete] = useState();
  const [saving, setSaving] = useState(false);
  const [agreementFileName, setAgreementFileName] = useState([]);
  const [agreementSaving, setAgreementSaving] = useState(false);
  const [agreementFile, setAgreementFile] = useState();
  const [agreementEerror, setAgreementError] = useState();
  const agreementFileInput = useRef();
  // console.log('agreementFileName', agreementFileName);
  useEffect(() => {
    let isCancelled = false;
    Agent.Referral.getreferralbyuserId(appUserId)
      .then((ref) => {
        setFirstName(ref.firstName);
        setLastName(ref.lastName);
        setEmail(ref.email);
        setAdditionalEmail(ref.additionalEmail);
        setPhoneNumber(ref.phoneNumber);
        setAdditionalPhoneNumber(ref.additionalPhoneNumber);
        setNationality(ref.nationality);
        setPostalCode(ref.postcode);
        setCity(ref.city);
        setStreetAddress(ref.streetAddress);
        setLoaded(true);
        setId(ref.id);
        setTermsAgreed(ref.termsAgreed);
        setDataPrivacyAgreed(ref.dataPrivacyAgreed);
        //setReferral(ref);
        setFileName(ref.fileName);
        setFileUrl(ref.fileUrl);
        setAgreementFileName(ref.referralCloudFiles);
      })
      .catch((error) => {
        debugger;
        console.log(error);
        setLoaded(true);
      });
    return () => {
      isCancelled = true;
    };
  }, [appUserId, isReset, isResetAgreement]);

  useEffect(() => {
    Agent.Referral.getreferralbyuserId(appUserId).then((data) => {
      SetReferralUserInfo(data);
      console.log('data', data);
    });
  }, [appUserId]);

  const handleFileDelete = (fileName) => {
    setFileName(fileName);
    setOpenConfirm(false);
  };

  const agreementCompleteUpload = (id) => {
    Agent.CloudFiles.upload(agreementFile, 24, 0, 0, '', 0, 0, 0, 0, id)
      .then((response) => {
        setIsResetAgreement(true);
        //setAgreementSaving(false);
        //setSuccessMessage('Upload Successfully');
        // setSuccessOpen(true);
      })
      .catch((error) => {
        console.log(error);
        setAgreementSaving(false);
        setAgreementError(
          'Error: Failed to upload. Check file type/size etc and try again!'
        );
      });
  };
  const uploadAgreementToCloud = () => {
    let fileZie = agreementFile.size / 1024 / 2024;
    if (fileZie > 5) {
      return;
    }
    setAgreementSaving(true);
    if (id > 0) {
      agreementCompleteUpload(id);
    } else {
      var modifiedReferral = {
        documentType: 24,
        referralId: id,
      };

      Agent.Referral.uploadFile(modifiedReferral)
        .then((id) => {
          setAgreementSaving(false);
          //debugger
          //setStudentIdParent(id)
          return id;
        })
        .then(agreementCompleteUpload)
        .catch((error) => {
          setAgreementSaving(false);
          console.log(error);
          setAgreementError(
            'Error: Failed to upload. Check file type/size etc and try again!'
          );
        });
    }
  };
  const deleteFile = () => {
    setIsReset(false);
    Agent.CloudFiles.delete(fileNameToDelete, 0, 0, 0, 0, 0, 0, id)
      .then(() => {
        handleFileDelete(fileNameToDelete);
        setIsReset(true);
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  const upload = (file) => {
    setSaving(false);
    const harmful = ['exe', 'pif', 'bat'];
    setFile(file);
    setIsReset(false);
    let fileZie = file.size / 1024 / 2024;
    if (fileZie > 2) {
      setError('Error: File size cannot be more than 2 MB');
      return;
    }
    console.log('docum file', file);
    const extension = file.name.split('.').pop();
    if (harmful.includes(extension.toLowerCase())) {
      setError(
        'Error: Unsupported file type. You can only upload a valid document file type'
      );
      return;
    }
    setError(null);
  };

  const completeUpload = (id) => {
    Agent.CloudFiles.upload(file, 23, 0, 0, '', 0, 0, 0, 0, id)
      .then((response) => {
        setIsReset(true);
      })
      .catch((error) => {
        console.log(error);
        setError(
          'Error: Failed to upload. Check file type/size etc and try again!'
        );
      });
  };

  const uploadToCloud = () => {
    let fileZie = file.size / 1024 / 2024;
    if (fileZie > 5) {
      return;
    }
    setSaving(true);
    if (id > 0) {
      completeUpload(id);
    } else {
      var modifiedReferral = {
        documentType: 1,
        referralId: id,
      };

      Agent.Referral.uploadFile(modifiedReferral)
        .then((id) => {
          setSaving(false);
          //debugger
          //setStudentIdParent(id)
          return id;
        })
        .then(completeUpload)
        .catch((error) => {
          setSaving(false);
          console.log(error);
          setError(
            'Error: Failed to upload. Check file type/size etc and try again!'
          );
        });
    }
  };

  const handleOnClickUpdate = async () => {
    const modifiedReferral = {
      id,
      firstName,
      lastName,
      email,
      additionalEmail: additionalEmail=='' ? null : additionalEmail,
      phoneNumber,
      additionalPhoneNumber,
      city,
      postcode,
      nationality,
      streetAddress,
      termsAgreed: termsAgreed,
      dataPrivacyAgreed: dataPrivacyAgreed,
    };
    setUpdating(true);
    console.log('modifiedReferral', modifiedReferral);
    await Agent.Referral.updateReferralUser(modifiedReferral)
      .then(async (res) => {
        console.log('respond', res);
        setSuccessMessage('update successfully');
        setSuccessOpen(true);
        setUpdating(false);
        //navigate('/referral/profile');
      })
      .catch((error) => {
        console.log(error);
        setUpdating(false);
        setErrorMessage(error);
      });
  };
  const initialFileUpload = () => {
    agreementFileInput.current.click();
    setAgreementFile();
    setOpenConfirmAgreement(false);
    setIsResetAgreement(false);
    setAgreementSaving(false);
  };

  const successDialogStyles = {
    backgroundColor: 'bg-green-400',
  };
  const errorDialogStyles = {
    backgroundColor: 'bg-red-400',
  };

  const size = useWindowSize();
  console.log('size.width', size.width);
  return (
    <>
      {' '}
      <Dialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        aria-labelledby="success-dialog-title"
        PaperProps={{
          className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
        }}
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>{successMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        aria-labelledby="error-dialog-title"
        PaperProps={{
          className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
        }}
      >
        <DialogTitle id="error-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorMessage('')} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {loaded ? (
        size.width > 844 ? (
          <>
            <div className="flex flex-col gap-4 items-center mb-64">
              <div className="relative min-w-[60%] md:min-w-[70%] xl:min-w-[60%]  justify-center items-center py-6 min-h-full overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
                {/* <div className="relative bg-gray-100 shadow-full px-4 py-2 rounded-lg w-full">
                  <div className="font-semibold pb-0 text-center">
                    <div className="text-2xl">Your Documents*</div>
                    <div className="text-xl text-gray-600">
                      Please upload your passport or NID copy of pdf format here
                    </div>
                    <div className="relative items-start gap-5 mt-0 w-full">
                      <div className="w-full">
                        <div className="relative flex justify-between">
                          <div></div>
                          {!openConfirm && !isReset && file && (
                            <Grid
                              item
                              xs={6}
                              container
                              direction="column"
                              spacing={2}
                              className="mb-2 mt-2 "
                            >
                              <Grid item container direction="column">
                                {error && (
                                  <Grid
                                    item
                                    style={{
                                      color: 'red',
                                      marginBottom: 20,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {error}
                                  </Grid>
                                )}
                                <Grid item>
                                  <Typography>{`File name: ${file.name}`}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>{`File Size ${
                                    file.size / 1024
                                  } KiB`}</Typography>
                                </Grid>
                              </Grid>
                              {referralUserInfo.status != 5 && (
                                <Grid item>
                                  <Button
                                    disabled={error}
                                    variant="contained"
                                    className="bg-sky-400 hover:bg-sky-500"
                                    onClick={uploadToCloud}
                                  >
                                    {saving ? (
                                      <>
                                        <span>SAVING </span>{' '}
                                        <CircularProgress size={14} />
                                      </>
                                    ) : (
                                      'SAVE'
                                    )}
                                  </Button>
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </div>
                        <div className="relative px-4 gap-6 justify-between w-full bg-gray-300 pt-3">
                          <div className="min-w-[50%]">
                            <div>
                              <button
                                className="w-50 text-indigo-600 min-w-full text-left hover:text-indigo-700"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open(fileUrl, '_blank');
                                }}
                              >
                                {fileName}
                              </button>
                            </div>
                          </div>

                          <div className="flex justify-between">
                            <div className="w-1/2">
                              {fileName && (
                                <div className="flex flex-row gap-2 py-4">
                                  <Button
                                    className="bg-cyan-500 hover:bg-cyan-400 text-gray-900 px-2"
                                    loading={loading}
                                    onClick={() => {
                                      setFileSelected(fileName);
                                      setLoading(true);
                                      DownloadCloudFile(
                                        '',
                                        fileName,
                                        'referralfiles'
                                      ).finally(() => {
                                        setLoading(false);
                                      });
                                    }}
                                    startIcon={<CloudDownloadIcon />}
                                  >
                                    <h3 className="font-bold">Download</h3>
                                  </Button>
                                  <Button
                                    disabled={referralUserInfo.status == 5}
                                    variant="contained"
                                    className="bg-rose-400 hover:bg-rose-500 text-white"
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setFileNameToDelete(fileName);
                                    }}
                                    // startIcon={<DeleteForeverIcon />}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              )}
                            </div>
                            <Grid item>
                              <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => setFile(e.target.files[0])}
                                ref={personalFileInput}
                              />
                              <Button
                                onClick={initialPersonalFileUpload}
                                className="bg-cyan-500 hover:bg-cyan-400 text-gray-900 px-2 mb-1"
                              >
                                <Upload fontSize="medium" />
                                <span className="font-bold">Upload</span>
                              </Button>
                            </Grid>
                          </div>
                        </div>
                        <Divider />
                      </div>
                    </div>
                  </div>
                </div> */}
                <br />
                <div className="relative bg-gray-100 shadow-full px-4 py-2 rounded-lg w-full">
                  <div className="font-semibold pb-0 text-center">
                    <div className="text-2xl mb-1">Agreement Documents*</div>
                    {agreementFileName.length > 0 && (
                      <div>
                        Please download the sample agreement and re-upload it
                        with complete information
                      </div>
                    )}
                    {agreementFileName.length > 0 && (
                      <div className="text-red-400 mb-3">
                        You needs to upload the complete agreement file to claim
                        the comission
                      </div>
                    )}
                    <div className="relative gap-6 justify-between w-full mb-2">
                      <Grid item container spacing={3}>
                        <Grid item xs={6}></Grid>
                        {!openConfirmAgreement &&
                          !isResetAgreement &&
                          agreementFile && (
                            <Grid
                              item
                              xs={6}
                              container
                              direction="column"
                              spacing={2}
                              className="mt-2"
                            >
                              <Grid item container direction="column">
                                {agreementEerror && (
                                  <Grid
                                    item
                                    style={{
                                      color: 'red',
                                      marginBottom: 20,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {agreementEerror}
                                  </Grid>
                                )}
                                <Grid item>
                                  <Typography>{`File name: ${agreementFile.name}`}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>{`File Size ${
                                    agreementFile.size / 1024
                                  } KiB`}</Typography>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Button
                                  disabled={agreementEerror}
                                  variant="contained"
                                  className="bg-sky-400 hover:bg-sky-500"
                                  onClick={uploadAgreementToCloud}
                                >
                                  {agreementSaving ? (
                                    <>
                                      <span>SAVING </span>{' '}
                                      <CircularProgress size={14} />
                                    </>
                                  ) : (
                                    'SAVE'
                                  )}
                                </Button>
                              </Grid>
                            </Grid>
                          )}
                      </Grid>
                    </div>
                    {agreementFileName.map((record, index) => (
                      <div
                        key={index}
                        className="relative items-start gap-5 mt-0 w-full"
                      >
                        <div className="w-full mb-2">
                          <div className="relative flex-row px-4 gap-6 justify-between w-full bg-gray-300 pt-3">
                            <div className="min-w-[50%]">
                              <div>
                                <div className="w-50 text-indigo-600 min-w-full text-left hover:text-indigo-700">
                                  {record.fileNameLocal}
                                </div>
                              </div>
                            </div>

                            <Typography>
                              {record.fileName && (
                                <div>
                                  <div className="flex flex-row justify-between gap-2 py-4">
                                    <Button
                                      className="bg-cyan-500 hover:bg-cyan-400 text-gray-900 px-2 drop-shadow-md"
                                      loading={loading}
                                      onClick={() => {
                                        setFileSelected(record.fileName);
                                        //setLoading(true);
                                        DownloadCloudFile(
                                          '',
                                          record.fileName,
                                          'referralfiles'
                                        ).finally(() => {
                                          //setLoading(false);
                                        });
                                      }}
                                      startIcon={<CloudDownloadIcon />}
                                    >
                                      <h3 className="font-bold">Download</h3>
                                    </Button>
                                    <div></div>

                                    <Grid item>
                                      <MyTooltip
                                        title={
                                          record.isComplete
                                            ? 'Complete'
                                            : 'Incomplete'
                                        }
                                      >
                                        <CheckCircleIcon
                                          color={record.isComplete ? 'success' : ''}
                                          fontSize="large"
                                        />
                                      </MyTooltip>
                                      &nbsp;&nbsp;
                                      <input
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(e) =>
                                          setAgreementFile(e.target.files[0])
                                        }
                                        ref={agreementFileInput}
                                      />
                                      <Button
                                        onClick={initialFileUpload}
                                        disabled={record.isComplete}
                                        className="bg-cyan-500 hover:bg-cyan-400 text-gray-900 px-2"
                                      >
                                        <Upload fontSize="medium" />
                                        <span className="font-bold">
                                          Upload
                                        </span>
                                      </Button>
                                    </Grid>
                                  </div>
                                </div>
                              )}
                            </Typography>
                          </div>
                          <Divider />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <br />
              </div>
            </div>
            <ConfirmationModal
              open={openConfirm}
              cancel={setOpenConfirm}
              confirm={deleteFile}
              confirmText="Confirm Deletion"
              content="Are you sure that you would like to delete this document?"
            />
          </>
        ) : (
          <>
            <div className="min-w-screen min-h-screen px-0 py-6  overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
              {/* <div className="relative bg-gray-100 px-4 py-2 shadow-full rounded-lg w-full">
                <div className="font-semibold pb-0 text-center">
                  <div className="text-2xl">Your Document*</div>
                  <div className="text-xl text-gray-600 mb-8">
                    Please upload your passport or NID copy of pdf format here
                  </div>
                  <div className="relative items-start gap-5 mt-0 w-full">
                    <div className="w-full">
                      <div className="relative gap-6 justify-between w-full">
                        <Grid item container spacing={3}>
                          {!openConfirm && !isReset && file && (
                            <Grid
                              item
                              xs={6}
                              container
                              direction="column"
                              spacing={1}
                              className="mt-2 mb-4"
                            >
                              <Grid item container direction="column">
                                {error && (
                                  <Grid
                                    item
                                    style={{
                                      color: 'red',
                                      marginBottom: 20,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {error}
                                  </Grid>
                                )}
                                <Grid item>
                                  <Typography>{`File name: ${file.name}`}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>{`File Size ${
                                    file.size / 1024
                                  } KiB`}</Typography>
                                </Grid>
                              </Grid>
                              {referralUserInfo.status != 5 && (
                                <Grid item>
                                  <Button
                                    disabled={error}
                                    variant="contained"
                                    className="bg-sky-400 hover:bg-sky-500"
                                    onClick={uploadToCloud}
                                  >
                                    {saving ? (
                                      <>
                                        <span>SAVING </span>{' '}
                                        <CircularProgress size={14} />
                                      </>
                                    ) : (
                                      'SAVE'
                                    )}
                                  </Button>
                                </Grid>
                              )}
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    </div>
                  </div>
                  <div className="relative items-start gap-5 mt-0 w-full">
                    <div className="w-full">
                      <div className="relative gap-6 justify-between w-full bg-gray-200 pt-3">
                        <div className="min-w-[70%]">
                          <div>
                            <button
                              className="w-50 text-indigo-600 min-w-full text-left hover:text-indigo-700"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(fileUrl, '_blank');
                              }}
                            >
                              {fileName}
                            </button>
                          </div>
                        </div>
                        <Typography className="flex flex-row gap-2">
                          {fileName && (
                            <div>
                              <div className="flex flex-row gap-2 py-4">
                                <Button
                                  className="bg-cyan-500 hover:bg-cyan-400 text-gray-900 px-2"
                                  loading={loading}
                                  onClick={() => {
                                    setFileSelected(fileName);
                                    setLoading(true);
                                    DownloadCloudFile(
                                      '',
                                      fileName,
                                      'referralfiles'
                                    ).finally(() => {
                                      setLoading(false);
                                    });
                                  }}
                                  startIcon={<CloudDownloadIcon />}
                                >
                                  <h3 className="font-bold">Download</h3>
                                </Button>
                                <Button
                                  disabled={referralUserInfo.status == 5}
                                  variant="contained"
                                  className="bg-rose-400 hover:bg-rose-500 text-white"
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setFileNameToDelete(fileName);
                                  }}
                                  // startIcon={<DeleteForeverIcon />}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          )}
                          <div className="w-42"></div>
                          <Grid item className="py-4">
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(e) => setFile(e.target.files[0])}
                              ref={personalFileInput}
                            />
                            <Button
                              onClick={initialPersonalFileUpload}
                              className="bg-cyan-500 hover:bg-cyan-400 text-gray-900 px-4 mb-1"
                            >
                              <Upload fontSize="medium" />
                              <span className="font-bold">Upload</span>
                            </Button>
                          </Grid>
                        </Typography>
                      </div>
                      <Divider />
                    </div>
                  </div>
                </div>
              </div> */}
              <br />
              <div className="relative bg-gray-100 shadow-full px-4 py-2 rounded-lg w-full">
                <div className="font-semibold pb-0 text-center">
                  <div className="text-2xl mb-3">Agreement Documents*</div>
                  {!openConfirmAgreement &&
                    !isResetAgreement &&
                    agreementFile && (
                      <Grid
                        item
                        xs={6}
                        container
                        direction="column"
                        spacing={2}
                        className="mb-2 mt-2 "
                      >
                        <Grid item container direction="column">
                          {agreementEerror && (
                            <Grid
                              item
                              style={{
                                color: 'red',
                                marginBottom: 20,
                                fontWeight: 'bold',
                              }}
                            >
                              {agreementEerror}
                            </Grid>
                          )}
                          <Grid item>
                            <Typography>{`File name: ${agreementFile.name}`}</Typography>
                          </Grid>
                          <Grid item>
                            <Typography>{`File Size ${
                              agreementFile.size / 1024
                            } KiB`}</Typography>
                          </Grid>
                        </Grid>
                        {referralUserInfo.status != 5 && (
                          <Grid item>
                            <Button
                              disabled={agreementEerror}
                              variant="contained"
                              className="bg-sky-400 hover:bg-sky-500"
                              onClick={uploadAgreementToCloud}
                            >
                              {saving ? (
                                <>
                                  <span>SAVING </span>{' '}
                                  <CircularProgress size={14} />
                                </>
                              ) : (
                                'SAVE'
                              )}
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  {agreementFileName.map((record, index) => (
                    <div
                      key={index}
                      className="relative items-start gap-5 mt-0 w-full"
                    >
                      <div className="w-full mb-2">
                        <div className="relative px-4 gap-6 justify-between w-full bg-gray-200 pt-3">
                          <div className="min-w-[70%]">
                            <div>
                              <div className="w-50 text-indigo-600 min-w-full text-left hover:text-indigo-700">
                                {record.fileNameLocal}
                              </div>
                            </div>
                          </div>

                          <Typography>
                            {record.fileName && (
                              <div>
                                <div className="flex flex-row gap-2 py-4">
                                  <Button
                                    className="bg-cyan-500 hover:bg-cyan-400 text-gray-900 px-2 drop-shadow-md"
                                    loading={loading}
                                    onClick={() => {
                                      setFileSelected(record.fileName);
                                      //setLoading(true);
                                      DownloadCloudFile(
                                        '',
                                        record.fileName,
                                        'referralfiles'
                                      ).finally(() => {
                                        //setLoading(false);
                                      });
                                    }}
                                    startIcon={<CloudDownloadIcon />}
                                  >
                                    <h3 className="font-bold">Download</h3>
                                  </Button>
                                  <div className='w-6'></div>
                                  <Grid item>
                                    <MyTooltip
                                      title={
                                        record.isComplete
                                          ? 'Complete'
                                          : 'Incomplete'
                                      }
                                    >
                                      <CheckCircleIcon
                                        color={record.isComplete ? 'success' : ''}
                                        fontSize="large"
                                      />
                                    </MyTooltip>
                                    &nbsp;&nbsp;
                                    <input
                                      type="file"
                                      style={{ display: 'none' }}
                                      onChange={(e) =>
                                        setAgreementFile(e.target.files[0])
                                      }
                                      ref={agreementFileInput}
                                    />
                                    <Button
                                      onClick={initialFileUpload}
                                      disabled={record.isComplete}
                                      className="bg-cyan-500 hover:bg-cyan-400 text-gray-900 px-4 mb-1"
                                    >
                                      <Upload fontSize="medium" />
                                      <span className="font-bold">Upload</span>
                                    </Button>
                                  </Grid>
                                </div>
                              </div>
                            )}
                          </Typography>
                        </div>
                        <Divider />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <br />
            </div>

            <ConfirmationModal
              open={openConfirm}
              cancel={setOpenConfirm}
              confirm={deleteFile}
              confirmText="Confirm Deletion"
              content="Are you sure that you would like to delete this document?"
            />
          </>
        )
      ) : (
        <>
          {' '}
          <br /> <br />
          <br /> <br /> <LoadingView />
          <br /> <br />
          <br /> <br />
          <LoadingView /> <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
        </>
      )}
      {size.width > 844 ? <ReferralFooter /> : <ReferralFooterMob />}
      {/* {openAddMessage && (
        <AddMessage
          open={openAddMessage}
          cancel={setOpenAddMessage}
          toId={appUserId}
          agentId={0}
          toName={firstName + ' ' + lastName}
        />
      )} */}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    isInternal: state.auth.isInternal,
    role: state.auth.role,
    users: state.users,
    name: state.auth.name,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Contracts);
