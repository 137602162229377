import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Facebook, Twitter, LinkedIn, YouTube, Link } from '@mui/icons-material';
import { InputLabel } from '@mui/material';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';

const SocialMediaForm = ({
  open,
  onClose,
  socialMediaUrls: initialUrls,
  handleChange,
  onSave,
}) => {
  const [socialMediaUrls, setSocialMediaUrls] = useState(initialUrls);

  useEffect(() => {
    if (initialUrls) {
      setSocialMediaUrls(initialUrls);
    }
  }, [initialUrls]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className="bg-sky-800 text-white mb-4 flex justify-center items-center">
        <Link className="mr-2" />
        Add Social Media URL
      </DialogTitle>

      <DialogContent className="space-y-3">
        <InputLabel></InputLabel>
        <TextField
          label={
            <>
              <Facebook className="inline-block mr-2 text-sky-500" /> Facebook
              URL
            </>
          }
          value={socialMediaUrls?.facebookUrl || ''}
          onChange={(e) => handleChange(e, 'facebookUrl')}
          fullWidth
          variant="outlined"
        />
        <TextField
          label={
            <>
              <Twitter className="inline-block mr-2 text-sky-500" /> Twitter URL
            </>
          }
          value={socialMediaUrls?.twitterUrl || ''}
          onChange={(e) => handleChange(e, 'twitterUrl')}
          fullWidth
          variant="outlined"
        />
        <TextField
          label={
            <>
              <LinkedIn className="inline-block mr-2 text-sky-500" /> LinkedIn
              URL
            </>
          }
          value={socialMediaUrls?.linkedinUrl || ''}
          onChange={(e) => handleChange(e, 'linkedinUrl')}
          fullWidth
          variant="outlined"
        />

        <TextField
          label={
            <>
              <YouTube className="inline-block mr-2 text-sky-500" /> YouTube URL
            </>
          }
          value={socialMediaUrls?.youTubeURL || ''}
          onChange={(e) => handleChange(e, 'youTubeURL')}
          fullWidth
          variant="outlined"
        />

        <TextField
          label={
            <>
              <Link className="inline-block mr-2 text-sky-500" /> Other URL
            </>
          }
          value={socialMediaUrls?.otherUrl || ''}
          onChange={(e) => handleChange(e, 'otherUrl')}
          fullWidth
          variant="outlined"
        />
      </DialogContent>
      <DialogActions className="justify-between">
        <Button onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={onSave}
          color="primary"
          className="bg-sky-800"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};



const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    isInternal: state.auth.isInternal,
    role: state.auth.role,
    users: state.users,
    name: state.auth.name,
    permissions: state.auth.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialMediaForm);


