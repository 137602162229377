import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import { Snackbar, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import * as Yup from 'yup';

import { Formik, Form, ErrorMessage } from 'formik';

import { useNavigate } from 'react-router-dom';
import MyTextInput from '../Common/Form/MyTextInput';
import Agent from '../../api/agent';
import { Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const PREFIX = 'ResetPassword';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  paper: `${PREFIX}-paper`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    padding: '5px 20px',
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const ResetPassword = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  console.log(`token found: ${token}`);
  const navigate = useNavigate();

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const handleSuccessClick = () => {
    setOpenSuccess(true);
  };
  // const handleFailureClick = () => {
  //   setOpenFailure(true);
  // };
  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenFailure(false);
  };

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const handleClickShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);
  const handleClickShowConfirmPassword = () => {
    setConfirmPasswordVisibility(!confirmPasswordVisibility);
  };

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email')
      .required('Email address is required')
      .max(60),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8}.*$/,
        'Must contain  at least 8 characters, one uppercase, one lowercase, one number'
      )
      .max(50, 'Must be 50 characters or less'),
    confirmpassword: Yup.string()
      .required('Confirm password is required')
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: () =>
          Yup.string().oneOf(
            [Yup.ref('password')],
            'Both passwords need to be the same'
          ),
      }),
  });

  async function handleFormSubmit(user) {
    await Agent.Account.resetPassword(
      token,
      user.email,
      user.password,
      user.confirmpassword
    )
      .then(() => {
        handleSuccessClick();
      })
      .catch((error) => {
        console.log(error);
        return Promise.reject(error);
      });
  }

  let initValues = {
    email: '',
    password: '',
    confirmpassword: '',
    error: '',
  };

  return (
    <Root>
      <Formik
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={initValues}
        onSubmit={(values, { setErrors, setSubmitting }) => {
          handleFormSubmit(values).catch((error) => {
            let err = '';
            if (error.errors.length == 0) {
              err = `${error.statusText}- ${error.error}`;
            } else {
              err = error.errors[0];
            }
            setErrors({
              error: err,
            });
            setSubmitting(false);
          });
        }}
      >
        {({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              style={{ marginTop: '20px' }}
            >
              <Grid item>
                <Typography
                  align="center"
                  gutterBottom
                  variant="h5"
                  component="h2"
                >
                  Reset Password
                </Typography>
              </Grid>
              <Grid item>
                <Box
                  boxShadow={5}
                  bgcolor="background.paper"
                  m={2}
                  style={{ width: '28rem' }}
                  p={2}
                >
                  <div className={classes.root}>
                    <Grid container direction="column" spacing={2}></Grid>
                    <Grid item style={{ marginTop: '20px' }}>
                      <MyTextInput
                        label="Email"
                        name="email"
                        placeholder="Email Address"
                        type="email"
                      />
                    </Grid>
                    <Grid item style={{ marginTop: '10px' }}>
                      <MyTextInput
                        label="Password"
                        name="password"
                        placeholder="Password"
                        type={passwordVisibility ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                size="large"
                              >
                                {passwordVisibility ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item style={{ marginTop: '10px' }}>
                      <MyTextInput
                        label="Confirm Password"
                        name="confirmpassword"
                        placeholder="Confirm Password"
                        type={confirmPasswordVisibility ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownConfirmPassword}
                                size="large"
                              >
                                {passwordVisibility ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <ErrorMessage
                        name="error"
                        render={() => (
                          <Typography color="error">{errors.error}</Typography>
                        )}
                      />
                    </Grid>

                    <Grid item style={{ marginTop: '10px' }}>
                      {' '}
                      <Button
                        disabled={isSubmitting || !dirty || !isValid}
                        type="submit"
                        fullWidth
                        variant="contained"
                      className='btn btn-primary'
                      >
                        Reset
                      </Button>
                    </Grid>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Snackbar
        open={openFailure}
        autoHideDuration={6000}
        onClose={handleCloseFailure}
      >
        <Alert onClose={handleCloseFailure} severity="error">
          Failed to reset the password. Please check email address, password and
          try again.
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        color="primary"
        open={openSuccess}
        autoHideDuration={60000}
        onClose={handleCloseSuccess}
        message="Password has been successfully reset. You can now log in."
        action={
          <>
            <Button
              color="secondary"
              size="small"
              onClick={() => navigate('/')}
            >
              LOGIN
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={() => navigate('/')}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </Root>
  );
};

export default ResetPassword;
