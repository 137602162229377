/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import {
  APPLICATION_STATUSES,
  INTAKES,
  STUDY_LEVELS,
} from '../../../Util/Constants';
import LoadingView from '../../Common/Loading/LoadingView';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {  Paper, Typography } from '@mui/material';
import Draggable from 'react-draggable';
import { styled } from '@mui/material/styles';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ApplicationList = ({ applications, studentName, cancel }) => {
  //const [applications, setApplications] = useState([]);
  const [applicationsLoading, setApplicationsLoading] = useState(
    applications ? false : true
  );
  const [dialogOpen, setDialogOpen] = useState(true);
  console.log('applications', applications);

  const handleClose = () => {
    setDialogOpen(false);
    cancel(false);
  };

  return (
    <div className="w-full max-w-[1400px] pt-2">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        open={dialogOpen}
        maxWidth="sm"
        fullWidth
        PaperComponent={PaperComponent}
      >
        <div className="flex justify-between gap-4 pt-6 px-8">
            <div></div>
          <button
            onClick={handleClose}
          >
           <CloseIcon/>
          </button>
        </div>
        <div className="flex justify-center items-center pt-4 px-4">
          <Typography align="center" color="secondary" variant="h6">
            Applications List of {studentName}
          </Typography>
        </div>
        <DialogContent>
          {/* APPLICATIONS TABLE */}
          {applicationsLoading ? (
            <div className="pb-10">
              <LoadingView />
            </div>
          ) : (
            <div className="p-6 px-2 bg-gray-300  mt-6 rounded-lg shadow-xl">
              <div className="overflow-x-auto app-scrollbar min-h-[400px]">
                <div className="w-full min-w-max ">
                  <div className="flex gap-1 m- p-2 h-12 w-full text-lg font-semibold px-2 ">
                    <div className={`w-[180px] xl:w-[300px]`}>Institution</div>
                    <div className={`w-[180px] xl:w-[300px]`}>Course Name</div>
                    <div className={`w-[150px]`}>Course Level</div>
                    <div className={`w-[120px]`}>Status</div>
                    <div className={`w-[120px]`}>Year</div>
                    <div className={`w-[150px]`}>Intake</div>
                    <div className={`w-[150px]`}>Update On</div>

                    {/* <div className={`w-[150px]`}>Added</div>
                <div className={`w-[150px]`}>Applied</div> */}
                  </div>

                  {applications.map((item, idx) => {
                    return (
                      <div key={idx}>
                        <div className="flex items-center gap-1 bg-white text-gray-700 rounded-lg p-2 my-2 h-12  hover:bg-sky-400 hover:text-white cursor-pointer transition-all duration-100 text-base ">
                          <div
                            className={`w-[180px] xl:w-[300px] truncate px-2`}
                          >
                            {item.universityName}
                          </div>
                          <div className={`w-[180px] xl:w-[300px] truncate px-0`}>
                            {item.courseName}
                          </div>
                          <div className={`w-[150px] px-2`}>
                            {
                              STUDY_LEVELS.find(
                                ({ id }) => id == item.studyLevelId
                              ).type
                            }
                          </div>
                          <div className={`w-[120px]`}>
                            {APPLICATION_STATUSES.filter(
                              (appStatus) => appStatus.id === item.status
                            ).map((appStatus) => appStatus.type)}
                          </div>
                          <div className={`w-[120px]`}>{item.year}</div>
                          <div className={`w-[150px]`}>
                            {INTAKES.find(({ id }) => id == item.intake).type}
                          </div>
                          <div className={`w-[150px]`}>
                            {item.updatedOn ? item.updatedOn.split('T')[0] : ''}
                          </div>

                          {/* <div className={`w-[150px]`}>
                        {format(new Date(item.appliedOn), 'dd-MM-yyyy')}
                      </div>
                      <div className={`w-[150px]`}>
                        {item.appliedOnActual
                          ? format(new Date(item.appliedOnActual), 'dd-MM-yyyy')
                          : null}
                      </div> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default ApplicationList;
