import React, { useState, useEffect } from 'react';
import * as actions from '../../../store/actions/index';
import {
  CONTACT_SERVICE_TYPE,
  CONTACT_TYPE,
  Contact_Lead_Status,
  MUI_LICENSE_KEY,
  contactEventYear,
  // eslint-disable-next-line import/namespace
} from '../../../Util/Constants';
import {
  LicenseInfo,
  DataGridPro,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import MyTooltip from '../../Common/Tooltip/MyTooltip';
import LoadingView from '../../Common/Loading/LoadingView';
import { connect } from 'react-redux';
import UserContext from '../../../context/user-context';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // the locale you want
registerLocale('en-GB', enGB); // register it with the name you want
import 'react-datepicker/dist/react-datepicker.css';
import { startOfDay, addHours, addMonths } from 'date-fns';
import { Description, PersonAddAlt1Rounded } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import ContactNotes from './ContactNotes';
import { ContactManagementAssign } from './ContactManagementAssign';
import { Countries } from '../../../Util/Util';
import Agent from '../../../api/agent';

function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {show && <GridToolbarColumnsButton />}
      {hide && <GridToolbarFilterButton />}
      <GridToolbarDensitySelector />
      {hide && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

const ContactList = ({
  users,
  loadUsers,
  isInternal,
  name,
  role,
  permissions,
  props,
  // agentId = 0,
}) => {
  // const classes = useStyles();
  const [assessmentUsers, setAssessmentUsers] = useState([]);
  const [bulkUpdate, setBulkUpdate] = useState();

  useEffect(() => {
    if (users) {
      const filteredUsers = users.filter((item) => {
        return [1, 2, 3].includes(item.userType) && !item.isDisabled;
      });
      setAssessmentUsers(filteredUsers);
    }
  }, [users]); //

  const eventUrlId = useParams();
  const navigate = useNavigate();
  // let userContext = useContext(UserContext);
  const [studentLoaded, setStudentLoaded] = useState(false);
  const [id, setId] = useState('');
  const [notes, setNotes] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [typeOfContact, setTypeOfContact] = useState(0);
  const [contactLeadStatus, setContactLeadStatus] = useState(0);
  const [serviceType, setServiceType] = useState(0);
  const [contactId, setContactId] = useState(0);
  const [contactPerson, setContactPerson] = useState('');
  const [contactName, setContactName] = useState('');
  const [eventName, setEventName] = useState('');
  const [eventYear, setEventYear] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [contactPersonEmail, setContactPersonEmail] = useState('');
  const [start, setStart] = useState(
    eventUrlId.id ? null : addMonths(new Date(), -1)
  );
  const [end, setEnd] = useState(new Date());
  const [ready, setReady] = useState(false);
  const [contactRow, setContactRow] = useState();
  const [contacts, setContacts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const pageSize = 800;
  const [totalPages, setTotalPages] = useState();
  const [totalItems, setTotalItems] = useState();
  const [openAddNote, setOpenAddNote] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [reload, setReload] = useState(false);

  const handleAddContact = () => {
    navigate('/addcontact');
  };
  const actionSelectedUser = (record) => {
    const assignDto = {
      contactManagementIds:
        selectionModel.length > 0 ? selectionModel : [contactRow.id],
      countryId: record.countryId,
      typeOfContact: record.typeOfContact,
      serviceType: record.serviceType,
      eventYear: record.eventYear,
      contactName: record.contactName,
      assessmentTeamId1: record.assessmentTeamId1,
      assessmentTeamId2: record.assessmentTeamId2,
      assessmentTeamId3: record.assessmentTeamId3,
      assessmentTeamId4: record.assessmentTeamId4,
      assessmentTeamId5: record.assessmentTeamId5,
      assessmentTeamId6: record.assessmentTeamId6,
    };

    //console.log('Assign DTO stund:', assignDto, record); // Console log the assignment data before making the API call

    Agent.GlobalContacts.assign({ ...record, ...assignDto })

      .then(() => {
        if (bulkUpdate) {
          setReady(false);
          return;
        }
        setReload(true);
        let temp = [];
        contacts.map((item) => {
          temp.push(item);
        });
        setContacts(temp);
        setReady(false);
        //setStudent(null);
      })
      .catch((error) => {
        console.log(error);
        setReady(false);
      });
  };
  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', page.toString());
    params.append('pageSize', pageSize.toString());
    params.append('id', filter ? id.trim() : '');

    params.append('ContactName', filter ? contactName?.trim() : '');
    params.append('ContactEmail', filter ? contactEmail.trim() : '');

    params.append('ContactPerson', filter ? contactPerson.trim() : '');

    if (filter && eventName!=null) {
      params.append('EventName', filter ? eventName.trim() : null);
    }
    if (filter && eventYear > 0) {
      params.append('EventYear', filter ? eventYear : 0);
    }
    if (filter && typeOfContact > 0) {
      params.append('TypeOfContact', filter ? typeOfContact : 0);
    }
    if (filter && contactLeadStatus > 0) {
      params.append('ContactLeadStatus', filter ? contactLeadStatus : 0);
    }
    if (filter && serviceType > 0) {
      params.append('ServiceType', filter ? serviceType : 0);
    }
    if (filter && countryId > 0) {
      params.append('CountryId', filter ? countryId : 0);
    }

    params.append(
      'ContactPersonEmail',
      filter ? contactPersonEmail.trim() : ''
    );

    if (filter) {
      if (start) {
        params.append('startDate', new Date(start).toDateString());
      }
    } else {
      params.append('startDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (end) {
        params.append('endDate', new Date(end).toDateString());
      }
    } else {
      params.append('endDate', new Date().toDateString());
    }

    // params.append('ServiceType', serviceType);
    // params.append('TypeOfContact', typeOfContact);

    return params;
  };

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const getRows = (rows) => {
    console.log('rows', permissions);
    let contactsRows = [];
    rows.map((contactInfo) => {
      contactsRows.push({
        id: contactInfo.id,
        contactName: contactInfo?.contactName, // as per requirement eventName changed to BusinessName
        email: contactInfo?.email,
        contactPerson: contactInfo?.contactPerson,
        countryId: contactInfo.countryId,
        countryName:
          contactInfo.countryId && contactInfo.countryId > 0
            ? Countries.find(({ id }) => id == contactInfo.countryId).name
            : '',
        emailAddress: contactInfo?.emailAddress,
        eventYear: contactInfo?.eventYear,
        eventName: contactInfo?.eventName, // as per requirement eventName need changed to source
        contactNumber: contactInfo?.contactNumber,
        designation: contactInfo?.designation,
        typeOfContact: contactInfo?.typeOfContact,
        contactLeadStatus: contactInfo.contactLeadStatus,
        typeOfContactName:
          contactInfo.typeOfContact >= 0
            ? CONTACT_TYPE.find(({ id }) => id == contactInfo.typeOfContact)
                ?.type
            : '',
        serviceType: contactInfo.serviceType,
        serviceTypeName:
          contactInfo.serviceType >= 0
            ? CONTACT_SERVICE_TYPE.find(
                ({ id }) => id == contactInfo.serviceType
              )?.type
            : '',
        assessmentTeamId1: contactInfo.assessmentTeamId1,
        SalesUser: contactInfo.assessmentTeamId1
          ? assessmentUsers.find(
              ({ id }) => id == contactInfo.assessmentTeamId1
            )?.name
          : '',
        assessmentTeamId2: contactInfo.assessmentTeamId2,
        FollowUp: contactInfo.assessmentTeamId2
          ? assessmentUsers.find(
              ({ id }) => id == contactInfo.assessmentTeamId2
            )?.name
          : '',
        assessmentTeamId3: contactInfo.assessmentTeamId3,
        Teamleader: contactInfo.assessmentTeamId3
          ? assessmentUsers.find(
              ({ id }) => id == contactInfo.assessmentTeamId3
            )?.name
          : '',
        assessmentTeamId4: contactInfo.assessmentTeamId4,
        Manager: contactInfo.assessmentTeamId4
          ? assessmentUsers.find(
              ({ id }) => id == contactInfo.assessmentTeamId4
            )?.name
          : '',
        assessmentTeamId5: contactInfo.assessmentTeamId5,
        CountryManager: contactInfo.assessmentTeamId5
          ? assessmentUsers.find(
              ({ id }) => id == contactInfo.assessmentTeamId5
            )?.name
          : '',
        assessmentTeamId6: contactInfo.assessmentTeamId6,
        RegionalManager: contactInfo.assessmentTeamId6
          ? assessmentUsers.find(
              ({ id }) => id == contactInfo.assessmentTeamId6
            )?.name
          : '',
        assessmentTeamId7: contactInfo.assessmentTeamId7,
        Director: contactInfo.assessmentTeamId7
          ? assessmentUsers.find(
              ({ id }) => id == contactInfo.assessmentTeamId7
            )?.name
          : '',

        updatedOn:
          contactInfo.updatedOn == null
            ? ''
            : new Date(contactInfo.updatedOn).toISOString().split('T')[0],

        createdOn:
          contactInfo.createdOn == null
            ? ''
            : new Date(contactInfo.createdOn).toISOString().split('T')[0],
      });
    });
    // console.log('contactsRows', contactsRows);
    setContacts(contactsRows);
    setStudentLoaded(true);
    setLoading(false);
  };
  useEffect(() => {
    if (contactId > 0) {
      Agent.GlobalContacts?.getContactNotesById(contactId).then((res) => {
        var allNotes = res.contactManagementNotes.sort(function (a, b) {
          return new Date(b.dateSent) - new Date(a.dateSent);
        });
        setNotes(allNotes);
      });
    }
  }, [contactId]);

  useEffect(() => {
    if (isInternal) {
      if (assessmentUsers.length == 0) {
        loadUsers()
          .then(() => {
            //  getData();
          })
          .catch((error) => {
            console.log(error);
            //debugger;
          });
      } else {
        getData(true);
      }
    } else {
      getData(true);
    }
  }, [assessmentUsers, page, reload]);

  const showEditOptions = (row) => {
    setContactRow(row);
    setReady(true);
  };
  const handleNote = (row) => {
    setNotes([]);
    setContactId(row.id);
    setOpenAddNote(true);
  };

  let contactColumns = [
    {
      field: 'id',
      renderHeader: () => <strong>{'Id'}</strong>,
      width: 80,
      hide: true,
    },

  
    {
      field: 'contactName',
      //headerAlign: 'center',
      width: 200,
      minWidth: 200,
      maxWidth: 380,
      resizable: true,
      renderHeader: () => <strong>{'Business / Contact Name'}</strong>,
      renderCell: (params) => (
        <MyTooltip title={params.row.contactName} color="primary">
          <Typography lassName="clickable">{params.row.contactName}</Typography>
        </MyTooltip>
      ),
    },


    {
      field: 'eventName',
      align: 'left',
      headerAlign: 'center',
      width: 200,
      minWidth: 200,
      maxWidth: 380,
      resizable: true,
      renderHeader: () => <strong>{'Event_Name / Source'}</strong>,
      hide: false,
      renderCell: (params) => (
        <MyTooltip title={params.row.eventName} color="primary">
          <Typography>{params.row.eventName}</Typography>
        </MyTooltip>
      ),
    },


    {
      field: 'contactPerson',
      hide: false,
      align: 'left',
      //headerAlign: 'center',
      width: 200,
      minWidth: 200,
      maxWidth: 300,
      resizable: true,
      renderHeader: () => <strong>{'Contact Person'}</strong>,
      renderCell: (params) => (
        <MyTooltip title={params.row.contactPerson} color="primary">
          <Typography>{params.row.contactPerson}</Typography>
        </MyTooltip>
      ),
    },

    {
      field: 'email', // this is for contact person email
      hide: false,
      align: 'left',
      headerAlign: 'left',
      width: 200,
      minWidth: 200,
      maxWidth: 300,
      resizable: true,
      renderHeader: () => <strong>{'Contact Person Email'}</strong>,
    },

    {
      field: 'emailAddress',
      hide: false,
      align: 'left',
      //headerAlign: 'center',
      width: 200,
      minWidth: 200,
      maxWidth: 300,
      resizable: true,
      renderHeader: () => <strong>{'Contact Email'}</strong>,
    },
    {
      field: 'contactNumber',
      align: 'left',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => <strong>{'Contact Number'}</strong>,
      hide: true,
    },
    role.isSuperAdmin && {
      field: 'designation',
      hide: true,
      align: 'left',
      //headerAlign: 'center',
      width: 125,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => <strong>{'Designation'}</strong>,
    },

    {
      field: 'contactLeadStatus',
      align: 'center',
      headerAlign: 'left',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => <strong>{'Contact Lead Status'}</strong>,
      valueGetter: (params) => {
        const statusId = params.row.contactLeadStatus;
        const status = Contact_Lead_Status.find(
          (leadStatus) => leadStatus.id == statusId
        );
        return status ? status.name : null;
      },
    },

    {
      field: 'SalesUser',
      hide: false,
      align: 'left',
      //headerAlign: 'center',
      width: 170,
      resizable: true,
      renderHeader: () => <strong>{'Sales user'}</strong>,
    },

    {
      field: 'FollowUp',
      hide: true,
      align: 'left',
      //headerAlign: 'center',
      width: 170,
      resizable: true,
      renderHeader: () => <strong>{'Follow up'}</strong>,
    },
    {
      field: 'Teamleader',
      hide: true,
      align: 'left',
      //headerAlign: 'center',
      width: 170,
      resizable: true,
      renderHeader: () => <strong>{'Team leader'}</strong>,
    },
    {
      field: 'Manager',
      hide: true,
      align: 'left',
      //headerAlign: 'center',
      width: 170,
      resizable: true,
      renderHeader: () => <strong>{'Manager'}</strong>,
    },
    {
      field: 'CountryManager',
      hide: true,
      align: 'left',
      //headerAlign: 'center',
      width: 170,
      resizable: true,
      renderHeader: () => <strong>{'Country Manager'}</strong>,
    },
    {
      field: 'RegionalManager',
      hide: true,
      align: 'left',
      //headerAlign: 'center',
      width: 170,
      resizable: true,
      renderHeader: () => <strong>{'Regional Manager'}</strong>,
    },
    {
      field: 'Director',
      hide: true,
      align: 'left',
      //headerAlign: 'center',
      width: 170,
      resizable: true,
      renderHeader: () => <strong>{'Director'}</strong>,
    },
    {
      field: 'countryName',
      align: 'center',
      headerAlign: 'center',
      width: 170,
      resizable: true,
      renderHeader: () => <strong>{'Country'}</strong>,
      hide: false,
    },
    {
      field: 'updatedOn',
      align: 'center',
      //headerAlign: 'center',
      width: 150,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => <strong>{'Updated Date'}</strong>,
      hide: true,
    },

    {
      field: 'typeOfContactName',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => <strong>{'Type Of Contact'}</strong>,
      hide: false,
    },
    {
      field: 'serviceTypeName',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => <strong>{'Service Type'}</strong>,
      hide: false,
    },

    {
      field: 'createdOn',
      align: 'center',
      headerAlign: 'center',
      width: 150,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => <strong>{'Created On'}</strong>,
      hide: false,
    },
    {
      field: 'note',
      renderHeader: () => <strong>{'Note'}</strong>,
      width: 70,
      hide: !isInternal,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <IconButton
          disabled={!isInternal}
          onClick={() => handleNote(params.row)}
          size="large"
        >
          <Description />
        </IconButton>
      ),
    },
    {
      field: 'eventYear',
      align: 'center',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      renderHeader: () => <strong>{'Event Year'}</strong>,
      hide: true,
    },

    {
      field: 'edit',
      headerName: 'Edit',
      width: 90,
      hide: !isInternal || bulkUpdate,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <IconButton
          disabled={!isInternal}
          onClick={() => showEditOptions(params.row)}
          size="large"
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const getData = (filter = false) => {
    setLoading(true);
    Agent.GlobalContacts.getContactList(getParams(filter))
      .then((contacts) => {
        const { pagination, data } = contacts;
        setTotalPages(pagination.totalPages);
        setTotalItems(pagination.totalItems);
        console.log('response', data);
        getRows(data);
        setReload(false);
      })
      .catch((error) => {
        console.log(error);
        //debugger;
      });
  };
  const resetFilter = () => {
    setContactLeadStatus('');
    setId('');
    setContactName('');
    setContactPerson('');
    setContactEmail('');
    setContactPersonEmail('');
    setStart(addMonths(new Date(), -1));
    setEnd(new Date());
    getData(false);
    setServiceType(0);
    setTypeOfContact(0);
    setEventName('');
    setEventYear('');
    setCountryId(0);
  };

  return studentLoaded ? (
    <>
      <Grid container direction="column" spacing={2}>
        <Grid item container spacing={1}>
          <Grid item xs={2}>
            <DatePicker
              // popperClassName={classes.reactDatepickerPopper}
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(start && new Date(start)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setStart(newVal);
              }}
              customInput={
                <TextField
                  label="Start Date"
                  fullWidth
                  value={start}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              // popperClassName={classes.reactDatepickerPopper}
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(end && new Date(end)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setEnd(newVal);
              }}
              customInput={
                <TextField
                  label="End Date"
                  fullWidth
                  // onChange={onChange}
                  value={end}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              label="Contact Name"
              value={contactName}
              onChange={(e) => setContactName(e.target.value)}
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <SearchIcon />
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              label="Contact Email"
              value={contactEmail}
              onChange={(e) => setContactEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              label="Contact Person Email"
              value={contactPersonEmail}
              onChange={(e) => setContactPersonEmail(e.target.value)}
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              label="Contact Person"
              value={contactPerson}
              onChange={(e) => setContactPerson(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item xs={2}>
            <TextField
              fullWidth
              size="small"
              label="Event Name"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
            />
          </Grid>

          <Grid item xs={2}>
            <TextField
              select
              required
              label="Event Year"
              placeholder="Event Year"
              variant="outlined"
              size="small"
              fullWidth
              value={eventYear}
              onChange={(e) => setEventYear(e.target.value)}
            >
              {contactEventYear.map((eventYear) => (
                <MenuItem key={eventYear.id} value={eventYear.year}>
                  {eventYear.type}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                label="Type of Contact"
                variant="outlined"
                select
                fullWidth
                size="small"
                value={typeOfContact}
                onChange={(e) => setTypeOfContact(e.target.value)}
              >
                {CONTACT_TYPE.map((contact) => (
                  <MenuItem key={contact.id} value={contact.id}>
                    {contact.type}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                label="Service Type"
                variant="outlined"
                select
                fullWidth
                size="small"
                value={serviceType}
                onChange={(e) => setServiceType(e.target.value)}
              >
                {CONTACT_SERVICE_TYPE.map((service) => (
                  <MenuItem key={service.id} value={service.id}>
                    {service.type}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <TextField
              required
              select
              fullWidth
              size="small"
              label="Country"
              value={countryId}
              onChange={(e) => setCountryId(e.target.value)}
              variant="outlined"
              type="number"
            >
              {Countries.filter((item)=>item.id!=0).map((charge) => (
                <MenuItem key={charge.id} value={charge.id}>
                  {charge.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <TextField
              required
              select
              fullWidth
              size="small"
              label="Lead Status"
              value={contactLeadStatus}
              onChange={(e) => setContactLeadStatus(e.target.value)}
              variant="outlined"
              type="number"
            >
              {Contact_Lead_Status.map((status) => (
                <MenuItem key={status.id} value={status.id}>
                  {status.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item container spacing={2} justifyContent="end">
          <Grid item xs={2}>
            <Button
              fullWidth
              onClick={getData}
              variant="contained"
              className="bg-sky-400 hover:bg-sky-500"
            >
              Apply Filters
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button fullWidth onClick={resetFilter} variant="outlined">
              Reset Filters
            </Button>
          </Grid>

          {isInternal && permissions.CanAddContact && (
            <Grid item xs={2}>
              <Button
                variant="contained"
                size="small"
                onClick={handleAddContact}
                startIcon={<PersonAddAlt1Rounded />}
                className="bg-gradient-to-r from-sky-600 to-blue-700 hover:scale-105 transform transition-all duration-300 rounded-full text-white font-bold px-6 py-1 shadow-md"
              >
                Add Contact
              </Button>
            </Grid>
          )}

          {isInternal && (
            <Grid item xs={2}>
              <Grid item>
                <Button
                  fullWidth
                  onClick={() => setBulkUpdate(!bulkUpdate)}
                  variant="text"
                >
                  {bulkUpdate ? 'Disable Bulk Update' : 'Enable Bulk Update'}
                </Button>
              </Grid>
              {bulkUpdate && (
                <Grid item xs={1}>
                  <IconButton
                    onClick={() => {
                      if (selectionModel.length > 0) {
                        showEditOptions(null);
                      }
                    }}
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>

        <Grid item style={{ height: 730, width: '100%' }}>
          <DataGridPro
            paginationMode="server"
            pagination
            pageSize={pageSize}
            rowCount={totalItems}
            page={page - 1}
            pageSizeOptions={[800]}
            onPageChange={handlePageChange}
            rows={contacts}
            columns={contactColumns}
            loading={loading}
            density="compact"
            rowHeight={70}
            components={{
              Toolbar: () => CustomToolbar(isInternal),
            }}
            onCellClick={(params, event) => {
              event.defaultMuiPrevented = true;
              if (params.colDef.field === 'contactName') {
                window.open(
                  `/contacts/${params.row.id}/contactprofile`,
                  '_blank'
                );
              }
            }}
            checkboxSelection={isInternal && bulkUpdate}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel); //onRowSelection use for datagridpro v6 and onselection is use for current datagrid
            }}
            selectionModel={selectionModel}
          />
        </Grid>
      </Grid>
      {ready && (
        <ContactManagementAssign
          bulkUpdate={bulkUpdate}
          cancel={setReady}
          action={actionSelectedUser}
          contact={bulkUpdate ? null : contactRow}
          users={assessmentUsers}
          role={role}
        />
      )}
      {openAddNote && (
        <ContactNotes
          records={notes}
          setRecords={setNotes}
          open={openAddNote}
          cancel={setOpenAddNote}
          contactId={contactId} // message being sent from admin to an agent
          //toId={referralId}
          toName={name}
        />
      )}
    </>
  ) : (
    <LoadingView />
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    name: state.auth.name,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactList);
