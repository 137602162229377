import * as actionTypes from './actionTypes';
import Agent from '../../api/agent';

export const updateAgent = (count) => {
  return {
    type: actionTypes.SUMMARY_AGENT_COUNT,
    payload: { count },
  };
};

export const updateStudent = (count) => {
  return {
    type: actionTypes.SUMMARY_STUDENT_COUNT,
    payload: { count },
  };
};

export const updateApplication = (count) => {
  return {
    type: actionTypes.SUMMARY_APPLICATION_COUNT,
    payload: { count },
  };
};

export const updateAdmin = (count) => {
  return {
    type: actionTypes.SUMMARY_ADMIN_COUNT,
    payload: { count },
  };
};
