import React, { useState, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Divider } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Add from './Add';

import LinearIndeterminate from '../../../../Common/Progress/LinearIndeterminate';
import ConfirmationModal from '../../../../Common/Modal/ConfirmationModal';
import StudentContext from '../../../../Students/Context/student-context';
import { EDUCATION_LEVELS } from '../../../../../Util/Constants';
import { Countries } from '../../../../../Util/Util';
import Agent from '../../../../../api/agent';

const Education = ({
  records,
  displayError,
  setRecordsGrandParent,
  id,
  setStudentIdParent,
  isLocked,
}) => {
  let studentContext = useContext(StudentContext);
  const [eduRecords, setEduRecords] = useState(records);
  const [recordAddMode, setRecordAddMode] = useState(false);
  const [record, setRecord] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [recordIdToDelete, setRecordIdToDelete] = useState();
  const setEduRecordsParent = (records) => {
    setEduRecords(records);
    setRecordsGrandParent(records);
  };

  const addRecord = (record) => {
    setRecord(record);
    setRecordAddMode(true);
  };
  const deleteRecord = () => {
    var studentData = {
      saveStudentDto: {
        student: {
          id: id,
          educationRecords: eduRecords.filter(
            (item) => item.id != recordIdToDelete
          ),
        },
        agentAppUserId: studentContext.agentAppUserId,
        createdByAppUserId: studentContext.createdByAppUserId,
        saveMode: 1,
      },
    };
    setUpdating(true);
    Agent.Students.saveStudent(studentData)
      .then(() => {
        if (id > 0) {
          setStudentIdParent(id);
          setEduRecords(studentData.saveStudentDto.student.educationRecords);
          setRecordsGrandParent(
            studentData.saveStudentDto.student.educationRecords
          );
        }
        setUpdating(false);
        setOpenConfirm(false);
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
        debugger;
      });
  };
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    if (startIndex == endIndex) return;
    let temp = eduRecords.slice();
    const [removed] = temp.splice(startIndex, 1);
    temp.splice(endIndex, 0, removed);

    let newOrder = 0;
    temp.forEach(function (obj) {
      newOrder = newOrder + 1;
      obj.order = newOrder;
    });

    var studentData = {
      saveStudentDto: {
        student: {
          id: id,
          educationRecords: temp,
        },
        agentAppUserId: studentContext.agentAppUserId,
        createdByAppUserId: studentContext.createdByAppUserId,
        saveMode: 1,
      },
    };
    setUpdating(true);
    Agent.Students.saveStudent(studentData)
      .then((id) => {
        setEduRecordsParent(temp);
        setUpdating(false);
      })
      .catch((error) => {
        debugger;
        setUpdating(false);
      });
  };

  return (
    <div className="w-full flex flex-col items-start">
      {recordAddMode && (
        <Add
          record={record}
          eduRecords={eduRecords}
          setEduRecordsParent={setEduRecordsParent}
          setRecordAddMode={setRecordAddMode}
          id={id}
          setStudentIdParent={setStudentIdParent}
          isLocked={isLocked}
        />
      )}
      <div className="bg-gray-100 px-4 py-10 rounded-lg flex flex-col gap-2 items-center justify-center w-full ">
        {updating && (
          <div>
            <div>
              <LinearIndeterminate />
            </div>
          </div>
        )}
        <div className="flex flex-col gap-2 text-center text-4xl font-semibold pb-6 pt-3 w-2/3 divide-y-2 divide-slate-400/70">
          <div> Education</div>
          <div></div>
        </div>
        {displayError && eduRecords?.length == 0 && (
          <div className="text-base text-rose-600">
            You must at least add one educational record
          </div>
        )}
        <div className="text-lg px-2 font-medium text-gray-600 text-justify">
          Please enter information about your formal education, starting with
          your secondary education. If you have not yet finished your current
          school or university, please enter the year of expected graduation
          (which should be the current year).
        </div>
        {!(id > 0) && (
          <>
            <div>
              You must save the student first (General Info) before you can add
              the eudcation info
            </div>
            <div>
              You must save the student first (General Info) before you can add
              the eudcation info
            </div>
          </>
        )}
        <div>
          <Button
            disabled={!(id > 0)}
            className="bg-indigo-100 hover:bg-indigo-100 hover:text-indigo-800 text-indigo-600 px-6 drop-shadow-md"
            startIcon={<AddIcon />}
            onClick={() => addRecord({ id: 'new' })}
          >
            {records.length > 0 ? 'Add Another' : 'Add Education Info'}
          </Button>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="w-full"
              >
                {eduRecords.map((record, index) => (
                  <Draggable
                    key={record.id}
                    draggableId={record.id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="w-full"
                      >
                        <div key={record.id} className="w-full ">
                          <div className="flex gap-4 items-center w-full justify-between">
                            <div>
                              <div>
                                <div className="text-lg font-medium text-indigo-600">
                                  {`${
                                    EDUCATION_LEVELS.find(
                                      ({ id }) => id === record.educationLevel
                                    ).type
                                  } ${
                                    record.educationLevel == 9 &&
                                    record.description
                                      ? ' (' + record.description + ')'
                                      : ''
                                  }
                                `}
                                </div>
                              </div>
                              <div>
                                <div>{`${record.institute}, ${
                                  Countries.find(
                                    ({ id }) => id == record.country
                                  ).name
                                }`}</div>
                                {record.startOfStudies && (
                                  <div>{`${new Date(
                                    record.startOfStudies
                                  ).getFullYear()}-${new Date(
                                    record.expectedGraduation
                                  ).getFullYear()}`}</div>
                                )}
                                <div>
                                  {record.passingYear
                                  ? `Passing Year: ${record.passingYear}`
                                  : ''}
                                </div>
                                <div>
                                  {record.gpa
                                    ? `GPA/Average Grade: ${record.gpa}`
                                    : ''}
                                </div>                                
                              </div>
                            </div>
                            <div>
                              <div className="flex flex-col gap-2">
                                <Button
                                  className="bg-indigo-100 hover:bg-indigo-100 hover:text-indigo-800 text-indigo-600 px-6 drop-shadow-md"
                                  onClick={() => addRecord(record)}
                                  startIcon={
                                    isLocked ? <VisibilityIcon /> : <EditIcon />
                                  }
                                >
                                  {isLocked ? 'View' : 'Edit'}
                                </Button>
                                <Button
                                  disabled={isLocked}
                                  variant="contained"
                                  className="bg-rose-400 hover:bg-rose-500 text-white"
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setRecordIdToDelete(record.id);
                                  }}
                                  startIcon={<DeleteForeverIcon />}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Divider className="py-2" />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {eduRecords.length > 1 && (
          <div className="text-3xl font-semibold text-gray-700 pb-5 text-center">
            You can optionally change the order in which your education records
            appear above by dragging a record up or down
          </div>
        )}
      </div>
      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={deleteRecord}
        confirmText="Confirm Deletion"
        content="Are you sure that you would like to delete this record?"
      />
    </div>
  );
};

export default Education;
