import {Button, Grid, Typography} from '@mui/material'
import React, {useState, useEffect} from 'react'
import Agent from '../../../api/agent'
import LoadingView from '../../Common/Loading/LoadingView'
import PaymentIcon from '@mui/icons-material/Payment'
import {MUI_LICENSE_KEY} from '../../../Util/Constants'
import {LicenseInfo, DataGridPro, GridToolbar} from '@mui/x-data-grid-pro'
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY)

import Commission from './Commission'
import {format} from 'date-fns'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Edit from '@mui/icons-material/Edit'
import {connect} from 'react-redux'

const Commissions = ({id, isInternal}) => {
  // const classes = useStyles();
  const [loading, setLoading] = useState(true)
  const [comms, setComms] = useState([])
  const [dialogMode, setDialogMode] = useState(false)
  const [commission, setCommission] = useState({id: 0, agentId: id})

  const edit = (row) => {
    setCommission({...commission, ...row})
    setDialogMode(true)
  }

  const updateParent = () => {
    getRecords()
  }

  let columns = [
    {
      field: 'intake',
      headerName: 'Intake',
      width: 180,
    },
    {
      field: 'studentEnrolled',
      headerName: 'Student Enrolled',
      width: 220,
    },
    {
      field: 'commissionValue',
      headerName: 'Comm. Value',
      width: 180,
    },
    {
      field: 'dueDate',
      headerName: 'Due Date',
      width: 200,
    },
    {
      field: 'otherStudent',
      headerName: 'Other Student',
      width: 250,
    },
    {
      field: 'expectedCommission',
      headerName: 'Expected Comm.',
      width: 210,
    },
    {
      field: 'id',
      headerName: 'Edit',
      width: 50,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <Tooltip
          id='tooltip-top'
          title='Edit Commission'
          placement='top'
          // classes={{ tooltip: classes.tooltip }}
        >
          {isInternal ? (
            <IconButton
              disabled={!isInternal}
              aria-label='Edit'
              // className={classes.tableActionButton}
              onClick={() => edit(params.row)}
              size='large'
            >
              <Edit
              // className={classes.tableActionButtonIcon + ' ' + classes.edit}
              />
            </IconButton>
          ) : (
            <Typography></Typography>
          )}
        </Tooltip>
      ),
    },
  ]
  useEffect(() => {
    getRecords()
  }, [])

  const getRecords = () => {
    Agent.Agents.listCommission(id)
      .then((c) => {
        c.forEach(function (item) {
          item.dueDate = format(new Date(item.dueDate), 'MMMM d, yyyy')
        })
        setComms(c)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        debugger
      })
  }

  const addCommission = () => {
    setCommission({agentId: id, id: 0})
    setDialogMode(true)
  }

  return (
    <>
      {loading ? (
        <LoadingView />
      ) : (
        <Grid container direction='column' spacing={2}>
          {isInternal && (
            <Grid item container justifyContent='flex-end'>
              <Button
                className='bg-sky-400 hover:bg-sky-500'
                variant='contained'
                startIcon={<PaymentIcon />}
                onClick={addCommission}
              >
                Add Commission
              </Button>
            </Grid>
          )}

          <Grid item xs={12}>
            <DataGridPro
              autoHeight
              disableSelectionOnClick
              rows={comms}
              columns={columns}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </Grid>
        </Grid>
      )}
      {dialogMode && (
        <Commission
          commissionParent={commission}
          setAddMode={setDialogMode}
          updateParent={updateParent}
        />
      )}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isInternal: state.auth.isInternal,
    id: ownProps.id,
  }
}

export default connect(mapStateToProps, null)(Commissions)
