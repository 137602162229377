/* eslint-disable react/jsx-no-target-blank */
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import SendIcon from '@mui/icons-material/Send';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Typography, Grid, Divider } from '@mui/material';

import { ButtonGroup } from '@mui/material';
import { Button } from '@mui/material';
import Agent from '../../../../api/agent';
import { Avatar } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ProcessDownloadedFile } from '../../../../Util/FileProcessing';
import DescriptionIcon from '@mui/icons-material/Description';
import ConfirmationModal from '../../../Common/Modal/ConfirmationModal';
import { LoadingButton } from '@mui/lab';
// import { format, addMinutes } from 'date-fns';
import MySnackbarAlert from '../../../Common/AlertMessage/MySnackbarAlert';
import AddManual from './AddManual';
import SendContract from './SendContract';
import LoadingView from '../../../Common/Loading/LoadingView';
import moment from 'moment/moment';

const PREFIX = 'Documents';

const classes = {
  size: `${PREFIX}-size`,
  formControl: `${PREFIX}-formControl`,
  selectEmpty: `${PREFIX}-selectEmpty`,
  panelHeader: `${PREFIX}-panelHeader`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.size}`]: {
    width: (props) => theme.spacing(props.size ? props.size : 10),
    height: (props) => theme.spacing(props.size ? props.size : 10),
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
  },

  [`& .${classes.selectEmpty}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.panelHeader}`]: {
    backgroundColor: theme.palette.common.lightBlue,
    minHeight: '0px !important',
    height: 40,
  },
}));

const ContractDocuments = ({ agentId, isInternal, agent }) => {
  const [loading, setLoading] = useState(false);
  const [fileSelected, setFileSelected] = useState();
  const [files, setFiles] = useState([]);
  const [sendMode, setSendMode] = useState(false);
  const [addMode, setAddMode] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);
  const [fileToDelete, setFileToDelete] = useState({});
  const [existingInfo, setExistingInfo] = useState({});

  const [deleteConfirmTitleText, setDeleteConfirmTitleText] = useState();
  const [deleteConfirmText, setDeleteConfirmText] = useState();
  const [deleting, setDeleting] = useState(false);

  //#snackbar stuff
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [alertMsg, setAlertMsg] = useState();
  //#endregion
  const showAlert = (success) => {
    setAlertMsg(success ? 'Successfully actioned' : 'Failed to action');
    setOpenSuccess(success);
    setOpenFailure(!success);
  };

  useEffect(() => {
    getAgreements();
  }, []);

  const getAgreements = () => {
    setLoading(true);
    Agent.Agents?.getAgreements(agentId)
      .then((docs) => {
        // console.log('file info', docs);
        setFiles(docs);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        // debugger;
      });
  };

  const deleteFile = async () => {
    setDeleting(true);
    await Agent.Agents.deleteAgreement(
      fileToDelete.id,
      fileToDelete.fileName,
      fileToDelete.isManual
    )
      .then(() => {
        setDeleting(false);
        getAgreements();
        setOpenConfirm(false);
      })
      .catch((error) => {
        setDeleting(false);
        console.log(error);
        debugger;
      });
  };

  return loading ? (
    <LoadingView />
  ) : (
    <Root>
      {addMode && isInternal && (
        <AddManual
          agentId={agentId}
          setRecordAddMode={setAddMode}
          getAgreementsParent={getAgreements}
        />
      )}
      {sendMode && isInternal && (
        <SendContract
          agentId={agentId}
          setRecordAddMode={setSendMode}
          getAgreementsParent={getAgreements}
          existingInfo={existingInfo}
        />
      )}
      <Grid container direction="column">
        {isInternal && (
          <Grid
            item
            style={{ marginBottom: 30 }}
            container
            spacing={2}
            justifyContent={'end'}
          >
            <Grid item>
              <Button
                color="primary"
                variant="outlined"
                className={classes.button}
                onClick={() => {
                  setExistingInfo({});
                  setSendMode(true);
                }}
                startIcon={<SendIcon />}
              >
                Send Contract for Signing
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="outlined"
                className={classes.button}
                startIcon={<CloudUploadIcon />}
                onClick={() => setAddMode(true)}
              >
                Upload Signed Contract
              </Button>
            </Grid>
          </Grid>
        )}

        <Grid item>
          {files?.map((file, index) => (
            <Grid item container key={index} direction="column">
              <Grid item container spacing={6} style={{ marginBottom: 10 }}>
                <Grid
                  item
                  xs={8}
                  container
                  spacing={3}
                  style={{ marginBottom: 0 }}
                >
                  <Grid item xs={2}>
                    <Avatar alt="doc" className={classes.size} variant="square">
                      <DescriptionIcon color="primary" />
                    </Avatar>
                  </Grid>
                  <Grid item xs={10} container direction="column" spacing={1}>
                    <Grid item>
                      <Typography
                        textAlign="left"
                        as={Button}
                        style={{
                          padding: 0,
                          textDecoration: isInternal ? 'underline' : null,
                          textTransform: 'none',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setExistingInfo({ ...file, id: file?.templateId });
                          setSendMode(true);
                        }}
                      >
                        {file.fileNameLocal}
                      </Typography>
                    </Grid>
                    {file.status && (
                      <Grid item style={{ paddingTop: 0 }}>
                        <Typography
                          color={'secondary'}
                          variant="caption"
                        >{`Current status: ${file.status}`}</Typography>
                      </Grid>
                    )}
                    <Grid item style={{ paddingTop: 0 }}>
                      {/*                       
                      <Typography variant="caption">
                        
                        {`${!file.sent ? 'Manually added' : 'Added'} on: ${
                          file.sent

                            ? format(
                                addMinutes(
                                  new Date(file.sent),
                                  new Date().getTimezoneOffset()
                                ),
                                'MMMM d, yyyy h:mm a'
                              )
                            : format(new Date(), 'MMMM d, yyyy h:mm a')
                        }, by ${file.sender}`}
                        
                      </Typography> */}
                      {/* 2024-01-19T12:32:04.000Z */}

                      <Typography variant="caption">
                        {`${!file.sent ? 'Manually added' : 'Added'} on: ${
                          file.sent
                            ? moment(file.sent).format('MMMM D, YYYY h:mm A')
                            : ''
                        }, by ${file.sender}`}
                      </Typography>
                    </Grid>

                    {file.partnerEmail && (
                      <Grid item style={{ paddingTop: 0 }}>
                        <Typography variant="caption">{`Partner email: ${file.partnerEmail}`}</Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={4}>
                  <ButtonGroup
                    size="small"
                    color="primary"
                    aria-label="large outlined primary button group"
                  >
                    <LoadingButton
                      disabled={!file.link}
                      loading={loading && fileSelected == file.fileName}
                      onClick={() => {
                        setFileSelected(file.fileName);
                        setLoading(true);
                        Agent.Agents.downloadAgreement(file.fileName)
                          .then((response) =>
                            ProcessDownloadedFile(response, file.fileNameLocal)
                          )
                          .finally(() => {
                            setLoading(false);
                          });
                      }}
                      startIcon={<CloudDownloadIcon />}
                    >
                      Download
                    </LoadingButton>
                    {/* {isInternal && (
                      <Button
                        variant="contained"
                        className="bg-blue-500"
                        color={
                          file.completed || !file.partnerEmail
                            ? 'secondary'
                            : 'primary'
                        }
                        onClick={() => {
                          if (file.completed || !file.partnerEmail) {
                            setDeleteConfirmText(
                              'Are you sure you want to DELETE this agreement?'
                            );
                            setDeleteConfirmTitleText('Delete Agreement');
                          } else {
                            setDeleteConfirmText(
                              'Are you sure that you would like to cancel this agreement signing?'
                            );
                            setDeleteConfirmTitleText(
                              'Cancel Agreement Signing'
                            );
                          }
                          setOpenConfirm(true);
                          setFileToDelete({
                            id: file.id,
                            fileName: file.fileName,
                            isManual: !file.sent,
                          });
                        }}
                        startIcon={<DeleteForeverIcon />}
                      >
                        {file.completed || !file.partnerEmail
                          ? 'Delete'
                          : 'Cancel'}
                      </Button>
                    )} */}

                    {isInternal && (
                      <button
                        className={`${
                          file.completed || !file.partnerEmail
                            ? 'bg-red-500 text-white' // secondary color
                            : 'bg-blue-500 text-white' // primary color
                        } px-4 py-2 rounded-full`}
                        onClick={() => {
                          if (file.completed || !file.partnerEmail) {
                            setDeleteConfirmText(
                              'Are you sure you want to DELETE this agreement?'
                            );
                            setDeleteConfirmTitleText('Delete Agreement');
                          } else {
                            setDeleteConfirmText(
                              'Are you sure that you would like to cancel this agreement signing?'
                            );
                            setDeleteConfirmTitleText(
                              'Cancel Agreement Signing'
                            );
                          }
                          setOpenConfirm(true);
                          setFileToDelete({
                            id: file.id,
                            fileName: file.fileName,
                            isManual: !file.sent,
                          });
                        }}
                      >
                        <span className="flex items-center">
                          <DeleteForeverIcon className="mr-2" />
                          {file.completed || !file.partnerEmail
                            ? 'Delete'
                            : 'Cancel'}
                        </span>
                      </button>
                    )}
                  </ButtonGroup>
                </Grid>
              </Grid>
              <Grid item style={{ marginBottom: 10 }}>
                <Divider />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={deleteFile}
        confirmText={deleteConfirmTitleText}
        content={deleteConfirmText}
        loading={deleting}
      />
      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
          autoHideDuration={1000}
        />
      )}
    </Root>
  );
};

export default ContractDocuments;
