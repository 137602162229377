import { SendOutlined } from '@mui/icons-material';
import {
  Avatar,
  Divider,
  Fab,
  IconButton,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { format, addMinutes } from 'date-fns';
import ReplyIcon from '@mui/icons-material/Reply';
import parse from 'html-react-parser';
import { connect } from 'react-redux';
import Send from '@mui/icons-material/Send';
import Agent from '../../api/agent';
import EditorDraftJs from '../Common/Editor/EditorDraftJs';
import { findIndex } from 'lodash';

const Chat = ({
  messages,
  mainMessageSubject = '',
  applicationId = 0,
  user,
  setNoteCount,
  isMessageOpen,
  hideReplyOption = false,
  appUserId,
}) => {
  const [replyMode, setReplyMode] = useState(false);
  const [replyMessage, setReplyMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reply, setReply] = useState({});
  const [editorStateValid, setEditorStateValid] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  // const [userName, setUserName] = useState('');
  const [messageId, setMessageId] = useState();
  const [fromId, setFromId] = useState();
  const [sendEmail, setSendEmail] = useState(false);
  const [content, setContent] = useState('');
  const [messageLength, setMessageLength] = useState(messages.length);

  console.log('messages chat', messages);
  const sendReply = () => {
    var replyMsg = {
      messageId: messages[0]?.messageId,
      subject: messages[0]?.subject,
      content: content,
      sendEmail: false,
    };
    Agent.Messages.add(replyMsg)
      .then(() => {
        setContent('');
        setReplyMessage(true);
        let temp = [];
        replyMsg = {
          ...reply,
          ...{
            messageId: messages[0]?.messageId,
            from:
              appUserId == messages[0]?.fromId
                ? messages[0]?.from
                : messages[0]?.to,
            content: content,
            sendEmail: false,
            dateSent: format(
              addMinutes(new Date(), new Date().getTimezoneOffset()),
              'MMMM d, yyyy h:mm:ss a'
            ),
          },
        };

        // debugger;
        temp.push(replyMsg);
        allMessages.map((item) => temp.push(item));
        temp.sort(function (a, b) {
          return new Date(a.dateSent) - new Date(b.dateSent);
        });
        console.log('temp', temp);
        setAllMessages(temp);
        if (setNoteCount) {
          setNoteCount(temp.length);
        }
      })
      .catch((error) => {
        console.log(error);
        debugger;
        setLoading(false);
      });
  };

  useEffect(() => {
    setAllMessages(
      messages.sort(function (a, b) {
        return new Date(a.dateSent) - new Date(b.dateSent);
      })
    );
  }, [messages]);
  return (
    <>
      {isMessageOpen && (
        <>
          <Grid item xs={12}>
            <Divider />
            <Grid container className="pl-5 p-6 py-2">
              <Grid item xs={9}>
                <Typography className="text-xl">Conversations</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Divider />
        </>
      )}
      <div id="chatbox" className="p-2 h-[720px] overflow-y-auto">
        {isMessageOpen && (
          <div>
            <Grid container component={Paper} className="w-[100%] h-[80%] pt-8">
              {allMessages.map((message, index) => (
                <Grid
                  className="bg-white"
                  key={message.id}
                  item
                  container
                  direction="column"
                  //style={{ marginBottom: 10, marginTop: 5 }}
                >
                  {/*{console.log('message length',message.id)} */}
                  <Grid item xs={9}>
                    {index + 1 > messageLength && replyMessage ? (
                      <>
                        <List>
                          <Grid>
                            {' '}
                            <ListItemText align="right" className="pr-6">
                              {' '}
                              {message.from}
                            </ListItemText>
                          </Grid>
                          <ListItem key="1">
                            <Grid item xs={12}></Grid>
                            <Grid
                              container
                              className=" bg-blue-300 pr-4 py-3 rounded-md"
                            >
                              <Grid item xs={12}>
                                <ListItemText
                                  align="right"
                                  className="text-left pl-4"
                                >
                                  {message.content
                                    ? parse(message.content)
                                    : ''}
                                </ListItemText>
                              </Grid>
                              <Grid item xs={12}>
                                <ListItemText
                                  align="right"
                                  className="text-blue-700"
                                >
                                  {format(
                                    addMinutes(
                                      new Date(message.dateSent),
                                      -new Date().getTimezoneOffset()
                                    ),
                                    'MMMM d, yyyy h:mm a'
                                  )}
                                </ListItemText>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </List>
                      </>
                    ) : message.fromId != appUserId ? (
                      <List>
                        <ListItem> {message.from} </ListItem>
                        <ListItem
                          key="1"
                          className="w-[45%] ml-4 rounded-md bg-sky-800 text-gray-200"
                        >
                          <Grid container>
                            <Grid item xs={12}>
                              <ListItemText align="left">
                                {' '}
                                {message.content ? parse(message.content) : ''}
                              </ListItemText>
                            </Grid>
                            <Grid item xs={12}>
                              <ListItemText
                                align="left"
                                className="text-right text-lime-500 pr-4"
                              >
                                {format(
                                  addMinutes(
                                    new Date(message.dateSent),
                                    -new Date().getTimezoneOffset()
                                  ),
                                  'MMMM d, yyyy h:mm a'
                                )}
                              </ListItemText>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </List>
                    ) : (
                      <List>
                        <Grid>
                          {' '}
                          <ListItemText align="right" className="pr-6">
                            {' '}
                            {message.from}
                          </ListItemText>
                        </Grid>
                        <ListItem key="1">
                          <Grid item xs={12}></Grid>
                          <Grid
                            container
                            className=" bg-blue-300 pr-4 py-3 rounded-md"
                          >
                            <Grid item xs={12}>
                              <ListItemText
                                align="right"
                                className="text-left pl-4"
                              >
                                {' '}
                                {message.content ? parse(message.content) : ''}
                              </ListItemText>
                            </Grid>
                            <Grid item xs={12}>
                              <ListItemText
                                align="right"
                                className="text-blue-700"
                              >
                                {format(
                                  addMinutes(
                                    new Date(message.dateSent),
                                    -new Date().getTimezoneOffset()
                                  ),
                                  'MMMM d, yyyy h:mm a'
                                )}
                              </ListItemText>
                            </Grid>
                          </Grid>
                        </ListItem>
                      </List>
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </div>
        )}
      </div>
      {isMessageOpen && (
        <>
          <Grid item xs={12}>
            <Grid container className="pl-2 w-full py-2 pt-2">
              <Grid item xs={10}>
                <TextField
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  id="outlined-basic-email"
                  label="Type Something"
                  fullWidth
                />
              </Grid>
              <button
                onClick={() => sendReply()}
                className="pt-1 my-1 bg-blue-500 ml-2 rounded-sm text-gray-200 px-3 hover:bg-blue-600"
              >
                Send&nbsp;
                <Send />
              </button>
            </Grid>
          </Grid>
          <Divider />
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps)(Chat);
