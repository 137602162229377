import React from 'react';
import Avatar from '@mui/material/Avatar';
import { getInitialsFromFullName } from '../../../Util/Util';
import { useTheme } from '@mui/material';

const AvatarMui = ({ imageUrl, fullName, size, agent = '', ...props }) => {
  const theme = useTheme();

  const sx = {
    width: theme.spacing(size ? size : 5),
    height: theme.spacing(size ? size : 5),
    bgcolor: '#fff',
    color: '#0A2647',
    fontSize: `${agent.length ? '2.5rem' : ''}`,
    border: '2px',
    boxShadow:
      '0px 0px 20px 2px rgba(0, 0, 0, 0.05), 0 1px 20px 0 rgba(0, 0, 0, 0.1)',
  };
  return imageUrl ? (
    <Avatar alt="photo" src={imageUrl} sx={sx} {...props} />
  ) : fullName ? (
    <Avatar sx={sx} {...props}>
      {getInitialsFromFullName(fullName)}
    </Avatar>
  ) : (
    <Avatar sx={sx} src="/broken-image.jpg" {...props} />
  );
};
export default AvatarMui;
