import {Typography, Grid, ButtonGroup, Button} from '@mui/material'
import React, {useState} from 'react'
import Agent from '../../../api/agent'
import PhotoWidgetDropzone from '../../Common/ImageUpload/PhotoWidgetDropzone'
import LinearIndeterminate from '../../Common/Progress/LinearIndeterminate'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {DownloadCloudFile} from '../../../Util/FileProcessing'
import ConfirmationModal from '../../Common/Modal/ConfirmationModal'
import {LoadingButton} from '@mui/lab'
import * as actions from '../../../store/actions';
import { connect } from 'react-redux'

const AgentDocument = (props) => {
  const [agent, setAgent] = useState(props.agent)
  const [updating, setUpdating] = useState(false)
  const [error, setError] = useState()
  const [file, setFile] = useState()
  let agreementFiles = agent.agentCloudFiles.filter(
    (file) => file.documentType == 17
  )
  const [showAgreement, setShowAgreement] = useState(agreementFiles.length > 0)
  const [openConfirm, setOpenConfirm] = useState(false)
  const uploadToCloud = () => {
    let fileZie = file.size / 1024 / 2024
    if (fileZie > 5) {
      return
    }
    setUpdating(true)
    completeUpload(agent.id)
  }

  const completeUpload = (id) => {
    Agent.CloudFiles.upload(file, 17, id, 0, '', 0)
      .then((response) => {
        var file = {
          fileUrl: response.blobUri,
          documentType: 17,
          fileName: response.blobName,
          fileNameLocal: response.fileNameLocal,
          containerName: response.containerName,
        }
        agreementFiles = file
        setFile(file)
        const modifiedAgent = {
          ...agent,
          ...{
            agentCloudFiles: agent.agentCloudFiles.concat(file),
          },
        }
        setAgent(modifiedAgent)
        props.setAgentParent(modifiedAgent)
        setUpdating(false)
        setShowAgreement(true)
      })
      .catch((error) => {
        console.log(error)
        setUpdating(false)
        setError(
          'Error: Failed to upload. Check file type/size etc and try again!'
        )
      })
  }

  const upload = (file) => {
    const harmful = ['exe', 'pif', 'bat']
    setFile(file)
    let fileZie = file.size / 1024 / 2024
    if (fileZie > 5) {
      setError('Error: File size cannot be more than 5 MB')
      return
    }
    const extension = file.name.split('.').pop()
    if (harmful.includes(extension.toLowerCase())) {
      setError(
        'Error: Unsupported file type. You can only upload a valid document file type'
      )
      return
    }
    setError(null)
  }
  const handleFileDelete = () => {
    setShowAgreement(false)
    setFile(null)
    setOpenConfirm(false)
    const modifiedAgent = {
      ...agent,
      ...{
        agentCloudFiles: agent.agentCloudFiles.filter(
          (file) => file.documentType != 17
        ),
      },
    }
    setAgent(modifiedAgent)
    agreementFiles = null
    props.setAgentParent(modifiedAgent)
  }
  const deleteFile = () => {
    const fileName = agreementFiles[0].fileName
    Agent.CloudFiles.delete(fileName, agent.id, 0, 0)
      .then(() => {
        handleFileDelete()
      })
      .catch((error) => {
        console.log(error)
        debugger
      })
  }

  return (
    <>
      {showAgreement ? (
        <Grid item container direction='column' spacing={2}>
          <Grid item>
            <Typography variant='h6'>Agreement Document</Typography>
          </Grid>
          <Grid item>
            <Typography color='primary'>{`File Name: ${agreementFiles[0].fileNameLocal}`}</Typography>
          </Grid>
          <Grid item>
            <ButtonGroup size='small' color='primary'>
              <LoadingButton
                loading={updating}
                onClick={() => {
                  setUpdating(true)
                  DownloadCloudFile(
                    agreementFiles[0].fileNameLocal,
                    agreementFiles[0].fileName,
                    agreementFiles[0].containerName
                  ).then(() => {
                    setUpdating(false)
                  })
                }}
                startIcon={<CloudDownloadIcon />}
              >
                Download
              </LoadingButton>

              {props.admin && props.permissions.CanDeleteAgentAgreement &&  (
                <Button
                  disabled={error}
                  variant='contained'
                  className='bg-red-700'
                  onClick={() => setOpenConfirm(true)}
                  startIcon={<DeleteForeverIcon />}
                >
                  Delete
                </Button>
              )}
            </ButtonGroup>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container direction='column' spacing={3}>
            {updating && (
              <Grid item>
                <LinearIndeterminate />
              </Grid>
            )}
            <Grid item container direction='column' spacing={1}></Grid>
            <Grid item>
              <Typography variant='h6'>Upload Agreement Document</Typography>
            </Grid>
            {agent.status == 1 && (
              <Grid item>
                <Typography color='secondary' variant='caption'>
                  You cannot currently upload any document as the agent profile
                  is incomplete
                </Typography>
              </Grid>
            )}
            <Grid item container spacing={3}>
              <Grid item xs={6}>
                <PhotoWidgetDropzone
                  crop={false}
                  upload={upload}
                  message='Drop your document here'
                  disabled={agent.status == 1}
                />
              </Grid>
              {file && (
                <Grid item xs={6} container direction='column' spacing={4}>
                  <Grid item container direction='column'>
                    {error && (
                      <Grid
                        item
                        style={{
                          color: 'red',
                          marginBottom: 20,
                          fontWeight: 'bold',
                        }}
                      >
                        {error}
                      </Grid>
                    )}
                    <Grid item>
                      <Typography>{`File name: ${file.name}`}</Typography>
                    </Grid>
                    <Grid item>
                      <Typography>{`File Size ${
                        file.size / 1024
                      } KiB`}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <ButtonGroup
                      orientation='vertical'
                      size='large'
                      aria-label='large button group'
                    >
                      <Button
                        variant='contained'
                        className='bg-rose-400 hover:bg-rose-500 mb-2'
                        onClick={() => {
                          setFile(null)
                        }}
                      >
                        CHOOSE ANOTHER
                      </Button>
                      <Button
                        disabled={error || agent.status == 1}
                        variant='contained'
                        className='bg-sky-400 hover:bg-sky-500'
                        onClick={uploadToCloud}
                      >
                        SAVE
                      </Button>
                    </ButtonGroup>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          {updating && (
            <Grid item style={{marginBottom: 20}}>
              <LinearIndeterminate />
            </Grid>
          )}
        </>
      )}
      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={deleteFile}
        confirmText='Confirm Action'
        content='Are you sure that you would like to delete this document?'
      />
    </>
  )
}


const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    isInternal: state.auth.isInternal,
    role: state.auth.role,
    users: state.users,
    name: state.auth.name,
    permissions: state.auth.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentDocument);

