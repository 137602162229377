import { combineReducers } from 'redux';

import auth from './auth';
import students from './students';
import users from './user';
import agents from './agent';
import common from './common';
import summary from './summary';
import courses from './course';
import courseallocations from './courseallocation';
import management from './management';
import departments from './departments';

export default combineReducers({
  management,
  summary,
  auth,
  students,
  users,
  agents,
  common,
  courses,
  courseallocations,
  departments,
});
