/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Draggable from 'react-draggable';
import DatePicker from 'react-datepicker';
import { startOfDay, addHours, addMonths, format } from 'date-fns';
import { Announcement_TYPE, User_TYPE_Announcement } from '../../../Util/Constants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { formats, modules } from '../../../Util/Util';
import {Chip} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import MyTooltip from '../../Common/Tooltip/MyTooltip';
import Agent from '../../../api/agent';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const EditAnnouncement = ({
  announcement,
  action,
  cancel,
  setReload
}) => {
  const [ready, setReady] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [content, setContent] = useState(announcement?.content);
  const [title, setTitle] = useState(announcement?.title);
  const [announcementTypeId, setAnnouncementTypeId] = useState(announcement?.announcementTypeId);
  const [userTypeId, setUserTypeId] = useState();
  const [publishDate, setPublishDate] = useState(announcement?.publishDate && new Date(announcement?.publishDate)||null);
  const [expiryDate, setExpiryhDate] = useState(announcement?.expiryDate && new Date(announcement?.expiryDate)||null);
  const [userTypeChanged, setUserTypeChanged]=useState(false);
  const [announcementTypeList, setAnnouncementTypeList] = useState([]);
  const editor = useRef(null);
  const [error,setError]=useState('');
  const [success,setSuccess]=useState('');
  const confirm = () => {
  action({
      id:announcement.id,
      title: title,
      isInactive :announcement.isInactive,
      userTypeId : userTypeId,
      content: content,
      publishDate:publishDate,
      expiryDate:expiryDate,
      announcementTypeId: announcementTypeId,
      addedBy: announcement.addedBy,
      addedOn: announcement.addedOn,
      link:announcement.link,
    });
  };
  const assignUserType = () => {
    setReload(false);
    const assignDto = {
       id: announcement.id,
       userTypeId: [parseInt(userTypeId)],
       userTypeChanged: userTypeChanged
    };
    Agent.Announcement.assign(assignDto)
      .then(() =>{
        setReload(true);
        setSuccess('UserType successfully assign')
      })
      .catch((error) => {
        if (error.includes('UserType already exist in this announcement!!')){
          setError(error)
        }
        console.log(error);
      });

  };
  useEffect(()=>{
    Agent.Announcement.getAnnouncementType().then((res)=>{
    const temp= res.sort(function (a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    })
      setAnnouncementTypeList(temp);
    })
  },[]);
    const handleDelete = (user) => {
    setReload(false);
    Agent.Announcement.deleteUserType(user.announcementId, user.userTypeId).then(()=>{
      setReload(true);
      setSuccess('Delete successfully !!')
    })
    };
  const handleClose = () => {
    setDialogOpen(false);
    cancel(false);
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        open={dialogOpen}
        maxWidth="md"
        npn
        fullWidth
        PaperComponent={PaperComponent}
      >
       <BootstrapDialogTitle onClose={handleClose} style={{ cursor: 'move' }}>
          <Typography align="center" className='pb-4 pl-4' color="secondary" variant="h6">
            {title}
          </Typography>
          
          <Divider />
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            fullWidth
            spacing={1}
            style={{paddingLeft: 6 ,paddingBottom : 6}}
          >
            <div className='pt-4'>
          <FormControl fullWidth variant="outlined">
            <TextField
              label="Title"
              variant="outlined"
              size="small"
              fullWidth
              value={title}
              onChange={(e)=>{setTitle(e.target.value);setReady(true);}}
              className="focus:ring-2 focus:ring-blue-500"
            />
            </FormControl>
            </div>
            <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
              <FormControl fullWidth variant="outlined">
                <InputLabel>Announcement Type</InputLabel>
                <Select
                  label="Announcement Type"
                  size="small"
                  value={announcementTypeId}
                  onChange={(e) =>{
                    setAnnouncementTypeId(e.target.value);setReady(true);}
                  }
                  className="focus:ring-2 focus:ring-blue-500"
                >
                  <MenuItem value={0}>Select Announcement Type</MenuItem>
                  {announcementTypeList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined">
             <DatePicker
              autoComplete="off"
              locale="enGB"
              label="Expiry Date"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(expiryDate && new Date(expiryDate)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setExpiryhDate(newVal);
                setReady(true);
              }}
              customInput={
                <TextField
                  label="Expiry Date"
                  fullWidth
                  value={expiryDate}
                  variant="outlined"
                  size="small"
                />
              }
            />
              </FormControl>
              </div>
              {announcement.announcementAssignments.length== 0 && <br/>}
              <div className='mt-4'>
              <Grid container direction="column" spacing={3}>
                  {announcement.announcementAssignments.length > 0 ? (
                    <Grid item style={{ marginBottom: 30 }}>
                      {announcement.announcementAssignments?.map((user, index) => (
                        <Chip
                          key={index}
                          color="primary"
                          label={User_TYPE_Announcement.find(({ id }) => id == user.userTypeId)?.type}
                          onDelete={()=>handleDelete(user)}
                          deleteIcon={
                            <MyTooltip
                              title={User_TYPE_Announcement.find(({ id }) => id == user.userTypeId)?.type} 
                            >
                               <IconButton
                                 color="primary"
                                 size="small"
                               >
                                 <CancelIcon />
                               </IconButton>
                             </MyTooltip>
                           } 
                           variant="outlined"
                         />
                      ))}
                    </Grid>
                  ) : null}
              </Grid>    
             <div className='flex justify-between'>
              <div className='w-[20%]'>
                <Typography className='text-lg'>Assign User</Typography>
              </div>
              <Grid item container justifyContent="start" >
              <Select
                  label="Applicable User"
                  size="small"
                  value={userTypeId||0}
                  onChange={(e) =>{
                    setUserTypeId(e.target.value);
                    setUserTypeChanged(true);
                    setError('');
                    setSuccess('');
                    }
                  }
                >
                  <option value={0}>Select Applicable User</option>
                  {User_TYPE_Announcement.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.type}
                    </MenuItem>
                  ))}
                </Select>

                <div className='w-[10px]'></div>
                <Button
                    color="primary"
                    variant="outlined"
                    onClick={assignUserType}
                  >
                    Assign
                  </Button>
              </Grid>
            </div>
            {error&&<div className='text-red-600 mt-2'>{error}</div>}
            {success&&<div className='pl-8 text-teal-700 pt-2'>{success}</div>}
              {/* <FormControl fullWidth variant="outlined">
                <InputLabel>Applicable User</InputLabel>
                <Select
                  label="Applicable User"
                  size="small"
                  value={userTypeId}
                  onChange={(e) =>{
                    setUserTypeId(e.target.value);setReady(true);}
                  }
                  className="focus:ring-2 focus:ring-blue-500"
                >
                  <MenuItem value={0}>Select Applicable User</MenuItem>
                  {User_TYPE_Announcement.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            </div>
            {/* <div className='pt-4'>
            <FormControl fullWidth variant="outlined">
            <DatePicker
              autoComplete="off"
              label="Publish Date"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(publishDate && new Date(publishDate)) || null }
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setPublishDate(newVal);
                setReady(true);
              }}
              customInput={
                <TextField
                  label="Publish Date"
                  fullWidth
                  value={publishDate}
                  variant="outlined"
                  size="small"
                />
              }
            />
            </FormControl>
            </div> */}
        
            <div className='pt-2'>
            <InputLabel className='pl-2 font-semibold'>Content</InputLabel>
             <ReactQuill 
              theme="snow" 
              fullWidth
              value={content} 
              modules={modules}
              formats={formats}
              onChange={newContent => {setContent(newContent);setReady(true)}}
              className='h-[450px] pb-12'
             />
            </div>
          </Grid>
        </DialogContent>
        <DialogActions className='pb-6'>
          <Button
            disabled={!ready}
            autoFocus
            onClick={confirm}
            variant="contained"
            className="bg-sky-400 hover:bg-sky-500 mr-5"
          >
            Confirm Selection
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
