/* eslint-disable react/display-name */
import React, { useState, useEffect, useContext } from 'react';

import * as actions from '../../store/actions/index';
import {
  STATUS_APPROVED,
  MUI_LICENSE_KEY,
  STUDENTSTATUS,
  STUDENTSOURCES,
  LEADORIGINS,
  LEADCHANNELS,
  GENDER,
  StudentLead_Activities,
  COMPLIANCE_STATUS,
  STUDY_LEVEL_GROUPS,
} from '../../Util/Constants';
import {
  LicenseInfo,
  DataGridPro,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridPagination,
} from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MyTooltip from '../Common/Tooltip/MyTooltip';
import EditIcon from '@mui/icons-material/Edit';
import LoadingView from '../Common/Loading/LoadingView';
import Agent from '../../api/agent';
import { connect } from 'react-redux';
import UserContext from '../../context/user-context';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // the locale you want
registerLocale('en-GB', enGB); // register it with the name you want
import 'react-datepicker/dist/react-datepicker.css';
import { startOfDay, addHours, addMonths } from 'date-fns';
import { Countries } from '../../Util/Util';
import { StudentAssignment } from '../Common/Widgets/StudentAssignment';
import StudentNotes from './StudentNotes/StudentNotes';
import Description from '@mui/icons-material/Description';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { NotInterested } from '@mui/icons-material';

function CustomToolbar(show, isInternal) {
  return (
    <GridToolbarContainer>
      {isInternal && <GridToolbarColumnsButton />}
      {isInternal && <GridToolbarFilterButton />}
      <GridToolbarDensitySelector />
      {show && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

const StudentList = ({
  users,
  role,
  permissions,
  loadUsers,
  agents,
  loadAgents,
  isInternal,
  agentId = 0,
  referralId = 0,
  userName,
}) => {
  // const classes = useStyles();

  const navigate = useNavigate();
  let userContext = useContext(UserContext);
  const [studentLoaded, setStudentLoaded] = useState(false);
  const [name, setName] = useState('');
  const [passportNumber, setPassportNumber] = useState('');
  const [id, setId] = useState('');
  const [email, setEmail] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [start, setStart] = useState(addMonths(new Date(), -1));
  const [end, setEnd] = useState(new Date());
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState();
  const [bulkUpdate, setBulkUpdate] = useState();
  const [page, setPage] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(800);
  const [totalPages, setTotalPages] = useState();
  const [totalrows, setTotalRows] = useState(0);

  const [selectionModel, setSelectionModel] = useState([]);
  const [ready, setReady] = useState(false);
  const [offices, setOffices] = useState([]);
  const [branchOfficeId, setBranchOfficeId] = useState(0);
  const [dateType, setDateType] = useState(1);
  const [studentStatus, setstudentStatus] = useState(0);
  const [leadactivity, setLeadActivity] = useState(0);
  const [countryOfResidence, setCountryOfResidence] = useState(0);
  const [TeamLeader, setTeamLeader] = useState(0);
  const [assessmentTeam, setAssessmentTeam] = useState(0);
  const [followupTeamId, setFollowUpTeam] = useState(0);
  const [studentType, setStudentType] = useState(0);
  const [intake, setIntake] = useState(0);
  const [studyLevel, setStudyLevel] = useState(0);
  const [filteredTeamLeaders, setFilteredTeamLeaders] = useState([]);
  const [
    filteredFollowAndAssessmentTeams,
    setFilteredFollowAndAssessmentTeams,
  ] = useState([]);
  const [leadOrigin, setLeadOrigin] = useState(0);
  const [complianceStatus, setComplianceStatus] = useState(0);
  const [leadSource, setLeadSource] = useState(0);
  const [leadChannel, setLeadChannel] = useState(0);
  const [phone, setPhoneNumber] = useState('');
  const [leadStatus, setLeadStatus] = useState(0);
  const [openAddNote, setOpenAddNote] = useState(false);
  const [studentId, setStudentId] = useState('');
  const [referralList, setReferralList] = useState([{}]);
  const [AgentList, setAgentList] = useState();
  const [referralRelaod, setReferralReload] = useState(false);
  const [events, setEventList] = useState([]);
  const [eventId, setEventId] = useState(0);
  const [referralAssignId, setReferralAssignId] = useState(0);
  const [agentFilterId, setAgentFilterId] = useState(0);
  const [referralBDM, setReferralBDM] = useState(0);
  const [studyLevelFilter, setStudyLevelFilter] = useState('');

  const handleNote = (row) => {
    console.log('row', row);
    setOpenAddNote(true);
    setStudentId(row.id);
    //setMsgName(row.firstName + ' ' + row.lastName);
  };
  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  useEffect(() => {
    if (referralId > 0) {
      Agent.Referral.getreferralbyRefId(referralId).then((res) => {
        console.log('ref bdm', res);
        setReferralBDM(res.businessDevAppUserDetailsId);
      });
    }
  }, []);

  useEffect(() => {
    Agent.Referral.List().then((res) => {
      setReferralList(res);
    });
    Agent.Agents.list().then((res) => {
      setAgentList(res);
      console.log('agents', res);
    });
  }, []);
  //const updateData = (k, v) => setData((prev) => ({ ...prev, [k]: v }));  handle way to update an object state
  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', pageSize.toString());
    params.append('id', filter ? id.trim() : '');
    params.append('name', filter ? name.trim() : '');
    params.append('email', filter ? email.trim() : '');

    if (agentId > 0) {
      params.append('agentId', agentId.toString());
    }
    if (filter) {
      params.append('PhoneNumber', phone.toString());
    }

    if (referralBDM > 0) {
      params.append('ReferralBDM', referralBDM);
    }
    if (referralId > 0) {
      params.append('ReferralId', referralId.toString());
    }
    if (dateOfBirth && filter) {
      params.append('dateOfBirth', new Date(dateOfBirth).toDateString());
    }
    if (filter) {
      if (start) {
        params.append('startDate', new Date(start).toDateString());
      }
    } else {
      params.append('startDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (end) {
        params.append('endDate', new Date(end).toDateString());
      }
    } else {
      params.append('endDate', new Date().toDateString());
    }
    params.append('dateType', dateType);
    if (filter) {
      params.append('branchOfficeId', branchOfficeId);
    }
    if (filter) {
      params.append('Status', studentStatus);
    }
    if (filter) {
      params.append('LeadActivity', leadactivity);
    }
    if (filter) {
      params.append('PartnerReferralId', referralAssignId);
    }
    if (filter) {
      params.append('PartnerAgentId', agentFilterId);
    }
    if (filter) {
      params.append('ComplianceStatus', complianceStatus);
    }
    if (filter) {
      params.append('TeamLeader', TeamLeader);
    }
    if (filter) {
      params.append('eventId', eventId);
    }
    if (filter) {
      params.append('AssessmentTeamId', assessmentTeam);
    }
    if (filter) {
      params.append('FollowupTeamId', followupTeamId);
    }
    if (filter) {
      params.append('Source', leadSource);
    }
    if (filter) {
      params.append('Channel', leadChannel);
    }
    if (filter) {
      params.append('PassportNumber', passportNumber);
    }
    if (filter) {
      params.append('Origin', leadOrigin);
    }
    if (filter) {
      params.append('StudentType', studentType);
    }
    if (filter) {
      params.append('CountryOfResidence', countryOfResidence);
    }
    if (filter) {
      params.append('PreferenceStudyLevelId', studyLevelFilter); 
    }
    
    return params;
  };

  const getRows = (rows) => {
    let studentRows = [];
    rows?.map((student) => {
      studentRows.push({
        id: student.id,
        name: student.firstName + ' ' + student.lastName,
        email: student.email,
        citizenship:
          student.citizenship && student.citizenship > 0
            ? Countries.find(({ id }) => id === student.citizenship)?.name
            : '',
        residence:
          student.countryOfResidence && student.countryOfResidence > 0
            ? Countries.find(({ id }) => id === student.countryOfResidence)
                ?.name
            : '',
        applicationCount:
          student.noOfApplications == 0 ? '' : student.noOfApplications,
        appUserDetailsId: student.appUserDetailsId,
        assessmentTeamId: student.assessmentTeamId,
        complianceStatus: student.complianceStatus
          ? student.complianceStatus
          : null,
        complianceStatusName:
          student.complianceStatus && student.complianceStatus > 0
            ? COMPLIANCE_STATUS.find(
                ({ id }) => id === student.complianceStatus
              ).type
            : null,
        assessmentTeam:
          student.assessmentTeamId && users.length > 0
            ? users.find(({ id }) => id === student.assessmentTeamId)?.name
            : '',
        followupTeamId: student.followupTeamId,
        followUpTeam:
          student.followupTeamId && users.length > 0
            ? users.find(({ id }) => id === student.followupTeamId)?.name
            : '',
        complianceTeamId: student.complianceTeamId,
        complianceTeam:
          student.complianceTeamId && users.length > 0
            ? users.find(({ id }) => id === student.complianceTeamId)?.name
            : '',
        admin: student.adminName,
        agentId: student.agentId,
        agent: student.agentCompanyName,
        referralName: student.referralName,
        eventName: student.eventName,
        bdm:
          student.businessDevAppUserDetailsId && users.length > 0
            ? users.find(({ id }) => id === student.businessDevAppUserDetailsId)
                ?.name
            : '',
        refbdm:
          student.referralBDMAppUserDetailsId && users.length > 0
            ? users.find(({ id }) => id === student.referralBDMAppUserDetailsId)
                ?.name
            : '',
        profile: student.profileComplete,
        status: student.status,
        statusName: STUDENTSTATUS.find(({ id }) => id == student.status).type,
        branchOfficeId: student.branchOfficeId,
        branch: student.branchOfficeName,
        sourceId: student.source,
        source:
          student.source && student.source > 0
            ? STUDENTSOURCES.find(({ id }) => id == student.source).type
            : '',
        originId: student.origin,
        origin:
          student.origin > 0
            ? LEADORIGINS.find(({ id }) => id == student.origin).type
            : '',
        channelId: student.channel,
        channel:
          student.channel > 0
            ? LEADCHANNELS.find(({ id }) => id == student.channel).type
            : '',
        campaign: student.campaign,
        passport: student.passportNumber,
        gender:
          student.gender > 0
            ? GENDER.find(({ id }) => id == student.gender).type
            : '',
        phone: student.phoneNumber,
        dateOfBirth:
          student.dateOfBirth == null
            ? ''
            : new Date(student.dateOfBirth).toISOString().split('T')[0],

        regionalManagerId: student.regionalManagerId,
        regionalManager:
          student.regionalManagerId && users.length > 0
            ? users.find(({ id }) => id === student.regionalManagerId)?.name
            : '',
        countryManagerId: student.countryManagerId,
        countryManager:
          student.countryManagerId && users.length > 0
            ? users.find(({ id }) => id === student.countryManagerId)?.name
            : '',
        branchManagerId: student.branchManagerId,
        branchManager:
          student.branchManagerId && users.length > 0
            ? users.find(({ id }) => id === student.branchManagerId)?.name
            : '',
        virtualTeamId: student.virtualTeamId,
        virtualTeam:
          student.virtualTeamId && users.length > 0
            ? users.find(({ id }) => id === student.virtualTeamId)?.name
            : '',
        preferenceStudyLevel:
            student.preferenceStudyLevelId &&
            student.preferenceStudyLevelId > 0
            ? STUDY_LEVEL_GROUPS.find(
              ({ id }) => id == student.preferenceStudyLevelId
            )?.type
            : '',
        isLogin: student.isLoginCreated,
        studentLeadId: student.studentLeadId,
        referralId: student.referralId,
        leadactivityName:
          student.leadActivity && student.leadActivity > 0
            ? StudentLead_Activities.find(
                ({ id }) => id === student.leadActivity
              )?.name
            : '',
        createdOn:
          student.createdOn == null
            ? ''
            : new Date(student.createdOn).toISOString().split('T')[0],
        statusupdatedon:
          student.statusUpdatedOn == null
            ? ''
            : new Date(student.statusUpdatedOn).toISOString().split('T')[0],
      });
    });
    console.log('studentRows', studentRows);
    setStudents(studentRows);
    setStudentLoaded(true);
    setLoading(false);
  };

  useEffect(() => {
    // Filter the users when the component mounts or when the users prop changes
    const filteredTeamLeaders = users.filter(
      (user) =>
        !user.isDisabled &&
        (user.role === 'ApplicationTeamLeader' ||
          user.role === 'SuperAdmin' ||
          user.role === 'SeniorAdmin' ||
          user.role === 'Admin')
    );
    const filteredFollowAndAssessmentTeams = users.filter(
      (user) => !user.isDisabled && [1, 2, 3].includes(user.roleId)
    );

    // Update the local state with the filtered users
    setFilteredTeamLeaders(filteredTeamLeaders);
    setFilteredFollowAndAssessmentTeams(filteredFollowAndAssessmentTeams);
  }, [users]);

  useEffect(() => {
    Agent.Events.getEventList().then((res) => {
      res.sort(function (a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (a.id < b.id) {
          return 1;
        }
        return 0;
      });
      setEventList(res);
    });
  }, []);

  useEffect(() => {
    if (isInternal) {
      if (users.length == 0) {
        loadUsers()
          .then(() => {
            //  getData();
          })
          .catch((error) => {
            console.log(error);
            //debugger;
          });
      } else {
        getData(true);
      }
    } else {
      getData(true);
    }
  }, [users, pageNumber, referralRelaod]);

  let studentColumns = [
    {
      field: 'id',
      headerName: 'id',
      width: 180,
      hide: true,
    },

    {
      field: 'name',
      headerName: 'Name',
      width: 300,
      renderCell: (params) => (
        <MyTooltip title={params.row.name} color="primary">
          <Typography className="clickable">{params.row.name}</Typography>
        </MyTooltip>
      ),
    },

    {
      field: 'residence',
      headerName: 'Residence',
      width: isInternal ? 150 : 180,
      //hide: !isInternal, // changes on request of Himel vai and Nahid Vai
    },

    {
      field: 'citizenship',
      headerName: 'Citizenship',
      hide: isInternal,
      width: isInternal ? 100 : 200,
    },

    {
      field: 'applicationCount',
      headerName: 'App',
      width: isInternal ? 100 : 200,
      headerAlign: 'center',
      align: 'center',
      hide: false,
    },

    {
      field: 'agent',
      headerName: 'Agent Name',
      width: 170,
      hide: !isInternal,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.agent}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'referralName',
      headerName: 'Referral Name',
      width: 170,
      hide: isInternal ? false : true,
      // renderCell: (params) => (
      //   <Grid container>
      //     <Grid item xs={10} container alignItems="center">
      //       <Typography>{params.row.agent}</Typography>
      //     </Grid>
      //   </Grid>
      // ),
    },
    {
      field: 'branch',
      headerName: 'Branch',
      hide: !isInternal,
      width: 120,
    },
    {
      field: 'preferenceStudyLevel',
      headerName: 'preference Study Level',
      align: 'center',
      headerAlign: 'center',
      hide: true,
      width: 170,
    },
    {
      field: 'complianceStatusName',
      headerName: 'compliance Status',
      width: 240,
      headerAlign: 'center',
      align: 'center',
      hide: false,
    },
    {
      field: 'admin',
      headerName: 'Team Leader',
      width: 180,
      hide: !isInternal,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.admin}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'assessmentTeam',
      headerName: 'Assessment',
      hide: !isInternal,
      width: 180,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.assessmentTeam}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'statusName',
      headerName: 'Status',
      width: 250,
      hide: true,
    },
    {
      field: 'followUpTeam',
      headerName: 'Follow Up',
      hide: !isInternal,
      width: 180,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.followUpTeam}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 90,
      hide: !isInternal,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <IconButton
          disabled={!isInternal}
          onClick={() => handleNote(params.row)}
          size="large"
        >
          <Description />
        </IconButton>
      ),
    },
    {
      field: 'profile',
      headerName: 'Profile Status',
      width: isInternal ? 140 : 200,
      align: 'center',
      renderCell: (params) => (
        <strong>
          {params.row.profile ? (
            <MyTooltip
              icon={<CheckCircleIcon className="text-green-400" />}
              title="Profile Complete"
            >
              <IconButton size="large">
                <CheckCircleIcon className="text-green-500" />
              </IconButton>
            </MyTooltip>
          ) : (
            <MyTooltip icon={<NotInterested />} title="Not Completed">
              <IconButton size="large">
                <NotInterested className="text-red-600" />
              </IconButton>
            </MyTooltip>
          )}
        </strong>
      ),
    },
    {
      field: 'campaign',
      headerName: 'Campaign',
      hide: true,
    },

    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      hide: true,
      renderCell: (params) => <Typography>{params.row.email}</Typography>,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      hide: true,
    },

    // {
    //   field: 'dateOfBirth',
    //   headerName: 'DOB',
    //   width: 100,
    //   hide: false,
    // },
    {
      field: 'createdOn',
      headerName: 'Created On',
      width: 100,
      hide: true,
    },
    {
      field: 'passport',
      headerName: 'Passport',
      hide: true,
    },
    {
      field: 'gender',
      headerName: 'Gender',
      hide: true,
    },
    {
      field: 'bdm',
      headerName: 'Business Dev Manager',
      hide: true,
    },
    {
      field: 'refbdm',
      headerName: 'Referral BDM',
      hide: true,
    },
    {
      field: 'eventName',
      headerName: 'Event Name',
      width: 220,
      hide: true,
    },
    {
      field: 'isLogin',
      headerName: 'Login',
      hide: !isInternal,
      width: 70,
      align: 'center',
      renderCell: (params) => (
        <strong>
          {params.row.isLogin ? (
            <MyTooltip
              icon={<CheckCircleIcon className="bg-green-500" />}
              title="Already Logged In"
            >
              <IconButton size="large">
                <CheckCircleIcon className="text-green-500" />
              </IconButton>
            </MyTooltip>
          ) : (
            <MyTooltip icon={<NotInterested />} title="Not Logged In Yet">
              <IconButton size="large">
                <NotInterested className="text-red-600" />
              </IconButton>
            </MyTooltip>
          )}
        </strong>
      ),
    },
    {
      field: 'source',
      headerName: 'Lead Source',
      width: 130,
      hide: !isInternal,
    },
    {
      field: 'origin',
      headerName: 'Lead Origin',
      width: 130,
      hide: !isInternal,
    },
    {
      field: 'channel',
      headerName: 'Lead Channel',
      width: 130,
      hide: !isInternal,
    },
    {
      field: 'studentLeadId',
      headerName: 'Student Lead',
      hide: !isInternal,
      width: 100,
      align: 'center',
      renderCell: (params) => (
        <strong>
          {params.row.studentLeadId ? (
            <MyTooltip
              icon={<CheckCircleIcon className="bg-green-500" />}
              title="Converted From Lead"
            >
              <IconButton size="large">
                <CheckCircleIcon className="text-green-500" />
              </IconButton>
            </MyTooltip>
          ) : null}
        </strong>
      ),
    },
    {
      field: 'leadactivityName',
      headerName: 'Lead Activity',
      width: 150,
      hide: false,
    },
    {
      field: 'complianceTeam',
      headerName: 'Compliance',
      hide: true,
      width: 180,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.complianceTeam}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'countryManager',
      headerName: 'Country Manager',
      hide: true,
      width: 180,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.countryManager}</Typography>
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'branchManager',
      headerName: 'Branch Manager',
      hide: true,
      width: 180,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.branchManager}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'regionalManager',
      headerName: 'Regional Manager',
      hide: true,
      width: 180,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.regionalManager}</Typography>
          </Grid>
        </Grid>
      ),
    },

    {
      field: 'virtualTeam',
      headerName: 'Uni. Representative',
      hide: true,
      width: 180,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.virtualTeam}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'statusupdatedon',
      headerName: 'Status Updated Date',
      width: 150,
      align: 'center',
      hide: true,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 90,
      hide: !isInternal || bulkUpdate,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <IconButton
          disabled={!isInternal}
          onClick={() => showEditOptions(params.row)}
          size="large"
        >
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const getData = async (filter = false) => {
    setLoading(true);
    try {
      const params = getParams(filter);
      const studentListPage = await Agent.Students.list(params);
      console.log('studentListPage', studentListPage);
      const { pagination, data } = studentListPage;
      console.log('data', data);
      getRows(data);
      setTotalPages(pagination.totalPages);
      setTotalRows(pagination.totalItems);
    } catch (error) {
      console.error(error);
    }
  };

  const applyFilter = () => {
    getData(true);
    setPageNumber(1);
  };
  const resetFilter = () => {
    setId('');
    setName('');
    setEmail('');
    setPhoneNumber('');
    setBranchOfficeId(0);
    setDateOfBirth(null);
    setStart(addMonths(new Date(), -1));
    setEnd(new Date());
    getData(false);
    setDateType(1);
    setstudentStatus(0);
    setLeadActivity(0);
    setLeadOrigin(0);
    setLeadSource(0);
    setLeadChannel(0);
    setLeadStatus(0);
    setLeadActivity(0);
    setCountryOfResidence(0);
    setTeamLeader(0);
    setAssessmentTeam(0);
    setFollowUpTeam(0);
    setStudentType(0);
    setIntake(0);
    setEventId(0);
    setPassportNumber('');
    setComplianceStatus(0);
    setDateOfBirth(null);
    setStart(addMonths(new Date(), -1));
    setEnd(new Date());
    setAgentFilterId(0);
    setReferralAssignId(0);
    setStudyLevelFilter('');
  };

  const actionSelectedUser = (record) => {
    const assignDto = {
      studentIds: selectionModel.length > 0 ? selectionModel : [student.id],
      appUserDetailsId: record.appUserDetailsId,
      assessmentTeamId: record.assessmentTeamId,
      followupTeamId: record.followupTeamId,
      complianceTeamId: record.complianceTeamId,
      agentId: record.agentId,
      referralId: record.referralId,
      status: parseInt(record.status),
      branchOfficeId: record.branchOfficeId
        ? parseInt(record.branchOfficeId)
        : null,
      campaign: record.campaign,
      source: record.source,
      origin: record.origin,
      channel: record.channel,
      //Added by Liton 31/05/2023
      regionalManagerId: record.regionalManagerId,
      countryManagerId: record.countryManagerId,
      branchManagerId: record.branchManagerId,
      virtualTeamId: record.virtualTeamId,
      createdOn: record.createdOn,
    };

    console.log('Assign DTO stund:', assignDto, record); // Console log the assignment data before making the API  call

    Agent.Students.assign({ ...record, ...assignDto })
      .then(() => {
        if (bulkUpdate) {
          setReady(false);
          return;
        }
        // update the grid now
        let temp = [];
        students.map((item) => {
          if (item.id == student.id) {
            if (item.status != record.status) {
              item.status = parseInt(record.status);
              item.statusName = STUDENTSTATUS.find(
                ({ id }) => id == record.status
              ).type;
            }
            if (item.agentId != record.agentId) {
              item.agentId = record.agentId;
              item.agent = record.agent;
            }
            if (item.appUserDetailsId != record.appUserDetailsId) {
              item.appUserDetailsId = record.appUserDetailsId;
              item.admin = record.admin;
            }
            if (item.assessmentTeamId != record.assessmentTeamId) {
              item.assessmentTeamId = record.assessmentTeamId;
              item.assessmentTeam = record.assessment;
            }
            if (item.followupTeamId != record.followupTeamId) {
              item.followupTeamId = record.followupTeamId;
              item.followUpTeam = record.followUp;
            }
            if (item.complianceTeamId != record.complianceTeamId) {
              item.complianceTeamId = record.complianceTeamId;
              item.complianceTeam = record.compliance;
            }
            if (item.branchOfficeId != record.branchOfficeId) {
              item.branchOfficeId = record.branchOfficeId;
              item.branch = record.branchOffice;
            }
            item.campaign = record.campaign;
            item.channelId = record.channel;
            item.originId = record.origin;
            item.sourceId = record.source;
            (item.source =
              record.source && record.source > 0
                ? STUDENTSOURCES.find(({ id }) => id == record.source).type
                : ''),
              (item.origin =
                record.origin > 0
                  ? LEADORIGINS.find(({ id }) => id == record.origin).type
                  : ''),
              (item.channel =
                record.channel > 0
                  ? LEADCHANNELS.find(({ id }) => id == record.channel).type
                  : '');
            //Added by Liton 31/05/2023
            if (item.regionalManagerId != record.regionalManagerId) {
              item.regionalManagerId = record.regionalManagerId;
              item.regionalManager = record.regionalManager;
            }
            if (item.countryManagerId != record.countryManagerId) {
              item.countryManagerId = record.countryManagerId;
              item.countryManager = record.countryManager;
            }
            if (item.branchManagerId != record.branchManagerId) {
              item.branchManagerId = record.branchManagerId;
              item.branchManager = record.branchManager;
            }
            if (item.virtualTeamId != record.virtualTeamId) {
              item.virtualTeamId = record.virtualTeamId;
              item.virtualTeam = record.virtualTeam;
            }
          }
          temp.push(item);
        });
        setStudents(temp);
        setReady(false);
        setReferralReload(true);
        setStudent(null);
      })
      .catch((error) => {
        console.log(error);
        setStudent(null);
        setReady(false);
        debugger;
      });
  };
  // const showAgentAssignment = (row) => {
  //   setStudent(row);
  //   if (agents.length == 0) {
  //     loadAgents()
  //       .then(() => {
  //         setAgentAssignMode(true);
  //         setReady(true);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         debugger;
  //       });
  //   } else {
  //     setAgentAssignMode(true);
  //     setReady(true);
  //   }
  // };
  // const showAdminAssignment = (row) => {
  //   setStudent(row);
  //   if (users.length == 0) {
  //     loadUsers()
  //       .then(() => {
  //         setAdminAssignMode(true);
  //         setReady(true);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         debugger;
  //       });
  //   } else {
  //     setAdminAssignMode(true);
  //     setReady(true);
  //   }
  // };

  const showEditOptions = (row) => {
    setStudent(row);
    console.log('Row', row);
    if (users.length == 0) {
      loadUsers()
        .then(() => {
          if (agents.length == 0) {
            loadAgents()
              .then(() => {
                setReady(true);
              })
              .catch((error) => {
                console.log(error);
                debugger;
              });
          }
        })
        .catch((error) => {
          console.log(error);
          debugger;
        });
    } else {
      if (agents.length == 0) {
        loadAgents()
          .then(() => {
            setReady(true);
          })
          .catch((error) => {
            console.log(error);
            debugger;
          });
      } else {
        setReady(true);
      }
    }
  };

  return studentLoaded ? (
    <>
      <Grid item container spacing={1}>
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="small"
            label="Id"
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="small"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            size="small"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Grid>
        <Grid item xs={2}>
          <DatePicker
            // popperClassName={classes.reactDatepickerPopper}
            autoComplete="off"
            locale="enGB"
            dateFormat="dd-MM-yyyy"
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            error={true}
            selected={(dateOfBirth && new Date(dateOfBirth)) || null}
            onChange={(newVal) => {
              if (newVal) {
                newVal = addHours(startOfDay(newVal), 12);
              }
              setDateOfBirth(newVal);
            }}
            customInput={
              <TextField
                label="Date of Birth"
                fullWidth
                // onChange={onChange}
                value={dateOfBirth}
                variant="outlined"
                size="small"
              />
            }
          />
        </Grid>
        {isInternal ? (
          <Grid item xs={2}>
            <Select
              fullWidth
              size="small"
              native
              label="Branch Office"
              value={branchOfficeId}
              onChange={(e) => {
                setBranchOfficeId(e.target.value);
              }}
            >
              <option value={0}>All Branches</option>
              {offices.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Select>{' '}
          </Grid>
        ) : (
          <Grid item xs={2}>
            <Grid item>
              <Button
                onClick={() => navigate('students/register')}
                variant="contained"
                className="bg-sky-400 hover:bg-sky-500"
                disabled={
                  userContext.agentId > 0 &&
                  userContext.status != STATUS_APPROVED
                }
              >
                Register New Student
              </Button>
            </Grid>
          </Grid>
        )}
        <Grid item container spacing={1}>
          {/* {!isInternal && (
            <Grid item container xs={2} justifyContent="flex-end">
              {/* <Grid item>
                <Button
                  onClick={() => navigate('students/register')}
                  variant="contained"
                  className="bg-sky-400 hover:bg-sky-500"
                  disabled={
                    userContext.agentId > 0 &&
                    userContext.status != STATUS_APPROVED
                  }
                >
                  Register New Student
                </Button>
              </Grid> 
            </Grid>
          )}*/}
        </Grid>

        {isInternal && (
          <Grid item xs={2}>
            <Select
              fullWidth
              size="small"
              native
              label="Date Type"
              value={dateType}
              onChange={(e) => {
                setDateType(e.target.value);
              }}
            >
              <option value={1}>Student Added</option>
              <option value={2}>Student Modified</option>
              <option value={3}>Status Changed</option>
            </Select>
          </Grid>
        )}

        {isInternal && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Lead Activity</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Lead Activity"
                value={leadactivity}
                onChange={(e) => {
                  setLeadActivity(e.target.value);
                }}
              >
                <option value={0}>Lead Activity</option>
                {StudentLead_Activities.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {isInternal && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Origin</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Origin"
                value={leadOrigin}
                onChange={(e) => {
                  setLeadOrigin(e.target.value);
                }}
              >
                <option value={0}> -- select origin --</option>
                {LEADORIGINS.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.type}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {isInternal && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Source</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Source"
                value={leadSource}
                onChange={(e) => {
                  setLeadSource(e.target.value);
                }}
              >
                <option value={0}> -- select source --</option>
                {STUDENTSOURCES.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.type}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {isInternal && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel> Channel</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Channel"
                value={leadChannel}
                onChange={(e) => {
                  setLeadChannel(e.target.value);
                }}
              >
                <option value={0}>-- select channel --</option>
                {LEADCHANNELS.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.type}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {isInternal && (
          <Grid item xs={2}>
            <FormControl>
              <InputLabel>Events</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Event Name"
                value={eventId}
                onChange={(e) => {
                  setEventId(e.target.value);
                }}
              >
                <option value={0}>All Event</option>
                {events.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.eventName}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {/* <div className="w-36"></div> */}
        {isInternal && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel> Country Of Residence</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Country Of Residence"
                value={countryOfResidence}
                onChange={(e) => {
                  setCountryOfResidence(e.target.value);
                }}
              >
                <option value={0}>Country Of Residence</option>
                {Countries.filter((item)=>item.id!=0).map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {isInternal && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel> Team Leader</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Team Leader"
                value={TeamLeader}
                onChange={(e) => {
                  setTeamLeader(e.target.value);
                }}
              >
                <option value={0}>Team Leader</option>
                {filteredTeamLeaders.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {isInternal && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel> Assessment</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Assessment"
                value={assessmentTeam}
                onChange={(e) => {
                  setAssessmentTeam(e.target.value);
                }}
              >
                <option value={0}>Assessment</option>
                {filteredFollowAndAssessmentTeams.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {isInternal && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel> Follow-up Team</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Follow-up Team"
                value={followupTeamId}
                onChange={(e) => {
                  setFollowUpTeam(e.target.value);
                }}
              >
                <option value={0}>Follow-up Team</option>
                {filteredFollowAndAssessmentTeams.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        {isInternal && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Student Type</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Student Type"
                value={studentType}
                onChange={(e) => {
                  setStudentType(e.target.value);
                }}
              >
                <option value={0}>All Student Type</option>
                <option value={1}>Agent Students</option>
                <option value={2}>Direct Students</option>
                <option value={3}>Referral Students</option>
              </Select>
            </FormControl>
          </Grid>
        )}
        {isInternal && (
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Compliance Status</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Compliance Status"
                value={complianceStatus}
                onChange={(e) => {
                  setComplianceStatus(e.target.value);
                }}
              >
                {/* <option value={0}> Unknown</option> */}
                {COMPLIANCE_STATUS.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.type}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item container spacing={1} className="pb-6">
          <Grid item xs={2}>
            <DatePicker
              // popperClassName={classes.reactDatepickerPopper}
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(start && new Date(start)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setStart(newVal);
              }}
              customInput={
                <TextField
                  label="Start Date"
                  fullWidth
                  value={start}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              // popperClassName={classes.reactDatepickerPopper}
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(end && new Date(end)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setEnd(newVal);
              }}
              customInput={
                <TextField
                  label="End Date"
                  fullWidth
                  // onChange={onChange}
                  value={end}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>
          {isInternal && (
            <Grid item xs={2}>
              <TextField
                fullWidth
                size="small"
                label="Phone Number"
                value={phone}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
          )}
          {isInternal && (
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel> Status</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  native
                  label=" Status"
                  value={studentStatus}
                  onChange={(e) => {
                    setstudentStatus(e.target.value);
                  }}
                >
                  <option value={0}> Status</option>
                  {STUDENTSTATUS.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.type}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {!isInternal && (
            <Grid item xs={2}>
              <FormControl fullWidth>
                <InputLabel> Country Of Residence</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  native
                  label="Country Of Residence"
                  value={countryOfResidence}
                  onChange={(e) => {
                    setCountryOfResidence(e.target.value);
                  }}
                >
                  <option value={0}>Country Of Residence</option>
                  {Countries.filter((item)=>item.id!=0).map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {isInternal && (
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <InputLabel>Referral</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  native
                  label="Referral"
                  value={referralAssignId}
                  onChange={(e) => {
                    setReferralAssignId(e.target.value);
                  }}
                >
                  <option value={0}>All Referrals</option>
                  {referralList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.firstName + ' ' + item.lastName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          {isInternal && (
            <Grid item xs={12} sm={6} md={2}>
              <FormControl fullWidth>
                <InputLabel>Agent</InputLabel>
                <Select
                  fullWidth
                  size="small"
                  native
                  label="Agent"
                  value={agentFilterId}
                  onChange={(e) => {
                    setAgentFilterId(e.target.value);
                  }}
                >
                  <option value={0}>All Agents</option>
                  {AgentList?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.companyName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid
            container
            justifyContent={'flex-end'}
            spacing={1}
            className="pt-2 pl-2"
          > 
          
           {isInternal && ( <Grid item xs={2} >
          <FormControl fullWidth>
            <TextField
              select
              label="Preferred Study Level"
              variant="outlined"
              size="small"
              value={studyLevelFilter}
              onChange={(e) => {
                setStudyLevelFilter(e.target.value);
              }}
            >
              {STUDY_LEVEL_GROUPS.map((studyLevel) => (
                <MenuItem key={studyLevel.id} value={studyLevel.id}>
                  {studyLevel.type}
                </MenuItem>
              ))}
            </TextField>
           </FormControl>
          </Grid> )}
            <Grid item xs={2}>
              <TextField
                fullWidth
                size="small"
                label="Passport Number"
                value={passportNumber}
                onChange={(e) => setPassportNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                onClick={getData}
                variant="contained"
                className="bg-sky-400 hover:bg-sky-500"
              >
                Apply Filters
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button fullWidth onClick={resetFilter} variant="outlined">
                Reset Filters
              </Button>
            </Grid>

            {isInternal && (
              <Grid item xs={2}>
                <Grid item>
                  <Button
                    fullWidth
                    onClick={() => setBulkUpdate(!bulkUpdate)}
                    variant="text"
                  >
                    {bulkUpdate ? 'Disable Bulk Update' : 'Enable Bulk Update'}
                  </Button>
                </Grid>
                {bulkUpdate && (
                  <Grid item xs={1}>
                    <IconButton
                      onClick={() => {
                        if (selectionModel.length > 0) {
                          showEditOptions(null);
                        }
                      }}
                      size="large"
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid item style={{ height: 730, width: '100%' }}>
        <DataGridPro
          rows={students}
          columns={studentColumns}
          loading={loading}
          density="compact"
          rowHeight={70}
          pagination
          totalPageSize={totalPages}
          paginationMode="server"
          pageSize={pageSize}
          disableColumnFilter={true}
          pageSizeOptions={[800]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowCount={totalrows}
          onPageChange={(newPage) => setPageNumber(newPage + 1)}
          page={pageNumber - 1}
          components={{
            Toolbar: () =>
              CustomToolbar(permissions.CanDownloadData, isInternal),
            Pagination: GridPagination,
          }}
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
            if (params.colDef.field === 'name') {
              window.open(`/students/${params.row.id}/dashboard`, '_blank');
            }
          }}
          checkboxSelection={isInternal && bulkUpdate}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel); //onRowSelection use for datagridpro v6 and onselection is use for current datagrid
          }}
          selectionModel={selectionModel}
        />
      </Grid>

      {ready && (
        <StudentAssignment
          bulkUpdate={bulkUpdate}
          referralList={referralList}
          setReferralReload={setReferralReload}
          student={bulkUpdate ? null : student}
          users={users.filter((item) => item.isDisabled == false)}
          agents={agents.map(function (item) {
            return { ...item, name: item.companyName };
          })}
          cancel={setReady}
          action={actionSelectedUser}
          role={role}
        />
      )}
      {openAddNote && (
        <StudentNotes
          //records={notes}
          //setRecords={setNotes}
          open={openAddNote}
          cancel={setOpenAddNote}
          studentId={studentId}
          toName={userName}
        />
      )}
    </>
  ) : (
    <LoadingView />
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
    userName: state.auth.name,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StudentList);
