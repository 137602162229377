import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Countries } from '../../../Util/Util';
import { FormHelperText } from '@mui/material';

const PREFIX = 'MyCountryListFormik';

const classes = {
  option: `${PREFIX}-option`,
  inputContainer: `${PREFIX}-inputContainer`,
  label: `${PREFIX}-label`,
  error: `${PREFIX}-error`,
};

const Root = styled('div')({
  [`& .${classes.option}`]: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  [`& .${classes.inputContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 15px',
  },
  [`& .${classes.label}`]: {
    margin: '0 0 6px 0',
    fontSize: '1.1rem',
  },
  [`& .${classes.error}`]: {
    color: 'red',
  },
});

export default function MyCountryListFormik(props) {
  const { label, name, errorMessage, error, value, setFieldValue, required } =
    props;
  const [val, setVal] = useState(Countries.find(({ id }) => id === value));
  const [errorLocal, setErrorLocal] = useState(error);

  return (
    <Root>
      <Autocomplete
        size="small"
        name={name}
        // fullWidth
        value={val}
        onChange={(e, newValue) => {
          setVal(newValue);
          setFieldValue(name, newValue ? newValue.id : null);
          setErrorLocal(newValue == null && required);
        }}
        options={Countries}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              label={label}
              
              variant="standard"
              name={name}
              // onBlur={(e) => {
              //   if (handleBlur) {
              //     handleBlur(e.target.name);
              //   }
              // }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              error={errorLocal}
              required
              // {...props}
              // helperText="test"
            />
            {errorMessage && errorLocal && (
              <FormHelperText className={classes.error}>
                {errorMessage}
              </FormHelperText>
            )}
          </>
        )}
      />
    </Root>
  );
}
