/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import * as actions from '../../../store/actions/index';
import {
  LicenseInfo,
  DataGridPro,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  Snackbar,
  Step,
  StepIcon,
  TextField,
  Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // the locale you want
registerLocale('en-GB', enGB); // register it with the name you want
import 'react-datepicker/dist/react-datepicker.css';
import { addHours, addMonths, format, startOfDay } from 'date-fns';
import LoadingView from '../../Common/Loading/LoadingView';
import { Announcement_TYPE, MUI_LICENSE_KEY, User_TYPE_Announcement, } from '../../../Util/Constants';
import { useNavigate } from 'react-router';
// eslint-disable-next-line import/default
import Agent from '../../../api/agent';
import Alert from '@mui/material/Alert';
import { htmlToText } from 'html-to-text';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MyTooltip from '../../Common/Tooltip/MyTooltip';
import { NotInterested } from '@mui/icons-material';
import ConfirmationModal from '../../Common/Modal/ConfirmationModal';
import EditIcon from '@mui/icons-material/Edit';
import { EditAnnouncement } from './EditAnnouncement';

function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {show && <GridToolbarColumnsButton />}
      {hide && <GridToolbarFilterButton />}
      <GridToolbarDensitySelector />
      {hide && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

const AnnouncementList = ({ users, loadUsers, isInternal }) => {
  const [activityLoaded, setStudentLoaded] = useState(false);
  const [startDate, setStartDate] = useState(addMonths(new Date(), -1));
  const [endDate, setEndDate] = useState(new Date());
  const [announcement, setAnnouncement] = useState({});
  const [templateType, setTemplateType] = useState(0);
  const [announcementTypeId, setAnnouncementTypeId] = useState(0);
  const [templateRowList, settemplateRowList] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [announcementId, setAnnouncemnetId]=useState(0);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [titleName, setTitleName] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [enableModalOpen, setEnableModalOpen] = useState(false);
  const [disableTemplateId, setDisableTemplateId] = useState(null);
  const [enableTemplateId, setEnableTemplateId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [pageSize, setPageSize] = useState(100);
  const [ready, setReady] = useState(false);
  const [reload,setReload]=useState(false);
  const [announcementTypeList, setAnnouncementTypeList] = useState([]);
  const navigate = useNavigate();
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', page.toString());
    params.append('pageSize', pageSize.toString());

    if (filter) {
      if (startDate) {
        params.append('StartDate', new Date(startDate).toDateString());
      }
    } else {
      params.append('StartDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (endDate) {
        params.append('EndDate', new Date(endDate).toDateString());
      }
    } else {
      params.append('EndDate', new Date().toDateString());
    }

    params.append('TitleName', titleName.toString());
    params.append('AnnouncementTypeId', announcementTypeId);

    return params;
  };

  const handleCreateTemplate = () => {
    navigate('/createannouncement');
  };

  const handlePageChange = (params) => {
    setPage(params + 1);
  };
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1); // Reset page when changing page size
  };
  useEffect(()=>{
    Agent.Announcement.getAnnouncementType().then((res)=>{
    const temp= res.sort(function (a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    })
      setAnnouncementTypeList(temp);
    })
  },[]);
  const getRows = (rows) => {
    let templateRows = rows.map((template) => ({
      id: template.id,
      announcementTypeId: template.announcementTypeId,
      userTypeId : template.userTypeId,
      announcementAssignments: template.announcementAssignments,
      userTypeName : User_TYPE_Announcement.find(({id})=>id==template.userTypeId)?.type,
      link:template.link,
      isInactive:template.isInactive,
      announcementTypeName: template.announcementTypeName,
      expiryDate: template.expiryDate,
      publishDate: template.publishDate,
      content: template.content,
      contentText: htmlToText(template.content),
      title: template.title,
      addedBy: template.addedBy,
      addedOn: template.addedOn,
      updatedBy: template.updatedBy,
      updatedOn: template.updatedOn,
    }));
    settemplateRowList(templateRows);
    setStudentLoaded(true);
    setLoading(false);
  };
  const actionSelectedUser = (record) => {
    console.log('record',record);
    const assignDto = {
      id: record.id,
      announcementTypeId: record.announcementTypeId,
      userTypeId : record.userTypeId,
      link:record.link,
      isInactive:record.isInactive,
      expiryDate: record.expiryDate ?  record.expiryDate.toISOString():'',
      publishDate: record.publishDate ?  record.publishDate.toISOString():'',
      content: record.content,
      title: record.title,
      addedBy: record.addedBy,
      addedOn: record.addedOn,
    }

        Agent.Announcement.postAnnouncement(assignDto).then((res)=>{
          getData(true);
          setReady(false);
        })
       .catch((error)=>{
      console.log(error);
      })
  }
  const showEditOptions = (row) => {
    setAnnouncement(row);
    console.log('Row', row);
     setReady(true);
  };
  const handleDisableTemplateConfirm = () => {
    Agent.Communication.disableTemplate(disableTemplateId)
      .then((response) => {
        handleSnackbarOpen('success');
        settemplateRowList((prevtemplateRowList) =>
          prevtemplateRowList.filter(
            (template) => template.id !== disableTemplateId
          )
        );

        console.log(response);
      })
      .catch((error) => {
        handleSnackbarOpen(
          'error',
          'Failed to disable template. Please try again.'
        );
        console.error(error);
      });
    setModalOpen(false);
  };

  const handleEnableTemplateConfirm = () => {
    Agent.Communication.enableTemplate(enableTemplateId)
      .then((response) => {
        handleSnackbarOpen('success', 'Template Enable successfully.');
        settemplateRowList((prevtemplateRowList) =>
          prevtemplateRowList.filter(
            (template) => template.id !== enableTemplateId
          )
        );

        console.log(response);
      })
      .catch((error) => {
        handleSnackbarOpen(
          'error',
          'Failed to enable template. Please try again.'
        );
        console.error(error);
      });
    setEnableModalOpen(false);
  };

  useEffect(() => {
    if (isInternal) {
      if (users.length === 0) {
        loadUsers()
          .then(() => {
            getData(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getData(true);
      }
    } else {
      getData(true);
    }
  }, [users, page,reload]);

  const getData = (filter = false) => {
    setLoading(true);
    Agent.Announcement.getAnnouncementList(getParams(filter))
      .then((res) => {
        getRows(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleDisable = () => {
    Agent.Announcement.inActiveAnnouncement(announcementId).then((res)=>{
      setOpenConfirm(false);
      getData(true);
    })
  };
  const handleInactive = (row) => {
    setAnnouncemnetId(row.id);
    setOpenConfirm(true);
  };
  
  const resetFilter = () => {
    setStartDate(addMonths(new Date(), -1));
    setEndDate(new Date());
    setTemplateType(0);
    setAnnouncementTypeId(0);
    getData(false);
    setTitleName('')
  };

  let columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      hide: true,
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 200,
      align: 'left',
      headerAlign: 'left',
    },


    {
      field: 'contentText',
      headerName: 'Content',
      width: 300,
      align: 'left',
      headerAlign: 'left',
    },
    {
      field: 'announcementTypeName',
      headerName: 'AnnouncementType',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      // renderCell: (params) => (
      //   <Link to={`/templates/${params.row.id}`}>{params.value}</Link>
      // ),
    },
    // {
    //   field: 'userTypeName',
    //   headerName: 'Applicable User Type',
    //   width: 150,
    //   align: 'center',
    //   headerAlign: 'center',
    //   // renderCell: (params) => (
    //   //   <Link to={`/templates/${params.row.id}`}>{params.value}</Link>
    //   // ),
    // },
    {
      field: 'isInactive',
      headerName: 'isActive',
      width: 90,
      align: 'center',
      renderCell: (params) => (
        <strong>
          {!params.row.isInactive ? (
            <MyTooltip
              icon={<CheckCircleIcon className="bg-green-500" />}
              title="Active Announcement"
            >
              <IconButton size="large">
                <CheckCircleIcon className="text-green-500" />
              </IconButton>
            </MyTooltip>
          ) : (
            <MyTooltip icon={<NotInterested />} title="Not Active Announcement">
              <IconButton size="large">
                <NotInterested className="text-red-600" />
              </IconButton>
            </MyTooltip>
          )}
        </strong>
      ),
    },
    {
      field: 'publishDate',
      headerName: 'Publish Date',
      width: 120,
      align: 'center',
      hide: false,
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.publishDate?format(new Date(params.row.publishDate), 'dd/MM/yyyy') : ''
      },
    },
    {
      field: 'expiryDate',
      headerName: 'Expiry Date',
      width: 120,
      align: 'center',
      hide: false,
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.expiryDate?format(new Date(params.row.expiryDate), 'dd/MM/yyyy') :''
      },
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 180,
      hide: false,
      valueGetter: (params) => {
        const getUserId = params.row.addedBy;
        const userName = users.find((user) => user.id == getUserId);
        return userName ? userName.name : null;
      },
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      hide: true,
      valueGetter: (params) => {
        return format(new Date(params.row.addedOn), 'dd/MM/yyyy') 
      },
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      width: 180,
      hide: true,
      valueGetter: (params) => {
        const getUserId = params.row.updatedBy;
        const userName = users.find((user) => user.id == getUserId);
        return userName ? userName.name : null;
      },
    },
    {
      field: 'updatedOn',
      headerName: 'Updated On',
      width: 120,
      align: 'center',
      hide: true,
      headerAlign: 'center',
      valueGetter: (params) => {
        return params.row.updatedOn ? format(new Date(params.row.updatedOn), 'dd/MM/yyyy') : ''
      },
    },
    {
      field: 'edit',
      align: 'center',
      headerAlign: 'center',
      width: 90,
      resizable: true,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <IconButton
          disabled={!isInternal}
          onClick={() => showEditOptions(params.row)}
          size="large"
        >
         <EditIcon/>
        </IconButton>
      ),
    },
    {
      field: 'Disabled',
      headerName: 'Disable',
      headerAlign: 'center',
      align: 'center',
      width: 110,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleInactive(params.row)}
          size="small"
          disabled={params.row.isInactive}
        >
         {params.row.isInactive? <Typography className='bg-gray-500 px-2 py-1 rounded-md text-gray-100 text-sm'>Disabled</Typography>
         : <Typography className='bg-red-800 hover:bg-red-700 px-2 py-1 rounded-md text-gray-100 text-sm'>Disabled</Typography>}
        </IconButton>
      ),
    },

  ];

  return activityLoaded ? (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item container spacing={1}>
          <Grid item xs={2}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              // onChange={(date) => setStartDate(date || new Date())}
              selected={(startDate && new Date(startDate)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setStartDate(newVal);
              }}
              customInput={
                <TextField
                  label="Start Date"
                  fullWidth
                  value={startDate}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>

          <Grid item xs={2}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              // selected={endDate}
              selected={(endDate && new Date(endDate)) || null}
              customInput={
                <TextField
                  label="End Date"
                  fullWidth
                  value={endDate}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Announcement Type</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Announcement Type"
                value={announcementTypeId}
                onChange={(e) => setAnnouncementTypeId(e.target.value)}
              >
                <option value={0}>All Types</option>
                {announcementTypeList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <Button
              fullWidth
              onClick={() => getData(true)}
              variant="contained"
              className="bg-sky-400 hover:bg-sky-500"
            >
              Apply Filters
            </Button>
          </Grid>

          <Grid item xs={2}>
            <Button fullWidth onClick={resetFilter} variant="outlined">
              Reset Filters
            </Button>
          </Grid>
        </Grid>

        <Grid item container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              fullWidth
              onClick={handleCreateTemplate}
              variant="contained"
              className="bg-sky-700"
            >
              Create Announcement
            </Button>
          </Grid>
        </Grid>

        <Grid item style={{ height: 900, width: '100%' }}>
          <DataGridPro
            paginationMode="client"
            pagination
            pageSize={pageSize}
            rowCount={totalItems}
            page={page - 1}
            pageSizeOptions={[25, 50, 100]}
            onPageChange={handlePageChange}
            rows={templateRowList}
            columns={columns}
            loading={loading}
            density="compact"
            rowHeight={70}
            components={{
              Toolbar: () => CustomToolbar(isInternal),
            }}
            onPageSizeChange={(newPageSize) =>
              handlePageSizeChange(newPageSize)
            }
          />
        </Grid>
      </Grid>

      <Dialog open={enableModalOpen} onClose={() => setEnableModalOpen(false)}>
        <DialogTitle className='text-green-700'>Enable Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to enable this template?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button onClick={() => setEnableModalOpen(false)}>Cancel</Button>
          <Button onClick={handleEnableTemplateConfirm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle className='text-red-700'>Disable Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to disable this template?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button onClick={handleDisableTemplateConfirm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        <Alert
          elevation={16}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }} // Set the width to 100% to center the content
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
      {ready && (
        <EditAnnouncement
        announcement={announcement}
        action={actionSelectedUser}
        cancel={setReady}
        setReload={setReload}
        />
      )}
      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={handleDisable}
        confirmText="Confirm Action"
        content="Are you sure, you would like to diabled this announcement!!"
      />
    </>
  ) : (
    <LoadingView />
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementList);
