import React, { useEffect, useState } from "react";
import { GridToolbar } from "@mui/x-data-grid";
import { Grid, Stack, Typography } from "@mui/material";


import { INTAKES, STUDY_LEVELS } from "../../../Util/Constants";
import LoadingView from "../../Common/Loading/LoadingView";
import { connect } from "react-redux";
import {
  intakeYearList,
  SelectionMenu,
  AutocompleteSelectionMenu,
  yearList,
} from "../Helper/HelperFunction";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Agent from "../../../api/agent";

const ModifiedIntakeMonth = [
  { id: 0, type: "All Intakes", typeShort: "All" },
  ...INTAKES.map((item) => item),
];

const ModifiedIntakeYear = [
  { id: 0, year: "All Intakes" },
  ...intakeYearList.map((item) => item),
];

const PendingReport = ({ appUserId, appUserDetailsId, role, permissions }) => {
  // const classes = useStyles();
  const [universities, setUniversities] = useState([]);
  const [selectedUniversities, setSelectedUniversities] = useState([]);
  const [appData, setAppData] = useState([]);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  const [payload, setPayload] = useState({
    year: 2023,
    intakeMonth: 0,
    intakeYear: 0,
    courseLevel: 0,
    universities: [],
    appUserId,
    appUserDetailsId,
    role,
    canManageAll: permissions.CanManageAllAgents,
  });

  //API CALL TO GET REPORT DATA
  useEffect(() => {
  Agent.Report.pendingSummary(payload).then((res) => {
      setAppData(res.data.univerisityPendingList);
    });
    Agent.Universities.list()
      .then((req) => {
        setUniversities(req);
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [payload]);

  //COLUMN FIELD LOGIC
  const columns = [
    {
      field: "title",
      headerName: "",
      width: 350,
      renderCell: (params) => (
        <Typography color="#0081C9" fontSize={18} fontWeight={500}>
          {params.row.title}
        </Typography>
      ),
    },
    { field: "oneMonth", headerName: "Upto One Month", width: 200 },
    { field: "threeMonth", headerName: "Upto Three Month", width: 220 },
    {
      field: "threeMonthPlus",
      headerName: "More Than Three Month",
      width: 280,
    },
    {
      field: "total",
      headerName: "Total",
      width: 230,
    },
  ];

  // CALL TO GET-ROW-DATA
  useEffect(() => {
    if (appData.length && universities.length) {
      const rowData = getRowData(appData);
      setRows(rowData);
      setLoading(false);
    } else {
      const rowData = getRowData(appData);
      setRows(rowData);
      setLoading(false);
    }
  }, [appData, universities]);

  //ROW FIELD LOGIC
  const getRowData = (item) => {
    let rowData = [];
    item.map((item, idx) => {
      rowData.push({
        id: idx,
        title: getUniversityName(item.universityId),
        oneMonth: item.oneMonth || 0,
        threeMonth: item.threeMonth || 0,
        threeMonthPlus: item.threeMonthPlus || 0,
        total: item.oneMonth + item.threeMonth + item.threeMonthPlus,
      });
    });
    return rowData;
  };

  // GET UNIVERSITY NAME FROM ID
  const getUniversityName = (id) => {
    let name = "";
    for (let i = 0; i < universities.length; i++) {
      const university = universities[i];
      if (university.id === id) {
        name = university.name;
        break;
      }
    }
    return name;
  };

  // HANDLE RETURN DATA FROM SELECT MENU FhandleSelectionMenuILTER COMPONENT
  const handleSelectionMenu = (name, value) => {
    setPayload((prev) => {
      return { ...prev, [name]: Number(value) || 0 };
    });
  };

  // HANDLE RETURN DATA FOR MULTIPLE UNIVERSITIES FROM FILTER COMPONENT
  const handleUniversitySelect = (_, e) => {
    let data;
    e != 0 ? (data = e.id) : (data = e);
    setSelectedUniversities(e.id);
    const val = [data];
    val[0] != 0
      ? setPayload((prev) => {
          return { ...prev, universities: val };
        })
      : setPayload((prev) => {
          return { ...prev, universities: [] };
        });
  };

  //HANDLE YEAR CHANGE
  const handleYearChange = (_, value) => {
    setPayload((prev) => {
      return {
        ...prev,
        year: Number(value) || 0,
        startDate: null,
        endDate: null,
      };
    });
  };

  // console.log('payload', payload);

  return loading ? (
    <LoadingView />
  ) : (
    <div style={{ height: "100%", width: "100%" }}>
      <Grid container direction={"column"}>
        <Grid item>
          <h4
            style={{
              textTransform: "uppercase",
              color: "#282A3A",
              textAlign: "center",
            }}
          >{`Pending Report`}</h4>
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          sx={{ px: 2 }}
        >
          <Grid item>
            <AutocompleteSelectionMenu
              selection={selectedUniversities}
              name="universities"
              label="Universities"
              options={universities}
              onChange={handleUniversitySelect}
            />
          </Grid>
          <Grid item>
            <SelectionMenu
              name="courseLevel"
              label="Course Level"
              options={STUDY_LEVELS}
              onChange={handleSelectionMenu}
            ></SelectionMenu>
          </Grid>

          <Grid item>
            <SelectionMenu
              name="intakeMonth"
              label="Intake Month"
              options={ModifiedIntakeMonth}
              onChange={handleSelectionMenu}
            ></SelectionMenu>
          </Grid>

          <Grid item>
            <SelectionMenu
              name="intakeYear"
              label="Intake Year"
              options={ModifiedIntakeYear}
              onChange={handleSelectionMenu}
            ></SelectionMenu>
          </Grid>

          <Grid item>
            <SelectionMenu
              name="year"
              label="Year"
              val={payload.year}
              options={yearList}
              onChange={handleYearChange}
            ></SelectionMenu>
          </Grid>
        </Grid>
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems="center"
          sx={{ px: 2 }}
        >
          <Grid item sx={{ mt: 0, width: "100%" }}>
            <DataGridPro
              width="full"
              height="600px"
              rows={rows}
              columns={columns}
              // hideFooter
              ColumnFilteredIcon
              autoHeight
              disableColumnFilter
              // className={classes.root}
              style={{
                borderRadius: "16px",
                fontSize: "17px",
                color: "#263159",
                margin: 0,
                padding: 0,
              }}
              components={{
                Toolbar: GridToolbar,
                NoRowsOverlay: () => (
                  <Stack
                    height="100%"
                    alignItems="center"
                    justifyContent="center"
                    fontSize="20px"
                  >
                    {` No applications found for the selected filters`}
                  </Stack>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(PendingReport);
