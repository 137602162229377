import React, { useState, useEffect } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useNavigate } from 'react-router-dom';
import AvatarMui from '../../../Common/Image/AvatarMui';
// import { Card, Typography } from '@material-tailwind/react';
import Appointment from '../../../Appointments/Appointment';
import { format, addMinutes } from 'date-fns';
import { connect } from 'react-redux';
import Agent from '../../../../api/agent';
import { WhatsApp } from '@mui/icons-material';
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import useWindowSize from '../../common/useWindowSize';
import LoadingView from '../../../Common/Loading/LoadingView';
import Messages from './Messages';
import { Typography } from '@mui/material';

const SideNav = ({ appUserId ,studentId,studentName,role}) => {
  const [student, setStudent] = useState();
  const [addMode, setAddMode] = useState(false);
  const [openEmailMode, setOpenEmailMode] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [appointmentList, setAppointmentList] = useState([]);
  const [communicationHistory, setCommunicationHistory] = useState([]);
  const [referralLogin, setReferralLogin] = useState(false);
  const [notes, setNotes] = useState([]);
  const [draftMessage, setDraftMessage] = useState('');

  const currentDate = new Date();
  const navigate = useNavigate();

  useEffect(() => {
    Agent.Students.getStudent(0)
      .then((std) => {
        setStudent(std);
        const {studentNotes, ...rest } = std;

        studentNotes.sort(function (a, b) {
          return new Date(b.dateSent) - new Date(a.dateSent);
        });
        setNotes(studentNotes);
      })
      .catch((error) => {
        debugger;
        console.log(error);
      });

    Agent.Appointment.get()
      .then((records) => {
        setAppointmentList(records);
      })
      .catch((error) => {
        debugger;
        console.log(error);
      });
    Agent.Communication.getStudentCommunicationHistory(appUserId).then((data) =>
      setCommunicationHistory(data)
    );
  }, []);

  const addRecord = (record) => {
    let stu = {
      name: student.firstName + ' ' + student.lastName,
    };
    setAppointment({ ...record, ...stu });
    setAddMode(true);
  };
  const handleReferralOnclick = () => {
    setReferralLogin(true);
  };
  const handleAppointmentUpdate = () => {
    setAddMode(false);
    navigate('/appointment');
  };
  const handleMessageSend = () => {
    setOpenEmailMode(true);
  };
  //Format date to display in the UI 
  const formattedDate = (date) => {
    return format(
      addMinutes(new Date(date), -new Date().getTimezoneOffset()),
      'MMMM d, yyyy h:mm a'
    );
  };

  const handOnClick = () => {
    window.open(
      'https://web.whatsapp.com'
    );
  };
  const size = useWindowSize();

  return (
    <div className=" rounded  bg-gray-700 flex my-0 lg:min-w-[300px] xl:min-w-[300px] py-3">
      <div className="w-full rounded-xl -20 px-3 text-gray-800">
        {student ? (
          //ADMINISTRATOR COMPONENT
          <div className="flex flex-col gap-2">
            {size.width > 844 && (
              <div className="flex flex-row py-2 gap-4 bg-gray-700  lg:rounded">
                <div className="text-white text-lg pl-0">follow us</div>
                <Typography
                  as="a"
                  target="_blank"
                  href="https://www.facebook.com/tclglobal"
                  className="opacity-80 transition-opacity hover:opacity-100 "
                >
                  <FaFacebook
                    style={{ color: 'white' }}
                    className="h-8 w-5 hover:h-14 hover:h-8"
                  />
                </Typography>
                <Typography
                  as="a"
                  target="_blank"
                  href="https://www.youtube.com/channel/UCy0Mv170m6uSMMNBaU0EK8A"
                  className="opacity-80 transition-opacity hover:opacity-100"
                >
                  <FaYoutube
                    style={{ color: 'white' }}
                    className="h-8 w-5 hover:h-14 hover:h-8"
                  />
                </Typography>
                <Typography
                  as="a"
                  target="_blank"
                  href="https://www.instagram.com/tclglobal_uk/"
                  className="opacity-80 transition-opacity hover:opacity-100"
                >
                  <FaInstagram
                    style={{ color: 'white' }}
                    className="h-8 w-5 hover:h-14 hover:h-8"
                  />
                </Typography>
                <Typography
                  as="a"
                  target="_blank"
                  href="https://twitter.com/tclglobal"
                  className="opacity-80 transition-opacity hover:opacity-100"
                >
                  <FaTwitter
                    style={{ color: 'white' }}
                    className="h-8 w-5 hover:h-14 hover:h-8"
                  />
                </Typography>
                <Typography
                  as="a"
                  target="_blank"
                  href="https://www.linkedin.com/company/tcl-global/"
                  className="opacity-80 transition-opacity hover:opacity-100"
                >
                  <FaLinkedinIn
                    style={{ color: 'white' }}
                    className="h-8 w-5 hover:h-14 hover:h-8"
                  />
                </Typography>
              </div>
            )}
            <div className=" p-3 shadow rounded-xl bg-cyan-600 flex flex-col items-center gap-2">
              <div className="text-xl font-semibold">Your Counselor</div>
              <div className="flex items-center gap-3">
                {student && student.adminFileUrl && (
                  <div>
                    <AvatarMui
                      imageUrl={student.adminFileUrl}
                      fullName={student.adminName}
                      size={5}
                    />
                  </div>
                )}
                <div className="text-2xl font-semibold text-indigo-700">
                  {student.adminName ? student.adminName : 'Not Assigned Yet'}
                </div>
              </div>
              {student.adminName && (
                <>
                  <div className="flex gap-4">
                    <div>
                      <EmailIcon className="text-indigo-600" />
                    </div>
                    <div>
                      <div>{student.adminEmail}</div>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div>
                      <PhoneIcon className="text-indigo-600" />
                    </div>
                    <div>
                      <div>{student.adminPhone}</div>
                    </div>
                  </div>
                </>
              )}

              <div className="h- p-2 m-1">
                <button
                  className="flex gap-2 rounded-xl studentSidebarBgGradient0 p-3 font-medium   drop-shadow shadow-inner  hover:scale-102 transition-all duration-300 ease-in-out"
                  onClick={handleMessageSend}
                >
                  <div>{<EmailIcon />}</div>
                  <div>
                    {student.adminName
                      ? 'Send Message to Admin'
                      : 'Send Message to TCL'}
                  </div>
                </button>
              </div>
              <div className="h- pb-2">
                <button
                  className="flex gap-2 rounded-xl bg-gradient-to-r from-teal-600 via-teal-500 to-teal-400 p-3 font-medium drop-shadow shadow-inner  hover:scale-102 transition-all duration-300 ease-in-out"
                  onClick={handOnClick}
                >
                  <div>{<WhatsApp />}</div>
                  <div>
                    {student.adminName
                      ? 'Send Message to Admin'
                      : 'Send Message to TCL'}
                  </div>
                </button>
              </div>
            </div>

            {/* APPOINTMENT COMPONENT */}
            <div className="p-3 shadow rounded-xl bg-cyan-600 flex flex-col items-center gap-2">
              <div className="text-xl font-semibold">Talk to an Advisor</div>

              <div className="max-w-[250px] text-center ">
                Book an appointment to talk to one of our advisors
              </div>

              <div className="">
                <button
                  className="flex gap-2 rounded-xl studentSidebarBgGradient0 p-3 font-medium   drop-shadow shadow-inner  hover:scale-102 transition-all duration-300 ease-in-out"
                  onClick={() => addRecord({ id: 0 })}
                >
                  <div>{<ScheduleIcon />}</div>
                  <div>Book Appointment</div>
                </button>
              </div>
            </div>
            {/* APPOINTMENT COMPONENT */}
            <div className="p-3 shadow rounded-xl bg-cyan-600 flex flex-col items-center gap-2">
              <div className="text-xl font-semibold text-center">
                Upcoming Appointments
              </div>

              <div className="max-w-[250px] text-center ">
                Book an appointment to talk to one of our advisors
              </div>

              <div className="w-full ">
                {appointmentList
                  .filter(
                    (appointment) =>
                      new Date(appointment.appointmentOn) > currentDate
                  )
                  .map((appointment, idx) => {
                    return (
                      <div key={idx}>
                        {idx < 3 && (
                          <button
                            key={idx}
                            className="my-2 w-full flex gap-2 rounded-md bg-sky-300 txt-white px-2 py-1.5 font-base text-sm   drop-shadow-lg  transition-all duration-300 ease-in-out"
                            onClick={() => ''}
                          >
                            <div>{`Appointment on ${formattedDate(
                              appointment.appointmentOn
                            )}`}</div>
                          </button>
                        )}
                        {idx >= 3 && (
                          <button
                            onClick={() => navigate('/appointments')}
                            className="my-2 w-full flex gap-2 rounded-md bg-gray-700/10 txt-white px-2 py-1.5 font-base text-sm   drop-shadow-lg  transition-all duration-300 ease-in-out"
                          >
                            Show All
                          </button>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
            {/* REFER COMPONENT */}
            {/* {student?.profileComplete && (
              <div className="p-3 shadow rounded-xl bg-cyan-600 flex flex-col items-center gap-2">
                <div className="text-xl font-semibold">Refer and Earn</div>

                <div className="max-w-[250px] text-center ">
                  Refer your friends and be rewarded
                </div>

                <div className="">
                  <button
                    className="w-full flex gap-2 rounded-xl studentSidebarBgGradient0 p-3 px-6 font-medium   drop-shadow shadow-inner  hover:scale-102 transition-all duration-300 ease-in-out"
                     onClick={handleReferralOnclick}
                  >
                    <div>
                      Referral Registration
                      {/* {referralLogin&&(
                        <ReferralLogin
                          //record={taskRecord}
                          //setAddMode={setAddTask}
                          //cosmosAdminUsersParent={cosmosAdminUsers}
                         // path={2}
                        />
                      )} */}
            {/* </div>
                  </button>
                </div>
              </div>
            )} */}
            {/* COMMUNICATION HISTORY COMPONENT */}
            {student?.profileComplete && (
              <div className="p-3 shadow rounded-xl bg-cyan-600 flex flex-col items-center gap-2">
                <div className="text-xl font-semibold">
                  Communication History
                </div>

                <div className="w-full bg">
                  {communicationHistory.map((comm, idx) => {
                    return (
                      <button
                        key={idx}
                        className="my-2 w-full flex gap-2 rounded-md bg-gray-700/10 txt-white px-2 py-1.5 font-base text-sm   drop-shadow-lg  transition-all duration-300 ease-in-out"
                        onClick={() => ''}
                      >
                        <div className="max-w-[150px] truncate">{`${comm.communicationName}`}</div>{' '}
                        <p>on </p>
                        <div className="max-w-[120px] truncate">
                          {formattedDate(comm.sentDatedOn)}
                        </div>
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
            <div className="p-3 shadow rounded-xl bg-cyan-600 flex flex-col items-center gap-2">
              <div className="text-xl font-semibold">Help & Support</div>

              <div className="max-w-[250px] text-center ">
                Please request for help and support you need and we will get
                back to you in due time
              </div>

              <div className="">
                <button
                  className="w-full flex gap-2 rounded-xl studentSidebarBgGradient0 p-3 px-6 font-medium   drop-shadow shadow-inner  hover:scale-102 transition-all duration-300 ease-in-out"
                  // onClick={() => ''}
                >
                  <div>Coming soon</div>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <LoadingView />
        )}
      </div>
      {addMode && (
        <Appointment
          record={appointment}
          setRecordParent={handleAppointmentUpdate}
          setAddMode={setAddMode}
          id={0}
        />
      )}
     {openEmailMode && <Messages
        studentId={studentId}
        studentName={studentName}
        draftMessageParent={draftMessage}
        setDraftMessageParent={setDraftMessage}
        role={role}
        records={notes}
        setRecords={setNotes}
        setOpenEmailMode={setOpenEmailMode}
        openEmailMode={true}
      />}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { 
    appUserId: state.auth.id ,
    role : state.auth.role};
};

export default connect(mapStateToProps)(SideNav);
