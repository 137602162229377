/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Countries } from './../../../Util/Util';
import {
  Disqualified_Reason,
  GENDER,
  LEADCHANNELS,
  LEADORIGINS,
  MARITALSTATUS,
  STUDENTSOURCES,
} from '../../../Util/Constants';
import { Service_Charge_Status } from './../../../Util/Constants';
import { addMinutes, format } from 'date-fns';
import moment from 'moment/moment';
import * as yup from 'yup';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';


const GeneralInfo = ({
  upfirstName,
  setFirstName,
  upLastName,
  setLastName,
  upPlaceOfBirth,
  setPlaceOfBirth,
  updateCitizenship,
  setCitizenship,
  updatedCountryOfRes,
  setUpdatedCountryOfRes,
  updatePassportNumber,
  setPassportNumber,
  updatedGender,
  setUpdatedGender,
  setMaritalStatus,
  maritalStatus,
  updatedStreet,
  setUpdatedStreet,
  serviceChargeStatus,
  setServiceChargeStatus,
  disqualifiedReason,
  setDisqualifiedReason,
  interestedCourseName,
  setInterestedCourseName,
  leadListName,
  setLeadListName,
  updatedDateOfBirth,
  setUpdatedDateOfBirth,
  updateExpiryDate,
  setExpiryDate,
  issueDate,
  setIssueDate,
  updatedLeadEntryDate,
  setLeadEntryDate,
  setLeadOrigin,
  leadOrigin,
  setLeadSource,
  leadSource,
  setLeadChannel,
  leadChannel,
  countryOfIssue,
  setCountryOfIssue,
  role,
}) => {

  const handleDateOfBirthChange = (event) => {
    const selectedDate = event.target.value;
    // Check if the selectedDate is a valid date string before setting the state
    if (/^\d{4}-\d{2}-\d{2}$/.test(selectedDate)) {
      setUpdatedDateOfBirth(selectedDate);
    } else {
      // Handle invalid date input
      setUpdatedDateOfBirth('');
      console.error('Invalid date format:', selectedDate);
    }
  };

  const handleLeadEntryDateChange = (e) => {
    const selectedDate = e.target.value;
    // Check if the selectedDate is a valid date string before setting the state
    if (/^\d{4}-\d{2}-\d{2}$/.test(selectedDate)) {
      setLeadEntryDate(selectedDate);
    } else {
      // Handle invalid date input
      console.error('Invalid date format:', selectedDate);
    }
  };
  const handleExpiryDateChange = (e) => {
    const selectedDate = e.target.value;
    // Check if the selectedDate is a valid date string before setting the state
    if (/^\d{4}-\d{2}-\d{2}$/.test(selectedDate)) {
      setExpiryDate(selectedDate);
    } else {
      // Handle invalid date input
      setExpiryDate('');
      console.error('Invalid date format:', selectedDate);
    }
  };

  const handleIssueDateChange = (e) => {
    const selectedDate = e.target.value;
    // Check if the selectedDate is a valid date string before setting the state
    if (/^\d{4}-\d{2}-\d{2}$/.test(selectedDate)) {
      setIssueDate(selectedDate);
    } else {
      // Handle invalid date input
      setIssueDate('');
      console.error('Invalid date format:', selectedDate);
    }
  };

  // Validate form data
  const validationSchema = yup.object().shape({
    updatedEmail: yup
      .string()
      .email('Invalid email format')
      .required('Email is required'),
    updatePassportNumber: yup.string().required('Passport number is required'),
    // updatedDateOfBirth: yup.date().required('Date of birth is required'),
  });

  //formatted using momentjs
  const formattedDate = (date) => {
    return moment(date).format('MMMM D, YYYY h:mm a');
  };

  return (
    <section id="basic-info" className="p-4 border rounded">
      <h2 className="text-2xl font-semibold mb-4 text-center">
        General Information
      </h2>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="firstName" className="block font-bold mb-2">
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            className="border border-gray-300 px-3 py-2 rounded-md w-full focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 focus:outline-none shadow-sm transition duration-150"
            value={upfirstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="lastName" className="block font-bold mb-2">
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            className="border border-gray-300 px-3 py-2 rounded-md w-full focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 focus:outline-none shadow-sm transition duration-150"
            value={upLastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
       
        <div>
          <label htmlFor="passportNumber" className="block font-bold mb-2">
            Passport Number
            <span className="text-red-500 ml-2">*</span>
          </label>
          <input
            className="input input-bordered w-full py-2 px-4"
            required
            type="text"
            id="passportNumber"
            name="passportNumber"
            value={updatePassportNumber}
            onChange={(e) => setPassportNumber(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="dateOfBirth" className="block font-bold mb-2">
            Passport Issue Date
            {<span className="text-red-500 ml-2">*</span>}
          </label>
          <input
            type="date"
            id="issueDate"
            name="issueDate"
            className={
              'border border-gray-300 px-3 py-2 rounded-md w-full focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 focus:outline-none shadow-sm transition duration-150'
            }
            value={issueDate ? issueDate.split('T')[0] : ''}
            onChange={handleIssueDateChange}
          />
        </div>

        <div>
          <label htmlFor="countryOfResidence" className="block font-bold mb-2">
            Country Of Issue
          </label>

          <select
            id="countryOfIssue"
            required
            className="input input-bordered w-full py-2 px-4"
            value={countryOfIssue}
            onChange={(e) => setCountryOfIssue(e.target.value)}
          >
            <option value={0}>Select</option>
            {Countries.filter((item)=>item.id!=0).map((country, id) => (
              <option key={id} value={country.id}>
                {country.name}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-4">
          <label htmlFor="dateOfBirth" className="block font-bold mb-2">
            Passport Expiry Date
            {<span className="text-red-500 ml-2">*</span>}
          </label>
          <input
            type="date"
            id="expiryDate"
            name="expiryDate"
            className={
              'border border-gray-300 px-3 py-2 rounded-md w-full focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 focus:outline-none shadow-sm transition duration-150'
            }
            value={updateExpiryDate ? updateExpiryDate.split('T')[0] : ''}
            onChange={handleExpiryDateChange}
          />
        </div>

        <div>
          <label htmlFor="gender" className="block font-bold mb-2">
            Gender
          </label>

          <select
            id="gender"
            className="input input-bordered w-full py-2 px-4"
            value={updatedGender}
            onChange={(e) => setUpdatedGender(e.target.value)}
          >
            <option value={0}>Select</option>
            {GENDER.map((gender) => (
              <option key={gender.id} value={gender.id}>
                {gender.type}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="maritalStatus" className="block font-bold mb-2">
            Marital Status
          </label>
          <select
            id="maritalStatus"
            className="input input-bordered w-full py-2 px-4"
            value={maritalStatus}
            onChange={(e) => setMaritalStatus(e.target.value)}
          >
            <option value={0}>Select</option>
            {MARITALSTATUS.map((marital, id) => (
              <option key={id} value={marital.id}>
                {marital.type}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="placeOfBirth" className="block font-bold mb-2">
            Place of Birth
            {<span className="text-red-500 ml-2">*</span>}
          </label>
          <input
            type="text"
            id="placeOfBirth"
            name="placeOfBirth"
            className="input input-bordered w-full py-2 px-4"
            value={upPlaceOfBirth}
            onChange={(e) => setPlaceOfBirth(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="citizenship" className="block font-bold mb-2">
            Citizenship
          </label>
          <select
            id="citizenship"
            className="input input-bordered w-full py-2 px-4"
            value={updateCitizenship}
            onChange={(e) => setCitizenship(e.target.value)}
          >
            <option value={0}>Select</option>
            {Countries.filter((item)=>item.id!=0).map((country, id) => (
              <option key={id} value={country.id}>
                {country.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="countryOfResidence" className="block font-bold mb-2">
            Country Of Residence
          </label>
          <select
            id="countryOfResidence"
            className="input input-bordered w-full py-2 px-4"
            value={updatedCountryOfRes}
            onChange={(e) => setUpdatedCountryOfRes(e.target.value)}
          >
            <option value={0}>Select</option>
            {Countries.filter((item)=>item.id!=0).map((country, id) => (
              <option key={id} value={country.id}>
                {country.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="streetAddress" className="block font-bold mb-2">
            Street Address
          </label>
          <input
            type="text"
            id="streetAddress"
            name="streetAddress"
            className="input input-bordered w-full py-2 px-4"
            value={updatedStreet}
            onChange={(e) => setUpdatedStreet(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="service_charge" className="block font-bold mb-2">
            Service Charge Status
          </label>
          <select
            id="service_charge"
            className="input input-bordered w-full py-2 px-4"
            value={serviceChargeStatus}
            onChange={(e) => setServiceChargeStatus(parseInt(e.target.value))}
          >
             {/* <option value={0}>Select</option> */}
            {Service_Charge_Status.map((status, index) => (
              <option key={index} value={status.id}>
                {status.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block font-bold mb-1">Disqualified Reason</label>
          <select
            id="disqualified"
            className="input input-bordered w-full py-2 px-4"
            value={disqualifiedReason}
            onChange={(e) => setDisqualifiedReason(parseInt(e.target.value))}
          >
            <option value={0}>Select</option>
            {Disqualified_Reason.map((reason, index) => (
              <option key={index} value={reason.id}>
                {reason.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block font-bold mb-1">Interested Course Name</label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={interestedCourseName}
            onChange={(e) => setInterestedCourseName(e.target.value)}
          />
        </div>

        <div className="mb-4">
          <label className="block font-bold mb-1">Lead List Name</label>
          <input
            type="text"
            className="input input-bordered w-full"
            value={leadListName}
            onChange={(e) => setLeadListName(e.target.value)}
          />
        </div>

        {role.isSuperAdmin && (
          <div>
            <label className="block font-bold mb-1">Lead Source</label>
            <select
              id="source"
              className="input input-bordered w-full py-2 px-4"
              value={leadSource}
              onChange={(e) => setLeadSource(parseInt(e.target.value))}
            >
              {STUDENTSOURCES.map((source) => (
                <option key={source.id} value={source.id}>
                  {source.type}
                </option>
              ))}
            </select>
          </div>
        )}

        {role.isSuperAdmin && (
          <div>
            <label className="block font-bold mb-1">Lead Origin</label>
            <select
              id="origin"
              className="input input-bordered w-full py-2 px-4"
              value={leadOrigin}
              onChange={(e) => setLeadOrigin(parseInt(e.target.value))}
            >
              {LEADORIGINS.map((origin) => (
                <option key={origin.id} value={origin.id}>
                  {origin.type}
                </option>
              ))}
            </select>
          </div>
        )}
        {role.isSuperAdmin && (
          <div>
            <label className="block font-bold mb-1">Lead Channel</label>
            <select
              id="channel"
              className="input input-bordered w-full py-2 px-4"
              value={leadChannel}
              onChange={(e) => setLeadChannel(parseInt(e.target.value))}
            >
              {LEADCHANNELS.map((channel) => (
                <option key={channel.id} value={channel.id}>
                  {channel.type}
                </option>
              ))}
            </select>
          </div>
        )}


        <div className="mb-4">
          <label htmlFor="dateOfBirth" className="block font-bold mb-2">
            Date of Birth
            {<span className="text-red-500 ml-2">*</span>}
          </label>
          <input
            type="date"
            id="dateOfBirth"
            name="dateOfBirth"
            className={
              'border border-gray-300 px-3 py-2 rounded-md w-full focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 focus:outline-none shadow-sm transition duration-150'
            }
            value={updatedDateOfBirth ? updatedDateOfBirth.split('T')[0] : ''}
            onChange={handleDateOfBirthChange}
          />
        </div>

        <div className="mb-4">
          <label htmlFor="leadEntryDate" className="block font-bold mb-2">
            Lead Entry Date
          </label>
          <input
            type="date"
            id="leadEntryDate"
            name="leadEntryDate"
            className="input input-bordered w-full py-2 px-4"
            value={
              updatedLeadEntryDate ? updatedLeadEntryDate.split('T')[0] : ''
            }
            onChange={handleLeadEntryDateChange}
          />
        </div>

        {/* Expiry Date */}
        {/* <p>Expiry Date: {formattedDate(updateExpiryDate)}</p>
        <div className="mb-4">
          <label htmlFor="expiryDate" className="block font-bold mb-2">
            Expiry Date
          </label>
          <input
            type="date"
            id="expiryDate"
            name="expiryDate"
            className="input input-bordered w-full py-2 px-4"
            value={updateExpiryDate ? updateExpiryDate.split('T')[0] : ''}
            onChange={handleExpiryDateChange}
          />
        </div> */}
        {/* <p>Lead Entry Date: {formattedDate(updatedLeadEntryDate)}</p> */}
        {/* ... */}
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);