import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import * as actions from '../../store/actions/index'
import Fonts from '../UI/Fonts'
import UserContext from '../../context/user-context'
import RegisterContext from '../../context/register-context'
import Contents from './Contents'
import MainNavigation from './MainNavigation'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import {useLocation} from 'react-router-dom'
import RegistrationStudent from '../Auth/StudentRegistration/RegistrationStudent'

const App = ({isLoggedIn, onTryAutoSignup}) => {
  const location = useLocation()
  const [drawerOpen, setDrawerOpen] = useState(true)
  const [userContext, setUserContext] = useState({
    openDrawer: true,
    unReadMessagesCount: 0,
    notificationCount: 0,
    notifications: [],
    backgroundColor: '#eeeeee',
    agentId: 0,
    status: null,
    applicationId: 0,
    studentId: 0,
    studentName: null,
    draft: '',
  })
  const [registerContext, setRegisterContext] = useState({
    currentPage: 1,
    firstName: '',
    lastName: '',
    countryOfResidence: null,
    email: '',
    password: '',
    selectedCountries: [],
    preferenceStudyLevelId: null,
    subjects: [],
    year: null,
    funding: null,
  })
  useEffect(() => {
    onTryAutoSignup()
    Fonts()
  }, [])

  // return location.pathname == '/register/student' && !isLoggedIn ? (
  return location.pathname == '/register/student' && !isLoggedIn ? (
    <RegisterContext.Provider
      value={{
        currentPage: registerContext.currentPage,
        firstName: registerContext.firstName,
        lastName: registerContext.lastName,
        countryOfResidence: registerContext.countryOfResidence,
        email: registerContext.email,
        password: registerContext.password,
        selectedCountries: registerContext.selectedCountries,
        preferenceStudyLevelId: registerContext.preferenceStudyLevelId,
        subjects: registerContext.subjects,
        year: registerContext.year,
        funding: registerContext.funding,
        setRegisterContext,
      }}
    >
      <StyledEngineProvider injectFirst>
        <RegistrationStudent />
      </StyledEngineProvider>
    </RegisterContext.Provider>
  ) : (
    <>
      <UserContext.Provider
        value={{
          unReadMessagesCount: userContext.unReadMessagesCount,
          notificationCount: userContext.notificationCount,
          notifications: userContext.notifications,
          backgroundColor: userContext.backgroundColor,
          agentId: userContext.agentId,
          status: userContext.status,
          applicationId: userContext.applicationId,
          studentId: userContext.studentId,
          studentName: userContext.studentName,
          draft: userContext.draft,
          setUserContext,
        }}
      >
        <StyledEngineProvider injectFirst>
          <MainNavigation setDrawerOpen={setDrawerOpen} />
          <Contents drawerOpen={drawerOpen} />
        </StyledEngineProvider>
      </UserContext.Provider>
      {/* <Hidden xsDown>
        <Footer />
      </Hidden> */}
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

// import React from 'react'
// import {Button} from '@mui/material'
// import './App.css'
// import Auth from '../Auth/Auth'

// function App() {
//   return (
//     <div className='App'>
//       <Auth />
//       <div className='flex justify-center gap-6 pt-10 pb-20'>
//         <Button className='bg-sky-500 ' variant='contained'>
//           Material UI
//         </Button>
//         <button className='inline-flex bg-rose-700 rounded-md p-3 hover:bg-rose-600'>
//           Tailwind CSS
//         </button>
//       </div>
//     </div>
//   )
// }

// export default App
