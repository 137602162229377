import React, { useEffect, useState } from 'react';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import * as yup from 'yup';
import Agent from '../../../api/agent';
import MessageDisplay from '../LeadUtils/MessageDisplay/MessageDisplay ';
import { Button, Grid } from '@mui/material';

const PhoneAndEmails = ({
  Email,
  PhoneNumber,
  AdditionalEmail1,
  AdditionalEmail2,
  AdditionalPhoneNumber,
  leadId,
}) => {
  const [readOnly, setReadOnly] = useState(true);
  const [formData, setFormData] = useState({
    Email,
    PhoneNumber,
    AdditionalEmail1,
    AdditionalEmail2,
    AdditionalPhoneNumber,
  });
  const [allValidationsPassed, setAllValidationsPassed] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const [additionalPhoneError, setAdditionalPhoneError] = useState('');
  const [emailErrors, setEmailErrors] = useState({
    Email: '',
    AdditionalEmail1: '',
    AdditionalEmail2: '',
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState('');

  useEffect(() => {
    setFormData({
      Email,
      PhoneNumber,
      AdditionalEmail1,
      AdditionalEmail2,
      AdditionalPhoneNumber,
    });
  }, []);

  const handlePhoneChange = (value) => {
    if (!value) {
      setPhoneError('');
    } else if (!/^\+\d{1,3}\d{6,14}$/.test(value)) {
      setPhoneError(
        'Invalid phone number format. Include country code (+) and digits.'
      );
    } else {
      setFormData((prevData) => ({ ...prevData, PhoneNumber: value }));
      setPhoneError('');
    }
  };

  const handleAdditionalPhoneNumber = (value) => {
    if (!value) {
      setAdditionalPhoneError('');
    } else if (!/^\+\d{1,3}\d{6,14}$/.test(value)) {
      setAdditionalPhoneError(
        'Invalid phone number format. Include country code (+) and digits.'
      );
    } else {
      setFormData((prevData) => ({
        ...prevData,
        AdditionalPhoneNumber: value,
      }));
      setAdditionalPhoneError('');
    }
  };

  const validateEmail = (field, value) => {
    if (!value) {
      return ''; // No validation if email field is empty
    }
    try {
      emailValidationSchema.validateSync({ email: value });
      return '';
    } catch (error) {
      return error.errors[0];
    }
  };
  const handleEmailChange = (field, value) => {
    const error = validateEmail(field, value);
    setEmailErrors((prevErrors) => ({
      ...prevErrors,
      [field]: error,
    }));
  
    if (!error) {
      setEmailErrors((prevErrors) => ({
        ...prevErrors,
        [field]: '', 
      }));
      checkAllValidations(); 
    } else {
      setAllValidationsPassed(false); 
    }
  };


  const checkAllValidations = () => {
    const isValid = Object.values(emailErrors).every((error) => error === '');
    setAllValidationsPassed(isValid);
  };

  const emailValidationSchema = yup.object().shape({
    email: yup
      .string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        'Invalid email address'
      )
      .test('unique-email', 'Only one email address allowed', (value) => {
        const emailAddresses = value?.split(/[;,]/);
        return emailAddresses?.length === 1;
      })
      .max(80),
  });

  const handleToggleReadOnly = () => {
    setReadOnly(!readOnly);
  };

  const handleUpdateInfo = () => {
    const payload = {
      Id: leadId,
      Email: formData?.Email === Email ? null : formData?.Email,
      PhoneNumber:
        formData.PhoneNumber === PhoneNumber ? null : formData.PhoneNumber,
      AdditionalEmail1:
        formData.AdditionalEmail1 === AdditionalEmail1
          ? null
          : formData.AdditionalEmail1,
      AdditionalEmail2:
        formData.AdditionalEmail2 === AdditionalEmail2
          ? null
          : formData.AdditionalEmail2,
      AdditionalPhoneNumber:
        formData.AdditionalPhoneNumber === AdditionalPhoneNumber
          ? null
          : formData.AdditionalPhoneNumber,
    };

    console.log('test', payload);
    Agent.StudentLeads.updateLeadEmailAndInfo(payload)
      .then((response) => {
        console.log('Update successful', response);
        setUpdateSuccess(true);
        fetchData();
      })
      .catch((error) => {
        console.error('Update failed', error);
        setUpdateError(`Failed to update information: ${error}`);
      });
  };

  const fetchData = () => {
    Agent.StudentLeads.getLeadEmailAndPhone(leadId)
      .then((response) => {
        setFormData({
          Email: response.email,
          PhoneNumber: response.phoneNumber,
          AdditionalEmail1: response.additionalEmail1,
          AdditionalEmail2: response.additionalEmail2,
          AdditionalPhoneNumber: response.additionalPhoneNumber,
        });
      })
      .catch((error) => {
        console.error('Failed to fetch updated data', error);
      });
  };

  return (
    <div id="basic-info" className="p-4 border rounded">
      <h2 className="text-2xl font-semibold mb-6 text-center">
        Contact Information
      </h2>

      <Grid container spacing={3}>
        <Grid item xs={6}>
          <label htmlFor="email" className="block font-bold mb-2">
            Email
            <span className="text-red-500 ml-2">*</span>
          </label>
          <input
            type="email"
            id="email"
            name="email"
            className={`input input-bordered w-full py-2 px-4 ${
              readOnly ? 'bg-gray-200' : ''
            }`}
            value={formData.Email}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                Email: e.target.value,
              }))
            }
            onBlur={(e) => handleEmailChange('Email', e.target.value)}
            readOnly={readOnly}
          />
          {emailErrors.Email && (
            <p className="text-red-500">{emailErrors.Email}</p>
          )}
        </Grid>

        <Grid item xs={6}>
          <label htmlFor="phoneNumber" className="block font-bold mb-2">
            Phone Number
          </label>
          <PhoneInput
            className="w-full px-3 py-3  text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            value={formData.PhoneNumber || null}
            onChange={handlePhoneChange}
            inputClass={`input input-bordered w-full ${
              phoneError ? 'border-red-500' : ''
            }`}
            disabled={readOnly}
          />
          {phoneError && <p className="text-red-500">{phoneError}</p>}
        </Grid>

        <Grid item xs={6}>
          <label
            htmlFor="additionalPhoneNumber"
            className="block font-bold mb-2"
          >
            Additional Phone Number
          </label>
          <PhoneInput
            className="w-full px-3 py-3  text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
            value={formData.AdditionalPhoneNumber || null}
            onChange={handleAdditionalPhoneNumber}
            inputClass={`input input-bordered w-full ${
              additionalPhoneError ? 'border-red-500' : ''
            }`}
            disabled={readOnly}
          />
          {additionalPhoneError && (
            <p className="text-red-500">{additionalPhoneError}</p>
          )}
        </Grid>

        <Grid item xs={6}>
          <label htmlFor="additionalEmail1" className="block font-bold mb-1">
            Additional Email 1
          </label>
          <input
            type="email"
            id="additionalEmail1"
            name="additionalEmail1"
            className={`input input-bordered w-full ${
              readOnly ? 'bg-gray-200' : ''
            }`}
            value={formData.AdditionalEmail1}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                AdditionalEmail1: e.target.value,
              }))
            }
            onBlur={(e) =>
              handleEmailChange('AdditionalEmail1', e.target.value)
            }
            readOnly={readOnly}
          />
          {emailErrors.AdditionalEmail1 && (
            <p className="text-red-500">{emailErrors.AdditionalEmail1}</p>
          )}
        </Grid>

        <Grid item xs={6}>
          <label htmlFor="additionalEmail2" className="block font-bold mb-1">
            Additional Email 2
          </label>
          <input
            type="email"
            id="additionalEmail2"
            name="additionalEmail2"
            className={`input input-bordered w-full ${
              readOnly ? 'bg-gray-200' : ''
            }`}
            value={formData.AdditionalEmail2}
            onChange={(e) =>
              setFormData((prevData) => ({
                ...prevData,
                AdditionalEmail2: e.target.value,
              }))
            }
            onBlur={(e) =>
              handleEmailChange('AdditionalEmail2', e.target.value)
            }
            readOnly={readOnly}
          />
          {emailErrors.AdditionalEmail2 && (
            <p className="text-red-500">{emailErrors.AdditionalEmail2}</p>
          )}
        </Grid>
      </Grid>

      <div className="mt-6 flex justify-end space-x-4">
        <Button
          variant="contained"
          color="primary"
          className="bg-sky-700"
          onClick={handleToggleReadOnly}
          disabled={updateSuccess}
        >
          {readOnly ? 'Enable Edit' : 'Disable Edit'}
        </Button>
        <Button
          variant="contained"
          color="success"
          className="bg-green-500"
          type="submit"
          onClick={handleUpdateInfo}
          disabled={
            readOnly ||
            updateSuccess ||
            phoneError ||
            emailErrors.Email ||
            emailErrors.AdditionalEmail1 ||
            emailErrors.AdditionalEmail2
          }
        >
          {updateSuccess ? 'Updated' : 'Update Info'}
        </Button>
      </div>

      {updateSuccess && (
        <MessageDisplay
          type="success"
          message="Updated successfully!"
          duration={3000}
          onClose={() => setUpdateSuccess(false)}
        />
      )}

      {updateError && (
        <MessageDisplay
          type="error"
          message={`Failed to update: ${updateError}`}
          duration={5000}
          onClose={() => setUpdateError('')}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneAndEmails);
