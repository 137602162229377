import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Typography,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import 'tailwindcss/tailwind.css';
import 'tailwindcss/tailwind.css';
import { connect } from 'react-redux';
import {
  DataGridPro,
  GridPagination,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import Agent from '../../../api/agent';
import MyTooltip from '../../Common/Tooltip/MyTooltip';
import { Countries } from '../../../Util/Util';
import BulkSummaryDialog from '../StudentLeadComms/SendBulkMailDialog/BulkSummaryDialog';
import CountdownLoader from '../StudentLeadComms/LeadComUtils/CountdownLoader';
import ContactComFilters from './ContactComFilters';
import { CONTACT_SERVICE_TYPE, CONTACT_TYPE } from './../../../Util/Constants';

function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {isInternal && <GridToolbarColumnsButton />}
      {/* {isInternal && <GridToolbarFilterButton />} */}
      <GridToolbarDensitySelector />
      {hide && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}
const ContactComBulkEmail = ({ appUserId }) => {
  const [showCountdownLoader, setShowCountdownLoader] = useState(false);
  const [eventYear, setEventYear] = useState('');
  const [eventName, setEventName] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [designation, setDesignation] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [studentsLead, setStudentsLead] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [serviceTypeId, setServiceTypeId] = useState(0);
  const [typeOfContact, setTypeOfContact] = useState(0);
  const [id, setId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [totalSelected, setTotalSelected] = useState(0);
  const [fromMail, setFromEmail] = useState(0);
  const [selectTemplateId, setSelectTemplateId] = useState('');
  const [templates, setTemplates] = useState([]);
  const [studentsLeadIdList, setStudentsLeadIdList] = useState([]);
  const [fromMailList, setFromMailList] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [dialogueCommunicationName, setDialogueCommunicationName] =
    useState('');
  const [mailSubject, setMailSubject] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showData, setShowData] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(800);
  const [totalrows, setTotalRows] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [events, setEventList] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCountdownComplete = () => {
    setShowCountdownLoader(false);
  };

  const handleApplyFilters = () => {
    setShowData(true);
    setPageNumber(1);
    fetchData(true);
  };

  const handleSendMail = async () => {
    handleCloseConfirmDialog();
    setShowCountdownLoader(true);

    try {
      const response = await Agent.Communication.sendContactBulkEmail(payload);
      setOpenDialog(false);
      setSuccessMessage('Mail sent successfully!', response);
      setShowCountdownLoader(false);
    } catch (error) {
      console.error(error);
      setErrorMessage(error);
      setShowErrorMessage(true);
    }
  };

  useEffect(() => {
    Agent.Events.getEventList().then(setEventList);
  }, []);

  const handleAddStudentsLeadId = (selectedStudentsLeadId) => {
    setStudentsLeadIdList((prevList) => [
      ...prevList,
      { Id: selectedStudentsLeadId },
    ]);
  };

  const closeErrorMessage = () => {
    setShowErrorMessage(false);
  };

  const ErrorMessageDisplay = () =>
    showErrorMessage && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-red-800 text-white p-6 rounded-lg shadow-lg z-50">
          <button
            className="btn btn-warning float-right"
            onClick={closeErrorMessage}
          >
            Close
          </button>
          <p>{errorMessage}</p>
        </div>
      </div>
    );

  const payload = {
    ContactManagements: studentsLeadIdList,
    CommunicationName: dialogueCommunicationName,
    TemplateId: selectTemplateId,
    EmailSubject: mailSubject,
    FromEmail: fromMail,
    AppUserId: appUserId,
    NumberOfStudents: totalSelected,
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    setSelectedRowCount(selectedRows.length);
  }, [selectedRows]);

  useEffect(() => {
    setTotalSelected(selectedRows.length);
  }, [selectedRows]);

  const fetchTemplates = async () => {
    try {
      const response = await Agent.Communication.getContactComTemp();
      setTemplates(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', pageSize.toString());
    if (filter) {
      params.append('TypeOfContact', typeOfContact);
    }
    // params.append('Id', filter ? id.trim() : '');
    if (filter) {
      params.append('ContactName', contactName.trim());
    }
    // params.append('ContactName', filter ? contactName.trim() :'');
    params.append('Designation', filter ? designation.trim() : '');
    params.append('ContactPerson', filter ? contactPerson.trim() : '');
    params.append('EventName', filter ? eventName.trim() : '');

    if (filter) {
      params.append('EventYear', eventYear);
    }
    if (filter) {
      params.append('ServiceType', serviceTypeId);
    }

    if (filter) {
      params.append('CountryId', countryId);
    }
    return params;
  };

  useEffect(() => {
    Agent.Communication.getFromEmails().then(setFromMailList);
  }, []);

  const fetchData = async (filter = false) => {
    setLoading(true);
    try {
      const params = getParams(filter);
      const leads = await Agent.Communication.listContactMailList(params);
      const { pagination, data } = leads;
      setTotalPages(pagination.totalPages);
      setTotalRows(pagination.totalItems);
      setDataLoaded(true);
      setStudentsLead(data);
      console.log('comms lead', data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(true);
  }, []);

  const handleTypeOfContact = (event) => {
    setTypeOfContact(event.target.value);
  };
  const handleServiceType = (event) => {
    setServiceTypeId(event.target.value);
  };

  const handleSelectTemplateIdChange = (event) => {
    setSelectTemplateId(event.target.value);
  };

  const handleCommunicationNameChange = (event) => {
    setDialogueCommunicationName(event.target.value);
  };

  const handleMailSubjectChange = (event) => {
    setMailSubject(event.target.value);
  };
  const handleFromMailChange = (event) => {
    setFromEmail(event.target.value);
  };
  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    // If Select All is checked, select all students on the current page
    if (!selectAll) {
      const selectedIds = studentsLead.map((studentLead) => ({
        Id: studentLead.id,
      }));

      // console.log('test lead',studentLead)

      setStudentsLeadIdList(selectedIds);
      setSelectedRows(selectedIds.map((student) => student.Id));
    } else {
      setStudentsLeadIdList([]);
      setSelectedRows([]);
    }
  };

  const renderCheckbox = (params) => (
    <Checkbox
      checked={selectedRows.includes(params.row.id) || selectAll}
      onChange={(event) => handleRowSelection(event, params.row.id)}
    />
  );

  let studentsLeadColumns = [
    {
      field: 'checkbox',
      headerName: 'Select',
      width: 100,
      renderCell: renderCheckbox,
      headerAlign: 'center',
      renderHeader: () => (
        <Checkbox
          checked={selectAll}
          onChange={handleSelectAll}
          indeterminate={
            selectedRows.length > 0 && selectedRows.length < studentsLead.length
          }
        />
      ),
    },
    {
      field: 'id',
      headerName: 'Id',
      width: 300,
      hide: true,
      renderCell: (params) => <Typography>{params.row.id}</Typography>,
    },
    {
      field: 'contactName',
      headerName: 'Contact Name',
      width: 250,
      renderCell: (params) => (
        <MyTooltip title={params.row.contactName} color="primary">
          <Typography className="clickable">
            {params.row.contactName}
          </Typography>
        </MyTooltip>
      ),
    },
    {
      field: 'emailAddress',
      headerName: 'Email Address',
      width: 300,
      renderCell: (params) => (
        <Typography>{params.row.emailAddress}</Typography>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 300,
      renderCell: (params) => <Typography>{params.row.email}</Typography>,
    },

    {
      field: 'countryId',
      headerName: 'Country',
      width: 250,
      valueGetter: (params) => {
        const Citizenship = params.row.countryId;
        const country = Countries.find(
          (citizenship) => citizenship.id == Citizenship
        );
        return country ? country.name : '';
      },
    },
    {
      field: 'website',
      headerName: 'Website',
      width: 200,
    },
    {
      field: 'serviceType',
      headerName: 'Service Type',
      width: 200,
      valueGetter: (params) => {
        const serviceId = params.row.serviceType;
        const service = CONTACT_SERVICE_TYPE.find(
          (service) => service.id == serviceId
        );
        return service ? service.name : '';
      },
    },

    {
      field: 'Type Of Contact',
      headerName: 'Type Of Contact',
      width: 200,
      valueGetter: (params) => {
        const contactType = params.row.typeOfContact;
        const type = CONTACT_TYPE.find((type) => type.id == contactType);
        return type ? type.name : '';
      },
    },

    {
      field: 'contactNumber',
      headerName: 'Contact',
      width: 250,
      hide: true,
      renderCell: (params) => (
        <Typography>{params.row.contactNumber}</Typography>
      ),
    },

    {
      field: 'eventName',
      headerName: 'Event Name',
      hide: false,
      width: 230,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.eventName}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'eventYear',
      headerName: 'Event Year',
      hide: false,
      width: 230,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.eventYear}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'eventLocation',
      headerName: 'Event Location',
      hide: false,
      width: 230,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.eventLocation}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'contactPerson',
      headerName: 'Contact Person',
      hide: false,
      width: 230,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.contactPerson}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'designation',
      headerName: 'Designation',
      hide: false,
      width: 230,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.designation}</Typography>
          </Grid>
        </Grid>
      ),
    },
  ];

  const handleIdChange = (event, value) => {
    setId(value ? value.id : '');
  };

  const handleCountryIdChange = (event, value) => {
    setCountryId(value ? value.id : 0);
  };

  const handleEventNameChange = (event) => {
    const selectedValue = event.target.value;
    setEventName(selectedValue);
  };
  const handleContactName = (event) => {
    const selectedValue = event.target.value;
    setContactName(selectedValue);
  };
  const handleDesignation = (event) => {
    const selectedValue = event.target.value;
    setDesignation(selectedValue);
  };
  const handleContactPerson = (event) => {
    const selectedValue = event.target.value;
    setContactPerson(selectedValue);
  };

  const handleEventYear = (event) => {
    setEventYear(event.target.value);
  };

  const handleClearFilters = () => {
    setDesignation('');
    setContactPerson('');
    setCountryId(0);
    setId('');
    setTypeOfContact(0);
    setServiceTypeId(0);
    setContactName('');
    setEventName('');
    setEventYear(0);
    setSelectedRows([]);
    setStudentsLeadIdList([]);
    setShowData(false);
    setSelectAll(false);
    fetchData(false); // Refetch the data after clearing filters
  };

  const handleRowSelection = (event, rowId) => {
    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowId];
      const selectedStudensLeadtId = studentsLead.find(
        (studentLead) => studentLead.id === rowId
      ).id;
      handleAddStudentsLeadId(selectedStudensLeadtId);
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
    }

    setSelectedRows(newSelectedRows);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDialogueCommunicationName('');
  };

  const ConfirmDialog = () => (
    <Dialog
      open={openConfirmDialog}
      onClose={handleCloseConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Confirm Sending Mail'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description ">
          Are you sure you want to send the mail?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="flex justify-around">
        <Button
          onClick={handleCloseConfirmDialog}
          color="primary"
          className="btn-primary"
        >
          No
        </Button>
        <Button
          onClick={handleSendMail}
          color="success"
          className="btn-success"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <div className="h-96 w-full">
      <div className="bg-white p-4 mb-4 shadow-sm">
        <ContactComFilters
          id={id}
          handleIdChange={handleIdChange}
          countryId={countryId}
          handleCountryIdChange={handleCountryIdChange}
          eventYear={eventYear}
          handleEventYear={handleEventYear}
          eventName={eventName}
          contactName={contactName}
          handleContactName={handleContactName}
          contactPerson={contactPerson}
          designation={designation}
          handleDesignation={handleDesignation}
          handleContactPerson={handleContactPerson}
          events={events}
          handleEventNameChange={handleEventNameChange}
          handleClearFilters={handleClearFilters}
          handleApplyFilters={handleApplyFilters}
          handleSendBulkEmail={handleOpenDialog}
          totalSelected={totalSelected}
          serviceTypeId={serviceTypeId}
          typeOfContact={typeOfContact}
          handleTypeOfContact={handleTypeOfContact}
          handleServiceType={handleServiceType}
        />

        {showData ? (
          dataLoaded ? (
            <DataGridPro
              rows={studentsLead}
              columns={studentsLeadColumns}
              totalPageSize={totalPages}
              loading={loading}
              autoHeight
              pagination={true}
              paginationMode="server"
              pageSize={pageSize}
              pageSizeOptions={[800]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowCount={totalrows}
              onPageChange={(newPage) => setPageNumber(newPage + 1)}
              page={pageNumber - 1}
              components={{
                Toolbar: CustomToolbar,
                Pagination: GridPagination,
              }}
            />
          ) : (
            <div>Loading data...</div>
          )
        ) : (
          <div>No data to display. Please apply filters to show data.</div>
        )}

        <BulkSummaryDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          totalSelected={totalSelected}
          templates={templates}
          fromMailList={fromMailList}
          dialogueCommunicationName={dialogueCommunicationName}
          setDialogueCommunicationName={setDialogueCommunicationName}
          selectTemplateId={selectTemplateId}
          setSelectTemplateId={setSelectTemplateId}
          mailSubject={mailSubject}
          setMailSubject={setMailSubject}
          fromMail={fromMail}
          setFromMail={setFromEmail}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          handleSelectTemplateIdChange={handleSelectTemplateIdChange}
          handleFromMailChange={handleFromMailChange}
          handleMailSubjectChange={handleMailSubjectChange}
          handleCommunicationNameChange={handleCommunicationNameChange}
        />

        {successMessage && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-blue-500 text-black p-4 rounded shadow-lg">
              <p>{successMessage}</p>
              <button
                className="btn btn-warning mt-4"
                onClick={() => setSuccessMessage('')}
              >
                Close
              </button>
            </div>
          </div>
        )}

        <ErrorMessageDisplay />
        <ConfirmDialog />

        {showCountdownLoader && (
          <CountdownLoader
            totalSelected={totalSelected}
            onCountdownComplete={handleCountdownComplete}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(ContactComBulkEmail);
