import React, { useState } from 'react';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordion from '@mui/material/Accordion';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import Application from './Application';
import { connect } from 'react-redux';
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Rating,
} from '@mui/material';

import ConfirmationModal from '../Common/Modal/ConfirmationModal';
import Agent from '../../api/agent';
import { RefreshOutlined } from '@mui/icons-material';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderRadius: '6px',
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ApplicationWrapper = ({
  application,
  applicationToExpand,
  role,
  permissions,
  setReload
}) => {
  const navigate = useNavigate();
  const [updating, setUpdating] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  // const [expanded, setExpanded] = React.useState('panel2');

  // const handleChange = (panel) => (event, newExpanded) => {
  //   setExpanded(newExpanded ? panel : false);
  // };

  const [rating, setRating] = useState(application.applicationPriorityId!=null?application.applicationPriorityId:0)
  const handleRating = (rate) => {
    setRating(rate.target.defaultValue);
    if(rate.target.defaultValue>0 && application.applicationPriorityId!=rate.target.defaultValue){
      Agent.Applications.applPriorityUpdate(application.id,rate.target.defaultValue)
      .then(() => {
       setSuccessMessage('Application Priority Updated Successfully!!'); 
       setSuccessOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }
  const handleResetRating = () => {
      setRating(0);
      Agent.Applications.applPriorityUpdate(application.id,0)
      .then(() => {
       setSuccessMessage('Priority Reset Successfully!!'); 
       setSuccessOpen(true);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  
  const disableApplication = () => {
    setUpdating(true);
    console.log('application', application);
    Agent.Applications.disableApplication(application.id)
      .then(() => {
        setUpdating(false);
        setOpenConfirm(false);
        setReload(true);
        //setSuccessMessage('Successfully Application Disabled');
        //setSuccessOpen(true);
        //navigate(`/students/manage/${application.studentId}`);
      })
      .catch((error) => {
        setUpdating(false);
        //setErrorMessage(error);
        console.log(error);
        debugger;
      });
  };
  const successDialogStyles = {
    backgroundColor: 'bg-green-400',
  };
  const errorDialogStyles = {
    backgroundColor: 'bg-red-400',
  };
  return (
    <>
      <Dialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        aria-labelledby="success-dialog-title"
        PaperProps={{
          className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
        }}
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>{successMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        aria-labelledby="error-dialog-title"
        PaperProps={{
          className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
        }}
      >
        <DialogTitle id="error-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorMessage('')} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className="w-full rounded-lg">
        <Accordion
          className=""
          defaultExpanded={application.id == applicationToExpand}

          // onChange={handleChange('panel1')}
        >
          <AccordionSummary className="bg-white text-gray-700 w-full font-medium">
            <Grid container justifyContent="space-between">
              <Grid item>
              {/* <div>{`${application.courseName}, ${application.universityName}`}</div> */}
                <div>{`${application.universityName}`}</div>
               { role!="Agent" && role!="Student" &&( <div className='flex'>
              <Rating
               max={3}
               value={rating}
               onClick={handleRating}
              />
               &nbsp;&nbsp;&nbsp;
               <button onClick={handleResetRating}>
               <RefreshOutlined className='text-sky-600 w-5 hover:w-6'/>   
               </button>
             </div>)}
              </Grid>
              {(role === 'SuperAdmin' ||
                permissions.CanDisableApplication) && (
                <Grid item>
                  <LoadingButton
                    loading={updating}
                    onClick={() => setOpenConfirm(true)}
                    className="bg-rose-400 hover:bg-rose-500 text-white"
                  >
                    Disable Application
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Application id={application.id} />
          </AccordionDetails>
        </Accordion>
        <ConfirmationModal
          open={openConfirm}
          cancel={setOpenConfirm}
          confirm={disableApplication}
          confirmText="Confirm Action"
          content="Are you sure that you would like to remove this application?"
        />
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    role: state.auth.roleName.replaceAll(' ', ''),
    permissions: state.auth.permissions,
    application: ownProps.application,
    applicationToExpand: ownProps.applicationToExpand,
  };
};

export default connect(mapStateToProps, null)(ApplicationWrapper);
