import React, { useState, useEffect, useContext } from 'react';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import SearchIcon from '@mui/icons-material/Search';
import { Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useTheme, alpha, styled } from '@mui/material/styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import GroupIcon from '@mui/icons-material/Group';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import SchoolIcon from '@mui/icons-material/School';
import HomeIcon from '@mui/icons-material/Home';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DescriptionIcon from '@mui/icons-material/Description';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ListIcon from '@mui/icons-material/List';
import { connect } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import { STATUS_APPROVED } from '../../Util/Constants';
import * as actions from '../../store/actions/index';
import { CompanyLogoWithoutBottomText } from '../UI/Logos';
import AvatarMui from '../Common/Image/AvatarMui';
import sideBgImage from '../../assets/images/sidebar-2.jpg';
import UserContext from '../../context/user-context';
import Task from '../Tasks/Task';
const PREFIX = 'MainNavigation';
// import StudentNavbar from './studentNavbar';
import NotificationDropdown from '../Custom/Menu/NotificationDropdown';
import Agent from '../../api/agent';
import ReferralNavbar from './ReferralNavbar';
import CommonNavbar from './CommonNavbar';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import { ContactPageRounded } from '@mui/icons-material';
import { AdminPanelSettings } from '@mui/icons-material';

const classes = {
  grow: `${PREFIX}-grow`,
  root: `${PREFIX}-root`,
  drawer: `${PREFIX}-drawer`,
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  menuButton: `${PREFIX}-menuButton`,
  hide: `${PREFIX}-hide`,
  drawerOpen: `${PREFIX}-drawerOpen`,
  drawerClose: `${PREFIX}-drawerClose`,
  toolbar: `${PREFIX}-toolbar`,
  content: `${PREFIX}-content`,
  title: `${PREFIX}-title`,
  search: `${PREFIX}-search`,
  searchIcon: `${PREFIX}-searchIcon`,
  inputRoot: `${PREFIX}-inputRoot`,
  inputInput: `${PREFIX}-inputInput`,
  sectionDesktop: `${PREFIX}-sectionDesktop`,
  sectionMobile: `${PREFIX}-sectionMobile`,
  profilePhotoMenu: `${PREFIX}-profilePhotoMenu`,
  activeLink: `${PREFIX}-activeLink`,
  regularLink: `${PREFIX}-regularLink`,
};
const drawerWidth = 240; // 58

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.grow}`]: {
    flexGrow: 1,
  },
  [`& .${classes.root}`]: {
    display: 'flex',
  },
  [`& .${classes.drawer}`]: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  // appBar: {
  //   backgroundImage:
  //     'linear-gradient(135deg, rgb(24, 42, 115) 0%, rgb(33, 138, 174) 69%, rgb(32, 167, 172) 89%) !important',
  //   [theme.breakpoints.up('sm')]: {
  //     width: `calc(100% - ${drawerWidth}px)`,
  //     marginLeft: drawerWidth,
  //   },
  // },
  [`& .${classes.appBar}`]: {
    backgroundImage:
      // 'linear-gradient(135deg, rgb(24, 42, 115) 0%, rgb(33, 138, 174) 69%, rgb(32, 167, 172) 89%) !important',
      'primary',

    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  [`& .${classes.appBarShift}`]: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${classes.menuButton}`]: {
    marginRight: 36,
  },
  [`& .${classes.hide}`]: {
    display: 'none',
  },
  [`& .${classes.drawerOpen}`]: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${classes.drawerClose}`]: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `${theme.spacing(7)}`,
  },
  // necessary for content to be below app bar
  [`& .${classes.toolbar}`]: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'flex-end',
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  [`& .${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  [`& .${classes.title}`]: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  [`& .${classes.search}`]: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  [`& .${classes.searchIcon}`]: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  [`& .${classes.inputRoot}`]: {
    color: 'inherit',
  },
  [`& .${classes.inputInput}`]: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  [`& .${classes.sectionDesktop}`]: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  [`& .${classes.sectionMobile}`]: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  [`& .${classes.profilePhotoMenu}`]: {
    top: 0,
  },
  [`& .${classes.activeLink}`]: {
    // color: '#0064e1',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    radius: 25,
    fontWeight: 700,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  [`& .${classes.regularLink}`]: {
    '&:hover': {
      backgroundColor: '#1C315E',
    },
  },
}));

const MainNavigation = (props, permissions) => {
  const [addTask, setAddTask] = useState(false);
  const theme = useTheme();
  const [openNotification, setOpenNotification] = useState(null);
  const [platform, setPlatform] = useState('Production');
  const [showFullMenuState, setShowFullMenuState] = useState(0);
  //const { classes } = props;

  let userContext = useContext(UserContext);

  useEffect(() => {
    if (props.appUserId) {
      Agent.Summary.getUnread()
        .then((count) => {
          userContext = { ...userContext, unReadMessagesCount: count };
          userContext.setUserContext(userContext);
        })
        .catch((error) => {
          console.log(error);
          debugger;
        });
    }
  }, [props.appUserId]);

  useEffect(() => {
    if (props.appUserId) {
      Agent.Summary.getNotifications()
        .then((notifications) => {
          var notificationsTemp = notifications.filter((item) => !item.isRead);
          userContext = {
            ...userContext,
            notificationCount: notificationsTemp.length,
            notifications,
          };
          userContext.setUserContext(userContext);
        })
        .catch((error) => {
          console.log(error);
          debugger;
        });
    }
  }, [props.appUserId]);

  useEffect(() => {
    if (props.appUserId) {
      if (
        (props.role?.isAgent || props.role?.isAgentStaff) &&
        userContext.agentId == 0
      ) {
        // we will stick some agent data into the context for later user
        Agent.Agents.getAgent(0, props.appUserId)
          .then((agent) => {
            // if (agent.status == STATUS_APPROVED) {
            //   setShowFullMenuState(2);
            // } else {
            //   setShowFullMenuState(1);
            // }

            if (agent.status == STATUS_APPROVED) {
              setShowFullMenuState(2); 
            } else if (agent.status == 4) {  //Status 4 means blocked agent
              setShowFullMenuState(3);               
            } else {
              setShowFullMenuState(1); 
            }

            userContext = {
              ...userContext,
              ...{
                agentId: agent.id,
                status: agent.status,
              },
            };
            userContext.setUserContext(userContext);
          })
          .catch((error) => {
            console.log(error);
            debugger;
          });
      }
    }
  }, [props.appUserId]);

  useEffect(() => {
    Agent.Common.getplatform().then(setPlatform);
  }, [props.appUserId]);

  const [open, setOpen] = useState(true);
  const [locked, setLocked] = useState(true);

  const handleDrawerOpen = () => {
    if (!locked) {
      props.setDrawerOpen(true);
      setOpen(true);
    }
  };

  const handleDrawerClose = () => {
    if (!locked) {
      setOpen(false);
      props.setDrawerOpen(false);
    }
  };

  let menuOptions = [];

  if (props.isLoggedIn) {
    if (
      props.role.isSuperAdmin ||
      props.role.isSeniorAdmin ||
      props.role.isAdmin
    ) {
      menuOptions.push('Home');

      if (props.role.isSuperAdmin || props.permissions.CanManageContact) {
        menuOptions.push('Contact');
      }
      if (props.role.isSuperAdmin || props.permissions.CanManageEvents) {
        menuOptions.push('Events');
      }
      if (
        props.role.isSuperAdmin ||
        props.permissions.CanManageLeads ||
        props.permissions.CanManageAllLeads
      ) {
        menuOptions.push('Leads');
      }

      if (props.role.isSuperAdmin || props.permissions.CanAccessActivityLog) {
        menuOptions.push('UsersActivity');
        // menuOptions.push('AdminPanel');
      }

      if (
        props.role.isSuperAdmin ||
        props.permissions.CanAccessPartnershipSection
      ) {
        menuOptions.push('Partnerships');
      }
      menuOptions.push('Students');
      menuOptions.push('Applications');

      if (
        props.role.isSuperAdmin ||
        props.permissions.CanAccessProgramsSection
      ) {
        menuOptions.push('Programs');
      }

      if (
        props.role.isSuperAdmin ||
        props.permissions.CanAccessAppointmentSection
      ) {
        menuOptions.push('Appointments');
      }

      if (
        props.permissions.CanManageWhatsAppUK ||
        props.permissions.CanManageWhatsAppBD ||
        props.permissions.CanManageWhatsAppIndia ||
        props.permissions.CanManageWhatsAppPak ||
        props.permissions.CanManageWhatsAppMor ||
        props.permissions.CanManageWhatsAppSA
      ) {
        menuOptions.push('WhatsApp');
      }
      // if (props.role.isSuperAdmin) {
      //   menuOptions.push('Users');
      // }
      if (props.role.isSuperAdmin || props.permissions.CanUseComms) {
        menuOptions.push('Comms');
      }

      if (
        props.permissions.CanAccessReportSection ||
        (props.permissions.CanManageAutomatedReports &&
          !props.role.isSuperAdmin)
      ) {
        menuOptions.push('Report');
      }

      if (props.permissions.CanAccessMessageSection) {
        menuOptions.push('Messages');
      } // menuOptions.push('Notifications');

      if (props.permissions.CanAccessNotificationsSection) {
        menuOptions.push('Notifications');
      }

      if (
        props.role.isSuperAdmin ||
        props.permissions.CanAccessUniversitySection
      ) {
        menuOptions.push('Universities');
      }

      if (props.permissions.CanAccessTasksSection) {
        menuOptions.push('Tasks');
      }
    } else if (props.role.isAgent || props.role.isAgentStaff) {
      menuOptions.push('Home');
      menuOptions.push('Profile');
      if (showFullMenuState == 2) {
        menuOptions.push('Students');
        menuOptions.push('Applications');
        menuOptions.push('Programs');
        menuOptions.push('Messages');
        menuOptions.push('Notifications');
      }
      if (showFullMenuState == 3) {
        menuOptions.push('Students');
        menuOptions.push('Applications');
        menuOptions.push('Messages');
        menuOptions.push('Notifications');
      }
      if (showFullMenuState != 0) menuOptions.push('Settings');
    } else if (
      props.role.isApplicationTeam ||
      props.role.isApplicationTeamLeader
    ) {
      menuOptions.push('Home');
      menuOptions.push('Partnerships');
      menuOptions.push('Students');
      menuOptions.push('Applications');

      if (props.permissions.CanAccessMessageSection) {
        menuOptions.push('Messages');
      }

      if (props.permissions.CanAccessTasksSection) {
        menuOptions.push('Tasks');
      }

      if (props.permissions.CanAccessNotificationsSection) {
        menuOptions.push('Notifications');
      }

      if (
        props.role.isSuperAdmin ||
        props.permissions.CanAccessUniversitySection
      ) {
        menuOptions.push('Universities');
      }
    } else if (props.role.isStudent) {
      menuOptions.push('Home');
      menuOptions.push('Profile');
      menuOptions.push('Preferences');
      menuOptions.push('Programs');
      menuOptions.push('Applications');
      menuOptions.push('Appointments');
      menuOptions.push('Notifications');
      menuOptions.push('Settings');
    }
    if (
      props.permissions.CanManageAllEmployees ||
      props.permissions.CanManageOwnEmployees
    ) {
      menuOptions.push('Employees');
    }
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const navigate = useNavigate();

  const handleLogOut = () => {
    props.logout();
    handleMenuClose();
    navigate('/auth');
  };
  const handleLogIn = () => {
    navigate('/');
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMyAccountSettings = () => {
    handleMenuClose();
    navigate('/settings');
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const drawer = (
    <>
      <div
        className={classes.toolbar}
        style={{ paddingLeft: 16, paddingTop: 5 }}
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <CompanyLogoWithoutBottomText style={{ height: '55px' }} />
          </Grid>
          {open ? (
            <Grid item>
              <IconButton
                onClick={() => setLocked((prev) => !prev)}
                size="large"
              >
                {locked ? (
                  <LockIcon color="primary" />
                ) : (
                  <LockOpenIcon color="primary" />
                )}
                {/* {theme.direction === 'rtl' ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )} */}
              </IconButton>
            </Grid>
          ) : null}
        </Grid>
      </div>
      <List
        style={
          open
            ? {
                backgroundColor: props.isLoggedIn ? '#181D31' : null,
                height: '100%',
                padding: 10,
                margin: 10,
                borderRadius: 10,
              }
            : {
                backgroundColor: props.isLoggedIn ? '#181D31' : null,
                height: '100%',
              }
        }
      >
        {menuOptions.map((text) => (
          <ListItem
            Button
            key={text}
            component={NavLink}
            to={'/' + text}
            activeClassName={classes.activeLink}
            style={{ borderRadius: 10 }}
            className={classes.regularLink}
          >
            <ListItemIcon>
              {(() => {
                switch (text) {
                  case 'Contact':
                    return <ContactPageRounded className="text-white" />;
                  // case 'AdminPanel':
                  //   return <AdsClickIcon className="text-white" />;
                  case 'UsersActivity':
                    return <AdsClickIcon className="text-white" />;
                  case 'Events':
                    return <ScheduleIcon className="text-white" />;
                  case 'Leads':
                    return <PersonSearchRoundedIcon className="text-white" />;
                  case 'Partnerships':
                    return <ScheduleIcon className="text-white" />;
                  case 'Students':
                    return <SchoolIcon className="text-white" />;
                  case 'Applications':
                    return <DescriptionIcon className="text-white" />;
                  case 'Programs':
                    return <SearchIcon className="text-white" />;
                  case 'Users':
                    return <GroupIcon className="text-white" />;
                  case 'Profile':
                    return <ContactMailIcon className="text-white" />;
                  case 'Messages':
                    return <EmailIcon className="text-white" />;
                  case 'Notifications':
                    return <NotificationsIcon className="text-white" />;
                  case 'Settings':
                    return <SettingsIcon className="text-white" />;
                  case 'Universities':
                    return <AccountBalanceIcon className="text-white" />;
                  case 'Preferences':
                    return <ListIcon className="text-white" />;
                  case 'Appointments':
                    return <ScheduleIcon className="text-white" />;
                  case 'Report':
                    return <AssessmentIcon className="text-white" />;
                  case 'Comms':
                    return <ContactMailIcon className="text-white" />;
                  case 'Tasks':
                    return <AssignmentIcon className="text-white" />;
                  case 'Employees':
                    return <SupervisedUserCircleIcon className="text-white" />;
                  case 'WhatsApp':
                    return <WhatsAppIcon className="text-white" />;
                  default:
                    return <HomeIcon className="text-white" />;
                }
              })()}
              {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{
                style: { color: 'white' },
              }}
              primary={text}
            />
          </ListItem>
        ))}
      </List>
    </>
  );

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{ marginTop: 54 }}
      className={classes.profilePhotoMenu}
    >
      {props.isInternal && (
        <MenuItem onClick={() => setAddTask(true)}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          Create Task
        </MenuItem>
      )}
      <MenuItem onClick={handleMyAccountSettings}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        Account Settings
      </MenuItem>
      <MenuItem onClick={handleLogOut}>
        <ListItemIcon>
          <LogoutIcon />
        </ListItemIcon>
        Log out
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {props.role && !props.role.isStudent && (
        <MenuItem>
          <IconButton
            color="inherit"
            onClick={() => navigate('/messages')}
            size="large"
          >
            <Badge
              badgeContent={userContext.unReadMessagesCount}
              color="secondary"
            >
              <MailIcon />
            </Badge>
          </IconButton>
          <p>Messages</p>
        </MenuItem>
      )}
      <MenuItem>
        <IconButton color="inherit" size="large">
          <Badge badgeContent={userContext.notificationCount} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleLogOut}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          size="large"
        >
          <AvatarMui imageUrl={props.imageUrl} fullName={props.name} size={5} />
        </IconButton>
        <p>Log out</p>
      </MenuItem>
    </Menu>
  );

  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickNotificationItem = (item) => {
    handleCloseNotification();
    navigate(item.link);
    Agent.Summary.updateAsReadNotifi([item.id]).then((res) => {
      Agent.Summary.getNotifications().then((notifications) => {
        var notificationsTemp = notifications.filter((item) => !item.isRead);
        userContext = {
          ...userContext,
          notificationCount: notificationsTemp.length,
          notifications,
        };
        userContext.setUserContext(userContext);
      });
    });
    userContext = {
      ...userContext,
      notificationCount: userContext.notificationCount - 1,
    };
    userContext.setUserContext(userContext);

    //let's not delete notification when users click on this. users can only delete from notification page
    // Agent.Common.deletenotification(item.id)
    //   .then(() => {
    //     userContext.setUserContext({
    //       ...userContext,
    //       notificationCount: userContext.notificationCount - 1,
    //       notifications: userContext.notifications.filter(function (
    //         notification
    //       ) {
    //         return notification.id != item.id;
    //       }),
    //     });
    //     navigate(item.link);
    //   })
    //   .catch((error) => {
    //     debugger;
    //     console.log(error);
    //   });
  };

  return props.isLoggedIn && props.role.isStudent ? (
    <>
      {/* <StudentNavbar /> */}
      <CommonNavbar />
    </>
  ) : props.isLoggedIn && props.role.isReferral ? (
    <>
      <ReferralNavbar />
    </>
  ) : (
    <>
      <Root className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx('bg-black', {
            [classes.appBarShift]: open && props.isLoggedIn,
          })}
        >
          {props.isLoggedIn && (
            <Toolbar className="bg-[#11aae2]">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={() => setLocked((prev) => !prev)}
                style={{ marginLeft: theme.spacing(5 + 1) }}
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
                size="large"
              >
                {locked ? (
                  <LockIcon color="light" />
                ) : (
                  <LockOpenIcon color="light" />
                )}
              </IconButton>

              <div className="text-xl">
                {props.isLoggedIn ? `Welcome, ${props.name}` : null}
              </div>
              {platform !== 'Production' && (
                <Typography
                  variant="h5"
                  color="white"
                  style={{ marginLeft: 150 }}
                >
                  {platform}
                </Typography>
              )}
              <div className={classes.grow} />
              {props.isLoggedIn ? (
                <>
                  <div className={classes.sectionDesktop}>
                    {(props.role.isSuperAdmin ||
                      props.permissions.CanAccessAdminPanel) &&
                      !props.role.isStudent && (
                        <IconButton
                          className="text-gray-700 hover:text-gray-100"
                          onClick={() => navigate('/adminpanel')}
                          size="large"
                        >
                          <AdminPanelSettings className="h-7 w-8 text-gray-100 hover:text-gray-200" />
                        </IconButton>
                      )}

                    {props.role && !props.role.isStudent && (
                      <IconButton
                        className="text-gray-700 hover:text-gray-900"
                        onClick={() => navigate('/messages')}
                        size="large"
                      >
                        <Badge
                          badgeContent={userContext.unReadMessagesCount}
                          color="secondary"
                        >
                          <MailIcon className="h-7 w-8 text-gray-100 hover:text-gray-200" />
                        </Badge>
                      </IconButton>
                    )}

                    <NotificationDropdown
                      noDownIcon={true}
                      bgColor="bg-white/0"
                      options={userContext}
                      isInternal={props.isInternal}
                      handleClick={handleClickNotificationItem}
                    />

                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      className="text-gray-700 hover:text-gray-900"
                      size="large"
                    >
                      <AvatarMui
                        imageUrl={props.imageUrl}
                        fullName={props.name}
                        size={5}
                        className="border-2 border-gray-300 hover:border-gray-500"
                      />
                    </IconButton>
                  </div>

                  <div className={classes.sectionMobile}>
                    <IconButton
                      aria-controls={mobileMenuId}
                      aria-haspopup="true"
                      onClick={handleMobileMenuOpen}
                      color="secondary"
                      size="large"
                    >
                      <MoreIcon />
                    </IconButton>
                  </div>
                </>
              ) : (
                <div>
                  {location.pathname != '/' && (
                    <Button onClick={handleLogIn} color="inherit">
                      <span className="material-icons">login</span>
                      <span style={{ marginLeft: '3px' }}>Login</span>
                    </Button>
                  )}
                </div>
              )}
            </Toolbar>
          )}
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        {props.isLoggedIn && (
          <nav
            className={classes.drawer}
            style={{ backgroundImage: 'url(' + sideBgImage + ')' }}
          >
            <Drawer
              onMouseLeave={handleDrawerClose}
              onMouseEnter={handleDrawerOpen}
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                }),
              }}
            >
              {drawer}
            </Drawer>
          </nav>
        )}
      </Root>
      {addTask && (
        <Task
          record={{ id: 0, studentId: 0 }}
          setAddMode={setAddTask}
          cosmosAdminUsersParent={[]}
          path={2}
        />
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    name: state.auth.name,
    status: state.auth.status,
    role: state.auth.role,
    imageUrl: state.auth.image,
    appUserId: state.auth.id,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    setDrawerOpen: ownProps.setDrawerOpen,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainNavigation);
