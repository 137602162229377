/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, Grid, Select, Typography } from '@mui/material';
import React, {  useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { format, addMinutes } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import { TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Divider } from '@mui/material';
import Agent from '../../../api/agent';
import EditorDraftJs from '../../Common/Editor/EditorDraftJs';
//import Conversations from './Conversations';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const SendMailToStudent = ({ setOpenEmailMode, ReferralId }) => {
  const [addNodeMode, setAddNodeMode] = useState(false);
  const [rawHtml, setRawHtml] = useState();
  const [updating, setUpdating] = useState(false);
  const [editorValidState, setEditorValidState] = useState(false);
  const [subject, setSubject] = useState("Invitation for student registration ");
  const [preferredDestinations, setPreferredDestinations] = useState([]);
  const [emails, setEmails] = useState([]);
  const [focused, setFocused] = useState(false);

  const editorOnChange = (rawHtml, valid) => {
    setRawHtml(rawHtml);
    setEditorValidState(valid);
  };
  console.log('emails',emails);
  const handleClose = () => {
      setOpenEmailMode(false);
  };
  console.log("ReferralId",ReferralId);
  const addNote = () => {
    var msg = {
      content: rawHtml,
      Emails: emails,
      ReferralId:ReferralId,
      subject : subject,
    };
    setUpdating(true);
    Agent.Messages.sendEmailToStudent(msg)
      .then(() => {
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
        debugger;
      });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <Typography variant="h5" align="center" color="primary">
        Send Mail To Your Student
        </Typography>
      </DialogTitle>
      <Divider />

      <DialogActions>
        <Grid container direction="column" spacing={2} style={{ marginTop: 5 }}>
          <Grid container direction="row" justifyContent="space-between" className='px-6'>
            <Button
              endIcon={!addNodeMode ? <AddIcon /> : null}
              onClick={() => {
                setAddNodeMode((prevState) => !prevState);
              }}
            >
              {addNodeMode ? 'Hide  Option' : 'Send Mail'}
            </Button>
            <Grid item >
              <LoadingButton className='text-red-600 font-medium' onClick={handleClose}>Cancel</LoadingButton>
            </Grid>
          </Grid>
          {addNodeMode && (
            <>
              <Grid item>
              <div className="">

             <ReactMultiEmail
                placeholder='You Can Select Multiple Emails Here'
                value={preferredDestinations}
                onChange={(_emails) => {
                    setEmails(_emails);
                  }}
                //onChange={handleDestinationChange}
                emails={emails}
                //autoFocus={true} 
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                getLabel={(email, index , removeEmail ) => {
                return (
                    <div data-tag key={index} role="button">
                    <div data-tag-item>{email}</div>
                    <span data-tag-handle onClick={() => removeEmail(index)}>
                        ×
                    </span>
                    </div>
                );
                }}
             />

                </div>
              <div className="py-4">
                  <TextField
                    fullWidth
                    size="small"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    label="Subject"
                    required
                  />
                </div>
                <EditorDraftJs editorOnChange={editorOnChange} height={100} />
              </Grid>
              <Grid item>
                <LoadingButton
                  loading={updating}
                  disabled={!editorValidState}
                  onClick={addNote}
                  variant="outlined"
                >
                  Send Mail
                </LoadingButton>
              </Grid>
            </>
          )}

        </Grid>
      </DialogActions>
      <br />
      <br />
      <hr />
    </Dialog>
  );
};

export default SendMailToStudent;