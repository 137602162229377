export const MANAGEMENT_PARTNERS_LOAD = 'MANAGEMENT_PARTNERS_LOAD';
export const MANAGEMENT_COURSE_LEVELS_LOAD = 'MANAGEMENT_COURSE_LEVELS_LOAD';
export const MANAGEMENT_LANGUAGE_TESTS_LOAD = 'MANAGEMENT_LANGUAGE_TESTS_LOAD';

export const SUMMARY_INFO = 'SUMMARY_INFO';

export const SUMMARY_AGENT_COUNT = 'SUMMARY_AGENT_COUNT';
export const SUMMARY_STUDENT_COUNT = 'SUMMARY_STUDENT_COUNT';
export const SUMMARY_APPLICATION_COUNT = 'SUMMARY_APPLICATION_COUNT';
export const SUMMARY_ADMIN_COUNT = 'SUMMARY_ADMIN_COUNT';

export const FETCH_STUDENT = 'FETCH_STUDENT';
export const ADD_STUDENT = 'ADD_STUDENT';

export const AUTH_START = 'AUTH_START';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const DEPARTMENTS_ADD = 'DEPARTMENTS_ADD';
export const DEPARTMENTS_UPDATE_DEPARTMENT = 'DEPARTMENTS_UPDATE_DEPARTMENT';
export const DEPARTMENTS_LOADING_SUCCESS = 'DEPARTMENTS_LOADING_SUCCESS';

export const COURSES_LOADING_SUCCESS = 'COURSES_LOADING_SUCCESS';
export const COURSES_ADD = 'COURSES_ADD';
export const COURSES_UPDATE_COURSE = 'COURSES_UPDATE_COURSE';
export const COURSES_DELETE = 'COURSES_DELETE';

export const COURSE_ALLOCATIONS_LOADING_SUCCESS =
  'COURSE_ALLOCATIONS_LOADING_SUCCESS';
export const COURSE_ALLOCATIONS__EMPTY = 'COURSE_ALLOCATIONS__EMPTY';
export const COURSE_ALLOCATIONS__ADD = 'COURSE_ALLOCATIONS__ADD';
export const COURSE_ALLOCATIONS__UPDATE_COURSE =
  'COURSE_ALLOCATIONS__UPDATE_COURSE';

export const USERS_LOADING_START = 'USERS_LOADING_START';
export const USERS_LOADING_SUCCESS = 'USERS_LOADING_SUCCESS';
export const USERS_LOADING_FAIL = 'USERS_LOADING_FAIL';
export const USERS_USER_PERSONAL_UPDATE = 'USERS_USER_PERSONAL_UPDATE';
export const USERS_USER_COUNTRY_UPDATE = 'USERS_USER_COUNTRY_UPDATE';

export const USERS_USER_AVATAR_UPDATE = 'USERS_USER_AVATAR_UPDATE';
export const USERS_USER_NAME_UPDATE = 'USERS_USER_NAME_UPDATE';

export const USERS_USER_PARENT_ADD_SUCCESS = 'USERS_USER_PARENT_ADD_SUCCESS';
export const USERS_USER_PARENT_ADD_FAIL = 'USERS_USER_PARENT_ADD_FAIL';
export const USERS_USER_PARENT_DELETE_SUCCESS =
  'USERS_USER_PARENT_DELETE_SUCCESS';
export const USERS_USER_PARENT_DELETE_FAIL = 'USERS_USER_PARENT_DELETE_FAIL';
export const USER_AGENTS_ADMIN_UPDATE = 'USER_AGENTS_ADMIN_UPDATE';

export const USERS_USER_CHILD_UPDATE_SUCCESS =
  'USERS_USER_CHILD_UPDATE_SUCCESS';
export const USERS_USER_CHILD_ADD_FAIL = 'USERS_USER_CHILD_ADD_FAIL';
export const USERS_USER_CHILD_DELETE_SUCCESS =
  'USERS_USER_CHILD_DELETE_SUCCESS';
export const USERS_USER_CHILD_DELETE_FAIL = 'USERS_USER_CHILD_DELETE_FAIL';

export const AGENTS_LOADING_START = 'AGENTS_LOADING_START';
export const AGENTS_LOADING_SUCCESS = 'AGENTS_LOADING_SUCCESS';
export const AGENTS_LOADING_FAIL = 'AGENTS_LOADING_FAIL';
export const AGENTS_STATUS_UPDATE = 'AGENTS_STATUS_UPDATE';
export const AGENTS_ADMIN_UPDATE = 'AGENTS_ADMIN_UPDATE';

export const PROFILE_UPLOADING_SUCCESS = 'PROFILE_UPLOADING_SUCCESS';
export const PROFILE_DELETING_SUCCESS = 'PROFILE_DELETING_SUCCESS';

export const SERVER_ERRORS = 'SERVER_ERRORS';
