import React from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Button, Checkbox } from '@mui/material';

const AgentBulkSummaryDialog = ({
  open,
  handleClose,
  totalSelected,
  templates,
  fromMailList,
  dialogueCommunicationName,
  selectTemplateId,
  mailSubject,
  fromMail,
  handleOpenConfirmDialog,
  handleMailSubjectChange,
  handleFromMailChange,
  handleCommunicationNameChange,
  handleSelectTemplateIdChange,
  isApplicationEmail,
  setIsApplicationEmail,
}) => {
  return (
    <Transition show={open} as={React.Fragment}>
      <Dialog
        onClose={handleClose}
        className="fixed inset-0 z-10 overflow-y-auto"
        open={open}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="mb-2">
                <h2 className="text-xl font-bold text-center text-blue-500">
                  Summary
                </h2>
                <button
                  onClick={handleClose}
                  className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  x
                </button>
              </div>

              <div className="space-y-4">
                <p className="text-lg font-semibold text-center">
                  Total Selected: {totalSelected}
                </p>

                <div className="space-y-2">
                  <label htmlFor="communicationName" className="text-gray-700">
                    Communication Name
                  </label>
                  <input
                    id="communicationName"
                    type="text"
                    placeholder="Enter communication name"
                    className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                    value={dialogueCommunicationName}
                    onChange={handleCommunicationNameChange}
                    maxLength={300}
                  />
                </div>

                <div className="space-y-2">
                  <label htmlFor="templateName" className="text-gray-700">
                    Template Name
                  </label>
                  <select
                    id="templateName"
                    className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                    value={selectTemplateId}
                    onChange={handleSelectTemplateIdChange}
                  >
                    <option value={null}>Select</option>
                    {templates?.map((template) => (
                      <option key={template.id} value={template.templateId}>
                        {template.templateName}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="space-y-2">
                  <label htmlFor="mailSubject" className="text-gray-700">
                    Mail Subject
                  </label>
                  <input
                    id="mailSubject"
                    type="text"
                    placeholder="Enter your mail subject"
                    className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                    value={mailSubject}
                    onChange={handleMailSubjectChange}
                    maxLength={100}
                  />
                </div>

                <div className="space-y-2">
                  <label htmlFor="fromEmail" className="text-gray-700">
                    From Email
                  </label>
                  <select
                    id="fromEmail"
                    className="w-full p-2 border rounded-lg focus:outline-none focus:border-blue-500"
                    value={fromMail}
                    onChange={handleFromMailChange}
                  >
                    <option value={0}>Select Email</option>
                    {fromMailList.map((email) => (
                      <option key={email.id} value={email.name}>
                        {email.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="space-y-2">
                &nbsp;Application update Email&nbsp;
                      <Checkbox
                          checked={isApplicationEmail}
                          onChange={() => {
                            setIsApplicationEmail(!isApplicationEmail);
                          }}           
                      />
                  </div>
                <Button
                  onClick={handleOpenConfirmDialog}
                  className="w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none"
                  disabled={
                    selectTemplateId === '' ||
                    fromMail === '' ||
                    dialogueCommunicationName === '' ||
                    mailSubject === ''
                  }
                >
                  Send Mail
                </Button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AgentBulkSummaryDialog;
