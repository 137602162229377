import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const PREFIX = 'MyInputField';

const classes = {
  inputContainer: `${PREFIX}-inputContainer`,
  label: `${PREFIX}-label`,
  error: `${PREFIX}-error`,
};

const Root = styled('div')(() => ({
  [`&.${classes.inputContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 15px',
  },

  [`& .${classes.label}`]: {
    margin: '0 0 6px 0',
    fontSize: '1.1rem',
  },

  [`& .${classes.error}`]: {
    color: 'red',
  },
}));

function MyInputField({ obj, label, name, isInvalid, ...rest }) {
  return (
    <Root className={classes.inputContainer}>
      <TextField
        // type={type}
        label={label}
        name={name}
        onChange={(e) => {
          obj.valueChangeHandler(e.target.value, name);
        }}
        onBlur={(e) => {
          obj.valueInputBlurHandler(name);
        }}
        value={obj.value}
        variant="outlined"
        size="small"
        error={isInvalid}
        {...rest}
      />
      {isInvalid && (
        <span className={classes.error}>{`${label} is invalid`}</span>
      )}
    </Root>
  );
}

export default React.memo(MyInputField);
