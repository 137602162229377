import React from 'react';
import {
  Grid,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  Switch,
  InputLabel,
} from '@mui/material';
import { Countries } from '../../../../Util/Util';
import {
  STUDENTSOURCES,
  StudentLead_Activities,
  StudentLead_Status,
} from '../../../../Util/Constants';
import { AiFillMail } from 'react-icons/ai';

const LeadComFilters = ({
  Status,
  LeadActivity,
  eventId,
  citizenship,
  BranchOfficeId,
  LeadListName,
  Name,
  offices,
  events,
  handleCitizenshipFilterChange,
  handleLeadStatusFilterChange,
  handleLeadActivityFilterChange,
  handleEventFilter,
  handleBranchOfficeFilterChange,
  handleLeadListNameChange,
  handleNameChange,
  handleClearFilters,
  handleApplyFilters,
  handleSendBulkEmail,
  totalSelected,
  setLeadType,
  setEventAttendee,
  eventAttendee,
  leadType,
  setLeadSource,
  leadSource
}) => {
  return (
    <Grid container spacing={2} className="mb-3">
    <Grid container spacing={1} className=" ml-1">
      <Grid item xs={12} sm={6} md={2}>
        <FormControl fullWidth>
          <Autocomplete
           value={
            Countries.find((citizenship) => citizenship.id == citizenship) ||
            null
          }
            onChange={handleCitizenshipFilterChange}
            options={Countries}
            //className="w-64"
            getOptionLabel={(country) => country.name}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                label="Citizenship"
                variant="outlined"
              />
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <FormControl fullWidth>
          <InputLabel>Lead Status</InputLabel>
          <Select
            size="small"
            label="Lead Status"
           // className="w-64"
            value={Status}
            onChange={handleLeadStatusFilterChange}
            variant="outlined"
          >
            <MenuItem value={null}>All Status</MenuItem>
            {StudentLead_Status.map((leadStatus) => (
              <MenuItem key={leadStatus.id} value={leadStatus.id}>
                {leadStatus.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <FormControl fullWidth>
          <InputLabel>Lead Activity</InputLabel>
          <Select
            size="small"
            label="Lead Activity"
            //className="w-64"
            value={LeadActivity}
            onChange={handleLeadActivityFilterChange}
            variant="outlined"
          >
~            {StudentLead_Activities.map((leadActivity) => (
              <MenuItem key={leadActivity.id} value={leadActivity.id}>
                {leadActivity.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel>Lead Source</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Lead Source"
                value={leadSource}
                onChange={(e) => {
                  setLeadSource(e.target.value);
                }}
              >
                <option value={0}>Lead Source</option>
                {STUDENTSOURCES.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.type}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
      <Grid item xs={6} sm={6} md={3}>
        <FormControl fullWidth>
          <InputLabel>Event</InputLabel>
          <Select
            size="small"
            label="Event Name"
            //className="w-64"
            value={eventId}
            onChange={handleEventFilter}
            variant="outlined"
          >
            <option value={0}>All Event</option>
            {events.map((event) => (
              <MenuItem key={event.id} value={event.id}>
                {event.eventName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={1} className="mt-2 ml-1">
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <InputLabel>Branch Office</InputLabel>
            <Select
              size="small"
              label="Branch Office"
              //className="w-64"
              value={BranchOfficeId}
              onChange={handleBranchOfficeFilterChange}
              variant="outlined"
            >
              {offices.map((branch) => (
                <MenuItem key={branch.id} value={branch.id}>
                  {branch.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <InputLabel>Lead Type</InputLabel>
            <Grid item xs={12}>
                  <Select
                    fullWidth
                    size="small"
                    native
                    label="Lead Type"
                    //className="w-64"
                    value={leadType}
                    onChange={(e) => {
                      setLeadType(e.target.value);
                    }}
                  >
                    <option value={0}>All Leads Type</option>
                    <option value={1}>Referral Leads</option>
                  </Select>
                </Grid>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
               <FormControl fullWidth>
              <InputLabel>Event Attendee</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Event Attendee"
                value={eventAttendee}
                onChange={(e) => {
                  setEventAttendee(e.target.value);
                 }}
              >
                <option value={0}>Both Attendee</option>
                <option value={1}>Attendee</option>
                <option value={2}>Not Attendee</option>
                
              </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              label="Lead List Name"
              size="small"
              value={LeadListName}
              onChange={handleLeadListNameChange}
              variant="outlined"
              fullWidth
             // className="w-64"
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <FormControl fullWidth>
            <TextField
              label="Name"
              size="small"
              value={Name}
              onChange={handleNameChange}
              variant="outlined"
              fullWidth
             // className="w-64"
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container justifyContent="end" gap={3} className='pt-4'>
        <div className="flex gap-4">
         

          <Button
            variant="contained"
            className="bg-blue-500 text-white ml-2 px-12"
            onClick={handleApplyFilters}
          >
            Apply Filters
          </Button>
        </div>
        <Button
            variant="outlined"
            className="ml-2 px-12"
            onClick={handleClearFilters}
          >
            Clear Filters
          </Button>
        <div>
          <Button
            variant="contained"
            className="bg-sky-800 text-white"
            onClick={handleSendBulkEmail}
            disabled={totalSelected === 0}
          >
            <AiFillMail className="mr-2 text-lg" />
            Send Bulk
          </Button>
        </div>
        {/* <div className='w-48'></div> */}
      </Grid>
    </Grid>
  );
};

export default LeadComFilters;
