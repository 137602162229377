import React from 'react';
import { Button, Grid, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import AvatarMui from '../../../Common/Image/AvatarMui';
import { AnimateZoomIn } from '../../../Common/Animation/Animation';

const ManagerProfile = ({ businessDev, setOpenAddMessage }) => {
  return (
    <div className='flex flex-nowrap justify-between w-[450px] bg-slate-900 rounded-lg lg:px-6 py-5 mr-auto glass'>
      <div className=''>
        {businessDev.appUserId > 0 && (
          <AvatarMui
            imageUrl={businessDev.image}
            fullName={businessDev.name}
            size={12}
            agent='yes'
          />
        )}
      </div>
      <div className='text-2xl font-medium text-info'>
        <div>Your Account Manager</div>
        <AnimateZoomIn>
          <div className='text-white'>{businessDev.name}</div>
        </AnimateZoomIn>
        {businessDev.appUserId > 0 && (
          <>
            <AnimateZoomIn>
              <div className='flex items-center gap-1'>
                <div>
                  <EmailIcon />
                </div>
                <div>
                  <AnimateZoomIn>
                    <Typography className='text-white'>{businessDev.email}</Typography>
                  </AnimateZoomIn>
                </div>
              </div>
            </AnimateZoomIn>
            <AnimateZoomIn>
              <div className='flex items-center gap-1'>
                <div>
                  <PhoneIcon />
                </div>
                <Grid>
                  <Typography  className='text-white'>{businessDev.phone}</Typography>
                </Grid>
              </div>
            </AnimateZoomIn>
          </>
        )}
        {businessDev.appUserId > 0 && (
          <div className='inline-flex justify-end w-full lg:pt-10'>
            <Button
              variant='contained'
              className='bg-blue-600 hover:bg-blue-500 text-white'
              size='large'
              startIcon={<EmailIcon />}
              onClick={() => setOpenAddMessage(true)}
            >
              Send Message
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManagerProfile;