/* eslint-disable react/display-name */
import React, {useState, useEffect} from 'react'
import {styled} from '@mui/material/styles'
import {MUI_LICENSE_KEY} from '../../../../Util/Constants'
import {LicenseInfo, DataGridPro} from '@mui/x-data-grid-pro'
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY)
import * as actions from '../../../../store/actions/index'
import {connect} from 'react-redux'
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import SchoolIcon from '@mui/icons-material/School'
// import { Link, useNavigate } from 'react-router-dom';
import LoadingView from '../../../Common/Loading/LoadingView'
import Agent from '../../../../api/agent'
import MyTooltip from '../../../Common/Tooltip/MyTooltip'
import {STUDY_LEVELS, INTAKES} from '../../../../Util/Constants'
import UniversityCourseAllocation from './Course/UniversityCourseAllocation'
import AddIcon from '@mui/icons-material/Add'
import {NumberFormatBase} from 'react-number-format'
import {newCourse} from '../../Constants/Constants'
import {v4 as uuid} from 'uuid'
import {Countries} from '../../../../Util/Util'

// const PREFIX = 'UniversitieCourse';
const classes = {
  // courseArea: `${PREFIX}-courseArea`,
  // studentArea: `${PREFIX}-studentArea`,
}

const StyledDiv = styled('div')(() => ({
  // [`& .${classes.courseArea}`]: {
  //   borderRight: '1px solid gray',
  //   marginTop: 5,
  // },
  // [`& .${classes.studentArea}`]: {
  //   marginTop: 5,
  //   paddingLeft: 24,
  // },
}))

const UniversityCourses = ({
  allocations,
  loadCourseAllocations,
  updateSingleCourseAllocation,
  universityId,
  role,
  permissions,
  countryId,
}) => {
  const [loading, setLoading] = useState(true)
  const [courses, setCourses] = useState([])
  const [name, setName] = useState('')
  const [addMode, setAddMode] = useState(false)
  const [course, setCourse] = useState()
  const [refresh, setRefresh] = useState()
  const [firstLoad, setFirstLoad] = useState()

  const applyFilter = (apply) => {
    if (!apply) {
      setName('')
    }
    getRows(allocations, apply)
  }

  const updateStore = (action) => {
    if (action.type == 'save') {
      updateSingleCourseAllocation(action.course)
    }
    if (action.type == 'add') {
      setRefresh(true)
    }
    setAddMode(false)
  }
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  useEffect(() => {
    if ((allocations.length == 0 && !firstLoad) || refresh) {
      setLoading(true)
      loadCourseAllocations(universityId).then((list) => {
        getRows(list, false)
      })
      setLoading(false)
      setFirstLoad(true)
      setRefresh(false)
    } else {
      getRows(allocations, false)
    }
  }, [allocations, refresh])

  const getRows = (rows, applyFilter) => {
    let courseRows = []
    if (applyFilter) {
      rows = rows.filter((row) =>
        row.name
          .replace(/\s/g, '')
          .toUpperCase()
          .includes(name.replace(/\s/g, '').toUpperCase())
      )
    }
    rows
      .filter((item) => item.name)
      .map((course) => {
        courseRows.push({
          id: uuid(),
          courseId: course.id,
          name: course.name,
          studyLevelId: course.studyLevelId,
          level: STUDY_LEVELS.find(({id}) => id == course.studyLevelId).type,
          courseIntakes: course.courseIntakes,
          intakeMonths: course.courseIntakes.map(
            (intake) =>
              INTAKES.find(({id}) => id == intake.intake).typeShort +
              '-' +
              intake.year.toString().substring(2) +
              ' '
          ),
          tuitionFeeFirst: course.courseIntakes[0].tuitionFee,
          infoLink: course.infoLink,
        })
      })
    setCourses(courseRows)
    setLoading(false)
  }
  let columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 85,
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Course Name',
      width: 630,
      renderCell: (params) => (
        <MyTooltip title={params.row.name}>
          <Button
            onClick={() =>
              manageCourse({
                id: params.row.courseId,
                name: params.row.name,
                intakes: params.row.courseIntakes,
                studyLevelId: params.row.studyLevelId,
                infoLink: params.row.infoLink,
              })
            }
          >
            {params.row.name}
          </Button>
        </MyTooltip>
      ),
    },
    {
      field: 'level',
      headerName: 'Level',
      width: 150,
    },
    {
      field: 'intakeMonths',
      headerName: 'INTAKE',
      width: 130,
      renderCell: (params) => (
        <MyTooltip title={params.row.intakeMonths}>
          <Typography>{params.row.intakeMonths}</Typography>
        </MyTooltip>
      ),
    },
    {
      field: 'tuitionFeeFirst',
      headerName: 'Tuition',
      width: 85,
      renderCell: (params) => (
        <NumberFormatBase
          isNumericString={true}
          value={params.row.tuitionFeeFirst}
          thousandSeparator={true}
          prefix={`${
            Countries.find(({id}) => id == countryId).currency || '£'
          } `}
          customInput={TextField}
          size='small'
          displayType='text'
        />
      ),
    },
    {
      field: 'infoLink',
      headerName: 'Information Link',
      width: 270,
      renderCell: (params) => (
       <Link className='clickable' onClick={() => openInNewTab(params.row.infoLink)}>
          {params.row.infoLink}
        </Link>
      ),
    },
  ]

  const manageCourse = (record) => {
    setAddMode(false)
    setCourse(record)
    setAddMode(true)
  }

  return loading ? (
    <LoadingView />
  ) : (
    <StyledDiv>
      {addMode && (
        <UniversityCourseAllocation
          course={course}
          universityId={universityId}
          countryId={countryId}
          setAddMode={setAddMode}
          updateParent={updateStore}
        />
      )}
      <Grid
        container
        direction='column'
        spacing={1}
        style={{backgroundColor: 'white'}}
      >
        {(role.isSuperAdmin || permissions.CanManageUniversity) && (
          <Grid item container justifyContent='flex-end'>
            <Button
              LinkComponent
              variant='contained'
              className='bg-sky-400 hover:bg-sky-500'
              startIcon={<AddIcon />}
              onClick={() => manageCourse(newCourse)}
            >
              Add Course Link
            </Button>
          </Grid>
        )}
        <Grid item container spacing={1}>
         
          <Grid item xs={3}>
            <TextField
              fullWidth
              size='small'
              label='Course Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              onClick={() => applyFilter(true)}
              variant='contained'
              className='bg-sky-400 hover:bg-sky-500'
            >
              Apply Filters
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              fullWidth
              onClick={() => applyFilter(false)}
              className='bg-rose-200 hover:bg-rose-300 text-gray-600 hover:text-gray-700'
            >
              Reset Filters
            </Button>
          </Grid>
        </Grid>

        <Grid item>
          <DataGridPro
            disableSelectionOnClick
            rows={courses}
            pagination
            columns={columns}
            loading={loading}
            // disableColumnFilter
            autoHeight
            density='compact'
          />
        </Grid>
      </Grid>
    </StyledDiv>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    allocations: state.courseallocations,
    isInternal: state.auth.isInternal,
    role: state.auth.role,
    permissions: state.auth.permissions,
    universityId: ownProps.universityId,
    countryId: ownProps.countryId,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadCourseAllocations: (universityId) =>
      dispatch(actions.loadCourseAllocations(universityId)),
    updateSingleCourseAllocation: (course) =>
      dispatch(actions.updateSingleCourseAllocation(course)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UniversityCourses)
