import { Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditorDraftJs from '../../../Common/Editor/EditorDraftJs';
import { LoadingButton } from '@mui/lab';
import Conversations from '../../../Messages/Conversations';
import Agent from '../../../../api/agent';
import { format, addMinutes } from 'date-fns';

const Communications = ({ records, setRecords, universityId, name }) => {
  const [addNodeMode, setAddNodeMode] = useState(false);
  const [rawHtml, setRawHtml] = useState();
  const [updating, setUpdating] = useState(false);
  const [editorValidState, setEditorValidState] = useState(false);
  const editorOnChange = (rawHtml, valid) => {
    setRawHtml(rawHtml);
    setEditorValidState(valid);
  };

  const addNote = () => {
    var msg = {
      content: rawHtml,
      universityId,
      sendEmail: false,
    };
    setUpdating(true);
    Agent.Messages.addUniversityNote(msg)
      .then(() => {
        const newNote = [
          {
            content: rawHtml,
            from: name,
            dateSent: format(
              addMinutes(new Date(), new Date().getTimezoneOffset()),
              'MMMM d, yyyy h:mm a'
            ),
          },
        ];
        setRecords(newNote.concat(records));
        setUpdating(false);
        setAddNodeMode(false);
        setEditorValidState(false);
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
        debugger;
      });
  };
  return (
    <Grid container direction="column" spacing={2} style={{ marginTop: 5 }}>
      <Grid item container justifyContent="center">
        {' '}
        <Typography color="primary" variant="h5">
          Internal Communication Notes
        </Typography>
      </Grid>
      <Grid item>
        <Button
          endIcon={!addNodeMode ? <AddIcon /> : null}
          onClick={() => {
            setAddNodeMode((prevState) => !prevState);
          }}
        >
          {addNodeMode ? 'Hide Note Option' : 'Add Internal Note'}
        </Button>
      </Grid>
      {addNodeMode && (
        <>
          <Grid item>
            <EditorDraftJs editorOnChange={editorOnChange} height={100} />
          </Grid>
          <Grid item>
            <LoadingButton
              loading={updating}
              disabled={!editorValidState}
              onClick={addNote}
              variant="outlined"
            >
              Add New Note
            </LoadingButton>
          </Grid>
        </>
      )}
      <Grid item container>
        <Conversations messages={records} user={name} hideReplyOption={true} />
      </Grid>
    </Grid>
  );
};

export default Communications;
