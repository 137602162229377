/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Countries } from './../../../Util/Util';
import * as yup from 'yup';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
// import LeadPhonInput from '../LeadUtils/LeadPhonInput';
import { Select } from '@mui/material';
import { CONTACT_SERVICE_TYPE, CONTACT_TYPE } from './../../../Util/Constants';

const GeneralInfo = ({
  handlePhoneNumberChange,
  handleUpdateClick,
  handleEditClick,
  isEditMode,
  userData,
  handleInputChange,
}) => {
  // const [PhoneNumber, setPho] = useState(null);
  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');

  console.log(userData);
  // const handlePhoneChange = (value, country) => {
  //   if (value) {
  //     if (/^\+[1-9]\d{1,14}$/.test(value)) {
  //       setPhoneNumber(value);
  //       setPhoneError('');
  //     } else {
  //       setPhoneNumber(null);
  //       setPhoneError(
  //         'Invalid phone number format. Include country code (+) and digits.'
  //       );
  //     }
  //   } else {
  //     // Clear any previous error and reset the phone number
  //     setPhoneNumber(null);
  //     setPhoneError('');
  //   }
  // };

  const handlePhoneChange = (value) => {
    if (!value || !/^\+\d{1,3}\d{6,14}$/.test(value)) {
      setPhoneError(
        'Invalid phone number format. Include country code (+) and digits.'
      );
    } else {
      setPhoneError('');
    }
  };

  // const handleEmailChange = (value) => {
  //   if (!value || /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
  //     setEmailError('Invalid email format.');
  //   } else {
  //     setEmailError('');
  //   }
  // };

  const handleEmailChange = (value) => {
    try {
      validationSchema.validateSync({ email: value });
      setEmailError('');
    } catch (error) {
      setEmailError(error.errors[1]);
    }
  };

  const validationSchema = yup.object().shape({
    contactNumber: yup
      .string()
      .matches(/^\+\d{1,3}\d{6,14}$/, 'Invalid phone number format.'),
    email: yup
      .string()
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        'Invalid, please provide standard mail format.'
      ),
    // .required('Person Email is required'),
  });

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(
        { email: userData?.email },
        { abortEarly: false }
      );
      handleUpdateClick();
    } catch (error) {
      error.inner.forEach((err) => {
        if (err.path === 'contactNumber') {
          setPhoneError(err.message);
        }
        if (err.path === 'email') {
          setEmailError(err.message);
        }
      });

      // if (!emailError) {
      //   setEmailError('Email is required.'); // Set default email error message
      // }
    }
  };

  return (
    <section id="basic-info" className="p-2 border rounded">
      <h2 className="text-2xl font-semibold mb-1 text-center">
        General Information
      </h2>

      <div className="bg-white rounded-lg shadow-md p-6">
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="form-control">
              <label htmlFor="contactName" className="label">
                <span className="label-text">Business Name / Contact Name</span>
              </label>
              <input
                type="text"
                id="contactName"
                name="contactName"
                defaultValue={userData?.contactName || ''}
                className="input input-bordered"
                onChange={handleInputChange}
                disabled={!isEditMode}
              />
            </div>

            <div className="form-control">
              <label htmlFor="email" className="label">
                <span className="label-text">Contact Email (For Communication)</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={userData?.email || ''}
                className="input input-bordered"
                onChange={(e) => {
                  handleInputChange(e);
                  handleEmailChange(e.target.value);
                }}
                disabled={!isEditMode}
              />
              {emailError && (
                <span className="text-xs text-red-500">{emailError}</span>
              )}
            </div>

            <div className="form-control">
              <label htmlFor="emailAddress" className="label">
                <span className="label-text">Email Address (optional)</span>
              </label>
              <input
                type="email"
                id="emailAddress"
                name="emailAddress"
                defaultValue={userData?.emailAddress || ''}
                className="input input-bordered"
                onChange={handleInputChange}
                disabled={!isEditMode}
              />
            </div>

            <div className="form-control">
              <label htmlFor="contactNumber" className="label">
                <span className="label-text">Contact Number</span>
              </label>
              <PhoneInput
                className="w-full px-3 py-3  text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="contactNumber"
                placeholder="Enter contact no."
                value={userData?.contactNumber || ''}
                onChange={(value) => {
                  handlePhoneNumberChange(value);
                  handlePhoneChange(value);
                }}
                disabled={!isEditMode}
              />
              {phoneError && (
                <span className="text-xs text-red-500">{phoneError}</span>
              )}
            </div>

            <div>
              <label
                htmlFor="typeOfService"
                className="block text-sm font-medium text-gray-700"
              >
                Service Type
              </label>
              <Select
                fullWidth
                size="small"
                native
                value={userData?.serviceType}
                onChange={(e) =>
                  handleInputChange({
                    target: { name: 'serviceType', value: e.target.value },
                  })
                }
                className="mt-1 p-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500 w-full" // Add w-full class for full width
              >
                <option value={0}>Select</option>
                {CONTACT_SERVICE_TYPE.map((service) => (
                  <option key={service.id} value={service.id}>
                    {service.type}
                  </option>
                ))}
              </Select>
            </div>

            <div>
              <label
                htmlFor="typeOfContact"
                className="block text-sm font-medium text-gray-700"
              >
                Type Of Contact
              </label>
              <Select
                fullWidth
                size="small"
                native
                value={userData?.typeOfContact}
                onChange={(e) =>
                  handleInputChange({
                    target: { name: 'typeOfContact', value: e.target.value },
                  })
                }
                className="mt-1 p-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500 w-full" // Add w-full class for full width
              >
                <option value={0}>Select</option>
                {CONTACT_TYPE.map((typeOfContact) => (
                  <option key={typeOfContact.id} value={typeOfContact.id}>
                    {typeOfContact.type}
                  </option>
                ))}
              </Select>
            </div>

            <div className="form-control">
              <label htmlFor="description" className="label">
                <span className="label-text">Description</span>
              </label>
              <input
                type="text"
                id="description"
                name="description"
                defaultValue={userData?.description||''}
                className="input input-bordered"
                onChange={handleInputChange}
                disabled={!isEditMode}
              />
            </div>
            <div className="form-control">
              <label htmlFor="followUpMeetingNote" className="label">
                <span className="label-text">Follow-up Meeting Note</span>
              </label>
              <input
                type="text"
                id="followUpMeetingNote"
                name="followUpMeetingNote"
                defaultValue={userData?.followUpMeetingNote||''}
                className="input input-bordered"
                onChange={handleInputChange}
                disabled={!isEditMode}
              />
            </div>

            <div>
              <label
                htmlFor="CountryId"
                className="block text-sm font-medium text-gray-700"
              >
                Country
              </label>
              <Select
                fullWidth
                size="small"
                native
                value={userData?.countryId}
                onChange={(e) =>
                  handleInputChange({
                    target: { name: 'countryId', value: e.target.value },
                  })
                }
                className="mt-3 p-1 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-500 w-full" // Add w-full class for full width
              >
                <option value={0}>Select a Country</option>
                {Countries.filter((item)=>item.id!=0).map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </Select>
            </div>

          

            <div className="form-control">
              <label htmlFor="address" className="label">
                <span className="label-text">Address</span>
              </label>
              <input
                type="text"
                id="address"
                name="address"
                defaultValue={userData?.address ||''}
                className="input input-bordered"
                onChange={handleInputChange}
                disabled={!isEditMode}
              />
            </div>
            <div className="form-control">
              <label htmlFor="website" className="label">
                <span className="label-text">Website</span>
              </label>
              <input
                type="text"
                id="website"
                name="website"
                defaultValue={userData?.website || ''}
                className="input input-bordered"
                onChange={handleInputChange}
                disabled={!isEditMode}
              />
            </div>

            <div className="form-control">
              <label htmlFor="countryId" className="label">
                <span className="label-text">Event Name / Source</span>
              </label>
              <input
                type="text"
                id="eventName"
                name="eventName"
                defaultValue={userData?.eventName || ''}
                className="input input-bordered"
                onChange={handleInputChange}
                disabled={!isEditMode}
              />
            </div>

           

            <div className="form-control">
              <label htmlFor="designation" className="label">
                <span className="label-text">Designation</span>
              </label>
              <input
                type="text"
                id="designation"
                name="designation"
                defaultValue={userData?.designation || ''}
                className="input input-bordered"
                onChange={handleInputChange}
                disabled={!isEditMode}
              />
            </div>
            <div className="form-control">
              <label htmlFor="department" className="label">
                <span className="label-text">Department</span>
              </label>
              <input
                type="text"
                id="department"
                name="department"
                defaultValue={userData?.department || ''}
                className="input input-bordered"
                onChange={handleInputChange}
                disabled={!isEditMode}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end mt-6">
          {!isEditMode ? (
            <button className="btn btn-primary" onClick={handleEditClick}>
              Edit Profile
            </button>
          ) : (
            <button className="btn btn-success" onClick={handleSubmit}>
              Update
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfo);
