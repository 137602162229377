import React from 'react';
import {
  Grid,
  Button,
  FormControl,
  TextField,
  Autocomplete,
  Select,
  InputLabel,
} from '@mui/material';

import { AiFillMail } from 'react-icons/ai';
import { Countries } from '../../../Util/Util';
import { CONTACT_SERVICE_TYPE, CONTACT_TYPE } from '../../../Util/Constants';

const ContactComFilters = ({
  designation,
  handleDesignation,
  handleServiceType,
  handleTypeOfContact,
  serviceTypeId,
  typeOfContact,
  eventYear,
  handleEventYear,
  countryId,
  contactName,
  handleContactName,
  contactPerson,
  handleContactPerson,
  eventName,
  handleEventNameChange,
  handleCountryIdChange,
  handleClearFilters,
  handleApplyFilters,
  handleSendBulkEmail,
  totalSelected,
}) => {
  return (
    <Grid container spacing={2} className="mb-3">
      <Grid container spacing={1} className=" ml-1">
        {/* <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              label="ID"
              size="small"
              value={id}
               onChange={handleIdChange}
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid> */}
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              label="Contact Name"
              size="small"
              value={contactName}
              onChange={handleContactName}
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <Autocomplete
              options={Countries}
              getOptionLabel={(option) => option.name}
              value={
                Countries.find((option) => option.id === countryId) || null
              }
              onChange={handleCountryIdChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  size="small"
                  variant="outlined"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {' '}
          <FormControl fullWidth>
            <InputLabel> Type Of Contact</InputLabel>
            <Select
              fullWidth
              size="small"
              native
              label="Type Of Contact"
              value={typeOfContact}
              onChange={handleTypeOfContact}
            >
              <option value={0}>Type Of Contact </option>
              {CONTACT_TYPE.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.type}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          {' '}
          <FormControl fullWidth>
            <InputLabel> Service Type</InputLabel>
            <Select
              fullWidth
              size="small"
              native
              label="Service Type"
              value={serviceTypeId}
              onChange={handleServiceType}
            >
              <option value={0}>Service Type</option>
              {CONTACT_SERVICE_TYPE.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.type}
                </option>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              label="Event Name"
              size="small"
              value={eventName}
              onChange={handleEventNameChange}
              variant="outlined"
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              label="Event Year"
              size="small"
              value={eventYear}
              onChange={handleEventYear}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              label="Contact Person"
              size="small"
              value={contactPerson}
              onChange={handleContactPerson}
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormControl fullWidth>
            <TextField
              label="Designation"
              size="small"
              value={designation}
              onChange={handleDesignation}
              variant="outlined"
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container justifyContent="end" gap={3} className="pt-4">
        <div className="flex gap-4">
          <Button
            variant="contained"
            className="bg-blue-500 text-white ml-2 px-12"
            onClick={handleApplyFilters}
          >
            Apply Filters
          </Button>
        </div>
        <Button
          variant="outlined"
          className="ml-2 px-12"
          onClick={handleClearFilters}
        >
          Clear Filters
        </Button>
        <div>
          <Button
            variant="contained"
            className="bg-sky-800 text-white"
            onClick={handleSendBulkEmail}
            disabled={totalSelected === 0}
          >
            <AiFillMail className="mr-2 text-lg" />
            Send Bulk
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default ContactComFilters;
