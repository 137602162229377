import { Button, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Appointment from './Appointment';
import AddIcon from '@mui/icons-material/Add';
import Agent from '../../api/agent';
import { APPOINTMENTSTATUS, MUI_LICENSE_KEY } from '../../Util/Constants';
import { LicenseInfo, DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import { format, addMinutes } from 'date-fns';
import { connect } from 'react-redux';
import { Countries } from '../../Util/Util';
import AppointmentList from '../Students/Student/AppointmentList/AppointmentList';

const Appointments = ({ isInternal, role }) => {
  const [addMode, setAddMode] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Agent.Appointment.get()
      .then((records) => {
        setAppointments(records);
        setLoading(false);
      })
      .catch((error) => {
        debugger;
        console.log(error);
      });
  }, []);

  const handleAppointmentUpdate = (appt) => {
    setAddMode(false);
    let newItem = true;
    let temp = [];
    appointments.forEach((item) => {
      if (item.id == appt.id) {
        temp.push(appt);
        newItem = false;
      } else {
        temp.push(item);
      }
    });
    if (newItem) {
      temp.unshift(appt);
    }
    setAppointments(temp);
  };

  const addRecord = (record) => {
    if (record.id == 0 && role.isStudent) {
      Agent.Students.getStudent(0)
        .then((std) => {
          let stu = {
            studentId: std.id,
            name: std.firstName + ' ' + std.lastName,
            countryOfResidence: std.countryOfResidence,
            email: std.email,
          };
          setAppointment({ ...record, ...stu });
          setAddMode(true);
          // setEmail(std.email);
          // debugger;
          // setCountryOfResidence(std.countryOfResidence);
          // setDefaultPhoneCountry(
          //   Countries.find(({ id }) => id == std.countryOfResidence).code
          // );
          // let temp = { ...appointment, ...stu };
          // setAppointment(temp);
          // setInitialLoading(false);
        })
        .catch((error) => {
          debugger;
          console.log(error);
        });
    } else {
      setAppointment(record);
      setAddMode(true);
    }
  };

  const editRecord = (row) => {
    let temp = { ...appointment, ...row };
    setAppointment(temp);
    setAddMode(true);
  };

  let columns = [
    {
      field: 'id',
      headerName: 'Id',
      hide: true,
      width: 85,
    },
    {
      field: 'appointmentWithId',
      headerName: 'AppointmentWithId',
      hide: true,
    },
    {
      field: 'studentId',
      headerName: 'StudentId',
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Student',
      width: 200,
      hide: !isInternal,
      renderCell: (params) => (
        <Typography color="primary" className="clickable">
          {params.row.name}
        </Typography>
      ),
    },
    {
      field: 'adminName',
      headerName: 'TCL Admin',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 140,
      renderCell: (params) => (
        <Typography>
          {APPOINTMENTSTATUS.find(({ id }) => id == params.row.status).type}
        </Typography>
      ),
    },
    {
      field: 'appointmentOn',
      headerName: 'Date/Time',
      width: 200,
      renderCell: (params) => (
        <Typography>
          {format(
            addMinutes(
              new Date(params.row.appointmentOn),
              -new Date().getTimezoneOffset()
            ),
            'MMMM d, yyyy h:mm a'
          )}
        </Typography>
      ),
    },
    {
      field: 'countryOfResidence',
      headerName: 'Country',
      width: 170,
      renderCell: (params) => (
        <Typography>
          {params.row.countryOfResidence
            ? Countries.find(({ id }) => id == params.row.countryOfResidence)
                .name
            : ''}
        </Typography>
      ),
    },
    {
      field: 'subject',
      headerName: 'Subject',
      width: 600,
    },
  ];
  return (
    <>
      {role.isStudent ? (
        <div className="w-full p-6 flex justify-center">
          <AppointmentList />
        </div>
      ) : (
        <Grid container direction={'column'}>
          <Grid item style={{ height: 710, width: '100%' }}>
            <DataGridPro
              rowHeight={90}
              disableSelectionOnClick
              rows={appointments}
              columns={columns}
              loading={loading}
              density="compact"
              components={{
                Toolbar: GridToolbar,
              }}
              onCellClick={(params, event) => {
                event.defaultMuiPrevented = true;
                if (params.colDef.field === 'name') {
                  console.log('params.row', params.row);
                  editRecord(params.row);
                }
              }}
            />
          </Grid>
        </Grid>
      )}
      {addMode && (
        <Appointment
          record={appointment}
          setRecordParent={handleAppointmentUpdate}
          setAddMode={setAddMode}
          id={0}
        />
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isInternal: state.auth.isInternal,
    role: state.auth.role,
  };
};

//record, setRecordParent, setAddMode
export default connect(mapStateToProps, null)(Appointments);
