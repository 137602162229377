import { Field, useField } from 'formik';
import { TextField } from '@mui/material';
import React from 'react';

const MyTextInput = (props) => {
  const [field, meta] = useField(props.name);
  return (
    <Field
      error={meta.touched && !!meta.error}
      component={TextField}
      fullWidth
      variant="outlined"
      size="small"
      {...field}
      {...props}
      autoComplete="off"
      id={props.name}
      helperText={meta.touched && !!meta.error && meta.error}
    />
  );
};

export default MyTextInput;
