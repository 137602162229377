import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {Button, Grid, Typography} from '@mui/material'
import * as actions from '../../../../store/actions'
import {Search} from '../../../Common/Widgets/Search'
import Agent from '../../../../api/agent'

const AssignAdmin = ({
  users,
  loadUsers,
  assignAdminToAgent,
  agent,
  adminName,
  updateAdminInfo,
  accountName,
  updateAccountInfo,
  updateAlert,
}) => {
  const [allUsers, setAllUsers] = useState([])
  const [assignMode, setAssignMode] = useState(false)
  const [assignType, setAssignType] = useState(1)
  useEffect(() => {
    if (users.length === 0) {
      loadUsers()
        .then(() => {
          setAllUsers(users)
        })
        .catch((error) => {
          console.log(error)
          debugger
        })
    } else {
      setAllUsers(users)
    }
  }, [users])

  const handleSelection = (user) => {
    console.log('assign user with Id: ' + user.id)
    setAssignMode(false)
    if (assignType == 1) {
      assignAdminToAgent(
        agent.id,
        user.id,
        user.name,
        agent.userProfile.id,
        agent.userProfile.name
      )
        .then(() => {
          updateAdminInfo(allUsers.find((x) => x.id === user.id))
          updateAlert('Successfully assigned the admin to this agent', true)
        })
        .catch((error) => {
          console.log(error)
          updateAlert('Failed to update', false)
        })
    } else {
      Agent.Agents.updateBusinessDevelopment(agent.id, user.id)
        .then(() => {
          updateAccountInfo(allUsers.find((x) => x.id === user.id))
          updateAlert(
            'Successfully assigned the business development manager to this agent',
            true
          )
        })
        .catch((error) => {
          debugger
        })
    }
  }

  return (
    <>
      <Grid container direction={'column'} spacing={2}>
        <Grid item container spacing={2}>
          <Grid item>
            <Button
              className='bg-sky-400 hover:bg-sky-500'
              variant='contained'
              onClick={() => {
                setAssignMode(true)
                setAssignType(1)
              }}
            >
              {adminName ? 'Change Admin' : 'Assign Admin'}
            </Button>
          </Grid>
          <Grid item>
            <Typography>{adminName}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
            <Button
              variant='contained'
              className='bg-sky-400 hover:bg-sky-500'
              onClick={() => {
                setAssignMode(true)
                setAssignType(2)
              }}
            >
              Assign Business Development
            </Button>
          </Grid>
          <Grid item>
            <Typography>{accountName}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {assignMode && (
        <Search
          users={allUsers.filter((user) => !user.isDisabled)}
          cancel={setAssignMode}
          action={handleSelection}
          title={
            assignType == 1
              ? 'Admin Assignment'
              : 'Business Development Assignment'
          }
        />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    assignAdminToAgent: (id, parentId, parentName, childId, childName) =>
      dispatch(
        actions.assignAdminToAgent(id, parentId, parentName, childId, childName)
      ),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignAdmin)
