import React from 'react';
import { Field, ErrorMessage, useField } from 'formik';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const MaterialUISelectField = ({
  error,
  errorString,
  label,
  children,
  value,
  name,
  onChange,
  onBlur,
  onChangeParent,
  required,
}) => {
  return (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel required={required}>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        onChange={(e) => {
          onChange(e);
          if (onChangeParent) {
            onChangeParent(e.target.value);
          }
        }}
        onBlur={onBlur}
        value={value}
        error={error}
      >
        {children}
      </Select>
      <FormHelperText style={{ color: 'red' }}>{errorString}</FormHelperText>
    </FormControl>
  );
};

const MySelectInputFormik = ({
  name,
  items,
  label,
  required = false,
  onChangeParent,
}) => {
  const [field, meta] = useField(name);
  return (
    <Field
      error={meta.touched && !!meta.error}
      name={name}
      as={MaterialUISelectField}
      label={label}
      errorString={<ErrorMessage name={name} />}
      required={required}
      value={field.value || null}
      onChangeParent={onChangeParent}
    >
      {items.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.type}
        </MenuItem>
      ))}
    </Field>
  );
};

export default MySelectInputFormik;
