import React, {useState, useContext, useEffect} from 'react'
import styles from '../StudentRegistration/CSS/registration.module.css'
import {connect} from 'react-redux'
import * as actions from '../../../store/actions/index'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import {MobileStepper, Typography} from '@mui/material'

import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

import {Link, useLocation, useNavigate} from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import RegisterContext from '../../../context/register-context'

import CSSTransition from 'react-transition-group/CSSTransition'
import StepLoginEmail from './Steps/StepLoginEmail'
import Agent from '../../../api/agent'
import Step1Personal from './Steps/Step1Personal'
import Step2Subjects from './Steps/Step2Subjects'
import Step3Destination from './Steps/Step3Destination'
import Step4Others from './Steps/Step4Others'

const animationTiming = {
  enter: 400,
  exit: 400,
}

const RegistrationStudent = ({onAuth}) => {
  const navigate = useNavigate()
  // const [exit, setExit] = useState(false)
  const [step, setStep] = useState('Personal Details')
  const [progress, setProgress] = useState('Intro')
  const [step1Done, setStep1Done] = useState(true)
  const [step2Done, setStep2Done] = useState(true)
  const [step3Done, setStep3Done] = useState(true)
  const [step4Done, setStep4Done] = useState(true)
  const [step5Done, setStep5Done] = useState(true)
  const [open, setOpen] = useState(false)

  const registerContext = useContext(RegisterContext)

  // const handleClickOpen = () => {
  //   setOpen(true)
  // }
  const [GuId, setReferralGuid] = useState();
  const { search } = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(search);
    const ref = params.get('referral');
    setReferralGuid(ref || '');
  }, [search]);

  const handleClose = () => {
    setOpen(false)
  }

  async function handleLogin(user) {
    await onAuth(user.email, user.password).then(() => {
      navigate('/student/home?welcome=true')
    })
  }

  const registerStudent = async (user) => {
    let tempCountries = [];
    registerContext.selectedCountries.forEach((c) => tempCountries.push(c.id));
    let tempDepartments = [];
    registerContext.subjects.forEach((c) => tempDepartments.push(c.id));
    if (GuId) {
      var studentrRefData = {
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber: user.phoneNumber,
          email: user.email,
          password: user.password,
          countryOfResidence: registerContext.countryOfResidence,
          countries: tempCountries,
          departments: tempDepartments,
          preferenceStudyLevelId: registerContext.preferenceStudyLevelId,
          year: registerContext.year,
          fundType: registerContext.funding,
          GuId: GuId,
      };
      await Agent.Students.registerRefStudent(studentrRefData)
        .then(() => {
          handleLogin(user);
        })
        .catch((error) => {
          console.log('error: ', error);
          if (
            error.includes(
              'Email address already exists in the system. Please use a different one'
            )
          ) {
            error =
              'You have already created a student portal, please drop WhatsApp text to "44 7939 940559" for further assistance';
          }
          return Promise.reject(error);
        });
    } else {
      var studentData = {
        studentDto: {
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber: user.phoneNumber,
          email: user.email,
          password: user.password,
          countryOfResidence: registerContext.countryOfResidence,
          countries: tempCountries,
          departments: tempDepartments,
          preferenceStudyLevelId: registerContext.preferenceStudyLevelId,
          year: registerContext.year,
          fundType: registerContext.funding
        },
      };
      await Agent.Students.registerStudent(studentData)
        .then(() => {
          handleLogin(user);
        })
        .catch((error) => {
          console.log('error: ', error);
          if (
            error.includes(
              'Email address already exists in the system. Please use a different one'
            )
          ) {
            error =
              'You have already created a student portal, please drop WhatsApp text to "44 7939 940559" for further assistance';
          }
          return Promise.reject(error);
        });
  }
}

  const goPrevious = () => {
    registerContext.setRegisterContext({
      ...registerContext,
      ...{
        currentPage: registerContext.currentPage - 1,
      },
    })
    returnStep(registerContext.currentPage - 1)
  }
  const goNext = () => {
    registerContext.setRegisterContext({
      ...registerContext,
      ...{
        currentPage: registerContext.currentPage + 1,
      },
    })
    returnStep(registerContext.currentPage + 1)
  }
  const returnStep = (page) => {
    if (page == 1) {
      setStep('Personal Details')
      setProgress('Intro')
    } else if (page == 2) {
      setStep('What do you want to study?')
      setProgress('Subjects')
    } else if (page == 3) {
      setStep('Where do you want to study?')
      setProgress('Destination')
    } else if (page == 4) {
      setStep('Final Choices')
      setProgress('Others')
    } else if (page == 5) {
      setStep('Registration Details')
      setProgress('Registration')
    }
  }

  return (
    <>
      <Grid container direction='column' className={styles.body}>
        <Grid
          item
          style={{
            height: 80,
            border: '1px solid rgb(224, 230, 237)',
          }}
          container
          alignItems='center'
        >
          <Grid
            item
            xs={1}
            container
            justifyContent={'center'}
            style={{
              height: 80,
              borderRight: '1px solid rgb(224, 230, 237)',
              opacity: registerContext.currentPage == 1 ? 0.3 : 1,
            }}
          >
            <IconButton
              disabled={registerContext.currentPage == 1}
              aria-label='substract'
              size='large'
              onClick={() => {
                if (registerContext.currentPage == 1) {
                  window.location.replace('https://www.tclglobal.co.uk/')
                } else {
                  goPrevious()
                }
              }}
              style={{backgroundColor: 'transparent', color: 'black'}}
            >
              <ChevronLeftIcon fontSize='inherit' />
            </IconButton>
          </Grid>
          <Grid item xs={10} container justifyContent='center'>
            {/* <Grid item>{registerContext.currentPage}</Grid> */}
            <Grid item>
              <Typography variant='h5' color='primary'>
                {step}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={1}
            container
            justifyContent={'center'}
            style={{height: 80, borderLeft: '1px solid rgb(224, 230, 237)'}}
          >
            <IconButton
              size='large'
              onClick={() => {
                setOpen(true)
              }}
              style={{backgroundColor: 'transparent', color: 'black'}}
            >
              <CloseIcon fontSize='inherit' />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container style={{height: 700, paddingTop: 50}}>
          <Grid item xs={12}>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              in={registerContext.currentPage == 1 && step2Done}
              timeout={animationTiming}
              onEnter={() => setStep1Done(false)}
              onExited={() => setStep1Done(true)}
              classNames={{
                enter: '',
                enterActive: styles['register-step-open'],
                exit: '',
                exitActive: styles['register-step-close'],
              }}
            >
              <Step1Personal goNext={goNext} />
            </CSSTransition>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              in={registerContext.currentPage == 2 && step1Done}
              timeout={animationTiming}
              onEnter={() => setStep3Done(false)}
              onExited={() => setStep3Done(true)}
              classNames={{
                enter: '',
                enterActive: styles['register-step-open'],
                exit: '',
                exitActive: styles['register-step-close'],
              }}
            >
              <Step2Subjects goNext={goNext} />
            </CSSTransition>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              in={registerContext.currentPage == 3 && step2Done}
              timeout={animationTiming}
              onEnter={() => setStep4Done(false)}
              onExited={() => setStep4Done(true)}
              classNames={{
                enter: '',
                enterActive: styles['register-step-open'],
                exit: '',
                exitActive: styles['register-step-close'],
              }}
            >
              <Step3Destination goNext={goNext} />
            </CSSTransition>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              in={registerContext.currentPage == 4 && step3Done}
              timeout={animationTiming}
              onEnter={() => setStep5Done(false)}
              onExited={() => setStep5Done(true)}
              classNames={{
                enter: '',
                enterActive: styles['register-step-open'],
                exit: '',
                exitActive: styles['register-step-close'],
              }}
            >
              <Step4Others goNext={goNext} />
            </CSSTransition>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              in={registerContext.currentPage == 5 && step4Done}
              timeout={animationTiming}
              // onEnter={() => setStep2Done(false)}
              // onExited={() => setStep2Done(true)}
              classNames={{
                enter: '',
                enterActive: styles['register-step-open'],
                exit: '',
                exitActive: styles['register-step-close'],
              }}
            >
              <StepLoginEmail registerStudent={registerStudent} />
            </CSSTransition>
          </Grid>
        </Grid>
        <Grid item container direction='column'>
          {/* <Grid item container justifyContent="center">
          <Grid item xs={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={goNext}
            >
              Next
            </Button>
          </Grid>
        </Grid> */}
          <Grid item container justifyContent='center'>
            <Grid item xs={3} container direction='column'>
              <Grid item>
                <Typography variant='caption'>{progress}</Typography>
              </Grid>
              <Grid item>
                <MobileStepper
                  style={{padding: 0}}
                  variant='progress'
                  steps={6}
                  position='static'
                  activeStep={registerContext.currentPage - 1}
                  sx={{
                    maxWidth: '100%',
                    flexGrow: 1,
                    '& .MuiLinearProgress-root': {
                      width: '100%',
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant='caption'>
                  {`${registerContext.currentPage}/5`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Confirm'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to leave the registration process. It will not
            take long!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Do Not Exit</Button>
          <Button>
           <Link className='text-blue-600'
              to="/"
            >
            Exit
          </Link>         
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email, password)),
  }
}

export default connect(null, mapDispatchToProps)(RegistrationStudent)
