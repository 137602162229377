export const newUniversity = {
  createdByAppUserId: 0,
  profile: {
    name: '',
  },
  courses: [],
};

export const newCourse = {
  id: 0,
  name: '',
  intakes: [],
  studyLevelId: 0,
};
