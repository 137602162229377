import React from 'react';

const LoadingMessage = () => (
  <div className="fixed inset-0 flex flex-col items-center justify-center bg-sky-400 bg-opacity-50 z-50 p-4">
    <div className="relative flex flex-col items-center">
      <progress className="progress w-56 h-4 bg-info-300 rounded-full" />
      <span className="text-white mt-4 text-xl font-semibold">Please wait...</span>
    </div>
  </div>
);

export default LoadingMessage;
