import React from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { MenuItem } from '@mui/material';

const PREFIX = 'MySelectInput';

const classes = {
  error: `${PREFIX}-error`,
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  [`& .${classes.error}`]: {
    color: 'red',
    marginLeft: 0,
  },
}));

const MySelectInput = ({
  options,
  errorMessage,
  label,
  value,
  name,
  handleChange,
  handleBlur,
  isInvalid,
  ...props
}) => {
  return (
    <StyledFormControl fullWidth variant="outlined" size="small">
      <InputLabel {...props}>{label}</InputLabel>
      <Select
        label={label}
        name={name}
        onChange={(e) => {
          handleChange(e.target.value, name);
        }}
        onBlur={(e) => handleBlur(e.target.name)}
        value={value == 0 ? null : value}
      >
        {options.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.type || item.name}
          </MenuItem>
        ))}
      </Select>
      {errorMessage && isInvalid && (
        <FormHelperText className={classes.error}>
          {errorMessage}
        </FormHelperText>
      )}
    </StyledFormControl>
  );
};

export default MySelectInput;
