import { Button, Grid, Typography } from '@mui/material';
import React, {  useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditorDraftJs from '../../../Common/Editor/EditorDraftJs';
import { LoadingButton } from '@mui/lab';
import Agent from '../../../../api/agent';
import { format, addMinutes } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import { TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Divider } from '@mui/material';
import Conversations from './Conversations';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Messages = ({ records, setRecords, setOpenEmailMode, studentId, studentName }) => {
  const [addNodeMode, setAddNodeMode] = useState(false);
  const [rawHtml, setRawHtml] = useState();
  const [updating, setUpdating] = useState(false);
  const [editorValidState, setEditorValidState] = useState(false);
  const [subject, setSubject] = useState(`${studentId} - ${studentName}`);

  const editorOnChange = (rawHtml, valid) => {
    setRawHtml(rawHtml);
    setEditorValidState(valid);
  };
  const handleClose = () => {
      setOpenEmailMode(false);
  };
  console.log('records',records);

  const addNote = () => {
    var msg = {
      content: rawHtml,
      studentId: studentId,
      sendEmail: true,
      noteType: 1,
      file: '',
      attachmentFileName:'',
      subject : subject,
    };
    setUpdating(true);
    Agent.Messages.addStudentNoteAtt(msg)
      .then(() => {
        let temp = records.concat({
          content: rawHtml,
          from: studentName,
          noteType: 1,
          subject,
          dateSent: format(
            addMinutes(new Date(), new Date().getTimezoneOffset()),
            'MMMM d, yyyy h:mm a'
          ),
        });
        temp.sort(function (a, b) {
          return new Date(b.dateSent) - new Date(a.dateSent);
        });
        setRecords(temp);
        setUpdating(false);
        setAddNodeMode(false);
        setEditorValidState(false);
        //setDraftMessageParent(null);
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
        debugger;
      });
  };
  // const addNote = () => {
  //   var msg = {
  //     content: rawHtml,
  //     applicationId: appId,
  //     sendEmail: false,
  //     noteType: 2,
  //   };
  //   setUpdating(true);
  //   Agent.Messages.addApplicationNote(msg)
  //     .then(() => {
  //       const tempUserName=toName.split(' ');
  //       var tempLastName=' ';
  //       if(tempUserName.length==3){
  //         tempLastName=tempUserName[1]+' '+tempUserName[2];
  //       }else if(tempUserName.length==4){
  //         tempLastName=tempUserName[1]+' '+tempUserName[2]+' '+tempUserName[3];
  //       }
  //       else{
  //         tempLastName=tempUserName[1];
  //       }
  //       const newNote = [
  //         {
  //           content: rawHtml,
  //           appUserDetails: {firstName : tempUserName[0] ? tempUserName[0]:' ',
  //             lastName : tempLastName ? tempLastName:' ',
  //           },
  //           noteType: 2,
  //           dateSent: format(
  //             addMinutes(new Date(), new Date().getTimezoneOffset()),
  //             'MMMM d, yyyy h:mm a'
  //           ),
  //         },
  //       ];
  //       setApplicationNote(newNote.concat(applicationNote));
  //       setUpdating(false);
  //       setAddNodeMode(false);
  //       setEditorValidState(false);
  //     })
  //     .catch((error) => {
  //       setUpdating(false);
  //       console.log(error);
  //       debugger;
  //     });
  // };
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <Typography variant="h5" align="center" color="primary">
         Communications with TCL
        </Typography>
      </DialogTitle>
      <Divider />

      <DialogActions>
        <Grid container direction="column" spacing={2} style={{ marginTop: 5 }}>
          <Grid container direction="row" justifyContent="space-between" className='px-6'>
            <Button
              endIcon={!addNodeMode ? <AddIcon /> : null}
              onClick={() => {
                setAddNodeMode((prevState) => !prevState);
              }}
            >
              {addNodeMode ? 'Hide  Option' : 'Send Mail'}
            </Button>
            <Grid item >
              <LoadingButton className='text-red-600 font-medium' onClick={handleClose}>Cancel</LoadingButton>
            </Grid>
          </Grid>
          {addNodeMode && (
            <>
              <Grid item>
              <div className="">
                  <TextField
                    fullWidth
                    size="small"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    label="Subject"
                    required
                  />
                </div>
                <EditorDraftJs editorOnChange={editorOnChange} height={100} />
              </Grid>
              <Grid item>
                <LoadingButton
                  loading={updating}
                  disabled={!editorValidState}
                  onClick={addNote}
                  variant="outlined"
                >
                  Send Mail
                </LoadingButton>
              </Grid>
            </>
          )}
          
          <Grid item container>
            <Conversations
              messages={records.filter((item) => item.noteType == 1)}
              user={studentName}
              hideReplyOption={true}
            />
          </Grid>
        </Grid>
      </DialogActions>
      <br />
      <br />
      <hr />
    </Dialog>
  );
};

export default Messages;
