import { Button, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const ServerError = ({ serverError }) => {
  return (
    <>
      <Typography>Server Error</Typography>
      <Typography variant="body1">{serverError.message}</Typography>
      {serverError.details && (
        <Typography variant="body1">{serverError.details}</Typography>
      )}

      <Button as={Link} to="/">
        Return to Home Page
      </Button>
    </>
  );
};

// this is how we get Redux store
const mapStateToProps = (state) => {
  return {
    serverError: state.common.serverError,
  };
};

export default connect(mapStateToProps, null)(ServerError);
