/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useContext, useEffect } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import AvatarMui from '../Common/Image/AvatarMui';
import { connect } from 'react-redux';

import Logo from '../../assets/images/logo.png';
import NotificationDropdown from '../Custom/Menu/NotificationDropdown';
import UserContext from '../../context/user-context';
import * as actions from '../../store/actions/index';
import LogoutIcon from '@mui/icons-material/Logout';
import Agent from '../../api/agent';
import { Fragment } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import Contents from './Contents';
import LoadingView from '../Common/Loading/LoadingView';
import useWindowSize from '../Students/common/useWindowSize';

const CommonNavbar = ({
  logout,
  name,
  appUserId,
  role,
  imageUrl,
  appUserDetailsId,
  browserUserInfo,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  console.log('firstName', name);
  const studentNameSplit = name?.split(' ');
  const firstName = studentNameSplit[0]?.split('');
  const lastName = studentNameSplit[1]?.split('');
  const [studentPhoto, setStudentPhoto] = useState();
  const [student, setStudent] = useState({});
  const [referralOnClick, setReferralOnClick] = useState(false);
  const [referralUserInfo, SetReferralUserInfo] = useState({});
  const [loadingPage, setLoadingPage] = useState(true);
  const [referral, setReferral] = useState(false);
  const [refdashboard, setRefDashboard] = useState(false);
  const [preferencesClick, setPreferencesClick] = useState(false);
  const [profileClick, setProfileClick] = useState(false);
  const [programsClick, setProgramsClick] = useState(false);
  const [appointmentsClick, setAppointmentsClick] = useState(false);
  const [applicationsClick, setApplicationsClick] = useState(false);
  const [homeClick, setHomeClick] = useState(false);
  const [dashBoardClick, setDashboardClick] = useState(false);
  const [refProfileClick, setRefProfileClick] = useState(false);
  const [refContractClick, setRefContractClick] = useState(false);

  const location = useLocation();
  const refUrl = location.pathname;
  const refUrlName = refUrl.split('/');

  console.log('role', role);
  const navigation = [
    { name: 'Home', href: '/Home', current: false },
    { name: 'Profile', href: '/profile', current: false },
    { name: 'Preferences', href: '/Preferences', current: false },
    { name: 'Appointments', href: '/appointments', current: false },
    { name: 'Applications', href: '/applications', current: false },
    { name: 'Programs', href: '/programs', current: false },
    { name: 'Referral', href: '', current: false },
  ];
  const refNavigation = [{ name: 'Referral', href: '', current: false }];
  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };
  let userContext = useContext(UserContext);
  const navigate = useNavigate();

  const handleClickNotificationItem = (item) => {
    console.log('item', item);
    Agent.Common.deletenotification(item.id)
      .then(() => {
        userContext.setUserContext({
          ...userContext,
          notificationCount: userContext.notificationCount - 1,
          notifications: userContext.notifications.filter(function (
            notification
          ) {
            return notification.id != item.id;
          }),
        });
        navigate(item.link);
      })
      .catch((error) => {
        debugger;
        console.log(error);
      });
  };
  useEffect(() => {
    setLoadingPage(true);
    if (appUserId) {
      var idToUse = appUserId;
      Agent.Students.getStudent(idToUse)
        .then((std) => {
          const { studentCloudFiles, ...rest } = std;
          setStudentPhoto(
            studentCloudFiles.filter((file) => file.documentType == 16)[0]
          );
          setReferral(rest.isReferral);
          setStudent(rest);
          console.log('student rest', rest);
          //  setLoadingPage(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    if (refUrlName[2] == 'dashboard') {
      setDashboardClick(true);
    } else if (refUrlName[2] == 'profile') {
      setRefProfileClick(true);
    } else if (refUrlName[2] == 'contract') {
      setRefContractClick(true);
    } else if (refUrlName[2] == 'home' || refUrlName[1] == 'home') {
      if (refdashboard) {
        setDashboardClick(true);
      } else {
        setHomeClick(true);
      }
    } else if (refUrlName[1] == 'profile') {
      setProfileClick(true);
    } else if (refUrlName[1] == 'Preferences') {
      setPreferencesClick(true);
    } else if (refUrlName[1] == 'programs') {
      setProgramsClick(true);
    } else if (refUrlName[1] == 'appointments') {
      setAppointmentsClick(true);
    } else if (refUrlName[1] == 'applications') {
      setApplicationsClick(true);
    }
  }, []);

  const handleOnClickMenu = (onClickMenuName) => {
    setPreferencesClick(false);
    setProfileClick(false);
    setProgramsClick(false);
    setAppointmentsClick(false);
    setApplicationsClick(false);
    setHomeClick(false);
    setDashboardClick(false);
    setRefProfileClick(false);
    setRefContractClick(false);

    if (onClickMenuName == 'Preferences') {
      setPreferencesClick(true);
    } else if (onClickMenuName == 'profile') {
      setProfileClick(true);
    } else if (onClickMenuName == 'programs') {
      setProgramsClick(true);
    } else if (onClickMenuName == 'appointments') {
      setAppointmentsClick(true);
    } else if (onClickMenuName == 'applications') {
      setApplicationsClick(true);
    } else if (onClickMenuName == 'home') {
      setHomeClick(true);
    } else if (onClickMenuName == 'refdashboard') {
      setDashboardClick(true);
    } else if (onClickMenuName == 'refProfile') {
      setRefProfileClick(true);
    } else if (onClickMenuName == 'refContract') {
      setRefContractClick(true);
    }
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMyAccountSettings = () => {
    handleMenuClose();
  };
  const handleHomeOnClick = () => {
    navigate('/student/home');
    handleOnClickMenu('home');
  };
  const handleProfileOnClick = () => {
    navigate('/profile');
    handleOnClickMenu('profile');
  };
  const handlePreferencesOnClick = () => {
    navigate('/Preferences');
    handleOnClickMenu('Preferences');
  };
  const handleAppointmentsOnClick = () => {
    navigate('/appointments');
    handleOnClickMenu('appointments');
  };
  const handleApplicationsOnClick = () => {
    navigate('/applications');
    handleOnClickMenu('applications');
  };
  const handleProgramsOnClick = () => {
    navigate('/programs');
    handleOnClickMenu('programs');
  };
  const handleReferralOnClick = () => {
    Agent.Referral.updateLandingPage(referralUserInfo.id, true)
      .then((res) => {
        console.log('referral dashboard changed to true successfully');
        navigate('/referral/dashboard');
      })
      .catch((error) => {
        console.log(error);
      });
    setReferralOnClick(false);
    handleOnClickMenu('refdashboard');
  };

  const handleStudentOnClick = () => {
    Agent.Referral.updateLandingPage(referralUserInfo.id, false)
      .then((res) => {
        console.log('referral dashboard changed to false successfully');
        navigate('/student/home');
      })
      .catch((error) => {
        console.log(error);
      });
    setReferralOnClick(true);
    handleOnClickMenu('home');
  };

  useEffect(() => {
    setLoadingPage(true);
    Agent.Referral.getreferralbyuserId(appUserId)
      .then((data) => {
        SetReferralUserInfo(data);
        setRefDashboard(data.isReferralDashboard);
        console.log('refdashboard', data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [appUserId]);

  setTimeout(() => {
    setLoadingPage(false);
  }, 500);
  
  const handleRefDashboardOnClick = () => {
    navigate('/referral/dashboard');
    handleOnClickMenu('refdashboard');
  };
  const handleRefProfileOnClick = () => {
    navigate('/referral/profile');
    handleOnClickMenu('refProfile');
  };
  const handleRefContractOnClick = () => {
    navigate('/referral/contract');
    handleOnClickMenu('refContract');
  };
  const handleLogOut = () => {
    logout();
    handleMenuClose();
    navigate('/');
  };
  const menuId = 'primary-search-account-menu';
  const size = useWindowSize();

  return (
    <>
      <Disclosure as="nav" className="bg-gray-800">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <button>
                      <Link to="/Home">
                        <img
                          alt="company logo"
                          className="w-24 h-14 pr-2"
                          src={Logo}
                        />
                      </Link>
                    </button>
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    {loadingPage ? (
                      <LoadingView />
                    ) : (
                      <div className="flex space-x-4">
                        {/* {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          onClick={handleNavOnClick(item.name)}
                          className={classNames(
                            item.current
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'rounded-md py-2 pt-4 text-sm font-medium md:px-0 lg:px-2'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                        { item.name}
                        </Link>
                      )) 
                    }  */}

                        {referralUserInfo && role.isStudent ? (
                          <div className="flex flex-start justify-between gap-4 text-white py-2 pt-4 text-sm font-medium md:px-0 lg:px-2">
                            {refUrlName[1] != 'referral' && (
                              <button onClick={handleHomeOnClick}>
                                {homeClick ? (
                                  <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                    Home
                                  </span>
                                ) : (
                                  <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                    Home
                                  </span>
                                )}
                              </button>
                            )}
                            {refUrlName[1] != 'referral' && (
                              <button onClick={handleProfileOnClick}>
                                {profileClick ? (
                                  <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                    Profile
                                  </span>
                                ) : (
                                  <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                    Profile
                                  </span>
                                )}
                              </button>
                            )}
                            {refUrlName[1] != 'referral' && (
                              <button onClick={handlePreferencesOnClick}>
                                {preferencesClick ? (
                                  <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                    Preferences
                                  </span>
                                ) : (
                                  <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                    Preferences
                                  </span>
                                )}
                              </button>
                            )}
                            {refUrlName[1] != 'referral' && (
                              <button onClick={handleProgramsOnClick}>
                                {programsClick ? (
                                  <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                    Programs
                                  </span>
                                ) : (
                                  <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                    Programs
                                  </span>
                                )}
                              </button>
                            )}
                            {refUrlName[1] != 'referral' && (
                              <button onClick={handleAppointmentsOnClick}>
                                {size.width > 912 &&
                                  (appointmentsClick ? (
                                    <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                      Appointments
                                    </span>
                                  ) : (
                                    <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                      Appointments
                                    </span>
                                  ))}
                              </button>
                            )}
                            {refUrlName[1] != 'referral' && (
                              <button onClick={handleApplicationsOnClick}>
                                {size.width > 912 &&
                                  (applicationsClick ? (
                                    <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                      Applications
                                    </span>
                                  ) : (
                                    <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                      Applications
                                    </span>
                                  ))}
                              </button>
                            )}
                            {refUrlName[1] != 'referral' && referral && (
                              <button
                                className="bg-sky-500 px-2 py-1 rounded-sm"
                                onClick={handleReferralOnClick}
                              >
                                Referral Dashboard
                              </button>
                            )}
                            {refUrlName[1] == 'referral' && (
                              <button onClick={handleRefDashboardOnClick}>
                                {dashBoardClick ? (
                                  <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                    Dashboard
                                  </span>
                                ) : (
                                  <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                    Dashboard
                                  </span>
                                )}
                              </button>
                            )}
                            {refUrlName[1] == 'referral' && (
                              <button onClick={handleRefProfileOnClick}>
                                {refProfileClick ? (
                                  <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                    Profile
                                  </span>
                                ) : (
                                  <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                    Profile
                                  </span>
                                )}
                              </button>
                            )}
                            {refUrlName[1] == 'referral' && (
                              <button onClick={handleRefContractOnClick}>
                                {refContractClick ? (
                                  <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                    Contract
                                  </span>
                                ) : (
                                  <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                    Contract
                                  </span>
                                )}
                              </button>
                            )}
                            {refUrlName[1] == 'referral' &&
                              referralUserInfo.isStudent && (
                                <button
                                  className="bg-sky-500 px-2 py-1 rounded-sm"
                                  onClick={handleStudentOnClick}
                                >
                                  Student Dashboard
                                </button>
                              )}
                          </div>
                        ) : role.isStudent ? (
                          <div className="flex flex-start justify-between gap-4 text-white py-2 pt-4 text-sm font-medium md:px-0 lg:px-2">
                            <button onClick={handleHomeOnClick}>Home</button>
                            <button onClick={handleProfileOnClick}>
                              {profileClick ? (
                                <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                  Profile
                                </span>
                              ) : (
                                <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                  Profile
                                </span>
                              )}
                            </button>

                            <button onClick={handlePreferencesOnClick}>
                              {preferencesClick ? (
                                <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                  Preferences
                                </span>
                              ) : (
                                <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                  Preferences
                                </span>
                              )}
                            </button>
                            <button onClick={handleProgramsOnClick}>
                              {programsClick ? (
                                <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                  Programs
                                </span>
                              ) : (
                                <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                  Programs
                                </span>
                              )}
                            </button>
                            <button onClick={handleAppointmentsOnClick}>
                              {size.width > 912 &&
                                (appointmentsClick ? (
                                  <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                    Appointments
                                  </span>
                                ) : (
                                  <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                    Appointments
                                  </span>
                                ))}
                            </button>
                            <button onClick={handleApplicationsOnClick}>
                              {size.width > 912 &&
                                (applicationsClick ? (
                                  <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                    Applications
                                  </span>
                                ) : (
                                  <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                    Applications
                                  </span>
                                ))}
                            </button>
                            {referral && (
                              <button
                                className="bg-sky-500 px-2 py-1 rounded-sm"
                                onClick={handleReferralOnClick}
                              >
                                Referral Dashboard
                              </button>
                            )}
                          </div>
                        ) : (
                          <div className="flex flex-start justify-between gap-4 text-white py-2 pt-4 text-sm font-medium md:px-0 lg:px-2">
                            {referralOnClick && (
                              <button onClick={handleHomeOnClick}>Home</button>
                            )}
                            {referralOnClick && (
                              <button onClick={handleProfileOnClick}>
                                {profileClick ? (
                                  <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                    Profile
                                  </span>
                                ) : (
                                  <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                    Profile
                                  </span>
                                )}
                              </button>
                            )}

                            {referralOnClick && (
                              <button onClick={handlePreferencesOnClick}>
                                {preferencesClick ? (
                                  <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                    Preferences
                                  </span>
                                ) : (
                                  <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                    Preferences
                                  </span>
                                )}
                              </button>
                            )}
                            {referralOnClick && (
                              <button onClick={handleProgramsOnClick}>
                                {programsClick ? (
                                  <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                    Programs
                                  </span>
                                ) : (
                                  <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                    Programs
                                  </span>
                                )}
                              </button>
                            )}
                            {referralOnClick && (
                              <button onClick={handleAppointmentsOnClick}>
                                {size.width > 912 &&
                                  (appointmentsClick ? (
                                    <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                      Appointments
                                    </span>
                                  ) : (
                                    <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                      Appointments
                                    </span>
                                  ))}
                              </button>
                            )}
                            {referralOnClick && (
                              <button onClick={handleApplicationsOnClick}>
                                {size.width > 912 &&
                                  (applicationsClick ? (
                                    <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                      Applications
                                    </span>
                                  ) : (
                                    <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                      Applications
                                    </span>
                                  ))}
                              </button>
                            )}

                            {!referralOnClick && (
                              <button onClick={handleRefDashboardOnClick}>
                                {dashBoardClick ? (
                                  <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                    Dashboard
                                  </span>
                                ) : (
                                  <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                    Dashboard
                                  </span>
                                )}
                              </button>
                            )}
                            {!referralOnClick && (
                              <button onClick={handleRefProfileOnClick}>
                                {refProfileClick ? (
                                  <span className="bg-gray-600 px-2 py-1 rounded-sm">
                                    Profile
                                  </span>
                                ) : (
                                  <span className="hover:bg-gray-600 px-2 py-1 rounded-sm ">
                                    Profile
                                  </span>
                                )}
                              </button>
                            )}
                            {!referralOnClick &&
                              role.isReferral &&
                              referralUserInfo.isStudent && (
                                <button
                                  className="bg-sky-500 px-2 py-1 rounded-sm"
                                  onClick={handleStudentOnClick}
                                >
                                  Student Dashboard
                                </button>
                              )}
                            {referralOnClick && (
                              <button
                                className="bg-sky-500 px-2 py-1 rounded-sm"
                                onClick={handleReferralOnClick}
                              >
                                Referral Dashboard
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <button
                    type="button"
                    className="relative rounded-full px-2 bg-gray-800 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  &nbsp;
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">Open user menu</span>
                        <div className="avatar placeholder">
                          <div className="bg-gray-600 text-neutral-content rounded-full w-9">
                            {!imageUrl ? (
                              <span className="text-xl uppercase">
                                {' '}
                                {firstName[0] + lastName[0]}
                              </span>
                            ) : (
                              <img
                                className="relative rounded-full h-full"
                                alt="student"
                                src={imageUrl}
                              />
                            )}
                          </div>
                        </div>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/settings"
                              onClick={handleMyAccountSettings}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Account Settings
                            </Link>
                          )}
                        </Menu.Item>

                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/"
                              onClick={handleLogOut}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              Log out
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                <Disclosure.Button>
                  {referralUserInfo && role.isStudent ? (
                    <div className="flex flex-col items-start pl-2 gap-4 text-white py-2 pt-4 text-lg font-medium md:px-0 lg:px-2">
                      {refUrlName[1] != 'referral' && (
                        <button onClick={handleHomeOnClick}>Home</button>
                      )}
                      {refUrlName[1] != 'referral' && (
                        <button onClick={handleProfileOnClick}>Profile</button>
                      )}
                      {refUrlName[1] != 'referral' && (
                        <button onClick={handlePreferencesOnClick}>
                          Preferences
                        </button>
                      )}
                      {refUrlName[1] != 'referral' && (
                        <button onClick={handleProgramsOnClick}>
                          Programs
                        </button>
                      )}
                      {refUrlName[1] != 'referral' && (
                        <button onClick={handleAppointmentsOnClick}>
                          Appointments
                        </button>
                      )}
                      {refUrlName[1] != 'referral' && (
                        <button onClick={handleApplicationsOnClick}>
                          Applications
                        </button>
                      )}
                      {refUrlName[1] != 'referral' && referral && (
                        <button
                          className="bg-sky-500 px-2 py-1 rounded-sm"
                          onClick={handleReferralOnClick}
                        >
                          Referral Dashboard
                        </button>
                      )}
                      {refUrlName[1] == 'referral' && (
                        <button onClick={handleRefDashboardOnClick}>
                          Dashboard
                        </button>
                      )}
                      {refUrlName[1] == 'referral' && (
                        <button onClick={handleRefProfileOnClick}>
                          Profile
                        </button>
                      )}
                       {refUrlName[1] == 'referral' && (
                        <button onClick={handleRefContractOnClick}>
                          Contract
                        </button>
                      )}
                      {refUrlName[1] == 'referral' &&
                        referralUserInfo.isStudent && (
                          <button
                            className="bg-sky-500 px-2 py-1 rounded-sm"
                            onClick={handleStudentOnClick}
                          >
                            Student Dashboard
                          </button>
                        )}
                    </div>
                  ) : role.isStudent ? (
                    <div className="flex flex-col items-start pl-2 gap-4 text-white py-2 pt-4 text-lg font-medium md:px-0 lg:px-2">
                      <button onClick={handleHomeOnClick}>Home</button>
                      <button onClick={handleProfileOnClick}>Profile</button>

                      <button onClick={handlePreferencesOnClick}>
                        Preferences
                      </button>
                      <button onClick={handleProgramsOnClick}>Programs</button>
                      <button onClick={handleAppointmentsOnClick}>
                        Appointments
                      </button>
                      <button onClick={handleApplicationsOnClick}>
                        Applications
                      </button>

                      {referral && (
                        <button
                          className="bg-sky-500 px-2 py-1 rounded-sm"
                          onClick={handleReferralOnClick}
                        >
                          Referral Dashboard
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className="flex flex-col items-start pl-2 gap-4 text-white py-2 pt-4 text-lg font-medium md:px-0 lg:px-2">
                      {referralOnClick && (
                        <button onClick={handleHomeOnClick}>Home</button>
                      )}
                      {referralOnClick && (
                        <button onClick={handleProfileOnClick}>Profile</button>
                      )}

                      {referralOnClick && (
                        <button onClick={handlePreferencesOnClick}>
                          Preferences
                        </button>
                      )}
                      {referralOnClick && (
                        <button onClick={handleProgramsOnClick}>
                          Programs
                        </button>
                      )}
                      {referralOnClick && (
                        <button onClick={handleAppointmentsOnClick}>
                          Appointments
                        </button>
                      )}
                      {referralOnClick && (
                        <button onClick={handleApplicationsOnClick}>
                          Applications
                        </button>
                      )}

                      {!referralOnClick && (
                        <button onClick={handleRefDashboardOnClick}>
                          Dashboard
                        </button>
                      )}
                      {!referralOnClick && (
                        <button onClick={handleRefProfileOnClick}>
                          Profile
                        </button>
                      )}
                      {!referralOnClick && (
                        <button onClick={handleRefContractOnClick}>
                          Contract
                        </button>
                      )}
                      {!referralOnClick &&
                        role.isReferral &&
                        referralUserInfo.isStudent && (
                          <button
                            className="bg-sky-500 px-2 py-1 rounded-sm"
                            onClick={handleStudentOnClick}
                          >
                            Student Dashboard
                          </button>
                        )}
                      {referralOnClick && (
                        <button
                          className="bg-sky-500 px-2 py-1 rounded-sm"
                          onClick={handleReferralOnClick}
                        >
                          Referral Dashboard
                        </button>
                      )}
                    </div>
                  )}
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    studentName: state.auth.studentName,
    name: state.auth.name,
    status: state.auth.status,
    role: state.auth.role,
    imageUrl: state.auth.image,
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    setDrawerOpen: ownProps.setDrawerOpen,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonNavbar);
