import React from 'react';
import { styled } from '@mui/material/styles';
import LinearProgress from '@mui/material/LinearProgress';

const PREFIX = 'LinearIndeterminate';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function LinearIndeterminate() {
  return (
    <Root className={classes.root}>
      <LinearProgress />
      {/* <LinearProgress color="secondary" /> */}
    </Root>
  );
}
