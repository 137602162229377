import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import { v4 as uuid } from 'uuid';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import LinearIndeterminate from '../Common/Progress/LinearIndeterminate';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { IconButton } from '@mui/material';
import isWeekend from 'date-fns/isWeekend';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // the locale you want

import {
  getDay,
  getHours,
  getMinutes,
  getMonth,
  getTime,
  getYear,
} from 'date-fns';
registerLocale('en-GB', enGB); // register it with the name you want
import { format, addMinutes } from 'date-fns';
import { APPOINTMENTSTATUS } from '../../Util/Constants';
import Users from '../Users/Users';
import MyCountryList from '../Common/Form/MyCountryList';
import { Countries } from '../../Util/Util';
import { LoadingButton } from '@mui/lab';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import CustomPhoneNumber from './phoneInput';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import MyTooltip from '../Common/Tooltip/MyTooltip';
import Agent from '../../api/agent';

const PREFIX = 'Appointment';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
  dateFiled: `${PREFIX}-dateFiled`,
  countryList: `${PREFIX}-countryList`,
  field: `${PREFIX}-field`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  [`& .${classes.dateFiled}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'red',
    backgroundColor: 'red',
  },
  [`& .${classes.countryList}`]: {
    ...theme.typography.body1,
  },
  [`& .${classes.field}`]: {
    margin: '10px 0',
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Appointment = ({
  record,
  setRecordParent,
  setAddMode,
  role,
  isInternal,
}) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [readyToSave, setReadyToSave] = useState(false);
  const [open, setOpen] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [date, setDate] = useState(
    record.appointmentOn ? new Date(record.appointmentOn) : null
  );
  const [time, setTime] = useState(
    record.appointmentOn
      ? addMinutes(
          new Date(record.appointmentOn),
          -new Date().getTimezoneOffset()
        )
      : null
  );
  const [appointmentOn, setAppointmentOn] = useState();
  const [countryOfResidence, setCountryOfResidence] = useState(
    record.countryOfResidence
  );
  const [phone, setPhone] = useState(record.phone);
  const [subject, setSubject] = useState(record.subject || null);
  const [email, setEmail] = useState(record.email || null);
  const [status, setStatus] = useState(record.status || 1);
  const [appointment, setAppointment] = useState(record);
  const [defaultPhoneCountry, setDefaultPhoneCountry] = useState(
    record.countryOfResidence
      ? Countries.find(({ id }) => id == record.countryOfResidence).code
      : 'GB'
  );
  // Student selection
  const [students, setStudents] = useState([]);
  const [student, setStudent] = useState(null);
  const studentRef = useRef();
  // admin end

  // Admin selection
  const [openAdmin, setOpenAdmin] = useState(false);
  const [cosmosAdminUsers, setCosmosAdminUsers] = useState([]);
  const adminLoading = openAdmin && cosmosAdminUsers.length === 0;
  const [cosmosAdmin, setCosmosAdmin] = useState(null);
  const adminRef = useRef();
  // admin end

  const [errors, setErrors] = useState();

  const messageStatus = record.isReplied
    ? 'Responded'
    : record.isRead
    ? 'Read'
    : record.isReceived
    ? 'Received'
    : record.isSent
    ? 'Sent'
    : 'No Info';

  useEffect(() => {
    if (status == 2) {
      // confirm
      setReadyToSave(
        date &&
          time &&
          subject &&
          email &&
          status &&
          phone &&
          phone.length > 5 &&
          cosmosAdmin
      );
    } else {
      setReadyToSave(
        date && time && subject && email && status && phone && phone.length > 5
      );
    }
  }, [date, time, subject, email, status, phone, cosmosAdmin]);

  useEffect(() => {
    Agent.Cosmos.getUsers()
      .then((usrs) => {
        if (!cosmosAdmin && record.appointmentWithId) {
          let temp = usrs.find(({ id }) => id == record.appointmentWithId);
          setCosmosAdmin(temp);
        }

        setCosmosAdminUsers(
          usrs.filter((item) => {
            return (
              item.usertype == 1 || item.usertype == 2 || item.usertype == 3
            );
          })
        );
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  }, []);

  function handleClose() {
    setOpen(false);
    setAddMode(false);
  }

  const handleFormCancel = () => {
    setOpen(false);
    setAddMode(false);
  };

  const handleChange = (name, val) => {
    let temp = { ...appointment, [name]: val };
    setAppointment(temp);
  };

  useEffect(() => {
    if (date && time) {
      let day = date.getDate();
      let month = date.getMonth();
      let year = date.getFullYear();
      let hour = time.getHours();
      let minute = time.getMinutes();
      setAppointmentOn(new Date(year, month, day, hour, minute, 0, 0));
    } else {
      setAppointmentOn(null);
    }
  }, [date, time]);

  const save = () => {
    setErrors(null);
    setUpdating(true);
    let newAppointment = {
      ...appointment,
      ...{
        appointmentOn: appointmentOn,
        status: status,
        localDateTimeAsString: format(appointmentOn, 'MMMM d, yyyy h:mm a'),
      },
    };
    Agent.Appointment.save(newAppointment)
      .then((appt) => {
        //to update parent, we basically need to add the timezone offset because parent will expect it in UTC and will deal with timezone offset itself.
        setRecordParent({
          ...appointment,
          id: appt.id,
          status: status,
          appointmentOn: addMinutes(
            new Date(appointmentOn),
            new Date().getTimezoneOffset()
          ),
        });
      })
      .catch((error) => {
        debugger;
        setErrors(error);
        console.log(error);
      });
  };

  return (
    <Root>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperComponent}
        maxWidth={'sm'}
        fullWidth
      >
        <>
          <div className='flex flex-start pt-2'>
            <div className="w-full pt-2 pl-2">
              <div className="text-2xl font-semibold text-indigo-600 px-4">{`Appointment for ${appointment.name}`}</div>
            </div>
            <div className='flex-end'>
              <IconButton
                aria-label="close"
                className="text-rose-500"
                onClick={handleFormCancel}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </div>
          </div>

            <div className='pl-6 pb-2'>
                {appointmentOn
                  ?<Typography className='text-gray-500 text-sm'>Appointment on:&nbsp;
                   {format(appointmentOn, 'MMMM d, yyyy h:mm a')} </Typography>
                  : ''}
            </div>
          <DialogContent>
            {updating && (
              <div>
                <LinearIndeterminate />
              </div>
            )}
            <div className="flex gap-5 justify-center">
              <div className="w-full flex flex-col gap-5">
               <div>
                  <TextField
                    value={subject}
                    onChange={(e) => {
                      handleChange('subject', e.target.value);
                      setSubject(e.target.value);
                    }}
                    fullWidth
                    label="Appointment Subject"
                    placeholder="Subject"
                    size="small"
                    inputProps={{
                      maxLength: 200,
                    }}
                  />
                </div>
                {role.isStudent?'': isInternal && (
                  <div>
                    <Autocomplete
                      fullWidth
                      size="small"
                      open={openAdmin}
                      onOpen={() => {
                        setOpenAdmin(true);
                      }}
                      onClose={() => {
                        setOpenAdmin(false);
                      }}
                      value={cosmosAdmin}
                      onChange={(_, newVal) => {
                        setCosmosAdmin(newVal);
                        let adm = { appointmentWithId: null, adminName: '' };
                        if (newVal) {
                          adm.appointmentWithId = parseInt(newVal.id);
                          adm.adminName = newVal.name;
                        }
                        let temp = { ...appointment, ...adm };
                        setAppointment(temp);
                      }}
                      getOptionLabel={(option) => `${option.name}`}
                      options={cosmosAdminUsers}
                      loading={adminLoading}
                      renderInput={(params) => (
                        <TextField
                          inputRef={adminRef}
                          {...params}
                          label="Appointment Admin"
                          placeholder="Appointment Admin"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {adminLoading ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </div>
                )}
                <div>
                  <MyCountryList
                    name="countryOfResidence"
                    label="Country of Residence"
                    value={countryOfResidence}
                    handleChange={(newVal) => {
                      setCountryOfResidence(newVal);
                      setAppointment({
                        ...appointment,
                        countryOfResidence: newVal,
                      });
                      if (newVal) {
                        const country = Countries.find(
                          ({ id }) => id == newVal
                        );
                        setDefaultPhoneCountry(country ? country.code : 'GB');
                        setPhone(null);
                      }
                    }}
                    errorMessage="Country of residenceis invalid"
                    // handleBlur={countryOfResidence.valueInputBlurHandler}
                    // isInvalid={countryOfResidence.hasError && displayError}
                  />
                </div>
                <div>
                  <PhoneInput
                    useNationalFormatForDefaultCountryValue={true}
                    placeholder="Enter phone number"
                    defaultCountry={defaultPhoneCountry || 'gb'}
                    value={phone}
                    onChange={(newVal) => {
                      handleChange('phone', newVal);
                      setPhone(newVal);
                    }}
                    inputComponent={CustomPhoneNumber}
                    //dropdownClass={classes.countryList}
                  />
                </div>
                <div>
                  <TextField
                    type={'email'}
                    value={email}
                    onChange={(e) => {
                      handleChange('email', e.target.value);
                      setEmail(e.target.value);
                    }}
                    fullWidth
                    label="Email"
                    // placeholder="Email"
                    size="small"
                    inputProps={{
                      maxLength: 80,
                    }}
                  />
                </div>
                
           <div className="flex justify-between gap-3">
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputFormat="dd-MM-yyyy"
                      label="Appointment Date"
                      value={date}
                      onChange={(newVal) => {
                        setDate(newVal);
                        handleChange('date', newVal);
                      }}
                      renderInput={(params) => (
                        <TextField error={false} size="small" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopTimePicker
                      label="Appointment Time"
                      value={time}
                      onChange={(newVal) => {
                        setTime(newVal);
                        handleChange('time', newVal);
                      }}
                      renderInput={(params) => (
                        <TextField size="small" {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
               
                {/* <div>
                  <div>
                    <Typography>Appointment Admin: </Typography>
                  </div>
                  <div>
                    <Typography color={'secondary'}>
                      {cosmosAdmin ? cosmosAdmin.name : ''}
                    </Typography>
                  </div>
                </div> */}
                {isInternal && (
                  <FormControl>
                    <InputLabel>Status</InputLabel>
                    <Select
                      disabled={!isInternal}
                      fullWidth
                      label="Email"
                      native
                      size="small"
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      {APPOINTMENTSTATUS.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </div>
            </div>
            {record.id > 0 && (
              <div className="flex justify-center p-2 bg-indigo-200 mx-1 lg:mx-4 mt-3 rounded-md font-medium">
                <div className="px-2 text-indigo-600 ">
                  <div>
                    <div>WhatsApp Message Status:</div>
                  </div>
                  {(record.confirmedByCandidate ||
                    record.cancelledByCandidate) && (
                    <div>
                      <div>Student Response:</div>
                    </div>
                  )}
                </div>
                <div>
                  <div>
                    <div className="text-indigo-800">{messageStatus}</div>
                  </div>
                  {(record.confirmedByCandidate ||
                    record.cancelledByCandidate) && (
                    <div>
                      {record.confirmedByCandidate ? (
                        <MyTooltip title="Confirmed by student via WhatsApp">
                          <ThumbUpIcon color="success" />
                        </MyTooltip>
                      ) : (
                        <MyTooltip title="Cancelled by student via WhatsApp">
                          <ThumbDownAltIcon color="error" />
                        </MyTooltip>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <div className="flex justify-between px-4 pb-4 gap-8 w-full">
              <div>
                <Button
                  variant="contained"
                  className="bg-rose-200 hover:text-rose-700 hover:bg-rose-200 text-rose-600 text-lg font-semibold w-32 font-sans lowercase"
                  onClick={handleFormCancel}
                >
                  Cancel
                </Button>
              </div>
              {errors && (
                <div>
                  <Typography color={'error'}>{errors}</Typography>
                </div>
              )}
              <div>
                <LoadingButton
                  disabled={!readyToSave}
                  type="submit"
                  variant="contained"
                  className="bg-indigo-200 hover:text-indigo-800 hover:bg-indigo-200 text-indigo-600 text-lg font-semibold w-32 font-sans lowercase"
                  onClick={save}
                >
                  Save
                </LoadingButton>
              </div>
            </div>
          </DialogActions>
        </>
      </Dialog>
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    role: state.auth.role,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
    isInternal: state.auth.isInternal,
    record: ownProps.record,
    setRecordParent: ownProps.setRecordParent,
    setAddMode: ownProps.setAddMode,
  };
};

//record, setRecordParent, setAddMode
export default connect(mapStateToProps, null)(Appointment);
