import {
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material'
import {styled} from '@mui/material/styles'
import React, {useEffect, useState} from 'react'
import PhotoWidgetCropper from './PhotoWidgetCropper'
import PhotoWidgetDropzone from './PhotoWidgetDropzone'
const PREFIX = 'PhotoUploadWidgetWithCropping'

const classes = {
  circularProgress: `${PREFIX}-circularProgress`,
}

const Root = styled('div')(({theme}) => ({
  [`& .${classes.circularProgress}`]: {
    marginLeft: 0,
    marginRight: theme.spacing(3),
  },
}))

function ButtonComponent(props) {
  const {onClick, loading} = props
  return (
    <Button
      style={{width: '200px', marginTop: 20}}
      className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full'
      variant='contained'
      onClick={onClick}
      disabled={loading}
    >
      {loading && (
        <>
          <CircularProgress size={14} color='secondary' />
          <span style={{marginLeft: 5}}>Uploading</span>
        </>
      )}
      {!loading && 'Upload'}
    </Button>
  )
}

export default function PhotoUploadWidgetWithCropping({loading, uploadPhoto}) {
  const [files, setFiles] = useState([])
  const [cropper, setCropper] = useState()

  function onCrop() {
    if (cropper) {
      cropper.getCroppedCanvas().toBlob((blob) => {
        uploadPhoto(blob, files[0].name)
      }, files[0].type)
    }
  }

  useEffect(() => {
    return () => {
      files.forEach((file) => URL.revokeObjectURL(file.preview))
    }
  }, [files])

  return (
    <Root>
      <Grid container spacing={3}>
        <Grid item>
          <Typography>Step 1 - Add Photo</Typography>
          <PhotoWidgetDropzone setFiles={setFiles} crop={true} />
        </Grid>
        <Grid item>
          <Typography>Step 2 - Resize image (optional)</Typography>
          {files && files.length > 0 && (
            <PhotoWidgetCropper
              setCropper={setCropper}
              imagePreview={files[0].preview}
            />
          )}
        </Grid>
        <Grid item>
          <Typography>Step 3 - Preview and Upload</Typography>
          {files && files.length > 0 && (
            <>
              <div
                className='img-preview'
                style={{minHeight: 200, overflow: 'hidden'}}
              />
              <ButtonGroup
                size='large'
                color='primary'
                aria-label='large outlined primary button group'
              >
                <ButtonComponent
                  onClick={onCrop}
                  loading={loading}
                  classes={classes.circularProgress}
                />
              </ButtonGroup>
            </>
          )}
        </Grid>
      </Grid>
    </Root>
  )
}
