
import { useState } from 'react';

function useDateFormatter() {
    
  const formatDateToISO = (dateString) => {
    if (!dateString || dateString === '') return null; // Handle null or 'null' values

    const parsedDate = new Date(dateString);

    if (isNaN(parsedDate)) {
      // Handle invalid date strings
      return null;
    }

    return parsedDate.toISOString().split('T')[0];
  };

  return { formatDateToISO };
}

export default useDateFormatter;