import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Tabs,
  Tab,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import { connect } from 'react-redux';
import AvatarMui from '../../../Common/Image/AvatarMui';
import Agent from '../../../../api/agent';
import LoadingView from '../../../Common/Loading/LoadingView';
import { Box } from '@mui/system';
import PersonalDetails from './Steps/PersonalDetails';
import WorkDetails from './Steps/WorkDetails';
import GeneralDetails from './Steps/GeneralDetails';
import MySnackbarAlert from '../../../Common/AlertMessage/MySnackbarAlert';
import EmployeeDocuments from './Steps/EmployeeDocuments';

const PREFIX = 'Employee';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
  chip: `${PREFIX}-chip`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  [`& .${classes.chip}`]: {
    marginTop: 10,
    marginRight: 5,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} boxShadow={5}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Employee = ({ emp, setOpenEmployee }) => {
  const [open, setOpen] = useState(true);
  const [tabValue, setTabValue] = useState('general');
  const [loading, setLoading] = useState(true);
  const [employee, setEmployee] = useState(emp);
  const [personal, setPersonal] = useState({});
  const [work, setWork] = useState({});
  const [managers, setManagers] = useState([]);
  const [isFemale, setIsFemale] = useState(false);
  //#snackbar stuff
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [alertMsg, setAlertMsg] = useState();
  //#endregion
  const [submitting, setSubmitting] = useState(false);
  const [jobTitle, setJobTitle] = useState();
  const [emailAddress, setEmailAddress] = useState();

  useEffect(() => {
    Agent.Employees.getEmployee(emp.id)
      .then((emp) => {
        setPersonal(emp.personalDetails);
        setIsFemale(emp.personalDetails && emp.personalDetails.gender == 2);
        setWork(emp.workDetails);
        setEmployee(emp);
        setManagers(emp.managers);
        setJobTitle(emp.workDetails.jobTitle || '');
        setEmailAddress(emp.workDetails.emailAddress || '');
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        debugger;
      });
  }, []);

  function handleClose() {
    setOpen(false);
    setOpenEmployee(false);
  }

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const save = async (record, saveMode) => {
    setSubmitting(true);
    let emp = { ...employee, ...{ saveMode } };

    if (saveMode == 1) {
      emp = {
        ...emp,
        ...{ firstName: record.firstName, lastName: record.lastName },
      };
    } else if (saveMode == 2) {
      emp = {
        ...emp,
        ...{ personalDetails: record },
      };
      setIsFemale(record.gender == 2);
    } else if (saveMode == 3) {
      emp = {
        ...emp,
        ...{ workDetails: record, managers: record.managers },
      };
      setManagers(record.managers);
      setJobTitle(record.jobTitle);
      setEmailAddress(record.emailAddress);
    }
    await Agent.Employees.save(emp)
      .then(() => {
        setAlert();
      })
      .catch((error) => {
        console.log(error);
        setAlert('Failed to update', false);
        debugger;
      });
  };

  const setAlert = (msg = 'Successfully updated', isSuccess = true) => {
    setSubmitting(false);
    setAlertMsg(msg);
    setOpenSuccess(isSuccess);
    setOpenFailure(!isSuccess);
  };

  return loading ? (
    <LoadingView />
  ) : (
    <Root>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperComponent}
        maxWidth="md"
        fullWidth
      >
        <>
          <DialogTitle
            style={{ cursor: 'move', padding: 2 }}
            id="draggable-dialog-title"
          >
            <Grid container justifyContent={'flex-end'}>
              <Grid item>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleClose}
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent style={{ padding: 0 }}>
            <Grid container>
              <Grid item container direction={'column'}>
                <Grid
                  item
                  container
                  style={{
                    backgroundColor: '#EEF3F8',
                    paddingLeft: 20,
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  <Grid item xs="2">
                    <AvatarMui
                      imageUrl={employee.logoUrl}
                      fullName={employee.fullName}
                      size={10}
                    />
                  </Grid>
                  <Grid item xs="10" container direction={'column'}>
                    <Grid item>
                      <Typography
                        color={isFemale ? 'secondary' : 'primary'}
                        variant="h5"
                      >
                        {employee.fullName}
                      </Typography>
                    </Grid>
                    {jobTitle && (
                      <Grid item>
                        <Typography>{jobTitle}</Typography>
                      </Grid>
                    )}
                    {emailAddress && (
                      <Grid item>
                        <Typography>{emailAddress}</Typography>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item container style={{ marginTop: 20 }}>
                  <Grid item xs={3}>
                    <Tabs
                      orientation="vertical"
                      variant="scrollable"
                      value={tabValue}
                      onChange={handleTabChange}
                      className={classes.tabs}
                    >
                      <Tab
                        value="general"
                        {...a11yProps('general')}
                        classes={{ root: classes.selectedTab }}
                        className={classes.tab}
                        label="General"
                      />
                      <Tab
                        value="details"
                        {...a11yProps('details')}
                        classes={{ root: classes.selectedTab }}
                        className={classes.tab}
                        label="Personal Info"
                      />
                      <Tab
                        value="workdetails"
                        {...a11yProps('workdetails')}
                        classes={{ root: classes.selectedTab }}
                        className={classes.tab}
                        label="Work Details"
                      />
                      {/* <Tab
                        value="documents"
                        {...a11yProps('documents')}
                        classes={{ root: classes.selectedTab }}
                        className={classes.tab}
                        label="Documents"
                      /> */}
                    </Tabs>
                  </Grid>
                  <Grid item xs={9}>
                    <TabPanel
                      value={tabValue}
                      index={'general'}
                      style={{ paddingTop: 0 }}
                    >
                      <GeneralDetails
                        details={employee}
                        setDetails={setEmployee}
                        saveInParent={save}
                        submitting={submitting}
                      />
                    </TabPanel>

                    <TabPanel
                      value={tabValue}
                      index={'details'}
                      style={{ paddingTop: 0 }}
                    >
                      <PersonalDetails
                        personalDetails={personal}
                        setPersonalDetails={setPersonal}
                        saveInParent={save}
                        submitting={submitting}
                      />
                    </TabPanel>

                    <TabPanel
                      value={tabValue}
                      index={'workdetails'}
                      style={{ paddingTop: 0 }}
                    >
                      <WorkDetails
                        workDetails={work}
                        setWorkDetails={setWork}
                        managers={managers}
                        setManagers={setManagers}
                        saveInParent={save}
                        submitting={submitting}
                      />
                    </TabPanel>
                    <TabPanel
                      value={tabValue}
                      index={'documents'}
                      style={{ paddingTop: 0 }}
                    >
                      <EmployeeDocuments
                        employeeId={emp.id}
                        // setWorkDetails={setWork}
                        // managers={managers}
                        // setManagers={setManagers}
                        // saveInParent={save}
                        // submitting={submitting}
                      />
                    </TabPanel>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <DialogActions></DialogActions>
          </DialogContent>
        </>
      </Dialog>
      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
        />
      )}
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Employee);
