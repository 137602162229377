/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import EditorDraftJs from '../../Common/Editor/EditorDraftJs';
import Agent from '../../../api/agent';
import { addMinutes, format } from 'date-fns';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import parse from 'html-react-parser';

const SummaryInfo = ({
  handleAddSummaryRecord,
  leadId,
  users,
  appUserDetailsId,
  loadUsers,
}) => {
  const [addNoteMode, setAddNoteMode] = useState(false);
  const [newLeadNote, setLeadNote] = useState([]);
  const [newNote, setNewNote] = useState({
    content: '',
  });
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  // useEffect(() => {
  //   ();
  // }, [loadUsers]);

  useEffect(() => {
    Agent.StudentLeads.getLeadProfile(leadId)
      .then((res) => {
        setLeadNote(res?.studentLeadsNotes);
        //console.log('lead note check', res);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [successMessage]);

  const handleAddNoteClick = () => {
    setAddNoteMode(true);
  };

  const handleAddNote = async () => {
    if (!newNote.content) {
      return;
    }

    const currentDate = new Date().toISOString();

    try {
      const payload = {
        content: newNote.content,
        studentLeadId: leadId,
        sendEmail: false,
        file: '',
        attachmentFileName: '',
        noteType: 2,
      };
      await Agent.Messages.addStudentLeadNote(payload);

      const updatedRecords = [
        {
          content: newNote.content,
          dateSent: currentDate,
          appUserDetailsId: appUserDetailsId,
        },
        ...newLeadNote,
      ];

      handleAddSummaryRecord(updatedRecords);

      setSuccessMessage('Note added!');
      setNewNote({
        content: '',
      });
      setAddNoteMode(false);

      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  const getUserName = (appUserDetailsId) => {
    const user = users.find(
      (user) => user.appUserDetailsId === appUserDetailsId
    );
    return user ? user.name : '';
  };

  return (
    <div id="summary-info" className="bg-gray-100 p-4 rounded-lg">
      <h2 className="text-2xl text-center font-semibold mb-4 text-indigo-600">
        Notes and Summary
      </h2>

      <div>
        {addNoteMode && (
          <div className="mb-4">
            <label className="block font-bold mb-1">Notes</label>
            <EditorDraftJs
              editorOnChange={(html) =>
                setNewNote({
                  ...newNote,
                  content: html,
                })
              }
              height={200}
              placeHolder="Write your notes here"
              html={newNote.content}
            />
          </div>
        )}

        {addNoteMode && (
          <div className="mb-4 flex justify-end">
            <button
              type="button"
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none"
              onClick={handleAddNote}
            >
              Add Note
            </button>
          </div>
        )}

        {successMessage && (
          <div className="text-green-600 font-semibold mt-2">
            {successMessage}
          </div>
        )}

        {!addNoteMode && (
          <button
            type="button"
            onClick={handleAddNoteClick}
            className="bg-blue-500 text-white px-4 mt-2 mb-6 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
          >
            Add Notes
          </button>
        )}

        <div className="space-y-4">
          {newLeadNote
            .filter((item) => item.noteType == 2)
            .slice()
            .reverse()
            .map((record, index) => (
              <div
                key={index}
                className="p-4 bg-white shadow-md rounded-md border border-gray-300 mb-4"
              >
                <div className="flex justify-between">
                  <div className="mb-2 text-blue-700">
                    {getUserName(record?.appUserDetailsId)}
                  </div>
                  <div className="mb-2 text-blue-700">
                    {format(
                      addMinutes(
                        new Date(record.dateSent),
                        -new Date().getTimezoneOffset()
                      ),
                      'MMMM d, yyyy h:mm a'
                    )}{' '}
                  </div>
                </div>
                <div className="mb-2">
                  {/* <strong className="text-gray-800">Content:</strong> */}
                  <div
                    className="text-gray-600"
                    dangerouslySetInnerHTML={{
                      __html: record.content,
                    }}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    departments: state.departments,
    users: state.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryInfo);
