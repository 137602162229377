import { ButtonGroup, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import Agent from '../../../api/agent';

const PREFIX = 'Documents';

const classes = {
  documentContainer: `${PREFIX}-documentContainer`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.documentContainer}`]: {
    borderTop: '1px solid gray',
    // marginLeft: 0,
    // marginRight: 0,
  },
}));

const Documents = ({
  files,
  handleFileDelete,
  agentId,
  studentLeadId,
  applicationId = 0,
  disabled = false,
  sizes = [7, 2],
  softDelete = false,
}) => {
  const [downloading, setDownloading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [fileToDownload, setFileToDownload] = useState();
  const deleteFile = (fileName) => {
    if (softDelete) {
      // this means, this is a new file that hasn't been uploaded to the server yet
      handleFileDelete(fileName);
      return;
    }
    setDeleting(true);
    Agent.CloudFiles.delete(fileName, agentId, studentLeadId, applicationId)
      .then(() => {
        handleFileDelete(fileName);
        setDeleting(false);
      })
      .catch((error) => {
        console.log(error);
        setDeleting(false);
        debugger;
      });
  };
  const downloadFile = async (fileNameLocal, fileName, containerName) => {
    setDownloading(true);
    await Agent.CloudFiles.download(fileNameLocal, fileName, containerName)
      .then((response) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/octet-stream' })
        );
        link.download = fileNameLocal ? fileNameLocal : fileName;
        document.body.appendChild(link);
        link.click();
        setDownloading(false);
        setTimeout(function () {
          window.URL.revokeObjectURL(link);
        }, 200);
      })
      .catch((error) => {
        setDownloading(false);
        console.log(error);
        debugger;
      });
  };

  return (
    <StyledGrid container direction="column" spacing={2}>
      {files.map((file, index) => (
        <Grid item container key={index} className={classes.documentContainer}>
          <Grid item xs={sizes[0]} style={{ paddingTop: 5 }}>
            {file.fileNameLocal}
          </Grid>
          {file.documentTypeDesc && (
            <Grid item xs={sizes[1]} style={{ paddingTop: 5 }}>
              {file.documentTypeDesc}
            </Grid>
          )}
          <Grid item xs={3} container justifyContent="flex-end">
            <Grid item>
              <ButtonGroup
                size="small"
                color="primary"
                aria-label="large outlined primary button group"
              >
                <LoadingButton
                  disabled={file.state == 2}
                  loading={downloading && fileToDownload == file.fileName}
                  onClick={() => {
                    setFileToDownload(file.fileName);
                    downloadFile(
                      file.fileNameLocal,
                      file.fileName,
                      file.containerName
                    );
                  }}
                  startIcon={<CloudDownloadIcon />}
                >
                  Download
                </LoadingButton>

                <LoadingButton
                  disabled={disabled}
                  color="secondary"
                  loading={deleting}
                  startIcon={<DeleteForeverIcon />}
                  onClick={() => deleteFile(file.fileName, file.id)}
                >
                  Delete
                </LoadingButton>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </StyledGrid>
  );
};

export default Documents;
