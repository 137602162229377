import React, { useEffect } from 'react';
import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Countries } from '../../../../../Util/Util';

const CountryFilter = ({ returnValue, clearValue, isDisabled }) => {
  const [selected, setSelected] = useState({
    id: 0,
    name: 'All Countries',
    order: 0,
    code: '',
  });

  useEffect(() => {
    returnValue(selected.id);
    clearValue('');
  }, [selected]);

  useEffect(() => {}, []);

  return (
    <div className="w-60">
      <Listbox disabled={isDisabled} value={selected} onChange={setSelected}>
        <div className="relative z-0 top-0">
          <Listbox.Button
            className={`relative w-full shadow-md cursor-default rounded-lg bg-white py-2.5 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-violet-500 sm:text-sm`}
          >
            <span
              className={`block truncate ${isDisabled ? 'text-gray-400 ' : ''}`}
            >
              {selected.name}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <Listbox.Option
                key={0}
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-violet-100 text-violet-700' : 'text-gray-900'
                  }`
                }
                value={{
                  id: 0,
                  name: 'All Countries',
                  order: 0,
                  code: '',
                }}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {'All Countries'}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-violet-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
              {Countries.filter((c) => c.showAll)
                .sort(function (a, b) {
                  if (a.order < b.order) {
                    return -1;
                  }
                  if (a.order > b.order) {
                    return 1;
                  }
                  return 0;
                })
                .map((country, idx) => (
                  <Listbox.Option
                    key={country.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active
                          ? 'bg-violet-100 text-violet-700'
                          : 'text-gray-900'
                      }`
                    }
                    value={country}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {country.name}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-violet-600">
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default CountryFilter;
