import React, { useState } from 'react';

import { Grid, Fab, CircularProgress } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import useInput from '../../../../Common/Form/use-input';
import MyInput from '../../../../Common/Form/MyInput';

const isOptional = () => true;
const isNotEmptyTrim = (value) => value && value.trim() !== '';
const isNotEmpty = (value) => value && value !== '';
const isNotEmptyNum = (value) => value && value > 0;
const isEmail = (value) => value && value.includes('@');

const GeneralDetails = ({ details, setDetails, saveInParent, submitting }) => {
  const [saving, setSaving] = useState(false);
  const [changed, setChanged] = useState(false);

  const onBlurHandler = (name, value) => {
    setChanged(true);
    details = {
      ...details,
      ...{
        [name]: value,
      },
    };
    setDetails(details);
  };
  const { obj: firstName } = useInput(
    details.firstName,
    isNotEmpty,
    onBlurHandler
  );
  const { obj: lastName } = useInput(
    details.lastName,
    isNotEmpty,
    onBlurHandler
  );
  const save = () => {
    saveInParent(details, 1).then(() => {
      setChanged(false);
    });
  };

  return (
    <Grid container direction={'column'}>
      <Grid item>
        <MyInput
          name="firstName"
          label="First Name"
          value={firstName.value}
          handleChange={firstName.valueChangeHandler}
          handleBlur={firstName.valueInputBlurHandler}
          errorMessage="Valid first name"
          isInvalid={firstName.hasError}
          required
          inputProps={{
            maxLength: 100,
          }}
        />
      </Grid>
      <Grid item>
        <MyInput
          name="lastName"
          label="Last Name"
          value={lastName.value}
          handleChange={lastName.valueChangeHandler}
          handleBlur={lastName.valueInputBlurHandler}
          errorMessage="Valid last name"
          isInvalid={lastName.hasError}
          required
          inputProps={{
            maxLength: 100,
          }}
        />
      </Grid>
      <Grid item container>
        <Grid item>
          <Fab
            size="small"
            variant="extended"
            color="primary"
            style={{ marginRight: 10 }}
            onClick={save}
            disabled={submitting || !changed}
          >
            <DoneIcon />
            {submitting && (
              <>
                <CircularProgress size={14} color="primary" />
                <span style={{ marginLeft: 5 }}>Saving</span>
              </>
            )}
            {!submitting && 'Save'}
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneralDetails;
