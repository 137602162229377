import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const HeadlessModal = ({
  primaryText,
  isModalOpen,
  setModalOpen,
  secondaryText,
  handleChange,
}) => {
  let [isOpen, setIsOpen] = useState(isModalOpen);

  function closeModal() {
    setIsOpen(false);
    setModalOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 " />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title className="text-lg font-semibold leading-6 text-gray-800 text-center">
                    <div className="text-violet-700">{`${primaryText}`}</div>
                    <div className="font-medium text-xl text-gray-800">
                      {secondaryText}
                    </div>
                  </Dialog.Title>
                  <p className="text-lg font-medium text-gray-800 mt-8 text-center">{``}</p>

                  <div className="mt-4 flex justify-center gap-8">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-rose-100 px-7 py-2 text font-semibold text-rose-700 hover:bg-rose-200 focus:outline-none  focus-visible:ring-rose-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-violet-100 px-7 py-2 text font-semibold text-violet-800 hover:bg-violet-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-violet-500 focus-visible:ring-offset-2"
                      onClick={handleChange}
                    >
                      Apply
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default HeadlessModal;
