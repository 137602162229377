import React, { useState, forwardRef, useEffect } from 'react';
import { INTAKES } from '../../../../Util/Constants';
import { Countries, getNext3Years } from '../../../../Util/Util';
import TextField from '@mui/material/TextField/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import Agent from '../../../../api/agent';
import DatePicker from 'react-datepicker';
import * as yup from 'yup';
import {
  FormHelperText,
  FormControl,
  InputLabel,
  Typography,
  MenuItem,
  Select,
  Button,
  Grid,
  Box,
} from '@mui/material';

// VALIDATION
const schema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'Name must be at least 3 characters')
    .max(32, 'First Name must be less than least 32 characters')
    .required('First Name is required'),
  lastName: yup
    .string()
    .min(2, 'Name must be at least 3 characters')
    .max(32, 'Last Name must be less than least 32 characters')
    .required('Last Name is required'),
  dateOfBirth: yup.date().required('Date of Birth is required'),
  citizenship: yup.number().required('Citizenship is required'),
  email: yup.string().email('Email is invalid').required('Email is required'),
  phoneNumber: yup
    .string()
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/,
      'Phone number is not valid'
    )
    .required('Phone Number is required')
    .min(8, 'Phone Number must be at least 8 characters'),
  preferredDestinations: yup
    .array()
    .min(1, 'At least one destination is required')
    .required('Destination is required'),
  intakeMonth: yup.number().required('Intake Month is required'),
  intakeYear: yup.number().required('Intake Year is required'),
  remarks: yup.string().max(500, 'Max 500 Characters'),
});

//GET NEXT 3 YEARS
const Years = getNext3Years();

//STYLING DATE PICKER INPUT FIELD
const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
  <TextField
    required
    id="outlined-basic"
    variant="standard"
    name="dateOfBirth"
    size="large"
    value={value}
    label="Date of Birth"
    className="example-custom-input"
    onClick={onClick}
    ref={ref}
    sx={style.textFieldRight}
  />
));
ExampleCustomInput.displayName = 'ExampleCustomInput';

//ADD QUICK STUDENT COMPONENT STARTS HERE
const AddQuickStudent = ({ setAddQuickStudent,setSuccessMessage,setSuccessOpen }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    citizenship: '',
    email: '',
    AdditionalEmail1: null,
    AdditionalEmail2: null,
    phoneNumber: '',
    intakeMonth: '',
    intakeYear: '',
    remarks: '',
    preferredDestinations: [],
  });
  const [isDisabled, setDisabled] = useState(true);
  const [emailExists, setEmailExists] = useState(false);
  const [validationErrorMsg, setValidationErrorMsg] = useState('');
  const [phoneFieldTouched, setPhoneFieldTouched] = useState(false);
  const [emailFieldTouched, setEmailFieldTouched] = useState(false);

  useEffect(() => {
    schema
      .validate(formData)
      .then(() => setDisabled(false))
      .catch(() => setDisabled(true));
  }, [formData]);

  const handleClose = () => setAddQuickStudent(false);

  // Update the form values
  const handleChange = (e) => {
    setValidationErrorMsg('');
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // handle Email Update & Validation Error Message
  const handleEmailChange = async (value, touched = false) => {
    setEnable();
    try {
      await schema.validateAt('email', { email: value });
      setValidationErrorMsg('');
    } catch (error) {
      setValidationErrorMsg('enter a valid email address');
    }
    setFormData({ ...formData, email: value.trim().toLowerCase() });
    if (touched) setEmailFieldTouched(true);
  };

  // Handle Phone Number Update & Validation Error Message
  const handlePhoneNumber = async (value, touched = false) => {
    try {
      await schema.validateAt('phoneNumber', { phoneNumber: value });
      setValidationErrorMsg('');
    } catch (error) {
      setValidationErrorMsg(error.message);
    }
    setFormData({ ...formData, phoneNumber: value });
    if (touched) setPhoneFieldTouched(true);
  };

  // Update Date
  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dateOfBirth: date,
    });
  };

  //Reset Duplicate Email Error Message
  const setEnable = () => {
    setEmailExists(false);
    // setDisabled(false);
  };

  //Logic for selecting Destinations with max 3 items at a time
  const handleDestinationChange = (event) => {
    const {
      target: { value },
    } = event;

    let destinations = typeof value === 'string' ? value.split(',') : value;
    if (destinations.length > 3) {
      destinations = destinations.slice(0, 3);
    }
    setFormData({
      ...formData,
      preferredDestinations: destinations,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisabled(true);
    try {
      await schema.validate(formData, { abortEarly: false }).then((res) => {
        console.log('Validation succeeded!', res);
        Agent.Students.saveQuickStudent(res)
          .then((res) => {
            console.log('POST succeeded!', res);
            setSuccessMessage('Save Successfully');
            setSuccessOpen(true);
            handleClose();
            setDisabled(false);
          })
          .catch((error) => {
            console.log(error);
            if (error.includes('Email address must be unique'))
              setValidationErrorMsg('Email address must be unique');
            setEmailExists(true);
          });
      });
    } catch (error) {
      console.log('Validation failed.', error);
      setValidationErrorMsg('Validation failed. Please check your inputs');
      setDisabled(false);
    }
  };

  return (
      <Box sx={style.root}>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            item
            spacing={1}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              item
              spacing={1}
              sm={12}
            >
              <Grid item>
                <Typography id="modal-modal-title" variant="h5" component="h2">
                  Student Quick Add Form
                </Typography>
              </Grid>
              <Grid item sx={style.crossBtn}>
              <CloseIcon style={{ width: '25px', height: '25px', cursor: 'pointer' }} onClick={handleClose} />
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <TextField
                required
                id="outlined-basic"
                name="firstName"
                label="First Name"
                variant="standard"
                size="large"
                onChange={handleChange}
                sx={style.textFieldLeft}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                required
                id="outlined-basic"
                label="Last Name"
                name="lastName"
                variant="standard"
                size="large"
                onChange={handleChange}
                sx={style.textFieldRight}
              />
            </Grid>

          <Grid item sm={6}>
            <FormControl variant="standard">
              <TextField
                select
                required
                size="large"
                id="outlined-basic"
                label="Citizenship"
                name="citizenship"
                variant="standard"
                value={formData.citizenship}
                onChange={handleChange}
                // helperText="Please select your country"
                sx={style.textFieldLeft}
              >
                {Countries.filter((item)=>item.id!=0).map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid item sm={6}>
            <DatePicker
              // id="outlined-basic"
              // variant="standard"
              // size="large"
              // label="Date of Birth"
              minDate={new Date('01-01-1950')}
              maxDate={
                new Date(new Date().setDate(new Date().getDate() - 3650))
              }
              popperClassName={classes.reactDatepickerPopper}
              // locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              // handleChange={dateOfBirth}
              onChange={handleDateChange}
              sx={style.textFieldLeft}
              selected={formData.dateOfBirth}
              placeholderText="Date of Birth"
              // showMonthDropdown
              scrollableYearDropdown
              customInput={<ExampleCustomInput />}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              // error={emailExists}
              // helperText={emailExists && 'Email Id already exists.'}
              required
              size="large"
              type="email"
              name="email"
              label="Email"
              variant="standard"
              id="outlined-basic"
              onBlur={(e) =>
                !emailFieldTouched && handleEmailChange(e.target.value, true)
              }
              onChange={(e) =>
                emailFieldTouched && handleEmailChange(e.target.value)
              }
              sx={style.textFieldLeft}
            />
          </Grid>
          <Grid item sm={6}>
            <TextField
              required
              size="large"
              name="phoneNumber"
              variant="standard"
              id="outlined-basic"
              label="Phone Number"
              onBlur={(e) =>
                !phoneFieldTouched && handlePhoneNumber(e.target.value, true)
              }
              onChange={(e) =>
                phoneFieldTouched && handlePhoneNumber(e.target.value)
              }
              sx={style.textFieldRight}
            />
          </Grid>
          <Grid item sm={6}>
            <FormControl required sx={style.dropDown} variant="standard">
              <InputLabel id="demo-multiple-name-label">
                Preferred Destination
              </InputLabel>
              <Select
                variant="standard"
                labelId="demo-multiple-name-label"
                id="outlined-basic"
                multiple
                name="preferredDestinations"
                value={formData.preferredDestinations}
                onChange={handleDestinationChange}
                // input={<OutlinedInput label="Preferred Destinations" />}
                MenuProps={MenuProps}
              >
                {Countries.filter((c) => c.showAll).map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.short || country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormHelperText id="component-helper-text">{` `}</FormHelperText>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            item
            spacing={1}
            sm={6}
          >
            <Grid item sm={6} sx={style.subTextField}>
              <FormControl variant="standard">
                <TextField
                  select
                  required
                  size="large"
                  label="Month"
                  name="intakeMonth"
                  variant="standard"
                  id="outlined-basic"
                  onChange={handleChange}
                  value={formData.intakeMonth}
                  // helperText="Please select your intake Month"
                  sx={style.subTextField}
                >
                  {INTAKES.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.typeShort}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText id="component-helper-text">
                  Intake Month
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              <FormControl sx={style.subTextField2}>
                <TextField
                  select
                  required
                  size="large"
                  id="outlined-basic"
                  label="Year"
                  name="intakeYear"
                  variant="standard"
                  value={formData.intakeYear}
                  onChange={handleChange}
                >
                  {Years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </TextField>
                <FormHelperText id="component-helper-text">
                  {`Intake Year `}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <TextField
              id="outlined-basic"
              label="Remarks"
              name="remarks"
              variant="outlined"
              multiline
              rows={4}
              size="large"
              onChange={handleChange}
              sx={style.remarks}
            />
          </Grid>
        </Grid>
        <div style={style.buttonSection}>
          <div style={style.phoneError}>{`${validationErrorMsg}`}</div>
          <div>
            <Button
              sx={style.closeBtn}
              variant="contained"
              color="secondary"
              size="large"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              //sx={style.saveBtn}
              className='bg-cyan-500 hover:bg-cyan-400'
              variant="contained"
              color="secondary"
              size="large"
              type="submit"
              disabled={isDisabled}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </Box>
  );
};

export default AddQuickStudent;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const style = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'end ',
    alignItems: 'end',
    justifyContent: 'end',
    borderRadius: '8px',
    marginTop: '10px',
    paddingRight: '20px',
  },
  textFieldLeft: {
    width: '330px',
    paddingRight: '20px',
    backgroundColor: '',
  },
  textFieldRight: {
    width: '330px',
    // paddingRight: '20px',
    backgroundColor: '',
  },
  subTextField: {
    width: '160px',
    backgroundColor: '',
  },
  subTextField2: {
    width: '160px',
    backgroundColor: '',
  },
  remarks: {
    width: '671px',
    marginTop: '20px',
  },
  dropDown: {
    width: '330px',
    paddingRight: '20px',
  },
  crossBtn: {
    cursor: 'pointer',
    color: '#FF9F9F',
    transition: '0.3s',
    '&:hover': {
      color: '#FF597B',
    },
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center ',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  closeBtn: {
    cursor: 'pointer',
    px: '8px',
    mx: '18px',
    backgroundColor: '#fff',
    boxShadow: '0',
    color: '#393E46',
    fontWeight: 'bold',
    borderRadius: '8px',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#222222',
      borderColor: '#fff',
      boxShadow: '0',
    },
  },
  saveBtn: {
    cursor: 'pointer',
    backgroundColor: '#E80F88',
    borderRadius: '24px',
    marginTop: '20px',
    '&:hover': {
      backgroundColor: '#AF0171',
      color: '#fff',
      borderColor: '#AF0171',
    },
  },
  phoneError: {
    paddingTop: '1rem',
    color: '#E80F88',
  },
};

const PREFIX = 'MyDate';

const classes = {
  reactDatepickerPopper: `${PREFIX}-reactDatepickerPopper`,
  error: `${PREFIX}-error`,
};

const Root = styled('div')(() => ({
  [`& .${classes.reactDatepickerPopper}`]: {
    zIndex: 3,
  },
  [`& .${classes.error}`]: {
    color: 'red',
  },
}));