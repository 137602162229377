import React, {useState, useContext, useEffect} from 'react'
import {styled} from '@mui/material/styles'
import {connect} from 'react-redux'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import Button from '@mui/material/Button'
import {Checkbox, FormControlLabel, SvgIcon, Typography} from '@mui/material'
import {green} from '@mui/material/colors'
import * as Yup from 'yup'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import CSSTransition from 'react-transition-group/CSSTransition'
import styles from '../../StudentRegistration/CSS/registration.module.css'
import {Formik, Form, ErrorMessage} from 'formik'
import RegisterContext from '../../../../context/register-context'
import SubjectSelection from '../../../Common/Widgets/SubjectSelection'

const Step2Subjects = ({goNext}) => {
  const [pickSubjectMode, setPickSubjectMode] = useState(false)
  const registerContext = useContext(RegisterContext)
  const [selectedSubjects, setSelectedSubjects] = useState(
    registerContext.subjects
  )

  useEffect(() => {
    let temp = []
    registerContext.subjects.forEach((item) => {
      temp.push(item.name)
    })
    setSelectedSubjects(temp)
  }, [])

  const setSelectedSubjectsParents = (items) => {
    let temp = []
    items.forEach((item) => {
      temp.push(item.name)
    })
    setSelectedSubjects(temp)
  }

  return (
    <>
    <div className="flex items-top justify-center min-h-screen min-w-screen">
    <div className="flex justify-center items-top ">
     
      <Grid >
        <Grid container spacing={1} >
          <Grid item xs={12}  textAlign={'center'}>
            {' '}
            <Typography className={styles.question}>Pick up to 3 subjects</Typography>
          </Grid>

          <Grid item xs={12}>
            <Button
              style={{textTransform: 'none'}}
              size='large'
              fullWidth
              variant={selectedSubjects.length == 0 ? 'contained' : 'outlined'}
              className={
                selectedSubjects.length == 0
                  ? 'bg-rose-400 hover:bg-rose-500'
                  : 'hover:bg-sky-50'
              }
              onClick={() => setPickSubjectMode(true)}
            >
              {selectedSubjects.length == 0
                ? 'Pick subjects'
                : selectedSubjects.join(', ')}
            </Button>
          </Grid>
          {selectedSubjects.length > 0 && (
            <Grid item xs={12}>
              <Button
                fullWidth
                variant='contained'
                className='bg-sky-400 hover:bg-sky-500'
                onClick={goNext}
              >
                Next
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
     
     </div>
     </div>
      {pickSubjectMode && (
        <SubjectSelection
          cancel={setPickSubjectMode}
          title='Pick Subjects'
          setSelectedSubjectsParents={setSelectedSubjectsParents}
          goNext={goNext}
        />
      )}
    </>
  )
}

export default Step2Subjects
