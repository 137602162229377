/* eslint-disable jsx-a11y/no-autofocus */
import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Divider, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const ConfirmationModal = ({
  open,
  cancel,
  confirmText = 'Confirm',
  confirm,
  content,
  email,
  errors = [],
  loading = false,
}) => {
  const handleClose = () => {
    cancel(false);
  };
  const handleConfirm = () => {
    confirm();
  };
  return (
    <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <Typography
          variant="h6"
          align="center"
          color={errors.length == 0 ? 'secondary' : 'error'}
        >
          {errors.length == 0 ? confirmText : 'Missing Info!'}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {errors.length == 0 ? (
          <>
          <Typography color="primary">{content}</Typography>
          {email &&  <Typography color="secondary">{email}</Typography> }
          </>

        ) : (
          <>
            <Typography color="error">
              Please resolve the following errors and try again
            </Typography>
            <span>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </span>
          </>
        )}
      </DialogContent>
      <DialogActions>
        {errors.length == 0 ? (
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button autoFocus onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <LoadingButton
                className='bg-sky-400 hover:bg-sky-500 text-white'
                loading={loading}
                color='secondary'
                onClick={handleConfirm}
                variant='contained'
              >
                {confirmText}
              </LoadingButton>
            </Grid>
          </Grid>
        ) : (
          <Button onClick={handleClose}  className='bg-cyan-700 text-white hover:bg-cyan-600'>
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;