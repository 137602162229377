/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import * as actions from '../../../store/actions/index';
import {
  LicenseInfo,
  DataGridPro,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Snackbar,
  TextField,
} from '@mui/material';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // the locale you want
registerLocale('en-GB', enGB); // register it with the name you want
import 'react-datepicker/dist/react-datepicker.css';
import { addHours, addMonths, startOfDay } from 'date-fns';
import LoadingView from '../../Common/Loading/LoadingView';
import { MUI_LICENSE_KEY, Templates_TYPE } from '../../../Util/Constants';
import { Countries } from '../../../Util/Util';
import { useNavigate } from 'react-router';
// eslint-disable-next-line import/default
import Agent from '../../../api/agent';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { Block, CheckCircle } from '@mui/icons-material';
function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {show && <GridToolbarColumnsButton />}
      {hide && <GridToolbarFilterButton />}
      <GridToolbarDensitySelector />
      {hide && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

const TemplateList = ({ users, loadUsers, isInternal }) => {
  const [activityLoaded, setStudentLoaded] = useState(false);
  const [startDate, setStartDate] = useState(addMonths(new Date(), -1));
  const [endDate, setEndDate] = useState(new Date());
  const [templateType, setTemplateType] = useState(0);
  const [templateId, setTemplateId] = useState('');
  const [templateRowList, settemplateRowList] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [enableModalOpen, setEnableModalOpen] = useState(false);
  const [disableTemplateId, setDisableTemplateId] = useState(null);
  const [enableTemplateId, setEnableTemplateId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [pageSize, setPageSize] = useState(100);

  const navigate = useNavigate();
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', page.toString());
    params.append('pageSize', pageSize.toString());
    // params.append('StartDate',  new Date(startDate).toDateString());
    // params.append('EndDate', new Date(endDate).toDateString());

    if (filter) {
      if (startDate) {
        params.append('StartDate', new Date(startDate).toDateString());
      }
    } else {
      params.append('StartDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (endDate) {
        params.append('EndDate', new Date(endDate).toDateString());
      }
    } else {
      params.append('EndDate', new Date().toDateString());
    }

    params.append('TemplateType', templateType.toString());
    params.append('TemplateId', templateId);

    return params;
  };

  const handleCreateTemplate = () => {
    navigate('/createtemplate');
  };

  const handlePageChange = (params) => {
    setPage(params + 1);
  };
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1); // Reset page when changing page size
  };

  const getRows = (rows) => {
    let templateRows = rows.map((template) => ({
      id: template.id,
      templateId: template.templateId,
      templateName: template.templateName,
      templateCategory: template.templateCategory,
      templateType: template.templateType,
      isDropdown: template.isDropdown,
      isInactive: template.isInactive,
      isWhatsApp: template.isWhatsApp,
      countryId: template.countryId,
      createdBy: template.createdBy,
      createdOn: template.createdOn,
      updatedBy: template.updatedBy,
      lastUpdatedOn: template.lastUpdatedOn,
    }));
    settemplateRowList(templateRows);
    setStudentLoaded(true);
    setLoading(false);
  };

  const handleDisableTemplateConfirm = () => {
    Agent.Communication.disableTemplate(disableTemplateId)
      .then((response) => {
        handleSnackbarOpen('success', 'Template disabled successfully.');

        settemplateRowList((prevtemplateRowList) =>
          prevtemplateRowList.filter(
            (template) => template.id !== disableTemplateId
          )
        );

        console.log(response);
      })
      .catch((error) => {
        handleSnackbarOpen(
          'error',
          'Failed to disable template. Please try again.'
        );
        console.error(error);
      });
    setModalOpen(false);
  };

  const handleEnableTemplateConfirm = () => {
    Agent.Communication.enableTemplate(enableTemplateId)
      .then((response) => {
        handleSnackbarOpen('success', 'Template Enable successfully.');

        settemplateRowList((prevtemplateRowList) =>
          prevtemplateRowList.filter(
            (template) => template.id !== enableTemplateId
          )
        );

        console.log(response);
      })
      .catch((error) => {
        handleSnackbarOpen(
          'error',
          'Failed to enable template. Please try again.'
        );
        console.error(error);
      });
    setEnableModalOpen(false);
  };

  useEffect(() => {
    if (isInternal) {
      if (users.length === 0) {
        loadUsers()
          .then(() => {
            getData(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getData(true);
      }
    } else {
      getData(true);
    }
  }, [users, page]);

  const getData = (filter = false) => {
    setLoading(true);
    Agent.ManageTemplats.getTemplateList(getParams(filter))
      .then((res) => {
        getRows(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetFilter = () => {
    setStartDate(addMonths(new Date(), -1));
    setEndDate(new Date());
    setTemplateType(0);
    setTemplateId('');
    getData(false);
  };

  let columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      hide: true,
    },
    {
      field: 'templateId',
      headerName: 'Template ID',
      hide: true,
      width: 250,
      align: 'left',
      headerAlign: 'center',
    },
    {
      field: 'templateName',
      headerName: 'Template Name',
      width: 250,
      align: 'left',
      headerAlign: 'center',
      renderCell: (params) => (
        <Link to={`/templates/${params.row.id}`}>{params.value}</Link>
      ),
    },

    // {
    //   field: 'templateCategory',
    //   headerName: 'Template Category',
    //   width: 200,
    //   align: 'center',
    //   headerAlign: 'center',
    // },
    {
      field: 'templateType',
      headerName: 'Template Type',
      width: 250,
      align: 'left',
      headerAlign: 'center',
      valueGetter: (params) => {
        const tempType = params.row.templateType;
        const user = Templates_TYPE.find((temp) => temp.id == tempType);
        return user ? user.type : '';
      },
    },
    {
      field: 'isDropdown',
      headerName: 'Is Dropdown',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },

    {
      field: 'isInactive',
      headerName: 'Active Status',
      headerAlign: 'center',
      align: 'center',
      width: 150,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {params.row.isInactive ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Block color="error" />
              <span
                style={{ marginLeft: 8, color: '#f44336', fontWeight: 'bold' }}
              >
                Disabled
              </span>
            </div>
          ) : (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CheckCircle color="success" />
              <span
                style={{ marginLeft: 8, color: '#4caf50', fontWeight: 'bold' }}
              >
                Enabled
              </span>
            </div>
          )}
        </div>
      ),
    },

    {
      field: 'isWhatsApp',
      headerName: 'Is WhatsApp',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'countryId',
      headerName: 'Country',
      width: 170,
      hide: false,
      valueGetter: (params) => {
        const getCountryId = params.row.countryId;
        const countryName = Countries.find(
          (country) => country.id == getCountryId
        );
        return countryName ? countryName.name : null;
      },
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 200,
      hide: false,
      valueGetter: (params) => {
        const getUserId = params.row.createdBy;
        const userName = users.find((user) => user.id == getUserId);
        return userName ? userName.name : null;
      },
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      width: 200,
      hide: false,
      valueGetter: (params) => {
        const getUserId = params.row.updatedBy;
        const userName = users.find((user) => user.id == getUserId);
        return userName ? userName.name : null;
      },
    },
    {
      field: 'lastUpdatedOn',
      headerName: 'Updated On',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'disableTemplate',
      headerName: 'Disable Template',
      width: 130,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Button
          variant="contained"
          className="bg-red-700"
          onClick={() => {
            setDisableTemplateId(params.row.id);
            setModalOpen(true);
          }}
        >
          Disable
        </Button>
      ),
    },
    {
      field: 'enableTemplate',
      headerName: 'Enable Template',
      width: 130,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Button
          variant="contained"
          className="bg-green-600"
          onClick={() => {
            setEnableTemplateId(params.row.id);
            setEnableModalOpen(true);
          }}
        >
          Enable
        </Button>
      ),
    },
  ];

  return activityLoaded ? (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item container spacing={1}>
          <Grid item xs={2}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              // onChange={(date) => setStartDate(date || new Date())}
              selected={(startDate && new Date(startDate)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setStartDate(newVal);
              }}
              customInput={
                <TextField
                  label="Start Date"
                  fullWidth
                  value={startDate}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>

          <Grid item xs={2}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              // selected={endDate}
              selected={(endDate && new Date(endDate)) || null}
              customInput={
                <TextField
                  label="End Date"
                  fullWidth
                  value={endDate}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>

          <Grid item xs={2}>
            <FormControl>
              <InputLabel>Template Type</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="Template Type"
                value={templateType}
                onChange={(e) => setTemplateType(e.target.value)}
              >
                <option value={0}>All Types</option>
                {Templates_TYPE.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.type}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                label="Template Id"
                variant="outlined"
                size="small"
                value={templateId}
                onChange={(e) => setTemplateId(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container spacing={3}>
          <Grid item xs={2}>
            <Button
              fullWidth
              onClick={() => getData(true)}
              variant="contained"
              className="bg-sky-400 hover:bg-sky-500"
            >
              Apply Filters
            </Button>
          </Grid>

          <Grid item xs={2}>
            <Button fullWidth onClick={resetFilter} variant="outlined">
              Reset Filters
            </Button>
          </Grid>
        </Grid>

        <Grid item container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              fullWidth
              onClick={handleCreateTemplate}
              variant="contained"
              className="bg-sky-700"
            >
              Create Template
            </Button>
          </Grid>
        </Grid>

        <Grid item style={{ height: 900, width: '100%' }}>
          <DataGridPro
            paginationMode="client"
            pagination
            pageSize={pageSize}
            rowCount={totalItems}
            page={page - 1}
            pageSizeOptions={[25, 50, 100]}
            onPageChange={handlePageChange}
            rows={templateRowList}
            columns={columns}
            loading={loading}
            density="compact"
            rowHeight={70}
            components={{
              Toolbar: () => CustomToolbar(isInternal),
            }}
            onPageSizeChange={(newPageSize) =>
              handlePageSizeChange(newPageSize)
            }
          />
        </Grid>
      </Grid>

      <Dialog open={enableModalOpen} onClose={() => setEnableModalOpen(false)}>
        <DialogTitle className='text-green-700'>Enable Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to enable this template?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button onClick={() => setEnableModalOpen(false)}>Cancel</Button>
          <Button onClick={handleEnableTemplateConfirm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle className='text-red-700'>Disable Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to disable this template?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button onClick={handleDisableTemplateConfirm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        <Alert
          elevation={16}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }} // Set the width to 100% to center the content
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  ) : (
    <LoadingView />
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateList);
