import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Divider,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import LinearIndeterminate from '../../../Common/Progress/LinearIndeterminate';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useInput from '../../../Common/Form/use-input';
import MySelectInput from '../../../Common/Form/MySelectInput';
import MyInputField from '../../../Common/Form/MyInputField';
import { connect } from 'react-redux';
import Agent from '../../../../api/agent';

const isOptional = () => true;
const isNotEmptyTrim = (value) => value.match('');
const isNotEmpty = (value) => value && value !== '';
const isNotEmptyNum = (value) => value && value > 0;
const isEmail = (value) => value && value.includes('@');

const PREFIX = 'Add';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const SendContract = ({
  agentId,
  setRecordAddMode,
  getAgreementsParent,
  sender,
  existingInfo,
}) => {
  const [open, setOpen] = useState(true);
  const [sending, setSending] = useState(false);
  const [isInValid, setIsInValid] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [info, setInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const onChangeHandler = (name, value) => {
    setInfo({
      ...info,
      ...{
        [name]: value.trim(),
      },
    });
  };
  useEffect(() => {
    setIsInValid(
      sending ||
        !info.id ||
        !isEmail(info.partnerEmail) ||
        !isEmail(info.tclEmail) ||
        !isEmail(info.cc1Email) ||
        !info.fullName ||
        !info.position ||
        !info.phone
    );
  }, [info]);
  const { obj: id } = useInput(
    existingInfo?.id,
    isNotEmpty,
    null,
    onChangeHandler
  );
  const { obj: partnerEmail } = useInput(
    existingInfo?.partnerEmail || '',
    isEmail,
    null,
    onChangeHandler
  );
  const { obj: tclEmail } = useInput(
    existingInfo?.tclEmail || '',
    isEmail,
    null,
    onChangeHandler
  );
  const { obj: cc1Email } = useInput(
    existingInfo?.cc1Email || '',
    isEmail,
    null,
    onChangeHandler
  );
  const { obj: cc2Email } = useInput(
    existingInfo?.cc2Email || '',
    isEmail,
    null,
    onChangeHandler
  );
  const { obj: cc3Email } = useInput(
    existingInfo?.cc3Email || '',
    isEmail,
    null,
    onChangeHandler
  );
  const { obj: fullName } = useInput(
    existingInfo?.fullName || '',
    isNotEmptyTrim,
    null,
    onChangeHandler
  );
  const { obj: position } = useInput(
    existingInfo?.position || '',
    isNotEmptyTrim,
    null,
    onChangeHandler
  );
  const { obj: email } = useInput(
    existingInfo.email || existingInfo.partnerEmail || '',
    isEmail,
    null,
    onChangeHandler
  );
  const { obj: phone } = useInput(
    existingInfo.phone || '',
    isNotEmptyTrim,
    null,
    onChangeHandler
  );

  const [loadingTemplates, setLoadingTemplates] = useState(true);

  useEffect(() => {
    setLoadingTemplates(true);
    Agent.Agents?.getTemplates()
      .then((records) => {
        let temp = [];
        records?.forEach((template) => {
          temp.push({
            ...records,
            name: template?.template_title,
            id: template?.template_fingerprint,
          });
        });
        setTemplates(temp);
        setLoadingTemplates(false);
      })
      .catch((error) => {
        console.log(`issue with getTemplates: ${error}`);
        setLoadingTemplates(false); // Set loading state to false in case of error
      });
  }, []);

  function handleClose() {
    setOpen(false);
    setRecordAddMode(false);
  }

  const send = () => {
    setSending(true);
    const contractInfo = { ...info, email: info.email || info.partnerEmail };
    Agent.Agents.sendAgreement({
      ...contractInfo,
      agentId,
      sender,
    })
      .then(() => {
        getAgreementsParent();
        handleClose();
      })
      .catch((error) => {
        console.log(error);
        handleClose();
        debugger;
      });
  };

  const remind = () => {
    setSending(true);
    Agent.Agents.sendAgreementReminder(existingInfo.envelope_fingerprint)
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        console.log(error);
        handleClose();
        debugger;
      });
  };

  return (
    <StyledDialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      aria-labelledby="draggable-dialog-title"
      PaperComponent={PaperComponent}
      fullWidth
      maxWidth="md"
    >
      <>
        <DialogTitle
          style={{ cursor: 'move', minWidth: 600 }}
          id="draggable-dialog-title"
        >
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h5" color="primary" align="center">
                {sending
                  ? 'Please wait....'
                  : 'Send Partner Agreement for Signing'}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleClose}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider />
        </DialogTitle>
        <DialogContent style={{ overflowY: 'hidden' }}>
          <Grid container direction="column" spacing={3}>
            {sending && (
              <Grid item>
                <LinearIndeterminate />
              </Grid>
            )}
            {/* {!loading && (
              <Grid item container style={{ marginTop: 5 }}>
                <Grid item xs={3}>
                  <Typography color={'primary'}>
                    Select your template
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <MySelectInput
                    options={
                      templates.length > 0
                        ? templates
                        : [{ name: 'Loading...', id: '' }]
                    } //  'Loading...' if templates are empty
                    name="id"
                    label="Agreement Template"
                    value={id.value}
                    handleChange={id.valueChangeHandler}
                    handleBlur={id.valueInputBlurHandler}
                    errorMessage="Template is invalid"
                    isInvalid={false}
                    required
                  />
                </Grid>
              </Grid>
            )} */}

            {loadingTemplates ? (
              <Typography className="bg-orange-700">Loading templates...</Typography>
            ) : (
              <Grid item container style={{ marginTop: 5 }}>
                <Grid item xs={3}>
                  <Typography color={'primary'}>
                    Select your template
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <MySelectInput
                    options={
                      templates.length > 0
                        ? templates
                        : [{ name: 'Loading...', id: '' }]
                    } //  'Loading...' if templates are empty
                    name="id"
                    label="Agreement Template"
                    value={id.value}
                    handleChange={id.valueChangeHandler}
                    handleBlur={id.valueInputBlurHandler}
                    errorMessage="Template is invalid"
                    isInvalid={false}
                    required
                  />
                </Grid>
              </Grid>
            )}

            <Grid item container justifyContent={'space-between'}>
              <Grid item xs={5} container direction={'column'}>
                <Grid item>
                  <Typography color={'primary'} variant={'subtitle2'}>
                    Partner Party
                  </Typography>
                </Grid>
                <Grid item>
                  <MyInputField
                    disabled={existingInfo?.id}
                    obj={partnerEmail}
                    label="Email Address (double check spelling)"
                    name="partnerEmail"
                    handleChange={partnerEmail.valueChangeHandler}
                    handleBlur={partnerEmail.valueInputBlurHandler}
                    errorMessage="Email is invalid"
                    isInvalid={false}
                    required
                  />
                </Grid>
                <Grid item>
                  <MyInputField
                    disabled={existingInfo?.id}
                    obj={fullName}
                    label="Partner Full Name"
                    name="fullName"
                    handleChange={fullName.valueChangeHandler}
                    handleBlur={fullName.valueInputBlurHandler}
                    errorMessage="Name is invalid"
                    isInvalid={false}
                    required
                  />
                </Grid>
                <Grid item>
                  <MyInputField
                    disabled={existingInfo?.id}
                    obj={position}
                    label="Position"
                    name="position"
                    handleChange={position.valueChangeHandler}
                    handleBlur={position.valueInputBlurHandler}
                    errorMessage="Position is invalid"
                    isInvalid={false}
                    required
                  />
                </Grid>
                <Grid item>
                  <MyInputField
                    disabled={existingInfo?.id}
                    obj={email}
                    label="Partner Email Address"
                    name="email"
                    handleChange={email.valueChangeHandler}
                    handleBlur={email.valueInputBlurHandler}
                    errorMessage="Email is invalid"
                    isInvalid={false}
                  />
                </Grid>
                <Grid item>
                  <MyInputField
                    disabled={existingInfo?.id}
                    obj={phone}
                    label="Partner Phone Number"
                    name="phone"
                    handleChange={phone.valueChangeHandler}
                    handleBlur={phone.valueInputBlurHandler}
                    errorMessage="Phone is invalid"
                    isInvalid={false}
                    required
                  />
                </Grid>
              </Grid>
              <Grid item container xs={5} direction={'column'}>
                <Grid item>
                  <Typography color={'secondary'} variant={'subtitle2'}>
                    TCL Global Party
                  </Typography>
                </Grid>
                <Grid item>
                  <MyInputField
                    disabled={existingInfo?.id}
                    obj={tclEmail}
                    label="Email Address (double check spelling)"
                    name="tclEmail"
                    handleChange={tclEmail.valueChangeHandler}
                    handleBlur={tclEmail.valueInputBlurHandler}
                    errorMessage="TCL email is invalid"
                    isInvalid={false}
                    required
                  />
                </Grid>
                <Grid item>
                  <MyInputField
                    disabled={existingInfo?.id}
                    obj={cc1Email}
                    label="First CC Email (double check spelling)"
                    name="cc1Email"
                    handleChange={cc1Email.valueChangeHandler}
                    handleBlur={cc1Email.valueInputBlurHandler}
                    errorMessage="First CC email is invalid"
                    isInvalid={false}
                    required
                  />
                </Grid>
                <Grid item>
                  <MyInputField
                    disabled={existingInfo?.id}
                    obj={cc2Email}
                    label="Second CC Email (double check spelling)"
                    name="cc2Email"
                    handleChange={cc2Email.valueChangeHandler}
                    handleBlur={cc2Email.valueInputBlurHandler}
                    errorMessage="Second CC email is invalid"
                    isInvalid={false}
                  />
                </Grid>
                <Grid item>
                  <MyInputField
                    disabled={existingInfo?.id}
                    obj={cc3Email}
                    label="Third CC Email (double check spelling)"
                    name="cc3Email"
                    handleChange={cc3Email.valueChangeHandler}
                    handleBlur={cc3Email.valueInputBlurHandler}
                    errorMessage="Third CC email is invalid"
                    isInvalid={false}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button onClick={handleClose}>Cancel</Button>
            </Grid>
            {existingInfo.id && !existingInfo.completed ? (
              <Grid item>
                <LoadingButton
                  loading={sending}
                  className="bg-sky-400 hover:bg-sky-500"
                  onClick={remind}
                >
                  Send Reminder
                </LoadingButton>
              </Grid>
            ) : (
              <Grid item>
                <LoadingButton
                  loading={sending}
                  disabled={isInValid}
                  className="bg-sky-400 hover:bg-sky-500"
                  onClick={send}
                >
                  Send Agreement
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </DialogActions>
      </>
    </StyledDialog>
  );
};

const mapStateToProps = (state) => {
  return {
    sender: state.auth.name,
  };
};

export default connect(mapStateToProps, null)(SendContract);
