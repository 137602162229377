import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import MyTextInput from '../../../../Common/Form/MyTextInput';
import { v4 as uuid } from 'uuid';
import Agent from '../../../../../api/agent';
import CloseIcon from '@mui/icons-material/Close';
import StudentContext from '../../../../Students/Context/student-context';
import MySelectInputFormik from '../../../../Common/Form/MySelectInputFormik';
import { QUALIFICATIONS } from '../../../../../Util/Constants';
import Draggable from 'react-draggable';
import LinearIndeterminate from '../../../../Common/Progress/LinearIndeterminate';
import MyDate from '../../../../Common/Form/MyDate';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { IconButton } from '@mui/material';

const PREFIX = 'Add';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Add = ({
  record,
  langRecords,
  setLangRecordsParent,
  setRecordAddMode,
  id,
  setStudentIdParent,
  isLocked,
}) => {
  let studentContext = useContext(StudentContext);
  const [open, setOpen] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [showDescription, setShowDescription] = useState(
    record.qualification == 7 // others category
  );
  function handleClose() {
    setOpen(false);
    setRecordAddMode(false);
  }

  const handleFormCancel = () => {
    setOpen(false);
    setRecordAddMode(false);
  };

  const validationSchema = Yup.object({
    qualification: Yup.string().required('Qualification is required'),
    description: Yup.string()
      .nullable()
      .when('qualification', {
        is: '7',
        then: () =>
          Yup.string()
            .required('Qualification name is required')
            .min(3, 'Must be 3 characters or more')
            .max(50, 'Must be 50 characters or less')
            .typeError('Qualification name is required'),
      }),
    examDate: Yup.date().required('Exam date is required').nullable(),
    band: Yup.string()
      .required('Band is requred')
      .nullable()
      .typeError('Invalid input'),
    reading: Yup.number()
      .required('Reading is requred')
      .nullable()
      .typeError('Invalid input'),
    speaking: Yup.number()
      .required('Speaking is requred')
      .nullable()
      .typeError('Invalid input'),
    writing: Yup.number()
      .required('Writing is requred')
      .nullable()
      .typeError('Invalid input'),
    listening: Yup.number()
      .required('Listening is requred')
      .nullable()
      .typeError('Invalid input'),
  });
  return (
    <Root>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperComponent}
        maxWidth="sm"
        fullWidth
      >
        <>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h6" color="primary" align="center">
                  Language
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleFormCancel}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Divider />
          </DialogTitle>
          <DialogContent>
            <Formik
              enableReinitialize
              initialValues={record}
              onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);
                let newRecords = [];
                if (record.id == 'new') {
                  newRecords = langRecords.concat({
                    ...values,
                    ...{ id: uuid(), order: langRecords.length + 1 },
                  });
                } else {
                  langRecords.forEach((item) => {
                    if (item.id === record.id) {
                      newRecords.push(values);
                    } else {
                      newRecords.push(item);
                    }
                  });
                }
                var studentData = {
                  saveStudentDto: {
                    student: {
                      id: id,
                      languageRecords: newRecords,
                    },
                    agentAppUserId: studentContext.agentAppUserId,
                    createdByAppUserId: studentContext.createdByAppUserId,
                    saveMode: 2,
                  },
                };
                setUpdating(true);
                Agent.Students.saveStudent(studentData)
                  .then(() => {
                    if (id > 0) {
                      setStudentIdParent(id);
                      setLangRecordsParent(newRecords);
                    }
                    setUpdating(false);
                    handleFormCancel();
                  })
                  .catch((error) => {
                    setUpdating(false);
                    console.log(error);
                    debugger;
                    //setProgressBar(false);
                  });
              }}
              validationSchema={validationSchema}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset,
                  isValid,
                  setFieldValue,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <Grid item container spacing={3}>
                      <Grid
                        item
                        xs={6}
                        container
                        direction="column"
                        spacing={2}
                      >
                        <Grid item style={{ marginTop: 5 }}>
                          <MySelectInputFormik
                            fullWidth
                            label="Qualification"
                            name="qualification"
                            placeholder="Select Qualification"
                            items={QUALIFICATIONS}
                            size="small"
                            onChangeParent={(val) => {
                              setShowDescription(val == 7);
                            }}
                            required
                          />
                        </Grid>
                        {showDescription && (
                          <Grid item>
                            <MyTextInput
                              label="Qualification Name"
                              name="description"
                              placeholder="Qualification Name"
                              required
                            />
                          </Grid>
                        )}
                        <Grid item>
                          <MyTextInput
                            label="Band"
                            name="band"
                            placeholder="Band"
                            required
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        container
                        direction="column"
                        spacing={2}
                      >
                        <Grid item style={{ marginTop: 5 }}>
                          <MyDate
                            label="Exam Date"
                            placeholderText="Exam Date"
                            name="examDate"
                            dateFormat="dd-MM-yyyy"
                            error={touched.examDate && Boolean(errors.examDate)}
                            required
                          />
                        </Grid>
                        <Grid item>
                          <MyTextInput
                            label="Reading"
                            name="reading"
                            placeholder="Reading"
                            required
                          />
                        </Grid>
                        <Grid item>
                          <MyTextInput
                            label="Listening"
                            name="listening"
                            placeholder="Listening"
                            required
                          />
                        </Grid>
                        <Grid item>
                          <MyTextInput
                            label="Writing"
                            name="writing"
                            placeholder="Writing"
                            required
                          />
                        </Grid>
                        <Grid item>
                          <MyTextInput
                            label="Speaking"
                            name="speaking"
                            placeholder="Speaking"
                            required
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <DialogActions>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Button onClick={() => handleFormCancel()}>
                            Cancel
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            type="submit"
                            disabled={
                              isSubmitting || !dirty || !isValid || isLocked
                            }
                            variant="contained"
                            className="bg-sky-400 hover:bg-sky-500"
                          >
                            Save
                          </Button>
                        </Grid>
                      </Grid>
                    </DialogActions>
                  </form>
                );
              }}
            </Formik>

            {updating && (
              <Grid item style={{ marginBottom: 20 }}>
                <LinearIndeterminate />
              </Grid>
            )}
          </DialogContent>
        </>
      </Dialog>
    </Root>
  );
};

export default Add;
