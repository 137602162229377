import React from 'react';
import { styled } from '@mui/material/styles';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const PREFIX = 'Agreements';

const classes = {
  root: `${PREFIX}-root`,
  panelHeader: `${PREFIX}-panelHeader`,
  penelHeaderTicked: `${PREFIX}-penelHeaderTicked`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.panelHeader}`]: {
    backgroundColor: 'lightgray',
  },

  [`& .${classes.penelHeaderTicked}`]: {
    backgroundColor: theme.palette.common.lightBlue,
  },
}));

const Agreements = ({
  termsAgreed,
  setTermsAgreed,
  role,
  isInternal,
  agentName
}) => {
  return (
    <Root>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-label="Expand"
          aria-controls="additional-actions1-content"
          id="additional-actions1-header"
          className={
            termsAgreed ? classes.penelHeaderTicked : classes.panelHeader
          }
        >
          <FormControlLabel
            aria-label="Acknowledge"
            onClick={(event) => event.stopPropagation()}
            onFocus={(event) => event.stopPropagation()}
            control={
              <Checkbox
                checked={termsAgreed}
                onChange={() => {
                  setTermsAgreed(!termsAgreed);
                }}
              />
            }
            label="I agree with the terms and conditions detailed here (expand to see terms and condtions)"
          />
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root}>
            <Grid container direction="column" spacing={3}>
              <Grid item style={{ marginBottom: 15 }}>
                <Typography align="center" variant="h5">
                  Terms and Conditions
                </Typography>
              </Grid>
              <div className="flex flex-col font-medium text-justify pl-4">
                <div>
                <Grid item style={{ marginBottom: 15,marginLeft: 15 }}>
                <Typography>
                        TCL Global shall not be held responsible if immigration
                        authority changes their rules, regulations or visa
                        processing requirements if that change has a direct
                        effect on the timeline for delivery.
                </Typography>
                 </Grid>
                 <Grid item style={{ marginBottom: 15,marginLeft: 15 }}>
                <Typography>
                        TCL Global will endeavor to process the application in
                        the shortest possible time frame but it does not provide
                        any guarantees on how long the process will take.
                </Typography>
                 </Grid>
                 <Grid item style={{ marginBottom: 15,marginLeft: 15 }}>
                <Typography>
                        TCL Global shall not be held responsible for any
                        application rejections as these outcomes are contingent
                        upon factors beyond our control, including but not
                        limited to the student’s academic qualifications,
                        interview performance and immigration policies.
                </Typography>
                 </Grid>
                 <Grid item style={{ marginBottom: 15,marginLeft: 15 }}>
                <Typography>
                        TCL Global shall not be held responsible for any
                        scholarship rejections, as these outcomes are determined
                        by the university’s independent assessment process, and
                        the company has no control over such decisions.
                </Typography>
                 </Grid>
                 <Grid item style={{ marginBottom: 15,marginLeft: 15 }}>
                <Typography>
                        TCL Global shall not be held liable for the CAS, I-20,
                        COE, LOA withdrawal due to changes in admission
                        policies, visa compliance issues or program
                        modifications.
                </Typography>
                 </Grid>
                 <Grid item style={{ marginBottom: 15,marginLeft: 15 }}>
                <Typography>
                        TCL Global shall not be held responsible for the outcome
                        of the visa application, as it is ultimately determined
                        by the immigration authorities.
                </Typography>
                 </Grid>
                 <Grid item style={{ marginBottom: 15,marginLeft: 15 }}>
                <Typography>
                        TCL Global shall not be held responsible and will not
                        assist in obtaining a refund if a student conceals or
                        provides inaccurate information such as history of visa
                        rejections, during the application process. It is the
                        student’s responsibility to disclose all relevant
                        information truthfully, and any repercussions arising
                        from the withholding of such information will be the
                        sole responsibility of the student.
                </Typography>
                 </Grid>
                 <Grid item style={{ marginBottom: 15,marginLeft: 15 }}>
                <Typography>
                        TCL Global shall not be liable for any variance when
                        refund is made due to foreign exchange rate
                        fluctuations.
                </Typography>
                 </Grid>
                </div>
                <br/>
                {(role.isAgent) && (
                  <div>
                    <div className='px-5 text-gray-900'>
                      {`I, ${agentName} and my agency declare that the information provided is true and the documents I am submitting in support of this application are genuine and have not been altered in any way. I authorize Talib Consultancy (UK) Limited (TCL Global) to be representative and act on behalf of all matters related to this application and submit this application to any university/UCAS/pathway providers.`}
                    </div>
                  </div>
                )}
              </div>
            </Grid>
          </div>
        </AccordionDetails>
      </Accordion>
    </Root>
  );
};

export default Agreements;
