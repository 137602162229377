import React from 'react';
import {
  MdPerson,
  MdSchool,
  MdDescription,
  MdFlight,
  MdLanguage,
  MdChat,
  MdFileUpload,
  MdSecurity,
  MdCurrencyExchange,
} from 'react-icons/md';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import {PhoneAndroidRounded} from '@mui/icons-material';

function SideNavigation({
   activeComponent, 
   setActiveComponent,
   role,
   permissions,
   studentLeadCloudFiles,
   LanguageRecordsStuLeads,
   EducationRecordsStuLeads,
   }) {
  const ButtonItem = ({ label, value, icon }) => {
    const isActive = activeComponent === value;
    console.log('studentLeadCloudFiles',studentLeadCloudFiles);
    return (
      <button
        className={`flex items-center px-6 py-3 rounded-lg w-full text-left transition-all duration-300 transform hover:scale-101 ${
          isActive
            ? 'bg-gradient-to-r from-blue-600 to-blue-700 text-white shadow-md'
            : 'bg-gradient-to-r from-white to-gray-100 text-gray-700 hover:bg-gray-200 border border-transparent hover:border-gray-300'
        }`}
        onClick={() => setActiveComponent(value)}
      >
        {icon}
        <span className="ml-2 text-lg">{label}</span>
      </button>
    );
  };

  return (
    <div className="w-auto p-4 border-r bg-white shadow-xl rounded-lg list-none">
      <ul className="space-y-6 list-none">
        <li>
          <ButtonItem
            label="General"
            value="basicinfo"
            icon={<MdPerson className="text-lg" />}
          />
        </li>

        <li>
          {permissions.CanAccessLeadContact && <ButtonItem
            label="Contact Info"
            value="phoneAndEmailInfo"
            icon={<PhoneAndroidRounded className="text-lg" />}
          />}

        </li>
        <li>
          <ButtonItem
             label={`Education ${
              EducationRecordsStuLeads.length > 0
              ? ' (' + EducationRecordsStuLeads.length + ')'
              : ''
          }`}
            value="eduinfo"
            icon={<MdSchool className="text-lg" />}
          />
        </li>
        <li>
          <ButtonItem
            label={`Language ${
              LanguageRecordsStuLeads.length > 0
              ? ' (' + LanguageRecordsStuLeads.length + ')'
              : ''
          }`}
            value="languageInfo"
            icon={<MdLanguage className="text-lg" />}
          />
        </li>
        <li>
          <ButtonItem
            label="Preferences"
            value="destinationInfo"
            icon={<MdFlight className="text-lg" />}
          />
        </li>
        <li>
          <ButtonItem
            label="Email"
            value="leadMessages"
            icon={<MdChat className="text-lg" />}
          />
        </li>
        <li>
          <ButtonItem
            label="Notes"
            value="summaryinfo"
            icon={<MdDescription className="text-lg" />}
          />
        </li>
        <li>
          <ButtonItem
            label={`Documents ${
              studentLeadCloudFiles.length > 0
              ? ' (' + studentLeadCloudFiles.length + ')'
              : ''
          }`}
            value="documents"
            icon={<MdFileUpload className="text-lg" />}
          />
        </li>
        <li>
        {permissions.CanAccessLeadChat && 
          <ButtonItem
            label="Chats"
            value="chats"
            icon={<MdChat className="text-lg" />}
          />
         }
        </li>
        <li>
          {role.isSuperAdmin && (
            <ButtonItem
              label="Activities"
              value="activity"
              icon={<MdSecurity className="text-lg" />}
            />
          )}
        </li>

        <li>
          <ButtonItem
            label="External Notes"
            value="externalNotes"
            icon={<MdDescription className="text-lg" />}
          />
        </li>
        <li>
          {role.isSuperAdmin && (
            <ButtonItem
              label="Accounts Notes"
              value="accountsNotes"
              icon={<MdCurrencyExchange className="text-lg" />}
            />
          )}
        </li>
      </ul>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SideNavigation);
