import React, { useState } from 'react';

import { Grid, Fab, CircularProgress } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import useInput from '../../../../Common/Form/use-input';
import MyInput from '../../../../Common/Form/MyInput';
import MySelectInput from '../../../../Common/Form/MySelectInput';
import { GENDER } from '../../../../../Util/Constants';
import MyCountryList from '../../../../Common/Form/MyCountryList';
import MyDateInput from '../../../../Common/Form/MyDateInput';

const isOptional = () => true;
const isNotEmptyTrim = (value) => value && value.trim() !== '';
const isNotEmpty = (value) => value && value !== '';
const isNotEmptyNum = (value) => value && value > 0;
const isEmail = (value) => !value || value.includes('@');
const isDate = (value) => Date.parse(value);
const isDateOptional = (value) => !value || Date.parse(value);

const PersonalDetails = ({
  personalDetails,
  setPersonalDetails,
  saveInParent,
  submitting,
}) => {
  const [changed, setChanged] = useState(false);

  const onBlurHandler = (name, value) => {
    setChanged(true);
    personalDetails = {
      ...personalDetails,
      ...{
        [name]: value,
      },
    };
    setPersonalDetails(personalDetails);
  };
  const { obj: emailAddress } = useInput(
    personalDetails.emailAddress,
    isEmail,
    onBlurHandler
  );
  const { obj: phoneNumber } = useInput(
    personalDetails.phoneNumber,
    isOptional,
    onBlurHandler
  );
  const { obj: gender } = useInput(
    personalDetails.gender,
    isOptional,
    onBlurHandler
  );
  const { obj: address } = useInput(
    personalDetails.address,
    isOptional,
    onBlurHandler
  );
  const { obj: countryOfResidence } = useInput(
    personalDetails.countryOfResidence,
    isOptional,
    onBlurHandler
  );
  const { obj: dateOfBirth } = useInput(
    personalDetails.dateOfBirth,
    isDateOptional,
    onBlurHandler
  );
  const save = () => {
    saveInParent(personalDetails, 2).then(() => {
      setChanged(false);
    });
  };

  return (
    <Grid container direction={'column'} spacing={1} xs={10}>
      <Grid item>
        <MyInput
          name="emailAddress"
          label="Personal Email"
          value={emailAddress.value}
          handleChange={emailAddress.valueChangeHandler}
          handleBlur={emailAddress.valueInputBlurHandler}
          errorMessage="Email must be valid"
          isInvalid={emailAddress.hasError}
          required
          inputProps={{
            maxLength: 100,
          }}
        />
      </Grid>
      <Grid item style={{ paddingTop: 0 }}>
        <MyInput
          name="phoneNumber"
          label="Phone Number"
          value={phoneNumber.value}
          handleChange={phoneNumber.valueChangeHandler}
          handleBlur={phoneNumber.valueInputBlurHandler}
          errorMessage="Phone number must be valid"
          isInvalid={phoneNumber.hasError}
          required
          inputProps={{
            maxLength: 25,
          }}
        />
      </Grid>
      <Grid item style={{ paddingTop: 0 }}>
        <MySelectInput
          options={GENDER}
          name="gender"
          label="Gender"
          value={gender.value}
          handleChange={gender.valueChangeHandler}
          handleBlur={gender.valueInputBlurHandler}
          errorMessage="Gender is invalid"
          isInvalid={gender.hasError}
          required
        />
      </Grid>
      <Grid item>
        <MyDateInput
          name="dateOfBirth"
          label="Date of Birth"
          value={dateOfBirth.value}
          handleChange={dateOfBirth.valueChangeHandler}
          handleBlur={onBlurHandler}
          isInvalid={dateOfBirth.hasError}
          errorMessage="Date of birth is invalid"
          required={true}
        />
      </Grid>
      <Grid item>
        <MyInput
          name="address"
          label="Address"
          value={address.value}
          handleChange={address.valueChangeHandler}
          handleBlur={address.valueInputBlurHandler}
          errorMessage="Email must be both valid"
          isInvalid={address.hasError}
          rows={3}
          inputProps={{
            maxLength: 300,
          }}
        />
      </Grid>
      <Grid item style={{ paddingTop: 0 }}>
        <MyCountryList
          name="countryOfResidence"
          label="Country of Residence"
          value={countryOfResidence.value}
          handleChange={countryOfResidence.valueChangeHandler}
          errorMessage="Country of residenceis invalid"
          handleBlur={countryOfResidence.valueInputBlurHandler}
          isInvalid={countryOfResidence.hasError}
        />
      </Grid>
      <Grid item container>
        <Grid item>
          <Fab
            size="small"
            variant="extended"
            color="primary"
            style={{ marginRight: 10 }}
            onClick={save}
            disabled={submitting || !changed}
          >
            <DoneIcon />
            {submitting && (
              <>
                <CircularProgress size={14} color="primary" />
                <span style={{ marginLeft: 5 }}>Saving</span>
              </>
            )}
            {!submitting && 'Save'}
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PersonalDetails;
