import React, {Fragment} from 'react'
import {ChevronDownIcon} from '@heroicons/react/20/solid'
import {Menu, Transition} from '@headlessui/react'
import {useNavigate} from 'react-router-dom'
import {TbBellFilled} from 'react-icons/tb'
import { format, addMinutes } from 'date-fns';

export default function NotificationDropdown(props) {
  const {
    iconColor = 'black/50',
    title = '',
    noDownIcon = false,
    bgColor = '',
    options = {notifications: []},
    handleClick = () => {},
  } = props
  console.log('props',props)
  const navigate = useNavigate()
  var tempNotifications=props.isInternal? options.notifications.filter((item)=>!item.isRead) : options.notifications.filter((item)=>!item.isRead && item.notificationType!=4);
  return (
    <div className='relative text-right pt-2'>
      {options.notificationCount > 0 && (
        <div className='absolute bg-rose-600 text-white w-5 h-5 text-xs drop-shadow-md rounded-full flex items-center justify-center z-10 left-3 top-3'>
          {options.notificationCount}
        </div>
      )}
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button
            className={`inline-flex w-full justify-left rounded-md  ${
              bgColor
                ? bgColor + 'px- py-3'
                : 'bg-black bg-opacity-20 px-4 py-2'
            }  text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
          >
            <TbBellFilled size={25} className={`text-${iconColor}`} /> {title}
            {!noDownIcon ? (
              <ChevronDownIcon
                className='ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100'
                aria-hidden='true'
              />
            ) : (
              ''
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 mt-3 w-[450px] pt-2 overflow-y-auto h-[500px] pl-4 origin-top-right divide-y divide-gray-100 rounded-md bg-white drop-shadow-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none '>
            <div className='relative Text-lg text-gray-900 pt-1 pl-2'>Notifications</div>
            <div className='px-1 py-1 '>
              {tempNotifications.map((option, index) => {
                if (index<50)
                  return (
                    <Menu.Item key={index}>
                      {({active}) => (
                        <button
                          className={`${
                            active
                              ? 'bg-blue-400 text-white'
                              : 'text-gray-900'
                          } group flex w-full items-center rounded-md shadow py-2 px-1 text-sm group-[1]:`}
                          onClick={() => handleClick(option)}
                        >
                          <TbBellFilled
                            className={`${
                              active ? 'text-white' : 'text-blue-600'
                            } h-10 w-10 max-w-[22px]`}
                            aria-hidden='true'
                          />
                           <span className='pl-2'>{option.name}</span> 
                           <span className='pl-2'>{format(
                                  addMinutes(
                                    new Date(option.addedOn),
                                    -new Date().getTimezoneOffset()
                                  ),
                                  'MMMM d, yyyy h:mm a'
                                )}</span> 
                           
                        </button>
                      )}
                    </Menu.Item>
                  )
              })}
              {options.notifications.length < 1 && (
                <Menu.Item>
                  <button className='  flex w-full items-center rounded-md px-2 py-2 text-sm '>
                    No New Notifications.
                  </button>
                </Menu.Item>
              )}
            </div>
            {options.notifications.length > 10 && (
              <div className='px-1 py-1'>
                <Menu.Item>
                  {({active}) => (
                    <button
                      className={`${
                        active ? 'bg-blue-400 text-white' : 'text-gray-900'
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm group-[1]:`}
                      onClick={() => navigate('/notifications')}
                    >
                      See All
                    </button>
                  )}
                </Menu.Item>
              </div>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}
