import { Button, Grid, InputLabel, Typography } from '@mui/material';
import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditorDraftJs from '../../../../Common/Editor/EditorDraftJs';
import { LoadingButton } from '@mui/lab';
import Conversations from '../../../../Messages/Conversations';
import Agent from '../../../../../api/agent';
import { format, addMinutes } from 'date-fns';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { formats, modules } from '../../../../../Util/Util';

const SummaryStage = ({ records, setRecords, studentId, name }) => {
  const [addNodeMode, setAddNodeMode] = useState(false);
  const [rawHtml, setRawHtml] = useState();
  const [updating, setUpdating] = useState(false);
  const [editorValidState, setEditorValidState] = useState(false);
  const editorOnChange = (rawHtml, valid) => {
    setRawHtml(rawHtml);
    setEditorValidState(valid);
  };
  const addNote = () => {
    var msg = {
      content: rawHtml,
      studentId: studentId,
      sendEmail: false,
      file: '',
      attachmentFileName:'',
      noteType: 2,
    };
    setUpdating(true);
    Agent.Messages.addStudentNoteAtt(msg)
      .then(() => {
        let temp = records.concat({
          content: rawHtml,
          from: name,
          noteType: 2,
          dateSent: format(
            addMinutes(new Date(), new Date().getTimezoneOffset()),
            'MMMM d, yyyy h:mm a'
          ),
        });
        temp.sort(function (a, b) {
          return new Date(b.dateSent) - new Date(a.dateSent);
        });
        setRecords(temp);
        setUpdating(false);
        setAddNodeMode(false);
        setEditorValidState(false);
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
        debugger;
      });
  };
  return (
    <Grid container direction="column" spacing={2} style={{ marginTop: 5 }}>
      <Grid item container justifyContent="center">
        {' '}
        <Typography color="primary" variant="h5">
          Student Summary Notes (Internal)
        </Typography>
      </Grid>
      <Grid item>
        <Button className='bg-white font-semibold hover:bg-gray-100'
          endIcon={!addNodeMode ? <AddIcon /> : null}
          onClick={() => {
            setAddNodeMode((prevState) => !prevState);
          }}
        >
          {addNodeMode ? 'Hide Note Option' : 'Add Summary Note'}
        </Button>
      </Grid>
      {addNodeMode && (
        <>
           <Grid item>
            <EditorDraftJs editorOnChange={editorOnChange} height={250} />
            {/* <InputLabel className='pl-2 font-semibold'>Content</InputLabel>
             <ReactQuill
              theme="snow" 
              fullWidth
              value={rawHtml} 
              modules={modules}
              formats={formats}
              onChange={editorOnChange}
              className='bg-white h-[300px] pb-10'
             /> */}
          </Grid> 
          <Grid item >
            <LoadingButton
              className='bg-cyan-700 font-semibold hover:bg-cyan-600'
              loading={updating}
              disabled={!editorValidState}
              onClick={addNote}
              variant="outlined"
            >
              <p className='text-gray-200'>Add Note</p>
            </LoadingButton>
          </Grid>
        </>
      )}
      <Grid item container>
        <Conversations
          messages={records.filter((item) => item.noteType == 2)}
          user={name}
          hideReplyOption={true}
        />
      </Grid>
    </Grid>
  );
};

export default SummaryStage;
