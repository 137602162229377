import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton } from '@mui/material';
import { MUI_LICENSE_KEY } from '../../../Util/Constants';
import { LicenseInfo, DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import React, { useContext, useState} from 'react';
import Agent from '../../../api/agent';
import MyTooltip from '../../Common/Tooltip/MyTooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import UserContext from '../../../context/user-context';
import { getDateDifference } from '../../../Util/Util';
import { useNavigate } from 'react-router';
import { connect } from 'react-redux';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const General = ({ appUserId, isInternal, setReload }) => {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const [bulkUpdate, setBulkUpdate] = useState();
  const [selectionModel, setSelectionModel] = useState([]);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const viewNotification = (row) => {
    console.log('notifi id',row.id);
    Agent.Summary.updateAsReadNotifi([row.id]);
    window.open(row.link, '_blank');
  };
  const handleMarkRead =()=>{
  Agent.Summary.updateAsReadNotifi(selectionModel).then((res)=>{
    setSuccessMessage('Update successfully');
    setSuccessOpen(true);
    setReload(true);
    setSelectionModel([]);
  }).catch((error)=>{
    console.log(error);
  });
  }
  const deleteNotification = (id) => {
    Agent.Common.deletenotification(id)
      .then(() => {
        userContext.setUserContext({
          ...userContext,
          notificationCount:
            userContext.notificationCount > 0
              ? userContext.notificationCount - 1
              : userContext.notificationCount,
          notifications: userContext.notifications.filter(
            (notification) => notification.id !== id
          ),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const successDialogStyles = {
    backgroundColor: 'bg-green-400',
  };
  const userColumns = [
    {
      field: 'name',
      headerName: 'Notification',
      width: 600,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <div
          className={`p-2 ${
            params.row.isRead ? 'bg-white':'bg-gray-300'
          } rounded`}
        >
          {params.row.name}
        </div>
      ),
    },
    {
      field: 'addedOn',
      headerName: 'Date',
      width: 150,
      renderCell: (params) => getDateDifference(params.row.addedOn, true),
    },
    {
      field: 'link',
      headerName: 'View',
      width: 100,
      renderCell: (params) => (
        <strong>
          <MyTooltip icon={<VisibilityIcon />} title="View Notification">
            <IconButton
              color="primary"
              onClick={() => viewNotification(params.row)}
            >
              <VisibilityIcon />
            </IconButton>
          </MyTooltip>
        </strong>
      ),
    },
    {
      field: 'id',
      headerName: 'Remove',
      width: 150,
      renderCell: (params) => (
        <strong>
          <MyTooltip icon={<DeleteForeverIcon />} title="Delete Notification">
            <IconButton
              color="primary"
              onClick={() => deleteNotification(params.row.id)}
            >
              <DeleteForeverIcon className="text-red-500" />
            </IconButton>
          </MyTooltip>
        </strong>
      ),
    },
  ];

  return (<>
        <Dialog
              open={successOpen}
              onClose={() => setSuccessOpen(false)}
              aria-labelledby="success-dialog-title"
              PaperProps={{
                className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
              }}
            >
              <DialogTitle id="success-dialog-title">Success</DialogTitle>
              <DialogContent>
                <DialogContentText>{successMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setSuccessOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
    <div className='flex flex-start justify-between pt-2 py-2'>
    <div className='w-[70%]'></div>
    {bulkUpdate && <div className='relative'>
     <button className='bg-teal-500 hover:bg-teal-400 px-4 py-1 mt-1 rounded-md text-gray-100'
      onClick={handleMarkRead}>Mark Read</button>
    </div>}
    {isInternal && <Button
      onClick={() => setBulkUpdate(!bulkUpdate)}
      variant="text"
      className='relative bg-blue-200 hover:bg-blue-100'
    >
      {bulkUpdate ? 'Disable Bulk Update' : 'Enable Bulk Update'}
    </Button>}
   
  </div>
  
    <div style={{ height: 800, width: '100%' }}>
      <DataGridPro
        density="compact"
        disableSelectionOnClick
        disableColumnSelector
        disableColumnMenu
        disableColumnFilter
        disableDensitySelector
        rows={userContext.notifications.filter((x) => x.notificationType !== 4)}
        columns={userColumns}
        checkboxSelection={ isInternal && bulkUpdate}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel); //onRowSelection use for datagridpro v6 and onselection is use for current datagrid
        }}
        selectionModel={selectionModel}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
    </>);
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
  };
};

export default connect(mapStateToProps, null)(General);
