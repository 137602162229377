import { Button, Grid, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState } from 'react';
import Agent from '../../../../api/agent';
import LinearIndeterminate from '../../../Common/Progress/LinearIndeterminate';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions/index';
import { useLocation } from 'react-router';
import { AGENTSTATUS } from '../../../../Util/Constants';

const PREFIX = 'ApproveAgent';

const classes = {
  button: `${PREFIX}-button`,
  formControl: `${PREFIX}-formControl`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.button}`]: {
    display: 'block',
    marginTop: theme.spacing(2),
  },

  [`& .${classes.formControl}`]: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const ApproveAgent = (props) => {
  const [status, setStatus] = useState(props.agent.status);
  const [open, setOpen] = useState(false);
  const [statusUpdating, setstatusUpdating] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const location = useLocation();
  const agentUrl = location.pathname;
  const agentSplit = agentUrl.split('/');
  const agentId = parseInt(agentSplit[2]);

  const handleChange = (event) => {
    setStatus(event.target.value);
    setEditMode(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  async function updateStatus() {
    var agentPayload = {
      status: status,
      Id: agentId,
    };
    //props.agent.status = status
    setstatusUpdating(true);
    await Agent.Agents.updateStatus(agentPayload)
      .then(() => {
        setEditMode(false);
        setstatusUpdating(false);
        props.updateStatus(status);
        props.updateAgentStatus(props.agent.id, status);
        props.updateAlert('State update actioned successfully!', true);
      })
      .catch((error) => {
        console.log(error);
        props.updateAlert('Failed to update status', false);
        setstatusUpdating(false);
        return Promise.reject(error);
      });
  }

  return (
    <StyledGrid container direction="column" spacing={2}>
      {status == 7 && (
        <Grid item>
          <Typography color="secondary">
            Current status of this agent means that the email address of this
            agent has not been verified. However, if you know their email
            address is valid, you can confirm the verification on their behalf
            by updating the agent to another status (Incomplete for example){' '}
          </Typography>
        </Grid>
      )}
      <Grid item container>
        <Grid item style={{ paddingTop: 6 }}>
          <Typography>Update the status</Typography>
        </Grid>
        <Grid item style={{ marginLeft: 50 }}>
          <Select
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={status}
            onChange={handleChange}
            size="small"
            disabled={!(props.role == 'SuperAdmin')}
          >
            {AGENTSTATUS.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.type}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <Grid item>
        <Button
          className="bg-sky-400 hover:bg-sky-500"
          variant="contained"
          onClick={updateStatus}
          disabled={!editMode}
        >
          Update Status
        </Button>
      </Grid>
      {statusUpdating && (
        <Grid item style={{ paddingRight: 10 }}>
          <LinearIndeterminate />
        </Grid>
      )}
    </StyledGrid>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAgentStatus: (id, status) =>
      dispatch(actions.updateAgentStatus(id, status)),
  };
};
//updateAgentStatus
export default connect(null, mapDispatchToProps)(ApproveAgent);
