import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Typography } from '@mui/material';

export default function PhotoWidgetDropzone({
  setFiles,
  crop,
  upload,
  message,
  disabled = false,
  height = 200,
}) {
  if (message == undefined) {
    message = 'Drop image here';
  }

  const dzStyles = {
    border: 'dashed 3px #eee',
    borderColor: '#eee',
    borderRadius: '5px',
    paddingTop: '30px',
    textAlign: 'center',
    height: height,
  };

  const dzActive = {
    borderColor: 'green',
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (crop) {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      } else {
        upload(acceptedFiles[0]);
      }
    },
    [setFiles]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      style={isDragActive ? { ...dzStyles, ...dzActive } : dzStyles}
    >
      <input {...getInputProps()} disabled={disabled} />
      <CloudUploadIcon>Upload</CloudUploadIcon>
      <Typography variant="h5">{message}</Typography>
    </div>
  );
}
