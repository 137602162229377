/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-extra-semi */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { useNavigate } from 'react-router-dom';
import DashboardCard from './Card';
import _ from 'lodash';
import { addDays, addMonths } from 'date-fns';
import { Countries } from '../../Util/Util';
import Dashboard from '../Agents/Agent/Dashboard/Dashboard';
import Agent from '../../api/agent';
import ShowAnnouncementList from '../AdminPanel/Announcement/ShowAnnouncementList';
//import CustomBarChart from './CustomBarChart';
//import CustomPieChart from './CustomPieChart';
import CardSection from './CardSection';

const HomePage = ({
  roleName,
  role,
  updateAgent,
  updateStudent,
  updateApplication,
  permissions,
}) => {
  // const classes = useStyles()
  const navigate = useNavigate();
  const [agents, setAgents] = useState([]);
  const [agentLoading, setAgentLoading] = useState(true);
  const [agentCount, setAgentCount] = useState();
  const [studentLoading, setStudentLoading] = useState(true);
  const [studentCount, setStudentCount] = useState();
  const [studentTableData2, setStudentTableData2] = useState([]);
  const [applicationLoading, setApplicationLoading] = useState(true);
  const [applicationCount, setApplicationCount] = useState();
  const [applicationTableData, setApplicationTableData] = useState([]);
  const [adminLoading, setAdminLoading] = useState(true);
  const [adminCount, setAdminCount] = useState();
  const [cosmosAdminUsers, setCosmosAdminUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const [activityLoaded, setStudentLoaded] = useState(false);
  const [startDate, setStartDate] = useState(addMonths(new Date(), -1));
  const [endDate, setEndDate] = useState(new Date());
  const [announcementTypeId, setAnnouncementTypeId] = useState(0);
  const [templateRowList, settemplateRowList] = useState([]);
  const [page, setPage] = useState(1);
  const [titleName, setTitleName] = useState('');
  const [pageSize, setPageSize] = useState(800);
  const [announcementList, setAnnouncementList] = useState([]);

  const [conditionalOfferCount, setConditionalOfferCount] = useState();
  const [unconditionalOfferCount, setUnconditionalOfferCount] = useState();

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', page.toString());
    params.append('pageSize', pageSize.toString());

    if (filter) {
      if (startDate) {
        params.append('StartDate', new Date(startDate).toDateString());
      }
    } else {
      params.append('StartDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (endDate) {
        params.append('EndDate', new Date(endDate).toDateString());
      }
    } else {
      params.append('EndDate', new Date().toDateString());
    }
    params.append('TitleName', titleName);
    params.append('AnnouncementTypeId', announcementTypeId);

    return params;
  };

  const getRows = (rows) => {
    let templateRows = rows.map((template) => ({
      id: template.id,
      announcementTypeId: template.announcementTypeId,
      userTypeId: template.userTypeId,
      link: template.link,
      announcementTypeName: template.announcementTypeName,
      expiryDate: template.expiryDate,
      publishDate: template.publishDate,
      content: template.content,
      title: template.title,
      addedBy: template.addedBy,
      addedOn: template.addedOn,
      updatedBy: template.updatedBy,
      updatedOn: template.updatedOn,
    }));
    settemplateRowList(templateRows);
    setStudentLoaded(true);
    setLoading(false);
  };

  const getAnnouncement = (filter = false) => {
    setLoading(true);
    Agent.Announcement.getAnnouncementList(getParams(filter))
      .then((res) => {
        console.log(res);
        getRows(res);
        setAnnouncementList(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (
      !(
        role.isAgent ||
        role.isAgentStaff ||
        role.isApplicationTeam ||
        role.isApplicationTeamLeader
      )
    )
      Agent.Applications.listdashboardAgent()
        .then(({ total, records }) => {
          console.log('dashboard agent', records);
          setApplicationCount(total);
          setConditionalOfferCount(
            records.filter((item) => item.status == 2).length
          ); //conditional
          setUnconditionalOfferCount(
            records.filter((item) => item.status == 3).length
          ); //unconditional
          setAgentLoading(true);
          setAdminLoading(true);

          // setStudentLoading(false);
          // setApplicationLoading(false);
          // setConversionCount(conversionApplications.length)
          // setConversionLoading(false)
        })
        .catch((error) => {
          console.log(error);
          // setApplicationLoading(false);
        });
  }, []);

  const groupBy = (array, key) => {
    // Return the end result
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        // If an array already present for key, push it to the array. Else create an array and push the object
        currentValue
      );
      // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
      return result;
    }, {}); // empty object is the initial value for result object
  };

  useEffect(() => {
    if (role.isApplicationTeam || role.isApplicationTeamLeader) {
      Agent.Summary.getApplicationTeamStats()
        .then((list) => {
          setAgentCount(list.filter((item) => item.status == 8).length); //pending
          setStudentCount(list.filter((item) => item.status == 1).length); //applied
          setApplicationCount(list.filter((item) => item.status == 7).length); // docs required
          setAdminCount(list.filter((item) => item.status == 5).length); // cancelled
          setAgentLoading(false);
          setStudentLoading(false);
          setApplicationLoading(false);
          setAdminLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setAgentLoading(false);
          setStudentLoading(false);
          setApplicationLoading(false);
          setAdminLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (
      !(
        role.isAgent ||
        role.isAgentStaff ||
        role.isApplicationTeam ||
        role.isApplicationTeamLeader
      )
    ) {
      Agent.Agents.list(getParamiter())
        .then((records) => {
          // setAgentCount(records.length);
          updateAgent(records.length);
          const recordsLast7Days = records.filter(
            (item) => new Date(item.created) >= addDays(new Date(), -1)
          );
          _.each(recordsLast7Days, function (item) {
            item.created = new Date(item.created).toLocaleString('en-GB', {
              weekday: 'short',
            });
          });
          const agentData = groupBy(recordsLast7Days, 'created');
          let labels = [];
          let values = [];
          let maxVal = 0;
          let totalVal = 0;
          for (const [key, value] of Object.entries(agentData)) {
            labels.push(key);
            values.push(value.length);
            totalVal = totalVal + value.length;
            maxVal = maxVal < value.length ? value.length : maxVal;
            // console.log(`${key}: ${value}`);
          }

          // setAgentLoading(false);
        })
        .catch((error) => {
          console.log(error);
          // setAgentLoading(false);
        });
    }
  }, []);
  useEffect(() => {
    if (
      !(
        role.isApplicationTeam ||
        role.isApplicationTeamLeader ||
        role.isAgent ||
        role.isAgentStaff
      )
    ) {
      Agent.Students.listdashboard()
        .then(({ total, records }) => {
          setStudentCount(total);
          updateStudent(total);
          // console.table('total', total);
          // let temp = [];
          let temp = [];
          let temp2 = [];
          _.each(records, function (student) {
            student.created = new Date(student.createdOn).toLocaleString(
              'en-GB',
              {
                weekday: 'short',
              }
            );

            //following will be the for the table at the bottom
            temp.push([
              student.id.toString(),
              `${student.firstName} ${student.lastName}`,
              student.citizenship
                ? Countries.find(({ id }) => id == student.citizenship).name
                : '',
              student.agentCompanyName,
            ]);
            temp2.push({
              id: student.id.toString(),
              name: `${student.firstName} ${student.lastName}`,
              country: student.citizenship
                ? Countries.find(({ id }) => id == student.citizenship).name
                : '',
            });
          });
          setStudentTableData2(temp2);
          const studentData = groupBy(records, 'created');
          let labels = [];
          let values = [];
          let maxVal = 0;
          let totalVal = 0;
          for (const [key, value] of Object.entries(studentData)) {
            labels.push(key);
            values.push(value.length);
            totalVal = totalVal + value.length;
            maxVal = maxVal < value.length ? value.length : maxVal;
            //  console.log(`${key}: ${value}`);
          }
          setStudentLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setStudentLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (
      !(
        role.isApplicationTeam ||
        role.isApplicationTeamLeader ||
        role.isAgent ||
        role.isAgentStaff
      )
    ) {
      Agent.Applications.listdashboard()
        .then(({ total, records }) => {
          setApplicationCount(total);
          updateApplication(total);
          let temp = [];
          _.each(records, function (app) {
            app.created = new Date(app.appliedOn).toLocaleString('en-GB', {
              weekday: 'short',
            });
            //following will be the for the table at the bottom
            temp.push({
              id: app.id.toString(),
              student: `${app.firstName} ${app.lastName}`,
              university: app.universityName,
              studentId: app.studentId,
            });
          });
          setApplicationTableData(temp);

          const applicationData = groupBy(records, 'created');
          let labels = [];
          let values = [];
          let maxVal = 0;
          let totalVal = 0;
          for (const [key, value] of Object.entries(applicationData)) {
            labels.push(key);
            values.push(value.length);
            totalVal = totalVal + value.length;
            maxVal = maxVal < value.length ? value.length : maxVal;
            // console.log(`${key}: ${value}`);
          }
          setApplicationLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setApplicationLoading(false);
        });

      //we need to find a better way to get total numbers for offers/conversions etc. Currently it's laoding (for users that have access to everything) ALL the applications which causes the API call to fail and also causes other issues
      // if (role.isSuperAdmin || permissions.CanManageAllAgents) {
      //   //find a smart way to do it
      // } else {
      //   getOfferCount();
      // }
    }
  }, []);

  useEffect(() => {
    Agent.Cosmos.getUsers()
      .then((usrs) => {
        setCosmosAdminUsers(
          usrs.filter((item) => {
            return (
              item.usertype == 1 ||
              item.usertype == 2 ||
              item.usertype == 3 ||
              item.usertype == 7 ||
              item.usertype == 8
            );
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    getAnnouncement();
  }, []);

  const getParamiter = () => {
    const params = new URLSearchParams();
    params.append('status', '5');
    return params;
  };

  return role.isAgent || role.isAgentStaff ? (
    <Dashboard announcementList={announcementList} role={role} />
  ) : (
    <div className="flex flex-col lg:flex-row flex-wrap gap-6 cursor-default bg-gradient-to-br from-sky-100 to-white  p-4 rounded-lg shadow-md glass">
      <div className="w-full p-4 rounded-lg bg-gradient from-sky-100 to-white">
        <CardSection
          roleName={roleName}
          role={role}
          studentLoading={studentLoading}
          studentCount={studentCount}
          applicationLoading={applicationLoading}
          applicationCount={applicationCount}
          agentLoading={agentLoading}
          agentCount={agentCount}
          adminLoading={adminLoading}
          adminCount={adminCount}
          conditionalOfferCount={conditionalOfferCount}
          unconditionalOfferCount={unconditionalOfferCount}
          navigate={navigate}
        />
      </div>

      <ShowAnnouncementList announcementList={announcementList} role={role} />

      <div className="w-full flex rounded-lg overflow-hidden my-1">
        {/* <CustomBarChart />

        <CustomPieChart /> */}
      </div>

      <DashboardCard appUsers={agents} groups={[]} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    name: state.auth.name,
    role: state.auth.role,
    roleName: state.auth.roleName,
    summary: state.summary,
    agent: state.agent,
    users: state.users,
    permissions: state.auth.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateAgent: (count) => dispatch(actions.updateAgent(count)),
    updateStudent: (count) => dispatch(actions.updateStudent(count)),
    updateApplication: (count) => dispatch(actions.updateApplication(count)),
    updateAdmin: (count) => dispatch(actions.updateAdmin(count)),
    loadUsers: () => dispatch(actions.loadUsers()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(HomePage);