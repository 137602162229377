import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import initialState from './initialState';

const reducer = (state = initialState.users, action) => {
  switch (action.type) {
    case actionTypes.USERS_LOADING_START:
      return loadUsersStart(state);
    case actionTypes.USERS_LOADING_SUCCESS:
      return action.users;
    case actionTypes.USERS_USER_AVATAR_UPDATE:
      return updateSingleUserImageUrl(state, action.payload);
    case actionTypes.USERS_USER_CHILD_UPDATE_SUCCESS:
      return updateChildToUser(state, action.payload);
    case actionTypes.USER_AGENTS_ADMIN_UPDATE:
      return assignAgentAsChild(state, action.payload);
    case actionTypes.USERS_USER_PERSONAL_UPDATE:
      return updateUserPersonalDetails(state, action.payload);
    case actionTypes.USERS_USER_COUNTRY_UPDATE:
      return updateUserCountry(state, action.payload);
    default:
      return state;
  }
};

const updateUserPersonalDetails = (state, payload) => {
  if (state.length === 0) return state;
  const { id, name, phoneNumber, applicationUpdateEmail } = payload;
  var user = state.find((obj) => obj.id == id);
  var temp = [];
  state.map((item) => {
    if (item.id == id) {
      temp.push({
        ...user,
        ...{
          name,
          phoneNumber,
          applicationUpdateEmail,
        },
      });
    } else {
      temp.push(item);
    }
  });
  return temp;
};

const loadUsersStart = (state) => {
  return updateObject(state);
};

const assignAgentAsChild = (state, payload) => {
  if (state.length === 0) return state;
  const { parentUserId, parentName, childUserId, childName } = payload;

  const oldParentUser = state.find((user) => {
    return user.childUsers.some((c) => c.childUserId == childUserId);
  });

  const parentUser = state.find((obj) => {
    return obj.id == parentUserId;
  });
  const newAssignment = {
    parentUserId: parseInt(parentUser.id),
    parentName,
    childUserId: parseInt(childUserId),
    childName,
    childUserType: 5,
  };
  parentUser.childUsers.push(newAssignment);

  if (oldParentUser) {
    oldParentUser.childUsers = parentUser.childUsers.filter(
      (user) => parseInt(user.childUserId) != childUserId
    );
  }
  var updatedState = state.map((item) => {
    if (item.id == parentUserId) {
      return parentUser;
    }
    if (oldParentUser) {
      if (item.appUserId == oldParentUser.appUserId) {
        return oldParentUser;
      }
    }
    return item;
  });
  return updatedState;
};

const updateUserCountry = (state, payload) => {
  if (state.length === 0) return state;
  const { id, country ,predicate, operation } = payload;
  var user = state.find((obj) => obj.id == id);
  if (operation == 'ADD' && predicate=='country') {
    user.countries.push(country);
  } else if(operation == 'ADD' && predicate=='leadCountry'){
    user.leadCountries.push(country);
  }else if(operation == 'ADD' && predicate=='referralCountry'){
    user.referralCountries.push(country);
  }else {
    user.countries = user.countries.filter((c) => c != country);
  }
  var updatedState = state.map((item) => {
    if (item.id == id) {
      return user;
    }
    return item;
  });
  return updatedState;
};

const updateChildToUser = (state, payload) => {
  if (state.length === 0) return state;
  const { parentUserId, parentName, childUserId, childName, operation } =
    payload;
  const parentUser = state.find((obj) => {
    return obj.id == parentUserId;
  });
  const childUser = state.find((obj) => {
    return obj.id == childUserId;
  });
  const newAssignment = {
    parentUserId: parseInt(parentUser.id),
    parentName,
    childUserId: parseInt(childUser.id),
    childName,
    childUserType: 2,
  };
  if (operation == 'ADD') {
    parentUser.childUsers.push(newAssignment);
    childUser.parentUsers.push(newAssignment);
  } else {
    parentUser.childUsers = parentUser.childUsers.filter(
      (user) => parseInt(user.childUserId) != childUser.id
    );
    childUser.parentUsers = childUser.parentUsers.filter(
      (user) => parseInt(user.parentUserId) != parentUser.id
    );
  }

  var updatedState = state.map((item) => {
    if (item.id == parentUserId) {
      return parentUser;
    }
    if (item.id == childUserId) {
      return childUser;
    }
    return item;
  });
  return updatedState;
};

const updateSingleUserImageUrl = (state, payload) => {
  if (state.length === 0) return state;
  const { id, url } = payload;
  const user = state.find((obj) => {
    return obj.id === id;
  });
  user.image = url;
  var updatedState = state.map((item) => {
    if (item.id === id) {
      return user;
    }
    return item;
  });
  return updatedState;
};

export default reducer;
