import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

import { Countries } from '../../../../../Util/Util';

const InstituteSearchBox = (props) => {
  const {
    isDisabled,
    selectedInstitution,
    setSelectedInstitution,
    country,
    allInstitutions,
    universityName,
    setUniversityName,
  } = props;

  return (
    <div className="w-60 lg:w-80">
      <Autocomplete
        disabled={isDisabled}
        InputLabelProps={{
          sx: {
            color: '#518eb9',
            fontSize: '28px',
            fontWeight: 600,
            '&.MuiOutlinedInput-notchedOutline': { fontSize: '28px' },
          },
        }}
        sx={{
          boxShadow:
            '0 1px 6px 0 rgba(0, 0, 0, 0.2), 0 0px 0px 0 rgba(0, 0, 0, 0.1)',
          width: '100%',
          backgroundColor: 'white',
          height: '38px',
          borderRadius: 2,
          '& .MuiOutlinedInput-root': {
            border: '0px solid yellow',
            borderRadius: '0',
            padding: '0',
          },
          '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            border: '0px solid #eee',
          },
        }}
        filterOptions={(x) => x}
        size="small"
        value={selectedInstitution}
        onChange={(_, newValue) => {
          setSelectedInstitution(newValue);
        }}
        options={
          country > 0
            ? allInstitutions.filter((c) => c.countryId == country)
            : allInstitutions
        }
        getOptionLabel={(option) =>
          `${option.name}, ${
            Countries.find(({ id }) => id == option.countryId).name
          }`
        }
        renderInput={(params) => (
          <TextField
            disabled={isDisabled}
            value={universityName}
            onChange={(e) => setUniversityName(e.target.value)}
            fullWidth
            {...params}
            placeholder="University/Institution"
          />
        )}
      />
    </div>
  );
};

export default InstituteSearchBox;
