import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { Button, Grid, Typography } from '@mui/material';
import AvatarMui from '../../../Common/Image/AvatarMui';
import MyTooltip from '../../../Common/Tooltip/MyTooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import { Search } from '../../../Common/Widgets/Search';

const PREFIX = 'UserAssignment';

const classes = {
  root: `${PREFIX}-root`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.root}`]: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const UserAssignment = ({ addChild, removeChild, currentUser, users }) => {
  const [assignedUsers, setAssignedUsers] = useState(
    users.filter((user) =>
      user.parentUsers.some((c) => c.parentUserId == currentUser.id)
    )
  );
  const [admins, setAdmins] = useState(
    users.filter(
      (user) =>
        user.parentUsers.length === 0 &&
        user.roleId === 3 &&
        user.id !== currentUser.id
    )
  );
  const [assignMode, setAssignMode] = useState(false);

  const handleDelete = (user) => {
    console.log('assign user with Id: ' + user.appUserId);
    setAssignMode(false);
    removeChild(currentUser.id, currentUser.name, user.id, user.name)
      .then(() => {
        setAssignedUsers(assignedUsers.filter((usr) => usr.id !== user.id));
        setAdmins(admins.concat(user));
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  const handleSelection = (user) => {
    console.log('assign user with Id: ' + user.id);
    setAssignMode(false);
    addChild(currentUser.id, currentUser.name, user.id, user.name)
      .then(() => {
        setAssignedUsers(assignedUsers.concat(user));
        setAdmins(admins.filter((u) => u.id !== user.id));
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  return (
    <StyledGrid container direction="column" spacing={2}>
      <Grid item>
        {assignedUsers.length > 0
          ? assignedUsers.map((user, index) => (
              <Chip
                key={index}
                avatar={
                  <AvatarMui imageUrl={user.image} fullName={user.name} />
                }
                label={user.name}
                onDelete={() => handleDelete(user)}
                deleteIcon={
                  <MyTooltip
                    title={`Click to un-assign ${user.name} from ${currentUser.name}`}
                  >
                    <CancelIcon color="primary" />
                  </MyTooltip>
                } // onClick={handleClick}
                // onDelete={handleDelete}
                variant="outlined"
              />
            ))
          : null}
      </Grid>
      <Grid item>
        <Button variant="contained" className="bg-red-800" onClick={() => setAssignMode(true)}>
          Assign Admin
        </Button>
      </Grid>
      {assignedUsers.length == 0 && (
        <Grid item>
          <Typography>No users assigned to this user yet</Typography>
        </Grid>
      )}
      {assignMode && (
        <Search
          users={admins}
          cancel={setAssignMode}
          action={handleSelection}
          title="Senior Admin Assignment"
        />
      )}
    </StyledGrid>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addChild: (parentId, parentName, childId, childName) =>
      dispatch(
        actions.addChildToUser(parentId, parentName, childId, childName)
      ),
    removeChild: (parentId, parentName, childId) =>
      dispatch(
        actions.removeChildFromUser(
          parentId,
          parentName,
          childId,
          parentId,
          childId
        )
      ),
  };
};

export default connect(null, mapDispatchToProps)(UserAssignment);
