import React, { useState, useEffect, useMemo } from 'react';
import {
  Button,
  Grid,
  Typography,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import 'tailwindcss/tailwind.css';
import 'tailwindcss/tailwind.css';
import { connect } from 'react-redux';
import {
  DataGridPro,
  GridPagination,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import Agent from '../../../../../api/agent';
import MyTooltip from '../../../../Common/Tooltip/MyTooltip';
import {
  STUDENTSOURCES,
  StudentLead_Activities,
  StudentLead_Status,
} from '../../../../../Util/Constants';
import { Countries } from '../../../../../Util/Util';
import LeadComFilters from '../../LeadComFilters/LeadComFilters';
import CountdownLoader from '../../LeadComUtils/CountdownLoader';
import BulkSummaryDialog from '../../SendBulkMailDialog/BulkSummaryDialog';

function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {isInternal && <GridToolbarColumnsButton />}
      {/* {isInternal && <GridToolbarFilterButton />} */}
      <GridToolbarDensitySelector />
      {hide && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}
const StudentsLeadBulkEmail = ({ appUserId }) => {
  const [showCountdownLoader, setShowCountdownLoader] = useState(false);
  const [LeadListName, setLeadListName] = useState('');
  const [Name, setLeadFirstName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [studentsLead, setStudentsLead] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [citizenship, setCitizenshipFilter] = useState(0);
  const [eventId, setEventFilter] = useState(0);
  const [LeadActivity, setLeadActivityFilter] = useState(0);
  const [Status, setLeadStatusFilter] = useState(0);
  const [BranchOfficeId, setBranchOfficeFilter] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalSelected, setTotalSelected] = useState(0);
  const [fromMail, setFromEmail] = useState(0);
  const [selectTemplateId, setSelectTemplateId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [studentsLeadIdList, setStudentsLeadIdList] = useState([]);
  const [offices, setOffices] = useState([]);
  const [fromMailList, setFromMailList] = useState([]);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [dialogueCommunicationName, setDialogueCommunicationName] =
    useState('');
  const [mailSubject, setMailSubject] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showData, setShowData] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(800);
  const [profileStatusFilter, setProfileStatusFilter] = useState(null);
  const [totalrows, setTotalRows] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [events, setEventList] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [leadType, setLeadType] = useState(0);
  const [eventAttendee, setEventAttendee] = useState(0)
  const [leadSource, setLeadSource] = useState(0);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCountdownComplete = () => {
    setShowCountdownLoader(false); // Hide countdown loader
  };

  const handleApplyFilters = () => {
    setShowData(true);
    setPageNumber(1);
    fetchData(true);
  };

  const handleSendMail = async () => {
    handleCloseConfirmDialog();
    setShowCountdownLoader(true); // Show countdown loader

    try {
      const response = await Agent.Communication.bulkEmailStudentsLead(payload);
      setOpenDialog(false);
      setSuccessMessage('Mail sent successfully!', response);
      setShowCountdownLoader(false);
    } catch (error) {
      console.error(error);
      setErrorMessage(error);
      setShowErrorMessage(true);
    }
  };

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  useEffect(() => {
    Agent.Events.getEventList().then((res) => {
      res.sort(function (a, b) {
        if (a.id > b.id) {
          return -1;
        }
        if (a.id < b.id) {
          return 1;
        }
        return 0;
      });
      setEventList(res);
    });
  }, []);

  const handleAddStudentsLeadId = (selectedStudentsLeadId) => {
    setStudentsLeadIdList((prevList) => [
      ...prevList,
      { Id: selectedStudentsLeadId },
    ]);
  };

  const closeErrorMessage = () => {
    setShowErrorMessage(false);
  };

  const ErrorMessageDisplay = () =>
    showErrorMessage && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-red-800 text-white p-6 rounded-lg shadow-lg z-50">
          <button
            className="btn btn-warning float-right"
            onClick={closeErrorMessage}
          >
            Close
          </button>
          <p>{errorMessage}</p>
        </div>
      </div>
    );

  const payload = {
    StudentLeads: studentsLeadIdList,
    CommunicationName: dialogueCommunicationName,
    TemplateId: selectTemplateId,
    EmailSubject: mailSubject,
    FromEmail: fromMail,
    AppUserId: appUserId,
    NumberOfStudents: totalSelected,
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    setSelectedRowCount(selectedRows.length);
  }, [selectedRows]);

  useEffect(() => {
    setTotalSelected(selectedRows.length);
  }, [selectedRows]);

  const fetchTemplates = async () => {
    try {
      const response = await Agent.Communication.getEmailTemplates();
      setTemplates(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', pageSize.toString());
    if (filter) {
      params.append('branchOfficeId', BranchOfficeId);
    }
    // params.append('id', filter ? id.trim() : '');
    params.append('name', filter ? Name.trim() : '');
    params.append('LeadListName', filter ? LeadListName.trim() : '');

    if (filter) {
      params.append('eventId', eventId);
    }
    if (filter) {
      params.append('LeadType', leadType);
    }
    if (filter) {
      params.append('Status', Status);
    }
    if (filter) {
      params.append('Source', leadSource);
    }
    if (filter) {
      params.append('leadActivity', LeadActivity);
    }
    if (filter) {
      params.append('IsEventAttendance', eventAttendee);
    }
    if (filter) {
      params.append('citizenship', citizenship);
    }
    return params;
  };

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  useEffect(() => {
    Agent.Communication.getFromEmails().then(setFromMailList);
  }, []);

  const fetchData = async (filter = false) => {
    setLoading(true);
    try {
      const params = getParams(filter);
      const leads = await Agent.Communication.listStudentsLead(params);
      const { pagination, data } = leads;
      // console.log(pagination);
      setTotalPages(pagination.totalPages);
      setTotalRows(pagination.totalItems); // Reflects the count of filtered data

      setDataLoaded(true);
      setStudentsLead(data);
      console.log('comms lead',data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(true); // Initial fetch with filters
  }, [
    pageNumber,
    pageSize,
    Name,
    Status,
    eventId,
    citizenship,
    LeadListName,
    BranchOfficeId,
    LeadActivity,
  ]); // Refetch data when pageNumber or pageSize or any filter status changes

  const handleSelectTemplateIdChange = (event) => {
    setSelectTemplateId(event.target.value);
  };

  const handleCommunicationNameChange = (event) => {
    setDialogueCommunicationName(event.target.value);
  };

  const handleMailSubjectChange = (event) => {
    setMailSubject(event.target.value);
  };
  const handleFromMailChange = (event) => {
    setFromEmail(event.target.value);
  };
  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const applyFilters = () => {
    let filteredStudentsLead = studentsLead;

    filteredStudentsLead = filteredStudentsLead.filter((studentLead) => {
      if (citizenship && studentLead.citizenship !== citizenship) {
        return false;
      }
      if (Status && studentLead.status !== Status) {
        return false;
      }
      if (LeadActivity && studentLead.leadActivity !== LeadActivity) {
        return false;
      }
      if (BranchOfficeId && studentLead.branchOfficeId !== BranchOfficeId) {
        return false;
      }

      if (LeadListName && studentLead.leadListName !== LeadListName) {
        return false;
      }

      if (Name && studentLead.firstName !== Name) {
        return false;
      }
      if (eventId && studentLead.eventId !== eventId) {
        return false;
      }

      // If no filter is applied or all filters match, include the student in the result
      return true;
    });

    setLoading(false);

    return filteredStudentsLead;
  };

  //  the useMemo hook to compute the filtered students when filter values change
  const filteredStudentsLead = useMemo(
    () => applyFilters(),
    [
      citizenship,
      Status,
      eventId,
      BranchOfficeId,
      profileStatusFilter,
      LeadActivity,
      LeadListName,
      Name,
      studentsLead, // students lead in the dependency array to recompute when the data changes
    ]
  );

  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    // If Select All is checked, select all students on the current page
    if (!selectAll) {
      const selectedIds = filteredStudentsLead.map((studentLead) => ({
        Id: studentLead.id,
      }));
      setStudentsLeadIdList(selectedIds);
      setSelectedRows(selectedIds.map((student) => student.Id));
    } else {
      setStudentsLeadIdList([]);
      setSelectedRows([]);
    }
  };

  const renderCheckbox = (params) => (
    <Checkbox
      checked={selectedRows.includes(params.row.id) || selectAll}
      onChange={(event) => handleRowSelection(event, params.row.id)}
    />
  );

  let studentsLeadColumns = [
    {
      field: 'checkbox',
      headerName: 'Select',
      width: 100,
      renderCell: renderCheckbox,
      headerAlign: 'center',
      renderHeader: () => (
        <Checkbox
          checked={selectAll}
          onChange={handleSelectAll}
          indeterminate={
            selectedRows.length > 0 && selectedRows.length < studentsLead.length
          }
        />
      ),
    },
    {
      field: 'firstName',
      headerName: 'Name',
      width: 250,
      renderCell: (params) => (
        <MyTooltip title={params.row.firstName} color="primary">
          <Typography className="clickable">{params.row.firstName}</Typography>
        </MyTooltip>
      ),
    },
    {
      field: 'email',
      headerName: 'To Email',
      width: 300,
      renderCell: (params) => <Typography>{params.row.email}</Typography>,
    },
    {
      field: 'status',
      headerName: 'Lead Status',
      width: 200,
      valueGetter: (params) => {
        const statusId = params.row.status;
        const status = StudentLead_Status.find(
          (leadStatus) => leadStatus.id == statusId
        );
        return status ? status.name : null;
      },
    },
    {
      field: 'referralName',
      headerName: 'Referral',
      width: 200,
      valueGetter: (params) => {
        const Citizenship = params.row.referralName;
        return Citizenship ? Citizenship : '';
      },
    },
    {
      field: 'isEventAddendence',
      align: 'center',
      headerAlign: 'center',
      width: 180,
      minWidth: 150,
      maxWidth: 200,
      resizable: true,
      hide: false,
      disabled: true,
      renderCell: (params) => (
        <Checkbox checked={params.row.isEventAddendence} />
      ),
    },
    {
      field: 'citizenship',
      headerName: 'Citizenship',
      width: 200,
      valueGetter: (params) => {
        const Citizenship = params.row.citizenship;
        const country = Countries.find(
          (citizenship) => citizenship.id == Citizenship
        );
        return country ? country.name : '';
      },
    },
    {
      field: 'source',
      headerName: 'Lead Source',
      width: 200,
      valueGetter: (params) => {
        const leadSource = params.row.source;
        const source = STUDENTSOURCES.find(
          (source) => source.id == leadSource
        );
        return source ? source?.type : '';
      },
    },
    {
      field: 'leadActivity',
      headerName: 'Lead Activity',
      width: 200,
      valueGetter: (params) => {
        const leadActivityId = params.row.leadActivity;
        const leadActivity = StudentLead_Activities.find(
          (leadActivity) => leadActivity.id == leadActivityId
        );
        return leadActivity ? leadActivity.name : '';
      },
    },
    {
      field: 'branchOfficeId',
      headerName: 'Branch',
      width: 200,
      valueGetter: (params) => {
        const branchOfficeId = params.row.branchOfficeId;
        const branch = offices.find((branch) => branch.id == branchOfficeId);
        return branch ? branch.name : '';
      },
    },
    {
      field: 'eventName',
      headerName: 'Event Name',
      hide: false,
      width: 270,
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={10} container alignItems="center">
            <Typography>{params.row.eventName}</Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      field: 'leadListName',
      headerName: 'Lead List Name',
      width: 250,
      hide: true,
      renderCell: (params) => (
        <Typography>{params.row.leadListName}</Typography>
      ),
    },
    
  ];

  const handleCitizenshipFilterChange = (event, value) => {
    setCitizenshipFilter(value ? value.id : 0);
  };

  const handleLeadStatusFilterChange = (event) => {
    setLeadStatusFilter(event.target.value);
  };

  const handleLeadActivityFilterChange = (event) => {
    setLeadActivityFilter(event.target.value);
  };

  const handleLeadListNameChange = (event) => {
    const selectedValue = event.target.value;
    setLeadListName(selectedValue);
  };
  const handleNameChange = (event) => {
    const selectedValue = event.target.value;
    setLeadFirstName(selectedValue);
  };

  const handleBranchOfficeFilterChange = (event) => {
    setBranchOfficeFilter(event.target.value);
  };
  const handleEventFilter = (event) => {
    setEventFilter(event.target.value);
  };

  const handleClearFilters = () => {
    setBranchOfficeFilter(0);
    setCitizenshipFilter(0);
    setLeadStatusFilter(0);
    setLeadActivityFilter(0);
    setLeadFirstName('');
    setLeadListName('');
    setEventFilter(0);
    setSelectedRows([]);
    setStudentsLeadIdList([]);
    setShowData(false);
    setSelectAll(false);
    setLeadType(0);
    setEventAttendee(0);
    setLeadSource(0);
    fetchData(false); // Refetch the data after clearing filters
  };

  const handleRowSelection = (event, rowId) => {
    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowId];
      const selectedStudensLeadtId = studentsLead.find(
        (studentLead) => studentLead.id === rowId
      ).id;
      handleAddStudentsLeadId(selectedStudensLeadtId);
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
    }

    setSelectedRows(newSelectedRows);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDialogueCommunicationName('');
  };

  const ConfirmDialog = () => (
    <Dialog
      open={openConfirmDialog}
      onClose={handleCloseConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Confirm Sending Mail'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description ">
          Are you sure you want to send the mail?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="flex justify-around">
        <Button
          onClick={handleCloseConfirmDialog}
          color="primary"
          className="btn-primary"
        >
          No
        </Button>
        <Button
          onClick={handleSendMail}
          color="success"
          className="btn-success"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <div className="h-96 w-full">
      <div className="bg-white p-4 mb-4 shadow-sm">
        <LeadComFilters
          citizenship={citizenship}
          Status={Status}
          LeadActivity={LeadActivity}
          eventId={eventId}
          BranchOfficeId={BranchOfficeId}
          LeadListName={LeadListName}
          Name={Name}
          offices={offices}
          events={events}
          handleCitizenshipFilterChange={handleCitizenshipFilterChange}
          handleLeadStatusFilterChange={handleLeadStatusFilterChange}
          handleLeadActivityFilterChange={handleLeadActivityFilterChange}
          handleEventFilter={handleEventFilter}
          handleBranchOfficeFilterChange={handleBranchOfficeFilterChange}
          handleLeadListNameChange={handleLeadListNameChange}
          handleNameChange={handleNameChange}
          handleClearFilters={handleClearFilters}
          handleApplyFilters={handleApplyFilters}
          handleSendBulkEmail={handleOpenDialog}
          totalSelected={totalSelected}
          setLeadType={setLeadType}
          leadType={leadType}
          eventAttendee={eventAttendee}
          setEventAttendee={setEventAttendee}
          leadSource={leadSource}
          setLeadSource={setLeadSource}
        />

        {showData ? (
          // Show the DataGrid only when showData is true (filters are applied)
          dataLoaded ? (<Grid item style={{ height: 730, width: '100%' }}>
            <DataGridPro
              rows={filteredStudentsLead}
              columns={studentsLeadColumns}
              totalPageSize={totalPages}
              loading={loading}
              //autoHeight
              pagination={true}
              paginationMode="server"
              pageSize={pageSize}
              pageSizeOptions={[800]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowCount={totalrows}
              onPageChange={(newPage) => setPageNumber(newPage + 1)}
              page={pageNumber - 1}
              components={{
                Toolbar: CustomToolbar,
                Pagination: GridPagination,
              }}
            />
            </Grid>
          ) : (
            <div>Loading data...</div>
          )
        ) : (
          <div>No data to display. Please apply filters to show data.</div>
        )}

        <BulkSummaryDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          totalSelected={totalSelected}
          templates={templates}
          fromMailList={fromMailList}
          dialogueCommunicationName={dialogueCommunicationName}
          setDialogueCommunicationName={setDialogueCommunicationName}
          selectTemplateId={selectTemplateId}
          setSelectTemplateId={setSelectTemplateId}
          mailSubject={mailSubject}
          setMailSubject={setMailSubject}
          fromMail={fromMail}
          setFromMail={setFromEmail}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          handleSelectTemplateIdChange={handleSelectTemplateIdChange}
          handleFromMailChange={handleFromMailChange}
          handleMailSubjectChange={handleMailSubjectChange}
          handleCommunicationNameChange={handleCommunicationNameChange}
        />

        {successMessage && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-blue-500 text-black p-4 rounded shadow-lg">
              <p>{successMessage}</p>
              <button
                className="btn btn-warning mt-4"
                onClick={() => setSuccessMessage('')}
              >
                Close
              </button>
            </div>
          </div>
        )}

        <ErrorMessageDisplay />
        <ConfirmDialog />

        {showCountdownLoader && (
          <CountdownLoader
            totalSelected={totalSelected}
            onCountdownComplete={handleCountdownComplete}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(StudentsLeadBulkEmail);
