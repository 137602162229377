import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Box, Typography } from '@mui/material';
import LoadingView from '../../Common/Loading/LoadingView';
import Agents from '../../Agents/Agents';
import ReferralList from '../Referrals/ReferralList';
import { connect } from 'react-redux';

const Partnerships = ({ permissions }) => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState('Agents');
  const [tabItems, setTabItems] = useState(['Agents']);
  useEffect(() => {
    setLoading(true);
    let items = ['Agents'];
    if (permissions.CanAccessReferral) {
      items.push('Referrals');
    }

    setTabItems(items);
    setLoading(false);
  }, [permissions]);

  const handleTabChange = (e, newVal) => {
    setTab(newVal);
  };

  return (
    <>
      {loading ? (
        <div style={{ marginTop: '0px', fontWeight: 'lighter' }}>
          <LoadingView />
        </div>
      ) : (
        <div>
          <Box>
            <Box>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#0081C9',
                    height: '5px',
                    borderRadius: '40px',
                  },
                }}
              >
                {tabItems.map((item, index) => {
                  return (
                    <Tab
                      className="text-black"
                      label={item}
                      key={index}
                      value={item}
                    />
                  );
                })}
              </Tabs>
              <Box>
                <TabPanel value={tab} index={'Agents'}>
                  <Typography
                    variant="h6"
                    component="h4"
                    align="center"
                    className="text-sky-900 mb-4 text-2xl"
                  ></Typography>
                  <Agents />
                </TabPanel>
                <TabPanel value={tab} index={'Referrals'}>
                  <Typography
                    variant="h6"
                    component="h4"
                    align="center"
                    paddingLeft="-20px"
                    className="text-sky-900 mb-4 text-2x.5"
                  ></Typography>
                  <ReferralList />
                </TabPanel>
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

const TabPanel = (props) => {
  const { children, value, index } = props;

  return <div>{value === index && <Box>{children}</Box>}</div>;
};

const mapStateToProps = (state) => {
  return {
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(Partnerships);
