import React, {useEffect, useState, useRef, Fragment} from 'react'
import {styled} from '@mui/material/styles'
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
  Chip,
} from '@mui/material'
// import Agent from '../../../../../../api/agent'
import CloseIcon from '@mui/icons-material/Close'
// import {INTAKES, STUDY_LEVELS} from '../../../../../../Util/Constants'
import Draggable from 'react-draggable'
// import LinearIndeterminate from '../../../../../Common/Progress/LinearIndeterminate'
import {Typography} from '@mui/material'
import {Divider} from '@mui/material'
import {IconButton} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
// import NumberFormat from 'react-number-format';
import {LoadingButton} from '@mui/lab'
import {connect} from 'react-redux'
import Agent from './../../../../api/agent'
import LinearIndeterminate from './../../../Common/Progress/LinearIndeterminate'
 //import * as actions from '../../../../../../store/actions/index'

const PREFIX = 'UniversityCourse'

const classes = {
  closeButton: `${PREFIX}-closeButton`,
  chip: `${PREFIX}-chip`,
}

const Root = styled('div')(({theme}) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  [`& .${classes.chip}`]: {
    marginTop: 10,
    marginRight: 5,
  },
}))

function PaperComponent(props) {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const AddRequirement = ({
  studentId,
  addRequirementToParent,
  setRequirementAddMode,
  requirementParent,
}) => {
  const [open, setOpen] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [records, setRecords] = useState(requirementParent.records)

  const [requirementName, setRequirementName] = useState(requirementParent.name)
  //const [requirementName, setRequirementName] = useState('');
  const [itemName, setItemName] = useState('')
  const [itemType, setItemType] = useState(1)

  function handleClose() {
    setOpen(false)
    setRequirementAddMode(false)
  }

  const handleFormCancel = () => {
    setOpen(false)
    setRequirementAddMode(false)
  }

  const submit = () => {
    var req = {
      id: requirementParent.id,
      studentId,
      name: requirementName,
      requirementItems: records,
    }
    setUpdating(true)
    Agent.Requirements.save(req)
      .then((id) => {
        addRequirementToParent({...req, ...{id}})
      })
      .catch((error) => {
        setUpdating(false)
        console.log(error)
        debugger
      })
  }

  return (
    <Root>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        aria-labelledby='draggable-dialog-title'
        PaperComponent={PaperComponent}
        maxWidth='md'
        fullWidth
      >
        <>
          <DialogTitle style={{cursor: 'move'}} id='draggable-dialog-title'>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant='h6' color='primary' align='center'>
                  Requirement Info
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label='close'
                  className={classes.closeButton}
                  onClick={handleFormCancel}
                  size='large'
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Divider />
          </DialogTitle>
          <DialogContent>
            <Grid container item direction='column' spacing={2}>
              <Grid item>
                <TextField
                  style={{marginTop: 0, marginBottom: 0}}
                  fullWidth
                  size='small'
                  label='Requirement Title'
                  margin='normal'
                  variant='outlined'
                  value={requirementName}
                  onChange={(e) => {
                    setRequirementName(e.target.value)
                  }}
                  inputProps={{maxLength: 100}}
                />
              </Grid>

              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    style={{marginTop: 0, marginBottom: 0}}
                    fullWidth
                    size='small'
                    label='Item '
                    margin='normal'
                    variant='outlined'
                    value={itemName}
                    onChange={(e) => {
                      setItemName(e.target.value)
                    }}
                    inputProps={{maxLength: 100}}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Select
                    fullWidth
                    size='small'
                    native
                    label='Year'
                    value={itemType}
                    onChange={(e) => {
                      setItemType(e.target.value)
                    }}
                  >
                    <option value={1}>Document</option>
                    <option value={2}>Information</option>
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <LoadingButton
                    loading={updating}
                    fullWidth
                    onClick={() => {
                      if (records.some((record) => record.name == itemName)) {
                        return
                      }
                      const newRecord = {
                        name: itemName,
                        type: itemType,
                      }
                      setRecords(records.concat(newRecord))
                    }}
                    variant='contained'
                    className='bg-sky-400 hover:bg-sky-500'
                  >
                    Add to list
                  </LoadingButton>
                </Grid>
              </Grid>
              {records.length > 0 && (
                <Grid item container direction='column' style={{marginTop: 5}}>
                  <Grid item>
                    <Typography
                      color='secondary'
                      textAlign='center'
                    >{`Following ${
                      records.length == 1 ? 'item' : 'items'
                    } will be added`}</Typography>
                    <Divider style={{marginBottom: 5}} />
                  </Grid>
                  {records.map((record, index) => (
                    <Fragment key={index}>
                      <Grid item container spacing={2}>
                        <Grid item xs={6}>
                          <Typography color='primary'>{record.name}</Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Typography color='primary'>
                            {record.type == 1 ? 'Document' : 'Information'}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            style={{paddingTop: 0}}
                            onClick={() => {
                              setRecords(
                                records.filter(
                                  (item) =>
                                    !(
                                      item.name == record.name &&
                                      item.type == record.type
                                    )
                                )
                              )
                            }}
                            size='large'
                          >
                            <CancelIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Fragment>
                  ))}
                </Grid>
              )}
            </Grid>

            {updating && (
              <Grid item style={{marginBottom: 20}}>
                <LinearIndeterminate />
              </Grid>
            )}

            <DialogActions>
              <Grid
                container
                justifyContent='space-between'
                style={{marginTop: 35}}
              >
                <Grid item>
                  <Button onClick={() => handleFormCancel()}>Cancel</Button>
                </Grid>
                <Grid item>
                  <LoadingButton
                    loading={updating}
                    disabled={!requirementName || records.length == 0}
                    onClick={submit}
                    variant='contained'
                    color='primary'
                    className='bg-sky-600'
                  >
                    Submit Requirements
                  </LoadingButton>
                </Grid>
              </Grid>
            </DialogActions>
          </DialogContent>
        </>
      </Dialog>
    </Root>
  )
}

export default AddRequirement
