import * as actionTypes from './actionTypes';
import Agent from '../../api/agent';

export const emptyCourseAllocations = () => {
  return {
    type: actionTypes.COURSE_ALLOCATIONS__EMPTY,
  };
};

const loadCourseAllocationsSuccess = (courses) => {
  return {
    type: actionTypes.COURSE_ALLOCATIONS_LOADING_SUCCESS,
    payload: {
      courses,
    },
  };
};

export const loadCourseAllocations = (universityId) => async (dispatch) => {
  //dispatch(loadUsersStart());
  const courses = await Agent.Courses.list(universityId);
  dispatch(loadCourseAllocationsSuccess(courses));
  return courses;
};

export const updateSingleCourseAllocation = (course) => {
  return {
    type: actionTypes.COURSE_ALLOCATIONS__UPDATE_COURSE,
    payload: {
      course,
    },
  };
};
