import { forwardRef, React } from 'react';
import { TextField } from '@mui/material';
import styled from '@emotion/styled';
const PREFIX = 'PhoneNumber';

const classes = {
  input: `${PREFIX}-input`,
};

const StyledTextField = styled(TextField)(({ theme }) => ({
  [`& .${classes.backgroundColor}`]: {
    input: {
      backgroundColor: 'red',
      color: 'red',
    },
  },
}));

const additionalPhoneInput = (props, ref) => {
  return (
    <StyledTextField
      {...props}
      InputProps={{
        className: classes.input,
      }}
      inputRef={ref}
      fullWidth
      size="small"
      label="Additional Phone Number"
      variant="outlined"
      name="phone"
    />
  );
};
export default forwardRef(additionalPhoneInput);