import React, { useEffect, useState, useRef } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Agent from '../../../../../../api/agent';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import MyTooltip from '../../../../../Common/Tooltip/MyTooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import RequirementItem from './RequirementItem';
import RequirementItemMob from './RequirementItemMob';

const PREFIX = 'Requirement';

const classes = {
  root: `${PREFIX}-root`,
  heading: `${PREFIX}-heading`,
  panelHeader: `${PREFIX}-panelHeader`,
  panelBody: `${PREFIX}-panelBody`,
};
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    width: '100%',
  },

  [`& .${classes.heading}`]: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
    color: 'white',
  },

  [`& .${classes.panelHeader}`]: {
    backgroundColor: theme.palette.primary.light,
    minHeight: '10px !important',
    height: '50px',
  },

  [`& .${classes.panelBody}`]: {
    paddingTop: 10,
  },
}));

const RequirementMob = ({
  requirement,
  isInternal,
  studentId,
  edit,
  showAlert,
  updateParent,
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className='flex justify-center items-center max-w-[350px]'>
      <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)  } className='w-150 gap-150'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={classes.panelHeader}
        >
          <Grid container spacing={4} alignItems={'center'} direction={'column'}>
            <Grid item>
              <Typography className={classes.heading}>
                {requirement.name}
              </Typography>
            </Grid>
            {isInternal &&
              !requirement.requirementItems.some(
                (item) => item.uploadCloudFileId || item.downloadCloudFileId
              ) && (
                <Grid item>
                  {' '}
                  <IconButton size="small" onClick={() => edit(requirement)}>
                    <EditIcon style={{ color: 'white' }} />
                  </IconButton>
                </Grid>
              )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          {requirement.requirementItems.map((item, index) => (
            <RequirementItemMob
              item={item}
              key={index}
              isInternal={isInternal}
              studentId={studentId}
              showAlert={showAlert}
              isEven={index % 2 == 0}
              updateGrandParent={updateParent}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default RequirementMob;
