import React, { useEffect, useState } from 'react';
// import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import Agent from '../../../../../api/agent';
import CloseIcon from '@mui/icons-material/Close';
import { INTAKES, STUDY_LEVELS, intakeYearList } from '../../../../../Util/Constants';
import Draggable from 'react-draggable';
import LinearIndeterminate from '../../../../Common/Progress/LinearIndeterminate';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { NumberFormatBase } from 'react-number-format';
import { LoadingButton } from '@mui/lab';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import { Countries } from '../../../../../Util/Util';

const PREFIX = 'UniversityCourse';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
  chip: `${PREFIX}-chip`,
};

// const Root = styled('div')(({ theme }) => ({
//   [`& .${classes.closeButton}`]: {
//     position: 'absolute',
//     right: theme.spacing(1),
//     top: theme.spacing(1),
//     color: theme.palette.grey[500],
//   },
//   [`& .${classes.chip}`]: {
//     marginTop: 10,
//     marginRight: 5,
//   },
// }));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const UniversityCourseAllocation = ({
  course,
  universityId,
  setAddMode,
  updateParent,
  courses,
  loadCourses,
  role,
  permissions,
  countryId,
}) => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedIntake, setSelectedIntake] = useState(INTAKES[0].id);
  const [open, setOpen] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [existingCourses, setExistingCourses] = useState([]);
  const [allocations, setAllocations] = useState([]);
  const [studyLevelId, setStudyLevelId] = useState(course.studyLevelId);
  const [tuitionFee, setTuitionFee] = useState(course.tuitionFee);
  const [courseName, setCourseName] = useState('');
  const [infoLink, setInfoLink] = useState('');
  const [selectedCourse, setSelectedCourse] = useState();
  const [openCourses, setOpenCourses] = useState(false);
  const coursesLoading = openCourses && existingCourses.length === 0;

  useEffect(() => {
    if (course.id > 0) {
      setAllocations(course.intakes);
      setCourseName(course.name);
      setInfoLink(course.infoLink);
      setSelectedCourse({
        id: course.id,
        name: course.name,
      });
    }
  }, []);

  useEffect(() => {
    let activeCourse = true;
    if (!coursesLoading) {
      return undefined;
    }

    // eslint-disable-next-line no-extra-semi
    (async () => {
      if (activeCourse) {
        if (courses.length > 0) {
          setExistingCourses(courses.filter((item) => !item.isInactive));
        } else {
          loadCourses()
            .then((list) => {
              setExistingCourses(list.filter((item) => !item.isInactive));
            })
            .catch((error) => {
              console.log(error);
              debugger;
            });
        }
      }
    })();

    return () => {
      activeCourse = false;
    };
  }, [coursesLoading]);

  function handleClose() {
    setOpen(false);
    setAddMode(false);
  }

  const handleFormCancel = () => {
    setOpen(false);
    setAddMode(false);
  };

  const saveCourse = (event) => {
    event.preventDefault();
    if (studyLevelId == 0) return;
    if (allocations.length == 0 && course.id == 0) return;

    setUpdating(true);
    if (course.id == 0) {
      // new course
      const courseList = {
        courses: {
          courseId: 0,
          universityId,
          allocations,
          studyLevelId,
          infoLink,
        },
      };
      Agent.Courses.addCourseAllocations(courseList)
        .then(() => {
          setUpdating(false);
          updateParent({
            type: 'add',
          });
          setOpen(false);
        })
        .catch((error) => {
          console.log(error);
          setUpdating(false);
          debugger;
        });
    } else {
      const modifiedCourse = {
        saveCourseDto: {
          courseId: course.id,
          universityId,
          studyLevelId,
          allocations,
          infoLink,
        },
      };
      Agent.Courses.save(modifiedCourse)
        .then(() => {
          setUpdating(false);
          updateParent({
            type: 'save',
            course: {
              id: modifiedCourse.saveCourseDto.courseId,
              name: course.name,
              infoLink,
              studyLevelId: modifiedCourse.saveCourseDto.studyLevelId,
              courseIntakes: allocations,
            },
          });
          setOpen(false);
        })
        .catch((error) => {
          console.log(error);
          setUpdating(false);
          debugger;
        });
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperComponent}
        maxWidth="md"
        fullWidth
      >
        <>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h6" color="primary" align="center">
                  {course.id ? course.name : 'Allocate Course to University'}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleFormCancel}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Divider />
          </DialogTitle>
          <DialogContent>
            <Grid container item direction="column" spacing={2}>
              <Grid item>
                <Select
                  disabled={course.id > 0}
                  size="small"
                  native
                  label="Level"
                  placeholder="Level"
                  value={studyLevelId}
                  onChange={(e) => {
                    setStudyLevelId(e.target.value);
                  }}
                >
                  <option value={0}>--Select Level--</option>
                  {STUDY_LEVELS.filter(
                    (level) => level.display == true && level.id > 0
                  ).map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.type}
                    </option>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <TextField
                  style={{ marginTop: 0, marginBottom: 0 }}
                  fullWidth
                  size="small"
                  label="Course Info Link"
                  margin="normal"
                  variant="outlined"
                  value={infoLink}
                  onChange={(e) => {
                    setInfoLink(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={10}>
                <Autocomplete
                  disabled={course.id > 0}
                  open={openCourses && courseName.length > 1}
                  onOpen={() => {
                    setOpenCourses(true);
                  }}
                  onClose={() => {
                    setOpenCourses(false);
                  }}
                  value={selectedCourse}
                  onChange={(_, newVal) => {
                    if (newVal) {
                      setSelectedCourse(newVal);
                      // setCourseName(newVal.name);
                    }
                  }}
                  loading={coursesLoading}
                  size="small"
                  fullWidth
                  // freeSolo
                  filterSelectedOptions
                  options={existingCourses}
                  getOptionLabel={(option) => `${option.name}`}
                  // sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      style={{ marginTop: 0, marginBottom: 0 }}
                      fullWidth
                      size="small"
                      {...params}
                      label="Start typing to see suggestions"
                      margin="normal"
                      variant="outlined"
                      value={courseName}
                      onChange={(e) => {
                        setCourseName(e.target.value);
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {coursesLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item container>
                <Grid item xs={3}>
                  <Select
                    fullWidth
                    size="small"
                    native
                    label="Year"
                    value={selectedYear}
                    onChange={(e) => {
                      setSelectedYear(e.target.value);
                    }}
                  >
                  {intakeYearList.map((item) => (
                      <option key={item.id} value={item.year}>
                        {item.year}
                      </option>
                    ))}
                  </Select>
                </Grid>
                
                <Grid item xs={3}>
                  <Select
                    fullWidth
                    size="small"
                    native
                    label="Year"
                    value={selectedIntake}
                    onChange={(e) => {
                      setSelectedIntake(e.target.value);
                    }}
                  >
                    {INTAKES.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.type}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={3}>
                  <NumberFormatBase
                    isNumericString={true}
                    value={tuitionFee}
                    onChange={(e) => {
                      setTuitionFee(e.target.value);
                    }}
                    // displayType={'text'}
                    thousandSeparator={true}
                    customInput={TextField}
                    size="small"
                    label={`Tuition Fee ${
                      Countries.find(({ id }) => id == countryId).currency ||
                      '£'
                    }`}
                  />
                </Grid>
                <Grid item xs={3}>
                  <LoadingButton
                    loading={updating}
                    fullWidth
                    disabled={!selectedCourse || !tuitionFee}
                    onClick={() => {
                      if (
                        allocations.some(
                          (item) =>
                            item.year == selectedYear &&
                            item.intake == selectedIntake
                        )
                      ) {
                        return;
                      }
                      const newAllocation = {
                        id: selectedCourse.id,
                        name: selectedCourse.name.trim(),
                        year: selectedYear,
                        intake: selectedIntake,
                        // intakeDesc: selectedIntake,
                        // intake: INTAKES.find(
                        //   ({ typeShort }) => typeShort == selectedIntake
                        // ).id,
                        tuitionFee: parseFloat(
                          tuitionFee
                            .toString()
                            .replace(
                              Countries.find(({ id }) => id == countryId)
                                .currency || '£',
                              ''
                            )
                            .replace(',', '')
                            .trim()
                        ),
                      };
                      setAllocations(allocations.concat(newAllocation));
                      // setSelectedCourse('');
                      setTuitionFee('');
                    }}
                    variant="contained"
                    className="bg-sky-400 hover:bg-sky-500"
                  >
                    Add to list
                  </LoadingButton>
                </Grid>
              </Grid>
              {allocations.length > 0 && (
                <Grid
                  item
                  container
                  direction="column"
                  style={{ marginTop: 5 }}
                >
                  <Grid item>
                    <Typography
                      color="secondary"
                      textAlign="center"
                    >{`Following ${
                      allocations.length == 1 ? 'intake' : 'intakes'
                    } will be added`}</Typography>
                    <Divider style={{ marginBottom: 5 }} />
                  </Grid>
                  {allocations.map((course, index) => (
                    <>
                      <Grid item container key={index}>
                        <Grid item xs={2}>
                          <Typography color="primary">{course.year}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Typography color="primary">
                            {INTAKES.find(({ id }) => id == course.intake).type}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <NumberFormatBase
                            isNumericString={true}
                            value={course.tuitionFee}
                            thousandSeparator={true}
                            prefix={`${
                              Countries.find(({ id }) => id == countryId)
                                .currency || '£'
                            } `}
                            customInput={TextField}
                            size="small"
                            displayType="text"
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            style={{ paddingTop: 0 }}
                            onClick={() => {
                              setAllocations(
                                allocations.filter(
                                  (item) =>
                                    !(
                                      item.year == course.year &&
                                      item.intake == course.intake
                                    )
                                )
                              );
                            }}
                            size="large"
                          >
                            <CancelIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Divider />
                    </>
                  ))}
                </Grid>
              )}
            </Grid>

            {updating && (
              <Grid item style={{ marginBottom: 20 }}>
                <LinearIndeterminate />
              </Grid>
            )}
            {(role.isSuperAdmin || permissions.CanManageUniversity) && (
              <DialogActions>
                <Grid
                  container
                  justifyContent="space-between"
                  style={{ marginTop: 25 }}
                >
                  <Grid item>
                    <Button onClick={() => handleFormCancel()}>Cancel</Button>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={
                        (allocations.length == 0 && course.id == 0) ||
                        (studyLevelId == 0 && course.id == 0) ||
                        !courseName
                      }
                      onClick={saveCourse}
                      variant="contained"
                      className="bg-sky-400 hover:bg-sky-500"
                    >
                      {course.id == 0 ? 'Submit' : 'Submit Changes'}
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            )}
          </DialogContent>
        </>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    courses: state.courses,
    isInternal: state.auth.isInternal,
    role: state.auth.role,
    permissions: state.auth.permissions,
    course: ownProps.course,
    universityId: ownProps.universityId,
    countryId: ownProps.countryId,
    setAddMode: ownProps.setAddMode,
    updateParent: ownProps.updateParent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadCourses: () => dispatch(actions.loadCourses()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UniversityCourseAllocation);
