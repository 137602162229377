import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import EditorDraftJs from '../../Common/Editor/EditorDraftJs';
import { LoadingButton } from '@mui/lab';
import Agent from '../../../api/agent';
import { format, addMinutes } from 'date-fns';
import Dialog from '@mui/material/Dialog';
import { TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Divider } from '@mui/material';
import StudentConversations from './StudentConversations';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const StudentNotes = ({ cancel, studentId, toName }) => {
  const [addNodeMode, setAddNodeMode] = useState(false);
  const [rawHtml, setRawHtml] = useState();
  const [updating, setUpdating] = useState(false);
  const [editorValidState, setEditorValidState] = useState(false);
  const [notes, setNotes] = useState([]);
  const editorOnChange = (rawHtml, valid) => {
    setRawHtml(rawHtml);
    setEditorValidState(valid);
  };
  console.log('toName', toName);
  const handleClose = () => {
    cancel(false);
  };
  useEffect(() => {
    Agent.Students.getStudent(studentId)
      .then((std) => {
        const { studentNotes } = std;
        console.log(std)
        studentNotes.sort(function (a, b) {
          return new Date(b.dateSent) - new Date(a.dateSent);
        });
        setNotes(studentNotes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [studentId]);

  // const addNote = () => {
  //   var msg = {
  //     content: rawHtml,
  //     studentId: studentId,
  //     sendEmail: false,
  //     noteType: 2,
  //   };
  //   setUpdating(true);
  //   Agent.Messages.addStudentNote(msg)
  //     .then(() => {
  //       let temp = records.concat({
  //         content: rawHtml,
  //         from: toName,
  //         noteType: 2,
  //         dateSent: format(
  //           addMinutes(new Date(), new Date().getTimezoneOffset()),
  //           'MMMM d, yyyy h:mm a'
  //         ),
  //       });
  //       temp.sort(function (a, b) {
  //         return new Date(b.dateSent) - new Date(a.dateSent);
  //       });
  //       setRecords(temp);
  //       setUpdating(false);
  //       setAddNodeMode(false);
  //       setEditorValidState(false);
  //     })
  //     .catch((error) => {
  //       setUpdating(false);
  //       console.log(error);
  //       debugger;
  //     });
  // };
  const addNote = () => {
    var msg = {
      content: rawHtml,
      StudentId: studentId,
      sendEmail: false,
      file: '',
      attachmentFileName:'',
      noteType: 2,
    };
    setUpdating(true);
    Agent.Messages.addStudentNoteAtt(msg)
      .then(() => {
        let temp = notes.concat({
          content: rawHtml,
          from: toName,
          noteType: 2,
          dateSent: format(
            addMinutes(new Date(), new Date().getTimezoneOffset()),
            'MMMM d, yyyy h:mm a'
          ),
        });
        temp.sort(function (a, b) {
          return new Date(b.dateSent) - new Date(a.dateSent);
        });
        setNotes(temp);
        setUpdating(false);
        setAddNodeMode(false);
        setEditorValidState(false);
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
        debugger;
      });
  };
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <Typography variant="h5" align="center" color="primary">
          Internal Notes
        </Typography>
      </DialogTitle>
      <Divider />

      <DialogActions>
        <Grid container direction="column" spacing={2} style={{ marginTop: 5 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            className="px-6"
          >
            <Button
              endIcon={!addNodeMode ? <AddIcon /> : null}
              onClick={() => {
                setAddNodeMode((prevState) => !prevState);
              }}
            >
              {addNodeMode ? 'Hide Note Option' : 'Add Internal Note'}
            </Button>
            <Grid item>
              <LoadingButton
                className="text-red-600 font-medium"
                onClick={handleClose}
              >
                Cancel
              </LoadingButton>
            </Grid>
          </Grid>
          {addNodeMode && (
            <>
              <Grid item>
                <EditorDraftJs editorOnChange={editorOnChange} height={100} />
              </Grid>
              <Grid item>
                <LoadingButton
                  loading={updating}
                  disabled={!editorValidState}
                  onClick={addNote}
                  variant="outlined"
                >
                  Add Note
                </LoadingButton>
              </Grid>
            </>
          )}

          <Grid item container>
            <StudentConversations
              messages={notes.filter((item) => item.noteType == 2)}
              user={toName}
              hideReplyOption={true}
            />
          </Grid>
        </Grid>
      </DialogActions>
      <br />
      <br />
      <hr />
    </Dialog>
  );
};

export default StudentNotes;
