/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useSearchParams } from 'react-router-dom';
import agent from '../../api/agent';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MySnackbarAlert from '../Common/AlertMessage/MySnackbarAlert';

const PREFIX = 'RegisterSuccess';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  paper: `${PREFIX}-paper`,
  cardRoot: `${PREFIX}-cardRoot`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    padding: 10,
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  [`& .${classes.cardRoot}`]: {
    width: 500,
    padding: 10,
  },
}));

const RegisterSuccess = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');

  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [alertMsg, setAlertMsg] = useState();

  const handleSuccessClick = () => {
    setAlertMsg(`Email has been successfully sent to ${email}`);
    setOpenSuccess(true);
  };

  const handleFailureClick = () => {
    setAlertMsg(`Failed to send email to ${email}. Please try again!`);
    setOpenFailure(true);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const handleCloseFailure = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenFailure(false);
  };

  const handleConfirmEmailResend = () => {
    agent.Account.resendVerificationEmail(email)
      .then(() => {
        handleSuccessClick();
      })
      .catch((error) => {
        handleFailureClick();
        console.log(error);
      });
  };

  return (
    <Root>
      <Grid item container justifyContent="center" alignItems="center">
        <Card className={classes.cardRoot}>
          <CardHeader title="Successful Registration" />
          <hr />
          <CardActionArea>
            <CardContent>
              <Typography variant="body1" color="textPrimary" component="p">
                Please check your inbox for the verification email. Before you
                can log into your account, you need to verify your account by
                clicking the link we sent to your inbox.
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                component="p"
                style={{ marginTop: 30 }}
              >
                <p>
                  Didn't receive an email? It can take a few minutes for the
                  email to arrive. If it doesn't arrive, check your junk mail or
                  you can resend the email
                </p>
              </Typography>
            </CardContent>
          </CardActionArea>
          {email && (
            <>
              <CardActions>
                <Button
                  onClick={handleConfirmEmailResend}
                  to={{ pathname: '/register', state: { role: 'Agent' } }}
                  size="large"
                  color="primary"
                  variant="outlined"
                >
                  Resend Email
                  <SendIcon color="red" style={{ marginLeft: 10 }} />
                </Button>
              </CardActions>
            </>
          )}
        </Card>
      </Grid>
      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
        />
      )}
    </Root>
  );
};

export default RegisterSuccess;
