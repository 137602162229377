import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, SvgIcon, Typography } from '@mui/material';
import * as Yup from 'yup';
import axios from 'axios';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import MyTextInput from '../Common/Form/MyTextInput';
import { ReactComponent as CompanyIcon } from '../../assets/images/TCL_Icon.svg';
import MySelectInputFormik from '../Common/Form/MySelectInputFormik';
import LinearIndeterminate from '../Common/Progress/LinearIndeterminate';
import { GEO_LOCATION_URL } from '../../Util/Constants';
import { useNavigate } from 'react-router-dom';

const PREFIX = 'Register';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  paper: `${PREFIX}-paper`,
};

const GreenCheckbox = (props) => <Checkbox color="default" {...props} />;

const Register = ({ externalRole, currentRole, onRegister }) => {
  const navigate = useNavigate();
  const role = externalRole ? externalRole : currentRole;
  const [newRole, setNewRole] = useState(externalRole ? externalRole : 'User');
  const [showCompanyName, setShowCompanyName] = useState(role === 'Agent');
  const [showUserType, setShowUserType] = useState(!externalRole);
  const [isEmailConfirmed, setIsEmailConfirmed] = useState(true);
  const [browserUserInfo, setBrowserUserInfo] = useState({
    ipAddress: '',
    city: '',
    country: '',
  });

  //creating function to load ip address from the API
  const getData = async () => {
    try {
      const res = await axios.get(GEO_LOCATION_URL);
      console.log(res.data);
      if (res.status === 200) {
        setBrowserUserInfo({
          ipAddress: res.data.IPv4,
          city: res.data.city,
          country: res.data.country_name,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  let allowedRoles = [];
  if (role === 'SuperAdmin') {
    allowedRoles = [
      {
        id: 'SeniorAdmin',
        type: 'Senior Admin',
      },
      {
        id: 'Admin',
        type: 'Admin',
      },
      {
        id: 'ApplicationTeamLeader',
        type: 'Application Team Leader',
      },
      {
        id: 'ApplicationTeam',
        type: 'Application Team',
      },
      {
        id: 'GeneralStaff',
        type: 'General Staff',
      },
      {
        id: 'Agent',
        type: 'Agent',
      },
      {
        id: 'Referral',
        type: 'Referral',
      },
    ];
  } else if (role === 'SeniorAdmin') {
    allowedRoles = [
      {
        id: 'Admin',
        type: 'Admin',
      },
      {
        id: 'GeneralStaff',
        type: 'General Staff',
      },
      {
        id: 'Agent',
        type: 'Agent',
      },
    ];
  } else if (role === 'Admin') {
    allowedRoles = [
      {
        id: 'Agent',
        type: 'Agent',
      },
    ];
  } else if (role === 'ApplicationTeamLeader') {
    allowedRoles = [
      {
        id: 'ApplicationTeam',
        type: 'Application Team',
      },
    ];
  }
  // state and functions for password
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const handleClickShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = Yup.object({
    role: Yup.string().required('You must choose a user type'),
    firstName: Yup.string()
      .required('First name is required')
      .min(2, 'Must be 2 characters or more')
      .max(50, 'Must be 50 characters or less'),
    lastName: Yup.string()
      .required('Last name is required')
      .min(2, 'Must be 2 characters or more')
      .max(50, 'Must be 50 characters or less'),
    companyName: Yup.string().when('role', {
      is: 'Agent',
      then: () =>
        Yup.string()
          .required('Company name is required')
          .min(5, 'Must be 5 characters or more')
          .max(50, 'Must be 50 characters or less')
          .typeError('Company name is required'),
    }),
    email: Yup.string()
      .email('Invalid email')
      .required('Email address is required')
      .max(60),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8}.*$/,
        'Must contain  at least 8 characters, one uppercase, one lowercase, one number'
      )
      .max(50, 'Must be 50 characters or less'),
  });

  const handleFormSubmit = async (user, { setErrors, setSubmitting }) => {
    try {
      await onRegister(
        user.email,
        user.password,
        user.firstName,
        user.lastName,
        user.role,
        isEmailConfirmed,
        !externalRole,
        user.companyName,
        browserUserInfo
      );
      setSubmitting(false);
      // ("user Created")
      navigate('/home'); // Redirect to home page after successful registration
    } catch (errors) {
      setSubmitting(false);
      let err = Array.isArray(errors) ? errors : [errors];
      setErrors({
        error: err,
      });
    }
  };

  let initValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: role,
    error: '',
    companyName: '',
  };

  return (
    <Formik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initValues}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Box
                boxShadow={5}
                bgcolor="background.paper"
                m={2}
                style={{ width: '28rem' }}
                p={2}
              >
                <div className={classes.root}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item container justifyContent="center">
                      <Grid
                        item
                        style={{
                          marginRight: '10px',
                          marginTop: '5px',
                        }}
                      >
                        <SvgIcon
                          component={CompanyIcon}
                          viewBox="200 150 750 750"
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          align="center"
                          gutterBottom
                          variant="h5"
                          component="h2"
                        >
                          {newRole} Registration
                        </Typography>
                      </Grid>
                    </Grid>
                    {showUserType && (
                      <Grid item>
                        <MySelectInputFormik
                          required={true}
                          fullWidth
                          label="User Type"
                          name="role"
                          placeholder="Choose User Type"
                          items={allowedRoles}
                          onChangeParent={(val) => {
                            setShowCompanyName(val === 'Agent');
                            setNewRole(val);
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <MyTextInput
                        required={true}
                        label="First Name"
                        name="firstName"
                        placeholder="First Name"
                        size="small"
                      />
                    </Grid>
                    <Grid item>
                      <MyTextInput
                        required={true}
                        label="Last Name"
                        name="lastName"
                        placeholder="Last Name"
                        size="small"
                      />
                    </Grid>
                    {showCompanyName && (
                      <Grid item>
                        <MyTextInput
                          required={true}
                          label="Company Name"
                          name="companyName"
                          placeholder="Company Name"
                          size="small"
                        />
                      </Grid>
                    )}
                    <Grid item>
                      <MyTextInput
                        required={true}
                        label="Email"
                        name="email"
                        placeholder="Email Address"
                        type="email"
                        size="small"
                      />
                    </Grid>
                    <Grid item>
                      <MyTextInput
                        required={true}
                        label="Password"
                        name="password"
                        placeholder="Password"
                        size="small"
                        type={passwordVisibility ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                size="large"
                              >
                                {passwordVisibility ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <ErrorMessage
                        name="error"
                        component="div"
                        style={{ color: 'red' }}
                      />
                    </Grid>
                    {showUserType && (
                      <Grid item>
                        <FormControlLabel
                          control={
                            <GreenCheckbox
                              checked={isEmailConfirmed}
                              onChange={() => {
                                setIsEmailConfirmed((prev) => !prev);
                              }}
                              name="checkedG"
                            />
                          }
                          label="Un-tick this if you want the new user to verify their email address before they can log in"
                        />
                      </Grid>
                    )}
                    <Grid item>
                      {isSubmitting && (
                        <Grid item style={{ paddingRight: 10 }}>
                          <LinearIndeterminate />
                        </Grid>
                      )}
                    </Grid>
                    <Grid item>
                      <Button
                        disabled={isSubmitting || !dirty || !isValid}
                        type="submit"
                        fullWidth
                        variant="contained"
                        className="bg-sky-400 hover:bg-sky-500"
                      >
                        Create Account
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    externalRole: ownProps.externalRole,
    currentRole: state.auth.roleName
      ? state.auth.roleName.replaceAll(' ', '')
      : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRegister: (
      email,
      password,
      firstName,
      lastName,
      role,
      isEmailConfirmed,
      isInternal,
      companyName,
      browserUserInfo
    ) =>
      dispatch(
        actions.register(
          email,
          password,
          firstName,
          lastName,
          role,
          isEmailConfirmed,
          isInternal,
          companyName,
          browserUserInfo
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
