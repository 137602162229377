import { styled } from '@mui/material/styles';
import React from 'react';
import { startOfDay, addHours } from 'date-fns';
import { TextField, FormHelperText } from '@mui/material';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // the locale you want
registerLocale('en-GB', enGB); // register it with the name you want
import 'react-datepicker/dist/react-datepicker.css';

const PREFIX = 'MyDate';

const classes = {
  reactDatepickerPopper: `${PREFIX}-reactDatepickerPopper`,
  error: `${PREFIX}-error`,
};

const Root = styled('div')(() => ({
  [`& .${classes.reactDatepickerPopper}`]: {
    zIndex: 3,
  },
  [`& .${classes.error}`]: {
    color: 'red',
  },
}));

const Input = ({
  onChange,
  placeholder,
  value,
  id,
  onClick,
  error,
  label,
  ...props
}) => (
  <TextField
    label={label}
    fullWidth
    onChange={onChange}
    placeholder={placeholder}
    value={value}
    id={id}
    onClick={onClick}
    variant="outlined"
    size="small"
    error={error}
    {...props}
  />
);

export default function MyDateInput(props) {
  return (
    <Root>
      <DatePicker
        popperClassName={classes.reactDatepickerPopper}
        autoComplete="off"
        locale="enGB"
        dateFormat="dd-MM-yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        error={true}
        selected={(props.value && new Date(props.value)) || null}
        onChange={(newVal) => {
          if (newVal) {
            newVal = addHours(startOfDay(newVal), 12);
          }
          props.handleChange(newVal);
          props.handleBlur(props.name, newVal);
        }}
        customInput={
          <Input
            label={props.label}
            error={props.errorMessage && props.isInvalid}
            {...props}
          />
        }
      />
      {props.errorMessage && props.isInvalid && (
        <FormHelperText className={classes.error}>
          {props.errorMessage}
        </FormHelperText>
      )}
    </Root>
  );
}
