import React, { useState, useEffect } from 'react';
import * as actions from '../../store/actions/index';
import {
  LicenseInfo,
  DataGridPro,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // the locale you want
registerLocale('en-GB', enGB); // register it with the name you want
import 'react-datepicker/dist/react-datepicker.css';
import { startOfDay, addHours, addMonths, format, addMinutes } from 'date-fns';
import Agent from '../../api/agent';
import LoadingView from '../Common/Loading/LoadingView';
import {
  MUI_LICENSE_KEY,
  Templates_TYPE,
  User_Activity_Type,
} from '../../Util/Constants';

function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {show && <GridToolbarColumnsButton />}
      {hide && <GridToolbarFilterButton />}
      <GridToolbarDensitySelector />
      {hide && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

const DetailModal = ({ open, onClose, detailData }) => {
  const renderDetail = (data, indent = 0) => {
    if (typeof data !== 'object' || data === null) {
      // Splitting the stringified data into lines and mapping them to JSX elements
      return JSON.stringify(data, null, 2)
        .split('\n')
        .map((line, index) => (
          <div key={index} style={{ marginLeft: `${indent * 20}px` }}>
            {line}
          </div>
        ));
    }

    return Object.entries(data).map(([key, value]) => (
      <div key={key} style={{ marginLeft: `${indent * 20}px` }}>
        <strong>{key}:</strong>
        {renderDetail(value, indent + 1)}
      </div>
    ));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle className="text-center text-xl">
        Activity Details
      </DialogTitle>
      <Button
        onClick={onClose}
        className="flex align-right"
        style={{ position: 'absolute', right: 0, top: 0 }}
      >
        Close
      </Button>
      <DialogContent
        dividers={true}
        style={{ maxHeight: '70vh', overflowY: 'auto' }}
      >
        <div className="p-2 text-center">{renderDetail(detailData)}</div>
      </DialogContent>
    </Dialog>
  );
};

const UserActivity = ({ users, loadUsers, isInternal }) => {
  const [activityLoaded, setStudentLoaded] = useState(false);
  const [start, setStart] = useState(addMonths(new Date(), -1));
  const [end, setEnd] = useState(new Date());
  const [activityType, setActivityType] = useState(0);
  const [recordId, setRecordId] = useState('');
  const [userId, setUserId] = useState(0);
  const [activityList, setActivityList] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const pageSize = 500;
  const [totalPages, setTotalPages] = useState();
  const [totalItems, setTotalItems] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [detailData, setDetailData] = useState({});

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', page.toString());
    params.append('pageSize', pageSize.toString());
    if (filter) {
      if (start) {
        params.append('StartDate', new Date(start).toDateString());
      }
    } else {
      params.append('StartDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (end) {
        params.append('EndDate', new Date(end).toDateString());
      }
    } else {
      params.append('EndDate', new Date().toDateString());
    }
    params.append('UserActivityType', activityType);

    params.append('RecordId', filter ? recordId.trim() : '');

    params.append('AppUserDetailsId', userId);

    return params;
  };

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const getRows = (rows) => {
    // console.log('activity',rows)
    let activityRows = [];
    rows.map((activity) => {
      activityRows.push({
        id: activity.id,
        name: activity.name,
        userActivityType: activity.userActivityType,
        recordId: activity.recordId,
        logIn: activity.logIn,
        logOut: activity.logOut,
        appUserDetailsId: activity.appUserDetailsId,
        adminName: activity.adminName,
        activity: activity.activity,
        activityDate:
          activity.activityDate == null
            ? ''
            : new Date(activity.activityDate).toISOString().split('T')[0],
      });
    });
    setActivityList(activityRows);
    setStudentLoaded(true);
    setLoading(false);
  };

  // Function to handle the button click, setting the right data and opening the modal
  const handleShowDetails = (rowData) => {
    setDetailData(rowData);
    setModalOpen(true);
  };

  useEffect(() => {
    if (isInternal) {
      if (users.length == 0) {
        loadUsers()
          .then(() => {
            //  getData();
          })
          .catch((error) => {
            console.log(error);
            //debugger;
          });
      } else {
        getData(true);
      }
    } else {
      getData(true);
    }
  }, [users, page]);

  let columns = [
    {
      field: 'recordId',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      hide: true,
      renderHeader: () => <strong>{'Record Id'}</strong>,
    },

    {
      field: 'userActivityType',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderHeader: () => <strong>{'🔍 Activity Type'}</strong>,
      valueGetter: (params) => {
        const userActivityType = params.row.userActivityType;
        const user = User_Activity_Type.find(
          (user) => user.id == userActivityType
        );
        return user ? user.type : '';
      },
    },

    {
      field: 'name',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>{'Action Name'}</strong>,
    },
    {
      field: 'adminName',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderHeader: () => <strong>{'Admin Name'}</strong>,
    },
    {
      field: 'activityDate',
      renderHeader: () => <strong>{'Activity Date'}</strong>,
      align: 'center',
      headerAlign: 'center',
      width: 180,
      valueGetter: (params) => {
        const activityDate = params.row.activityDate;
        return new Date(activityDate).toLocaleDateString();
      },
    },
    {
      field: 'logIn',
      renderHeader: () => <strong>{'🕒 Logged In'}</strong>,
      align: 'center',
      headerAlign: 'center',
      width: 250,
      minWidth: 200,
      maxWidth: 300,
      resizable: false,
      renderCell: (params) => {
        // Check if the logIn date is null or has the default value
        const logInDate = new Date(params.row.logIn);
        if (!logInDate.getTime() || logInDate.getFullYear() === 1) {
          return <Typography></Typography>; // Render empty for null or default dates
        }
        return (
          <Typography>
            {format(
              addMinutes(logInDate, -new Date().getTimezoneOffset()),
              'MMMM d, yyyy h:mm a'
            )}
          </Typography>
        );
      },
    },

    {
      field: 'logOut',
      renderHeader: () => <strong>{'🕒 Logged Out'}</strong>,
      align: 'center',
      headerAlign: 'center',
      width: 250,
      minWidth: 200,
      maxWidth: 300,
      resizable: false,
      renderCell: (params) => {
        // Check if the logIn date is null or has the default value
        const logOutDate = new Date(params.row.logOut);
        if (!logOutDate.getTime() || logOutDate.getFullYear() === 1) {
          return <Typography></Typography>; // Render empty for null or default dates
        }
        return (
          <Typography>
            {format(
              addMinutes(logOutDate, -new Date().getTimezoneOffset()),
              'MMMM d, yyyy h:mm a'
            )}
          </Typography>
        );
      },
    },

    {
      field: 'activity',
      headerName: 'Activity',
      renderHeader: () => <strong>{'Activity Details'}</strong>,
      width: 180,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Button
          variant="contained"
          className="bg-sky-700"
          onClick={() => handleShowDetails(params.row.activity)}
        >
          Show Details
        </Button>
      ),
    },
  ];

  const getData = (filter = false) => {
    setLoading(true);
    Agent.UserActivity.getActivitylist(getParams(filter))

      .then((leads) => {
        const { pagination, data } = leads;
        setTotalPages(pagination.totalPages);
        setTotalItems(pagination.totalItems);

        //console.log('response', data);
        getRows(data);
      })
      .catch((error) => {
        console.log(error);
        //debugger;
      });
  };
  const resetFilter = () => {
    setStart(addMonths(new Date(), -1));
    setEnd(new Date());
    setActivityType(0);
    setRecordId('');
    setUserId(0);
    getData(false);
  };

  return activityLoaded ? (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item container spacing={1}>
          <Grid item xs={2}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(start && new Date(start)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setStart(newVal);
              }}
              customInput={
                <TextField
                  label="Start Date"
                  fullWidth
                  value={start}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>
          <Grid item xs={2}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(end && new Date(end)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setEnd(newVal);
              }}
              customInput={
                <TextField
                  label="End Date"
                  fullWidth
                  // onChange={onChange}
                  value={end}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <InputLabel>Activity Type</InputLabel>

              <Select
                fullWidth
                size="small"
                native
                label="Activity Type"
                value={activityType}
                onChange={(e) => {
                  setActivityType(e.target.value);
                }}
              >
                <option value={0}>All Types</option>
                {User_Activity_Type.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.type}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label="Record Id"
                value={recordId}
                onChange={(e) => setRecordId(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl>
              <InputLabel>User Name</InputLabel>
              <Select
                fullWidth
                size="small"
                native
                label="User Name"
                value={userId}
                onChange={(e) => {
                  setUserId(e.target.value);
                }}
              >
                <option value={0}>ALL Users</option>
                {users.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container spacing={1}>
          <Grid item xs={2}>
            <Button
              fullWidth
              onClick={getData}
              variant="contained"
              className="bg-sky-400 hover:bg-sky-500"
            >
              Apply Filters
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button fullWidth onClick={resetFilter} variant="outlined">
              Reset Filters
            </Button>
          </Grid>
        </Grid>

        <Grid item style={{ height: 900, width: '100%' }}>
          <DataGridPro
            paginationMode="server"
            pagination
            pageSize={pageSize}
            rowCount={totalItems}
            page={page - 1}
            pageSizeOptions={[500]}
            onPageChange={handlePageChange}
            rows={activityList}
            columns={columns}
            loading={loading}
            density="compact"
            rowHeight={70}
            components={{
              Toolbar: () => CustomToolbar(isInternal),
            }}
          />
        </Grid>
      </Grid>

      <DetailModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        detailData={detailData}
      />
    </>
  ) : (
    <LoadingView />
  );
};
const mapStateToProps = (state) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserActivity);
