import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import {
  FormControlLabel,
  Grid,
  Typography,
  Switch,
  TextField,
} from '@mui/material';
import Agent from '../../../../api/agent';
import { LoadingButton } from '@mui/lab';

const TwoFactor = ({ currentUser, alert }) => {
  const [use2f, setUse2f] = useState(currentUser.requireVerification);
  const [phone, setPhone] = useState(currentUser.verificationPhoneNumber);
  const [email, setEmail] = useState(currentUser.verificationEmailAddress);
  const [loading, setLoading] = useState(false);
  const save = async () => {
    setLoading(true);
    await Agent.Users.save2f({
      ...currentUser,
      requireVerification: use2f,
      verificationPhoneNumber: phone,
      verificationEmailAddress: email,
    })
      .then(() => {
        setLoading(false);
        alert('Saved', true);
      })
      .catch((error) => {
        debugger;
        console.log(error);
        setLoading(false);
        alert('Failed to save', false);
      });
  };

  return (
    <Grid container direction={'column'} spacing={2}>
      <Grid item>
        <FormControlLabel
          control={
            <Switch
              checked={use2f}
              onChange={(e) => setUse2f(e.target.checked)}
              name="Use Two Factor"
            />
          }
          label={
            use2f
              ? 'User will need 2F for new device'
              : 'Click to enable 2F for new device'
          }
        />
      </Grid>
      <Grid item container spacing={1}>
        <Grid item>
          <TextField
            placeholder="Phone Number (eg +8801714321234)"
            size="small"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Grid>
        <Grid item>
          <Typography color={'secondary'} variant="caption">
            Phone number must include country code starting with + sign (eg
            +8801714321234)
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <TextField
          placeholder="Verification Email Address"
          size="small"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item>
        <LoadingButton
          loading={loading}
          onClick={save}
          disabled={use2f && (!phone || !email || !phone.startsWith('+'))}
        >
          Save Changes
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default TwoFactor;
