import { Button, Grid, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Agent from '../../../api/agent';
import { getDateDifference } from '../../../Util/Util';
const PREFIX = 'Chats';

const classes = {
  admin: `${PREFIX}-admin`,
  student: `${PREFIX}-student`,
  content: `${PREFIX}-content`,
  conversations: `${PREFIX}-conversations`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.conversations}`]: {
    background:
      '#efe7dd url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg") repeat',
  },

  [`& .${classes.admin}`]: {
    backgroundColor: '#D7FAD1',
    color: 'black',
    padding: 5,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    margin: 10,
  },

  [`& .${classes.student}`]: {
    backgroundColor: 'white',
    color: 'black',

    padding: 5,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    margin: 10,
  },

  [`& .${classes.content}`]: {
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

const DeliveryIcon = (status) =>
  status == 'read' ? (
    <DoneAllIcon color="primary" />
  ) : status == 'delivered' ? (
    <DoneAllIcon />
  ) : (
    <DoneIcon />
  );

const Chats = ({ messages }) => {
  const [replyMode, setReplyMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [newMessage, setNewMessage] = useState();

  useEffect(() => {
    setAllMessages(messages);
  }, [messages]);

  const sendReply = () => {
    setLoading(true);
  };
  const DownloadFile = async (mediaId, filename) => {
    await Agent.Chat.downloadfile(mediaId, !filename ? 'image' : filename)
      .then((response) => {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/octet-stream' })
        );
        let file = filename;
        if (!file) {
          const contentType = response.headers['content-type'];
          let ext = '';
          if (contentType == 'image/jpeg') {
            ext = 'jpg';
          } else if (contentType == 'image/jpg') {
            ext = 'jpg';
          } else if (contentType == 'image/png') {
            ext = 'png';
          } else if (contentType == 'text/plain') {
            ext = 'txt';
          } else if (contentType == 'application/pdf') {
            ext = 'pdf';
          }
          file = `${mediaId}.${ext}`;
        }
        link.download = file;
        document.body.appendChild(link);
        link.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(link);
          return Promise.resolve();
        }, 200);
      })
      .catch((error) => {
        debugger;
        console.log(error);
        return Promise.reject(error);
      });
  };

  return (
    <StyledGrid container direction="column">
      {replyMode && (
        <Grid item container direction="column" spacing={1}>
          <Grid item>
            <TextField value={newMessage} onChange={setNewMessage} />
          </Grid>
          <Grid item container>
            <Grid item xs={6}>
              <Button onClick={() => setReplyMode(false)}>Cancel</Button>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <LoadingButton
                loading={loading}
                onClick={sendReply}
                variant="outlined"
              >
                Send Reply
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      )}
      {allMessages.map((message) => (
        <Grid
          key={message.id}
          item
          container
          direction="column"
          className={classes.conversations}
        >
          <Grid
            item
            container
            xs={6}
            justifyContent={message.isInternal ? 'flex-end' : 'flex-start'}
          >
            <Grid
              item
              container
              direction={'column'}
              xs={5}
              className={message.isInternal ? classes.admin : classes.student}
            >
              {message.isInternal && (
                <Grid item container justifyContent={'flex-end'}>
                  <Grid item>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {message.sentBy}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Grid
                item
                container
                justifyContent={message.isInternal ? 'flex-end' : 'flex-start'}
              >
                <Grid item>
                  <Typography style={{ whiteSpace: 'pre-line' }}>
                    {message.templateId
                      ? message.templateId
                      : message.body
                      ? message.body
                      : message.caption}
                  </Typography>
                </Grid>
                {message.mediaId && (
                  <Grid item container>
                    <Grid item>
                      <Button
                        startIcon={<CloudDownloadIcon />}
                        onClick={() =>
                          DownloadFile(message.mediaId, message.filename)
                        }
                      >
                        <Typography
                          style={{
                            textTransform: 'none',
                            color: 'black',
                            textAlign: 'left',
                          }}
                        >
                          {message.filename ? message.filename : 'Image'}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              {message.errorMessage && (
                <Grid item container justifyContent={'flex-end'}>
                  <Typography color={'secondary'} variant="caption">
                    {message.errorMessage}
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                container
                justifyContent={'flex-end'}
                columnSpacing={1}
              >
                <Grid item>
                  <Typography align="right">
                    {`(${getDateDifference(message.sentOn, false)})`}
                  </Typography>
                </Grid>
                {message.isInternal && (
                  <Grid item>
                    <Typography align="right">
                      {DeliveryIcon(message.messageStatus)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </StyledGrid>
  );
};

export default Chats;
