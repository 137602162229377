/* eslint-disable import/default */
import { Typography } from '@mui/material';
import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import { INTAKES, STUDY_LEVELS } from '../../Util/Constants';
import StudentSearch from './StudentSearch';
import { NumberFormatBase } from 'react-number-format';
import { Countries } from '../../Util/Util';
import { motion } from 'framer-motion';

const Course = ({ course }) => {
  const [studentMode, setStudentMode] = useState(false);
  const apply = () => {
    setStudentMode(true);
  };

  return (
    <>
      {studentMode && (
        <StudentSearch
          courseAllocationId={course.courseAllocationId}
          setStudentMode={setStudentMode}
        />
      )}
      <Grid item container sx={{ p: 1, minWidth: '300px' }} spacing={2}>
        <Grid
          item
          xs={12}
          lg={4}
          container
          direction="column"
          alignItems={'center'}
          sx={{ textAlign: 'center' }}
        >
          {course.fileUrl && (
            <Grid item>
              <img
                alt="student"
                height={110}
                width={110}
                src={course.fileUrl}
              />
            </Grid>
          )}
          <Grid item alignItems={'center'}>
            <Typography
              color="secondary"
              variant="h6"
              style={{ textTransform: 'none' }}
            >
              {course.universityName}
            </Typography>
            <Typography>
              {`${course.city ? course.city + ', ' : ''} ${
                Countries.find(({ id }) => id == course.countryId).name
              }`}
            </Typography>
            {course.isScholarshipAvailable && (
              <Typography variant="body" color="#5800FF">
                Scholarship Available
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          container
          direction="column"
          spacing="20"
          alignItems={'center'}
        >
          <Grid item sx={{ textAlign: 'center' }}>
            <Typography
              color="primary"
              variant="h6"
              style={{
                textTransform: 'none',
                textAlign: 'center',
                color: '#263159',
              }}
            >
              {course.courseName}
            </Typography>
            <Typography>
              {STUDY_LEVELS.find(({ id }) => id == course.studyLevelId).type}
            </Typography>
            <Typography>
              {INTAKES.find(({ id }) => id == course.intake).type +
                ', ' +
                course.year}
            </Typography>
          </Grid>
          {course.tuitionFee > 0 && (
            <Grid item alignItems={'center'}>
              <NumberFormatBase
                isNumericString={true}
                value={course.tuitionFee}
                thousandSeparator={true}
                prefix={`Tuition Fee: ${
                  Countries.find(({ id }) => id == course.countryId).currency ||
                  '£'
                } `}
                customInput={Typography}
                // size="small"
                displayType="text"
                // color="secondary"
                style={{
                  color: '#E60965',
                  textTransform: 'uppercase',
                  fontWeight: 500,
                }}
              />
            </Grid>
          )}
          {course.infoLink && (
            <Grid
              item
              container
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Grid item xs={6} alignItems={'center'}>
                <Button
                  fullWidth
                  variant="outlined"
                  // color="primary"
                  sx={{
                    borderColor: '#000',
                    border: 1.5,
                    color: '#000',
                    borderRadius: '2px',
                    padding: 0.5,
                    '&:hover': {
                      borderColor: '#000',
                      color: '#E80F88',
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(course.infoLink.trim(), '_blank');
                  }}
                  style={{
                    fontSize: '15px',
                    // fontWeight: 'bold',
                  }}
                >
                  More Info
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          lg={4}
          container
          direction="column"
          alignItems={'center'}
          // alignContent={'center'}
          justifyContent={'center'}
        >
          <motion.div whileHover={{ scale: 1.03 }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={apply}
              className="bg-rose-500 hover:bg-rose-500"
              sx={{
                textTransform: 'none',
                height: 50,
              }}
            >
              {`Apply Now, for ${
                INTAKES.find(({ id }) => id == course.intake).type +
                ' ' +
                course.year
              }`}
            </Button>
          </motion.div>
        </Grid>
      </Grid>
    </>
  );
};

export default Course;
