/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DescriptionIcon from '@mui/icons-material/Description';
import AddIcon from '@mui/icons-material/Add';
import { format, addMinutes } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import { Divider } from '@mui/material';
import { Button } from '@mui/material';
import { CardBody } from '@material-tailwind/react';
import MySnackbarAlert from '../../../../Common/AlertMessage/MySnackbarAlert';
import ConfirmationModal from '../../../../Common/Modal/ConfirmationModal';
import { STUDENT_DOCUMENT_TYPES } from '../../../../../Util/Constants';
import { DownloadCloudFile } from '../../../../../Util/FileProcessing';
import AddRequirement from './Requirements/AddRequirement';
import Requirements from './Requirements/Requirements';
import Agent from '../../../../../api/agent';
import Add from './Add';
import useWindowSize from '../../../common/useWindowSize';
import Card from '../../../../Custom/Card/Card';
import { Typography } from '@mui/material';
import * as actions from '../../../../../store/actions';
import { connect } from 'react-redux';

const Documents = ({
  id,
  setStudentIdParent,
  studentLeadId,
  cloudFiles,
  displayError,
  setCloudFiles,
  isLocked,
  isInternal,
  profileCompleteStatus,
  permissions,
}) => {
  const [loading, setLoading] = useState(false);
  const [fileSelected, setFileSelected] = useState();
  const [studentId, setStudentId] = useState(id);
  const [files, setFiles] = useState(cloudFiles);
  const [error, setError] = useState();
  const [recordAddMode, setRecordAddMode] = useState(false);
  const [requirementAddMode, setRequirementAddMode] = useState(false);
  const [requirement, setRequirement] = useState({
    id: 0,
    name: '',
    records: [],
  });
  const [openConfirm, setOpenConfirm] = useState(false);
  const [fileNameToDelete, setFileNameToDelete] = useState();

  //requirements
  const [requirements, setRequirements] = useState([]);

  //#snackbar stuff
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [alertMsg, setAlertMsg] = useState();
  //#endregion
  const showAlert = (success) => {
    setAlertMsg(success ? 'Successfully actioned' : 'Failed to action');
    setOpenSuccess(success);
    setOpenFailure(!success);
  };

  console.log(profileCompleteStatus);

  useEffect(() => {
    let temp = [];
    files.forEach((file) => {
      temp.push({
        ...file,
        documentTypeDesc: STUDENT_DOCUMENT_TYPES.find(
          ({ id }) => id == file.documentType
        ).type,
      });
    });
    setFiles(temp);
  }, []);

  useEffect(() => {
    updateRequirements();
  }, []);

  const updateRequirements = () => {
    Agent.Requirements.list(studentId)
      .then((data) => {
        setRequirements(data);
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  const handleFileDelete = (fileName) => {
    setFiles(files.filter((file) => file.fileName != fileName));
    setCloudFiles(files.filter((file) => file.fileName != fileName));
    setOpenConfirm(false);
  };

  const addRequirementToParent = () => {
    updateRequirements();
    setRequirementAddMode(false);
  };

  const addRecordToParent = (file) => {
    setFiles(files.concat(file));
    setCloudFiles(files.concat(file));
  };

  const deleteFile = () => {
    Agent.CloudFiles.delete(fileNameToDelete, 0, studentId, 0)
      .then(() => {
        handleFileDelete(fileNameToDelete);
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };
  const edit = (item) => {
    setRequirement({
      id: item.id,
      name: item.name,
      records: item.requirementItems,
    });
    setRequirementAddMode(true);
  };
  const size = useWindowSize();

  return (
    <div className="w-full flex flex-col items-start ">
      {requirementAddMode && (
        <AddRequirement
          addRequirementToParent={addRequirementToParent}
          setRequirementAddMode={setRequirementAddMode}
          requirementParent={requirement}
          studentId={id}
        />
      )}
      {recordAddMode && (
        <Add
          addRecordToParent={addRecordToParent}
          id={id}
          setStudentIdParent={setStudentIdParent}
          setRecordAddMode={setRecordAddMode}
        />
      )}
      <div className="bg-gray-100 px-4 py-10 rounded-lg flex flex-col gap-6 items-center justify-center w-full mt-4">
        {requirements.length > 0 && (
          <>
            <div className="text-xl font-semibold text-gray-700 pb-5 text-center">
              Requirements
            </div>
            <div>
              <Requirements
                requirements={requirements}
                isInternal={isInternal}
                studentId={studentId}
                setRequirements={setRequirements}
                edit={edit}
                showAlert={showAlert}
              />
            </div>
          </>
        )}
        <div className="flex flex-col gap-2 text-center text-2xl font-semibold pb- pt-1 w-2/3 divide-y-2 divide-slate-400/70">
          <div>Documents</div>
          <div></div>
        </div>
        {displayError &&
          cloudFiles.filter((f) => f.documentType == 2).length == 0 && (
            <div className="text-base text-rose-600">
              You must at least upload a valid passport copy
            </div>
          )}
        {/* <div>
            Please upload all the necessary documents to support your
            applications here. You must at least upload your passport copy
            before you can submit your profile
        </div> */}
        {!(studentId > 0) && (
          <div className="text-lg font-medium text-gray-600 text-justify">
            You must save the student first (General Info) before you can upload
            any documents
          </div>
        )}

        {size.width <= 844 ? (
          <Card className="flex flex-row w-full h-full ">
            <CardBody>
              <Typography className="relative pt-0">
                <div>
                  <Button
                    disabled={!(studentId > 0)}
                    className="bg-indigo-100 hover:bg-indigo-100 hover:text-gray-800 text-gray-800"
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => setRecordAddMode(true)}
                  >
                    {files.length > 0 ? 'Add Another Document' : 'Add Document'}
                  </Button>
                </div>
              </Typography>
              <Typography className="relative pt-2">
                {isInternal && (
                  <div>
                    <Button
                      disabled={!(studentId > 0)}
                      color="primary"
                      variant="contained"
                      className="bg-indigo-100 hover:bg-indigo-100 hover:text-gray-800 text-gray-800"
                      startIcon={<AddIcon />}
                      onClick={() => setRequirementAddMode(true)}
                    >
                      Add Requirement
                    </Button>
                  </div>
                )}
              </Typography>
              <Typography>
                <div className="relative items-start gap-5 mt-6 w-full">
                  {files.map((file, index) => (
                    <div key={index} className="w-full">
                      <div className="relative gap-6 justify-between w-full ">
                        <div className="min-w-[70%]">
                          <div className="flex gap-2">
                            <div>
                              <DescriptionIcon className="text-indigo-600" />
                            </div>
                            <div className="text-lg font-semibold text-gray-600">
                              {`${
                                STUDENT_DOCUMENT_TYPES.find(
                                  ({ id }) => id == parseInt(file.documentType)
                                )
                                  ? STUDENT_DOCUMENT_TYPES.find(
                                      ({ id }) =>
                                        id == parseInt(file.documentType)
                                    ).type
                                  : 'unknown'
                              } ${
                                file.documentType == 19 && file.description
                                  ? ' (' + file.description + ')'
                                  : ''
                              }
                                `}
                            </div>
                          </div>
                          <div>
                            <button
                              className="w-50 text-indigo-600 min-w-full text-left hover:text-indigo-700"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(file.fileUrl, '_blank');
                              }}
                            >
                              {file.fileNameLocal}
                            </button>
                            {file.description && (
                              <div className="w-50">{`Description: ${file.description}`}</div>
                            )}
                          </div>
                          <div>
                            <div>
                              {`Added On: ${
                                file.addedOn
                                  ? format(
                                      addMinutes(
                                        new Date(file.addedOn),
                                        -new Date().getTimezoneOffset()
                                      ),
                                      'MMMM d, yyyy h:mm a'
                                    )
                                  : format(new Date(), 'MMMM d, yyyy h:mm a')
                              }`}
                            </div>
                          </div>
                        </div>
                        <Typography>
                          <div>
                            <div className="flex flex-col gap-2 py-4">
                              <LoadingButton
                                className="bg-indigo-100 hover:bg-indigo-100 hover:text-indigo-800 text-indigo-600 px-2 drop-shadow-md"
                                loading={
                                  loading && fileSelected == file.fileName
                                }
                                onClick={() => {
                                  setFileSelected(file.fileName);
                                  setLoading(true);
                                  DownloadCloudFile(
                                    file.fileNameLocal,
                                    file.fileName,
                                    file.containerName
                                  ).finally(() => {
                                    setLoading(false);
                                  });
                                }}
                                startIcon={<CloudDownloadIcon />}
                              >
                                Download
                              </LoadingButton>
                              <Button
                                disabled={error || isLocked}
                                variant="contained"
                                className="bg-rose-400 hover:bg-rose-500 text-white"
                                onClick={() => {
                                  setOpenConfirm(true);
                                  setFileNameToDelete(file.fileName);
                                }}
                                startIcon={<DeleteForeverIcon />}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </Typography>
                      </div>
                      <Divider />
                    </div>
                  ))}
                </div>
              </Typography>
            </CardBody>
          </Card>
        ) : (
          <>
            <div className="flex gap-3">
              <div>
                <Button
                  disabled={!(studentId > 0)}
                  className="min-w-[250px] bg-indigo-100 hover:bg-indigo-100 hover:text-indigo-800 text-indigo-600 px-6 drop-shadow-md"
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => setRecordAddMode(true)}
                >
                  {files.length > 0 ? 'Add Another Document' : 'Add Document'}
                </Button>
              </div>
              {isInternal && (
                <div>
                  <Button
                    disabled={!(studentId > 0)}
                    color="primary"
                    variant="contained"
                    className="min-w-[250px] bg-indigo-100 hover:bg-indigo-100 hover:text-indigo-800 text-indigo-600 px-6 drop-shadow-md"
                    startIcon={<AddIcon />}
                    onClick={() => setRequirementAddMode(true)}
                  >
                    Add Requirement
                  </Button>
                </div>
              )}
            </div>
            <div className="flex flex-col items-start gap-5 w-full">
              {files.map((file, index) => (
                <div key={index} className="w-full">
                  <div className="flex gap-4 justify-between w-full ">
                    <div className="min-w-[70%]">
                      <div className="flex gap-2">
                        <div>
                          <DescriptionIcon className="text-indigo-600" />
                        </div>
                        <div className="text-lg font-semibold text-gray-600">
                          {`${
                            STUDENT_DOCUMENT_TYPES.find(
                              ({ id }) => id == parseInt(file.documentType)
                            )
                              ? STUDENT_DOCUMENT_TYPES.find(
                                  ({ id }) => id == parseInt(file.documentType)
                                ).type
                              : 'unknown'
                          } ${
                            file.documentType == 19 && file.description
                              ? ' (' + file.description + ')'
                              : ''
                          }
                                `}
                        </div>
                      </div>
                      <div>
                        <button
                          className="w-60 text-indigo-600 min-w-full text-left hover:text-indigo-700"
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(file.fileUrl, '_blank');
                          }}
                        >
                          {file.fileNameLocal}
                        </button>
                        {file.description && (
                          <div className="w-60">{`Description: ${file.description}`}</div>
                        )}
                      </div>
                      <div>
                        <div>
                          {`Added On: ${
                            file.addedOn
                              ? format(
                                  addMinutes(
                                    new Date(file.addedOn),
                                    -new Date().getTimezoneOffset()
                                  ),
                                  'MMMM d, yyyy h:mm a'
                                )
                              : format(new Date(), 'MMMM d, yyyy h:mm a')
                          }`}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="flex flex-col gap-2">
                        <LoadingButton
                          className="bg-indigo-100 hover:bg-indigo-100 hover:text-indigo-800 text-indigo-600 px-6 drop-shadow-md"
                          loading={loading && fileSelected == file.fileName}
                          onClick={() => {
                            setFileSelected(file.fileName);
                            setLoading(true);
                            DownloadCloudFile(
                              file.fileNameLocal,
                              file.fileName,
                              file.containerName
                            ).finally(() => {
                              setLoading(false);
                            });
                          }}
                          startIcon={<CloudDownloadIcon />}
                        >
                          Download
                        </LoadingButton>

                        {(!profileCompleteStatus || permissions.CanDeleteStudentsDocument) && (
                            <Button
                              disabled={error || isLocked}
                              variant="contained"
                              className="bg-rose-400 hover:bg-rose-500 text-white"
                              onClick={() => {
                                setOpenConfirm(true);
                                setFileNameToDelete(file.fileName);
                              }}
                              startIcon={<DeleteForeverIcon />}
                            >
                              Delete
                            </Button>
                          )}

                      </div>
                    </div>
                  </div>
                  <Divider />
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={deleteFile}
        confirmText="Confirm Deletion"
        content="Are you sure that you would like to delete this document?"
      />
      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
          autoHideDuration={1000}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    isInternal: state.auth.isInternal,
    role: state.auth.role,
    users: state.users,
    name: state.auth.name,
    permissions: state.auth.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Documents);
