import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';

import Box from '@mui/material/Box';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 720,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  borderRadius: '25px',
  boxShadow: 24,
  pt: 2,
  pb: 2,
  pl: 3,
  pr: 0.5,
};

const CustomModal = ({ setTrue, setFalse, children }) => {
  const [open, setOpen] = useState(setTrue);
  const handleClose = () => setFalse(false);

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>{children}</Box>
      </Modal>
    </div>
  );
};

export default CustomModal;
