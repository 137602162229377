/* eslint-disable jsx-a11y/label-has-associated-control */
// EducationInfo.js
import React from 'react';
import { Countries } from './../../../Util/Util';
import { EDUCATION_LEVELS } from '../../../Util/Constants';
const EducationInfo = ({
  educationRecords,
  handleEducationChange,
  handleAddEducationRecord,
}) => {
  return (
    <div id="education-info">
      {console.log('eduRec', educationRecords)}
      <div>
        <h2 className="text-2xl text-center font-semibold mb-4 mt-1  text-gray-700">
          Education Information
        </h2>

        {educationRecords.map((record, index) => (
          <div
            key={index}
            className="mb-6 p-4 border rounded grid grid-cols-2 gap-2"
          >
            <div className="mb-4">
              <label className="block font-bold mb-1">Country</label>
              <select
                className="input input-bordered w-full"
                value={record.country}
                onChange={(e) =>
                  handleEducationChange(index, 'country', e.target.value)
                }
              >
                <option value="">Select country</option>
                {Countries.filter((item)=>item.id!=0).map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block font-bold mb-1">Degree</label>
              <input
                type="text"
                className="input input-bordered w-full"
                value={record.degree}
                onChange={(e) =>
                  handleEducationChange(index, 'degree', e.target.value)
                }
              />
            </div>
            <div className="mb-4">
              <label className="block font-bold mb-1">Description</label>
              <input
                type="text"
                className="input input-bordered w-full"
                value={record.description}
                onChange={(e) =>
                  handleEducationChange(index, 'description', e.target.value)
                }
              />
            </div>
            <div className="mb-4">
              <label className="block font-bold mb-1">Education Level</label>
              <select
                className="input input-bordered w-full"
                value={record.educationLevel}
                onChange={(e) =>
                  handleEducationChange(index, 'educationLevel', e.target.value)
                }
              >
                <option value="">Select your study level</option>
                {EDUCATION_LEVELS.map((study_level) => (
                  <option key={study_level.id} value={study_level.id}>
                    {study_level.type}
                  </option>
                ))}
              </select>
            </div>

            {/* <div className="mb-4">
              <label className="block font-bold mb-1">Expected End Date</label>
              <input
                type="date"
                className="input input-bordered w-full"
                value={record.expectedGraduation}
                onChange={(e) =>
                  handleEducationChange(
                    index,
                    'expectedGraduation',
                    new Date(e.target.value).toISOString()
                  )
                }
              />
            </div> */}

            <div className="mb-4">
              <label className="block font-bold mb-1">GPA</label>
              <input
                type="text"
                className="input input-bordered w-full"
                value={record.gpa}
                onChange={(e) =>
                  handleEducationChange(index, 'gpa', e.target.value)
                }
              />
            </div>
            <div className="mb-4">
              <label className="block font-bold mb-1">Institute</label>
              <input
                type="text"
                className="input input-bordered w-full"
                value={record.institute}
                onChange={(e) =>
                  handleEducationChange(index, 'institute', e.target.value)
                }
              />
            </div>
            <div className="mb-4">
              <label className="block font-bold mb-1">Maximum GPA</label>
              <input
                type="text"
                className="input input-bordered w-full"
                value={record.maximumGpa}
                onChange={(e) =>
                  handleEducationChange(index, 'maximumGpa', e.target.value)
                }
              />
            </div>

            <div className="mb-4">
              <label className="block font-bold mb-1">Passing Year</label>
              <input
                type="text"
                className="input input-bordered w-full"
                value={record.passingYear}
                onChange={(e) =>
                  handleEducationChange(index, 'passingYear', e.target.value)
                }
              />
            </div>
            <div className="mb-4">
              <label className="block font-bold mb-1">Program Name</label>
              <input
                type="text"
                className="input input-bordered w-full"
                value={record.programName}
                onChange={(e) =>
                  handleEducationChange(index, 'programName', e.target.value)
                }
              />
            </div>
            {/* <div className="mb-4">
              <label className="block font-bold mb-1">Start of Studies</label>
              <input
                type="date"
                className="input input-bordered w-full"
                value={record.startOfStudies}
                onChange={(e) =>
                  handleEducationChange(index, 'startOfStudies', e.target.value)
                }
              />
            </div> */}
            <div className="mb-4">
              <label className="block font-bold mb-1">Study Language</label>
              <input
                type="text"
                className="input input-bordered w-full"
                value={record.studyLanguage}
                onChange={(e) =>
                  handleEducationChange(index, 'studyLanguage', e.target.value)
                }
              />
            </div>
          </div>
        ))}

        <button
          type="button"
          onClick={handleAddEducationRecord}
          className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
        >
          Add Education
        </button>
      </div>
    </div>
  );
};

export default EducationInfo;
