import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import initialState from './initialState';

const reducer = (state = initialState.agents, action) => {
  switch (action.type) {
    case actionTypes.AGENTS_LOADING_START:
      return loadAgentsStart(state);
    case actionTypes.AGENTS_LOADING_SUCCESS:
      return action.agents;
    case actionTypes.AGENTS_STATUS_UPDATE:
      return updateSingleAgentStatus(state, action.payload);
    case actionTypes.AGENTS_ADMIN_UPDATE:
      return updateSingleAgentAdmin(state, action.payload);
    default:
      return state;
  }
};

const loadAgentsStart = (state) => {
  return updateObject(state);
};

const updateSingleAgentAdmin = (state, payload) => {
  if (state.length === 0) return state;
  const { id, parentUserId, parentName, childUserId, childName } = payload;
  const agent = state.find((obj) => {
    return obj.id === id;
  });
  agent.adminName = parentName;
  var updatedState = state.map((item) => {
    if (item.id === id) {
      return agent;
    }
    return item;
  });
  return updatedState;
};

const updateSingleAgentStatus = (state, payload) => {
  if (state.length === 0) return state;
  const { id, status } = payload;
  const agent = state.find((obj) => {
    return obj.id === id;
  });
  agent.status = status;
  var updatedState = state.map((item) => {
    if (item.id === id) {
      return agent;
    }
    return item;
  });
  return updatedState;
};

export default reducer;
