import * as actionTypes from '../actions/actionTypes';
import initialState from './initialState';

const reducer = (state = initialState.management, action) => {
  switch (action.type) {
    case actionTypes.MANAGEMENT_PARTNERS_LOAD:
      return { ...state, ...{ partners: action.payload.partners } };
    case actionTypes.MANAGEMENT_COURSE_LEVELS_LOAD:
      return { ...state, ...{ courseLevels: action.payload.courseLevels } };
    case actionTypes.MANAGEMENT_LANGUAGE_TESTS_LOAD:
      return { ...state, ...{ languageTests: action.payload.languageTests } };
    default:
      return state;
  }
};

export default reducer;
