import * as actionTypes from '../actions/actionTypes';
// import { updateObject } from '../utility';
import initialState from './initialState';

const reducer = (state = initialState.courseallocations, action) => {
  switch (action.type) {
    case actionTypes.COURSE_ALLOCATIONS_LOADING_SUCCESS:
      return action.payload.courses;
    case actionTypes.COURSE_ALLOCATIONS__EMPTY:
      return [];
    case actionTypes.COURSE_ALLOCATIONS__UPDATE_COURSE:
      if (action.payload.course.courseIntakes.length > 0) {
        return updateSingleCourse(state, action.payload);
      } else {
        return removeSingleCourse(state, action.payload);
      }
    default:
      return state;
  }
};

const updateSingleCourse = (state, payload) => {
  if (state.length === 0) return state;
  var updatedState = state.map((item) => {
    if (
      item.id === payload.course.id &&
      item.studyLevelId == payload.course.studyLevelId
    ) {
      return payload.course;
    }
    return item;
  });

  return updatedState.filter((item) => item.courseIntakes.length > 0);
};

const removeSingleCourse = (state, payload) => {
  if (state.length === 0) return state;
  return state.filter(
    (item) =>
      !(
        item.id === payload.course.id &&
        item.studyLevelId == payload.course.studyLevelId
      )
  );
};

export default reducer;
