import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { LoadingButton } from '@mui/lab';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import Agent from '../../../api/agent';
import { getDateDifferenceInHours } from '../../../Util/Util';
import MySnackbarAlert from '../AlertMessage/MySnackbarAlert';
import Chats from './chats';
const PHONE_NUMBER_VALIDATOR = /^\+[1-9]\d{10,14}$/; // E.164
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { ChatGetMessage, ChatJoinChat } from '../../../Util/Constants';
import { connect } from 'react-redux';

const Whatsapp = ({
  recordId,
  name,
  recipientName,
  phone,
  appUserId,
  type = 'student',
  country,
}) => {
  const [addNodeMode, setAddNodeMode] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [relaod, setReload] = useState(true);
  const [sending, setSending] = useState(false);
  const [opting, setOpting] = useState(false);
  const [enableOptIn, setEnableOptIn] = useState(false);
  const [enableSend, setEnableSend] = useState(false);
  //#snackbar stuff
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [alertMsg, setAlertMsg] = useState();
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [connection, setConnection] = useState();
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const handleTemplateChange = (e) => {
    setSelectedTemplate(e.target.value);
    // onChange={(e) => {debugger;setSelectedTemplate(e.target.value)}
    // console.log('test temp');
  };

  useEffect(() => {
    if (country) {
      if (type === 'agent') {
        // Fetch templates for agent
        Agent.Communication.getWhatsappTemplatesSingleAgent(country)
          .then((response) => {
            setTemplates(response);
          })
          .catch((error) => {
            console.error('Error fetching agent WhatsApp templates:', error);
          });
      } else {
        // Fetch templates for student (default)
        Agent.Communication.getWhatsappTemplateswithCountryId(country)
          .then((response) => {
            setTemplates(response);
          })
          .catch((error) => {
            console.error('Error fetching WhatsApp templates:', error);
          });
      }
    }
  }, [country, type]);

  useEffect(() => {
    return function cleanup() {
      console.log('connection closing');
      closeConnection();
    };
  }, []);

  const fulfilled = () => {
    //console.log('connection to hub successfull');
  };
  const rejected = () => {
    console.log('rejected');
  };
  const joinHub = async () => {
    if (!connection) {
      try {
        const conn = new HubConnectionBuilder()
          .withUrl(`${Agent.OTHER_API_URL}/comms/messagehub`)
          .configureLogging(LogLevel.Information)
          .build();
        conn.on(ChatGetMessage, function (msg) {
          if (msg.id) {
            console.log(msg);
            setMessages((messages) => [msg, ...messages]);
            setEnableSend(true);
          }
        });
        conn.onclose((e) => {
          setConnection(null);
        });
        await conn.start(fulfilled, rejected);
        await conn.invoke(ChatJoinChat, {
          name,
          phone: phone.startsWith('+')
            ? phone.substring(1).trim()
            : phone.trim(),
        });
        setConnection(conn);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const closeConnection = async () => {
    if (connection) {
      try {
        console.log('calling connection stop');
        await connection.stop();
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setIsPhoneValid(PHONE_NUMBER_VALIDATOR.test(phone));
  }, []);

  const updateAlert = (msg, success) => {
    setAlertMsg(msg);
    if (success) {
      setOpenSuccess(true);
    } else {
      setOpenFailure(true);
    }
  };

  useEffect(() => {
    if (relaod) {
      setLoading(true);
      Agent.Chat.getAllChats(
        type,
        phone.startsWith('+') ? phone.substring(1).trim() : phone.trim()
      )
        .then((doc) => {
          if (doc.chats.length > 0) {
            try {
              const record = doc.record;
              //let's try to get when uesr responded if available
              if (record.respondedOn) {
                if (record.country && record.country != country) {
                  setEnableOptIn(true);
                } else {
                  const respondedOn = new Date(record.respondedOn);
                  const hours = getDateDifferenceInHours(respondedOn);
                  setEnableOptIn(hours > 23);
                  setEnableSend(hours <= 23);
                }
              } else {
                setEnableOptIn(true);
              }
            } catch (error) {
              //
            }
            setMessages(doc.chats);
            setReload(false);
            setLoading(false);
          } else {
            //no record found
            setReload(false);
            setEnableOptIn(true);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          setReload(false);
          debugger;
        });
    }
  }, [relaod]);

  const sendOptIn = () => {
    setOpting(true);
    debugger;
    // const selectedTemplateData = templates.find(
    //   (t) => t.templateId == selectedTemplate.templateId
    // );
    // if (selectedTemplateData) {

    Agent.Chat.sendWelcome({
      to: phone,
      from: '',
      recordId,
      type,
      sentBy: name,
      body: 'sample',
      name: recipientName,
      appUserId,
      country,
      template: selectedTemplate,
    })
      .then(() => {
        Reload(1000);
        updateAlert(
          'Successfully sent the opt in message. It may take up to a minute to deliver the first message. Please refresh the list soon',
          true
        );
      })
      .catch((error) => {
        setOpting(false);
        console.log(error);
        updateAlert(
          'Failed to send the message. Please check if the phone number format is correct',
          false
        );
        debugger;
      });
  };

  console.log('test temp', selectedTemplate);
  const sendChat = () => {
    setSending(true);
    Agent.Chat.sendChat({
      to: phone,
      from: '',
      recordId,
      type,
      sentBy: name,
      body: newMessage,
      name: recipientName,
      appUserId,
      country,
    })
      .then(() => Reload(2000))
      .catch((error) => {
        setSending(false);
        console.log(error);
        updateAlert(error, false);
        debugger;
      });
  };

  console.log(type, 'type test');

  const Reload = (time) => {
    joinHub();
    setNewMessage('');
    setAddNodeMode(false);
    setTimeout(function () {
      setReload(true);
      setSending(false);
      setOpting(false);
    }, time);
  };
  return (<>
   <Grid container direction="column" spacing={2} style={{ marginTop: 5 }}>
      <Grid item container justifyContent="center">
        <Grid item>
          <Typography color="primary" variant="h5">
            WhatsApp Communications
          </Typography>
        </Grid>
        <Grid item>
          <LoadingButton
            size="large"
            title="Press to refresh this page"
            loading={loading}
            startIcon={loading ? null : <CachedIcon color="secondary" />}
            onClick={() => {
              setReload(true);
              joinHub();
            }}
          />
        </Grid>
      </Grid>
      {!isPhoneValid && (
        <Grid item>
          <Typography color={'secondary'} variant="h5">
            The phone number is not valid. Ensure it starts with + sign and
            country code (e.g., +8801944573012)
          </Typography>
        </Grid>
      )}

      <Grid item container spacing={2}>
        <Grid item xs={4}>
          <Button
            disabled={!enableSend}
            endIcon={!addNodeMode ? <WhatsAppIcon /> : null}
            onClick={() => {
              setAddNodeMode((prevState) => !prevState);
            }}
          >
            {addNodeMode ? 'Hide Chat Option' : 'Send a Chat'}
          </Button>
        </Grid>

        <Grid item xs={4}>
          <LoadingButton
            disabled={!isPhoneValid || !selectedTemplate}
            loading={opting}
            onClick={sendOptIn}
            variant="outlined"
            startIcon={<WavingHandIcon />}
          >
            Send Opt In Message
          </LoadingButton>
        </Grid>

        {
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel>Template</InputLabel>
              <Select
                size="small"
                value={selectedTemplate}
                // onChange={(e) => {debugger;setSelectedTemplate(e.target.value)}}
                onChange={handleTemplateChange}
                label="Template"
              >
                {templates.map((template) => (
                  <MenuItem key={template.id} value={template.templateId}>
                    {template.templateName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        }
      </Grid>

      {addNodeMode && (
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              size="small"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              label="Message"
              required
              rows={3}
              multiline
            />
          </Grid>
          <Grid item>
            <LoadingButton
              disabled={!newMessage}
              loading={sending}
              onClick={sendChat}
              variant="outlined"
              startIcon={<WhatsAppIcon />}
            >
              Send Chat
            </LoadingButton>
          </Grid>
        </Grid>
      )}
      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
          autoHideDuration={20000}
        />
      )}
      <Grid item container>
        <Chats messages={messages} />
      </Grid>
    </Grid>
    </> );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    role: state.auth.role,
    roleName: state.auth.roleName,
    isInternal: state.auth.isInternal,
    name: state.auth.name,
  };
};

export default connect(mapStateToProps, null)(Whatsapp);
