import React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Countries } from '../../../Util/Util';
import { FormHelperText } from '@mui/material';

const PREFIX = 'MyCountryList';

const classes = {
  option: `${PREFIX}-option`,
  inputContainer: `${PREFIX}-inputContainer`,
  label: `${PREFIX}-label`,
  error: `${PREFIX}-error`,
};

const Root = styled('div')({
  [`& .${classes.option}`]: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  [`& .${classes.inputContainer}`]: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 0 15px',
  },
  [`& .${classes.label}`]: {
    margin: '0 0 6px 0',
    fontSize: '1.1rem',
  },
  [`& .${classes.error}`]: {
    color: 'red',
  },
});

export default function MyCountryList(props) {
  const {
    label,
    name,
    handleChange,
    errorMessage,
    isInvalid,
    value,
    handleBlur,
  } = props;

  // console.log(
  //   'value provided: ' + Countries.find(({ id }) => id === value).name
  // );
  return (
    <Root>
      <Autocomplete
        size="small"
        name={name}
        // fullWidth
        value={Countries.find(({ id }) => id === value)}
        onChange={(e, newValue) => {
          handleChange(newValue ? newValue.id : null);
        }}
        options={Countries}
        classes={{
          option: classes.option,
        }}
        autoHighlight
        //getOptionSelected={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              label={label}
              variant="outlined"
              name={name}
              onBlur={(e) => {
                if (handleBlur) {
                  handleBlur(e.target.name);
                }
              }}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
              }}
              error={isInvalid}
              {...props}
              // helperText="test"
            />
            {errorMessage && isInvalid && (
              <FormHelperText className={classes.error}>
                {errorMessage}
              </FormHelperText>
            )}
          </>
        )}
      />
    </Root>
  );
}
