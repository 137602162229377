import React, { useState, useEffect } from 'react';
import {Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MdOutlineSchool } from 'react-icons/md';
import { MdFlightTakeoff } from 'react-icons/md';
import { MdOutlineDocumentScanner } from 'react-icons/md';
import Agent from '../../../api/agent';
import CustomisedCard from '../../Common/Card/CustomisedCardAgent';
import { AnimateZoomIn } from '../../Common/Animation/Animation';
import useWindowSize from '../../Students/common/useWindowSize';

const SummaryCount = () => {
  const navigate = useNavigate();

  const [studentCount, setStudentCount] = useState();
  const [studentLoading, setStudentLoading] = useState(true);
  const [Applications, setApplications] = useState();
  const [applicationCount, setApplicationCount] = useState();
  const [applicationLoading, setApplicationLoading] = useState(true);
  const [conversionCount, setConversionCount] = useState();
  const [conversionLoading, setConversionLoading] = useState(true);

  const iconSize = '5em';

  const goToApplicaiton = () => navigate('/applications');

  useEffect(() => {
    Agent.Students.listdashboard()
      .then(({ total }) => {
        setStudentCount(total);
        setStudentLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setStudentLoading(false);
      });
  }, []);
  useEffect(() => {
    Agent.Referral.listdashboardReferral()
      .then(({ total, records, conversionApplications }) => {
        setApplicationCount(total);
        setApplications(records);
        setApplicationLoading(false);
        setConversionCount(conversionApplications.length);
        setConversionLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setApplicationLoading(false);
      });
  }, []);
  const size = useWindowSize();
  return (
    <>
      {size.width > 844 ? (
        <div className="flex justify-between gap-4 rounded-lg lg:mr-12 py-5 lg:w-[950px]">
          <div className="w-[100%] w-[400px] sm:w-[100%] lg:w-[100%] lg:max-w-[350px]">
            <CustomisedCard>
              <div className="text-sky-500">
                <MdOutlineSchool size={iconSize} />
              </div>
              <div className="inline-flex items-end flex-col font-semibold text-lg">
                <p>Student Count</p>
                {!studentLoading ? (
                  <AnimateZoomIn>
                    <button
                      className="text-5xl font-bold text-sky-500"
                      onClick={() => navigate('/students')}
                    >
                      {studentCount}
                    </button>
                  </AnimateZoomIn>
                ) : (
                  <Typography color="secondary" variant="caption">
                    Loading..
                  </Typography>
                )}
              </div>
            </CustomisedCard>
          </div>
          <div className="w-[100%] w-[400px] sm:w-[100%] lg:w-[100%] lg:max-w-[350px]">
            <CustomisedCard>
              <div className="text-sky-500">
                <MdOutlineDocumentScanner size={iconSize} />
              </div>
              <div className="inline-flex items-end flex-col font-semibold text-lg">
                <p>App Count</p>
                {!applicationLoading ? (
                  <AnimateZoomIn>
                    <button
                      className="text-5xl font-bold text-sky-500"
                      onClick={() => navigate('/applications')}
                    >
                      {applicationCount}
                    </button>
                  </AnimateZoomIn>
                ) : (
                  <Typography color="secondary" variant="caption">
                    Loading..
                  </Typography>
                )}
              </div>
            </CustomisedCard>
          </div>
          <div className="w-[100%] w-[400px] sm:w-[100%] lg:w-[100%] lg:max-w-[350px]">
            <CustomisedCard>
              <div className="text-sky-500">
                <MdFlightTakeoff size={iconSize} />
              </div>
              <div className="inline-flex items-end flex-col font-semibold text-lg">
                <p>Total Conversion</p>
                {!conversionLoading ? (
                  <AnimateZoomIn>
                    <button
                      className="text-5xl font-bold text-sky-500"
                      onClick={() => navigate('/students')}
                    >
                      {conversionCount}
                    </button>
                  </AnimateZoomIn>
                ) : (
                  <Typography color="secondary" variant="caption">
                    Loading..
                  </Typography>
                )}
              </div>
            </CustomisedCard>
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap justify-between gap-4 rounded-lg  py-5">
          <div className="w-[100%] w-[370px]">
            <CustomisedCard>
              <div className="text-sky-500">
                <MdOutlineSchool size={iconSize} />
              </div>
              <div className="inline-flex items-end flex-col font-semibold text-lg">
                <p>Student Count</p>
                {!studentLoading ? (
                  <AnimateZoomIn>
                    <button
                      className="text-5xl font-bold text-sky-500"
                      onClick={() => navigate('/students')}
                    >
                      {studentCount}
                    </button>
                  </AnimateZoomIn>
                ) : (
                  <Typography color="secondary" variant="caption">
                    Loading..
                  </Typography>
                )}
              </div>
            </CustomisedCard>
          </div>
          <div className="w-[100%] w-[370px] ">
            <CustomisedCard>
              <div className="text-sky-500">
                <MdOutlineDocumentScanner size={iconSize} />
              </div>
              <div className="inline-flex items-end flex-col font-semibold text-lg">
                <p>App Count</p>
                {!applicationLoading ? (
                  <AnimateZoomIn>
                    <button
                      className="text-5xl font-bold text-sky-500"
                      onClick={() => navigate('/applications')}
                    >
                      {applicationCount}
                    </button>
                  </AnimateZoomIn>
                ) : (
                  <Typography color="secondary" variant="caption">
                    Loading..
                  </Typography>
                )}
              </div>
            </CustomisedCard>
          </div>
          <div className="w-[100%] w-[370px] ">
            <CustomisedCard>
              <div className="text-sky-500">
                <MdFlightTakeoff size={iconSize} />
              </div>
              <div className="inline-flex items-end flex-col font-semibold text-lg">
                <p>Total Conversion</p>
                {!conversionLoading ? (
                  <AnimateZoomIn>
                    <button
                      className="text-5xl font-bold text-sky-500"
                      onClick={() => navigate('/students')}
                    >
                      {conversionCount}
                    </button>
                  </AnimateZoomIn>
                ) : (
                  <Typography color="secondary" variant="caption">
                    Loading..
                  </Typography>
                )}
              </div>
            </CustomisedCard>
          </div>
        </div>
      )}
    </>
  );
};

export default SummaryCount;
