import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { IconButton } from '@mui/material';
import LinearIndeterminate from '../../../Common/Progress/LinearIndeterminate';
import Agent from '../../../../api/agent';
import { LoadingButton } from '@mui/lab';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';
import {
  ApplicationMethodPortalTypes,
  Application_Methods,
} from '../../../../Util/Constants';

const PREFIX = 'AddEditMethod';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const AddEditMethod = ({
  methodParent,
  setAddMode,
  updateParent,
  courseLevels,
  role,
  permissions,
}) => {
  const [open, setOpen] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [appMethod, setAppMethod] = useState(methodParent);

  function handleClose() {
    setOpen(false);
    setAddMode(false);
  }

  const handleFormCancel = () => {
    setOpen(false);
    setAddMode(false);
  };

  const handleChange = (e) => {
    setAppMethod({ ...appMethod, [e.target.name]: e.target.value });
  };

  const save = () => {
    setUpdating(true);
    Agent.Universities.saveApplicationMethods(appMethod, null)
      .then(() => {
        setUpdating(false);
        updateParent();
        handleFormCancel();
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
        debugger;
      });
  };

  const deleteRecord = () => {
    setUpdating(true);
    Agent.Universities.deleteMethod(appMethod.id)
      .then(() => {
        setUpdating(false);
        updateParent();
        handleFormCancel();
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
        debugger;
      });
  };

  return (
    <StyledDialog
      fullWidth
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      aria-labelledby="draggable-dialog-title"
      PaperComponent={PaperComponent}
      maxWidth="sm"
    >
      <>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h6" color="primary"  align="center">
                {appMethod.id > 0 ? 'Save Method' : 'Add Method'}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleFormCancel}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Divider />
        </DialogTitle>
        <DialogContent style={{ overflowY: 'hidden' }}>
          <Grid container direction="column" spacing={2}>
            {updating && (
              <Grid item>
                <LinearIndeterminate />
              </Grid>
            )}
            <Grid item container direction="column" spacing={2}>
              <Grid item>
                <Select
                  size="small"
                  native
                  label="Status"
                  placeholder="Status"
                  name="courseLevelId"
                  value={appMethod.courseLevelId}
                  onChange={handleChange}
                >
                  {courseLevels.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <Select
                  size="small"
                  native
                  name="method"
                  label="Application Method"
                  placeholder="Application Method"
                  value={appMethod.method}
                  onChange={handleChange}
                >
                  {Application_Methods.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <Select
                  size="small"
                  native
                  name="portalType"
                  label="Portal Type"
                  placeholder="Portal Type"
                  value={appMethod.portalType}
                  onChange={handleChange}
                >
                  {ApplicationMethodPortalTypes.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Select>
              </Grid>
              <Grid item>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  label="Portal URL"
                  name="url"
                  placeholder="Portal URL"
                  value={appMethod.url}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item>
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  label="Optional Description"
                  name="description"
                  placeholder="Optional Description"
                  value={appMethod.description}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item container>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Login U/N"
                    name="login"
                    placeholder="Login U/N"
                    value={appMethod.login}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Login P/W"
                    name="password"
                    placeholder="Login P/W"
                    value={appMethod.password}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {updating && (
            <Grid item style={{ marginBottom: 20 }}>
              <LinearIndeterminate />
            </Grid>
          )}
        </DialogContent>
        {(role.isSuperAdmin || permissions.CanManageUniversity) && (
          <DialogActions>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  color="secondary"
                  disabled={appMethod.id == 0}
                  onClick={deleteRecord}
                >
                  Delete This Record
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  loading={updating}
                  type="submit"
                  variant="contained"
                  color="primary"
                  className='bg-red-900'
                  onClick={save}
                >
                  {appMethod.id > 0 ? 'Save' : 'Add'}
                </LoadingButton>
              </Grid>
            </Grid>
          </DialogActions>
        )}
      </>
    </StyledDialog>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    methodParent: ownProps.methodParent,
    setAddMode: ownProps.setAddMode,
    updateParent: ownProps.updateParent,
    courseLevels: state.management.courseLevels,
    role: state.auth.role,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(AddEditMethod);
