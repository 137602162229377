/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Draggable from 'react-draggable';
import {
  EVENT_CATEGORIES,
  EVENT_STATUSES,
  LEADCHANNELS,
  LEADORIGINS,
  STUDENTSOURCES,
  StudentLead_Status,
} from '../../../Util/Constants';
import Agent from '../../../api/agent';
import { Countries } from '../../../Util/Util';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const LeadEditEvents = ({
  users,
  agents,
  role,
  cancel,
  leadEvent,
  action,
  bulkUpdate,
}) => {
  users.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  console.log('Action', leadEvent);
  const [ready, setReady] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [countryIdChanged, setCountryIdChanged] = useState(false);
  const [branchOfficeChanged, setBranchOfficeChanged] = useState(false);
  const [sourceChanged, setSourceChanged] = useState(false);
  const [eventDateChanged, setEventDateChanged] = useState(false);
  const [eventLocationChanged, setEventLocationChanged] = useState(false);
  const [formUrlChanged, setFormUrlChanged] = useState(false);
  const [channelChanged, setChannelChanged] = useState(false);
  const [originChanged, setOriginChanged] = useState(false);
  const [eventCategoryChanged, setEventCategoryChanged] = useState(false);
  const [isInactiveChanged, setIsInactiveChanged] = useState(false);
  const [eventBudgetChanged, setEventBudgetChanged] = useState(false);
  const [eventNameChanged, setEventNameChanged] = useState(false);
  const [countryId, setCountryId] = useState(
    leadEvent ? leadEvent.countryId : 0
  );
  const [name, setName] = useState(leadEvent ? leadEvent.name : '');
  const [eventDate, setEventDate] = useState(
    leadEvent ? leadEvent.eventDate : null
  );

  const [eventName, setEventName] = useState(
    leadEvent ? leadEvent.eventName : ''
  );
  const [eventLocation, setEventLocation] = useState(
    leadEvent ? leadEvent.eventLocation : ''
  );
  const [branchOfficeId, setBranchOfficeId] = useState(
    leadEvent ? leadEvent.branchOfficeId : 0
  );
  const [eventCategoryId, setEventCategoryId] = useState(
    leadEvent ? leadEvent.eventCategoryId : 0
  );
  const [eventStatusId, setEventStatusId] = useState(
    leadEvent ? leadEvent.eventStatusId : ''
  );
  const [leadSource, setSourceId] = useState(
    (leadEvent && leadEvent.leadSource) || 0
  );
  const [leadOrigin, setOriginId] = useState(
    leadEvent ? leadEvent.leadOrigin : 0
  );
  const [leadChannel, setChannelId] = useState(
    leadEvent ? leadEvent.leadChannel : 0
  );
  const [eventBudget, setEventBudget] = useState(
    leadEvent ? leadEvent.eventBudget : ''
  );
  const [formUrl, setFormUrl] = useState(leadEvent ? leadEvent.formUrl : '');
  const [attendanceUrl, setAttendanceUrl] = useState(
    leadEvent ? leadEvent.attendanceUrl : ''
  );
  const [id, setId] = useState(leadEvent ? leadEvent.id : '');

  const handleClose = () => {
    setDialogOpen(false);
    cancel(false);
  };
  function formatDateToISO(dateString) {
    if (!dateString) return 'null';

    const parsedDate = new Date(dateString);

    if (isNaN(parsedDate)) {
      // Handle invalid date strings
      return null;
    }

    return parsedDate.toISOString().split('T')[0];
  }
  const [offices, setOffices] = useState([]);
  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);
  const confirm = () => {
    //need to check first if agent has changed. if not stick to original value.
    //because if some users don't have access to assigned agents (for any reason), the system won't find the agent to pass it back to the caller and therefore will be null
    action({
      id: id ? id : '',
      eventName: eventName ? eventName : '',
      countryId: countryId ? parseInt(countryId) : 0,
      branchOfficeId: branchOfficeId ? branchOfficeId : 0,
      eventBudget: eventBudget ? eventBudget : 0,
      eventLocation: eventLocation ? eventLocation : '',
      formUrl: formUrl ? formUrl : '',
      leadSource: leadSource ? leadSource : '',
      leadOrigin: leadOrigin ? leadOrigin : '',
      leadChannel: leadChannel ? leadChannel : '',
      eventDate: eventDate ? formatDateToISO(eventDate) : '',
      isInactive: eventStatusId==2 ? true : false,
      eventCategoryId: eventCategoryId,
      sourceChanged,
      channelChanged,
      originChanged,
      eventBudgetChanged,
      eventLocationChanged,
      eventDateChanged,
      branchOfficeChanged,
      formUrlChanged,
      countryIdChanged,
      eventNameChanged,
      isInactiveChanged,
      eventCategoryChanged
    });
  };
  console.log('eventStatus', eventStatusId);
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        open={dialogOpen}
        maxWidth="sm"
        fullWidth
        PaperComponent={PaperComponent}
      >
        <BootstrapDialogTitle onClose={handleClose} style={{ cursor: 'move' }}>
          <Typography align="center" color="secondary" variant="h6">
            {bulkUpdate ? 'Bulk Update' : eventName}
          </Typography>
          <Divider />
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ paddingTop: 4 }}
          >
            {role?.isSuperAdmin && (
              <>
                <br />
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    native
                    size="small"
                    label="Event Name"
                    value={eventName}
                    onChange={(e) => {
                      setEventName(e.target.value);
                      setEventNameChanged(true);
                      setReady(true);
                    }}
                  />
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel>Country Name</InputLabel>
                    <Select
                      native
                      size="small"
                      value={countryId}
                      label="Country Name"
                      onChange={(e) => {
                        setCountryId(e.target.value);
                        setCountryIdChanged(true);
                        setReady(true);
                      }}
                    >
                      <option value={0}>--Country--</option>
                      {Countries.filter((item)=>item.id!=0).map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    native
                    size="small"
                    label="Event Location"
                    value={eventLocation}
                    onChange={(e) => {
                      setEventLocation(e.target.value);
                      setEventLocationChanged(true);
                      setReady(true);
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    native
                    size="small"
                    type="date"
                    label="Event Date"
                    value={eventDate}
                    onChange={(e) => {
                      setEventDate(e.target.value);
                      setEventDateChanged(true);
                      setReady(true);
                    }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <InputLabel>Branch Name</InputLabel>
                    <Select
                      native
                      size="small"
                      label="Branch Name"
                      value={branchOfficeId}
                      onChange={(e) => {
                        setBranchOfficeId(e.target.value);
                        setBranchOfficeChanged(true), setReady(true);
                      }}
                    >
                      <option value={0}>All Branches</option>
                      {offices.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel>Event Active/Inactive</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Event Active/Inactive"
                      value={eventStatusId}
                      onChange={(e) => {
                        setEventStatusId(e.target.value);
                        setIsInactiveChanged(true);
                        setReady(true);
                      }}
                    >
                      <option value={0}>--Select--</option>
                      {EVENT_STATUSES.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel>Event Category</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Event Category"
                      value={eventCategoryId}
                      onChange={(e) => {
                        setEventCategoryId(e.target.value);
                        setEventCategoryChanged(true);
                        setReady(true);
                      }}
                    >
                      <option value={0}>--Event Category--</option>
                      {EVENT_CATEGORIES.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel>Lead Origin</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Lead Origin"
                      value={leadOrigin}
                      onChange={(e) => {
                        setOriginId(e.target.value);
                        setOriginChanged(true);
                        setReady(true);
                      }}
                    >
                      <option value={0}>--Lead Origin--</option>
                      {LEADORIGINS.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel>Lead Source</InputLabel>
                    <Select
                      native
                      size="small"
                      label="Lead Source"
                      value={leadSource}
                      onChange={(e) => {
                        setSourceId(e.target.value);
                        setSourceChanged(true);
                        setReady(true);
                      }}
                    >
                      <option value={0}>--Lead Source--</option>
                      {STUDENTSOURCES.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel>Lead Channel</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Lead Channel"
                      value={leadChannel}
                      onChange={(e) => {
                        setChannelId(e.target.value);
                        setChannelChanged(true);
                        setReady(true);
                      }}
                    >
                      <option value={0}>--Lead Channel--</option>
                      {LEADCHANNELS.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={2}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Event Budget"
                    value={eventBudget}
                    onChange={(e) => {
                      setEventBudget(e.target.value);
                      setEventBudgetChanged(true);
                      setReady(true);
                    }}
                  />
                </Grid>
                <Grid item container>
                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Add Quick Lead Url"
                      value={formUrl}
                      disabled
                      onChange={(e) => {
                        setFormUrl(e.target.value);
                        setFormUrlChanged(true);
                        setReady(true);
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <CopyToClipboard text={formUrl}>
                      <IconButton size="small">
                        <FileCopyIcon />
                      </IconButton>
                    </CopyToClipboard>
                  </Grid>
                </Grid>

                <Grid item container>
                  <Grid item xs={11}>
                    <TextField
                      fullWidth
                      size="small"
                      label="Public Attendance Form Url"
                      value={attendanceUrl}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <CopyToClipboard text={attendanceUrl}>
                      <IconButton size="small">
                        <FileCopyIcon />
                      </IconButton>
                    </CopyToClipboard>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!ready}
            autoFocus
            onClick={confirm}
            variant="contained"
            className="bg-sky-400 hover:bg-sky-500"
          >
            Confirm Selection
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
