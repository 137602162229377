import React, { useState, useEffect } from 'react';
import PublicIcon from '@mui/icons-material/Public';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import { AnimateZoomIn } from '../../../Common/Animation/Animation';
import Agent from '../../../../api/agent';
import { countryFlag } from '../../../../Util/Constants';
import { Countries } from '../../../../Util/Util';
import { RegionalData, DestinationData } from './HelperFunction';
import WorldMap from '../../../../assets/images/world-map.png';

const RegionalSales = () => {
  const [conversionLoading, setConversionLoading] = useState(true);
  const [regionalSales, setRegionalSales] = useState([]);
  const [destinationSales, setDestinationSales] = useState();

  useEffect(() => {
    Agent.Applications.listdashboardAgent().then(({ conversionApplications }) => {
      setDestinationSales(RegionalData(conversionApplications));
      setRegionalSales(DestinationData(conversionApplications));
      setConversionLoading(false);
    });
  }, []);

  return (
    <div className='bg-gradient-to-r from-cyan-50 to-sky-100 glass rounded-lg p-6 shadow-sm'>
      <div className='flex flex-col md:flex-row md:justify-around space-y-6 md:space-y-0 md:space-x-6'>
        <div className='w-full md:w-1/3'>
          <div className='flex items-center mb-4'>
            <PublicIcon className='text-green-600' style={{ fontSize: 25 }} />
            <h2 className='text-2xl font-bold ml-2'>Destination Wise Sales</h2>
          </div>
          <div className='h-80 overflow-y-auto minimal-scrollbar mozilla-scroller'>
            {!conversionLoading &&
              destinationSales.map((item, index) => (
                <AnimateZoomIn key={index}>
                  <div>
                    {index !== 0 && <div className='border-t border-gray-300 my-2'></div>}
                    <div className='flex justify-between items-center py-2'>
                      <div className='flex items-center'>
                        <img
                          width='35'
                          height='25'
                          src={`${countryFlag}${Countries.find(({ id }) => id === item.universityCountry).code.toLowerCase()}.svg`}
                          alt='flag'
                        />
                        <span className='ml-2 text-lg font-medium'>
                          {Countries.find(({ id }) => id === item.universityCountry).name}
                        </span>
                      </div>
                      <span className='text-lg font-semibold'>{item.count}</span>
                    </div>
                  </div>
                </AnimateZoomIn>
              ))}
          </div>
        </div>
        <div className='w-full md:w-1/3'>
          <div className='flex items-center mb-4'>
            <InsertChartIcon className='text-yellow-500' style={{ fontSize: 25 }} />
            <h2 className='text-2xl font-bold ml-2'>Regional Sales</h2>
          </div>
          <div className='h-80 overflow-y-auto minimal-scrollbar mozilla-scroller'>
            {!conversionLoading &&
              regionalSales.map((item, index) => (
                <AnimateZoomIn key={index}>
                  <div>
                    {index !== 0 && <div className='border-t border-gray-300 my-2'></div>}
                    <div className='flex justify-between items-center py-2'>
                      <div className='flex items-center'>
                        <img
                          width='35'
                          height='25'
                          src={`${countryFlag}${Countries.find(({ id }) => id === item.studentCountry).code.toLowerCase()}.svg`}
                          alt='flag'
                        />
                        <span className='ml-2 text-lg font-medium'>
                          {Countries.find(({ id }) => id === item.studentCountry).name}
                        </span>
                      </div>
                      <span className='text-lg font-semibold'>{item.count}</span>
                    </div>
                  </div>
                </AnimateZoomIn>
              ))}
          </div>
        </div>
        <div className='w-full md:w-1/3 flex justify-center items-center'>
          <AnimateZoomIn>
            <img
              src={WorldMap}
              alt='map'
              className='max-h-60 p-2 rounded-lg shadow-sm'
            />
          </AnimateZoomIn>
        </div>
      </div>
    </div>
  );
};

export default RegionalSales;
