import { Button, Grid } from '@mui/material';
import React from 'react';
import Agent from '../../../api/agent';

const AgentCompanyLogo = ({ url, hideLogo, agentId, studentId }) => {
  const deleteLogo = () => {
    var parts = url.split('/');
    var fileName = parts[parts.length - 1];
    Agent.CloudFiles.delete(fileName, agentId, studentId, 0)
      .then(() => {
        hideLogo();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Grid container direction="column" spacing={1} alignItems="center">
      <Grid item>
        <img
          alt="company logo"
          src={url}
          style={{ maxHeight: '125px', maxWidth: '100%', objectFit: 'contain' }}
        />
      </Grid>
      <Grid item>
        <Button variant="outlined" size="small" onClick={deleteLogo}>
          Delete Logo
        </Button>
      </Grid>
    </Grid>
  );
};

export default AgentCompanyLogo;
