import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import StudentProvider from '../Students/Context/StudentProvider';
import Dashboard from './Student/Dashboard/Dashboard';
import Student from './Student/Student';
import StudentList from './StudentList';
const Students = () => {
  // const [searchParams] = useSearchParams();
  // const agentid = searchParams.get('tab');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);  //query strings is now working as URLsearch params
  const agentId = searchParams.get("agentid");
  const referralId = searchParams.get("referralId");
  return (
    <Routes>
      <Route path="/" element={<StudentList agentId={agentId} referralId={referralId}/>} />
      <Route path="/students/:id/dashboard" element={<Dashboard />} />

      <Route
        path="/students/register/:agentid"
        element={
          <StudentProvider>
            <Student />
          </StudentProvider>
        }
      />
      <Route
        path="/students/register"
        element={
          <StudentProvider>
            <Student />
          </StudentProvider>
        }
      />

      <Route
        path="/students/:id/manage/:agentid"
        element={
          <StudentProvider>
            <Student />
          </StudentProvider>
        }
      />
      <Route
        path="/students/:id/manage"
        element={
          <StudentProvider>
            <Student />
          </StudentProvider>
        }
      />
     
      <Route
        path="/students/:id/applications/:applicationid/:agentid"
        element={
          <StudentProvider>
            <Student />
          </StudentProvider>
        }
      />

      <Route
        path="/students/:id/applications/:applicationid"
        element={
          <StudentProvider>
            <Student />
          </StudentProvider>
        }
      />
    </Routes>
  );
};

export default Students;
