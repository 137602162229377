/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import EditorDraftJs from '../../Common/Editor/EditorDraftJs';
import Agent from '../../../api/agent';
import { format } from 'date-fns'; // Import date-fns for date formatting
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';

const ExternalNotes = ({ summmaryNoteRecords, leadId }) => {
  const [addNoteMode, setAddNoteMode] = useState(false);
  const [newNote, setNewNote] = useState({
    content: '',
  });
  const [successMessage, setSuccessMessage] = useState('');

  return (
    <div id="summary-info" className="bg-gray-100 p-4 rounded-lg">
      <div>
        <h2 className="text-2xl text-center font-semibold mb-4 text-indigo-600">
          External Notes
        </h2>

        <div className="space-y-4">
          {summmaryNoteRecords
            .slice()
            .reverse()
            .map((record, index) => (
              <div
                key={index}
                className="p-4 bg-white shadow-md rounded-lg border border-gray-300 mb-4"
              >
                <div className="mb-2">
                  <strong className="text-gray-800">Content:</strong>
                  <div
                    className="text-gray-600"
                    dangerouslySetInnerHTML={{
                      __html: record.content,
                    }}
                  />
                </div>

                <div className=" flex flex-row justify-between gap-6 text-gray-900 font-semibold">
                  <div></div>
                  <div>
                    Date:
                    {record.createdOn
                      ? new Date(record.createdOn).toISOString().split('T')[0]
                      : ''}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    departments: state.departments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExternalNotes);
