import {
  FormControl,
  Grid,
  TextField,
  MenuItem,
  Button,
  FormHelperText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Countries } from '../../../Util/Util';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import Agreements from '../../Agents/Agent/Agreements';
import axios from 'axios';
import { GEO_LOCATION_URL } from '../../../Util/Constants';
import Agent from '../../../api/agent';
import * as yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import phoneInput from './phoneInput';
import additionalPhoneInput from './additionalPhoneInput';
import LoadingView from '../../Common/Loading/LoadingView';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

const validationSchema = yup.object().shape({
  firstName: yup
    .string()
    .min(2, 'First Name must be at least 2 characters')
    .max(100, 'First Name must be less than 100 characters')
    .required('First Name is required'),
  lastName: yup
    .string()
    .min(2, 'Last Name must be at least 2 characters')
    .max(100, 'Last Name must be less than 100 characters')
    .required('Last Name is required'),
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
  country: yup.number().required('Country is required'),
  phoneNumber: yup.string().required('phone Number is required'),
  postalCode: yup.string().required('postal Code is required'),
  city: yup.string().required('city is required'),
  streetAddress: yup.string().required('street Address is required'),
});

const Registration = ({ onAuth }) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [additionalEmail, setAdditionalEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [additionalPhoneNumber, setAdditionalPhoneNumber] = useState(null);
  const [country, setCountry] = useState();
  const [postalCode, setPostalCode] = useState('');
  const [city, setCity] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const handleClickShowPassword = () => {
     setPasswordVisibility(!passwordVisibility);
   };
   const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [browserUserInfo, setBrowserUserInfo] = useState({
    ipAddress: '',
    city: '',
    country: '',
  });

  //creating function to load ip address from the API
  const getData = async () => {
    try {
      const res = await axios.get(GEO_LOCATION_URL);
      // console.log(res.data);
      if (res.status == 200) {
        setBrowserUserInfo({
          ipAddress: res.data.IPv4,
          city: res.data.city,
          country: res.data.country_name,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const navigate = useNavigate();

  const handleFirstName = (event) => {
    setFirstName(event.target.value);
    fieldErrors.firstName = '';
  };
  const handleLastName = (event) => {
    setLastName(event.target.value);
    fieldErrors.lastName = '';
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
    fieldErrors.email = '';
  };
  const handlePassword = (event) => {
    setPassword(event.target.value);
    fieldErrors.password = '';
  };
  const handleAdditionalEmail = (event) => {
    setAdditionalEmail(event.target.value);
  };
  const [phoneError, setPhoneError] = useState('');

  const handlePhoneNumber = (value, country) => {
    fieldErrors.phoneNumber = '';

    if (value) {
      if (/^\+[1-9]\d{1,14}$/.test(value)) {
        setPhoneNumber(value);
        setPhoneError('');
      } else {
        setPhoneNumber(null);
        setPhoneError(
          'Invalid phone number format. Include country code (+) and digits.'
        );
      }
    } else {
      // Clear any previous error and reset the phone number here
      setPhoneNumber(null);
      setPhoneError('');
    }
  };
  const [addPhoneError, setaddPhoneError] = useState('');
  const handleAdditionalPhoneNumber = (value, country) => {
    if (value) {
      if (/^\+[1-9]\d{1,14}$/.test(value)) {
        setAdditionalPhoneNumber(value);
        setaddPhoneError('');
      } else {
        setAdditionalPhoneNumber(null);
        setaddPhoneError(
          'Invalid phone number format. Include country code (+) and digits.'
        );
      }
    } else {
      // Clear any previous error and reset the phone number here
      setAdditionalPhoneNumber(null);
      setaddPhoneError('');
    }
  };
  const handleCountry = (event) => {
    setCountry(event.target.value);
    fieldErrors.country = '';
  };
  const handlePostalCode = (event) => {
    setPostalCode(event.target.value);
    fieldErrors.postalCode = '';
  };
  const handleCity = (event) => {
    setCity(event.target.value);
    fieldErrors.city = '';
  };
  const handleStreetAddress = (event) => {
    setStreetAddress(event.target.value);
    fieldErrors.streetAddress = '';
  };

  const onSubmit = async () => {
    try {
      setSuccessOpen(true);
      await validationSchema.validate(
        {
          firstName,
          lastName,
          email,
          password,
          additionalEmail,
          phoneNumber,
          additionalPhoneNumber,
          country,
          postalCode,
          city,
          streetAddress,
        },
        { abortEarly: false }
      );
      const referralData = {
        email: email,
        password: password,
        firstName: firstName,
        additionalEmail: additionalEmail,
        lastName: lastName,
        phoneNumber: phoneNumber,
        additionalPhoneNumber: additionalPhoneNumber,
        StreetAddress: streetAddress,
        city: city,
        postcode: postalCode,
        country: country,
      };
      await Agent.Referral.saveReferral(referralData)
        .then((data) => {
          setSuccessMessage('Successfully Registration Save');
          //setSuccessOpen(true);
          navigate('/home');
          //handleLogin(data.user)
        })
        .catch((error) => {
          setErrorMessage(error);
          setSuccessOpen(false);
        });
    } catch (error) {
      setSuccessOpen(false);
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setFieldErrors(errors);
    }
  };

  const successDialogStyles = {
    backgroundColor: 'bg-gray-100',
  };
  const errorDialogStyles = {
    backgroundColor: 'bg-red-400',
  };
  const handleSuccessfulOpen = () => {
    setSuccessMessage(<Agreements />);
    setSuccessOpen(true);
  };

  return (
    <>
      
      <Dialog
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        aria-labelledby="error-dialog-title"
        PaperProps={{
          className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
        }}
      >
        <DialogTitle id="error-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorMessage('')} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {successOpen ? (
        <div className="bg-gray-100 w-full min-h-screen">
          <LoadingView />
        </div>
      ) : (
        <div className="flex items-top p-4 sm:justify-center min-h-screen sm:pt-0 bg-cyan-800">
          <div className="w-full px-6 py-6 h-full mt-6 overflow-hidden bg-white shadow-md sm:max-w-md rounded-lg">
            <h2 className="text-gray-900 text-center pb-6 lg:pb-0 xl:pb-0 font-semibold">
              REFERRAL REGISTRATION
            </h2>
            <form>
              <div className="g-0 lg:flex lg:flex-wrap">
                <div className="px-4 md:px-0 lg:w-full">
                  <div className="md:mx-6 md:pt-6 pb-8 ">
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <TextField
                          label="First Name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          value={firstName}
                          error={!!fieldErrors.firstName}
                          helperText={fieldErrors.firstName}
                          onChange={handleFirstName}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Last Name"
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          value={lastName}
                          error={!!fieldErrors.lastName}
                          helperText={fieldErrors.lastName}
                          onChange={handleLastName}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Email"
                          variant="outlined"
                          size="small"
                          type="email"
                          fullWidth
                          required
                          value={email}
                          error={!!fieldErrors.email}
                          helperText={fieldErrors.email}
                          onChange={handleEmail}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Password"
                          variant="outlined"
                          size="small"
                          type={passwordVisibility ? 'text' : 'password'}
                          fullWidth
                          required
                          value={password}
                          error={!!fieldErrors.password}
                          helperText={fieldErrors.password}
                          onChange={handlePassword}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  size="large"
                                >
                                  {passwordVisibility ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Additional Email"
                          variant="outlined"
                          size="small"
                          type="email"
                          fullWidth
                          value={additionalEmail}
                          onChange={handleAdditionalEmail}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <PhoneInput
                            placeholder="Phone Number"
                            error={!!fieldErrors.phoneNumber}
                            helperText={fieldErrors.phoneNumber}
                            value={phoneNumber}
                            required
                            onChange={handlePhoneNumber}
                            inputComponent={phoneInput}
                          />
                          {phoneError && (
                            <FormHelperText error>{phoneError}</FormHelperText>
                          )}
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <FormControl fullWidth>
                          <PhoneInput
                            placeholder="Additional Phone Number"
                            value={additionalPhoneNumber}
                            onChange={handleAdditionalPhoneNumber}
                            inputComponent={additionalPhoneInput}
                          />
                          {addPhoneError && (
                            <FormHelperText error>
                              {addPhoneError}
                            </FormHelperText>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          select
                          size="small"
                          fullWidth
                          label="Country"
                          value={country}
                          error={!!fieldErrors.country}
                          helperText={
                            fieldErrors.country ? 'Country is required' : ''
                          }
                          onChange={handleCountry}
                          variant="outlined"
                          type="number"
                        >
                          {Countries.filter((item)=>item.id!=0).map((charge) => (
                            <MenuItem key={charge.id} value={charge.id}>
                              {charge.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Postal Code"
                          variant="outlined"
                          type="text"
                          size="small"
                          required
                          fullWidth
                          value={postalCode}
                          error={!!fieldErrors.postalCode}
                          helperText={fieldErrors.postalCode}
                          onChange={handlePostalCode}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="City"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          value={city}
                          error={!!fieldErrors.city}
                          helperText={fieldErrors.city}
                          onChange={handleCity}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label="Street Address"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          value={streetAddress}
                          error={!!fieldErrors.streetAddress}
                          helperText={fieldErrors.streetAddress}
                          onChange={handleStreetAddress}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center gap-24 lg:gap-36 xl:gap-36">
                <Link
                  className="text-sm text-white font-semibold px-5 py-2 rounded-md bg-red-700 hover:bg-red-500"
                  to="/referral/signin"
                >
                  Cancel
                </Link>
                <Button
                  type="button"
                  onClick={onSubmit}
                  className="inline-flex hover:bg-cyan-600 text-white items-center px-4 py-3 text-xs font-semibold bg-cyan-700 hover:bg-cyan-600"
                >
                  Register
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Registration;
