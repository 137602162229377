/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Grid,
  InputLabel,
  Paper,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material';
import Draggable from 'react-draggable';
import {
  EDUCATION_LEVELS,
  HaveIELTS,
  STUDY_LEVELS,
} from '../../../Util/Constants';
import Agent from '../../../api/agent';
import { Countries } from '../../../Util/Util';
import { LoadingButton } from '@mui/lab';
import LeadPublicNotes from './LeadPublicNotes';
import { VisibilityOff } from '@mui/icons-material';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const successDialogStyles = {
  backgroundColor: 'bg-green-500', // Green color for success
};
const successNotesDialogStyles = {
  backgroundColor: 'bg-[#598bc6]', // Green color for success
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const AttendedStudentOverview = ({ leadId, setReady }) => {
  const [dialogOpen, setDialogOpen] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastEducationLevel, setLastEducationLevel] = useState('');
  const [passingYear, setPassingYear] = useState('');
  const [cgpa, setCPGA] = useState('');
  const [preferredUniversities, setPreferredUniversities] = useState('');
  const [isPassport, setIsPassport] = useState('');
  const [ielts, setIELTS] = useState('');
  const [publicNotes, setPublicNotes] = useState('');
  const [preferenceStudyLevel, setPreferenceStudyLevel] = useState('');
  const [preferredFieldOfStudy, setPreferredFieldOfStudy] = useState('');
  const [preferredStudyDestinationList, setPreferredStudyDestinationList] =
    useState([]);
  const [preferredStudyDestination, setPreferredStudyDestination] =
    useState('');
  const [deptList, setDepartmentList] = useState([]);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successNoteOpen, setSuccessNoteOpen] = useState(false);
  const [publicNoteList, setPublicNoteList] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [successNoteMessage, setSuccessNoteMessage] = useState('');
  const [offices, setOffices] = useState([]);
  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  useEffect(() => {
    if (preferredStudyDestinationList.length > 0) {
      setPreferredFieldOfStudy(
        preferredStudyDestinationList.map(
          (dept) =>
            deptList.find(({ id }) => id === dept.departmentId)?.name + ' '
        )
      );
    } else {
      setPreferredFieldOfStudy('');
    }
  }, [preferredStudyDestinationList.length > 0]);

  useEffect(() => {
    Agent.Courses.listDepartments()
      .then((res) => {
        setDepartmentList(res);
        console.log('dept list', res);
      })
      .catch((err) => {
        console.log('dept err', err);
      });
  }, []);

  useEffect(() => {
    Agent.StudentLeads.getPublicLeadProfile(leadId)
      .then((res) => {
        console.log(res);
        setFirstName(res.firstName);
        setLastName(res.lastName);
        setIsPassport(res.isPassport ? 'Yes' : 'No');
        setLastEducationLevel(
          EDUCATION_LEVELS.find(({ id }) => id === res.lastEducation)?.type
        );
        setPreferenceStudyLevel(
          STUDY_LEVELS.find(({ id }) => id == res.preferenceStudyLevelId)?.type
        );
        setIELTS(HaveIELTS.find(({ id }) => id === res.ielts)?.type);
        setPreferredStudyDestinationList(res.studentLeadPreferenceDepartments);
        setPublicNoteList(res.studentLeadsPublicNotes);
        setPreferredStudyDestination(
          res.studentLeadPreferenceCountryRecords.length > 0
            ? res.studentLeadPreferenceCountryRecords.map(
                (country) =>
                  Countries.find(({ id }) => id === country.countryId)?.name +
                  ' '
              )
            : ''
        );
        setPassingYear(res.passingYear);
        setCPGA(res.passingPercentageOrCGPA);
        setPreferredUniversities(res.preferredUniversities);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [leadId]);
  const handleClose = () => {
    setDialogOpen(false);
    setReady(false);
    // cancel(false);
  };
  const previousNote = () => {
    setSuccessNoteMessage(<LeadPublicNotes messages={publicNoteList} />);
    setSuccessNoteOpen(true);
  };
  const addNote = () => {
    var payload = {
      Content: publicNotes,
      StudentLeadId: leadId,
    };
    Agent.StudentLeads.saveLeadPublicNote(payload).then(() => {
      setSuccessMessage('Successfully Note Add');
      setSuccessOpen(true);
      setPublicNotes('');
    });
  };
  function formatDateToISO(dateString) {
    if (!dateString) return 'null';

    const parsedDate = new Date(dateString);

    if (isNaN(parsedDate)) {
      // Handle invalid date strings
      return null;
    }

    return parsedDate.toISOString().split('T')[0];
  }

  return (
    <div>
      <Dialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        aria-labelledby="success-dialog-title"
        PaperProps={{
          className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
        }}
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>{successMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={successNoteOpen}
        onClose={() => setSuccessNoteOpen(false)}
        aria-labelledby="success-dialog-title"
        PaperProps={{
          className: successNotesDialogStyles.backgroundColor, // Apply successDialogStyles here
        }}
      > 
      <DialogActions>
          <Button onClick={() => setSuccessNoteOpen(false)} className='text-white'>
            Close
          </Button>
        </DialogActions>

        <DialogTitle id="success-dialog-title" className='flex font-semibold justify-center text-center'>
          {preferredStudyDestinationList.length > 0 ? 'Public Notes of This Lead' : 'Empty Public Notes'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{successNoteMessage}</DialogContentText>
        </DialogContent>
        
      </Dialog>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        open={dialogOpen}
        maxWidth="sm"
        PaperComponent={PaperComponent}
      >
        {/* <BootstrapDialogTitle
          onClose={handleClose}
          style={{ cursor: 'move' }}
        ></BootstrapDialogTitle> */}
        <Typography className="text-right">
          <LoadingButton
            className="font-medium pt-4 mr-4"
            onClick={handleClose}
          >
            Close
          </LoadingButton>
        </Typography>
        <Typography className="text-center fonttext-xl font-semibold text-sky-700">
          Lead Information Overview
        </Typography>
        <DialogContent className="bg-[#598bc6] mx-6 my-3 mb-6">
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ paddingTop: 4 }}
          >
            <div className="flex flex-row justify-between gap-4 pl-4">
              <Grid item>
                <InputLabel className="text-gray-900 font-semibold">
                  First Name
                </InputLabel>
                <TextField
                  className="bg-white rounded-sm"
                  native
                  size="small"
                  value={firstName}
                ></TextField>
              </Grid>
              <Grid item>
                <InputLabel className="text-gray-900 font-semibold">
                  Last Name
                </InputLabel>
                <TextField
                  className="bg-white rounded-sm"
                  native
                  size="small"
                  value={lastName}
                ></TextField>
              </Grid>
            </div>
            <div className="flex flex-row justify-between gap-4 pl-4 pt-3">
              <Grid item>
                <InputLabel className="text-gray-900 font-semibold">
                  Last Education Level
                </InputLabel>
                <TextField
                  className="bg-white rounded-sm"
                  fullWidth
                  native
                  size="small"
                  value={lastEducationLevel}
                ></TextField>
              </Grid>
              <Grid item className="relative w-[35%]">
                <InputLabel className="text-gray-900 font-semibold">
                  Passing Year
                </InputLabel>
                <TextField
                  className="bg-white rounded-sm"
                  fullWidth
                  native
                  size="small"
                  value={passingYear}
                ></TextField>
              </Grid>
              <Grid item className="relative w-[25%]">
                <InputLabel className="text-gray-900 font-semibold">
                  CGPA
                </InputLabel>
                <TextField
                  className="bg-white rounded-sm"
                  fullWidth
                  native
                  size="small"
                  value={cgpa}
                ></TextField>
              </Grid>
            </div>
            <Grid item>
              <InputLabel className="text-gray-900 font-semibold">
                Preferred University
              </InputLabel>
              <TextField
                className="bg-white rounded-sm"
                fullWidth
                native
                size="small"
                value={preferredUniversities}
              ></TextField>
            </Grid>

            <div className="flex flex-row justify-between gap-4 pl-4 pt-3">
              <Grid item>
                <InputLabel className="text-gray-900 font-semibold">
                  Preferred Study Level
                </InputLabel>
                <TextField
                  className="bg-white rounded-sm"
                  fullWidth
                  native
                  size="small"
                  value={preferenceStudyLevel}
                ></TextField>
              </Grid>
              <Grid>
                <InputLabel className="text-gray-900 font-semibold">
                  Preferred Study Destination
                </InputLabel>
                <TextField
                  className="bg-white rounded-sm"
                  fullWidth
                  native
                  size="small"
                  value={preferredStudyDestination}
                ></TextField>
              </Grid>
            </div>
            <Grid className="pl-4 pt-3">
              <InputLabel className="text-gray-900 font-semibold">
                Preferred Field Of Study
              </InputLabel>
              <TextareaAutosize
                className="bg-white rounded-sm w-[100%] p-2"
                fullWidth
                native
                size="small"
                value={preferredFieldOfStudy}
              ></TextareaAutosize>
            </Grid>
            <div className="flex flex-row justify-between gap-4 pl-4 pt-3">
              <Grid item>
                <InputLabel className="text-gray-900 font-semibold">
                  Passport Status
                </InputLabel>
                <TextField
                  className="bg-white rounded-sm"
                  fullWidth
                  native
                  size="small"
                  value={isPassport}
                ></TextField>
              </Grid>
              <Grid item>
                <InputLabel className="text-gray-900 font-semibold">
                  IELTS Status
                </InputLabel>
                <TextField
                  className="bg-white rounded-sm"
                  fullWidth
                  native
                  size="small"
                  value={ielts}
                ></TextField>
              </Grid>
            </div>
            <Grid item>
              <InputLabel className="text-gray-900 font-semibold">
                Notes
              </InputLabel>
              <TextareaAutosize
                className="bg-white rounded-sm text-medium p-2 w-[100%]"
                fullWidth
                native
                size="small"
                value={publicNotes}
                onChange={(e) => {
                  setPublicNotes(e.target.value);
                }}
              ></TextareaAutosize>
            </Grid>
            <div className="flex flex-row justify-between gap-4 pl-4 ">
              <Button
                onClick={previousNote}
                className="bg-orange-900 text-white hover:bg-orange-800 px-4"
              > <VisibilityOff/> &nbsp;
                View Notes
              </Button>
              <Button
                onClick={addNote}
                className="bg-cyan-800 text-white hover:bg-cyan-700 px-4"
              >
                Add Note
              </Button>
            </div>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
