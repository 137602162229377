import React, { useEffect, useState } from 'react';
import ReferralFooter from '../Footer/ReferralFooter';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import useWindowSize from '../../Students/common/useWindowSize';
import ReferralFooterMob from '../Footer/ReferralFooterMob';
import SummaryCount from '../SummaryCount/SummaryCount';
import MyTooltip from '../../Common/Tooltip/MyTooltip';
import { HiCheckCircle } from 'react-icons/hi';
import { CiNoWaitingSign } from 'react-icons/ci';
import {
  MenuItem,
  Button,
  TextField,
  Autocomplete,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Typography,
} from '@mui/material';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import { Countries } from '../../../Util/Util';
import {
  APPLICATION_STATUSES,
  INTAKES,
  LEADCHANNELS,
  LEADORIGINS,
  STUDENTSOURCES,
  STUDENTSTATUS,
} from '../../../Util/Constants';
import { intakeYearList } from '../../Report/MockData/MockData';
import Agent from '../../../api/agent';
import PhoneInput from 'react-phone-number-input';
import phoneInput from '../phoneInput';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconButton from '@mui/material/IconButton';
import {
  Email,
  FreeBreakfastRounded,
  Share,
  WhatsApp,
} from '@mui/icons-material';
import SendMailToStudent from '../Messages/SendMailToStudent';
import { Link, useNavigate } from 'react-router-dom';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from '@mui/lab';
import LoadingView from '../../Common/Loading/LoadingView';
import ApplicationList from './ApplicationList';
import {
  EmailIcon,
  EmailShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

const ReferralDashboard = ({
  appUserId,
  referralName,
  appUserDetailsId,
  logout,
}) => {
  const [preferredDestinations, setPreferredDestinations] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [intakeMonth, setIntakeMonth] = useState(0);
  const [intakeYear, setIntakeYear] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [phoneError, setPhoneError] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [refStudents, setRefStudents] = useState([]);
  const [referralUserInfo, SetReferralUserInfo] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);
  const [sending, setSending] = useState(false);
  const [id, setId] = useState(0);
  const [applications, setApplications] = useState([]);
  const [openApplication, setOpenApplication] = useState(false);
  const [studentName, setStudentName] = useState('');
  const [openEmailMode, setOpenEmailMode] = useState(false);
  const [draftMessage, setDraftMessage] = useState('');

  const successDialogStyles = {
    backgroundColor: 'bg-green-400',
  };
  const errorDialogStyles = {
    backgroundColor: 'bg-red-400',
  };
  const handlePhoneNumber = (value, country) => {
    fieldErrors.phoneNumber = '';
    if (value) {
      if (/^\+[1-9]\d{1,14}$/.test(value)) {
        setPhoneNumber(value);
        setPhoneError('');
      } else {
        setPhoneNumber(null);
        setPhoneError(
          'Invalid phone number format. Include country code (+) and digits.'
        );
      }
    } else {
      // Clear any previous error and reset the phone number here
      setPhoneNumber(null);
      setPhoneError('');
    }
  };
  const handOnClick = () => {
    window.open(
      `https://wa.me/?text=${referralUserInfo.refStudnentAddUrl}`,
      '_blank',
      'left=600 , width=450,height=450, top=300 '
    );
  };
  // const shareOptions = {
  //   title: 'Share WhatsApp',
  //   message: 'DEMO MESSAGE',
  //   social: Share.Social.WhatsApp,
  //   whatsAppNumber: "9199999999", //Share to a single Number
  //   };
  const handEmailOnClick = () => {
    // window.open(
    //   'https://mail.google.com/mail/?view=cm&fs=1&tf=1',
    //   '_blank',
    //   'left=600 , width=450,height=450, top=300 '
    // );
    setOpenEmailMode(true);
  };

  const handlePreferredDestination = (event, newValue) => {
    setPreferredDestinations(newValue);
    //fieldErrors.preferredDestinations = '';
  };

  const clearFields = () => {
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setPreferredDestinations([]);
    setIntakeMonth(0);
    setIntakeYear(0);
  };

  useEffect(() => {
    Agent.Referral.refStudentList(appUserId).then((res) => {
      console.log('student list', res);
      setRefStudents(res?.data);
    });
    Agent.Referral.getreferralbyuserId(appUserId).then((data) => {
      SetReferralUserInfo(data);
      setId(data.id);
      setLoadingPage(false);
      console.log('refdashboard', data);
    });
  }, [appUserId]);

  const handleSendInvitation = () => {
    setSending(true);
    const invitionData = {
      FirstName: firstName,
      LastName: lastName,
      Email: email,
      PhoneNumber: phoneNumber,
      PreferredDestinations: preferredDestinations.map(
        (destination) => destination.id
      ),
      IntakeMonth: intakeMonth,
      IntakeYear: intakeYear,
      AppUserDetailsId: appUserDetailsId,
    };
    if (
      !(
        preferredDestinations &&
        intakeMonth &&
        intakeYear &&
        email &&
        phoneNumber &&
        firstName &&
        lastName
      )
    ) {
      setErrorMessage(
        'Please, Fill up All the Fields For Sending Invitation!!'
      );
      setSending(false);
    } else if (referralUserInfo.status != 5) {
      setErrorMessage('Your User Id is Not Approved Yet!');
      setSending(false);
    } else if (
      referralUserInfo.termsAgreed == false ||
      referralUserInfo.dataPrivacyAgreed == false
      // || (isEmpty(referralUserInfo.fileUrl) && isEmpty(referralUserInfo.fileName))
    ) {
      setErrorMessage(
        'Before sending the invitation, please ensure that you have completed your profile by providing the necessary documents and agreeing to the terms and conditions'
      );
      setSending(false);
    } else {
      Agent.Referral.sendInvitation(invitionData)
        .then((res) => {
          clearFields();
        })
        .then(() => {
          setSuccessMessage('Send Successfully');
          setSuccessOpen(true);
          setSending(false);
        })
        .catch((error) => {
          console.log('error', error);
          setErrorMessage(error);
          setSending(false);
        });
    }
  };
  const handleOK = async () => {
    handleCloseConfirmDialog();
    try {
      Agent.Referral.ChangeRoleByAppuserId(appUserId, 'Student')
        .then((res) => {
          console.log('convert to student successfully');
        })
        .catch((error) => {
          console.log('role convert error', error);
        });

      logout();
    } catch (error) {
      console.error(error);
    }
  };
  const handleOpenAppPage = (student) => {
    console.log('student', student);
    setOpenApplication(false);
    Agent.Referral.getApplicationsByStudId(student.id).then((data) => {
      setApplications(data);
      setOpenApplication(true);
      setStudentName(student.firstName + ' ' + student.lastName);
      console.log('student app list', data);
    });
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };
  const ConfirmDialog = () => (
    <Dialog
      open={openConfirmDialog}
      onClose={handleCloseConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Success'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description ">
          Your Student Account Create Successfully, Please Re-login To get full
          feature!!
        </DialogContentText>
      </DialogContent>
      <DialogActions className="flex justify-between px-8 py-6">
        <div></div>
        <Button onClick={handleOK} color="success" className="btn-success">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
  const handleStudentCreateClick = async () => {
    await Agent.Referral.RegisterStudentbyRefId(id)
      .then((res) => {
        setOpenConfirmDialog(true);
        // if (
        //   window.confirm(
        //     'Student Registration Successfully, Please Re-login To get full feature!!'
        //   )
        // ) {
        //   Agent.Referral.ChangeRoleByAppuserId(appUserId, 'Student')
        //     .then((res) => {
        //       console.log('convert to student successfully');
        //     })
        //     .catch((error) => {
        //       console.log('role convert error', error);
        //     });

        //   logout();
        // }
      })
      .catch((error) => {
        console.log('error', error);
        setErrorMessage(error);
      });
  };
  const size = useWindowSize();
  return (
    <>
      {' '}
      <ConfirmDialog />
      <Dialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        aria-labelledby="success-dialog-title"
        PaperProps={{
          className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
        }}
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>{successMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        aria-labelledby="error-dialog-title"
        PaperProps={{
          className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
        }}
      >
        <DialogTitle id="error-dialog-title">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorMessage('')} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <div className=" items-center min-h-screen  min-w-screen p-0 md:justify-center pt-0 md:pl-0 bg-gray-100">
        <section className="relative pt-80 items-center sm:justify-center h-full w-full sm:overflow-scroll">
          <div className="container pt-0 mx-auto px-0">
            <div className="relative flex flex-col min-w-screen break-words bg-gray-100 w-full mb-24 shadow-6xl rounded-lg -mt-80">
              <div className="flex flex-col  lg:flex-row lg:justify-between lg:ml-24 gap-4 mr-16 pt-4">
                <div></div>
                {loadingPage ? (
                  <LoadingView />
                ) : (
                  <div className="flex flex-col lg:flex-row ml-16 py-2 lg:mr-20 xl:mr-20">
                    {(!referralUserInfo.termsAgreed ||
                      !referralUserInfo.dataPrivacyAgreed ||
                      !referralUserInfo.fileUrl) && (
                      <div className="flex justify-center items-center text-gray-100 bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 lg:mr-24  px-2 lg:mx-2 py-1 mb-6 lg:mb-0  rounded-sm">
                        <Link to="/referral/profile">
                          Complete your Profile
                        </Link>
                      </div>
                    )}
                    {!referralUserInfo.isStudent && (
                      <>
                        <div className="text-blue-500">
                          Create A Student Account using Your Existing Referral
                          User Id
                        </div>
                        <div className="lg:ml-4 lg:mr-32">
                          <button
                            onClick={handleStudentCreateClick}
                            className="text-gray-100 bg-gradient-to-r from-blue-400 via-sky-400 to-sky-500 px-6 py-1 rounded-sm"
                          >
                            Create A Student Account
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>

              <div className="mt-2 py-0 border-t border-blueGray-200 text-center">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-9/12 px-4">
                    <div className="lg:min-w-[1280px] flex mb-2 shadow-12xl">
                      <SummaryCount />
                    </div>
                    <hr />
                    {/* <div className="mb-4 text-lg leading-relaxed text-blueGray-700 bg-gray-100 rounded-xl">
                      <div className="grid gap-2 lg:grid-cols-3">
                        {posts.map((items, key) => (
                          <div
                            className="w-full py-8 rounded-lg shadow-md lg:max-w-sm"
                            key={key}
                          >
                            <div className="p-4">
                              <h4 className="text-xl font-semibold text-blue-600">
                                {items.title}
                              </h4>
                              <p className="mb-2 leading-normal">
                                {items.content}
                              </p>
                              <button className="px-4 py-2 text-sm text-blue-100 bg-blue-500 rounded shadow">
                                Read more
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="flex justify-center items-center">
                <div className="text-2xl font-medium pl-6 py-1">
                  Refer & Earn
                </div>
              </div>
              <hr className="bg-sky-200 pt-1 lg:mx-44 lg:mr-64" />

              <div className="flex flex-col lg:pl-32 xl:pl-32 pt-3 justify-between w-full px-4 mb-4 text-lg text-blueGray-700 lg:flex-row xl:flex-row">
                <div className="relative lg:ml-6 w-full lg:w-[45%] xl:w-[45%]  lg:px-6  py-1">
                  {/* <div className="">
                    <div className="text-2xl font-medium pl-2">
                      Refer & Earn
                    </div>
                  </div> */}
                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{ m: 'auto 0' }}
                      align="right"
                      variant="body2"
                    >
                      <Typography className="text-gray-900 text-xl font-medium">
                        {' '}
                        Sign up with TCL Global Referral Program
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot className="bg-red-400"></TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography>
                        Join our referral program to unlock exciting
                        opportunities
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>

                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{ m: 'auto 0' }}
                      align="right"
                      variant="body2"
                    >
                      <Typography className="text-gray-900 text-xl font-medium">
                        Invite Friends and Family
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot className="bg-sky-400"></TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography>
                        Share a quick registration link with your friends and
                        family
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{ m: 'auto 0' }}
                      align="right"
                      variant="body2"
                    >
                      <Typography className="text-gray-900 text-xl font-medium">
                        Assist in University Admissions
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot className="bg-blue-500"></TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography>
                        Help them secure admission to a foreign university with
                        our support.
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <TimelineItem>
                    <TimelineOppositeContent
                      sx={{ m: 'auto 0' }}
                      align="right"
                      variant="body2"
                    >
                      <Typography className="text-gray-900 text-xl font-medium">
                        Receive Payment
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot className="bg-blue-800"></TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Typography>
                        Earn rewards for successful referrals
                      </Typography>
                    </TimelineContent>
                  </TimelineItem>
                  <br />
                  {referralUserInfo.status==5 && !referralUserInfo.IsDisabled && <div className="flex flex-row text-xl lg:flex flex-row gap-2 ml-8 lg:ml-4 xl:ml-4 font-medium text-white mt-4 ">
                    <div className="bg-slate-600 hover:bg-slate-500 inline-flex lg:px-4 rounded-lg mb-2 lg:mb-0">
                      <CopyToClipboard
                        text={`${referralUserInfo.refStudnentAddUrl}`}
                        className="text-white "
                      >
                        <IconButton size="small">
                          <div className="flex flex-row">
                            <div>&nbsp; Copy Link &nbsp;</div>
                            <div>
                              <FileCopyIcon />
                            </div>
                          </div>
                        </IconButton>
                      </CopyToClipboard>
                    </div>
                    <div className=" flex flex-row justify-between gap-2">
                      {/* <div>
                        {appUserId > 0 && (
                          <div className="inline-flex  h-10 w-full">
                            <Button
                              variant="contained"
                              className="bg-teal-600 hover:bg-teal-500 text-white"
                              size="medium"
                              startIcon={<WhatsApp />}
                              onClick={handOnClick}
                            >
                              WhatsApp
                            </Button>
                          </div>
                        )}
                      </div> */}
                      {appUserId > 0 && (
                        <WhatsappShareButton
                          url={referralUserInfo.refStudnentAddUrl}
                          title="Hello there!⭐ I had a fantastic experience with TCL Global! Planning studies abroad can be quite overwhelming,
                            so it was great to receive their incredibly helpful assistance. I believe it could be equally beneficial for you. Here's the link to check it out👉"
                          windowHeight={800}
                          windowWidth={800}
                          onShareWindowClose={true}
                          //imageUrl="https://imgurl"
                          //imgAlt="image"
                        >
                          <Button
                            variant="contained"
                            className="bg-teal-600 hover:bg-teal-500 text-white"
                            size="medium"
                            startIcon={<WhatsApp />}
                            // onClick={handOnClick}
                          >
                            WhatsApp
                          </Button>
                        </WhatsappShareButton>
                      )}
                      {/* <EmailShareButton
                      url={referralUserInfo.refStudnentAddUrl}
                      subject="Invitation to register as student"
                      body={"Hello there!, I had a fantastic experience with TCL Global! Planning studies abroad can be quite overwhelming, so it was great to receive their incredibly helpful assistance. I believe it could be equally beneficial for you. Here's the link to check it out👉"}
                     >
                     <div>
                        {appUserId > 0 && (
                          <div className="inline-flex h-10 justify-start w-full">
                            <Button
                              variant="contained"
                              className="bg-amber-600 hover:bg-amber-500 text-white"
                              size="medium"
                              startIcon={<Email />}
                              //onClick={handEmailOnClick}
                            >
                              Message
                            </Button>
                          </div>
                        )}
                      </div>
                     
                  </EmailShareButton> */}
                    </div>
                  </div>}
                </div>
                <form className="mt-8 lg:mt-0 xl:mt-0 w-full lg:w-[50%] xl:w-[50%]">
                  <div className="pl-6">
                    <p>Send a Invitation to your referred students here.</p>
                  </div>
                  <div>
                    <div className="px-4 pt-4 lg:pt-0 xl:pt-0 md:px-0 lg:w-2/3 ">
                      <div className="md:mx-6 md:pt-6 pb-8 ">
                        <Grid container spacing={1}>
                          {/* <div className='text-center text-blue-800'>Referral Registration Form</div> */}

                          <Grid item xs={6}>
                            <TextField
                              label="First Name"
                              variant="outlined"
                              size="small"
                              fullWidth
                              required
                              error={!!fieldErrors.firstName}
                              helperText={fieldErrors.firstName}
                              value={firstName}
                              onChange={(e) => setFirstName(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              label="Last Name"
                              variant="outlined"
                              size="small"
                              fullWidth
                              required
                              error={!!fieldErrors.lastName}
                              helperText={fieldErrors.lastName}
                              value={lastName}
                              onChange={(e) => setLastName(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              label="Email"
                              variant="outlined"
                              size="small"
                              type="email"
                              fullWidth
                              required
                              error={!!fieldErrors.email}
                              helperText={fieldErrors.email}
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth>
                              <PhoneInput
                                placeholder="Phone Number"
                                error={!!fieldErrors.phoneNumber}
                                helperText={fieldErrors.phoneNumber}
                                value={phoneNumber}
                                required
                                onChange={handlePhoneNumber}
                                inputComponent={phoneInput}
                              />
                              {phoneError && (
                                <FormHelperText error>
                                  {phoneError}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>
                            <Autocomplete
                              multiple
                              options={Countries}
                              //onChange={(_, newValue) => setPreferredDestinations(newValue)}
                              onChange={handlePreferredDestination}
                              value={preferredDestinations}
                              size="small"
                              getOptionLabel={(option) => option.name}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Preferred Destinations"
                                  required
                                  placeholder="Select Preferred Destinations"
                                  error={!!fieldErrors.preferredDestinations}
                                  helperText={
                                    fieldErrors.preferredDestinations
                                      ? 'Preferred Destination is required'
                                      : ''
                                  }
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              select
                              required
                              label="Preferred Inatke Month"
                              variant="outlined"
                              size="small"
                              fullWidth
                              error={!!fieldErrors.intakeMonth}
                              helperText={fieldErrors.intakeMonth}
                              value={intakeMonth}
                              onChange={(e) => setIntakeMonth(e.target.value)}
                            >
                              <option value={0}>--select--</option>
                              {INTAKES.map((intakeMonth) => (
                                <MenuItem
                                  key={intakeMonth.id}
                                  value={intakeMonth.id}
                                >
                                  {intakeMonth.type}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              select
                              required
                              label="Preferred Intake Year"
                              placeholder="Preferred Intake Year"
                              variant="outlined"
                              size="small"
                              fullWidth
                              error={!!fieldErrors.intakeYear}
                              helperText={fieldErrors.intakeYear}
                              value={intakeYear}
                              onChange={(e) => setIntakeYear(e.target.value)}
                            >
                              <option value={0}>--select--</option>
                              {intakeYearList.map((intakeYear) => (
                                <MenuItem
                                  key={intakeYear.id}
                                  value={intakeYear.year}
                                >
                                  {intakeYear.year}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                          <div className="pl-2 pt-4">
                            <Button
                              onClick={handleSendInvitation}
                              disabled={sending}
                              className="hover:bg-cyan-600  px-4 py-3 text-xs font-semibold tracking-widest text-white uppercase transition duration-1500 ease-in-out bg-cyan-700 border border-transparent rounded-md active:bg-gray-900 true"
                            >
                              {sending ? 'Sending' : 'Send'}
                            </Button>
                          </div>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="p-6 lg:px-6 lg:ml-44 bg-gray-300 lg:w-[72%] mt-3 rounded-lg shadow-xl">
                <h2 className="text-2xl text-gray-900 font-semibold">
                  Student List
                </h2>
                <h4 className="text-xl text-gray-900">
                  A quick view of your Students List
                </h4>
                <br />
                {size.width > 844 && (
                  <div className="flex gap-1 m- p-2 pl-10 h-12 w-full text-lg font-semibold px-2 ">
                    {/* <div className={`w-[100px]`}>ID</div> */}
                    <div className={`w-[200px]`}>Student Name</div>
                    <div className={`w-[130px]`}>Applications</div>
                    <div className={`w-[140px]`}>Profile Status</div>
                    <div className="w-[160px]">Create Date</div>
                  </div>
                )}
                <div className="overflow-x-auto app-scrollbar max-h-[300px]">
                  <div className="w-full min-w-max ">
                    {size.width <= 844 && (
                      <div className="flex gap-1 m- p-2 pl-10 h-12 w-full text-lg font-semibold px-2 ">
                        {/* <div className={`w-[100px]`}>ID</div> */}
                        <div className={`w-[200px]`}>Student Name</div>
                        <div className={`w-[130px]`}>Applications</div>
                        <div className={`w-[140px]`}>Profile Status</div>
                        <div className="w-[160px]">Create Date</div>
                      </div>
                    )}
                    {refStudents?.map((item, idx) => {
                      return (
                        <div key={idx}>
                          <button
                            onClick={() => handleOpenAppPage(item)}
                            className="flex items-center gap-1 bg-white text-gray-700 rounded-lg p-2 my-2 h-12  hover:bg-sky-300 hover:text-white cursor-pointer transition-all duration-100 text-base "
                          >
                            {/* <div className="w-[100px]">{item.id}</div> */}

                            <div className="w-[200px]">
                              {item.firstName + ' ' + item.lastName}
                            </div>
                            <div className="w-[130px] pl-8">
                              {item.noOfApplications}
                            </div>
                            <div className="w-[140px] pl-8">
                              <strong>
                                {item.profileComplete ? (
                                  <MyTooltip
                                    icon={<HiCheckCircle color="success" />}
                                    title="Profile Completed"
                                  >
                                    <IconButton size="large">
                                      <HiCheckCircle color="#03C988" />
                                    </IconButton>
                                  </MyTooltip>
                                ) : (
                                  <MyTooltip
                                    icon={<HiCheckCircle />}
                                    title="Profile is Not Complete"
                                  >
                                    <IconButton size="large">
                                      <CiNoWaitingSign color="red" />
                                    </IconButton>
                                  </MyTooltip>
                                )}
                              </strong>
                            </div>
                            <div className="w-[160px] pl-0">
                              {item.createdOn
                                ? item.createdOn.split('T')[0]
                                : ''}
                            </div>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {size.width > 844 ? <ReferralFooter /> : <ReferralFooterMob />}
      </div>
      {openApplication && (
        <ApplicationList
          applications={applications}
          studentName={studentName}
          cancel={setOpenApplication}
        />
      )}
      {openEmailMode && (
        <SendMailToStudent
          studentName={studentName}
          draftMessageParent={draftMessage}
          setDraftMessageParent={setDraftMessage}
          ReferralId={id}
          setOpenEmailMode={setOpenEmailMode}
          openEmailMode={true}
        />
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    referralName: state.auth.name,
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralDashboard);
