import * as actionTypes from './actionTypes';
import Agent from '../../api/agent';

const loadDepartmentsSuccess = (depts) => {
  return {
    type: actionTypes.DEPARTMENTS_LOADING_SUCCESS,
    payload: {
      depts,
    },
  };
};

export const addDept = (dept) => {
  return {
    type: actionTypes.DEPARTMENTS_ADD,
    payload: {
      dept,
    },
  };
};

export const loadDepts = () => async (dispatch) => {
  //dispatch(loadUsersStart());
  const depts = await Agent.Courses.listDepartments();
  dispatch(loadDepartmentsSuccess(depts));
  return depts;
};

export const updateSingleDept = (dept) => {
  return {
    type: actionTypes.DEPARTMENTS_UPDATE_DEPARTMENT,
    payload: {
      dept,
    },
  };
};
