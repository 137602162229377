import React, { useState, useEffect } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import * as actions from '../../../../store/actions/index';
import { connect } from 'react-redux';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ListIcon from '@mui/icons-material/List';
import { format, addMinutes } from 'date-fns';
import {
  APPLICATION_STATUSES,
  APPOINTMENTSTATUS,
  INTAKES,
  STUDY_LEVELS,
} from '../../../../Util/Constants';
import Agent from '../../../../api/agent';
import StudentFooter from '../Footer/StudentFooter';
import { Card, Typography } from '@material-tailwind/react';
import {
  IoDocumentTextOutline,
  IoSearchCircleOutline,
  IoTimeOutline,
} from 'react-icons/io5';
import StudentFooterMob from '../Footer/StudentFooterMob';
import SideNav from './SideNav';
import { Countries } from '../../../../Util/Util';
import LoadingView from '../../../Common/Loading/LoadingView';

const MainSection = ({ studentName, roleName, role, logout, appUserId,imageUrl }) => {
  const [addMode, setAddMode] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [applications, setApplications] = useState([{}]);
  const [applicationsLoading, setApplicationsLoading] = useState(true);
  const [programs, setPrograms] = useState([]);

  const [student, setStudent] = useState({ applications: [] });
  const [refStudent, setRefStudent] = useState({});
  const [studentId, setStudentId] = useState();
  const [profileComplete, setProfileComplete] = useState();
  const [profileLoading, setProfileLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [studentLoad, setStudentLoad] = useState(true);
  const [studentPhoto, setStudentPhoto] = useState();
  const [program, setProgram] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [alertMsg, setAlertMsg] = useState('Hello from snackbar');
  const [appointmentList, setAppointmentList] = useState([]);
  const [communicationHistory, setCommunicationHistory] = useState([]);
  const currentDate = new Date();
  const studentNameSplit = studentName?.split(' ');
  const firstName = studentNameSplit[0]?.split('');
  const lastName = studentNameSplit[1]?.split('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [refDashboard, setRefDashboard] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const location = useLocation();
  const stdUrl = location.pathname;
  const [errorMessage, setErrorMessage] = useState('');

  const successDialogStyles = {
    backgroundColor: 'bg-green-400',
  };
  const errorDialogStyles = {
    backgroundColor: 'bg-red-400',
  };
  const navigate = useNavigate();
  // Dialog stuff
  const { welcome } = useSearchParams('welcome');

  useEffect(() => {
    var idToUse = 0;
    Agent.Students.getStudent(idToUse)
      .then((std) => {
        const { studentCloudFiles, ...rest } = std;
        setStudent(std);
        setRefStudent(rest);
        setStudentLoad(false);
        setApplications(rest.applications);
        setStudentId(rest.id);
        setProfileComplete(std.profileComplete);
        setStudentPhoto(
          studentCloudFiles.filter((file) => file.documentType == 16)[0]
        );
        if (!rest.isReferral) {
          setLoadingPage(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setProfileLoading(false);

    Agent.Programs?.list({ basedOnPreferences: true }).then((data) => {
      let tempPrograms = data.data;
      setPrograms(
        tempPrograms.length > 5 ? tempPrograms.slice(0, 5) : tempPrograms
      );
    });
  }, []);

  const handleAppointmentUpdate = () => {
    setAddMode(false);
    navigate('/appointment');
  };
  const getParams = (filter, reset) => {
    const params = new URLSearchParams();
    return params;
  };
  const handleOK = async () => {
    handleCloseConfirmDialog();
    try {
      logout();
      
    } catch (error) {
      console.error(error);
    }
  };
  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };
  const ConfirmDialog = () => (
    <Dialog
      open={openConfirmDialog}
      onClose={handleCloseConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Success'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description ">
          Your Referral Account Create Successfully, Please Re-login To get full
          feature!!
        </DialogContentText>
      </DialogContent>
      <DialogActions className="flex justify-between px-8 py-6">
        <div></div>
        <Button onClick={handleOK} color="success" className="btn-success">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  // const handleOpenAppPage = (student) => {
  //   window.open(
  //     `/students/${student.studentId}/applications/${student.id}`,
  //     '_blank'
  //   );
  // };

  // const openApplyModal = (program) => {
  //   student?.profileComplete
  //     ? (setModalOpen(true), setProgram(program))
  //     : navigate('/profile');
  // };

  // const handleApply = () => {
  //   const applicationId = userContext.applicationId;
  //   const studentId = 0;

  //   Agent.Applications.add(studentId, program.courseAllocationId, applicationId)
  //     .then((id) => {
  //       setOpenSuccess(true);
  //       setOpenFailure(false);
  //       setAlertMsg('Application Successfully Submitted');
  //       console.log('Successful Application, id:', id);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       const errorStatus = error.split('-')[1];
  //       setOpenFailure(true);
  //       setOpenSuccess(false);
  //       setAlertMsg(`${errorStatus}`);
  //     });

  //   setModalOpen(false);
  // };
  console.log('refStudent.isReferral', refStudent.isReferral);

  useEffect(() => {
    if (refStudent.isReferral) {
      setLoadingPage(true);
      Agent.Referral.getreferralbyuserId(appUserId)
        .then((data) => {
          //SetReferralUserInfo(data);
          console.log('refdashboard', data);
          setLoadingPage(false);
          setRefDashboard(data.isReferralDashboard);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [refStudent.isReferral]);

  const addRecord = (record) => {
    let stu = {
      name: student.firstName + ' ' + student.lastName,
    };
    setAppointment({ ...record, ...stu });
    setAddMode(true);
  };
  const formattedDate = (date) => {
    return format(
      addMinutes(new Date(date), -new Date().getTimezoneOffset()),
      'MMMM d, yyyy h:mm a'
    );
  };
  const TABLE_HEAD = [
    'Course',
    'University',
    'Country',
    'Study Year',
    'Study Level',
  ];

  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Agent.Appointment.get()
      .then((records) => {
        setAppointments(records);
        setLoading(false);
      })
      .catch((error) => {
        debugger;
        console.log(error);
      });
  }, []);
  const editRecord = (row) => {
    let temp = { ...appointment, ...row };
    setAppointment(temp);
    setAddMode(true);
  };

  const useWindowSize = () => {
    const [windowSize, setWindowSize] = React.useState({
      width: undefined,
      height: undefined,
    });

    React.useEffect(() => {
      const handleResize = () =>
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });

      window.addEventListener('resize', handleResize);

      handleResize();

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    return windowSize;
  };

  const handleOpenAppPage = (student) => {
    window.open(
      `/students/${student.studentId}/applications/${student.id}`,
      '_blank'
    );
  };

  const handleReferralOnclick = async () => {
    await Agent.Referral.CreateReferralbyStuId(studentId)
      .then((res) => {
        setOpenConfirmDialog(true);
        // if (
        //   window.confirm(
        //     'Referral Create Successfully, Please Re-login To get full feature!!'
        //   )
        // ) {
        //   logout();
        // }
      })
      .catch((error) => {
        if(error=='Bad Request: Student not found!'){
          setErrorMessage('You have already created an account, please drop WhatsApp text to 44 7939 940559 for further assistance!!'); //Error may Occured bcoz of same phone number exist in rferral already
        }
        console.log('error', error);
      });
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const size = useWindowSize();
  console.log('refDashboard status', refDashboard);

  return loadingPage ? (
    <LoadingView />
  ) : refDashboard ? (
    navigate('/referral/dashboard')
  ) : loadingPage ? (
    <LoadingView />
  ) : (
    <>
      <div className="flex items-center justify-between ">
        <div></div>
        <div></div>
        <div className="lg:mr-44 xl:mr-44 lg:px-16 xl:px-16 mr-12">
          {studentLoad ? (
            <LoadingView />
          ) : (
            !refStudent.isReferral && (
              <button
                onClick={handleReferralOnclick}
                className="bg-gradient-to-r from-blue-300 via-sky-400 to-sky-500 px-16 text-gray-700  rounded-sm py-2 font-medium"
              >
                {' '}
                <div>Create A Referral Account</div>
              </button>
            )
          )}
        </div>
      </div>
      <div className="flex items-center min-h-screen  min-w-screen p-0 md:justify-center pt-0 md:pl-0 bg-gray-100">
        <section className="flex pt-64 items-center sm:justify-center h-full w-full sm:overflow-scroll">
          <div className="container mx-auto px-0">
            <div className="relative flex flex-col min-w-0 break-words bg-sky-800 w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="justify-between bg-gray-100 items-top pt-8  lg:flex">
                <div className="relative justify-center items-center p-0 h-full md:max-w-[75%] md:ml-8 lg:w-full lg:pr-4">
                  <Card
                    className="bg-gradient-to-r from-blue-300 via-blue-300 to-blue-300 "
                    extra={
                      'items-center flex-col w-full h-full p-[16px] bg-cover'
                    }
                  >
                    {size.width > 844 ? (
                      <div
                        className="relative mt-0 flex h-80
                       w-full justify-center bg-cover bg-gradient-to-r from-blue-300 via-blue-300 to-blue-300 "
                        style={{
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundImage: `url(${require('./../Images/cover.png')})`,
                        }}
                      >
                        <div className=" absolute -bottom-12 flex h-[87px] w-[87px] text-4xl uppercase items-center text-gray-300 justify-center rounded-full bg-teal-600">
                          {!imageUrl ? (
                            firstName[0] + lastName[0]
                          ) : (
                            <img
                              className="relative rounded-full h-full"
                              alt="student"
                              src={imageUrl}
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="relative mt-0 flex h-80
                       w-full justify-center bg-cover bg-gradient-to-r from-blue-300 via-blue-300 to-blue-300 z-50"
                        style={{
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundImage: `url(${require('./../Images/coverMob.png')})`,
                        }}
                      >
                        <div className=" absolute -bottom-12 flex h-[87px] w-[87px] text-4xl uppercase items-center justify-center rounded-full bg-gray-500">
                          {!imageUrl ? (
                            firstName[0] + lastName[0]
                          ) : (
                            <img
                              className="relative rounded-full h-full"
                              alt="student"
                              src={imageUrl}
                            />
                          )}
                        </div>
                      </div>
                    )}

                    <div className="mt-16 flex flex-col items-center">
                      <h4 className="text-blue-700 text-xl font-semibold">
                        {studentName}
                      </h4>
                      <h5 className="text-base text-sky-700 font-semibold">
                        {roleName}
                      </h5>
                    </div>
                    {/* Cards */}
                    <div className=" p-2 flex flex-col gap-2 mr-2 lg:flex-row xl:flex-row ">
                      <div className="flex flex-col cursor-pointer justify-center text-gray-900 bg-gradient-to-r from-cyan-500 via-cyan-600 to-cyan-700 py-2 px-2 text-center items-center mt-2 rounded-2xl shadow-xl  w-full card-item-div max-w-screen-md  lg:flex-row xl:flex-row ">
                        <Link className="flex flex-row" to="/applications">
                          <div>
                            <p className="uppercase font-bold pt-2 hover:text-[20px]">
                              Applications
                            </p>

                            <p className=" font-small leading-2 w-full">
                              View Your Applications
                            </p>
                          </div>
                          {size.width > 829 && (
                            <div>
                              <IoDocumentTextOutline size={54} />
                            </div>
                          )}
                        </Link>
                      </div>

                      <div className="flex flex-col cursor-pointer justify-center text-gray-900 bg-gradient-to-r from-cyan-500 via-cyan-600 to-cyan-700 py-2 px-2 text-center items-center mt-2 rounded-2xl shadow-xl  w-full card-item-div max-w-screen-md lg:flex-row xl:flex-row ">
                        <Link className="flex flex-row" to="/programs">
                          <div>
                            {' '}
                            <p className="uppercase font-bold pt-2 hover:text-[20px]">
                              Programs
                            </p>
                            <p className=" font-small  leading-2 w-full">
                              Search For Programs
                            </p>
                          </div>
                          {size.width > 829 && (
                            <div>
                              <IoSearchCircleOutline size={54} />
                            </div>
                          )}
                        </Link>
                      </div>
                      <div className="flex cursor-pointer justify-center text-gray-900 bg-gradient-to-r from-cyan-500 via-cyan-600 to-cyan-700 py-2 px-2 text-center items-center mt-2 rounded-2xl shadow-xl  w-full card-item-div max-w-screen-md  lg:flex-row xl:flex-row ">
                        <Link className="flex flex-row" to="/appointments">
                          <div>
                            <p className="uppercase font-bold pt-2 hover:text-[20px]">
                              Appointment
                            </p>

                            <p className=" font-small leading-2 w-full">
                              View Your Appointments
                            </p>
                          </div>
                          {size.width > 829 && (
                            <div>
                              <IoTimeOutline size={50} />
                            </div>
                          )}
                        </Link>
                      </div>
                    </div>
                    {profileComplete === false ? (
                      <>
                        <div className="relative mt-1 flex h-17 p-4 w-full justify-center  mb-2 rounded-xl bg-cover pt-2">
                          <button
                            className="flex gap-2 studentBtnPrimary shadow-lg  rounded-full hover:p-5 text-white"
                            size="small"
                            onClick={() => navigate('/profile')}
                          >
                            <InfoOutlinedIcon />
                            {'Complete Your Profile'}
                          </button>{' '}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="pl-8">
                          <h2 className="text-2xl text-gray-900 font-medium pt-6">
                            Update Your preferences
                          </h2>
                          <h4 className="text-lg text-gray-900">
                            Manage your preferences that will narrow down your
                            course search
                          </h4>
                        </div>
                        <div className="flex justify-center bg-gradient-to-r from-blue-300 via-blue-300 to-blue-300 items-center pb-6 ">
                          <button
                            className="flex bg-gradient-to-r from-cyan-600 via-cyan-700 to-cyan-800 hover:p-5 gap-2 shadow-lg p-4 rounded-full text-gray-300"
                            size="small"
                            onClick={() => navigate('/Preferences')}
                          >
                            <ListIcon />
                            Manage Preferences
                          </button>
                        </div>
                      </>
                    )}
                  </Card>

                  <div className="p-6 bg-gray-300  mt-3 rounded-lg shadow-xl">
                    <h2 className="text-2xl text-gray-900 font-semibold">
                      Applications
                    </h2>
                    <h4 className="text-xl text-gray-900">
                      A quick view of your current applications
                    </h4>
                    <br />
                    <div className="overflow-x-auto app-scrollbar min-h-[300px]">
                      <div className="w-full min-w-max ">
                        <div className="flex gap-1 m- p-2 pl-8 h-12 w-full text-lg font-semibold px-2">
                          {/* <div className={`w-[100px]`}>ID</div> */}
                          <div className={`w-[250px]`}>Program</div>
                          <div className={`w-[220px]`}>University</div>
                          <div className={`w-[120px]`}>Status</div>
                          <div className={`w-[120px]`}>Year</div>
                          <div className={`w-[120px]`}>Intake</div>
                          <div className={`w-[120px]`}>Level</div>
                        </div>
                        {applications?.map((item, idx) => {
                          return (
                            <div key={idx}>
                              <button
                                onClick={() => handleOpenAppPage(item)}
                                className="flex items-center gap-1 bg-gray-300 text-gray-800 rounded-lg my-2 h-12 hover:bg-gray-200 hover:text-gray-900 cursor-pointer transition-all duration-100 text-base "
                              >
                                {/* <div className={`w-[100px] truncate`}>
                                  {item.id}
                                </div> */}
                                 <div className={`w-[220px] truncate`}>
                                  {item.courseName}
                                </div>
                                <div className={`w-[250px] truncate`}>
                                  {item.universityName}
                                </div>
                                <div className={`w-[120px]`}>
                                  {item.status
                                    ? APPLICATION_STATUSES.find(
                                        ({ id }) => id == item.status
                                      ).type
                                    : ''}
                                </div>
                                <div className={`w-[120px] truncate`}>
                                  {item.year}
                                </div>
                                <div className={`w-[120px] truncate`}>
                                  {item.intake
                                    ? INTAKES.find(
                                        ({ id }) => id == item.intake
                                      ).type
                                    : ''}
                                </div>
                                <div className={`w-[120px] truncate`}>
                                  {item.studyLevelId
                                    ? STUDY_LEVELS.find(
                                        ({ id }) => id == item.studyLevelId
                                      ).type
                                    : ''}
                                </div>
                              </button>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <div className="p-6 bg-gray-300  mt-6 rounded-lg shadow-xl">
                    <h2 className="text-2xl text-gray-900 font-semibold">
                      Top Programs
                    </h2>
                    <h4 className="text-xl text-gray-900">
                      Top programs based on your preferences
                    </h4>
                    <br />
                    <div className="overflow-x-auto app-scrollbar min-h-[300px]">
                      <div className="w-full min-w-max ">
                        <div className="flex gap-1 m- p-2 h-12 w-full text-lg font-semibold px-2 ">
                          <div className={`w-[300px]`}>Course</div>

                          <div className={`w-[250px]`}>University</div>
                          <div className={`w-[150px]`}>Country</div>
                          <div className={`w-[120px]`}>Study Year</div>
                          <div className={`w-[150px] lg:w-[150px]`}>
                            Study Level
                          </div>
                        </div>
                        {programs?.map((item, idx) => {
                          return (
                            <div
                              key={idx}
                              //onClick={() => editRecord(item)}
                            >
                              <div className="flex items-center gap-1 bg-gray-300 text-gray-800 rounded-lg p-2 my-2 h-12 hover:bg-gray-200 hover:text-gray-900 cursor-pointer transition-all duration-100 text-base ">
                                <div className={`w-[300px] truncate`}>
                                  {item.courseName}
                                </div>
                                <div className={`w-[250px] truncate`}>
                                  {item.universityName}
                                </div>
                                <div className={`w-[150px]`}>
                                  {item.countryId
                                    ? Countries.find(
                                        ({ id }) => id == item.countryId
                                      ).name
                                    : ''}
                                </div>
                                <div className={`w-[120px] truncate`}>
                                  {item.year}
                                </div>
                                <div className={`w-[150px] truncate`}>
                                  {item.studyLevelId
                                    ? STUDY_LEVELS.find(
                                        ({ id }) => id == item.studyLevelId
                                      ).type
                                    : ''}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Sidebar /> */}
                <SideNav  studentId={studentId} studentName={studentName}/>
              </div>
            </div>
          </div>
          <footer className="relative bg-blueGray-200 pt-8 pb-6 mt-8">
            <div className="container mx-auto px-4">
              <div className="flex flex-wrap items-center md:justify-between justify-center">
                <div className="w-full md:w-6/12 px-4 mx-auto text-center">
                  {/* <div className="text-sm text-blueGray-500 font-semibold py-1">
      Made with <a href="https://www.creative-tim.com/product/notus-js" className="text-blueGray-500 hover:text-gray-800" target="_blank">Notus JS</a> by <a href="https://www.creative-tim.com" className="text-blueGray-500 hover:text-blueGray-800" target="_blank"> Creative Tim</a>.
    </div> */}
                </div>
              </div>
            </div>
          </footer>
        </section>
      </div>
      {size.width <= 844 ? <StudentFooterMob /> : <StudentFooter />}
      {/* </div>
</div> */}

      <ConfirmDialog />

      <Dialog
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        aria-labelledby="error-dialog-title"
        PaperProps={{
          className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
        }}
      >
        <DialogTitle id="error-dialog-title">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorMessage('')} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    studentName: state.auth.name,
    isLoggedIn: state.auth.isLoggedIn,
    name: state.auth.name,
    role: state.auth.role,
    roleName: state.auth.roleName,
    appUserId: state.auth.id,
    state: state,
    imageUrl: state.auth.image,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainSection);
