/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Draggable from 'react-draggable';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const EditAnnouncementType = ({
  announcementType,
  action,
  cancel,
}) => {
  const [ready, setReady] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [announcementName, setAnnouncementName] = useState(announcementType?.name);
  const [isInactive, setIsInactive] = useState(announcementType?.isInactive);
  const confirm = () => {
  action({
      id:announcementType.id,
      name: announcementName,
      isInactive:isInactive,
    });
  };
  
  const handleClose = () => {
    setDialogOpen(false);
    cancel(false);
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        open={dialogOpen}
        maxWidth="sm"
        npn
        fullWidth
        PaperComponent={PaperComponent}
      >
       <BootstrapDialogTitle onClose={handleClose} style={{ cursor: 'move' }}>
          <Typography align="center" className='pb-4 pl-4' color="secondary" variant="h6">
            {announcementName}
          </Typography>
          
          <Divider />
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            fullWidth
            spacing={1}
            style={{paddingLeft: 6 ,paddingBottom : 6}}
          >
           
            <Grid className='mt-2' item container sx={12} sm={6} md={12}>
              <strong>Announcement Type</strong>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    native
                    size="small"
                    //label="Announcement Type"
                    value={announcementName}
                    onChange={(e) => {
                      setAnnouncementName(e.target.value);
                      setReady(true);
                    }}
                  />
                </Grid>
              </Grid>
            <Grid item xs={12}>
            <FormControl fullWidth>
            <InputLabel>Active/Inactive</InputLabel>
            <Select
              fullWidth
              size="small"
              native
              label="Active/Inactive"
              value={isInactive}
              onChange={(e) => {
                setIsInactive(e.target.value);
                setReady(true);
              }}
            >
              <option value={false}>Active</option>
              <option value={true}>Inactive</option>
            </Select>
            </FormControl>
          </Grid>
              </Grid>
        </DialogContent>
        <DialogActions className='pb-6'>
          <Button
            disabled={!ready}
            autoFocus
            onClick={confirm}
            variant="contained"
            className="bg-sky-400 hover:bg-sky-500 mr-5"
          >
            Confirm Selection
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
