import React from 'react';
import { Grid, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const MyTabLabelMob = ({ label, showWarning = false }) => {
  return (
      
    <Grid>
      <Grid  item xs={9}>
        <div className="text-sm font-semibold text-gray-100">{label}</div>
      </Grid>

      <Grid
        item
        xs={3}
        
        style={{ visibility: showWarning ? null : 'hidden' }}
      >
        <Grid item>
          <ErrorIcon color="error" fontSize="small" />
        </Grid>
      </Grid>
    </Grid>
    
  );
};

export default MyTabLabelMob;
