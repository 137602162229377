/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import EditorDraftJs from '../../Common/Editor/EditorDraftJs';
import Agent from '../../../api/agent';
import { format } from 'date-fns'; // Import date-fns for date formatting
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';

const LeadAccountsNotes = ({
  summmaryNoteRecords,
  handleAddSummaryRecord,
  leadId,
  users,
}) => {
  const [addNoteMode, setAddNoteMode] = useState(false);
  const [newNote, setNewNote] = useState({
    content: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  //console.log("test note", summmaryNoteRecords)

  // Function to find the user's name based on appUserDetailsId
  const getUserName = (appUserDetailsId) => {
    const user = users.find(
      (user) => user.appUserDetailsId === appUserDetailsId
    );
    return user ? user.name : '';
  };

  const handleAddNoteClick = () => {
    setAddNoteMode(true);
  };

  const handleAddNote = async () => {
    if (!newNote.content) {
      return;
    }

    const currentDate = new Date().toISOString();

    try {
      const payload = {
        content: newNote.content,
        studentLeadId: leadId,
        sendEmail: false,
        noteType: 2,
      };
      await Agent.Messages.addAccountsNote(payload);

      const updatedRecords = [
        ...summmaryNoteRecords,
        {
          content: newNote.content,
          dateSent: currentDate,
        },
      ];

      handleAddSummaryRecord(updatedRecords);

      setSuccessMessage('Note added successfully!');
      setNewNote({
        content: '',
      });
      setAddNoteMode(false);

      setTimeout(() => {
        setSuccessMessage('');
      }, 3000);
    } catch (error) {
      console.error('Error adding note:', error);
    }
  };

  return (
    <div id="summary-info" className="bg-gray-100 p-4 rounded-lg">
      <div>
        <h2 className="text-2xl text-center font-semibold mb-4 text-indigo-600">
          Accounts Notes 
        </h2>

        <div className="space-y-4">
          {summmaryNoteRecords
            .slice()
            .reverse()
            .map((record, index) => (
              <div
                key={index}
                className="p-4 bg-white shadow-md rounded-lg border border-gray-300 mb-4"
              >
                <div className="mb-2">
                  <strong className="text-gray-800">Content:</strong>
                  <div
                    className="text-gray-600"
                    dangerouslySetInnerHTML={{
                      __html: record.content,
                    }}
                  />
                </div>

                <div className="flex justify-between">
                  <div className="mb-2">
                    <strong className="text-gray-800">Date:</strong>{' '}
                    {format(new Date(record.dateSent), 'yyyy-MM-dd')}{' '}
                  </div>
                  <div className="mb-2">
                    <strong className="text-gray-800 mr-1">Added By:</strong>
                    {getUserName(record?.appUserDetailsId)}
                  </div>
                </div>
              </div>
            ))}
        </div>

        {!addNoteMode && (
          <button
            type="button"
            onClick={handleAddNoteClick}
            className="bg-blue-500 text-white px-4 mt-2 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
          >
            Add Notes
          </button>
        )}

        {addNoteMode && (
          <div
            className={`mt-6 ${
              addNoteMode
                ? 'transition-opacity duration-500'
                : 'hidden opacity-0'
            }`}
          >
            <div className="mb-4">
              <label className="block font-bold mb-1">Notes</label>
              <EditorDraftJs
                editorOnChange={(html, valid) =>
                  setNewNote({
                    ...newNote,
                    content: html,
                  })
                }
                height={200}
                placeHolder="Write your notes here"
                html={newNote.content}
              />
            </div>
            <div className="mb-4">
              <button
                type="button"
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 focus:outline-none"
                onClick={handleAddNote}
              >
                Add Note
              </button>
            </div>
            {successMessage && (
              <div className="text-green-600 font-semibold mt-2">
                {successMessage}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    departments: state.departments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeadAccountsNotes);
