import React, {useState, useContext, useEffect} from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import {Typography} from '@mui/material'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import CSSTransition from 'react-transition-group/CSSTransition'

import RegisterContext from '../../../../context/register-context'
import styles from '../../StudentRegistration/CSS/registration.module.css'

const Step3Destination = ({goNext}) => {
  const registerContext = useContext(RegisterContext)
  const [countries, setCountries] = useState([])
  const [selectedCountries, setSelectedCountries] = useState(
    registerContext.selectedCountries
  )

  const selectCountry = (item) => {
    let temp = []
    if (selectedCountries.some((s) => s.id == item.id)) {
      temp = selectedCountries.filter((c) => c.id != item.id)
    } else {
      setSelectedCountries(selectedCountries.concat(item))
      temp = selectedCountries.concat(item)
    }
    setSelectedCountries(temp)
    registerContext.setRegisterContext({
      ...registerContext,
      ...{
        selectedCountries: temp,
      },
    })
  }

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (!countries.some((c) => c.name == 'Malaysia')) {
        setCountries(countries.concat({id: 157, name: 'Malaysia'}))
      }
      if (!countries.some((c) => c.name == 'New Zealand')) {
        setCountries(countries.concat({id: 170, name: 'New Zealand'}))
      }
      if (!countries.some((c) => c.name == 'Ireland')) {
        setCountries(countries.concat({id: 101, name: 'Ireland'}))
      }
      if (!countries.some((c) => c.name == 'USA')) {
        setCountries(countries.concat({id: 231, name: 'USA'}))
      }
      if (!countries.some((c) => c.name == 'Canada')) {
        setCountries(countries.concat({id: 37, name: 'Canada'}))
      }
      if (!countries.some((c) => c.name == 'Australia')) {
        setCountries(countries.concat({id: 13, name: 'Australia'}))
      }
      if (!countries.some((c) => c.name == 'UK')) {
        setCountries(countries.concat({id: 76, name: 'UK'}))
      }
    }, 200)
    return () => {
      clearInterval(myInterval)
    }
  })

  return (
    <>
    <div className="flex items-top p-4 justify-center min-h-screen min-w-screen">
      <div className="flex justify-center items-top lg:w-1/3 xl:w-1/3">
       
      <Grid >
        <Grid item xs={12} container direction='column' spacing={3}>
          <Grid item>
            <Typography textAlign={'center'}>
              Pick one or more study destinations
            </Typography>
          </Grid>
          <Grid item>
            <TransitionGroup component='ul' className={styles['step-list']}>
              {countries.filter((item)=>item.id!=0).map((item, index) => (
                <CSSTransition key={index} classNames='fade' timeout={300}>
                  <li className={styles['step-listItem']}>
                    {
                      <Button
                        size='large'
                        variant={
                          selectedCountries.some((c) => c.id == item.id)
                            ? 'contained'
                            : 'outlined'
                        }
                        className={
                          selectedCountries.some((c) => c.id == item.id)
                            ? 'bg-amber-400 hover:bg-amber-500'
                            : ''
                        }
                        fullWidth
                        onClick={() => selectCountry(item)}
                      >
                        {item.name}
                      </Button>
                    }
                  </li>
                </CSSTransition>
              ))}
              <CSSTransition
                classNames='fade'
                timeout={300}
                style={{marginTop: 20}}
              >
                <li className={styles['step-listItem']}>
                  {
                    <Button
                      disabled={selectedCountries.length == 0}
                      fullWidth
                      variant='contained'
                      className='bg-sky-400 hover:bg-sky-500'
                      onClick={goNext}
                    >
                      Next
                    </Button>
                  }
                </li>
              </CSSTransition>
            </TransitionGroup>
          </Grid>
          {/* <Grid item>
            <Button
              disabled={selectedCountries.length == 0}
              fullWidth
              variant="contained"
              color="secondary"
              onClick={goNext}
            >
              Next
            </Button>
          </Grid> */}
        </Grid>
      </Grid>
      </div>
      </div>
    </>
  )
}

export default Step3Destination
