import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import MyTextInput from '../../../Common/Form/MyTextInput';
import LinearIndeterminate from '../../../Common/Progress/LinearIndeterminate';
import RegisterContext from '../../../../context/register-context';
import { Typography } from '@mui/material';
import styles from '../../StudentRegistration/CSS/registration.module.css';

// Add the necessary imports for phone input
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import phoneInput from '../../../Appointments/phoneInput';
// import CustomPhoneNumber from './phoneInput';

const PREFIX = 'Register';
const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  paper: `${PREFIX}-paper`,
};

const StyledFormik = styled(Formik)(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    padding: '5px 20px',
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const StepLoginEmail = ({ registerStudent }) => {
  const registerContext = useContext(RegisterContext);
  // state and functions for password
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const handleClickShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [phone, setPhone] = useState(registerContext.phoneNumber);
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const handlePhoneChange = (newPhone) => {
    setPhone(newPhone);

    // Use a regex pattern to check if the phone number is valid
    const phonePattern = /^\+[1-9]\d{1,14}$/;
    setIsPhoneValid(phonePattern.test(newPhone));
  };

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required('First name is required')
      .min(2, 'Must be 2 characters or more')
      .max(50, 'Must be 50 characters or less'),
    lastName: Yup.string()
      .required('Last name is required')
      .min(2, 'Must be 2 characters or more')
      .max(50, 'Must be 50 characters or less'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(
        /^\+[1-9]\d{1,14}$/, //following E_164 Phone number format
        'Invalid phone number format. Include country code (+) and digits.'
      )
      .max(45, 'Phone number is too long')
      .typeError(
        'Please enter a valid phone number including country code (+)'
      ),

    email: Yup.string()
      .required('Email address is required')
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        'Invalid email address'
      )
      .test('unique-email', 'Only one email address allowed', (value) => {
        // Split the value by comma and semicolon and check the count
        const emailAddresses = value.split(/[;,]/);
        return emailAddresses.length === 1;
      })
      .max(40),
    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8}.*$/,
        'Must contain  at least 8 characters, one uppercase, one lowercase, one number'
      )
      .max(50, 'Must be 50 characters or less'),
  });

  async function handleFormSubmit(user) {
    await registerStudent(user);
  }

  let initValues = {
    firstName: registerContext.firstName,
    lastName: registerContext.lastName,
    // Use the phone state variable as the initial value
    phoneNumber: phone,
    email: registerContext.email,
    password: registerContext.password,
  };

  return (
    <StyledFormik
      enableReinitialize
      validationSchema={validationSchema}
      initialValues={initValues}
      onSubmit={(values, { setErrors, setSubmitting }) => {
        handleFormSubmit(values).catch((errors) => {
          debugger;
          setSubmitting(false);
          let err = [];
          if (typeof errors === 'string') {
            err.push(errors);
          } else {
            err = errors;
          }
          setErrors({
            error: err,
          });
        });
      }}
    >
      {({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
        <div className="flex items-top justify-center min-h-screen min-w-screen">
          <div className="flex justify-center items-top ">
            <Form onSubmit={handleSubmit} autoComplete="off">
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <div className={classes.root}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item>
                        <MyTextInput
                          label="First Name"
                          name="firstName"
                          placeholder="First Name"
                          size="small"
                        />
                      </Grid>
                      <Grid item>
                        <MyTextInput
                          label="Last Name"
                          name="lastName"
                          placeholder="Last Name"
                          size="small"
                        />
                      </Grid>

                      {/* <Grid item>
                    <PhoneInput
                      useNationalFormatForDefaultCountryValue={true}
                      placeholder="Input Phone Number with country code"
                      // defaultCountry={defaultPhoneCountry || 'gb'}
                      value={phone}
                      onChange={handlePhoneChange} 
                      inputComponent={phoneInput}
                      //dropdownClass={classes.countryList}
                    />
                  </Grid> */}
                      <Grid item>
                        <PhoneInput
                          useNationalFormatForDefaultCountryValue={true}
                          placeholder="Input Phone Number with country code"
                          value={phone}
                          onChange={handlePhoneChange}
                          inputComponent={phoneInput}
                        />
                        {!isPhoneValid && (
                          <Typography variant="body2" color="error">
                            Invalid phone number format. Include country code
                            (+) and digits.
                          </Typography>
                        )}
                      </Grid>

                      <Grid item>
                        <MyTextInput
                          label="Email"
                          name="email"
                          placeholder="Email Address"
                          type="email"
                          size="small"
                        />
                      </Grid>
                      <Grid item>
                        <MyTextInput
                          label="Password"
                          name="password"
                          placeholder="Password"
                          size="small"
                          type={passwordVisibility ? 'text' : 'password'}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  size="large"
                                >
                                  {passwordVisibility ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      {errors && errors.error && (
                        <Grid item>
                          <ul>
                            {errors.error.map((error, i) => {
                              return (
                                <li key={i} style={{ color: 'red' }}>
                                  <Typography>{error}</Typography>
                                </li>
                              );
                            })}
                          </ul>
                        </Grid>
                      )}
                      <Grid item>
                        {isSubmitting && (
                          <Grid item style={{ paddingRight: 10 }}>
                            <LinearIndeterminate />
                          </Grid>
                        )}
                      </Grid>
                      <Grid item>
                        {' '}
                        <Button
                          disabled={isSubmitting || !dirty || !isValid}
                          type="submit"
                          fullWidth
                          variant="contained"
                          className="bg-sky-400 hover:bg-sky-500"
                        >
                          Create Profile
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Form>
          </div>
        </div>
      )}
    </StyledFormik>
  );
};

export default StepLoginEmail;
