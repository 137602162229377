import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import Agent from '../../../../api/agent';
import MyTooltip from '../../../Common/Tooltip/MyTooltip';
import CancelIcon from '@mui/icons-material/Cancel';
import * as actions from '../../../../store/actions/index';
import { connect } from 'react-redux';
import MyCountryList from '../../../Common/Form/MyCountryList';
import { Countries } from '../../../../Util/Util';
import useInput from '../../../Common/Form/use-input';

const LeadCountryAssignment = ({ currentUser, updateCountry, role }) => {
  const [countries, setCountries] = useState(currentUser.leadCountries);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const onCoutrySelction = (name, value) => {
    setSelectedCountry(value);
  };
  const { obj: country } = useInput(
    selectedCountry,
    () => true,
    onCoutrySelction
  );
  const handleDelete = (country) => {
    var tempCountries = countries.filter((c) => c !== country);
    const user = {
      editUserDto: {
        id: currentUser.appUserId,
        predicate: 'leadCountry',
        countries: tempCountries,
      },
    };
    Agent.Users.editUser(user)
      .then(() => setCountries(tempCountries))
      .then(() => updateCountry(currentUser.id, country, 'DELETE'))
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  const assignCountry = () => {
    if (selectedCountry == null) return;
    if (countries.includes(selectedCountry)) return;

    var tempCountries = countries.concat(selectedCountry);
    const user = {
      editUserDto: {
        id: currentUser.appUserId,
        predicate: 'leadCountry',
        countries: tempCountries,
      },
    };
    Agent.Users.editUser(user)
      .then(() => setCountries(tempCountries))
      .then(() => updateCountry(currentUser.id, country, 'leadCountry','ADD'))
      .catch((error) => {
        console.log(error);
        debugger;
      });
    setSelectedCountry(null);
  };

  return (
    <Grid container direction="column" spacing={3}>
      {countries.length > 0 ? (
        <Grid item style={{ marginBottom: 30 }}>
          {countries.filter((item)=>item.id!=0).map((country, index) => (
            <Chip
              key={index}
              color="primary"
              label={Countries.find(({ id }) => id == country).name}
              onDelete={() => handleDelete(country)}
              deleteIcon={
                <MyTooltip
                  title={`Click to un-assign ${
                    Countries.find(({ id }) => id == country).name
                  } from ${currentUser.name}`}
                >
                  <IconButton
                    color="primary"
                    disabled={role == 'Admin'}
                    size="large"
                  >
                    <CancelIcon />
                  </IconButton>
                </MyTooltip>
              }
              variant="outlined"
            />
          ))}
        </Grid>
      ) : null}
      <Grid item container spacing={2}>
        <Grid item xs={2}>
          <Typography>Assign Country</Typography>
        </Grid>
        <Grid item xs={5}>
          <MyCountryList
            name="country"
            label="Country"
            value={country.value}
            handleChange={country.valueChangeHandler}
            handleBlur={country.valueInputBlurHandler}
            isInvalid={false}
          />
        </Grid>
        <Grid item xs={3}>
          <Typography>
            <Button
              disabled={role == 'Admin'}
              color="primary"
              variant="outlined"
              onClick={assignCountry}
            >
              Assign
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    role: state.auth.roleName,
    currentUser: ownProps.currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCountry: (id, country, predicate, operation) =>
      dispatch(actions.updateUserCountryAction(id, country,predicate, operation)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadCountryAssignment);
