import { Autocomplete, Button, Chip, Skeleton, TextField } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

// import LoadingView from '../../../Common/Loading/LoadingView';
import * as actions from '../../../store/actions/index';
import { styled } from '@mui/material/styles';
import Agent from '../../../api/agent';
import { Countries } from '../../../Util/Util';
import { FUNDINGS, STUDY_LEVEL_GROUPS } from '../../../Util/Constants';
import MySnackbarAlert from './../../Common/AlertMessage/MySnackbarAlert';
import useWindowSize from '../../Students/common/useWindowSize';
import StudentFooterMob from '../../Students/Student/Footer/StudentFooterMob';
import StudentFooter from '../../Students/Student/Footer/StudentFooter';
const currentYear = new Date().getFullYear();
const years = [currentYear, currentYear + 1, currentYear + 2];

const PREFIX = 'Preferences';

const classes = {
  root: `${PREFIX}-root`,
  fieldset: `${PREFIX}-fieldset`,
  legend: `${PREFIX}-legend`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },
  [`& .${classes.fieldset}`]: {
    borderColor: theme.palette.common.lightBlue,
    borderRadius: 5,
    marginBottom: 15,
    width: '70%',
  },
  [`& .${classes.legend}`]: {
    // margin: 'auto',
    padding: 10,
  },
}));

const Preferences = ({ departments, loadDepts, studentLeadId, role }) => {
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [preferences, setPreferences] = useState();
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedDepts, setSelectedDepts] = useState([]);
  const [selectedDept, setSelectedDept] = useState();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedFunding, setSelectedFunding] = useState();
  const [level, setLevel] = useState();

  //#snackbar stuff
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [alertMsg, setAlertMsg] = useState();

  const location = useLocation();

  //#endregion
  const size = useWindowSize();
  useEffect(() => {
    if (!studentLeadId || studentLeadId > 0) {
      // when student is logged on, id will be undefined and for others id must be passed
      Agent.StudentLeads.getPreferences(studentLeadId || 0)
        .then((details) => {
          setPreferences({
            studentLeadDto: {
              id: studentLeadId,
              preferenceStudyLevelId: details.preferenceStudyLevelId,
            },
          });
          setSelectedCountries(details.studentLeadPreferenceCountryRecords);
          setSelectedDepts(details.studentLeadPreferenceDepartments);
          setLevel(details.preferenceStudyLevelId);
          setSelectedYear(details.year);
          setSelectedFunding(details.fundType);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          debugger;
        });
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (departments.length == 0) {
      loadDepts()
        .then((list) => {})
        .catch((error) => {
          console.log(error);
          debugger;
        });
    } else {
      setLoading(false);
    }
  }, [departments]);

  const update = (data) => {
    console.log('update paylaod', data);
    setUpdating(true);
    Agent.StudentLeads.updatePreferences(data)
      .then(() => {
        setUpdating(false);
        setAlertMsg('Updated successfully!');
        setOpenSuccess(true);
        setOpenFailure(false);
      })
      .catch((error) => {
        console.log(error);
        setUpdating(false);
        setAlertMsg('Failed to update. Refresh the page and try again!');
        setOpenSuccess(false);
        setOpenFailure(true);
      });
  };

  const handleLevelChange = (val) => {
    setLevel(val);
    let temp = {
      ...preferences.studentLeadDto,
      ...{ preferenceStudyLevelId: val, preferenceType: 1 },
    };
    setPreferences({ ...preferences, studentLeadDto: temp });
    update({ ...preferences, studentLeadDto: temp });
  };

  const handleYearChange = (val) => {
    setSelectedYear(val);
    let temp = {
      ...preferences.studentLeadDto,
      ...{ year: val, preferenceType: 2 },
    };
    setPreferences({ ...preferences, studentLeadDto: temp });
    update({ ...preferences, studentLeadDto: temp });
  };

  const handleFundingChange = (val) => {
    setSelectedFunding(val);
    let temp = {
      ...preferences.studentLeadDto,
      ...{ fundType: val, preferenceType: 3 },
    };
    setPreferences({ ...preferences, studentLeadDto: temp });
    update({ ...preferences, studentLeadDto: temp });
  };

  return studentLeadId == 0 ? (
    <div>
      <div style={{ margin: 50 }}>
        You must save the student first before you can record their study
        preferences
      </div>
    </div>
  ) : (
    <div className=" flex flex-col items-center py-4 ">
      {updating && <div>{/* <Skeleton animation='wave' /> */}</div>}
      {role.isStudent && location.pathname == '/Preferences' ? (
        <div>
          <div className="text-center text-xl text-indigo-800 font-medium bg-indigo-200 p-3 rounded-2xl xl:w-[850px] max-w-5xl mb-5">
            You can update your study related preferences that will help you
            find the right courses quickly
          </div>
        </div>
      ) : (
        ''
      )}

      <div
        className={`flex flex-col items-center rounded-xl  ${
          location.pathname == '/Preferences'
            ? 'md:w-[700px] lg:w-[800px] xl:w-[850px] bg-gray-200'
            : 'w-full bg-gray-100'
        }  p-4`}
      >
        <div className="flex flex-col gap-2 text-center text-4xl font-semibold pb-6 pt-6 w-2/3 divide-y-2 divide-slate-400/70">
          <div>Preferences</div>
          <div></div>
        </div>
        <div className="text-center text-xl font-medium p-2 w-full">
          Course Level Selection
        </div>

        <div className="flex gap-2 py-2 pb-6 justify-center w-full">
          {STUDY_LEVEL_GROUPS.map((item, index) => (
            <div key={index}>
              <Button
                className={
                  item.id == level
                    ? 'bg-indigo-200 text-indigo-800'
                    : 'bg-gray-400 text-black'
                }
                fullWidth
                onClick={() => handleLevelChange(item.id)}
              >
                {item.type}
              </Button>
            </div>
          ))}
        </div>

        <div className="text-center text-xl font-medium p-2 w-full">
          Select your Preferred Study Destinations
        </div>

        <div className="py-2 pb-6 w-full">
          <div className=" flex justify-center w-full">
            <Autocomplete
              className="p-2 rounded-lg w-2/3 border-none max-w-lg"
              size="small"
              value={selectedCountry}
              onChange={(_, newValue) => {
                setSelectedCountry(newValue);
                if (newValue) {
                  let countId = newValue.id;
                  if (selectedCountries.some((d) => d.countryId == countId))
                    return;
                  if (selectedCountries.length >= 3) return;
                  setSelectedCountries(
                    selectedCountries.concat({ countryId: newValue.id })
                  );
                  let temp = {
                    ...preferences.studentLeadDto,
                    ...{ countries: [countId], preferenceType: 6 },
                  };
                  setPreferences({ ...preferences, studentLeadDto: temp });
                  update({ ...preferences, studentLeadDto: temp });
                }
              }}
              options={Countries.filter((c) => c.showAll).sort(function (a, b) {
                if (a.order < b.order) {
                  return -1;
                }
                if (a.order > b.order) {
                  return 1;
                }
                return 0;
              })}
              getOptionLabel={(option) => option.short || option.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  hiddenLabel
                  fullWidth
                  {...params}
                  placeholder="You can pick up to three countries"
                />
              )}
            />
          </div>
          <div className=" flex justify-center gap-2">
            {selectedCountries.filter((item)=>item.id!=0).map((country, index) => (
              <Chip
                className="bg-indigo-200 text-indigo-800 font-medium border-none"
                key={index}
                label={Countries.find(({ id }) => id == country.countryId).name}
                variant="outlined"
                onDelete={() => {
                  let countId = country.countryId;
                  setSelectedCountries(
                    selectedCountries.filter((d) => d.countryId != countId)
                  );
                  let temp = {
                    ...preferences.studentLeadDto,
                    ...{ countries: [countId], preferenceType: 7 },
                  };
                  setPreferences({ ...preferences, studentLeadDto: temp });
                  update({ ...preferences, studentLeadDto: temp });
                }}
              />
            ))}
          </div>
        </div>

        <div className="text-center text-xl font-medium p-2 w-full">
          Department/Subject Selection
        </div>

        <div className="py-2 pb-6 w-full flex flex-col justify-center items-center ">
          <div className=" flex justify-center w-full">
            <Autocomplete
              className=" p-2 rounded-lg w-2/3  max-w-lg"
              size="small"
              value={selectedDept}
              onChange={(_, newValue) => {
                setSelectedDept(newValue);
                if (newValue) {
                  let deptId = newValue.id;
                  if (selectedDepts.some((d) => d.departmentId == deptId))
                    return;
                  if (selectedDepts.length >= 3) return;
                  setSelectedDepts(
                    selectedDepts.concat({ departmentId: newValue.id })
                  );
                  let temp = {
                    ...preferences.studentLeadDto,
                    ...{ departments: [deptId], preferenceType: 4 },
                  };
                  setPreferences({ ...preferences, studentLeadDto: temp });
                  update({ ...preferences, studentLeadDto: temp });
                }
              }}
              options={departments}
              getOptionLabel={(option) => option.name}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField
                  hiddenLabel
                  fullWidth
                  {...params}
                  placeholder="You can pick up to three subjects"
                />
              )}
            />
          </div>
          {departments.length > 0 && (
            <div className=" flex flex-col justify-center px-3 gap-2 w-2/3  max-w-lg">
              {selectedDepts.map((dept, index) => (
                <Chip
                  className="bg-indigo-200 text-indigo-800 font-medium border-none"
                  key={index}
                  label={
                    departments.find(({ id }) => id == dept.departmentId).name
                  }
                  variant="outlined"
                  onDelete={() => {
                    let deptId = dept.departmentId;
                    setSelectedDepts(
                      selectedDepts.filter((d) => d.departmentId != deptId)
                    );
                    let temp = {
                      ...preferences.studentLeadDto,
                      ...{ departments: [deptId], preferenceType: 5 },
                    };
                    setPreferences({ ...preferences, studentLeadDto: temp });
                    update({ ...preferences, studentLeadDto: temp });
                  }}
                />
              ))}
            </div>
          )}
        </div>

        <div className="text-center text-xl font-medium p-2 w-full">
          Year of Study Selection
        </div>

        <div className="flex gap-3 py-2 pb-6 justify-center w-1/4">
          {years.map((item, index) => (
            <div key={index}>
              <Button
                className={
                  item == selectedYear
                    ? 'bg-indigo-200 text-indigo-800'
                    : 'bg-gray-400 text-black'
                }
                fullWidth
                onClick={() => handleYearChange(item)}
              >
                {item}
              </Button>
            </div>
          ))}
        </div>

        <div className="text-center text-xl font-medium p-2 w-full">
          Funding Status Selection
        </div>

        <div className="flex gap-2 py-2 pb-6 justify-center w-full">
          {FUNDINGS.map((item, index) => (
            <div key={index}>
              <Button
                className={
                  item.id == selectedFunding
                    ? 'bg-indigo-200 text-indigo-800'
                    : 'bg-gray-400 text-black'
                }
                fullWidth
                onClick={() => handleFundingChange(item.id)}
              >
                {item.type}
              </Button>
            </div>
          ))}
        </div>
      </div>

      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
        />
      )}
{/* 
      {role.isStudent &&
        (size.width <= 844 ? <StudentFooterMob /> : <StudentFooter />)} */}
    
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    role: state.auth.role,
    departments: state.departments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadDepts: () => dispatch(actions.loadDepts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Preferences);
