/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Divider, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import EditorDraftJs from '../Common/Editor/EditorDraftJs';
import Agent from '../../api/agent';
import Conversations from './Conversations';
import ReferralConversations from './ReferralConversations';
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const ReferralAddMessage = ({
  open,
  cancel,
  ReferralId,
  toId,
  toName,
  records,
}) => {
  const [loading, setLoading] = useState(false);
  const [addMsgMode, setAddMsgMode] = useState(false);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState({
    referralId: ReferralId,
    toId: toId,
  });
  const [editorStateValid, setEditorStateValid] = useState(false);
  console.log('records',records);
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    setMessage({ ...message, subject: e.target.value });
  };
  const editorOnChange = (rawHtml, valid) => {
    setMessage({ ...message, content: rawHtml });
    setEditorStateValid(valid);
  };
  const handleClose = () => {
    cancel(false);
  };
  const handleSend = () => {
    setLoading(true);
    console.log('message', message);
    Agent.Referral.add(message)
      .then(() => {
        setLoading(false);
        setAddMsgMode(false);
        cancel(false);
      })
      .catch((error) => {
        console.log(error);
        debugger;
        setLoading(false);
      });
  };
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <Typography variant="h5" align="center" color="primary">
          {`Write Message to ${toName}`}
        </Typography>
      </DialogTitle>
      <Divider />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className="p-6"
      >
        <Button
          endIcon={!addMsgMode ? <AddIcon /> : null}
          onClick={() => {
            setAddMsgMode((prevState) => !prevState);
          }}
        >
          {addMsgMode ? 'Hide Message Option' : 'Add Message'}
        </Button>
        <Grid item>
          <LoadingButton
            className="text-red-600 font-medium"
            onClick={handleClose}
          >
            Cancel
          </LoadingButton>
        </Grid>
      </Grid>
      <Divider />
      {addMsgMode && (
        <>
          <DialogActions>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  fullWidth
                  size="small"
                  value={subject}
                  onChange={handleSubjectChange}
                  label="Message Subject/Title"
                  required
                />
              </Grid>

              <Grid item>
                <EditorDraftJs editorOnChange={editorOnChange} />
              </Grid>
            </Grid>
          </DialogActions>
          <DialogActions>
            <Grid container justifyContent="space-between">
              <Grid item>
                {/* <LoadingButton
                  loading={loading}
                  autoFocus
                  onClick={handleClose}
                >
                  
                </LoadingButton> */}
              </Grid>
              <Grid item>
                <LoadingButton
                  loading={loading}
                  className="bg-sky-400 hover:bg-sky-500 mb-4 mr-4"
                  onClick={handleSend}
                  variant="contained"
                  disabled={subject.length == 0 || !editorStateValid}
                >
                  Send
                </LoadingButton>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      )}
      <Divider />
      <DialogActions>
        <Grid item container>
          <ReferralConversations
            messages={records}
            user={toName}
            hideReplyOption={true}
          />
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ReferralAddMessage;
