/* eslint-disable react/display-name */
import { Grid, Button, FormControlLabel, Switch } from '@mui/material';

import React, { useEffect, useState } from 'react';
import Agent from '../../../../api/agent';
import { connect } from 'react-redux';
import * as actions from '../../../../store/actions';

const LanguageTests = ({
  university,
  languageTests,
  loadLanguageTests,
  setAlert,
  updateParent,
}) => {
  const [selectedTests, setSelectedTests] = useState(university.tests);

  useEffect(() => {
    if (languageTests.length == 0) {
      loadLanguageTests();
    }
  }, [languageTests]);

  const saveTests = () => {
    Agent.Universities.saveTests({
      universityId: university.id,
      tests: selectedTests,
    })
      .then(() => {
        setAlert();
        updateParent({ tests: selectedTests });
      })
      .catch((error) => {
        console.log(error);
        setAlert('Failed to save', false);
        debugger;
      });
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid container direction="column">
        {languageTests.map((test) => (
          <Grid item key={test.id}>
            <FormControlLabel
              control={
                <Switch
                  checked={selectedTests.some((item) => item == test.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedTests(selectedTests.concat(test.id));
                    } else {
                      setSelectedTests(
                        selectedTests.filter((item) => item != test.id)
                      );
                    }
                  }}
                  name="isInactive"
                />
              }
              label={test.name}
            />
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Button
          color="primary"
          className="bg-sky-400 hover:bg-sky-500 text-white w-60"
          onClick={saveTests}
        >
          Save Tests
        </Button>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    languageTests: state.management.languageTests,
    university: ownProps.university,
    setAlert: ownProps.setAlert,
    updateParent: ownProps.updateParent,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadLanguageTests: () => dispatch(actions.loadLanguageTests()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageTests);
