import React, {useState} from 'react'
import Grid from '@mui/material/Grid'
import Select from '@mui/material/Select'
import {AGENTSOURCES} from '../../../../Util/Constants'
import {LoadingButton} from '@mui/lab'
import Agent from '../../../../api/agent'

const OtherActions = ({agent}) => {
  const [source, setSource] = useState(agent.source)
  const [updating, setUpdating] = useState(false)
  const changeSource = async () => {
    setUpdating(true)
    const editDto = {
      agentId: agent.id,
      source,
    }
    await Agent.Agents.updateOthers(editDto)
      .then(() => {
        setUpdating(false)
      })
      .catch((error) => {
        console.log(error)
        setUpdating(false)
        debugger
      })
  }

  return (
    <Grid container>
      <Grid item>
        <Select
          fullWidth
          size='small'
          native
          label='Agent Source'
          value={source}
          onChange={(e) => {
            setSource(e.target.value)
          }}
        >
          <option value={0}>--Agent Source--</option>
          {AGENTSOURCES.map((item) => (
            <option key={item.id} value={item.id}>
              {item.type}
            </option>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <LoadingButton
          variant='contained'
          className='bg-sky-400 hover:bg-sky-500 mx-2'
          loading={updating}
          onClick={changeSource}
        >
          Submit Changes
        </LoadingButton>
      </Grid>
    </Grid>
  )
}

export default OtherActions
