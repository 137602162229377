import {
  USER_PERMISSIONS,
  USER_PERMISSIONS_OBJECT_INITIAL,
} from '../Util/Constants';

import {
  formatDistance,
  format,
  addMinutes,
  differenceInHours,
  differenceInDays,
} from 'date-fns';
import jwt_decode from 'jwt-decode';
const _ = require('lodash');

export const isPasswordValid = (val) => {
  let pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8}.*$/;
  return pattern.test(val);
};

export const getDateDifference = (d, formatForLocal) => {
  if (!d) return '';
  let now = new Date();
  let nowFormatted = format(
    addMinutes(now, formatForLocal ? now.getTimezoneOffset() : 0),
    'yyyy-MM-dd HH:mm:ss'
  );
  return formatDistance(new Date(nowFormatted), new Date(d), {
    addSuffix: true,
  });
};

export const getDateDifferenceInHours = (d) => {
  return differenceInHours(new Date(), d);
};
export const getDateDifferenceInDays = (d) => {
  return differenceInDays(new Date(), d);
};
export const getNameCase = (str) => {
  const arr = str.split(' ');
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }
  return arr.join(' ');
};

export const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const getDateForMuTextField = (date) => {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  var monthString = '';
  if (month < 10) monthString = `0${month}`;
  else monthString = month;

  return `${year}-${monthString}-${day}`;
};

export function getAgentStatusNameFromNumber(status) {
  switch (status) {
    case 1:
      return 'Incomplete';
    case 2:
      return 'Pending';
    case 3:
      return 'Rejected';
    case 4:
      return 'Blocked';
    case 5:
      return 'Approved';
    case 6:
      return 'Disabled';
    case 7:
      return 'Unverified';
    case 8:
      return 'Agreement Expired';
    default:
      return 'Unknown';
  }
}

export function getInitialsFromFullName(name) {
  var parts = name.split(' ');
  var firstInitial = parts[0].charAt(0).toUpperCase();
  var lastInitial = parts[parts.length - 1].charAt(0).toUpperCase();
  return firstInitial + lastInitial;
}

export function formatError(error) {
  let axiosError = {
    statusText: '',
    errorCount: 0,
    error: 'Something has gone wrong. Try again!',
    errors: [],
  };
  try {
    axiosError.statusText = error.response.statusText;
    if (
      typeof error.response.data == 'object' &&
      error.response.data !== null
    ) {
      for (const [key, value] of Object.entries(error.response.data.errors)) {
        axiosError.errorCount++;
        axiosError.errors.push(`${key}: ${value}`);
      }
    } else {
      axiosError.error = error.response.data;
    }
  } catch (err) {
    axiosError.errorCount = 1;
    console.log(err);
  }

  return axiosError;
}

export const modules = {
  toolbar: [
    [{'header': [1, 2, false] }],
    ['bold', 'italic', 'underline','strike', 'blockquote'],
    [{'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image','video'],
    [{'color': [] }, { 'background': [] }],          
    [{'font': [] }],
    [{'align': [] }],
    ['clean'],

  ],
}

export const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image','video',
  'color','background',
  'font',
  'align',
  'clean',
]

export function getPermissionStructureAsArray() {
  return _.cloneDeep(USER_PERMISSIONS);
}

export function getUserPermissionsFromToken(token) {
  var decodedToken = jwt_decode(token);
  var permissions = _.cloneDeep(USER_PERMISSIONS_OBJECT_INITIAL);
  for (const [key] of Object.entries(permissions)) {
    if (decodedToken[key] != undefined) {
      permissions[key] = true;
    }
  }

  return permissions;
}

export function getUserProfileStatus(status) {
  switch (status) {
    case 1:
      return 'Incomplete';
    case 2:
      return 'Pending';
    case 3:
      return 'Rejected';
    case 4:
      return 'Blocked';
    case 5:
      return 'Approved';
    case 6:
      return 'Disabled';
    case 7:
      return 'Unverified';
    default:
      return 'Active';
  }
}

// Get the next four years array list from the current year
export const getNext3Years = () => {
  const currentDate = new Date();
  let next3Years = [];
  let currentYear = currentDate.getFullYear();
  for (let i = 0; i < 3; i++) {
    next3Years.push(currentYear++);
  }
  return next3Years;
};

export const Countries = [
  {
    id: 0,
    name: 'Unknown', //For Default select value in nationality field
    order: 0,
    code: '',
  },
  {
    id: 1,
    name: 'Andorra',
    order: 21,
    code: 'AD',
  },
  {
    id: 2,
    name: 'United Arab Emirates',
    order: 22,
    code: 'AE',
    currency: 'AED',
  },
  {
    id: 3,
    name: 'Afghanistan',
    order: 23,
    code: 'AF',
  },
  {
    id: 4,
    name: 'Antigua and Barbuda',
    order: 24,
    code: 'AG',
  },
  {
    id: 5,
    name: 'Anguilla',
    order: 25,
    code: 'AI',
  },
  {
    id: 6,
    name: 'Albania',
    order: 26,
    code: 'AL',
  },
  {
    id: 7,
    name: 'Armenia',
    order: 27,
    code: 'AM',
  },
  {
    id: 8,
    name: 'Angola',
    order: 28,
    code: 'AO',
  },
  {
    id: 9,
    name: 'Antarctica',
    order: 29,
    code: 'AQ',
  },
  {
    id: 10,
    name: 'Argentina',
    order: 30,
    code: 'AR',
  },
  {
    id: 11,
    name: 'American Samoa',
    order: 31,
    code: 'AS',
  },
  {
    id: 12,
    name: 'Austria',
    order: 21,
    code: 'AT',
    currency: '€',
  },
  {
    id: 13,
    name: 'Australia',
    order: 2,
    code: 'AU',
    currency: 'AUD',
    showAll: true,
  },
  {
    id: 14,
    name: 'Aruba',
    order: 34,
    code: 'AW',
  },
  {
    id: 15,
    name: 'Alland Islands',
    order: 35,
    code: 'AX',
  },
  {
    id: 16,
    name: 'Azerbaijan',
    order: 36,
    code: 'AZ',
  },
  {
    id: 17,
    name: 'Bosnia and Herzegovina',
    order: 37,
    code: 'BA',
  },
  {
    id: 18,
    name: 'Barbados',
    order: 38,
    code: 'BB',
  },
  {
    id: 19,
    name: 'Bangladesh',
    order: 39,
    code: 'BD',
    whatsApp: '+8801978780202',
  },
  {
    id: 20,
    name: 'Belgium',
    order: 40,
    code: 'BE',
    currency: 'лв',
  },
  {
    id: 21,
    name: 'Burkina Faso',
    order: 41,
    code: 'BF',
  },
  {
    id: 22,
    name: 'Bulgaria',
    order: 42,
    code: 'BG',
    currency: '€',
  },
  {
    id: 23,
    name: 'Bahrain',
    order: 43,
    code: 'BH',
  },
  {
    id: 24,
    name: 'Burundi',
    order: 44,
    code: 'BI',
  },
  {
    id: 25,
    name: 'Benin',
    order: 45,
    code: 'BJ',
  },
  {
    id: 26,
    name: 'Saint Barthelemy',
    order: 46,
    code: 'BL',
  },
  {
    id: 27,
    name: 'Bermuda',
    order: 47,
    code: 'BM',
  },
  {
    id: 28,
    name: 'Brunei Darussalam',
    order: 48,
    code: 'BN',
  },
  {
    id: 29,
    name: 'Bolivia',
    order: 49,
    code: 'BO',
  },
  {
    id: 30,
    name: 'Brazil',
    order: 50,
    code: 'BR',
  },
  {
    id: 31,
    name: 'Bahamas',
    order: 51,
    code: 'BS',
  },
  {
    id: 32,
    name: 'Bhutan',
    order: 52,
    code: 'BT',
  },
  {
    id: 33,
    name: 'Bouvet Island',
    order: 53,
    code: 'BV',
  },
  {
    id: 34,
    name: 'Botswana',
    order: 54,
    code: 'BW',
  },
  {
    id: 35,
    name: 'Belarus',
    order: 55,
    code: 'BY',
  },
  {
    id: 36,
    name: 'Belize',
    order: 56,
    code: 'BZ',
  },
  {
    id: 37,
    name: 'Canada',
    order: 3,
    code: 'CA',
    currency: 'CAD',
    showAll: true,
  },
  {
    id: 38,
    name: 'Cocos (Keeling) Islands',
    order: 58,
    code: 'CC',
  },
  {
    id: 39,
    name: 'Congo, Democratic Republic of the',
    order: 59,
    code: 'CD',
  },
  {
    id: 40,
    name: 'Central African Republic',
    order: 60,
    code: 'CF',
  },
  {
    id: 41,
    name: 'Congo, Republic of the',
    order: 61,
    code: 'CG',
  },
  {
    id: 42,
    name: 'Switzerland',
    order: 62,
    code: 'CH',
    currency: 'CHf',
  },
  {
    id: 43,
    name: "Cote d'Ivoire",
    order: 63,
    code: 'CI',
  },
  {
    id: 44,
    name: 'Cook Islands',
    order: 64,
    code: 'CK',
  },
  {
    id: 45,
    name: 'Chile',
    order: 65,
    code: 'CL',
  },
  {
    id: 46,
    name: 'Cameroon',
    order: 66,
    code: 'CM',
  },
  {
    id: 47,
    name: 'China',
    order: 67,
    code: 'CN',
  },
  {
    id: 48,
    name: 'Colombia',
    order: 68,
    code: 'CO',
  },
  {
    id: 49,
    name: 'Costa Rica',
    order: 69,
    code: 'CR',
  },
  {
    id: 50,
    name: 'Cuba',
    order: 70,
    code: 'CU',
  },
  {
    id: 51,
    name: 'Cape Verde',
    order: 71,
    code: 'CV',
  },
  {
    id: 52,
    name: 'Curacao',
    order: 72,
    code: 'CW',
  },
  {
    id: 53,
    name: 'Christmas Island',
    order: 73,
    code: 'CX',
  },
  {
    id: 54,
    name: 'Cyprus',
    order: 74,
    code: 'CY',
    currency: '€',
  },
  {
    id: 55,
    name: 'Czech Republic',
    order: 75,
    code: 'CZ',
    currency: 'Kč',
  },
  {
    id: 56,
    name: 'Germany',
    order: 76,
    code: 'DE',
    currency: '€',
  },
  {
    id: 57,
    name: 'Djibouti',
    order: 77,
    code: 'DJ',
  },
  {
    id: 58,
    name: 'Denmark',
    order: 78,
    code: 'DK',
    currency: 'kr',
  },
  {
    id: 59,
    name: 'Dominica',
    order: 79,
    code: 'DM',
  },
  {
    id: 60,
    name: 'Dominican Republic',
    order: 80,
    code: 'DO',
  },
  {
    id: 61,
    name: 'Algeria',
    order: 81,
    code: 'DZ',
  },
  {
    id: 62,
    name: 'Ecuador',
    order: 82,
    code: 'EC',
  },
  {
    id: 63,
    name: 'Estonia',
    order: 83,
    code: 'EE',
    currency: '€',
  },
  {
    id: 64,
    name: 'Egypt',
    order: 84,
    code: 'EG',
  },
  {
    id: 65,
    name: 'Western Sahara',
    order: 85,
    code: 'EH',
  },
  {
    id: 66,
    name: 'Eritrea',
    order: 86,
    code: 'ER',
  },
  {
    id: 67,
    name: 'Spain',
    order: 87,
    code: 'ES',
    currency: '€',
  },
  {
    id: 68,
    name: 'Ethiopia',
    order: 88,
    code: 'ET',
  },
  {
    id: 69,
    name: 'Finland',
    order: 89,
    code: 'FI',
    currency: '€',
  },
  {
    id: 70,
    name: 'Fiji',
    order: 90,
    code: 'FJ',
  },
  {
    id: 71,
    name: 'Falkland Islands (Malvinas)',
    order: 91,
    code: 'FK',
  },
  {
    id: 72,
    name: 'Micronesia, Federated States of',
    order: 92,
    code: 'FM',
  },
  {
    id: 73,
    name: 'Faroe Islands',
    order: 93,
    code: 'FO',
  },
  {
    id: 74,
    name: 'France',
    order: 94,
    code: 'FR',
  },
  {
    id: 75,
    name: 'Gabon',
    order: 95,
    code: 'GA',
  },
  {
    id: 76,
    name: 'UK',
    short: 'UK',
    order: 1,
    code: 'GB',
    currency: '£',
    showAll: true,
    whatsApp: '+447939940559',
  },
  {
    id: 77,
    name: 'Grenada',
    order: 97,
    code: 'GD',
  },
  {
    id: 78,
    name: 'Georgia',
    order: 98,
    code: 'GE',
  },
  {
    id: 79,
    name: 'French Guiana',
    order: 99,
    code: 'GF',
  },
  {
    id: 80,
    name: 'Guernsey',
    order: 100,
    code: 'GG',
  },
  {
    id: 81,
    name: 'Ghana',
    order: 101,
    code: 'GH',
  },
  {
    id: 82,
    name: 'Gibraltar',
    order: 102,
    code: 'GI',
  },
  {
    id: 83,
    name: 'Greenland',
    order: 103,
    code: 'GL',
  },
  {
    id: 84,
    name: 'Gambia',
    order: 104,
    code: 'GM',
  },
  {
    id: 85,
    name: 'Guinea',
    order: 105,
    code: 'GN',
  },
  {
    id: 86,
    name: 'Guadeloupe',
    order: 106,
    code: 'GP',
  },
  {
    id: 87,
    name: 'Equatorial Guinea',
    order: 107,
    code: 'GQ',
  },
  {
    id: 88,
    name: 'Greece',
    order: 108,
    code: 'GR',
    currency: '€',
  },
  {
    id: 89,
    name: 'South Georgia and the South Sandwich Islands',
    order: 109,
    code: 'GS',
  },
  {
    id: 90,
    name: 'Guatemala',
    order: 110,
    code: 'GT',
  },
  {
    id: 91,
    name: 'Guam',
    order: 111,
    code: 'GU',
  },
  {
    id: 92,
    name: 'Guinea-Bissau',
    order: 112,
    code: 'GW',
  },
  {
    id: 93,
    name: 'Guyana',
    order: 113,
    code: 'GY',
  },
  {
    id: 94,
    name: 'Hong Kong',
    order: 114,
    code: 'HK',
  },
  {
    id: 95,
    name: 'Heard Island and McDonald Islands',
    order: 115,
    code: 'HM',
  },
  {
    id: 96,
    name: 'Honduras',
    order: 116,
    code: 'HN',
  },
  {
    id: 97,
    name: 'Croatia',
    order: 117,
    code: 'HR',
    currency: '€',
  },
  {
    id: 98,
    name: 'Haiti',
    order: 118,
    code: 'HT',
  },
  {
    id: 99,
    name: 'Hungary',
    order: 119,
    code: 'HU',
    currency: '€',
  },
  {
    id: 100,
    name: 'Indonesia',
    order: 120,
    code: 'ID',
  },
  {
    id: 101,
    name: 'Ireland',
    currency: '£',
    showAll: true,
    order: 5,
    code: 'IE',
  },
  {
    id: 102,
    name: 'Israel',
    order: 122,
    code: 'IL',
  },
  {
    id: 103,
    name: 'Isle of Man',
    order: 123,
    code: 'IM',
  },
  {
    id: 104,
    name: 'India',
    order: 124,
    code: 'IN',
    whatsApp: '+918583858651',
  },
  {
    id: 105,
    name: 'British Indian Ocean Territory',
    order: 125,
    code: 'IO',
  },
  {
    id: 106,
    name: 'Iraq',
    order: 126,
    code: 'IQ',
  },
  {
    id: 107,
    name: 'Iran, Islamic Republic of',
    order: 127,
    code: 'IR',
  },
  {
    id: 108,
    name: 'Iceland',
    order: 128,
    code: 'IS',
  },
  {
    id: 109,
    name: 'Italy',
    order: 129,
    code: 'IT',
    currency: '€',
  },
  {
    id: 110,
    name: 'Jersey',
    order: 130,
    code: 'JE',
  },
  {
    id: 111,
    name: 'Jamaica',
    order: 131,
    code: 'JM',
  },
  {
    id: 112,
    name: 'Jordan',
    order: 132,
    code: 'JO',
  },
  {
    id: 113,
    name: 'Japan',
    order: 133,
    code: 'JP',
  },
  {
    id: 114,
    name: 'Kenya',
    order: 134,
    code: 'KE',
  },
  {
    id: 115,
    name: 'Kyrgyzstan',
    order: 135,
    code: 'KG',
  },
  {
    id: 116,
    name: 'Cambodia',
    order: 136,
    code: 'KH',
  },
  {
    id: 117,
    name: 'Kiribati',
    order: 137,
    code: 'KI',
  },
  {
    id: 118,
    name: 'Comoros',
    order: 138,
    code: 'KM',
  },
  {
    id: 119,
    name: 'Saint Kitts and Nevis',
    order: 139,
    code: 'KN',
  },
  {
    id: 120,
    name: "Korea, Democratic People's Republic of",
    order: 140,
    code: 'KP',
  },
  {
    id: 121,
    name: 'Korea, Republic of',
    order: 141,
    code: 'KR',
  },
  {
    id: 122,
    name: 'Kuwait',
    order: 142,
    code: 'KW',
  },
  {
    id: 123,
    name: 'Cayman Islands',
    order: 143,
    code: 'KY',
  },
  {
    id: 124,
    name: 'Kazakhstan',
    order: 144,
    code: 'KZ',
  },
  {
    id: 125,
    name: "Lao People's Democratic Republic",
    order: 145,
    code: 'LA',
  },
  {
    id: 126,
    name: 'Lebanon',
    order: 146,
    code: 'LB',
  },
  {
    id: 127,
    name: 'Saint Lucia',
    order: 147,
    code: 'LC',
  },
  {
    id: 128,
    name: 'Liechtenstein',
    order: 148,
    code: 'LI',
  },
  {
    id: 129,
    name: 'Sri Lanka',
    order: 149,
    code: 'LK',
  },
  {
    id: 130,
    name: 'Liberia',
    order: 150,
    code: 'LR',
  },
  {
    id: 131,
    name: 'Lesotho',
    order: 151,
    code: 'LS',
  },
  {
    id: 132,
    name: 'Lithuania',
    order: 152,
    code: 'LT',
    currency: '€',
  },
  {
    id: 133,
    name: 'Luxembourg',
    order: 153,
    code: 'LU',
    currency: '€',
  },
  {
    id: 134,
    name: 'Latvia',
    order: 154,
    code: 'LV',
    currency: '€',
  },
  {
    id: 135,
    name: 'Libya',
    order: 155,
    code: 'LY',
  },
  {
    id: 136,
    name: 'Morocco',
    order: 156,
    code: 'MA',
    whatsApp: '+212643113158',
  },
  {
    id: 137,
    name: 'Monaco',
    order: 157,
    code: 'MC',
  },
  {
    id: 138,
    name: 'Moldova, Republic of',
    order: 158,
    code: 'MD',
  },
  {
    id: 139,
    name: 'Montenegro',
    order: 159,
    code: 'ME',
  },
  {
    id: 140,
    name: 'Saint Martin (French part)',
    order: 160,
    code: 'MF',
  },
  {
    id: 141,
    name: 'Madagascar',
    order: 161,
    code: 'MG',
  },
  {
    id: 142,
    name: 'Marshall Islands',
    order: 162,
    code: 'MH',
  },
  {
    id: 143,
    name: 'Macedonia, the Former Yugoslav Republic of',
    order: 163,
    code: 'MK',
  },
  {
    id: 144,
    name: 'Mali',
    order: 164,
    code: 'ML',
  },
  {
    id: 145,
    name: 'Myanmar',
    order: 165,
    code: 'MM',
  },
  {
    id: 146,
    name: 'Mongolia',
    order: 166,
    code: 'MN',
  },
  {
    id: 147,
    name: 'Macao',
    order: 167,
    code: 'MO',
  },
  {
    id: 148,
    name: 'Northern Mariana Islands',
    order: 168,
    code: 'MP',
  },
  {
    id: 149,
    name: 'Martinique',
    order: 169,
    code: 'MQ',
  },
  {
    id: 150,
    name: 'Mauritania',
    order: 170,
    code: 'MR',
  },
  {
    id: 151,
    name: 'Montserrat',
    order: 171,
    code: 'MS',
  },
  {
    id: 152,
    name: 'Malta',
    order: 172,
    code: 'MT',
    currency: '€',
  },
  {
    id: 153,
    name: 'Mauritius',
    order: 173,
    code: 'MU',
  },
  {
    id: 154,
    name: 'Maldives',
    order: 174,
    code: 'MV',
  },
  {
    id: 155,
    name: 'Malawi',
    order: 175,
    code: 'MW',
  },
  {
    id: 156,
    name: 'Mexico',
    order: 176,
    code: 'MX',
  },
  {
    id: 157,
    name: 'Malaysia',
    currency: '£',
    showAll: true,
    order: 7,
    code: 'MY',
  },
  {
    id: 158,
    name: 'Mozambique',
    order: 178,
    code: 'MZ',
  },
  {
    id: 159,
    name: 'Namibia',
    order: 179,
    code: 'NA',
  },
  {
    id: 160,
    name: 'New Caledonia',
    order: 180,
    code: 'NC',
  },
  {
    id: 161,
    name: 'Niger',
    order: 181,
    code: 'NE',
  },
  {
    id: 162,
    name: 'Norfolk Island',
    order: 182,
    code: 'NF',
  },
  {
    id: 163,
    name: 'Nigeria',
    order: 183,
    code: 'NG',
  },
  {
    id: 164,
    name: 'Nicaragua',
    order: 184,
    code: 'NI',
  },
  {
    id: 165,
    name: 'Netherlands',
    order: 185,
    code: 'NL',
    currency: '€',
  },
  {
    id: 166,
    name: 'Norway',
    order: 186,
    code: 'NO',
    currency: 'kr',
  },
  {
    id: 167,
    name: 'Nepal',
    order: 187,
    code: 'NP',
  },
  {
    id: 168,
    name: 'Nauru',
    order: 188,
    code: 'NR',
  },
  {
    id: 169,
    name: 'Niue',
    order: 189,
    code: 'NU',
  },
  {
    id: 170,
    name: 'New Zealand',
    currency: '£',
    showAll: true,
    order: 6,
    code: 'NZ',
  },
  {
    id: 171,
    name: 'Oman',
    order: 191,
    code: 'OM',
  },
  {
    id: 172,
    name: 'Panama',
    order: 192,
    code: 'PA',
  },
  {
    id: 173,
    name: 'Peru',
    order: 193,
    code: 'PE',
  },
  {
    id: 174,
    name: 'French Polynesia',
    order: 194,
    code: 'PF',
  },
  {
    id: 175,
    name: 'Papua New Guinea',
    order: 195,
    code: 'PG',
  },
  {
    id: 176,
    name: 'Philippines',
    order: 196,
    code: 'PH',
  },
  {
    id: 177,
    name: 'Pakistan',
    order: 197,
    code: 'PK',
    whatsApp: '+923100002414',
  },
  {
    id: 178,
    name: 'Poland',
    order: 198,
    code: 'PL',
    currency: 'zł',
  },
  {
    id: 179,
    name: 'Saint Pierre and Miquelon',
    order: 199,
    code: 'PM',
  },
  {
    id: 180,
    name: 'Pitcairn',
    order: 200,
    code: 'PN',
  },
  {
    id: 181,
    name: 'Puerto Rico',
    order: 201,
    code: 'PR',
  },
  {
    id: 182,
    name: 'Palestine, State of',
    order: 202,
    code: 'PS',
  },
  {
    id: 183,
    name: 'Portugal',
    order: 203,
    code: 'PT',
    currency: '€',
  },
  {
    id: 184,
    name: 'Palau',
    order: 204,
    code: 'PW',
  },
  {
    id: 185,
    name: 'Paraguay',
    order: 205,
    code: 'PY',
  },
  {
    id: 186,
    name: 'Qatar',
    order: 206,
    code: 'QA',
  },
  {
    id: 187,
    name: 'Reunion',
    order: 207,
    code: 'RE',
  },
  {
    id: 188,
    name: 'Romania',
    order: 208,
    code: 'RO',
    currency: 'lei',
  },
  {
    id: 189,
    name: 'Serbia',
    order: 209,
    code: 'RS',
  },
  {
    id: 190,
    name: 'Russian Federation',
    order: 210,
    code: 'RU',
  },
  {
    id: 191,
    name: 'Rwanda',
    order: 211,
    code: 'RW',
  },
  {
    id: 192,
    name: 'Saudi Arabia',
    order: 212,
    code: 'SA',
    whatsApp: '+966539626555',
  },
  {
    id: 193,
    name: 'Solomon Islands',
    order: 213,
    code: 'SB',
  },
  {
    id: 194,
    name: 'Seychelles',
    order: 214,
    code: 'SC',
  },
  {
    id: 195,
    name: 'Sudan',
    order: 215,
    code: 'SD',
  },
  {
    id: 196,
    name: 'Sweden',
    order: 216,
    code: 'SE',
    currency: 'kr',
  },
  {
    id: 197,
    name: 'Singapore',
    order: 217,
    code: 'SG',
    currency: '$',
  },
  {
    id: 198,
    name: 'Saint Helena',
    order: 218,
    code: 'SH',
  },
  {
    id: 199,
    name: 'Slovenia',
    order: 219,
    code: 'SI',
    currency: '€',
  },
  {
    id: 200,
    name: 'Svalbard and Jan Mayen',
    order: 220,
    code: 'SJ',
  },
  {
    id: 201,
    name: 'Slovakia',
    order: 221,
    code: 'SK',
    currency: '€',
  },
  {
    id: 202,
    name: 'Sierra Leone',
    order: 222,
    code: 'SL',
  },
  {
    id: 203,
    name: 'San Marino',
    order: 223,
    code: 'SM',
  },
  {
    id: 204,
    name: 'Senegal',
    order: 224,
    code: 'SN',
  },
  {
    id: 205,
    name: 'Somalia',
    order: 225,
    code: 'SO',
  },
  {
    id: 206,
    name: 'Suriname',
    order: 226,
    code: 'SR',
  },
  {
    id: 207,
    name: 'South Sudan',
    order: 227,
    code: 'SS',
  },
  {
    id: 208,
    name: 'Sao Tome and Principe',
    order: 228,
    code: 'ST',
  },
  {
    id: 209,
    name: 'El Salvador',
    order: 229,
    code: 'SV',
  },
  {
    id: 210,
    name: 'Sint Maarten (Dutch part)',
    order: 230,
    code: 'SX',
  },
  {
    id: 211,
    name: 'Syrian Arab Republic',
    order: 231,
    code: 'SY',
  },
  {
    id: 212,
    name: 'Swaziland',
    order: 232,
    code: 'SZ',
  },
  {
    id: 213,
    name: 'Turks and Caicos Islands',
    order: 233,
    code: 'TC',
  },
  {
    id: 214,
    name: 'Chad',
    order: 234,
    code: 'TD',
  },
  {
    id: 215,
    name: 'French Southern Territories',
    order: 235,
    code: 'TF',
  },
  {
    id: 216,
    name: 'Togo',
    order: 236,
    code: 'TG',
  },
  {
    id: 217,
    name: 'Thailand',
    order: 237,
    code: 'TH',
  },
  {
    id: 218,
    name: 'Tajikistan',
    order: 238,
    code: 'TJ',
  },
  {
    id: 219,
    name: 'Tokelau',
    order: 239,
    code: 'TK',
  },
  {
    id: 220,
    name: 'Timor-Leste',
    order: 240,
    code: 'TL',
  },
  {
    id: 221,
    name: 'Turkmenistan',
    order: 241,
    code: 'TM',
  },
  {
    id: 222,
    name: 'Tunisia',
    order: 242,
    code: 'TN',
  },
  {
    id: 223,
    name: 'Tonga',
    order: 243,
    code: 'TO',
  },
  {
    id: 224,
    name: 'Turkey',
    order: 244,
    code: 'TR',
  },
  {
    id: 225,
    name: 'Trinidad and Tobago',
    order: 245,
    code: 'TT',
  },
  {
    id: 226,
    name: 'Tuvalu',
    order: 246,
    code: 'TV',
  },
  {
    id: 227,
    name: 'Taiwan, Province of China',
    order: 247,
    code: 'TW',
  },
  {
    id: 228,
    name: 'United Republic of Tanzania',
    order: 248,
    code: 'TZ',
  },
  {
    id: 229,
    name: 'Ukraine',
    order: 249,
    code: 'UA',
  },
  {
    id: 230,
    name: 'Uganda',
    order: 250,
    code: 'UG',
  },
  {
    id: 231,
    name: 'USA',
    short: 'USA',
    showAll: true,
    currency: '$',
    order: 4,
    code: 'US',
  },
  {
    id: 232,
    name: 'Uruguay',
    order: 252,
    code: 'UY',
  },
  {
    id: 233,
    name: 'Uzbekistan',
    order: 253,
    code: 'UZ',
  },
  {
    id: 234,
    name: 'Holy See (Vatican City State)',
    order: 254,
    code: 'VA',
  },
  {
    id: 235,
    name: 'Saint Vincent and the Grenadines',
    order: 255,
    code: 'aVCe',
  },
  {
    id: 236,
    name: 'Venezuela',
    order: 256,
    code: 'VE',
  },
  {
    id: 237,
    name: 'British Virgin Islands',
    order: 257,
    code: 'VG',
  },
  {
    id: 238,
    name: 'US Virgin Islands',
    order: 258,
    code: 'VI',
  },
  {
    id: 239,
    name: 'Vietnam',
    order: 259,
    code: 'VN',
  },
  {
    id: 240,
    name: 'Vanuatu',
    order: 260,
    code: 'VU',
  },
  {
    id: 241,
    name: 'Wallis and Futuna',
    order: 261,
    code: 'WF',
  },
  {
    id: 242,
    name: 'Samoa',
    order: 262,
    code: 'WS',
  },
  {
    id: 243,
    name: 'Kosovo',
    order: 263,
    code: 'XK',
  },
  {
    id: 244,
    name: 'Yemen',
    order: 264,
    code: 'YE',
  },
  {
    id: 245,
    name: 'Mayotte',
    order: 265,
    code: 'YT',
  },
  {
    id: 246,
    name: 'South Africa',
    order: 266,
    code: 'ZA',
  },
  {
    id: 247,
    name: 'Zambia',
    order: 267,
    code: 'ZM',
  },
  {
    id: 248,
    name: 'Zimbabwe',
    order: 268,
    code: 'ZW',
  },
];
