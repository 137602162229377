import React, { useState, useCallback } from 'react';

const useInput = (
  defaultValue,
  validateValue,
  onBlurParent,
  onChangeParent
) => {
  const [enteredValue, setEnteredValue] = useState(defaultValue);
  const [isTouched, setIsTouched] = useState(false);

  const valueIsValid = validateValue(enteredValue);
  // const hasError = !valueIsValid && isTouched;
  const hasError = !valueIsValid;
  const valueChangeHandler = useCallback((value, name) => {
    setEnteredValue(value);
    if (onChangeParent && name) {
      onChangeParent(name, value);
    }
  });
  const valueInputBlurHandler = useCallback((name) => {
    setIsTouched(true);
    if (onBlurParent) {
      onBlurParent(name, enteredValue);
    }
  });

  const reset = () => {
    setEnteredValue('');
    setIsTouched(false);
  };

  return {
    value: enteredValue,
    isValid: valueIsValid,
    hasError,
    valueChangeHandler,
    valueInputBlurHandler,
    reset,
    obj: {
      value: enteredValue,
      isValid: valueIsValid,
      hasError,
      valueChangeHandler,
      valueInputBlurHandler,
      reset,
    },
  };
};

export default useInput;
