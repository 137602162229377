import React, { useState, useEffect } from 'react';
import {
  Button,
  Grid,
  Typography,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import 'tailwindcss/tailwind.css';
import { connect } from 'react-redux';
import {
  DataGridPro,
  GridPagination,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';

import {
  AGENTSOURCES,
  AGENTSTATUS,
} from '../../../../../Util/Constants';
import { Countries } from '../../../../../Util/Util';
import CountdownLoader from '../../../StudentLeadComms/LeadComUtils/CountdownLoader';
import WhatsappSummaryDialog from '../../../StudentLeadComms/SendBulkMailDialog/WhatsappSummaryDialog';
import AgentComFilters from '../../AgentComFilters/AgentComFilters';
import Agent from './../../../../../api/agent';

function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {isInternal && <GridToolbarColumnsButton />}
      <GridToolbarDensitySelector />
      {hide && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}
const AgentBulkWhatsapp = ({ appUserId, users }) => {
  const [showCountdownLoader, setShowCountdownLoader] = useState(false);

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bdmId, setBdmId] = useState('');
  const [companyHq, setCompanyHq] = useState('');
  // const [statusFilter, setStatusFilter] = useState(0);
  const [agentId, setAgentId] = useState('');
  const [company, setCompany] = useState('');
  const [status, setStatus] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [totalSelected, setTotalSelected] = useState(0);
  const [selectTemplateId, setSelectTemplateId] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [offices, setOffices] = useState([]);
  const [fromMailList, setFromMailList] = useState([]);
  const [openWhatsappConfirmDialog, setWhatsappOpenConfirmDialog] =
    useState(false);
  const [dialogueCommunicationName, setDialogueCommunicationName] =
    useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [showData, setShowData] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRowCount, setSelectedRowCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(800);
  const [totalrows, setTotalRows] = useState(0);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [events, setEventList] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [agents, setAgents] = useState([]);
  const [agentIdList, setAgentIdList] = useState([]);



  const handleCompanyHqChange = (event, value) => {
    setCompanyHq(value ? value.id : 0);
  };

  const handleCommunicationNameChange = (event) => {
    setDialogueCommunicationName(event.target.value);
  };
  const handleSelectTemplateIdChange = (event) => {
    setSelectTemplateId(event.target.value);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleWhatsappCloseConfirmDialog = () => {
    setWhatsappOpenConfirmDialog(false);
  };
  const handleCountdownComplete = () => {
    setShowCountdownLoader(false);
  };

  const handleApplyFilters = () => {
    setShowData(true);
    setPageNumber(1); // Reset to first page when applying new filters
    fetchData(true);
  };

  const handleSendWhatsapp = async () => {
    handleWhatsappCloseConfirmDialog();
    setShowCountdownLoader(true);
    try {
      const response = await Agent.Communication.SendbulkWhatsappAgent(
        payloadWhatsapp
      ).then((response) => {
        setShowCountdownLoader(false);
        setOpenDialog(false);
        setSuccessMessage('Successfully sent WhatsApp messages!', response);
      });
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to send whatsapp.', error);
      setShowErrorMessage(true);
    }
  };

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  useEffect(() => {
    Agent.Events.getEventList().then(setEventList);
  }, []);

  const handleAddAgentId = (selectedAgentId) => {
    setAgentIdList((prevList) => [...prevList, { Id: selectedAgentId }]);
  };

  const closeErrorMessage = () => {
    setShowErrorMessage(false);
  };

  const ErrorMessageDisplay = () =>
    showErrorMessage && (
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-red-800 text-white p-6 rounded-lg shadow-lg z-50">
          <button
            className="btn btn-warning float-right"
            onClick={closeErrorMessage}
          >
            Close
          </button>
          <p>{errorMessage}</p>
        </div>
      </div>
    );

  const payloadWhatsapp = {
    Agents: agentIdList,
    CommunicationName: dialogueCommunicationName,
    AppUserId: appUserId,
    NumberOfStudents: totalSelected,
    TemplateId: selectTemplateId,
  };

  useEffect(() => {
    // fetchData();
    fetchTemplates();
  }, []);

  
  useEffect(() => {
    fetchData(true); // Initial fetch with filters
  }, []); 

  useEffect(() => {
    setSelectedRowCount(selectedRows.length);
  }, [selectedRows]);

  useEffect(() => {
    setTotalSelected(selectedRows.length);
  }, [selectedRows]);

  const fetchTemplates = async () => {
    try {
      const response = await Agent.Communication.getAgentWhatsappTemplates();
      setTemplates(response);
    } catch (error) {
      console.error(error);
    }
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', pageNumber.toString());
    params.append('pageSize', pageSize.toString());

    params.append('id', filter ? agentId.trim() : '');
    params.append('company', filter ? company.trim() : '');
    params.append('status', filter ? status.toString() : '0');
    params.append('BusinessDevAppUserDetailsId', filter ? bdmId.trim() : '');
    if (filter) {
      params.append('Country', companyHq);
    }
    return params;
  };

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  useEffect(() => {
    Agent.Communication.getFromEmails().then(setFromMailList);
  }, []);

  const fetchData = async (filter = false) => {
    setLoading(true);
    try {
      const params = getParams(filter);
      const agentWhatsapp = await Agent.Communication.whatsapplistAgent(params);
      const { pagination, data } = agentWhatsapp;
      //console.log('agentWhatsapp', agentWhatsapp);
      setTotalPages(pagination.totalPages);
      setTotalRows(pagination.totalItems);
      setDataLoaded(true);
      setAgents(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenConfirmDialog = () => {
    // setOpenConfirmDialog(true);
    setWhatsappOpenConfirmDialog(true);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);

    // If Select All is checked, select all students on the current page
    if (!selectAll) {
      const selectedIds = agents.map((studentLead) => ({
        Id: studentLead.id,
      }));
      setAgentIdList(selectedIds);
      setSelectedRows(selectedIds.map((student) => student.Id));
    } else {
      setAgentIdList([]);
      setSelectedRows([]);
    }
  };

  const renderCheckbox = (params) => (
    <Checkbox
      checked={selectedRows.includes(params.row.id) || selectAll}
      onChange={(event) => handleRowSelection(event, params.row.id)}
    />
  );

  let agentColumns = [
    {
      field: 'checkbox',
      headerName: 'Select',
      width: 100,
      renderCell: renderCheckbox,
      headerAlign: 'center',
      renderHeader: () => (
        <Checkbox
          checked={selectAll}
          onChange={handleSelectAll}
          indeterminate={
            selectedRows.length > 0 && selectedRows.length < agents.length
          }
        />
      ),
    },
    {
      field: 'id',
      headerName: 'Id',
      width: 80,
      hide: true,
    },

    {
      field: 'company',
      headerName: 'Company Name',
      width: 300,
      renderCell: (params) => <Typography>{params.row.companyName}</Typography>,
    },
    {
      field: 'country',
      headerName: 'Company HQ',
      width: 160,
      hide: false,
      valueGetter: (params) => {
        const country = params.row.country;
        const countryName = Countries.find((sta) => sta.id == country);
        return countryName ? countryName.name : '';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 160,
      valueGetter: (params) => {
        const status = params.row.status;
        const agentStatus = AGENTSTATUS.find((sta) => sta.id == status);
        return agentStatus ? agentStatus.type : '';
      },
    },
    {
      field: 'admin',
      headerName: 'Admin',
      width: 200,
      renderCell: (params) => <Typography>{params.row.adminName}</Typography>,
    },
    {
      field: 'agentPhoneNumber',
      headerName: 'Phone Number',
      width: 180,
      hide:true, 
      renderCell: (params) => (
        <Typography>{params.row.agentPhoneNumber}</Typography>
      ),
    },
    {
      field: 'companyPhoneNumber',
      headerName: 'Company Phone Number',
      width: 180,
      hide:true,
      renderCell: (params) => (
        <Typography>{params.row.companyPhoneNumber}</Typography>
      ),
    },
    {
      field: 'businessDevAppUserDetailsId',
      headerName: 'BDM',
      width: 300,
      hide: false,
      valueGetter: (params) => {
        const bdm = params.row.businessDevAppUserDetailsId;
        const bdmName =
          bdm && users.length > 0
            ? users.find(({ id }) => id == parseInt(bdm))
            : '';
        return bdmName ? bdmName.name : '';
      },
    },
    {
      field: 'noOfStudents',
      headerName: 'Student Count',
      width: 120,
      hide: true,
      renderCell: (params) => (
        <Typography className="pl-8">{params.row.noOfStudents}</Typography>
      ),
    },
    {
      field: 'companyEmail',
      headerName: 'Company Email',
      width: 250,
      hide: true,
      renderCell: (params) => (
        <Typography>{params.row.companyEmail}</Typography>
      ),
    },
    {
      field: 'applicationUpdateEmail',
      headerName: 'application Update Email',
      width: 260,
      hide: true,
      renderCell: (params) => (
        <Typography>{params.row.applicationUpdateEmail}</Typography>
      ),
    },

    {
      field: 'agentNationality',
      headerName: 'Nationality',
      width: 200,
      valueGetter: (params) => {
        const nationality = params.row.agentNationality;
        const nationalityName = nationality
          ? Countries.find(({ id }) => id == nationality)
          : '';
        return nationalityName ? nationalityName.name : '';
      },
    },

    {
      field: 'source',
      headerName: 'Source',
      width: 200,
      hide: true,
      valueGetter: (params) => {
        const source = params.row.source;
        const sourceName = source
          ? AGENTSOURCES.find(({ id }) => id == source)
          : '';
        return sourceName ? sourceName.type : '';
      },
    },
    {
      field: 'created',
      headerName: 'Created On',
      width: 120,
      hide: true,
      valueGetter: (params) => {
        const createdOn = params.row.created;
        return createdOn ? createdOn.split('T')[0] : '';
      },
    },
  ];

  const handleClearFilters = () => {
    setBdmId('');
    setAgentId('');
    setCompany('');
    setCompanyHq(0);
    setStatus(0);
    setSelectedRows([]);
    setAgentIdList([]);
    setShowData(false);
    setSelectAll(false);
    fetchData(false);
  };

  const handleRowSelection = (event, rowId) => {
    const selectedIndex = selectedRows.indexOf(rowId);
    let newSelectedRows = [];

    if (selectedIndex === -1) {
      newSelectedRows = [...selectedRows, rowId];
      const selectedAgentId = agents.find((agent) => agent.id === rowId).id;
      handleAddAgentId(selectedAgentId);
    } else {
      newSelectedRows = selectedRows.filter((id) => id !== rowId);
    }

    setSelectedRows(newSelectedRows);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setDialogueCommunicationName('');
  };

  const WhatsappConfirmDialog = () => (
    <Dialog
      open={openWhatsappConfirmDialog}
      onClose={handleWhatsappCloseConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {'Confirm Sending Whatsapp'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description ">
          Are you sure you want to send the whatsapp?
        </DialogContentText>
      </DialogContent>
      <DialogActions className="flex justify-around">
        <Button
          onClick={handleWhatsappCloseConfirmDialog}
          color="primary"
          className="btn-primary"
        >
          No
        </Button>
        <Button
          onClick={handleSendWhatsapp}
          color="success"
          className="btn-success"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
  return (
    <div className="h-96 w-full">
      <div className="bg-white p-4 mb-4 shadow-sm">
        <AgentComFilters
        users={users}
        companyHq={companyHq}
        setCompanyHQ={setCompanyHq}
        bdmId={bdmId}
        setBdmId={setBdmId}
        agentId={agentId}
        status={status}
        setAgentId={setAgentId}
        setStatus={setStatus}
        company={company}
        handleCompanyHqChange={handleCompanyHqChange}
        handleClearFilters={handleClearFilters}
        handleApplyFilters={handleApplyFilters}
        handleSendBulkEmail={handleOpenDialog}
        totalSelected={totalSelected}
        />

        {showData ? (
          // Show the DataGrid only when showData is true (filters are applied)
          dataLoaded ? (
            <Grid item style={{ height: 730, width: '100%' }}>
              <DataGridPro
                rows={agents}
                columns={agentColumns}
                totalPageSize={totalPages}
                loading={loading}
                pagination={true}
                paginationMode="server"
                pageSize={pageSize}
                pageSizeOptions={[800]}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowCount={totalrows}
                onPageChange={(newPage) => setPageNumber(newPage + 1)}
                page={pageNumber - 1}
                components={{
                  Toolbar: CustomToolbar,
                  Pagination: GridPagination,
                }}
              />
            </Grid>
          ) : (
            <div>Loading data...</div>
          )
        ) : (
          <div>No data to display. Please apply filters to show data.</div>
        )}

        <WhatsappSummaryDialog
          open={openDialog}
          handleClose={handleCloseDialog}
          totalSelected={totalSelected}
          templates={templates}
          dialogueCommunicationName={dialogueCommunicationName}
          selectTemplateId={selectTemplateId}
          handleSelectTemplateIdChange={handleSelectTemplateIdChange}
          handleCommunicationNameChange={handleCommunicationNameChange}
          handleOpenConfirmDialog={handleOpenConfirmDialog}
          // fromphoneList={Phone_From}
          // setFromNumber={setFromNumber}
        />

        {successMessage && (
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-blue-500 text-black p-4 rounded shadow-lg">
              <p>{successMessage}</p>
              <button
                className="btn btn-warning mt-4"
                onClick={() => setSuccessMessage('')}
              >
                Close
              </button>
            </div>
          </div>
        )}

        <ErrorMessageDisplay />
        <WhatsappConfirmDialog />

        {showCountdownLoader && (
          <CountdownLoader
            totalEmails={totalSelected}
            onCountdownComplete={handleCountdownComplete}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
    users: state.users,
  };
};

export default connect(mapStateToProps, null)(AgentBulkWhatsapp);
