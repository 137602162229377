import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Grid, Typography } from '@mui/material';
// import { makeStyles } from '@mui/styles';
import Agent from '../../../api/agent';
import {
  LEADCHANNELS,
  LEADORIGINS,
  STUDENTSOURCES,
  STUDY_LEVELS,
} from '../../../Util/Constants';
import { INTAKES } from '../../../Util/Constants';
import {
  AutocompleteSelectionMenu,
  intakeYearList,
  SelectionMenu,
  FormatTitle,
  getRows,
  formatDate,
  yearList,
} from '../Helper/HelperFunction';
import { connect } from 'react-redux';

const appTypes = [
  'studentsSummary',
  'registered',
  'profileCompleted',
  'applied',
  'outstanding',
  'incomplete',

  'appSummary',
  'applicationSubmitted',
  'offerHolder',
  'rejection',
  'conversion',
  'pendingApplication',
  'cancellation',
];

const SalesReport = ({ appUserId, appUserDetailsId, role, permissions }) => {
  // const classes = useStyles();
  const [appData, setAppData] = useState();
  const [isApiLoading, setApiLoading] = useState(true);
  const [leadOrigin, setLeadOrigin] = useState([]);
  const [leadSource, setLeadSource] = useState([]);

  const [payload, setPayload] = useState({
    year: 2023,
    leadOrigin: 0,
    leadSource: 0,
    leadChannel: 0,
    appUserId,
    appUserDetailsId,
    role,
    canManageAll: permissions.CanManageAllAgents,
  });

  //API CALL TO GET REPORT DATA
  useEffect(() => {
    Agent.Report.salesSummaryData(payload).then((res) => {
      setAppData(res.data.salesSummaryData);
      setApiLoading(false);
    });
  }, [payload]);

  //console.log(appData, 'appData');

  //COLUMN FIELD LOGIC
  const columns = [
    {
      field: 'title',
      headerName: '',
      width: 200,
      renderCell: (params) => (
        <Typography
          color={`${
            params.row.title === 'Students Summary' ||
            params.row.title === 'App Summary'
              ? '#972344'
              : '#0081C9'
          }`}
          fontSize={18}
          fontWeight={500}
        >
          {params.row.title}
        </Typography>
      ),
    },
    ...INTAKES.map((month) => ({
      field: month.typeShort,
      headerName: month.typeShort,
      width: 100,
    })),
    {
      field: 'Total',
      headerName: 'Total',
      width: 100,
      renderCell: (params) => (
        <Typography color="#0081C9" fontSize={18} fontWeight={500}>
          {isNaN(params.row.Total) ? '' : params.row.Total}
        </Typography>
      ),
    },
  ];

  //ROW FIELD LOGIC
  let rowData = [];

  const getRowData = () => {
    appTypes.forEach((item) => {
      let temp = getRows(item, appData);
      rowData.push({ [item]: temp });
    });
  };

  !isApiLoading && getRowData();

  const row = rowData.map((item) => {
    const key = Object.keys(item)[0];
    const data = item[key];
    return { id: key, title: FormatTitle(key), ...data };
  });

  // HANDLE RETURN DATA FROM SELECT MENU FILTER COMPONENT
  const handleSelectionMenu = (name, value) => {
    setPayload((prev) => {
      return { ...prev, [name]: Number(value) || 0 };
    });
  };

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Grid container direction={'column'}>
        <Grid item>
          <h4
            style={{
              textTransform: 'uppercase',
              color: '#282A3A',
              textAlign: 'center',
            }}
          >{`Sales Summary`}</h4>
        </Grid>

        <Grid
          container
          justifyContent={'center'}
          alignItems="center"
          sx={{ px: 2 }}
        >
          <Grid item>
            <SelectionMenu
              name="leadOrigin"
              label="Lead Origin"
              options={LEADORIGINS}
              onChange={handleSelectionMenu}
            />
          </Grid>

          <Grid item>
            <SelectionMenu
              name="leadSource"
              label="Lead Source"
              options={STUDENTSOURCES}
              onChange={handleSelectionMenu}
            ></SelectionMenu>
          </Grid>
          <Grid item>
            <SelectionMenu
              name="leadChannel"
              label="Lead Channel"
              options={LEADCHANNELS}
              onChange={handleSelectionMenu}
            ></SelectionMenu>
          </Grid>
          <Grid item>
            <SelectionMenu
              name="year"
              label="Year"
              val={payload.year}
              options={yearList}
              onChange={handleSelectionMenu}
            ></SelectionMenu>
          </Grid>
        </Grid>

        <Grid sx={{ mt: 0 }}>
          <DataGrid
            rows={row}
            columns={columns}
            hideFooter
            ColumnFilteredIcon
            autoHeight
            components={{ Toolbar: GridToolbar }}
            disableColumnFilter
            // className={classes.root}
            style={{
              borderRadius: '16px',
              fontSize: '17px',
              color: '#263159',
              margin: 0,
              padding: 0,
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.roleName,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(SalesReport);
