import React, { useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import styles from '../../StudentRegistration/CSS/registration.module.css';

import {
  Checkbox,
  FormControlLabel,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import * as Yup from 'yup';

import { Formik, Form, ErrorMessage } from 'formik';
import RegisterContext from '../../../../context/register-context';
import MyCountryList from '../../../Common/Form/MyCountryList';

const Step1Personal = ({ goNext }) => {
  const registerContext = useContext(RegisterContext);
  const [firstName, setFirstname] = useState(registerContext.firstName);
  const [lastName, setLastname] = useState(registerContext.lastName);
  const [countryOfResidence, setCountryOfResidence] = useState(
    registerContext.countryOfResidence
  );

  const handleFirstNameChange = (e) => {
    setFirstname(e.target.value);
    registerContext.setRegisterContext({
      ...registerContext,
      ...{
        firstName: e.target.value,
      },
    });
  };
  const handleLastNameChange = (e) => {
    setLastname(e.target.value);
    registerContext.setRegisterContext({
      ...registerContext,
      ...{
        lastName: e.target.value,
      },
    });
  };
  const handleCountryChange = (val) => {
    setCountryOfResidence(val);
    registerContext.setRegisterContext({
      ...registerContext,
      ...{
        countryOfResidence: val,
      },
    });
  };
  return (
    <div className="flex items-top p-4 justify-center min-h-screen min-w-screen">
      <div className="flex justify-center items-top lg:w-1/3 xl:w-1/3">
       
        <Grid>
          <Grid item xs={12}>
            <Typography className={styles.question}>
              What is your first name?
            </Typography>
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              size="small"
              value={firstName}
              onChange={handleFirstNameChange}
            />
          </Grid>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className={styles.question}>
                What is your last name?
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                fullWidth
                size="small"
                value={lastName}
                onChange={handleLastNameChange}
              />
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography className={styles.question}>
                What is your country of residence
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <MyCountryList
                name="countryOfResidence"
                value={countryOfResidence}
                placeholder="Start typing...."
                handleChange={handleCountryChange}
              />
            </Grid>
          </Grid>
          <br/>
          <Grid container>
            <Button
              disabled={!firstName || !lastName || !countryOfResidence}
              variant="contained"
              className="bg-sky-400 hover:bg-sky-500"
              onClick={goNext}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Step1Personal;
