import React from 'react';
import { Grid, Typography } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import * as actions from '../../../store/actions';
import { AnimateZoomIn } from '../../Common/Animation/Animation';
import AvatarMui from '../../Common/Image/AvatarMui';
import { connect } from 'react-redux';
import useWindowSize from '../../Students/common/useWindowSize';
import { AGENTSTATUS } from '../../../Util/Constants';

const ManagerProfile = ({
  appUserId,
  referralUserInfo,
  imgUrl,
}) => {
  const size = useWindowSize();

  return (
    <>
      {size.width > 844 ? (
        <div className="flex justify-between min-w-[60%] md:min-w-[70%] xl:min-w-[60%] bg-sky-400 rounded-lg px-6 lg:mr-0 py-5">
          <div className="">
            {appUserId > 0 && (
              <AvatarMui
                imageUrl={imgUrl}
                fullName={referralUserInfo.referralName}
                size={12}
                agent="yes"
              />
            )}
          </div>
          <div className="text-2xl font-medium ">
            <div className="text-3xl">Your Account Overview</div>
            <AnimateZoomIn>
              <div>{referralUserInfo.referralName}</div>
            </AnimateZoomIn>
            {appUserId > 0 && (
              <>
                <AnimateZoomIn>
                  <div className="flex items-center gap-1">
                    <div>
                      <EmailIcon />
                    </div>
                    <div>
                      <AnimateZoomIn>
                        <Typography>{referralUserInfo.email}</Typography>
                      </AnimateZoomIn>
                    </div>
                  </div>
                </AnimateZoomIn>
                <AnimateZoomIn>
                  <div className="flex items-center gap-1">
                    <div>
                      <PhoneIcon />
                    </div>
                    <Grid>
                      <Typography>{referralUserInfo.phoneNumber}</Typography>
                    </Grid>
                  </div>
                </AnimateZoomIn>
              </>
            )}
          </div>
          <div className="text-xl flex flex-col font-medium text-gray-900 mt-12">
          {console.log('referralUserInfo.status',referralUserInfo.status)}
         
              <div className="inline-flex h-20 justify-end w-full lg:pt-10">
                <div>
                  {referralUserInfo.status? 'Status: ' +
                   AGENTSTATUS.find(({id}) => id == referralUserInfo.status).type :'' }
                </div>
                {/* <Button
                  variant="contained"
                  className="bg-slate-600 hover:bg-slate-700 text-white"
                  size="large"
                  startIcon={<EmailIcon />}
                  onClick={() => setOpenAddMessage(true)}
                >
                  Send Message
                </Button> */}
              </div>
          
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-between w-[450px] bg-sky-400 rounded-lg px-10 mx-2 lg:px-5 py-5">
          <div className="">
            {appUserId > 0 && (
              <AvatarMui
                imageUrl={imgUrl}
                fullName={referralUserInfo.name}
                size={12}
                agent="yes"
              />
            )}
          </div>
          <div className="text-xl font-medium ">
            <div className="text-2xl">Your Account Overview</div>
            <AnimateZoomIn>
              <div>{referralUserInfo.referralName}</div>
            </AnimateZoomIn>
            {appUserId > 0 && (
              <>
                <AnimateZoomIn>
                  <div className="flex items-center gap-1">
                    <div>
                      <EmailIcon />
                    </div>
                    <div>
                      <AnimateZoomIn>
                        <Typography>{referralUserInfo.email}</Typography>
                      </AnimateZoomIn>
                    </div>
                  </div>
                </AnimateZoomIn>
                <AnimateZoomIn>
                  <div className="flex items-center gap-1">
                    <div>
                      <PhoneIcon />
                    </div>
                    <Grid>
                      <Typography>{referralUserInfo.phoneNumber}</Typography>
                    </Grid>
                  </div>
                </AnimateZoomIn>
              </>
            )}
            <div className="text-xl flex flex-col font-medium text-gray-900 mt-4">
              {appUserId > 0 && (
                <div className="inline-flex h-10 justify-start w-full">
                  <div>
                    {referralUserInfo.status? 'Status: ' +
                      AGENTSTATUS.find(
                        ({id} ) => id === referralUserInfo.status).type : ''}
                  </div>

                  {/* <Button
                    variant="contained"
                    className="bg-slate-600 hover:bg-slate-500 text-white"
                    size="large"
                    startIcon={<EmailIcon />}
                    onClick={() => setOpenAddMessage(true)}
                  >
                    Send Message
                  </Button> */}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state, ownProps) => {
  return {
    appUserId: state.auth.id,
    referralName: state.auth.name,
    phone: state.auth.phone,
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    email: state.auth.email,
    imgUrl: state.auth.image,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ManagerProfile);
