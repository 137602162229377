import * as actionTypes from './actionTypes';
import Agent from '../../api/agent';

export const updateAdminSuccess = (
  id,
  parentUserId,
  parentName,
  childUserId,
  childName
) => {
  return {
    type: actionTypes.AGENTS_ADMIN_UPDATE,
    payload: {
      id,
      parentUserId,
      parentName,
      childUserId,
      childName,
    },
  };
};

export const updateAdminSuccessUser = (
  parentUserId,
  parentName,
  childUserId,
  childName
) => {
  return {
    type: actionTypes.USER_AGENTS_ADMIN_UPDATE,
    payload: {
      parentUserId,
      parentName,
      childUserId,
      childName,
    },
  };
};
export const updateAgentStatus = (id, status) => {
  return {
    type: actionTypes.AGENTS_STATUS_UPDATE,
    payload: {
      id,
      status,
    },
  };
};

export const loadAgentsStart = () => {
  return {
    type: actionTypes.AGENTS_LOADING_START,
  };
};

export const loadAgentsSuccess = (agents) => {
  return {
    type: actionTypes.AGENTS_LOADING_SUCCESS,
    agents,
  };
};

export const loadAgentsFail = (error) => {
  return {
    type: actionTypes.AGENTS_LOADING_FAIL,
    error: error,
  };
};

export const loadAgents = () => async (dispatch) => {
  const agents = await Agent.Agents.list();
  dispatch(loadAgentsSuccess(agents));
  return agents;
  // return async (dispatch) => {
  //   //dispatch(loadAgentsStart());
  //   await Agent.Agents.listAll()
  //     .then((response) => {
  //       dispatch(loadAgentsSuccess(response));
  //       return Promise.resolve(response);
  //     })
  //     .catch((error) => {
  //       debugger;
  //       dispatch(loadAgentsFail(error)); // for some reason, we can't just pass error. it fails
  //       return Promise.reject(error);
  //     });
  // };
};

export const assignAdminToAgent = (
  id,
  parentUserId,
  parentName,
  childUserId,
  childName
) => {
  return async (dispatch) => {
    await Agent.UserAssignment.assign(parentUserId, childUserId)
      .then(() => {
        dispatch(
          updateAdminSuccess(
            id,
            parentUserId,
            parentName,
            childUserId,
            childName
          )
        );
        dispatch(
          updateAdminSuccessUser(
            parentUserId,
            parentName,
            childUserId,
            childName
          )
        );
        return Promise.resolve();
      })
      .catch((error) => {
        debugger;
        //dispatch(loadUsersFail(error)); // for some reason, we can't just pass error. it fails
        return Promise.reject(error);
      });
  };
};
