import React from 'react';
import StudentContext from './student-context';
import { initialStudentState } from '../Constant/Constants';

const StudentProvider = (props) => {
  return (
    <StudentContext.Provider value={initialStudentState}>
      {props.children}
    </StudentContext.Provider>
  );
};

export default StudentProvider;
