import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Divider,
} from '@mui/material';
import Agent from '../../../../api/agent';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import LinearIndeterminate from '../../../Common/Progress/LinearIndeterminate';
import { Typography } from '@mui/material';
import { IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useInput from '../../../Common/Form/use-input';
import MyInputField from '../../../Common/Form/MyInputField';
import { connect } from 'react-redux';
import { isPasswordValid } from '../../../../Util/Util';
const isNotEmptyTrim = (value) =>
  value && value.length > 2 && value.length < 50;
const isEmail = (value) => value && value.length > 7 && value.includes('@');
const PREFIX = 'Add';
const classes = {
  closeButton: `${PREFIX}-closeButton`,
};
const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const AddEditStaff = ({ staff, setRecordAddMode, getStaff }) => {
  const [open, setOpen] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState();
  const [isInValid, setIsInValid] = useState(true);
  const [info, setInfo] = useState(staff);
  const [action, setAction] = useState({
    new: staff.action == 'new',
    save: staff.action == 'basic',
    email: staff.action == 'email',
    password: staff.action == 'password',
  });
  const onChangeHandler = (name, value) => {
    setInfo({
      ...info,
      ...{
        [name]: value.trim(),
      },
    });
  };
  useEffect(() => {
    if (action.new) {
      setIsInValid(
        submitting ||
          !firstName.isValid ||
          !lastName.isValid ||
          !email.isValid ||
          !password.isValid
      );
    } else if (action.save) {
      setIsInValid(submitting || !firstName.isValid || !lastName.isValid);
    } else if (action.email) {
      setIsInValid(submitting || !email.isValid);
    } else if (action.password) {
      setIsInValid(submitting || !password.isValid);
    }
  }, [info]);
  const { obj: firstName } = useInput(
    staff.firstName || '',
    isNotEmptyTrim,
    null,
    onChangeHandler
  );
  const { obj: lastName } = useInput(
    staff.lastName || '',
    isNotEmptyTrim,
    null,
    onChangeHandler
  );
  const { obj: position } = useInput(
    staff.position || '',
    () => {},
    null,
    onChangeHandler
  );
  const { obj: password } = useInput(
    '',
    isPasswordValid,
    null,
    onChangeHandler
  );
  const { obj: email } = useInput(staff.email, isEmail, null, onChangeHandler);
  function handleClose() {
    setOpen(false);
    setRecordAddMode(false);
  }

  const submit = async () => {
    setSubmitting(true);
    await Agent.Agents.saveStaff(info)
      .then(() => {
        getStaff(
          info.principalAccount ? info.firstName : '',
          info.principalAccount ? info.lastName : '',
          ''
        );
        setRecordAddMode(false);
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  const changeEmail = async () => {
    setSubmitting(true);
    await Agent.Agents.updateLoginEmail(info)
      .then(() => {
        getStaff('', '', info.principalAccount ? info.email : '');
        setRecordAddMode(false);
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };
  const changePassword = async () => {
    setSubmitting(true);
    await Agent.Agents.updateLoginPassword(info)
      .then(() => {
        getStaff();
        setRecordAddMode(false);
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };
  return (
    <StyledDialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      aria-labelledby="draggable-dialog-title"
      PaperComponent={PaperComponent}
      fullWidth
      maxWidth="sm"
    >
      <>
        <DialogTitle
          style={{ cursor: 'move', minWidth: 600 }}
          id="draggable-dialog-title"
        >
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h5" color="primary" align="center">
                {action.new
                  ? 'Add New User'
                  : `Edit User - ${info.firstName} ${info.lastName}`}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleClose}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
        </DialogTitle>
        <DialogContent style={{ overflowY: 'hidden' }}>
          <Grid container direction="column" style={{ marginTop: 5 }}>
            {submitting && (
              <Grid item>
                <LinearIndeterminate />
              </Grid>
            )}
            {(action.new || action.save) && (
              <>
                {' '}
                <Grid item>
                  <MyInputField
                    obj={firstName}
                    label="First Name"
                    name="firstName"
                    handleChange={firstName.valueChangeHandler}
                    handleBlur={firstName.valueInputBlurHandler}
                    errorMessage="First name is invalid"
                    isInvalid={false}
                    required
                  />
                </Grid>
                <Grid item>
                  <MyInputField
                    obj={lastName}
                    label="Last Name"
                    name="lastName"
                    handleChange={lastName.valueChangeHandler}
                    handleBlur={lastName.valueInputBlurHandler}
                    errorMessage="Last name is invalid"
                    isInvalid={false}
                    required
                  />
                </Grid>
                {!info.principalAccount && (
                  <Grid item>
                    <MyInputField
                      obj={position}
                      label="Position"
                      name="position"
                      handleChange={position.valueChangeHandler}
                      handleBlur={position.valueInputBlurHandler}
                      errorMessage="Postion is invalid"
                      isInvalid={false}
                    />
                  </Grid>
                )}
              </>
            )}
            {(action.email || action.new) && (
              <Grid item>
                <MyInputField
                  obj={email}
                  label="Login Email Address"
                  name="email"
                  handleChange={email.valueChangeHandler}
                  handleBlur={email.valueInputBlurHandler}
                  errorMessage="Login email address is invalid"
                  isInvalid={false}
                  required
                />
              </Grid>
            )}
            {(action.password || action.new) && (
              <Grid item>
                <MyInputField
                  obj={password}
                  label="Enter new password"
                  name="password"
                  handleChange={password.valueChangeHandler}
                  handleBlur={password.valueInputBlurHandler}
                  errorMessage="Password is invalid"
                  isInvalid={false}
                  required
                />
                <Typography color={'secondary'} variant="caption">
                  Password must contain at least 8 characters (with 1 digit and
                  1 uppercase)
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button onClick={handleClose}>Cancel</Button>
            </Grid>
            <Grid item>
              <LoadingButton
                loading={submitting}
                disabled={isInValid}
                color="secondary"
                onClick={
                  action.new || action.save
                    ? submit
                    : action.email
                    ? changeEmail
                    : changePassword
                }
              >
                {action.new || action.save
                  ? action.new
                    ? 'Add New User'
                    : 'Save Changes'
                  : action.email
                  ? 'Update Email'
                  : 'Update Password'}
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </>
    </StyledDialog>
  );
};
const mapStateToProps = (state) => {
  return {
    sender: state.auth.name,
  };
};
export default connect(mapStateToProps, null)(AddEditStaff);
