import {USER_PERMISSIONS_OBJECT_INITIAL} from '../../Util/Constants'

const management = {
  partners: [],
  courseLevels: [],
  languageTests: [],
}

const summary = {
  agentCount: null,
  studentCount: null,
  applicationCount: null,
  adminCount: null,
}

const currentUser = {
  id: null,
  name: null,
  phoneNumber: null,
  image: null,
  token: null,
  roleName: null,
  error: null,
  loading: false,
  isLoggedIn: false,
  status: 1,
  role: {
    isSuperAdmin: false,
    isSeniorAdmin: false,
    isAdmin: false,
    isAgentStaff: false,
    isAgent: false,
    isStudent: false,
    isApplicationTeam: false,
    isApplicationTeamLeader: false,
  },
  permissions: USER_PERMISSIONS_OBJECT_INITIAL,
  isInternal: false,
}

export default {
  management: management,
  summary: summary,
  currentUser: currentUser,
  users: [],
  agents: [],
  students: [],
  departments: [],
  courses: [],
  courseallocations: [],
  common: {
    summary: {
      appUsers: [],
      studentCount: 0,
      applicationCount: 0,
      unreadMessageCount: 0,
      notificationCount: 0,
    },
    serverError: {
      statusCode: null,
      message: '',
      details: '',
    },
  },
}
