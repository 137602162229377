/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Button, TextField } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Divider, Grid, Typography } from '@mui/material';
import MailOutline from '@mui/icons-material/MailOutline';
import { LoadingButton } from '@mui/lab';
import EditorDraftJs from '../Common/Editor/EditorDraftJs';
import Agent from '../../api/agent';
import { Send as SendIcon, Cancel as CancelIcon } from '@mui/icons-material';
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const AddMessage = ({ open, cancel, agentId, toId, toName,agentSub }) => {
  const [loading, setLoading] = useState(false);
  const [subject, setSubject] = useState(agentSub||'');
  const [message, setMessage] = useState({ agentId: agentId, toId: toId,subject: agentSub || '' });
  const [editorStateValid, setEditorStateValid] = useState(false);

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    setMessage({ ...message, subject: e.target.value });
  };
  const editorOnChange = (rawHtml, valid) => {
    setMessage({ ...message, content: rawHtml });
    setEditorStateValid(valid);
  };
  const handleClose = () => {
    cancel(false);
  };
  const handleSend = () => {
    setLoading(true);
    Agent.Messages.add(message)
      .then(() => {
        setLoading(false);
        cancel(false);
      })
      .catch((error) => {
        console.log(error);
        debugger;
        setLoading(false);
      });
  };
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      PaperComponent={PaperComponent}
    >
      
    <DialogTitle className="bg-blue-500 text-white p-4 flex justify-center">
      <MailOutline className="mr-2 text-2xl" /> 
      <h2 className="text-xl font-bold">Send New Mail</h2> 
    </DialogTitle>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <Typography variant="h5" align="center" color="primary">
          {`Write Message to ${toName}`}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              fullWidth
              size="small"
              value={subject}
              onChange={handleSubjectChange}
              label="Message Subject/Title"
              required
            />
          </Grid>
          <Grid item>
            <EditorDraftJs editorOnChange={editorOnChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent="space-between" className='pb-3 px-3 pt-8'>
          <Button
            onClick={handleClose}
            startIcon={<CancelIcon />}
            variant="outlined"
            color="secondary"
          >
            Cancel
          </Button>
          <Grid item>
            <Button
              loading={loading}
              className="bg-sky-400 hover:bg-sky-500"
              onClick={handleSend}
              variant="contained"
              disabled={subject.length == 0 || !editorStateValid}
            >
              Send &nbsp;<SendIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AddMessage;
