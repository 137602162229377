/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { Card, Typography } from '@mui/material';
import {
  MdOutlineDocumentScanner,
  MdFlightTakeoff,
  MdOutlineSchool,
} from 'react-icons/md';
import { AiOutlineFileDone } from 'react-icons/ai';

const CardSection = ({
  roleName,
  role,
  studentLoading,
  studentCount,
  applicationLoading,
  applicationCount,
  agentLoading,
  agentCount,
  adminLoading,
  adminCount,
  conditionalOfferCount,
  unconditionalOfferCount,
  navigate,
}) => {
  const cardBaseClasses =
    'bg-opacity-90 p-4 rounded-lg glass shadow-xl transform transition duration-500 hover:scale-105';
  const iconClasses = 'text-white';
  const textClasses = 'font-semibold text-white text-lg';
  const countClasses =
    'text-lg text-white font-bold cursor-pointer hover:text-gray-300';

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 p-4">
      <div className="w-full">
        <div
          className={`bg-gradient-to-r from-sky-500 to-sky-400 ${cardBaseClasses}`}
        >
          <div className="flex items-center gap-4">
            <MdOutlineSchool size="2.5em" className={iconClasses} />
            <div>
              <p className={textClasses}>
                {role.isApplicationTeam || role.isApplicationTeamLeader
                  ? 'Applied'
                  : 'Students'}
              </p>
              {!studentLoading ? (
                <div
                  onClick={() =>
                    navigate(
                      role.isApplicationTeam || role.isApplicationTeamLeader
                        ? '/applications/?sts=1'
                        : '/students'
                    )
                  }
                >
                  <div className={countClasses}>{studentCount}</div>
                </div>
              ) : (
                <Typography color="secondary" variant="caption">
                  Loading..
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full">
        <Card
          className={`bg-gradient-to-r from-blue-400 to-blue-600 ${cardBaseClasses}`}
        >
          <div className="flex items-center gap-4">
            <MdOutlineDocumentScanner size="2.5em" className={iconClasses} />
            <div>
              <p className={textClasses}>
                {role.isApplicationTeam || role.isApplicationTeamLeader
                  ? 'Missing Doc/Info'
                  : 'Applications'}
              </p>
              {!applicationLoading ? (
                <div
                  onClick={() =>
                    navigate(
                      role.isApplicationTeam || role.isApplicationTeamLeader
                        ? '/applications/?sts=7'
                        : '/applications'
                    )
                  }
                >
                  <div className={countClasses}>{applicationCount?applicationCount:'loading...'}</div>

                </div>
              ) : (
                <Typography color="secondary" variant="caption">
                  Loading..
                </Typography>
              )}
            </div>
          </div>
        </Card>
      </div>

      {roleName !== 'Agent' && (
        <div className="w-full">
          <Card
            className={`bg-gradient-to-r glass from-sky-300 to-sky-600 ${cardBaseClasses}`}
          >
            <div className="flex items-center gap-4">
              <AiOutlineFileDone size="2.5em" className={iconClasses} />
              <div>
                <p className={textClasses}>
                  {role.isApplicationTeam || role.isApplicationTeamLeader
                    ? 'Pending'
                    : 'Conditional Offer'}
                </p>
                {!agentLoading ? (
                  <div
                    onClick={() =>
                      navigate(
                        role.isApplicationTeam || role.isApplicationTeamLeader
                          ? '/applications/?sts=8'
                          : '/applications'
                      )
                    }
                  >
                    <div className={countClasses}>{agentCount}</div>
                  </div>
                ) : (
                  <Typography className="text-gray-200" variant="caption">
                    <div className={countClasses}>
                      {conditionalOfferCount || 'loading...'}
                    </div>
                  </Typography>
                )}
              </div>
            </div>
          </Card>
        </div>
      )}

      {roleName !== 'Agent' && (
        <div className="w-full">
          <Card
            className={`bg-gradient-to-r  from-sky-400 to-sky-300 ${cardBaseClasses}`}
          >
            <div className="flex items-center gap-4">
              <MdFlightTakeoff size="2em" className={iconClasses} />
              <div>
                <p className={textClasses}>
                  {role.isApplicationTeam || role.isApplicationTeamLeader
                    ? 'Cancelled Today'
                    : 'Unconditional Offer'}
                </p>
                {!adminLoading ? (
                  <div
                    onClick={() =>
                      navigate(
                        role.isApplicationTeam || role.isApplicationTeamLeader
                          ? '/applications/?sts=5'
                          : '/applications'
                      )
                    }
                  >
                    <div className={countClasses}>{adminCount}</div>
                  </div>
                ) : (
                  <Typography className="text-gray-200" variant="caption">
                    <div className={countClasses}>
                      {unconditionalOfferCount || 'loading...'}
                    </div>
                  </Typography>
                )}
              </div>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default CardSection;