import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import LinearIndeterminate from '../Common/Progress/LinearIndeterminate';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
// import NumberFormat from 'react-number-format';
import { LoadingButton } from '@mui/lab';
import { connect } from 'react-redux';
//import * as actions from '../../store/actions/index';

const PREFIX = 'CompanyDetails';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
  chip: `${PREFIX}-chip`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  [`& .${classes.chip}`]: {
    marginTop: 10,
    marginRight: 5,
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const CompanyDetails = () => {
  const [open, setOpen] = useState(true);
  const [addMode, setAddMode] = useState(false);
  const [updating, setUpdating] = useState(false);

  function handleClose() {
    setOpen(false);
    setAddMode(false);
  }

  const handleFormCancel = () => {
    setOpen(false);
    setAddMode(false);
  };

  return (
    <Root>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperComponent}
        maxWidth="md"
        fullWidth
      >
        <>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            title
            <Divider />
          </DialogTitle>
          <DialogContent>
            {updating && (
              <Grid item style={{ marginBottom: 20 }}>
                <LinearIndeterminate />
              </Grid>
            )}

            <DialogActions></DialogActions>
          </DialogContent>
        </>
      </Dialog>
    </Root>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
