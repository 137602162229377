import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import Agent from '../../../../api/agent';
import sampleCsvFile from '../../../../assets/SampleBulkFile/DataSetFile.csv';

const BulkDatasetUpload = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const clearMessages = () => {
      setError('');
      setSuccess('');
    };

    const timer = setTimeout(clearMessages, 6000);

    return () => clearTimeout(timer);
  }, [error, success]);

  const handleOnChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setFile(selectedFile);
      setError('');
      setSuccess('');
    }
  };

  const handleOnSubmit = () => {
    setError('');
    setSuccess('');

    if (!file) {
      setError('Please select a file before uploading.');
      return;
    }

    if (file.type !== 'application/vnd.ms-excel' && file.type !== 'text/csv') {
      setError('File must be of type CSV.');
      return;
    }

    Agent.BulkUpload.datasetBulkUpload(file)
      .then((response) => {
        console.log(response);
        setSuccess(response);
      })
      .catch((error) => {
        console.error('Error uploading CSV file:', error);
        setError('Error uploading CSV file. Please try again.' + error);
      });
  };

  const handleDownloadSample = () => {
    window.open(sampleCsvFile, '_blank');
  };

  return (
    <div className="container mx-auto p-4 mt-2 text-center">
      <h4 className="text-2xl font-bold mb-4 text-gray-800">Dataset Upload</h4>
      <p className="text-sky-500 text-lg font-semibold mb-6">
        * Reminder: Please upload the CSV file in the given sample format.
      </p>
      <form className="max-w-md mx-auto mt-8">
        <label className="block mb-4">
          <span className="text-gray-700">Choose a CSV file:</span>
          <input
            type="file"
            id="csvFileInput"
            accept=".csv"
            onChange={handleOnChange}
            className="mt-1 p-2 block w-full border rounded-md bg-gray-100 focus:outline-none focus:border-sky-500"
          />
        </label>

        <div className="flex justify-between items-center mb-12">
          <Button
            className="bg-sky-700 text-white py-2 px-4 rounded-md w-48"
            onClick={handleOnSubmit}
            variant="contained"
            disabled={!file}
          >
            Upload Now
          </Button>

          <Button
            className="bg-gray-500 text-white py-2 px-4 rounded-md w-48"
            onClick={handleDownloadSample}
            variant="contained"
          >
            Download Format
          </Button>
        </div>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded-md mt-4">
            <p>{error}</p>
          </div>
        )}

        {success && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded-md mt-4">
            <h4>{success}</h4>
          </div>
        )}
      </form>
    </div>
  );
};

export default BulkDatasetUpload;
