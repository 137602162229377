import React, { useState, useEffect } from 'react';
import * as actions from '../../../store/actions/index';
import {
  LicenseInfo,
  DataGridPro,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  Snackbar,
  TextField,
} from '@mui/material';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import LoadingView from '../../Common/Loading/LoadingView';
import { MUI_LICENSE_KEY } from '../../../Util/Constants';
import { useNavigate } from 'react-router';
import Agent from '../../../api/agent';
import { Link } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { addHours, addMonths, startOfDay } from 'date-fns';

function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {show && <GridToolbarColumnsButton />}
      {hide && <GridToolbarFilterButton />}
      <GridToolbarDensitySelector />
      {hide && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

const FromEmailList = ({ users, loadUsers, isInternal }) => {
  const [activityLoaded, setActivityLoaded] = useState(false);
  const [startDate, setStartDate] = useState(addMonths(new Date(), -1));
  const [endDate, setEndDate] = useState(new Date());
  const [emailName, setEmailName] = useState('');
  const [templateRowList, setTemplateRowList] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [disableEmail, setDisableEmail] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [pageSize, setPageSize] = useState(100);

  const navigate = useNavigate();

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleSnackbarOpen = (severity, message) => {
    setSnackbarSeverity(severity);
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', page.toString());
    params.append('pageSize', pageSize.toString());
    params.append('EmailName', emailName.toString());
    if (filter) {
      if (startDate) {
        params.append('StartDate', new Date(startDate).toDateString());
      }
    } else {
      params.append('StartDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (endDate) {
        params.append('EndDate', new Date(endDate).toDateString());
      }
    } else {
      params.append('EndDate', new Date().toDateString());
    }
    return params;
  };

  const handleCreateTemplate = () => {
    navigate('/createfromemail');
  };

  const handlePageChange = (params) => {
    setPage(params + 1);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1); // Reset page when changing page size
  };

  const getRows = (rows) => {
    let emailRows = rows.map((emailList) => ({
      id: emailList.id,
      EmailName: emailList.name,
      isInactive: emailList.isInactive,
      createdBy: emailList.createdBy,
      createdOn: emailList.createdOn,
      updatedBy: emailList.updatedBy,
      updatedOn: emailList.updatedOn,
    }));
    setTemplateRowList(emailRows);
    setActivityLoaded(true);
    setLoading(false);
  };

  const handleDisableTemplateConfirm = () => {
    Agent.Communication.disableFromMail(disableEmail)
      .then((response) => {
        handleSnackbarOpen('success', 'Email disabled successfully.');

        setTemplateRowList((prevtemplateRowList) =>
          prevtemplateRowList.filter((template) => template.id !== disableEmail)
        );

        console.log(response);
      })
      .catch((error) => {
        handleSnackbarOpen(
          'error',
          'Failed to disable email. Please try again.'
        );
        console.error(error);
      });
    setModalOpen(false);
  };

  useEffect(() => {
    if (isInternal) {
      if (users.length === 0) {
        loadUsers()
          .then(() => {
            getData(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getData(true);
      }
    } else {
      getData(true);
    }
  }, [users, page, pageSize]); // Update data when users, page, or page size changes

  const getData = (filter = false) => {
    setLoading(true);
    Agent.Communication.getManageFromEmailList(getParams(filter))
      .then((res) => {
       // console.log(res);
        getRows(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetFilter = () => {
    setStartDate(addMonths(new Date(), -1));
    setEndDate(new Date());
    setEmailName('');
    setPage(1); // Reset page to 1 when resetting filters
    getData(false);
  };

  let columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      hide: true,
    },
    {
      field: 'EmailName',
      headerName: 'Email Address',
      width: 250,
      align: 'left',
      headerAlign: 'center',
      renderCell: (params) => (
        <Link to={`/email/${params.row.id}`}>{params.value}</Link>
      ),
    },
    {
      field: 'isInactive',
      headerName: 'Is InActive',
      width: 150,
      hide:true,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'createdBy',
      headerName: 'Created By',
      width: 200,
      hide: false,
      valueGetter: (params) => {
        const getUserId = params.row.createdBy;
        const userName = users.find((user) => user.id == getUserId);
        return userName ? userName.name : null;
      },
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      width: 200,
      hide: false,
      valueGetter: (params) => {
        const getUserId = params.row.updatedBy;
        const userName = users.find((user) => user.id == getUserId);
        return userName ? userName.name : null;
      },
    },
    {
      field: 'updatedOn',
      headerName: 'Updated On',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'disableEmail',
      headerName: 'Disable Email',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Button
          variant="contained"
          className="bg-red-700"
          onClick={() => {
            setDisableEmail(params.row.id);
            setModalOpen(true);
          }}
        >
          Disable
        </Button>
      ),
    },
  ];

  return activityLoaded ? (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item container spacing={1}>
          <Grid item xs={2}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              // onChange={(date) => setStartDate(date || new Date())}
              selected={(startDate && new Date(startDate)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setStartDate(newVal);
              }}
              customInput={
                <TextField
                  label="Start Date"
                  fullWidth
                  value={startDate}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>



          <Grid item xs={2}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              // selected={endDate}
              selected={(endDate && new Date(endDate)) || null}
              customInput={
                <TextField
                  label="End Date"
                  fullWidth
                  value={endDate}
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                label="Email Name"
                variant="outlined"
                size="small"
                value={emailName}
                onChange={(e) => setEmailName(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container spacing={3}>
          <Grid item xs={2}>
            <Button
              fullWidth
              onClick={() => getData(true)}
              variant="contained"
              className="bg-sky-400 hover:bg-sky-500"
            >
              Apply Filters
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button fullWidth onClick={resetFilter} variant="outlined">
              Reset Filters
            </Button>
          </Grid>
        </Grid>
        <Grid item container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              fullWidth
              onClick={handleCreateTemplate}
              variant="contained"
              className="bg-sky-700"
            >
              Create Email
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ height: 900, width: '100%' }}>
          <DataGridPro
            paginationMode="client"
            pagination
            pageSize={pageSize}
            rowCount={templateRowList.length}
            page={page - 1}
            pageSizeOptions={[25, 50, 100]}
            onPageChange={handlePageChange}
            rows={templateRowList}
            columns={columns}
            loading={loading}
            density="compact"
            rowHeight={70}
            components={{
              Toolbar: () => CustomToolbar(isInternal),
            }}
            onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
          />
        </Grid>
      </Grid>
      <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
        <DialogTitle>Disable Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to disable this email?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button onClick={handleDisableTemplateConfirm} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        <Alert
          elevation={16}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }} // Set the width to 100% to center the content
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  ) : (
    <LoadingView />
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FromEmailList);
