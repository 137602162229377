import {
  DialogTitle,
  Typography,
  IconButton,
  Divider,
  Paper,
  DialogContent,
  Grid,
  Dialog,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect, useContext } from 'react';
import Draggable from 'react-draggable';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import UserContext from '../../context/user-context';
import Agent from '../../api/agent';

const PREFIX = 'StudentSearch';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const StudentSearch = ({
  courseAllocationId = { courseAllocationId },
  setStudentMode = { setStudentMode },
  students,
  isInternal,
  role,
}) => {
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(true);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  


  const loading = open && options?.length === 0;
  const [student, setStudent] = useState();
  const [applying, setApplying] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const userContext = useContext(UserContext);

  useEffect(() => {
    if (role.isStudent) {
      Agent.Students?.getStudent(0)
        .then((std) => {
          setStudent(std);
        })
        .catch((error) => {
          debugger;
          console.log(error);
        });
    }
  }, []);

  // useEffect(() => {
  //   let active = true;

  //   if (!loading) {
  //     return undefined;
  //   }
    // eslint-disable-next-line no-extra-semi
    
  // (async () => {
  //   if (active) {
  //     if (students && Array.isArray(students) && students.length > 0) {
  //       console.log('students',students);
  //       setOptions(students);
  //     } else {
  //       try {
  //         const fetchedStudents = await Agent?.Students?.list();
  //         console.log('fetchedStudents',fetchedStudents);
  //         if (active && Array.isArray(fetchedStudents)) {
  //           setOptions(fetchedStudents.data);
  //         }
  //       } catch (error) {
  //         console.error(error);
  //         // debugger;
  //       }
  //     }
  //   }
  // })();
    // (async () => {
    //   if (active) {
    //     if (students.length > 0) setOptions(students);
    //     else {
    //       Agent?.Students?.list()
    //         .then((students) => {
    //           setOptions(students);
    //         })
    //         .catch((error) => {
    //           console.log(error);
    //           // debugger;
    //         });
    //     }
    //   }
    // })();
  //   return () => {
  //     active = false;
  //   };
  // }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }else{
      Agent?.Students?.programList()
              .then((students) => {
                console.log('students',students);
                setOptions(students);
              })
              .catch((error) => {
                console.log(error);
                // debugger;
              });
    }
  }, [open]);

  const handleFormCancel = () => {
    setDialogOpen(false);
    setStudentMode(false);
  };

  const goToStudentProfile = () => {
    navigate(`/students/${student.id}/manage`);
  };

  const apply = () => {
    setApplying(true);
    const applicationId = userContext.applicationId;
    console.log('applicationId', applicationId);
    let studentId = 0;
    if (role.isStudent) {
      studentId = 0; // when student themself are logged on, backend code will work out their student id
    } else if (userContext.studentId > 0) {
      studentId = userContext.studentId;
    } else {
      if (!student) return;
      studentId = student.id;
    }
    Agent.Applications.add(studentId, courseAllocationId, applicationId)
      .then((id) => {
        setApplying(false);
        userContext.setUserContext({
          ...userContext,
          ...{
            studentId: 0,
            studentName: null,
          },
        });
        navigate(`/students/${studentId}/applications/${id}`);
      })
      .catch((error) => {
        setApplying(false);
        setErrorMessage(error);
        console.error(error);
      });
  };

  return (
    <StyledDialog
      open={dialogOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleFormCancel;
        }
      }}
      aria-labelledby="draggable-dialog-title"
      PaperComponent={PaperComponent}
      maxWidth="sm"
      fullWidth
    >
      <>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Grid container>
            <Grid item xs={11}>
              <Typography variant="h6" color="primary" align="center">
                {role.isStudent || userContext?.studentId > 0
                  ? 'Confirm Application Submission'
                  : 'Select Student & Apply'}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleFormCancel}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={3}>
            {role.isStudent && student && (
              <Grid item>
                <Typography
                  color={!student.profileComplete ? 'secondary' : null}
                >
                  {student.profileComplete
                    ? 'Hit Apply to submit this application'
                    : 'You must complete your profile first before you can apply for any course'}
                </Typography>
              </Grid>
            )}
            {!role.isStudent &&
              (userContext.studentId > 0 ? (
                <Grid item>
                  <Typography>{`Please press 'Apply' to submit application for ${userContext.studentName}`}</Typography>
                </Grid>
              ) : (
                <Grid item>
                  {' '}
                  <Autocomplete
                    fullWidth
                    size="small"
                    // sx={{ width: 500 }}
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    value={student}
                    onChange={(_, newVal) => {
                      setStudent(newVal);
                    }}
                    // getOptionSelected={(option, value) =>
                    //   option.firstName === value.firstName
                    // }
                    // isOptionEqualToValue={(option, value) =>
                    //   option.title === value.title
                    // }
                    getOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName} - ${option.email}`
                    }
                    options={options}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Start typing to get suggesstions"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {loading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              ))}

            {student &&
              student.profileComplete &&
              student.noOfApplications > 2 &&
              !isInternal && (
                <Grid item>
                  <Typography color="secondary">
                    No more applications allowed for this student, sorry!
                  </Typography>
                </Grid>
              )}
            <Grid item>
              <Divider />
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid item>
                {!role.isStudent && (
                  <Button
                    onClick={() => navigate('/students/register')}
                    variant="outlined"
                  >
                    Add New Student
                  </Button>
                )}
              </Grid>
              <Grid item>
                {!student || (student && student.profileComplete) ? (
                  <LoadingButton
                    loading={applying}
                    onClick={apply}
                    className="bg-sky-400 hover:bg-sky-500"
                    variant="contained"
                    disabled={
                      (!student && userContext.studentId == 0) ||
                      (student &&
                        student.noOfApplications &&
                        student.noOfApplications > 2 &&
                        !isInternal)
                    }
                  >
                    {applying ? 'Applying..' : 'Apply'}
                  </LoadingButton>
                ) : (
                  <Button
                    onClick={goToStudentProfile}
                    variant="contained"
                    className="bg-sky-400 hover:bg-sky-500"
                    disabled={!student}
                  >
                    Complete Profile & Apply
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* {updating && (
          <Grid item style={{ marginBottom: 20 }}>
            <LinearIndeterminate />
          </Grid>
        )} */}

          <DialogContent>
            {errorMessage && (
              <Typography color="error" gutterBottom>
                {errorMessage}
              </Typography>
            )}
          </DialogContent>
        </DialogContent>
      </>
    </StyledDialog>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    // students: state.student.students,
    students: state.students,
    courseAllocationId: ownProps.courseAllocationId,
    roleName: ownProps.setStudentMode,
    studentId: ownProps.studentId,
    isInternal: state.auth.isInternal,
    role: state.auth.role,
    // ids: state.student.ids,
  };
};

export default connect(mapStateToProps, null)(StudentSearch);
