import React from 'react';
import ApplicationWrapper from '../../../../Applications/ApplicationWrapper';

const ApplicationStage = ({ records, applicationToExpand,setReload }) => {
  return (
    <div className="w-full flex flex-col items-start ">
      <div className="bg-gray-100 px-4 py-10 rounded-lg  flex flex-col gap-0 items-center justify-center w-full  mt-4">
        <div className="flex flex-col gap-2 text-center text-4xl font-semibold pb-3 pt-3 w-2/3 divide-y-2 divide-slate-400/70">
          <div> Applications</div>
          <div></div>
        </div>
        <div className="flex flex-col justify-center items-center w-full text-lg font-medium text-gray-600 pb-6">
          <div>{`You have ${records.length} application${
            records.length > 1 ? 's' : ''
          }`}</div>
          <div>Expand the application panel below to see details</div>
        </div>

        {records.map((application) => (
          <ApplicationWrapper
            key={application.id}
            application={application}
            setReload={setReload}
            applicationToExpand={applicationToExpand}
          />
        ))}
      </div>
    </div>
  );
};

export default ApplicationStage;
