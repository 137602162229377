import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Container,
  Autocomplete,
} from '@mui/material';
import {
  LEADORIGINS,
  STUDENTSOURCES,
  LEADCHANNELS,
  EVENT_CATEGORIES,
} from '../../../Util/Constants';

import { Countries } from '../../../Util/Util';
import { Done } from '@mui/icons-material';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions';
import * as yup from 'yup';
import Agent from '../../../api/agent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

// Validate form data
const validationSchema = yup.object().shape({
  eventName: yup
    .string()
    .min(3, 'Event Name must be at least 3 characters')
    .max(200, 'Event Name must be less than least 200 characters')
    .required('Event Name is required'),
  eventDate: yup.date().required('Event Date is required'),
  countryFilter: yup.number().required('Country is required'),
  eventLocation: yup.string().required('Event Location is required'),
  branchOfficeId: yup.number().required('Branch Office is required'),
  selectedlLeadOrigin: yup.string().required('Lead Origin is required'),
  selectedlLeadSource: yup.string().required('Lead Source is required'),
  selectedlLeadChannel: yup.string().required('Lead Channel is required'),
  eventBudget: yup.string().required('Event Budget is required'),
  eventCategory: yup.string().required('Event Category is required'),
  leadCampaignBudget: yup.string().required('Lead Campaign Budget is required'),
  remarks: yup.string().required('Remarks is required'),
});

const AddEventForm = ({ appUserId, role, permissions }) => {
  // State variables for form data
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState(
    new Date().toISOString().split('T')[0]
  );
  const [countryFilter, setCountryFilter] = useState();
  const [eventLocation, setEventLocation] = useState('');
  const [branchoffice, setBranchOffices] = useState([]);
  const [branchOfficeId, setBranchOfficeId] = useState();
  const [selectedlLeadOrigin, setSelectedlLeadOrigin] = useState('');
  const [selectedlLeadSource, setSelectedlLeadSource] = useState('');
  const [selectedlLeadChannel, setSelectedlLeadChannel] = useState('');
  const [eventBudget, setEventBudget] = useState('');
  const [leadCampaignBudget, setLeadCampaignBudget] = useState('');
  const [remarks, setRemarks] = useState('');
  const [eventCategory, setEventCategory] = useState('');
  const [eventCategoryId, setEventCategoryId] = useState();
  const [formUrl, setFormUrl] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);

  const handleSaveEvent = async () => {
    try {
      // Validate form data
      await validationSchema.validate(
        {
          eventName,
          eventDate,
          countryFilter,
          eventLocation,
          branchOfficeId,
          selectedlLeadOrigin,
          selectedlLeadSource,
          selectedlLeadChannel,
          eventBudget,
          eventCategory,
          leadCampaignBudget,
          remarks
        },
        { abortEarly: false }
      );
      // If validation passes, proceed to save the event
      const payload = {
        eventName: eventName,
        eventDate: eventDate,
        countryId: countryFilter,
        eventLocation: eventLocation,
        branchOfficeId: branchOfficeId,
        leadOrigin: selectedlLeadOrigin,
        leadSource: selectedlLeadSource,
        leadChannel: selectedlLeadChannel,
        eventBudget: eventBudget,
        formUrl: formUrl,
        eventCategory: eventCategory ,
        LeadCampaignBudget:leadCampaignBudget,
        Remarks:remarks,
        isInactive: 0,
      };

      const response = await Agent.Events.saveEvent(payload);
      console.log('res', response);
      setSuccessMessage('Event saved successfully', response);
      setSuccessOpen(true); // Show the success dialog
      clearForm(); // Clear the form fields
    } catch (error) {
      // Handle validation errors
      console.error(error);
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setFieldErrors(errors);
      setErrorMessage('Validation error occurred.');
    }
  };

  const clearForm = () => {
    setEventName('');
    setEventDate(new Date());
    setCountryFilter(0);
    setEventLocation('');
    setBranchOfficeId();
    setSelectedlLeadOrigin('');
    setSelectedlLeadSource('');
    setSelectedlLeadChannel('');
    setEventBudget('');
    setLeadCampaignBudget('');
    setRemarks('');
    setFormUrl('');
  };

  const handleEventName = (event) => {
    setEventName(event.target.value);
    fieldErrors.eventName = '';
  };
  const handleEventDate = (event) => {
    setEventDate(event.target.value);
  };
  const handleCountryFilterChange = (event, newValue) => {
    if (newValue) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        countryFilter: false, // Reset the error state
      }));
      setCountryFilter(newValue.id);
      fieldErrors.countryFilter = '';
    } else {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        countryFilter: true, // Set the error state to true
      }));
      setCountryFilter(0);
    }
  };
  const handleBranchOffice = (event) => {
    setBranchOfficeId(event.target.value);
    fieldErrors.branchOfficeId = '';
  };

  const handleEventLocation = (event) => {
    setEventLocation(event.target.value);
    fieldErrors.eventLocation = '';
  };
  const handleSelectedLeadOrigin = (event) => {
    setSelectedlLeadOrigin(event.target.value);
    fieldErrors.selectedlLeadOrigin = '';
  };
  const handleSelectedLeadSource = (event) => {
    setSelectedlLeadSource(event.target.value);
    fieldErrors.selectedlLeadSource = '';
  };
  const handleSelectedLeadChannel = (event) => {
    setSelectedlLeadChannel(event.target.value);
    fieldErrors.selectedlLeadChannel = '';
  };
  const handleEventBudget = (event) => {
    setEventBudget(event.target.value);
    fieldErrors.eventBudget = '';
  };
  const handleLeadCampaignBudget = (event) => {
    setLeadCampaignBudget(event.target.value);
    fieldErrors.leadCampaignBudget = '';
  };
  const handleRemarks = (event) => {
    setRemarks(event.target.value);
    fieldErrors.remarks = '';
  };
  const handleEventCategory = (event) => {
    setEventCategory(event.target.value);
    fieldErrors.eventCategory='';
  };
  // Custom styles for success dialog
  const successDialogStyles = {
    backgroundColor: 'bg-green-500', // Green color for success
  };

  // Custom styles for error dialog
  const errorDialogStyles = {
    backgroundColor: 'bg-red-400',
  };

  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setBranchOffices);
  }, []);

  return (
    <div className="bg-gradient-to-r from-sky-100 to-sky-200   flex justify-center items-center rounded-lg">
      <Container maxWidth="md" className="pt-4 pb-2">
        <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow-2xl rounded-3xl sm:p-10">
          {(role.isSuperAdmin || permissions.CanAddEvent) && (
            <h2 className="text-3xl text-center font-bold mb-6 mt-0">
              Add Event
            </h2>
          )}

          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                label="Event Name"
                variant="outlined"
                fullWidth
                required
                error={!!fieldErrors.eventName}
                helperText={fieldErrors.eventName}
                value={eventName}
                onChange={handleEventName}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Event Date"
                  variant="outlined"
                  required
                  type="date"
                  value={eventDate}
                  onChange={handleEventDate}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <Autocomplete
                  value={
                    Countries.find((country) => country.id === countryFilter) ||
                    null
                  }
                  onChange={handleCountryFilterChange}
                  options={Countries.filter((item)=>item.id!=0)}
                  getOptionLabel={(country) => country.name}
                  renderInput={(params) => (
                    <div>
                      <TextField
                        {...params}
                        label="Country"
                        variant="outlined"
                        required
                        error={fieldErrors.countryFilter} // Use the error state from fieldError
                        helperText={
                          fieldErrors.countryFilter ? 'Country is required' : ''
                        }
                      />
                    </div>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Event Location"
                variant="outlined"
                fullWidth
                required
                error={!!fieldErrors.eventLocation}
                helperText={fieldErrors.eventLocation}
                value={eventLocation}
                onChange={handleEventLocation}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  required
                  select
                  label="Branch Office"
                  value={branchOfficeId}
                  name="branchOfficeId"
                  error={!!fieldErrors.branchOfficeId}
                  helperText={fieldErrors.branchOfficeId}
                  onChange={handleBranchOffice}
                  variant="outlined"
                >
                  {branchoffice.map((charge) => (
                    <MenuItem key={charge.id} value={charge.id}>
                      {charge.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  required
                  label="Lead Origin"
                  value={selectedlLeadOrigin}
                  error={!!fieldErrors.selectedlLeadOrigin}
                  helperText={fieldErrors.selectedlLeadOrigin}
                  onChange={handleSelectedLeadOrigin}
                  variant="outlined"
                >
                  {LEADORIGINS.map((origin) => (
                    <MenuItem key={origin.id} value={origin.id}>
                      {origin.type}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  required
                  label="Lead Source"
                  value={selectedlLeadSource}
                  error={!!fieldErrors.selectedlLeadSource}
                  helperText={fieldErrors.selectedlLeadSource}
                  onChange={handleSelectedLeadSource}
                  variant="outlined"
                >
                  {STUDENTSOURCES.map((source) => (
                    <MenuItem key={origin.id} value={source.id}>
                      {source.type}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  required
                  label="Lead Channel"
                  error={!!fieldErrors.selectedlLeadChannel}
                  helperText={fieldErrors.selectedlLeadChannel}
                  value={selectedlLeadChannel}
                  onChange={handleSelectedLeadChannel}
                  variant="outlined"
                >
                  {LEADCHANNELS.map((channel) => (
                    <MenuItem key={channel.id} value={channel.id}>
                      {channel.type}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <FormControl fullWidth>
                <TextField
                  select
                  required
                  label="Event Category"
                  error={!!fieldErrors.eventCategory}
                  helperText={fieldErrors.eventCategory}
                  value={eventCategory}
                  onChange={handleEventCategory}
                  variant="outlined"
                >
                  {EVENT_CATEGORIES.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.type}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Event Budget"
                variant="outlined"
                fullWidth
                required
                error={!!fieldErrors.eventBudget}
                helperText={fieldErrors.eventBudget}
                value={eventBudget}
                onChange={handleEventBudget}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Lead Campaign Budget"
                variant="outlined"
                fullWidth
                required
                error={!!fieldErrors.leadCampaignBudget}
                helperText={fieldErrors.leadCampaignBudget}
                value={leadCampaignBudget}
                onChange={handleLeadCampaignBudget}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Remarks"
                variant="outlined"
                fullWidth
                required
                error={!!fieldErrors.remarks}
                helperText={fieldErrors.remarks}
                value={remarks}
                onChange={handleRemarks}
              />
            </Grid>
            {/* <Grid item xs={6}>
              <TextField
                label="Form Url"
                variant="outlined"
                fullWidth
                error={!!fieldErrors.formUrl}
                helperText={fieldErrors.formUrl}
                value={formUrl}
                onChange={(e) => setFormUrl(e.target.value)}
              />
            </Grid> */}
            <Grid item xs={12} className="flex justify-center">
              <Button
                variant="contained"
                onClick={handleSaveEvent}
                color="success"
                className="min-w-fit py-2 px-6 mt-6 bg-indigo-500 hover:bg-indigo-600 focus:ring-indigo-400 focus:ring-offset-indigo-200 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                endIcon={<Done />}
              >
                Save
              </Button>
            </Grid>

            {/* Show success Dialog */}
            <Dialog
              open={successOpen}
              onClose={() => setSuccessOpen(false)}
              aria-labelledby="success-dialog-title"
              PaperProps={{
                className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
              }}
            >
              <DialogTitle id="success-dialog-title">Success</DialogTitle>
              <DialogContent>
                <DialogContentText>{successMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setSuccessOpen(false)} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>

            {/* Show error Dialog */}
            <Dialog
              open={!!errorMessage}
              onClose={() => setErrorMessage('')}
              aria-labelledby="error-dialog-title"
              PaperProps={{
                className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
              }}
            >
              <DialogTitle id="error-dialog-title">Error</DialogTitle>
              <DialogContent>
                <DialogContentText>{errorMessage}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setErrorMessage('')} color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    appUserId: state.auth.id,
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEventForm);
