import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Draggable from 'react-draggable';
import {
  COMPLIANCE_STATUS,
  LEADCHANNELS,
  LEADORIGINS,
  STUDENTSOURCES,
} from '../../../Util/Constants';
import Agent from '../../../api/agent';
import LoadingMessage from '../../Common/LoadingMessage/LoadingMessage';

// Custom Paper Component for draggable dialog
function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title">
      <Paper {...props} />
    </Draggable>
  );
}

// Dialog Title with Tailwind CSS
const DialogTitleWithClose = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <div
      className="flex items-center  p-4 bg-gray-200 border-b border-gray-300"
      {...other}
    >
      <Typography variant="h6" color="secondary">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="text-gray-600"
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </div>
  );
};

DialogTitleWithClose.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

// Main Component
export const StudentLeadAssignment = ({
  users,
  action,
  role,
  cancel,
  studentlead,
  bulkUpdate,
  referralList,
  setReferralReload,
  events,
}) => {
  users.sort((a, b) => (a.name < b.name ? -1 : 1));
  const [ready, setReady] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openRegManager, setopenRegManager] = useState(false);
  const [openCountryManager, setopenCountryManager] = useState(false);
  const [openBranchManager, setopenBranchManager] = useState(false);
  const [openAssess, setOpenAssess] = useState(false);
  const [openFollow, setOpenFollow] = useState(false);
  const [openCom, setOpenCom] = useState(false);
  const [leadStatusChanged, setLeadStatusChanged] = useState(false);
  const [appUserDetailsChanged, setAppUserDetailsChanged] = useState(false);
  const [assessmentTeamChanged, setAssessmentTeamChanged] = useState(false);
  const [followupTeamChanged, setFollowupTeamChanged] = useState(false);
  const [complianceTeamChanged, setComplianceTeamChanged] = useState(false);
  const [branchOfficeChanged, setBranchOfficeChanged] = useState(false);
  const [sourceChanged, setSourceChanged] = useState(false);
  const [channelChanged, setChannelChanged] = useState(false);
  const [eventChanged, setEventChanged] = useState(false);
  const [originChanged, setOriginChanged] = useState(false);
  const [campaignChanged, setCampaignChanged] = useState(false);
  const [regionalManagerChanged, setRegionalManagerChanged] = useState(false);
  const [countryManagerChanged, setCountryManagerChanged] = useState(false);
  const [branchManagerChanged, setBranchManagerChanged] = useState(false);
  const [virtualTeamChanged, setVirtualTeamChanged] = useState(false);
  const [openReferral, setopenReferral] = useState(false);
  const [referralChanged, setReferralChanged] = useState(false);
  const [complianceStatusChanged, setComplianceStatusChanged] = useState(false);
  const [openComplianceStatus, setopenComplianceStatus] = useState(false);
  const [complianceStatus, setComplianceStatus] = useState(
    COMPLIANCE_STATUS.find(
      ({ id }) => id == (studentlead ? studentlead.complianceStatus : null)
    )
  );

  const [adminRrecord, setAdminRecord] = useState(
    users.find(
      ({ id }) => id == (studentlead ? studentlead.appUserDetailsId : 0)
    )
  );
  const [referralAssignId, setReferralAssignId] = useState(
    referralList.find(
      ({ id }) => id == (studentlead ? studentlead.referralId : 0)
    )
  );
  const [assessmentRecord, setAssessmentRecord] = useState(
    users.find(
      ({ id }) => id == (studentlead ? studentlead.assessmentTeamId : 0)
    )
  );
  const [followUpRecord, setFollowUpRecord] = useState(
    users.find(({ id }) => id == (studentlead ? studentlead.followupTeamId : 0))
  );
  const [complianceRecord, setComplianceRecord] = useState(
    users.find(
      ({ id }) => id == (studentlead ? studentlead.complianceTeamId : 0)
    )
  );
  const [regionalManagerRecord, setRegionalManagerRecord] = useState(
    users.find(
      ({ id }) => id == (studentlead ? studentlead.regionalManagerId : 0)
    )
  );
  const [countryManagerRecord, setCountryManagerRecord] = useState(
    users.find(
      ({ id }) => id == (studentlead ? studentlead.countryManagerId : 0)
    )
  );
  const [branchManagerRecord, setBranchManagerRecord] = useState(
    users.find(
      ({ id }) => id == (studentlead ? studentlead.branchManagerId : 0)
    )
  );
  const [virtualTeamRecord, setVirtualTeamRecord] = useState(
    users.find(({ id }) => id == (studentlead ? studentlead.virtualTeamId : 0))
  );
  //
  const [leadStatus, setLeadStatus] = useState(
    (studentlead && studentlead.leadStatus) || 1
  );
  const [branchOfficeId, setBranchOfficeId] = useState(
    (studentlead && studentlead.branchOfficeId) || 0
  );
  const [source, setSource] = useState(
    (studentlead && studentlead.sourceId) || 0
  );
  const [origin, setOrigin] = useState(studentlead ? studentlead.originId : 0);
  const [channel, setChannel] = useState(
    studentlead ? studentlead.channelId : 0
  );
  const [eventId, setEventId] = useState(
    studentlead ? studentlead.eventId : null
  );
  const [campaign, setCampaign] = useState(
    studentlead ? studentlead.campaign : 0
  );

  const handleClose = () => {
    setDialogOpen(false);
    cancel(false);
  };

  const [offices, setOffices] = useState([]);
  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  const confirm = () => {
    setLoading(true);
    action({
      leadStatus,
      appUserDetailsId: adminRrecord ? adminRrecord.id : null,
      admin: adminRrecord ? adminRrecord?.name : '',
      assessmentTeamId: assessmentRecord ? assessmentRecord.id : null,
      assessment: assessmentRecord ? assessmentRecord?.name : '',
      followupTeamId: followUpRecord ? followUpRecord.id : null,
      followUp: followUpRecord ? followUpRecord?.name : '',
      complianceTeamId: complianceRecord ? complianceRecord?.id : null,
      eventId: parseInt(eventId),
      ReferralId: referralAssignId?.id ? referralAssignId?.id : null,
      complianceStatus: complianceStatus ? complianceStatus.id : null,
      compliance: complianceRecord ? complianceRecord?.name : '',
      branchOfficeId: branchOfficeId || 0,
      branchOffice:
        branchOfficeId > 0
          ? offices.find(({ id }) => id == branchOfficeId)?.name
          : '',
      campaign,
      source,
      origin,
      channel,
      leadStatusChanged,
      appUserDetailsChanged,
      assessmentTeamChanged,
      followupTeamChanged,
      complianceTeamChanged,
      complianceStatusChanged,
      branchOfficeChanged,
      sourceChanged,
      channelChanged,
      referralChanged,
      originChanged,
      campaignChanged,
      eventChanged,
      regionalManagerId: regionalManagerRecord
        ? regionalManagerRecord.id
        : null,
      regionalManager: regionalManagerRecord ? regionalManagerRecord?.name : '',
      regionalManagerChanged,
      countryManagerId: countryManagerRecord ? countryManagerRecord.id : null,
      countryManager: countryManagerRecord ? countryManagerRecord?.name : '',
      countryManagerChanged,
      branchManagerId: branchManagerRecord ? branchManagerRecord.id : null,
      branchManager: branchManagerRecord ? branchManagerRecord?.name : '',
      branchManagerChanged,
      virtualTeamId: virtualTeamRecord ? virtualTeamRecord.id : null,
      virtualTeam: virtualTeamRecord ? virtualTeamRecord?.name : '',
      virtualTeamChanged,
    })?.finally(() => {
      setLoading(false);
      setDialogOpen(false);
    });
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={dialogOpen}
        maxWidth="sm"
        fullWidth
        PaperComponent={PaperComponent}
      >
        <DialogTitleWithClose onClose={handleClose} className="text-center p-1">
          {bulkUpdate ? 'Bulk Update' : studentlead?.name}
        </DialogTitleWithClose>
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ paddingTop: 4 }}
          >
            {/* <Grid item container spacing={3}>
              <Grid item xs={2}>
                <Typography>Status</Typography>
              </Grid>
              <Grid item xs={10}>
                <Select
                  fullWidth
                  size="small"
                  native
                  label="Status"
                  value={leadStatus}
                  onChange={(e) => {
                    setLeadStatus(e.target.value);
                    setLeadStatusChanged(true);
                    setReady(true);
                  }}
                >
                  {StudentLead_Status.map((item) => (
                    <option key={item.id} value={item?.id}>
                      {item?.name}
                    </option>
                  ))}
                </Select>
              </Grid>
            </Grid> */}
            {offices.length > 0 && role.isSuperAdmin && (
              <>
                <Grid item>
                  <Select
                    fullWidth
                    size="small"
                    native
                    label="Branch Office"
                    value={branchOfficeId}
                    onChange={(e) => {
                      setBranchOfficeId(e.target.value);
                      setBranchOfficeChanged(true);
                      setReady(true);
                    }}
                  >
                    <option value={0}>--Pick a branch--</option>
                    {offices.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Grid>
              </>
            )}

            {offices.length > 0 && (role.isSuperAdmin || role.isAdmin) && (
              <>
                <Grid item className="mt-1">
                  <Autocomplete
                    fullWidth
                    size="small"
                    open={openAdmin}
                    onOpen={() => {
                      setOpenAdmin(true);
                    }}
                    onClose={() => {
                      setOpenAdmin(false);
                    }}
                    defaultValue={users.find(
                      ({ id }) =>
                        id == (studentlead ? studentlead?.appUserDetailsId : 0)
                    )}
                    value={adminRrecord}
                    onChange={(_, newVal) => {
                      setAdminRecord(newVal);
                      setAppUserDetailsChanged(true);
                      setReady(true);
                    }}
                    getOptionLabel={(user) => `${user?.name}`}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Grid
                          container
                          direction="column"
                          sx={{
                            backgroundColor: (theme) => theme.palette.grey[200],
                            borderRadius: 1,
                            padding: 1,
                          }}
                          spacing={1}
                        >
                          <Grid item>
                            <Typography color="primary">
                              {option?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    options={users}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Team Leader"
                        label="Team Leader"
                        InputProps={{
                          ...params.InputProps,
                          autoComplete: 'new-password',
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
            {role.isSuperAdmin && (
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <Autocomplete
                    value={referralAssignId}
                    open={openReferral}
                    onOpen={() => {
                      setopenReferral(true);
                    }}
                    onClose={() => {
                      setopenReferral(false);
                    }}
                    onChange={(_, newVal) => {
                      setReferralAssignId(newVal);
                      setReferralChanged(true);
                      setReady(true);
                      setReferralReload(false);
                    }}
                    defaultValue={referralList.find(
                      ({ id }) =>
                        id == (studentlead ? studentlead.referralId : 0)
                    )}
                    options={referralList}
                    // className="w-56"
                    getOptionLabel={(referralList) =>
                      referralList.firstName + ' ' + referralList.lastName
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Referral"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            )}

            {!role.isApplicationTeam && (
              <Grid className="mt-1" item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <Autocomplete
                    value={complianceStatus}
                    open={openComplianceStatus}
                    onOpen={() => {
                      setopenComplianceStatus(true);
                    }}
                    onClose={() => {
                      setopenComplianceStatus(false);
                    }}
                    onChange={(_, newVal) => {
                      setComplianceStatus(newVal);
                      setComplianceStatusChanged(true);
                      setReady(true);
                    }}
                    defaultValue={COMPLIANCE_STATUS.find(
                      ({ id }) =>
                        id ==
                        (studentlead ? studentlead.complianceStatus : null)
                    )}
                    options={COMPLIANCE_STATUS}
                    // className="w-56"
                    getOptionLabel={(COMPLIANCE_STATUS) =>
                      COMPLIANCE_STATUS.type
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Compliance Status"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            )}

            {!role?.isApplicationTeam &&
              !role?.isAdmin &&
              !role?.isSeniorAdmin && (
                <Grid item>
                  <Autocomplete
                    fullWidth
                    size="small"
                    open={openRegManager}
                    onOpen={() => {
                      setopenRegManager(true);
                    }}
                    onClose={() => {
                      setopenRegManager(false);
                    }}
                    value={regionalManagerRecord}
                    onChange={(_, newVal) => {
                      setRegionalManagerRecord(newVal);
                      setRegionalManagerChanged(true);
                      setReady(true);
                    }}
                    getOptionLabel={(user) => `${user?.name}`}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Grid
                          container
                          direction="column"
                          sx={{
                            backgroundColor: (theme) => theme.palette.grey[200],
                            borderRadius: 1,
                            padding: 1,
                          }}
                          spacing={1}
                        >
                          <Grid item>
                            <Typography color="primary">
                              {option?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    options={users.filter((item) => item.userType == 2)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Regional Manager"
                        label="Regional Manager"
                        InputProps={{
                          ...params.InputProps,
                          autoComplete: 'new-password',
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

            {!role.isApplicationTeam &&
              !role.isAdmin &&
              !role.isSeniorAdmin && (
                <Grid item>
                  <Autocomplete
                    fullWidth
                    size="small"
                    open={openCountryManager}
                    onOpen={() => {
                      setopenCountryManager(true);
                    }}
                    onClose={() => {
                      setopenCountryManager(false);
                    }}
                    value={countryManagerRecord}
                    onChange={(_, newVal) => {
                      setCountryManagerRecord(newVal);
                      setCountryManagerChanged(true);
                      setReady(true);
                    }}
                    getOptionLabel={(user) => `${user?.name}`}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Grid
                          container
                          direction="column"
                          sx={{
                            backgroundColor: (theme) => theme.palette.grey[200],
                            borderRadius: 1,
                            padding: 1,
                          }}
                          spacing={1}
                        >
                          <Grid item>
                            <Typography color="primary">
                              {option.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    options={users.filter((item) => item.userType == 2)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Country Manager"
                        label="Country Manager"
                        InputProps={{
                          ...params.InputProps,
                          autoComplete: 'new-password',
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

            {!role?.isApplicationTeam &&
              !role?.isAdmin &&
              !role?.isSeniorAdmin && (
                <Grid item>
                  <Autocomplete
                    fullWidth
                    size="small"
                    open={openBranchManager}
                    onOpen={() => {
                      setopenBranchManager(true);
                    }}
                    onClose={() => {
                      setopenBranchManager(false);
                    }}
                    value={branchManagerRecord}
                    onChange={(_, newVal) => {
                      setBranchManagerRecord(newVal);
                      setBranchManagerChanged(true);
                      setReady(true);
                    }}
                    getOptionLabel={(user) => `${user?.name}`}
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Grid
                          container
                          direction="column"
                          sx={{
                            backgroundColor: (theme) => theme.palette.grey[200],
                            borderRadius: 1,
                            padding: 1,
                          }}
                          spacing={1}
                        >
                          <Grid item>
                            <Typography color="primary">
                              {option.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    options={users.filter((item) => item.userType == 2)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Branch Manager"
                        label="Branch Manager"
                        InputProps={{
                          ...params.InputProps,
                          autoComplete: 'Branch manager',
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

            {!role?.isApplicationTeam && (
              <Grid item>
                <Autocomplete
                  fullWidth
                  size="small"
                  open={openAssess}
                  onOpen={() => {
                    setOpenAssess(true);
                  }}
                  onClose={() => {
                    setOpenAssess(false);
                  }}
                  value={assessmentRecord}
                  onChange={(_, newVal) => {
                    setAssessmentRecord(newVal);
                    setAssessmentTeamChanged(true);
                    setReady(true);
                  }}
                  getOptionLabel={(user) => `${user?.name}`}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Grid
                        container
                        direction="column"
                        sx={{
                          backgroundColor: (theme) => theme.palette.grey[200],
                          borderRadius: 1,
                          padding: 1,
                        }}
                        spacing={1}
                      >
                        <Grid item>
                          <Typography color="primary">
                            {option?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  )}
                  options={users}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Assessment Team Member"
                      label="Assessment"
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password',
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {!role.isApplicationTeam && (
              <Grid item>
                <Autocomplete
                  fullWidth
                  size="small"
                  open={openFollow}
                  onOpen={() => {
                    setOpenFollow(true);
                  }}
                  onClose={() => {
                    setOpenFollow(false);
                  }}
                  value={followUpRecord}
                  onChange={(_, newVal) => {
                    setFollowUpRecord(newVal);
                    setFollowupTeamChanged(true);
                    setReady(true);
                  }}
                  getOptionLabel={(user) => `${user?.name}`}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Grid
                        container
                        direction="column"
                        sx={{
                          backgroundColor: (theme) => theme.palette.grey[200],
                          borderRadius: 1,
                          padding: 1,
                        }}
                        spacing={1}
                      >
                        <Grid item>
                          <Typography color="primary">{option.name}</Typography>
                        </Grid>
                      </Grid>
                    </li>
                  )}
                  options={users}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Follow Up Team Member"
                      label="Follow Up"
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password',
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {!role.isApplicationTeam && (
              <Grid item>
                <Autocomplete
                  fullWidth
                  size="small"
                  open={openCom}
                  onOpen={() => {
                    setOpenCom(true);
                  }}
                  onClose={() => {
                    setOpenCom(false);
                  }}
                  value={complianceRecord}
                  onChange={(_, newVal) => {
                    setComplianceRecord(newVal);
                    setComplianceTeamChanged(true);
                    setReady(true);
                  }}
                  getOptionLabel={(user) => `${user?.name}`}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Grid
                        container
                        direction="column"
                        sx={{
                          backgroundColor: (theme) => theme.palette.grey[200],
                          borderRadius: 1,
                          padding: 1,
                        }}
                        spacing={1}
                      >
                        <Grid item>
                          <Typography color="primary">
                            {option?.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  )}
                  options={users}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Compliance Team Member"
                      label="Compliance Team Member"
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password',
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {role.isSuperAdmin ? (
              <>
                <Grid item>
                  <TextField
                    fullWidth
                    size="small"
                    label="Campaign & Referral"
                    value={campaign}
                    onChange={(e) => {
                      setCampaign(e.target.value);
                      setCampaignChanged(true);
                      setReady(true);
                    }}
                  />
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel>Lead Events</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Lead Event"
                      value={eventId}
                      onChange={(e) => {
                        setEventId(e.target.value);
                        setEventChanged(true);
                        setReady(true);
                      }}
                    >
                      <option value={0}>--Lead Events--</option>
                      {events.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.eventName}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel>Lead Origin</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Lead Origin"
                      value={origin}
                      onChange={(e) => {
                        setOrigin(e.target.value);
                        setOriginChanged(true);
                        setReady(true);
                      }}
                    >
                      <option value={0}>--Lead Origin--</option>
                      {LEADORIGINS.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel>Lead Source</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Lead Source"
                      value={source}
                      onChange={(e) => {
                        setSource(e.target.value);
                        setSourceChanged(true);
                        setReady(true);
                      }}
                    >
                      <option value={0}>--Lead Source--</option>
                      {STUDENTSOURCES.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel>Lead Channel</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Lead Channel"
                      value={channel}
                      onChange={(e) => {
                        setChannel(e.target.value);
                        setChannelChanged(true);
                        setReady(true);
                      }}
                    >
                      <option value={0}>--Lead Channel--</option>
                      {LEADCHANNELS.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : role.isAdmin || role.isSeniorAdmin ? (
              <>
                <Grid item>
                  <FormControl fullWidth>
                    <InputLabel>Lead Origin</InputLabel>
                    <Select
                      fullWidth
                      size="small"
                      native
                      label="Lead Origin"
                      value={origin}
                      onChange={(e) => {
                        setOrigin(e.target.value);
                        setOriginChanged(true);
                        setReady(true);
                      }}
                    >
                      <option value={0}>--Lead Origin--</option>
                      {LEADORIGINS.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            ) : (
              ''
            )}
          </Grid>
        </DialogContent>
        {/* <DialogActions>
          <Button
            disabled={!ready}
            onClick={confirm}
            variant="contained"
            className="bg-sky-400 hover:bg-sky-500"
          >
            Confirm Selection
          </Button>
        </DialogActions> */}

        <DialogActions className="flex justify-between">
          <Button onClick={handleClose} className="btn btn-warning">
            Cancel
          </Button>
          <Button
            onClick={confirm}
            className="btn btn-info"
            disabled={!ready || loading}
          >
            {loading ? <LoadingMessage /> : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
