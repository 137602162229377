import React from 'react';
import { Button, Modal } from '@mui/material';

const ConfirmConvertDialog = ({
  showConfirmDialog,
  setShowConfirmDialog,
  handleConvertAndSubmit,
  convertResponse,
}) => {
  return (
    <Modal
      open={showConfirmDialog}
      onClose={() => setShowConfirmDialog(false)}
      className="flex items-center justify-center"
    >
      <div className="bg-white p-10 rounded-lg shadow-lg max-w-sm mx-auto">
        <p className="mb-4 font-bold text-xl text-gray-900">Convert Lead</p>

        <p className="mb-6 text-gray-600">
          Are you sure you want to convert this lead?
        </p>

        <div className="flex justify-end space-x-4">
          <Button
            className="border border-gray-300 text-gray-600"
            variant="outlined"
            onClick={() => setShowConfirmDialog(false)}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            className="bg-blue-600 hover:bg-blue-700 text-white"
            onClick={handleConvertAndSubmit}
          >
            Convert
          </Button>
        </div>

        {convertResponse.message && (
          <p
            className={`mt-6 text-center text-xl ${
              convertResponse.success ? 'text-green-600' : 'text-red-600'
            }`}
          >
            {convertResponse.message}
          </p>
        )}
      </div>
    </Modal>
  );
};

export default ConfirmConvertDialog;
