import React from 'react';
import ReferralDashboard from '../Dashboard/ReferralDashboard';

const ReferralHomePage = () => {
  return (
    <>
       <ReferralDashboard/>
    </>
  );
};

export default ReferralHomePage;
