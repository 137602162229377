/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import * as actions from '../../store/actions/index';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { connect } from 'react-redux';

import { Button, ButtonGroup, Grid } from '@mui/material';
import ProfilePhoto from './ProfilePhoto';
import PhotoUploadWidgetWithCropping from '../Common/ImageUpload/PhotoUploadWidgetWithCropping';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonalDetails from './PersonalDetails';
import { useTheme } from '@mui/material';

const PREFIX = 'MyAccount';

const classes = {
  tabs: `${PREFIX}-tabs`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.tabs}`]: {
    width: '12%',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: '88%' }}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const MyAccount = (props) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [value, setValue] = React.useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadingInProgress, setUploadingInProgress] = useState(false);

  const successDialogStyles = {
    backgroundColor: 'bg-green-500', // Green color for success
  };

  // Custom styles for error dialog
  const errorDialogStyles = {
    backgroundColor: 'bg-red-400',
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDeletePhoto = (imageUrl) => {
    props
      .deleteProfilePhoto(imageUrl)
      .then(() => {
        handleClose();
      })
      .catch((error) => {
        debugger;
        console.log(error);
      });
  };

  const handlePhotoUpload = (file) => {
    setUploadingInProgress(true);
    props
      .uploadProfilePhoto(file)
      .then(() => {
        setUploadingInProgress(false);
        setUploading(false);
        setSuccessMessage('Photo Upload Successfully')
        setSuccessOpen(true);
      })
      .catch((error) => {
        console.log(error);
        setUploadingInProgress(false);
        setSuccessMessage(error);
      });
  };

  return (
    // <Root
    //   sx={{
    //     flexGrow: 1,
    //     backgroundColor: 'theme.palette.background.paper',
    //     display: 'flex',

    //   }}
    // >
    <>
      <Dialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        aria-labelledby="success-dialog-title"
        PaperProps={{
          className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
        }}
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>{successMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Show error Dialog */}
      <Dialog
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        aria-labelledby="error-dialog-title"
        PaperProps={{
          className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
        }}
      >
        <DialogTitle id="error-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorMessage('')} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Tabs
        orientation="horizontal"
        variant="standard"
        value={value}
        onChange={handleChange}
        aria-label="vertical tabs example"
        className={classes.tabs}
      >
        <Tab label="Profile Photo" {...a11yProps(0)} />
        <Tab label="Personal Details" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        {uploading ? (
          <Grid container direction="row" justifyContent="center">
          
            <Grid item >
              <PhotoUploadWidgetWithCropping
                uploadPhoto={handlePhotoUpload}
                loading={uploadingInProgress}
              />
            </Grid>
            
            <Grid item container justifyContent="center">
              <Grid item>
                <Button className='mt-1 mr-6 px-32 bg-red-700 hover:bg-red-600 text-white' onClick={() => setUploading(false)} variant="outlined">
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}
          >
            <Grid item>
              {props.isLoggedIn ? (
                <ProfilePhoto imageUrl={props.imageUrl} name={props.name} />
              ) : null}
            </Grid>
            <Grid item>
              <div className="flex flex-col">
                <Button
                  onClick={() => setUploading(true)}
                  className="relative bg-sky-500 hover:bg-sky-400 rounded-xl text-white"
                >
                  {props.imageUrl
                    ? 'Change Photo'
                    : 'Click here to Upload Photo'}
                </Button>

                {props.imageUrl ? (
                  <Button
                    className="relative mt-2 bg-red-600 hover:bg-red-500 rounded-xl text-white"
                    onClick={handleClickOpen}
                  >
                    Delete Photo
                  </Button>
                ) : null}
              </div>
            </Grid>
          </Grid>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PersonalDetails
          name={props.name}
          phoneNumber={props.phoneNumber}
          applicationUpdateEmail={props.applicationUpdateEmail}
          id={props.id}
        />
      </TabPanel>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Delete Profile Photo'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you wish to delete your profile photo? Press yes to
            confirm.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeletePhoto(props.imageUrl)}
            color="primary"
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    
    </>
    // </Root>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    id: state.auth.id,
    name: state.auth.name,
    applicationUpdateEmail: state.auth.applicationUpdateEmail,
    phoneNumber: state.auth.phoneNumber,
    roleName: state.auth.roleName,
    isInternal: state.auth.isInternal,
    status: state.auth.status,
    role: state.auth.role,
    permissions: state.auth.permissions,
    imageUrl: state.auth.image,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    uploadProfilePhoto: (file) => dispatch(actions.uploadProfilePhoto(file)),
    deleteProfilePhoto: (fileName) =>
      dispatch(actions.deleteProfilePhoto(fileName)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);
