import LoadingView from '../../Common/Loading/LoadingView';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState, useRef } from 'react';
import Agent from '../../../api/agent';
import { LoadingButton } from '@mui/lab';
import PhoneInput from 'react-phone-number-input';
import phoneInput from '../Registration/phoneInput';
import additionalPhoneInput from '../Registration/additionalPhoneInput';
import { Countries } from '../../../Util/Util';
import useWindowSize from '../../Students/common/useWindowSize';
import { connect } from 'react-redux';
import PhotoWidgetDropzone from '../../Common/ImageUpload/PhotoWidgetDropzone';
import * as actions from '../../../store/actions';
import ReferralFooter from '../Footer/ReferralFooter';
import ReferralFooterMob from '../Footer/ReferralFooterMob';
import { useNavigate } from 'react-router-dom';
import {
  MenuItem,
  Button,
  TextField,
  Grid,
  Typography,
  FormHelperText,
  Select,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ButtonGroup,
  FormControl,
  IconButton,
  CircularProgress,
} from '@mui/material';
import { DownloadCloudFile } from '../../../Util/FileProcessing';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Divider } from '@mui/material';
import ManagerProfile from '../Profile/ManageProfile';
import AddMessage from '../../Messages/AddMessage';
import ConfirmationModal from '../../Common/Modal/ConfirmationModal';
import Agreements from '../Registration/Agreemnts';
import { Upload } from '@mui/icons-material';

const PREFIX = 'AgentGeneralInfo';
const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  paper: `${PREFIX}-paper`,
  agentInfo: `${PREFIX}-agentInfo`,
  form: `${PREFIX}-form`,
  documentContainer: `${PREFIX}-documentContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingRight: '20px',
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  [`& .${classes.agentInfo}`]: {
    width: '100%',
  },

  [`& .${classes.form}`]: {
    paddingLeft: 0,
  },

  [`& .${classes.documentContainer}`]: {
    paddingTop: -15,
    marginLeft: 12,
    border: '1px solid gray',
    borderRadius: 5,
  },
}));

const ReferralGeneralInfo = ({ appUserId }) => {
  const [successMessage, setSuccessMessage] = useState('');
  const [referral, setReferral] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [id, setId] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [additionalEmail, setAdditionalEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [additionalPhoneNumber, setAdditionalPhoneNumber] = useState();
  const [nationality, setNationality] = useState();
  const [postcode, setPostalCode] = useState();
  const [city, setCity] = useState();
  const [streetAddress, setStreetAddress] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [expanded, setExpanded] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [fileUrl, setFileUrl] = useState();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();
  const [localFileName, setLocaleFileName] = useState();
  const [error, setError] = useState();
  const [termsAgreed, setTermsAgreed] = useState(0);
  const [fileSelected, setFileSelected] = useState();
  const [dataPrivacyAgreed, setDataPrivacyAgreed] = useState(0);
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referralUserInfo, SetReferralUserInfo] = useState({});
  const [openAddMessage, setOpenAddMessage] = useState(false);
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [fileNameToDelete, setFileNameToDelete] = useState();
  const [saving, setSaving] = useState(false);
  const [agreementFileName, setAgreementFileName] = useState([]);
  const personalFileInput = useRef();

  const initialPersonalFileUpload = () => {
    personalFileInput.current.click();
    setOpenConfirm(false);
    setIsReset(false);
    setSaving(false);
    setFile();
  };
  useEffect(() => {
    let isCancelled = false;
    Agent.Referral.getreferralbyuserId(appUserId)
      .then((ref) => {
        console.log('ref', ref);

        setFirstName(ref.firstName);
        setLastName(ref.lastName);
        setEmail(ref.email);
        setAdditionalEmail(ref.additionalEmail);
        setPhoneNumber(ref.phoneNumber);
        setAdditionalPhoneNumber(ref.additionalPhoneNumber);
        setNationality(ref.nationality);
        setPostalCode(ref.postcode);
        setCity(ref.city);
        setStreetAddress(ref.streetAddress);
        setPassword(ref.password);
        setLoaded(true);
        setId(ref.id);
        setTermsAgreed(ref.termsAgreed);
        setDataPrivacyAgreed(ref.dataPrivacyAgreed);
        //setReferral(ref);
        setFileName(ref.fileName);
        const localFileNameTemp=ref?.fileName.split('/')[1].split('_');
        console.log('local file name',localFileNameTemp);
        setLocaleFileName(localFileNameTemp[1])
        setFileUrl(ref.fileUrl);
        setAgreementFileName(ref.referralCloudFiles);
      })
      .catch((error) => {
        debugger;
        console.log(error);
        setLoaded(true);
      });
    return () => {
      isCancelled = true;
    };
  }, [appUserId, isReset]);

  useEffect(() => {
    Agent.Referral.getreferralbyuserId(appUserId).then((data) => {
      SetReferralUserInfo(data);
      console.log('data', data);
    });
  }, [appUserId]);

  const handleFileDelete = (fileName) => {
    setFileName(fileName);
    setLocaleFileName('');
    setOpenConfirm(false);
  };

  const deleteFile = () => {
    setIsReset(false);
    Agent.CloudFiles.delete(fileNameToDelete, 0, 0, 0, 0, 0, 0, id)
      .then(() => {
        handleFileDelete(fileNameToDelete);
        setIsReset(true);
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };

  const upload = (file) => {
    setSaving(false);
    const harmful = ['exe', 'pif', 'bat'];
    setFile(file);
    setIsReset(false);
    let fileZie = file.size / 1024 / 2024;
    if (fileZie > 2) {
      setError('Error: File size cannot be more than 2 MB');
      return;
    }
    const extension = file.name.split('.').pop();
    if (harmful.includes(extension.toLowerCase())) {
      setError(
        'Error: Unsupported file type. You can only upload a valid document file type'
      );
      return;
    }
    setError(null);
  };

  const completeUpload = (id) => {
    Agent.CloudFiles.upload(file, 23, 0, 0, '', 0, 0, 0, 0, id)
      .then((response) => {
        setIsReset(true);
      })
      .catch((error) => {
        console.log(error);
        setError(
          'Error: Failed to upload. Check file type/size etc and try again!'
        );
      });
  };

  const uploadToCloud = () => {
    let fileZie = file.size / 1024 / 2024;
    if (fileZie > 5) {
      return;
    }
    setSaving(true);
    if (id > 0) {
      completeUpload(id);
    } else {
      var modifiedReferral = {
        documentType: 1,
        referralId: id,
      };

      Agent.Referral.uploadFile(modifiedReferral)
        .then((id) => {
          setSaving(false);
          //debugger
          //setStudentIdParent(id)
          return id;
        })
        .then(completeUpload)
        .catch((error) => {
          setSaving(false);
          console.log(error);
          setError(
            'Error: Failed to upload. Check file type/size etc and try again!'
          );
        });
    }
  };

  const handleFirstName = (event) => {
    setFirstName(event.target.value);

    // fieldErrors.handleReferralName = '';
  };
  const handleLastName = (event) => {
    setLastName(event.target.value);
    //fieldErrors.lastName = '';
  };
  const handleEmail = (event) => {
    setEmail(event.target.value);
    fieldErrors.email = '';
  };
  const handlePassword = (event) => {
    setPassword(event.target.value);
    //fieldErrors.password = '';
  };
  const handleAdditionalEmail = (event) => {
    setAdditionalEmail(event.target.value);
  };
  const [phoneError, setPhoneError] = useState('');

  const handlePhoneNumber = (value, country) => {
    fieldErrors.phoneNumber = '';

    if (value) {
      if (/^\+[1-9]\d{1,14}$/.test(value)) {
        setPhoneNumber(value);
        setPhoneError('');
      } else {
        setPhoneNumber(null);
        setPhoneError(
          'Invalid phone number format. Include country code (+) and digits.'
        );
      }
    } else {
      // Clear any previous error and reset the phone number here
      setPhoneNumber(null);
      setPhoneError('');
    }
  };
  const [addPhoneError, setaddPhoneError] = useState('');
  const handleAdditionalPhoneNumber = (value, country) => {
    if (value) {
      if (/^\+[1-9]\d{1,14}$/.test(value)) {
        setAdditionalPhoneNumber(value);
        setaddPhoneError('');
      } else {
        setAdditionalPhoneNumber(null);
        setaddPhoneError(
          'Invalid phone number format. Include country code (+) and digits.'
        );
      }
    } else {
      // Clear any previous error and reset the phone number here
      setAdditionalPhoneNumber(null);
      setaddPhoneError('');
    }
  };
  const handleNationality = (event) => {
    setNationality(event.target.value);
    //fieldErrors.country = '';
  };
  const handlePostalCode = (event) => {
    setPostalCode(event.target.value);
    fieldErrors.postalCode = '';
  };
  const handleCity = (event) => {
    setCity(event.target.value);
    fieldErrors.city = '';
  };
  const handleStreetAddress = (event) => {
    setStreetAddress(event.target.value);
    fieldErrors.streetAddress = '';
  };

  const handleOnClickUpdate = async () => {
    if (!termsAgreed || !dataPrivacyAgreed || !fileUrl) {
      setErrorMessage(
        'Please check the Term and Conditions and upload personal document before submission'
      );
    } else {
      const modifiedReferral = {
        id,
        firstName,
        lastName,
        email,
        additionalEmail: additionalEmail=='' ? null : additionalEmail,
        phoneNumber,
        additionalPhoneNumber,
        city,
        postcode,
        nationality,
        streetAddress,
        termsAgreed: termsAgreed,
        dataPrivacyAgreed: dataPrivacyAgreed,
      };
      setUpdating(true);
      console.log('modifiedReferral', modifiedReferral);
      await Agent.Referral.updateReferralUser(modifiedReferral)
        .then(async (res) => {
          console.log('respond', res);
          setSuccessMessage('update successfully');
          setSuccessOpen(true);
          setUpdating(false);
          //navigate('/referral/profile');
        })
        .catch((error) => {
          console.log(error);
          setUpdating(false);
          setErrorMessage(error);
        });
    }
  };

  const successDialogStyles = {
    backgroundColor: 'bg-green-400',
  };
  const errorDialogStyles = {
    backgroundColor: 'bg-red-400',
  };

  const size = useWindowSize();
  console.log('size.width', size.width);
  return (
    <>
      {' '}
      <Dialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        aria-labelledby="success-dialog-title"
        PaperProps={{
          className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
        }}
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>{successMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        aria-labelledby="error-dialog-title"
        PaperProps={{
          className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
        }}
      >
        <DialogTitle id="error-dialog-title">Alert</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorMessage('')} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {loaded ? (
        size.width > 844 ? (
          <>
            <div className="flex flex-col gap-4 items-center mt-4">
              <ManagerProfile
                referralUserInfo={referralUserInfo}
                setOpenAddMessage={setOpenAddMessage}
              />
            </div>
            <div className="flex flex-col gap-4 items-center">
              <div className="relative min-w-[60%] md:min-w-[70%] xl:min-w-[60%]  justify-center items-center py-6 min-h-full overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
                <h2 className="text-gray-800 pl-2 text-xl text-center font-semibold">
                  {' '}
                  GENERAL INFORMATION
                </h2>
                <form>
                  <div className="g-0 flex">
                    <div className="px-4 w-full">
                      <div className=" md:pt-6 pb-8 ">
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <TextField
                              label="First Name"
                              variant="outlined"
                              size="small"
                              fullWidth
                              required
                              //error={!!fieldErrors.firstName}
                              // helperText={fieldErrors.firstName}
                              value={firstName}
                              onChange={handleFirstName}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              label="Last Name"
                              variant="outlined"
                              size="small"
                              fullWidth
                              required
                              //error={!!fieldErrors.firstName}
                              // helperText={fieldErrors.firstName}
                              value={lastName}
                              onChange={handleLastName}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              label="Email"
                              variant="outlined"
                              size="small"
                              type="email"
                              fullWidth
                              required
                              //error={!!fieldErrors.email}
                              //helperText={fieldErrors.email}
                              value={email}
                              onChange={handleEmail}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              label="Additional Email"
                              variant="outlined"
                              size="small"
                              type="email"
                              fullWidth
                              //error={!!fieldErrors.email}
                              //helperText={fieldErrors.email}
                              value={additionalEmail}
                              onChange={handleAdditionalEmail}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <PhoneInput
                                placeholder="Phone Number"
                                error={!!fieldErrors.phoneNumber}
                                helperText={fieldErrors.phoneNumber}
                                value={phoneNumber}
                                required
                                onChange={handlePhoneNumber}
                                inputComponent={phoneInput}
                              />
                              {phoneError && (
                                <FormHelperText error>
                                  {phoneError}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <PhoneInput
                                placeholder="Additional Phone Number"
                                error={!!fieldErrors.phoneNumber}
                                helperText={fieldErrors.phoneNumber}
                                value={additionalPhoneNumber}
                                onChange={handleAdditionalPhoneNumber}
                                inputComponent={additionalPhoneInput}
                              />
                              {phoneError && (
                                <FormHelperText error>
                                  {phoneError}
                                </FormHelperText>
                              )}
                            </FormControl>
                          </Grid>
                          {/* {emailChecking && (
                        <div>
                          <Box sx={{ display: 'flex' }}>
                            <CircularProgress />
                          </Box>
                        </div>
                      )} */}

                          <Grid item xs={6}>
                            <TextField
                              label="City"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={city}
                              //error={!!fieldErrors.streetAddress}
                              //helperText={fieldErrors.streetAddress}
                              onChange={handleCity}
                              required
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              label="Post code"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={postcode}
                              //error={!!fieldErrors.streetAddress}
                              //helperText={fieldErrors.streetAddress}
                              onChange={handlePostalCode}
                              required
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel>Country</InputLabel>
                              <Select
                                required
                                size="small"
                                label="Country"
                                native
                                fullWidth
                                value={nationality}
                                onChange={handleNationality}
                              >
                                <option value={0}>select</option>
                                {Countries.filter((item)=>item.id!=0).map((charge) => (
                                  <option key={charge.id} value={charge.id}>
                                    {charge.name}
                                  </option>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item xs={6}>
                            <TextField
                              label="Street Address"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={streetAddress}
                              //error={!!fieldErrors.streetAddress}
                              //helperText={fieldErrors.streetAddress}
                              onChange={handleStreetAddress}
                              required
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="relative bg-gray-100 shadow-full px-4 py-2 rounded-lg w-full">
                  <div className="font-semibold pb-0 text-center">
                    <div className="text-2xl">Your Documents*</div>
                    <div className="text-xl text-gray-600">
                      Please upload your passport or NID copy of pdf format here
                    </div>
                    <div className="relative items-start gap-5 mt-0 w-full">
                      <div className="w-full">
                        <div className="relative flex justify-between">
                          <div></div>
                          {!openConfirm && !isReset && file && (
                            <Grid
                              item
                              xs={6}
                              container
                              direction="column"
                              spacing={2}
                              className="mb-2 mt-2 "
                            >
                              <Grid item container direction="column">
                                {error && (
                                  <Grid
                                    item
                                    style={{
                                      color: 'red',
                                      marginBottom: 20,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {error}
                                  </Grid>
                                )}
                                <Grid item>
                                  <Typography>{`File name: ${file.name}`}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>{`File Size ${
                                    file.size / 1024
                                  } KiB`}</Typography>
                                </Grid>
                              </Grid>
                              {/* {referralUserInfo.status != 5 && ( */}
                                <Grid item>
                                  <Button
                                    disabled={error}
                                    variant="contained"
                                    className="bg-sky-400 hover:bg-sky-500"
                                    onClick={uploadToCloud}
                                  >
                                    {saving ? (
                                      <>
                                        <span>SAVING </span>{' '}
                                        <CircularProgress size={14} />
                                      </>
                                    ) : (
                                      'SAVE'
                                    )}
                                  </Button>
                                </Grid>
                              {/* )} */}
                            </Grid>
                          )}
                        </div>
                        <div className="relative px-4 gap-6 justify-between w-full bg-gray-300 pt-3">
                          <div className="min-w-[50%]">
                            <div>
                              <button
                                className="w-50 text-indigo-600 min-w-full text-left hover:text-indigo-700"
                                onClick={(e) => {
                                  e.preventDefault();
                                  window.open(fileUrl, '_blank');
                                }}
                              >
                                {localFileName}
                              </button>
                            </div>
                          </div>

                          <div className="flex justify-between">
                            <div className="w-1/2">
                              {fileName && (
                                <div className="flex flex-row gap-2 py-4">
                                  <Button
                                    className="bg-cyan-500 hover:bg-cyan-400 text-gray-900 px-2"
                                    loading={loading}
                                    onClick={() => {
                                      setFileSelected(fileName);
                                      setLoading(true);
                                      DownloadCloudFile(
                                        '',
                                        fileName,
                                        'referralfiles'
                                      ).finally(() => {
                                        setLoading(false);
                                      });
                                    }}
                                    startIcon={<CloudDownloadIcon />}
                                  >
                                    <h3 className="font-bold">Download</h3>
                                  </Button>
                                  <Button
                                    disabled={referralUserInfo.status == 5}
                                    variant="contained"
                                    className="bg-rose-400 hover:bg-rose-500 text-white"
                                    onClick={() => {
                                      setOpenConfirm(true);
                                      setFileNameToDelete(fileName);
                                    }}
                                    // startIcon={<DeleteForeverIcon />}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              )}
                            </div>
                            <Grid item>
                              <input
                                type="file"
                                style={{ display: 'none' }}
                                onChange={(e) => setFile(e.target.files[0])}
                                ref={personalFileInput}
                              />
                              <Button
                                onClick={initialPersonalFileUpload}
                                className="bg-cyan-500 hover:bg-cyan-400 text-gray-900 px-2 mb-1"
                              >
                                <Upload fontSize="medium" />
                                <span className="font-bold">Upload</span>
                              </Button>
                            </Grid>
                          </div>
                        </div>
                        <Divider />
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <div className=" bg-gray-100 w-full px-4 py-2 rounded-lg">
                  <div className="text-2xl w-full font-semibold mb-4 text-gray-700 pb-0 text-center">
                    Terms and Conditions
                  </div>
                  <Grid item className="mb-6">
                    <Agreements
                      termsAgreed={termsAgreed}
                      setTermsAgreed={setTermsAgreed}
                      dataPrivacyAgreed={dataPrivacyAgreed}
                      setDataPrivacyAgreed={setDataPrivacyAgreed}
                    />
                  </Grid>
                </div>
                {referralUserInfo.status != 5 && (
                  <div className="flex justify-between gap-96 p-2 mb-24">
                    <div></div>
                    <div className="flex bg-cyan-700 px-4 py-1 text-white rounded-lg">
                      <Button onClick={handleOnClickUpdate}>
                        {updating ? (
                          <>
                            <span className="font-medium text-white">
                              UPDATING{' '}
                            </span>
                          </>
                        ) : (
                          <span className="font-medium text-white">
                            UPDATE{' '}
                          </span>
                        )}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <ConfirmationModal
              open={openConfirm}
              cancel={setOpenConfirm}
              confirm={deleteFile}
              confirmText="Confirm Deletion"
              content="Are you sure that you would like to delete this document?"
            />
          </>
        ) : (
          <>
            <div className="min-w-screen min-h-screen px-0 py-6  overflow-hidden bg-white shadow-md sm:max-w-md sm:rounded-lg">
              <div className="flex flex-col ml-4 gap-4 items-center mt-0">
                <ManagerProfile
                  referralUserInfo={referralUserInfo}
                  setOpenAddMessage={setOpenAddMessage}
                />
              </div>
              <h2 className="text-gray-800 pl-2 text-xl text-center font-semibold">
                {' '}
                GENERAL INFORMATION
              </h2>
              <form className="mt-6">
                <div className="g-0 lg:flex lg:flex-wrap">
                  <div className="px-4 md:px-0 lg:w-full">
                    <div className="md:mx-6 md:pt-6 pb-8 ">
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            label="First Name"
                            variant="outlined"
                            size="small"
                            fullWidth
                            required
                            //error={!!fieldErrors.firstName}
                            //helperText={fieldErrors.firstName}
                            value={firstName}
                            onChange={handleFirstName}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Last Name"
                            variant="outlined"
                            size="small"
                            fullWidth
                            required
                            //error={!!fieldErrors.firstName}
                            //helperText={fieldErrors.firstName}
                            value={lastName}
                            onChange={handleLastName}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Email"
                            variant="outlined"
                            size="small"
                            type="email"
                            fullWidth
                            required
                            // error={!!fieldErrors.email}
                            // helperText={fieldErrors.email}
                            value={email}
                            onChange={handleEmail}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            label="Additional Email"
                            variant="outlined"
                            size="small"
                            type="email"
                            fullWidth
                            //error={!!fieldErrors.email}
                            //helperText={fieldErrors.email}
                            value={additionalEmail}
                            onChange={handleAdditionalEmail}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <PhoneInput
                              placeholder="Phone Number"
                              error={!!fieldErrors.phoneNumber}
                              helperText={fieldErrors.phoneNumber}
                              value={phoneNumber}
                              required
                              onChange={handlePhoneNumber}
                              inputComponent={phoneInput}
                            />
                            {phoneError && (
                              <FormHelperText error>
                                {phoneError}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <PhoneInput
                              placeholder="Additional Phone Number"
                              error={!!fieldErrors.phoneNumber}
                              helperText={fieldErrors.phoneNumber}
                              value={additionalPhoneNumber}
                              onChange={handleAdditionalEmail}
                              inputComponent={additionalPhoneInput}
                            />
                            {phoneError && (
                              <FormHelperText error>
                                {phoneError}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                        {/* {emailChecking && (
                          <div>
                            <Box sx={{ display: 'flex' }}>
                              <CircularProgress />
                            </Box>
                          </div>
                        )} */}

                        <Grid item xs={12}>
                          <TextField
                            label="City"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={city}
                            //error={!!fieldErrors.streetAddress}
                            //helperText={fieldErrors.streetAddress}
                            onChange={handleCity}
                            required
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            label="Post code"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={postcode}
                            //error={!!fieldErrors.streetAddress}
                            //helperText={fieldErrors.streetAddress}
                            onChange={handlePostalCode}
                            required
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <TextField
                              required
                              size="small"
                              select
                              label="Country"
                              fullWidth
                              value={nationality}
                              //error={!!fieldErrors.country}
                              //helperText={fieldErrors.country}
                              onChange={handleNationality}
                              variant="outlined"
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              <option value={0}>select</option>
                              {Countries.filter((item)=>item.id!=0).map((charge) => (
                                <MenuItem key={charge.id} value={charge.id}>
                                  {charge.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                            label="Street Address"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={streetAddress}
                            error={!!fieldErrors.streetAddress}
                            helperText={fieldErrors.streetAddress}
                            onChange={handleStreetAddress}
                            required
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </form>
              <br />
              <div className="relative bg-gray-100 px-4 py-2 shadow-full rounded-lg w-full">
                <div className="font-semibold pb-0 text-center">
                  <div className="text-2xl">Your Document*</div>
                  <div className="text-xl text-gray-600 mb-8">
                    Please upload your passport or NID copy of pdf format here
                  </div>
                  <div className="relative items-start gap-5 mt-0 w-full">
                    <div className="w-full">
                      <div className="relative gap-6 justify-between w-full">
                        <Grid item container spacing={3}>
                          {!openConfirm && !isReset && file && (
                            <Grid
                              item
                              xs={6}
                              container
                              direction="column"
                              spacing={1}
                              className="mt-2 mb-4"
                            >
                              <Grid item container direction="column">
                                {error && (
                                  <Grid
                                    item
                                    style={{
                                      color: 'red',
                                      marginBottom: 20,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {error}
                                  </Grid>
                                )}
                                <Grid item>
                                  <Typography>{`File name: ${file.name}`}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>{`File Size ${
                                    file.size / 1024
                                  } KiB`}</Typography>
                                </Grid>
                              </Grid>
                              {/* {referralUserInfo.status != 5 && ( */}
                                <Grid item>
                                  <Button
                                    disabled={error}
                                    variant="contained"
                                    className="bg-sky-400 hover:bg-sky-500"
                                    onClick={uploadToCloud}
                                  >
                                    {saving ? (
                                      <>
                                        <span>SAVING </span>{' '}
                                        <CircularProgress size={14} />
                                      </>
                                    ) : (
                                      'SAVE'
                                    )}
                                  </Button>
                                </Grid>
                              {/* )} */}
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    </div>
                  </div>
                  <div className="relative items-start gap-5 mt-0 w-full">
                    <div className="w-full">
                      <div className="relative gap-6 justify-between w-full bg-gray-200 pt-3">
                        <div className="min-w-[70%]">
                          <div>
                            <button
                              className="w-50 text-indigo-600 min-w-full text-left hover:text-indigo-700"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(fileUrl, '_blank');
                              }}
                            >
                              {localFileName}
                            </button>
                          </div>
                        </div>
                        <Typography className="flex flex-row gap-2">
                          {fileName && (
                            <div>
                              <div className="flex flex-row gap-2 py-4">
                                <Button
                                  className="bg-cyan-500 hover:bg-cyan-400 text-gray-900 px-2"
                                  loading={loading}
                                  onClick={() => {
                                    setFileSelected(fileName);
                                    setLoading(true);
                                    DownloadCloudFile(
                                      '',
                                      fileName,
                                      'referralfiles'
                                    ).finally(() => {
                                      setLoading(false);
                                    });
                                  }}
                                  startIcon={<CloudDownloadIcon />}
                                >
                                  <h3 className="font-bold">Download</h3>
                                </Button>
                                <Button
                                  disabled={referralUserInfo.status == 5}
                                  variant="contained"
                                  className="bg-rose-400 hover:bg-rose-500 text-white"
                                  onClick={() => {
                                    setOpenConfirm(true);
                                    setFileNameToDelete(fileName);
                                  }}
                                  // startIcon={<DeleteForeverIcon />}
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          )}
                          <div className="w-42"></div>
                          <Grid item className="py-4">
                            <input
                              type="file"
                              style={{ display: 'none' }}
                              onChange={(e) => setFile(e.target.files[0])}
                              ref={personalFileInput}
                            />
                            <Button
                              onClick={initialPersonalFileUpload}
                              className="bg-cyan-500 hover:bg-cyan-400 text-gray-900 px-4 mb-1"
                            >
                              <Upload fontSize="medium" />
                              <span className="font-bold">Upload</span>
                            </Button>
                          </Grid>
                        </Typography>
                      </div>
                      <Divider />
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className=" bg-gray-100 w-full px-4 py-2 rounded-lg">
                <div className="text-2xl w-full font-semibold mb-4 text-gray-700 pb-0 text-center">
                  Terms and Conditions
                </div>
                <Grid item>
                  <Agreements
                    termsAgreed={termsAgreed}
                    setTermsAgreed={setTermsAgreed}
                    dataPrivacyAgreed={dataPrivacyAgreed}
                    setDataPrivacyAgreed={setDataPrivacyAgreed}
                  />
                </Grid>
              </div>
              {/* <div className="flex flex-col ml-64 gap-4 mt-4 mb-6"> */}
              {referralUserInfo.status != 5 && (
                <div className="ml-72 bg-cyan-700 px-2 mr-6 py-1 text-white rounded-lg mt-6">
                  <Button onClick={handleOnClickUpdate}>
                    {updating ? (
                      <>
                        <span className="font-medium text-white">
                          UPDATING{' '}
                        </span>
                      </>
                    ) : (
                      <span className="font-medium text-white">UPDATE </span>
                    )}
                  </Button>
                </div>
              )}
            </div>

            <ConfirmationModal
              open={openConfirm}
              cancel={setOpenConfirm}
              confirm={deleteFile}
              confirmText="Confirm Deletion"
              content="Are you sure that you would like to delete this document?"
            />
          </>
        )
      ) : (
        <>
          {' '}
          <br /> <br />
          <br /> <br /> <LoadingView />
          <br /> <br />
          <br /> <br />
          <LoadingView /> <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
        </>
      )}
      {size.width > 844 ? <ReferralFooter /> : <ReferralFooterMob />}
      {openAddMessage && (
        <AddMessage
          open={openAddMessage}
          cancel={setOpenAddMessage}
          toId={appUserId}
          agentId={0}
          toName={firstName + ' ' + lastName}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    isInternal: state.auth.isInternal,
    role: state.auth.role,
    users: state.users,
    name: state.auth.name,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralGeneralInfo);
