import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useField } from 'formik';
import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import { TextField } from '@mui/material';
import enGB from 'date-fns/locale/en-GB'; // the locale you want
registerLocale('en-GB', enGB); // register it with the name you want
import 'react-datepicker/dist/react-datepicker.css';
import { addHours, startOfDay } from 'date-fns';

const PREFIX = 'MyDate';

const classes = {
  reactDatepickerPopper: `${PREFIX}-reactDatepickerPopper`,
};

const Root = styled('div')(() => ({
  [`& .${classes.reactDatepickerPopper}`]: {
    zIndex: 3,
  },
}));

const Input = ({
  onChange,
  placeholder,
  value,
  id,
  onClick,
  error,
  label,
  ...props
}) => (
  <TextField
    label={label}
    fullWidth
    onChange={onChange}
    placeholder={placeholder}
    value={value}
    id={id}
    onClick={onClick}
    variant="outlined"
    size="small"
    error={error}
    {...props}
  />
);

export default function MyDate(props) {
  const [field, meta, helpers] = useField(props.name);
  return (
    <Root>
      
      <DatePicker
        popperClassName={classes.reactDatepickerPopper}
        autoComplete="off"
        locale="enGB"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        error={true}
        {...props}
        selected={(field.value && new Date(field.value)) || null}
        onChange={(newValue) => {
          if (newValue) {
            newValue = addHours(startOfDay(newValue), 12);
          }
          helpers.setValue(newValue);
        }}
        customInput={
          <Input
            label={props.label}
            error={props.error}
            {...props}
            {...field}
          />
        }
      />

      {meta.touched && meta.error ? (
        <Typography color="error">{meta.error}</Typography>
      ) : null}
    </Root>
  );
}
