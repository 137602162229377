import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Box, Typography } from '@mui/material';
import LoadingView from '../../Common/Loading/LoadingView';
import StudentLead from './../StudentLead/StudentLead';
import { connect } from 'react-redux';

const LeadsMenu = ({ role }) => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);

  const handleTabChange = (e, newVal) => {
    setTab(newVal);
  };
  return (
    <>
      {loading ? (
        <div style={{ marginTop: '0px', fontWeight: 'lighter' }}>
          <LoadingView />
        </div>
      ) : (
        <div>
          {/* <h3 style={{ fontWeight: 'bolder',marginTop:'-1%'}}>Reports and Analytics </h3> */}
          <Box>
            <Box>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#0081C9',
                    height: '3px',
                    // fontSize:'20px',
                    borderRadius: '30px',
                  },
                }}
              >
                {tabItems.map((item, index) => {
                  return (
                    <Tab className="text-black" label={item} key={index} />
                  );
                })}
              </Tabs>
              <Box>
                <TabPanel value={tab} index={0}>
                  <Typography
                    variant="h6"
                    component="h4"
                    align="center"
                    className="text-sky-900  text-xl mb-3"
                  ></Typography>
                  <StudentLead />
                </TabPanel>
{/* 
                {role.isSuperAdmin && (
                  <TabPanel value={tab} index={1}>
                    <BulkLeadUpload />
                  </TabPanel>
                )} */}
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.role,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(LeadsMenu);

const TabPanel = (props) => {
  const { children, value, index } = props;

  return <div>{value === index && <Box>{children}</Box>}</div>;
};

const tabItems = ['Students Lead Management'];
