// HELPER FUNCTION TO FORMAT TITLE
import React, { useState, forwardRef } from 'react';

import {
  MenuItem,
  FormControl,
  TextField,
  Autocomplete,
  Select,
  InputLabel,
  Checkbox,
} from '@mui/material';
import DatePicker from 'react-datepicker';

const textFieldColor = '#4E31AA';
const textFieldHoverColor = '#E80F88';

// FUNCTION FOR TITLE FILTERING FROM STRING

export const FormatTitle = (title) => {
  return (
    title.charAt(0).toUpperCase() +
    title
      .slice(1)
      .split(/(?=[A-Z])/)
      .join(' ')
  );
};

// GET MONTH WISE ROWS FOR MUI DATA TABLE

export const getRows = (fieldName, payload) => {
  let totalData = { Total: 0 };
  payload.map((item) => {
    totalData = {
      ...totalData,
      [item.month]: item[fieldName],
      Total: totalData.Total + item[fieldName],
    };
  });
  // console.log('Total Data', totalData);
  return totalData;
};

// HELPER FUNCTION FOR SELECTION FILTER
export const SelectionMenu = (props) => {
  const { options, label, name, val = '', onChange, disabled } = props;
  // const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState('');

  const handleClose = (event) => {
    setSelectedValue(event.target.value);
    onChange(name, event.target.value || 0);
  };

  return (
    <>
      <FormControl fullWidth sx={{ width: '230px', mx: 1 }}>
        <TextField
          select
          size="small"
          label={label}
          value={!val == '' ? val : selectedValue}
          onChange={handleClose}
          sx={{ backgroundColor: 'white', borderRadius: '16px', mb: 2 }}
          // className={classes.root}
        >
          {options.map((item, id) => {
            let menuItemLabel;
            let value;
            switch (name) {
              case 'intakeMonth':
                menuItemLabel = item.type;
                value = item.id;
                break;
              case 'year':
                menuItemLabel = item.year;
                value = item.year;
                break;
              case 'courseLevel':
                menuItemLabel = item.type;
                value = item.id;
                break;
              case 'intakeYear':
                menuItemLabel = item.year;
                value = item.year;
                break;
              case 'leadOrigin':
                menuItemLabel = item.type;
                value = item.id;
                break;

              case 'leadSource':
                menuItemLabel = item.type;
                value = item.id;
                break;

              case 'leadChannel':
                menuItemLabel = item.type;
                value = item.id;
                break;

              case 'leadCampaign':
                menuItemLabel = item.type;
                value = item.id;
                break;
              case 'studentType':
                menuItemLabel = item.type;
                value = item.id;
                break;

              default:
                menuItemLabel = item.name;
                value = item.id;
            }

            return (
              <MenuItem
                key={id}
                data-value={menuItemLabel}
                value={value}
                onClick={handleClose}
              >
                {menuItemLabel}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
    </>
  );
};

// HELPER FUNCTION FOR MULTI SELECT
export const MultiSelectionMenu = (props) => {
  const { options, label, onChange, name, selection, width = 230 } = props;
  // const classes = useStyles();

  const handleClose = (e) => {
    const { value } = e.target;
    onChange(value);
  };

  return (
    <FormControl
      sx={{ width: `${width}px`, mx: 1 }}
      // className={classes.quantityRoot}
    >
      <InputLabel
        sx={{
          fontWeight: 500,
          fontSize: 17,
          m: -0.3,
          color: `#3A1078`,
        }}
        id="multi-simple-select-label"
      >
        {label}
      </InputLabel>
      <Select
        sx={{
          boxShadow: 'none',
          '.MuiOutlinedInput-notchedOutline': {
            border: `0px solid ${textFieldColor}`,
            boxShadow:
              '0 4px 8px 0 rgba(0, 0, 0, 0.02), 0 6px 20px 0 rgba(0, 0, 0, 0.01)',
          },
          bgcolor: 'white',
          border: 0,
          borderRadius: '16px',
          mb: 2,
        }}
        // className={classes.root}
        labelId="multi-simple-select-label"
        id="multi-simple-select"
        multiple="true"
        size="small"
        value={selection}
        onChange={handleClose}
        MenuProps={{ style: { maxHeight: '200px', maxWidth: '100px' } }}
      >
        {options.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <Checkbox checked={selection.indexOf(item.id) > -1} />
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

// HELPER FUNCTION FOR AUTOCOMPLETE SELECTION FILTER
export const AutocompleteSelectionMenu = (props) => {
  const {
    options,
    label,
    item = 'name',
    onChange,
    name,
    multiple = false,
    disabled = false,
  } = props;
  // const classes = useStyles();

  const handleClose = (newVal) => {
    console.log('newVal', newVal);

    newVal ? onChange(name, newVal) : onChange(name, 0);
  };

  return (
    <>
      <FormControl sx={{ width: '230px', mx: 1 }}>
        <Autocomplete
          disabled={disabled}
          multiple={multiple}
          limitTags={1}
          size="small"
          options={options}
          onChange={(event, newVal) => {
            handleClose(newVal);
          }}
          getOptionLabel={(option) => option[item]}
          renderInput={(params) => (
            <TextField
              // className={classes.root}
              sx={{
                backgroundColor: 'white',
                height: '45px',
                width: 'full',
                borderRadius: '16px',
                mb: 2,
              }}
              {...params}
              label={label}
            />
          )}
        />
      </FormControl>
    </>
  );
};

// DATE PICKER
export const DatePickerField = ({
  name,
  label,
  onChange,
  selected,
  secondName,
}) => {
  // const classes = useStyles();

  const handleChange = (date) => {
    onChange(date, name, secondName);
  };
  return (
    <DatePicker
      minDate={new Date('01-01-2021')}
      maxDate={new Date(new Date().setDate(new Date().getDate() + 365))}
      popperClassName={className.reactDatepickerPopper}
      autoComplete="off"
      dateFormat="dd-MM-yyyy"
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      error={true}
      selected={(selected && new Date(selected)) || null}
      onChange={handleChange}
      customInput={
        <TextField
          label={label}
          fullWidth
          // onChange={onChange}
          value={selected}
          variant="outlined"
          size="small"
          // className={classes.root}
          sx={{
            backgroundColor: 'white',
            borderRadius: '16px',
            height: '45px',
            mb: 2,
            width: '230px',
            mx: 1.6,
          }}
        />
      }
    />
  );
};

const PREFIX = 'MyDate';

const className = {
  reactDatepickerPopper: `${PREFIX}-reactDatepickerPopper`,
  error: `${PREFIX}-error`,
};

// // FORMATE DATE FROM DATE PICKER

export const formatDate = (date) => {
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (month < 10) month = '0' + month;
  if (day < 10) day = '0' + day;
  return [year, month, day];
};

// INTAKE YEAR LOGIC

const previousyear = 2022; //  Year starts with 2022 as required
export const intakeYearList = Array.from({ length: 9 }, (_, i) => ({
  id: i + 1,
  year: previousyear + i,
}));

// YEAR LIST
export const yearList = [
  {
    id: 1,
    year: 2021,
  },

  {
    id: 2,
    year: 2022,
  },

  {
    id: 3,
    year: 2023,
  },
  {
    id: 4,
    year: 2024,
  },
  {
    id: 5,
    year: 2025,
  },
  {
    id: 6,
    year: 2026,
  },
  {
    id: 7,
    year: 2027,
  },
  {
    id: 8,
    year: 2028,
  },
];

//Student type list
export const StudentTypeList = [
  {
    id: 0,
    type: 'All',
  },
  {
    id: 1,
    type: 'Agent Students',
  },
  {
    id: 2,
    type: 'Direct Students',
  },
];
