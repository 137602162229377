/* eslint-disable jsx-a11y/no-autofocus  */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Divider,
  FormControl,
  Grid,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Draggable from 'react-draggable';
import {
  COMPLIANCE_STATUS,
  LEADCHANNELS,
  LEADORIGINS,
  STUDENTSOURCES,
  STUDENTSTATUS,
} from '../../../Util/Constants';
import Agent from '../../../api/agent';
import { Countries } from '../../../Util/Util';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const StudentAssignment = ({
  users,
  agents,
  action,
  role,
  cancel,
  student,
  bulkUpdate,
  referralList,
  setReferralReload,
}) => {
  users.sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
   console.log('role',role);
  const [ready, setReady] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [openAgent, setOpenAgent] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openRegManager, setopenRegManager] = useState(false);
  const [openCountryManager, setopenCountryManager] = useState(false);
  const [openBranchManager, setopenBranchManager] = useState(false);
  const [openComplianceStatus, setopenComplianceStatus] = useState(false);
  const [openVirtualTeam, setopenVirtualTeam] = useState(false);
  const [openAssess, setOpenAssess] = useState(false);
  const [openFollow, setOpenFollow] = useState(false);
  const [openCom, setOpenCom] = useState(false);
  const [agentChanged, setAgentChanged] = useState(false);
  const [statusChanged, setStatusChanged] = useState(false);
  const [appUserDetailsChanged, setAppUserDetailsChanged] = useState(false);
  const [assessmentTeamChanged, setAssessmentTeamChanged] = useState(false);
  const [followupTeamChanged, setFollowupTeamChanged] = useState(false);
  const [complianceTeamChanged, setComplianceTeamChanged] = useState(false);
  const [branchOfficeChanged, setBranchOfficeChanged] = useState(false);
  const [sourceChanged, setSourceChanged] = useState(false);
  const [channelChanged, setChannelChanged] = useState(false);
  const [originChanged, setOriginChanged] = useState(false);
  const [campaignChanged, setCampaignChanged] = useState(false);
  const [complianceStatusChanged, setComplianceStatusChanged] = useState(false);
  const [regionalManagerChanged, setRegionalManagerChanged] = useState(false);
  const [countryManagerChanged, setCountryManagerChanged] = useState(false);
  const [referralChanged, setReferralChanged] = useState(false);
  const [branchManagerChanged, setBranchManagerChanged] = useState(false);
  const [virtualTeamChanged, setVirtualTeamChanged] = useState(false);
  const [openReferral, setopenReferral] = useState(false);

  const [agentRecord, setAgentRecord] = useState(
    agents.find(({ id }) => id == (student ? student.agentId : 0))
  );
  const [complianceStatus, setComplianceStatus] = useState(
    COMPLIANCE_STATUS.find(
      ({ id }) => id == (student ? student.complianceStatus : null)
    )
  );
  const [adminRrecord, setAdminRecord] = useState(
    users.find(({ id }) => id == (student ? student.appUserDetailsId : 0))
  );
  const [assessmentRecord, setAssessmentRecord] = useState(
    users.find(({ id }) => id == (student ? student.assessmentTeamId : 0))
  );
  const [followUpRecord, setFollowUpRecord] = useState(
    users.find(({ id }) => id == (student ? student.followupTeamId : 0))
  );
  const [complianceRecord, setComplianceRecord] = useState(
    users.find(({ id }) => id == (student ? student.complianceTeamId : 0))
  );
  //Added by Liton 31/05/2023
  const [regionalManagerRecord, setRegionalManagerRecord] = useState(
    users.find(({ id }) => id == (student ? student.regionalManagerId : 0))
  );
  const [countryManagerRecord, setCountryManagerRecord] = useState(
    users.find(({ id }) => id == (student ? student.countryManagerId : 0))
  );
  const [branchManagerRecord, setBranchManagerRecord] = useState(
    users.find(({ id }) => id == (student ? student.branchManagerId : 0))
  );
  const [virtualTeamRecord, setVirtualTeamRecord] = useState(
    users.find(({ id }) => id == (student ? student.virtualTeamId : 0))
  );
  const [referralAssignId, setReferralAssignId] = useState(
    referralList.find(({ id }) => id == (student ? student.referralId : 0))
  );
  const [status, setStatus] = useState((student && student.status) || 1);
  const [branchOfficeId, setBranchOfficeId] = useState(
    (student && student.branchOfficeId) || 0
  );
  const [source, setSource] = useState((student && student.sourceId) || 0);
  const [origin, setOrigin] = useState(student ? student.originId : 0);
  const [channel, setChannel] = useState(student ? student.channelId : 0);
  const [campaign, setCampaign] = useState(student ? student.campaign : '');

  const handleClose = () => {
    setDialogOpen(false);
    cancel(false);
  };

  const [offices, setOffices] = useState([]);
  useEffect(() => {
    Agent.Cache.getBranchOffices().then(setOffices);
  }, []);

  const confirm = () => {
    //need to check first if agent has changed. if not stick to original value.
    //because if some users don't have access to assigned agents (for any reason), the system won't find the agent to pass it back to the caller and therefore will be null
    action({
      status,
      agentId: agentChanged
        ? agentRecord
          ? agentRecord.id
          : null
        : student
        ? student.agentId
        : null,
      agent: agentChanged
        ? agentRecord
          ? agentRecord.name
          : ''
        : student
        ? student.agentCompanyName
        : '',
      appUserDetailsId: adminRrecord ? adminRrecord.id : null,
      admin: adminRrecord ? adminRrecord.name : '',
      assessmentTeamId: assessmentRecord ? assessmentRecord.id : null,
      ReferralId: referralAssignId?.id ? referralAssignId?.id : null,
      assessment: assessmentRecord ? assessmentRecord.name : '',
      followupTeamId: followUpRecord ? followUpRecord.id : null,
      followUp: followUpRecord ? followUpRecord.name : '',
      complianceTeamId: complianceRecord ? complianceRecord.id : null,
      compliance: complianceRecord ? complianceRecord.name : '',
      complianceStatus: complianceStatus ? complianceStatus.id : null,
      branchOfficeId: branchOfficeId || null,
      branchOffice:
        branchOfficeId > 0
          ? offices.find(({ id }) => id == branchOfficeId).name
          : '',
      campaign,
      source,
      origin,
      channel,
      agentChanged,
      statusChanged,
      appUserDetailsChanged,
      assessmentTeamChanged,
      followupTeamChanged,
      referralChanged,
      complianceTeamChanged,
      branchOfficeChanged,
      sourceChanged,
      channelChanged,
      originChanged,
      campaignChanged,
      complianceStatusChanged,
      regionalManagerId: regionalManagerRecord
        ? regionalManagerRecord.id
        : null,
      regionalManager: regionalManagerRecord ? regionalManagerRecord.name : '',
      regionalManagerChanged,
      countryManagerId: countryManagerRecord ? countryManagerRecord.id : null,
      countryManager: countryManagerRecord ? countryManagerRecord.name : '',
      countryManagerChanged,
      branchManagerId: branchManagerRecord ? branchManagerRecord.id : null,
      branchManager: branchManagerRecord ? branchManagerRecord.name : '',
      branchManagerChanged,
      virtualTeamId: virtualTeamRecord ? virtualTeamRecord.id : null,
      virtualTeam: virtualTeamRecord ? virtualTeamRecord.name : '',
      virtualTeamChanged,
    });
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        open={dialogOpen}
        maxWidth="sm"
        fullWidth
        PaperComponent={PaperComponent}
      >
        <BootstrapDialogTitle onClose={handleClose} style={{ cursor: 'move' }}>
          <Typography align="center" color="secondary" variant="h6">
            {bulkUpdate ? 'Bulk Update' : student.name}
          </Typography>
          <Divider />
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid
            container
            direction="column"
            spacing={2}
            style={{ paddingTop: 4 }}
          >
            <Grid item container spacing={3}>
              <Grid item xs={2}>
                <Typography>Status</Typography>
              </Grid>
              <Grid item xs={10}>
                <Select
                  fullWidth
                  size="small"
                  native
                  label="Status"
                  value={status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setStatusChanged(true);
                    setReady(true);
                  }}
                >
                  {STUDENTSTATUS.filter((item) => item.display).map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.type}
                    </option>
                  ))}
                </Select>
              </Grid>
            </Grid>
            {offices.length > 0 && role.isSuperAdmin && (
              <>
                <Grid item>
                  <Select
                    fullWidth
                    size="small"
                    native
                    label="Branch Office"
                    value={branchOfficeId}
                    onChange={(e) => {
                      setBranchOfficeId(e.target.value);
                      setBranchOfficeChanged(true);
                      setReady(true);
                    }}
                  >
                    <option value={0}>--Pick a branch--</option>
                    {offices.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid item>
                  <Autocomplete
                    fullWidth
                    size="small"
                    open={openAgent}
                    onOpen={() => {
                      setOpenAgent(true);
                    }}
                    onClose={() => {
                      setOpenAgent(false);
                    }}
                    value={agentRecord}
                    onChange={(_, newVal) => {
                      setAgentRecord(newVal);
                      setReady(true);
                      setAgentChanged(true);
                    }}
                    getOptionLabel={(user) =>
                      `${user.name} ${
                        user && user.country
                          ? '(' +
                            Countries.find(({ id }) => id == user.country)
                              .name +
                            ')'
                          : ''
                      }`
                    }
                    renderOption={(props, option) => (
                      <li {...props}>
                        <Grid
                          container
                          direction="column"
                          sx={{
                            backgroundColor: (theme) => theme.palette.grey[200],
                            borderRadius: 1,
                            padding: 1,
                          }}
                          spacing={1}
                        >
                          <Grid item>
                            <Typography color="primary">
                              {option.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    )}
                    options={agents.filter((agent) => !agent.isDisabled)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Agent"
                        label="Agent"
                        InputProps={{
                          ...params.InputProps,
                          autoComplete: 'new-password',
                          endAdornment: <>{params.InputProps.endAdornment}</>,
                        }}
                      />
                    )}
                  />
                </Grid>
                {!role.isApplicationTeam && !role.isAdmin && (
                  <Grid item xs={12} sm={6} md={2}>
                    <FormControl fullWidth>
                      <Autocomplete
                        value={referralAssignId}
                        open={openReferral}
                        onOpen={() => {
                          setopenReferral(true);
                        }}
                        onClose={() => {
                          setopenReferral(false);
                        }}
                        onChange={(_, newVal) => {
                          setReferralAssignId(newVal);
                          setReferralChanged(true);
                          setReady(true);
                          setReferralReload(false);
                        }}
                        defaultValue={referralList.find(
                          ({ id }) => id == (student ? student.referralId : 0)
                        )}
                        options={referralList}
                        // className="w-56"
                        getOptionLabel={(referralList) =>
                          referralList.firstName + ' ' + referralList.lastName
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Referral"
                            variant="outlined"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}
              </>
            )}
             {!role.isApplicationTeam &&(<Grid item>
                <Autocomplete
                  fullWidth
                  size="small"
                  open={openAdmin}
                  onOpen={() => {
                    setOpenAdmin(true);
                  }}
                  onClose={() => {
                    setOpenAdmin(false);
                  }}
                  defaultValue={users.find(
                    ({ id }) => id == (student ? student.appUserDetailsId : 0)
                  )}
                  value={adminRrecord}
                  onChange={(_, newVal) => {
                    setAdminRecord(newVal);
                    setAppUserDetailsChanged(true);
                    setReady(true);
                  }}
                  getOptionLabel={(user) => `${user.name}`}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Grid
                        container
                        direction="column"
                        sx={{
                          backgroundColor: (theme) => theme.palette.grey[200],
                          borderRadius: 1,
                          padding: 1,
                        }}
                        spacing={1}
                      >
                        <Grid item>
                          <Typography color="primary">
                            {option.name}
                          </Typography>
                        </Grid>
                      </Grid>
                    </li>
                  )}
                  options={users}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Team Leader"
                      label="Team Leader"
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password',
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
                </Grid>)}
            {!role.isApplicationTeam && (
              <Grid item xs={12} sm={6} md={2}>
                <FormControl fullWidth>
                  <Autocomplete
                    value={complianceStatus}
                    open={openComplianceStatus}
                    onOpen={() => {
                      setopenComplianceStatus(true);
                    }}
                    onClose={() => {
                      setopenComplianceStatus(false);
                    }}
                    onChange={(_, newVal) => {
                      setComplianceStatus(newVal);
                      setComplianceStatusChanged(true);
                      setReady(true);
                    }}
                    defaultValue={COMPLIANCE_STATUS.find(
                      ({ id }) =>
                        id == (student ? student.complianceStatus : null)
                    )}
                    options={COMPLIANCE_STATUS}
                    // className="w-56"
                    getOptionLabel={(COMPLIANCE_STATUS) =>
                      COMPLIANCE_STATUS.type
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        label="Compliance Status"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            )}
            {!role.isApplicationTeam && !role.isAdmin && (
              <Grid item>
                <Autocomplete
                  fullWidth
                  size="small"
                  open={openRegManager}
                  onOpen={() => {
                    setopenRegManager(true);
                  }}
                  onClose={() => {
                    setopenRegManager(false);
                  }}
                  value={regionalManagerRecord}
                  onChange={(_, newVal) => {
                    setRegionalManagerRecord(newVal);
                    setRegionalManagerChanged(true);
                    setReady(true);
                  }}
                  getOptionLabel={(user) => `${user.name}`}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Grid
                        container
                        direction="column"
                        sx={{
                          backgroundColor: (theme) => theme.palette.grey[200],
                          borderRadius: 1,
                          padding: 1,
                        }}
                        spacing={1}
                      >
                        <Grid item>
                          <Typography color="primary">{option.name}</Typography>
                        </Grid>
                      </Grid>
                    </li>
                  )}
                  options={users.filter((item) => item.userType == 2)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Regional Manager"
                      label="Regional Manager"
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password',
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            {!role.isApplicationTeam && !role.isAdmin && (
              <Grid item>
                <Autocomplete
                  fullWidth
                  size="small"
                  open={openCountryManager}
                  onOpen={() => {
                    setopenCountryManager(true);
                  }}
                  onClose={() => {
                    setopenCountryManager(false);
                  }}
                  value={countryManagerRecord}
                  onChange={(_, newVal) => {
                    setCountryManagerRecord(newVal);
                    setCountryManagerChanged(true);
                    setReady(true);
                  }}
                  getOptionLabel={(user) => `${user.name}`}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Grid
                        container
                        direction="column"
                        sx={{
                          backgroundColor: (theme) => theme.palette.grey[200],
                          borderRadius: 1,
                          padding: 1,
                        }}
                        spacing={1}
                      >
                        <Grid item>
                          <Typography color="primary">{option.name}</Typography>
                        </Grid>
                      </Grid>
                    </li>
                  )}
                  options={users.filter((item) => item.userType == 2)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Country Manager"
                      label="Country Manager"
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password',
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {!role.isApplicationTeam && !role.isAdmin && (
              <Grid item>
                <Autocomplete
                  fullWidth
                  size="small"
                  open={openBranchManager}
                  onOpen={() => {
                    setopenBranchManager(true);
                  }}
                  onClose={() => {
                    setopenBranchManager(false);
                  }}
                  value={branchManagerRecord}
                  onChange={(_, newVal) => {
                    setBranchManagerRecord(newVal);
                    setBranchManagerChanged(true);
                    setReady(true);
                  }}
                  getOptionLabel={(user) => `${user.name}`}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Grid
                        container
                        direction="column"
                        sx={{
                          backgroundColor: (theme) => theme.palette.grey[200],
                          borderRadius: 1,
                          padding: 1,
                        }}
                        spacing={1}
                      >
                        <Grid item>
                          <Typography color="primary">{option.name}</Typography>
                        </Grid>
                      </Grid>
                    </li>
                  )}
                  options={users.filter((item) => item.userType == 2)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Branch Manager"
                      label="Branch Manager"
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password',
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {!role.isApplicationTeam && (
              <Grid item>
                <Autocomplete
                  fullWidth
                  size="small"
                  open={openAssess}
                  onOpen={() => {
                    setOpenAssess(true);
                  }}
                  onClose={() => {
                    setOpenAssess(false);
                  }}
                  value={assessmentRecord}
                  onChange={(_, newVal) => {
                    setAssessmentRecord(newVal);
                    setAssessmentTeamChanged(true);
                    setReady(true);
                  }}
                  getOptionLabel={(user) => `${user.name}`}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Grid
                        container
                        direction="column"
                        sx={{
                          backgroundColor: (theme) => theme.palette.grey[200],
                          borderRadius: 1,
                          padding: 1,
                        }}
                        spacing={1}
                      >
                        <Grid item>
                          <Typography color="primary">{option.name}</Typography>
                        </Grid>
                      </Grid>
                    </li>
                  )}
                  options={users}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Assessment Team Member"
                      label="Assessment"
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password',
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {!role.isApplicationTeam && (
              <Grid item>
                <Autocomplete
                  fullWidth
                  size="small"
                  open={openFollow}
                  onOpen={() => {
                    setOpenFollow(true);
                  }}
                  onClose={() => {
                    setOpenFollow(false);
                  }}
                  value={followUpRecord}
                  onChange={(_, newVal) => {
                    setFollowUpRecord(newVal);
                    setFollowupTeamChanged(true);
                    setReady(true);
                  }}
                  getOptionLabel={(user) => `${user.name}`}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Grid
                        container
                        direction="column"
                        sx={{
                          backgroundColor: (theme) => theme.palette.grey[200],
                          borderRadius: 1,
                          padding: 1,
                        }}
                        spacing={1}
                      >
                        <Grid item>
                          <Typography color="primary">{option.name}</Typography>
                        </Grid>
                      </Grid>
                    </li>
                  )}
                  options={users}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Follow Up Team Member"
                      label="Follow Up"
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'new-password',
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {!role.isApplicationTeam && (
              <Grid item>
                <Autocomplete
                  fullWidth
                  size="small"
                  open={openCom}
                  onOpen={() => {
                    setOpenCom(true);
                  }}
                  onClose={() => {
                    setOpenCom(false);
                  }}
                  value={complianceRecord}
                  onChange={(_, newVal) => {
                    setComplianceRecord(newVal);
                    setComplianceTeamChanged(true);
                    setReady(true);
                  }}
                  getOptionLabel={(user) => `${user.name}`}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <Grid
                        container
                        direction="column"
                        sx={{
                          backgroundColor: (theme) => theme.palette.grey[200],
                          borderRadius: 1,
                          padding: 1,
                        }}
                        spacing={1}
                      >
                        <Grid item>
                          <Typography color="primary">{option.name}</Typography>
                        </Grid>
                      </Grid>
                    </li>
                  )}
                  options={users}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Compliance Team Member"
                      label="Compliance Team Member"
                      InputProps={{
                        ...params.InputProps,
                        autoComplete: 'complaince',
                        endAdornment: <>{params.InputProps.endAdornment}</>,
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {role.isSuperAdmin ? (
              <>
                <Grid item>
                  <TextField
                    fullWidth
                    size="small"
                    label="Campaign & Referral"
                    value={campaign}
                    onChange={(e) => {
                      setCampaign(e.target.value);
                      setCampaignChanged(true);
                      setReady(true);
                    }}
                  />
                </Grid>
                <Grid item>
                  <Select
                    fullWidth
                    size="small"
                    native
                    label="Lead Source"
                    value={source}
                    onChange={(e) => {
                      setSource(e.target.value);
                      setSourceChanged(true);
                      setReady(true);
                    }}
                  >
                    <option value={0}>--Lead Source--</option>
                    {STUDENTSOURCES.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.type}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid item>
                  <Select
                    fullWidth
                    size="small"
                    native
                    label="Lead Origin"
                    value={origin}
                    onChange={(e) => {
                      setOrigin(e.target.value);
                      setOriginChanged(true);
                      setReady(true);
                    }}
                  >
                    <option value={0}>--Lead Origin--</option>
                    {LEADORIGINS.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.type}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid item>
                  <Select
                    fullWidth
                    size="small"
                    native
                    label="Lead Channel"
                    value={channel}
                    onChange={(e) => {
                      setChannel(e.target.value);
                      setChannelChanged(true);
                      setReady(true);
                    }}
                  >
                    <option value={0}>--Lead Channel--</option>
                    {LEADCHANNELS.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.type}
                      </option>
                    ))}
                  </Select>
                </Grid>
              </>
            ): (role.isAdmin||role.isSeniorAdmin) ? (<>
              <Grid item>
                  <Select
                    fullWidth
                    size="small"
                    native
                    label="Lead Origin"
                    value={origin}
                    onChange={(e) => {
                      setOrigin(e.target.value);
                      setOriginChanged(true);
                      setReady(true);
                    }}
                  >
                    <option value={0}>--Lead Origin--</option>
                    {LEADORIGINS.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.type}
                      </option>
                    ))}
                  </Select>
                </Grid>
            </>):''}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={!ready}
            autoFocus
            onClick={confirm}
            variant="contained"
            className="bg-sky-400 hover:bg-sky-500"
          >
            Confirm Selection
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};
