/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';

import {
  LicenseInfo,
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import IconButton from '@mui/material/IconButton';
import EmailIcon from '@mui/icons-material/Email';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import * as actions from '../../../store/actions/index';
import DeleteIcon from '@mui/icons-material/Delete';
// import { withRouter } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import { Countries, getAgentStatusNameFromNumber } from '../../../Util/Util';
import Agent from '../../../api/agent';
import LoadingView from '../../Common/Loading/LoadingView';
import MyTooltip from '../../Common/Tooltip/MyTooltip';
import {
  AGENTSTATUS,
  MUI_LICENSE_KEY,
  REFERRALSOURCE,
} from '../../../Util/Constants';
import { format } from 'date-fns';
import EditIcon from '@mui/icons-material/Edit';
import { ReferralsAssignment } from './ReferralsAssignment';
import ConfirmationModal from '../../Common/Modal/ConfirmationModal';
import Description from '@mui/icons-material/Description';
import ReferralNotes from './ReferralNotes';
import ReferralAddMessage from '../../Messages/ReferralAddMessage';
import { HiCheckCircle } from 'react-icons/hi';
import { RestoreTwoTone } from '@mui/icons-material';

function CustomToolbar(show) {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      {show && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}
const successDialogStyles = {
  backgroundColor: 'bg-green-400',
};
const errorDialogStyles = {
  backgroundColor: 'bg-red-400',
};

const ReferralList = ({
  isInternal,
  role,
  permissions,
  users,
  name,
  loadUsers,
  appUserDetailsId,
}) => {
  // const classes = useStyles();

  const [id, setId] = useState('');
  const [refName, setRefName] = useState('');
  const [email, setEmail] = useState('');
  const [refRemoveId, setRefRemoveId] = useState();
  const [status, setStatus] = useState(0);
  const [loading, setLoading] = useState(false);
  const [agentLoaded, setAgentLoaded] = useState(false);
  const [openAddMessage, setOpenAddMessage] = useState(false);
  const [openAddNote, setOpenAddNote] = useState(false);
  const [referral, setReferral] = useState();
  const [referralId, setReferralId] = useState();
  const [msgName, setMsgName] = useState();
  const [referralRows, setReferralRows] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [ready, setReady] = useState(false);
  const [agent, setAgent] = useState();
  const [agents, setAgents] = useState([]);
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [notes, setNotes] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isReset, setIsReset] = useState(false);

  console.log('messages parent', messages);

  const addMessage = (agentId, name) => {
    setAgent({ ...referral, id: agentId, companyName: name });
    setOpenAddMessage(true);
  };
  const referralNotes = (agentId, name) => {
    setAgent({ ...referral, id: agentId, companyName: name });
    setOpenAddNote(true);
  };
  const handleMessage = (row) => {
    addMessage(row.id, row.referralName);
    setReferralId(row.id);
    setMsgName(row.referralName);
    //setOpenAddMessage(true);
  };
  const handleNote = (row) => {
    referralNotes(row.id, row.referralName);
    setReferralId(row.id);
    setMsgName(row.referralName);
    //setOpenAddNote(true);
  };
  const handleRemove = (row) => {
    //console.log('row',row);
    setRefRemoveId(row.id);
    setOpenConfirm(true);
  };

  const deleteReferral = () => {
    Agent.Referral.deleteReferral(refRemoveId)
      .then(() => {
        setOpenConfirm(false);
        setSuccessOpen(true);
        setSuccessMessage('Delete Successfully');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getData = (filter = false) => {
    setLoading(true);
    Agent.Referral.ReferralList(getParams(filter)).then((records) => {
      getRows(records);
      console.log('records', records);
      setIsReset(false);
    });
  };

  console.log('msg referral', referral);
  const resetFilter = () => {
    setId('');
    setRefName('');
    setStatus(0)
    getData(false);
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();
    if(filter){
      params.append('Id', parseInt(id));
    }
    if(filter){
      params.append('Name', refName.trim());
    }
    if(filter){
      params.append('Status', status);
    }
    // if(filter){
    //   params.append('Email', email.trim());
    // }
    return params;
  };

  // useEffect(() => {
  //   if (isInternal) {
  //     if (users.length == 0) {
  //       loadUsers()
  //         .then(() => {
  //           //  getData();
  //         })
  //         .catch((error) => {
  //           console.log(error);
  //           debugger;
  //         });
  //     } else {
  //       getData();
  //     }
  //   } else {
  //     getData();
  //   }
  // }, [users]);

  const handleGetData=()=>{
    getData(true);
  }

  useEffect(() => {
    getData();
  }, [isReset]);

  useEffect(() => {
    if (referralId) {
      Agent.Referral.getreferralbyRefId(parseInt(referralId))
        .then((ref) => {
          var allNotes = ref.referralNotes.sort(function (a, b) {
            return new Date(b.dateSent) - new Date(a.dateSent);
          });
          setNotes(allNotes);
        })
        .catch((error) => {
          console.log(error);
        });
      Agent.Referral.getMessagebyRefId(parseInt(referralId))
        .then((ref) => {
          var allNotes = ref.referralNotes.sort(function (a, b) {
            return new Date(b.dateSent) - new Date(a.dateSent);
          });
          setNotes(allNotes);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [referralId]);

  useEffect(() => {
    if (referralId) {
      Agent.Referral.getMessagebyRefId(parseInt(referralId))
        .then((ref) => {
          var allMessages = ref.sort(function (a, b) {
            return new Date(b.dateSent) - new Date(a.dateSent);
          });
          setMessages(allMessages);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [openAddMessage]);

  const getRows = (rows) => {
    let refRows = [];
    rows.map((ref) => {
      refRows.push({
        id: ref.id,
        status: ref.status,
        statusDesc: getAgentStatusNameFromNumber(ref.status),
        created: format(new Date(ref.createdOn), 'dd/MM/yy'),
        parentId: ref.ParentId,
        adminId: ref.adminId,
        adminName: ref.adminName,
        businessDevAppUserDetailsId: ref.businessDevAppUserDetailsId,
        businessDevAppUserDetailsName:
          ref.businessDevAppUserDetailsId && users.length > 0
            ? users.find(({ id }) => id === ref.businessDevAppUserDetailsId)
                .name
            : '',
        referralName: ref.firstName + ' ' + ref.lastName,
        email: ref.email,
        applicationUpdateEmail: ref.applicationUpdateEmail,
        phoneNumber: ref.phoneNumber,
        isStudent: ref.isStudent,
        source: ref.source,
        sourceName: REFERRALSOURCE?.find(({ id }) => id == ref.source).type,
        student: ref.noOfStudents == 0 ? '' : ref.noOfStudents,
        nationality:
          ref.nationality && ref.nationality > 0
            ? Countries.find(({ id }) => id == ref.nationality).name
            : '',
      });
    });
    setReferralRows(refRows);
    setAgentLoaded(true);
    setLoading(false);
  };

  const actionSelectedUser = (record) => {
    const assignDto = {
      ReferralIds: [record.id],
      Source: parseInt(record.source),
      Status: parseInt(record.status),
      BusinessDevAppUserDetailsId: parseInt(record.businessDevAppUserDetailsId),
      SourceChanged: record.sourceChanged,
      StatusChanged: record.statusChanged,
      AdminChanged: record.AdminChanged,
      BusinessDevAppUserDetailsIdChanged:
        record.businessDevAppUserDetailsIdChanged,
      ParentId: record.parentId,
    };

    console.log('Assign DTO stund:', assignDto, record); // Console log the assignment data before making the API call

    Agent.Referral.assign({ ...record, ...assignDto })
      .then(() => {
        // if (bulkUpdate) {
        //   setReady(false);
        //   return;
        // }
        let temp = [];
        referralRows.map((item) => {
          if (item.id == referral.id) {
            item.id = record.id;
            item.status = record.status;
            (item.statusDesc = getAgentStatusNameFromNumber(record.status)),
              (item.sourceName = REFERRALSOURCE?.find(
                ({ id }) => id == record.source
              ).type);
            item.businessDevAppUserDetailsName =
              record.businessDevAppUserDetailsId && users.length > 0
                ? users.find(
                    ({ id }) => id === record.businessDevAppUserDetailsId
                  ).name
                : '';
          }
          temp.push(item);
          setSuccessMessage('Update Successfully');
          setSuccessOpen(true);
          setIsReset(true);
          console.log('item', item);
        });
        setReferralRows(temp);
        setReady(false);
        setReferral(null);
      })
      .catch((error) => {
        console.log(error);
        //setErrorMessage('Error occurred.');
        //setEvent(null);
        //setAdminAssignMode(false);
        //setAgentAssignMode(false);
        setReady(false);
      });
  };

  const showEditOptions = (row) => {
    setReferral(row);
    console.log('Row', row);
    console.log('agents.length', agents);
    if (users) {
      loadUsers()
        .then(() => {
          if (agents) {
            loadUsers()
              .then(() => {
                setReady(true);
              })
              .catch((error) => {
                console.log(error);
                debugger;
              });
          }
        })
        .catch((error) => {
          console.log(error);
          debugger;
        });
    } else {
      if (agents) {
        loadUsers()
          .then(() => {
            setReady(true);
          })
          .catch((error) => {
            console.log(error);
            debugger;
          });
      } else {
        setReady(true);
      }
    }
  };
  let columns = [
    {
      field: 'id',
      headerName: 'Id',
      width: 70,
      hide: true,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => params.value,
    },
    {
      field: 'referralName',
      headerName: 'Referral Name',
      hide: false,
      width: 200,
      renderCell: (params) => (
        <MyTooltip title={params.row.referralName} color="primary">
          <Typography className="clickable">
            {params.row.referralName}
          </Typography>
        </MyTooltip>
      ),
    },
    {
      field: 'nationality',
      headerName: 'Country',
      width: 150,
      hide: false,
    },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 160 },
    { field: 'email', headerName: 'Email', width: 220 ,hide: true},
    { field: 'created', headerName: 'Added On', width: 80, hide: true },
    {
      field: 'statusDesc',
      headerName: 'Status',
      width: 120,
      hide: false,
    },
    // { field: 'parentId', headerName: 'ParentId', width: 180, hide: true },
    { field: 'adminName', headerName: 'Admin', width: 180, hide: false },
    {
      field: 'businessDevAppUserDetailsName',
      headerName: 'BDM',
      width: 180,
      hide: false,
    },
    {
      field: 'isStudent',
      headerName: 'Student',
      hide: false,
      width: 100,
      align: 'center',
      renderCell: (params) => (
        <strong>
          {params.row.isStudent ? (
            <MyTooltip
              icon={<HiCheckCircle color="success" />}
              title="This Referral has Student User Also"
            >
              <IconButton size="large">
                <HiCheckCircle color="#03C988" />
              </IconButton>
            </MyTooltip>
          ) : null}
        </strong>
      ),
    },
    { field: 'sourceName', headerName: 'Source', width: 120, hide: false },
    {
      field: 'student',
      headerName: 'Student Count',
      width: 120,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <Grid container>
          <Grid item xs={4}>
            <Link to={`/students?referralId=${params.row.id}`}>
              {params.row.student}
            </Link>
          </Grid>
          {/* )} */}
          {isInternal && params.row.status == 5 && (
            <Grid item xs={8}>
              <strong>
                <MyTooltip
                  icon={<AddIcon color="success" />}
                  title="Add New Student"
                >
                  <IconButton
                    as={Link}
                    to={`/students/register/referraladdstudent/${params.row.id}`}
                    size="small"
                  >
                    <AddIcon color="success" />
                  </IconButton>
                </MyTooltip>
              </strong>
            </Grid>
          )}
        </Grid>
      ),
    },
    {
      field: 'message',
      headerName: 'Message',
      width: 130,
      hide: role.isApplicationTeam || role.isApplicationTeamLeader,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <strong>
          <Button
            disabled={role.isApplicationTeam || role.isApplicationTeamLeader}
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<EmailIcon />}
            onClick={() => handleMessage(params.row)}
          >
            Message
          </Button>
        </strong>
      ),
    },
    {
      field: 'edit',
      headerName: 'Edit',
      width: 70,
      hide: !isInternal,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <IconButton
          disabled={!isInternal}
          onClick={() => showEditOptions(params.row)}
          size="large"
        >
          <EditIcon />
        </IconButton>
      ),
    },
    {
      field: 'note',
      headerName: 'Note',
      width: 70,
      hide: !isInternal,
      // eslint-disable-next-line react/display-name
      renderCell: (params) => (
        <IconButton
          disabled={!isInternal}
          onClick={() => handleNote(params.row)}
          size="large"
        >
          <Description />
        </IconButton>
      ),
    },
    {
      field: 'remove',
      headerName: 'remove',
      width: 80,
      renderCell: (params) => (
        <IconButton
          disabled={!(role === 'SuperAdmin' || permissions.CanDeleteReferral)}
          onClick={() => handleRemove(params.row)}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return agentLoaded ? (
    <>
      <Dialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        aria-labelledby="success-dialog-title"
        PaperProps={{
          className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
        }}
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>{successMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        aria-labelledby="error-dialog-title"
        PaperProps={{
          className: errorDialogStyles.backgroundColor, // Apply errorDialogStyles here
        }}
      >
        <DialogTitle id="error-dialog-title">Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorMessage('')} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container direction="column" spacing={1}>
        <Grid item container spacing={1}>
          <Grid item>
            <TextField
              size="small"
              label="Referral Id"
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              size="small"
              label="Referral Name"
              value={refName}
              onChange={(e) => setRefName(e.target.value)}
            />
          </Grid>
          {/* <Grid item>
            <TextField
              size="small"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid> */}
          <Grid item>
            <FormControl>
              <InputLabel>Status</InputLabel>
              <Select
                size="small"
                native
                label="Status"
                placeholder="Status"
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                }}
              >
                <option value={0}>All</option>
                {role.isApplicationTeam || role.isApplicationTeamLeader
                  ? AGENTSTATUS.filter((item) => item.displayInApp).map(
                      (item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      )
                    )
                  : AGENTSTATUS.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.type}
                      </option>
                    ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Button
              onClick={handleGetData}
              variant="contained"
              className="bg-sky-700 hover:bg-sky-600"
            >
              Apply Filters
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={resetFilter}
              className="bg-teal-600 hover:bg-teal-500 text-white"
            >
              <RestoreTwoTone/>
              Reset Filters
            </Button>
          </Grid>
        </Grid>
        <Grid item style={{ height: 800, width: '100%' }}>
          <DataGridPro
            pagination
            rows={referralRows}
            columns={columns}
            loading={loading}
            density="compact"
            hideFooterSelectedRowCount
            onCellClick={(params, event) => {
              event.defaultMuiPrevented = true;
              if (params.colDef.field === 'referralName') {
                window.open(
                  `/referral/${params.row.id}/referralProfile`,
                  '_blank'
                );
              }
            }}
            components={{
              Toolbar: () =>
                CustomToolbar(permissions.CanDownloadData, isInternal),
            }}
          />
        </Grid>
      </Grid>

      {openAddMessage && (
        <ReferralAddMessage
          records={messages}
          open={openAddMessage}
          cancel={setOpenAddMessage}
          ReferralId={referralId} // message being sent from admin to an agent
          // toId={referralId}
          toName={msgName}
        />
      )}
      {ready && (
        <ReferralsAssignment
          users={users.filter((item) => item.isDisabled == false)}
          role={role}
          cancel={setReady}
          referral={referral}
          action={actionSelectedUser}
        />
      )}
      {openAddNote && (
        <ReferralNotes
          records={notes}
          setRecords={setNotes}
          open={openAddNote}
          cancel={setOpenAddNote}
          ReferralId={referralId} // message being sent from admin to an agent
          toId={referralId}
          toName={name}
        />
      )}
      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={deleteReferral}
        confirmText="Confirm Action"
        content="Are you sure that you would like to remove this Referral record completely?"
      />
    </>
  ) : (
    <LoadingView />
  );
};

const mapStateToProps = (state) => {
  return {
    isInternal: state.auth.isInternal,
    role: state.auth.role,
    name: state.auth.name,
    permissions: state.auth.permissions,
    users: state.users,
    appUserDetailsId: state.auth.appUserDetailsId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReferralList);
