/* eslint-disable jsx-a11y/no-autofocus */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { grey } from '@mui/material/colors';
import {
  Divider,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import RegisterContext from '../../../context/register-context';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function PaperComponent(props) {
  return <Paper {...props} />;
}

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SubjectSelection = ({
  departments,
  loadDepts,
  cancel,
  setSelectedSubjectsParents,
  goNext,
}) => {
  const registerContext = useContext(RegisterContext);
  const [dialogOpen, setDialogOpen] = useState(true);
  const [selectedSubjects, setSelectedSubjects] = useState(
    registerContext.subjects
  );
  const [depts, setDepts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState();

  useEffect(() => {
    if (departments.length == 0) {
      loadDepts()
        .then((list) => {
          setDepts(list.filter((d) => !d.isInactive));
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          debugger;
        });
    } else {
      setDepts(departments.filter((d) => !d.isInactive));
      setLoading(false);
    }
  }, [departments]);

  const selectSubject = (item) => {
    let temp = [];
    if (selectedSubjects.some((s) => s.id == item.id)) {
      temp = selectedSubjects.filter((c) => c.id != item.id);
    } else {
      if (selectedSubjects.length == 3) {
        return;
      }
      temp = selectedSubjects.concat(item);
    }

    setSelectedSubjects(temp);
    setSelectedSubjectsParents(temp);
    registerContext.setRegisterContext({
      ...registerContext,
      ...{
        subjects: temp,
      },
    });
  };

  const handleClose = () => {
    setDialogOpen(false);
    cancel(false);
  };

  const handleSearch = (val) => {
    setSearch(val);
    if (val.length == 0) {
      setDepts(departments);
    } else {
      let temp = [];
      departments.forEach((item) => {
        if (item.name.toLowerCase().includes(val.toLowerCase())) {
          temp.push(item);
        }
      });
      setDepts(temp);
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        open={dialogOpen}
        maxWidth="sm"
        fullWidth
        PaperComponent={PaperComponent}
      >
        <BootstrapDialogTitle>
          <TextField
            style={{
              backgroundColor: 'rgba(239, 242, 247)',
              borderRadius: '999px',
              marginBottom: 10,
              borderColor: 'transparent',
              borderWidth: 0,
              padding: 5,
            }}
            size="large"
            hiddenLabel
            fullWidth
            variant="standard"
            id="standard-start-adornment"
            placeholder="Search subjects..."
            autoComplete="new-password"
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="large" />
                </InputAdornment>
              ),
              endAdornment:
                search && search.length > 0 ? (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleSearch('')}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
            }}
          />
          <Divider />
        </BootstrapDialogTitle>
        <DialogContent>
          <Grid container direction="column">
            {depts.map((dept, index) => (
              <Grid item key={index} container>
                <Grid item xs={1}>
                  <IconButton onClick={() => selectSubject(dept)}>
                    {selectedSubjects.some((item) => item.id == dept.id) ? (
                      <CheckCircleIcon fontSize="small" color="primary" />
                    ) : (
                      <AddIcon
                        fontSize="small"
                        sx={{
                          backgroundColor: grey[200],
                          borderRadius: 50,
                        }}
                      />
                    )}
                  </IconButton>
                </Grid>
                <Grid
                  item
                  xs={8}
                  container
                  alignContent="center"
                  style={{ marginLeft: 15 }}
                >
                  <Typography>{dept.name}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent="space-between">
            <Grid item xs={5}>
              <Button autoFocus onClick={handleClose} variant="outlined">
                Close
              </Button>
            </Grid>
            <Grid item xs={3} container alignContent="center">
              <Typography>{`${selectedSubjects.length}/3`}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Button
                disabled={selectedSubjects.length == 0}
                autoFocus
                onClick={goNext}
                variant="contained"
                className='bg-sky-400 hover:bg-sky-500'
              >
                Confirm Selection
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    departments: state.departments,
    action: ownProps.action,
    cancel: ownProps.cancel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadDepts: () => dispatch(actions.loadDepts()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubjectSelection);
