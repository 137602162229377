import React, { useEffect, useState } from 'react';

import GeneralInfo from './GeneralInfo';
import SummaryInfo from './SummaryInfo';
import EducationInfo from './EducationInfo';
import LanguageInfo from './LanguageInfo';
import TopNavBar from './TopNavBar';
// eslint-disable-next-line import/namespace, import/default
import Agent from '../../../api/agent';
import { useNavigate, useParams } from 'react-router';
import MessageDisplay from '../LeadUtils/MessageDisplay/MessageDisplay ';
import SideNavgation from './SideNavigation';
import ConfirmConvertDialog from './ConfirmConvertDialog';
import Preferences from './Preferences';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import useDateFormatter from '../LeadUtils/CustomHooks/useDateFormatter';
import LeadMessages from './LeadMessages';
import Documents from './LeadDocuments/Documents';
import Chat from '../../Common/Chat/chat';
import LeadActivity from './LeadActivity';
import ExternalNotes from './ExternalNotes';
import LeadAccountNotes from './LeadAccountsNotes';
import PhoneAndEmails from './PhoneAndEmails';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import ConfirmationModal from '../../Common/Modal/ConfirmationModal';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
const LeadProfile = (user) => {

  const dispatch = useDispatch();
  const users = useSelector(state => state.users);
  const [assessmentTeam, setAssessmentTeam] = useState(0);
  const [teamLeader, setTeamLeader] = useState('');
  const [studentLeadsActivity, setStudentLeadsActivity] = useState([]);
  // const [users, setUsers] = useState([]);
  const [activeComponent, setActiveComponent] = useState('basicinfo');
  const [leadOrigin, setLeadOrigin] = useState(0);
  const [leadSource, setLeadSource] = useState(0);
  const [leadChannel, setLeadChannel] = useState(0);
  const [selectedLeadStatus, setSelectedLeadStatus] = useState(0);
  const [selectedLeadActivity, setSelectedLeadActivity] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [upfirstName, setFirstName] = useState('');
  const [upLastName, setLastName] = useState('');
  const [updatedEmail, setEmail] = useState('');
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [successMsgOpen, setSuccessMsgOpen] = useState(false);
  const [updateError, setUpdateError] = useState('');
  const [updatephonenumber, setPhoneNumber] = useState('');
  const [updatePassportNumber, setPassportNumber] = useState('');
  const [updateCitizenship, setCitizenship] = useState();
  const [updatedCountryOfRes, setUpdatedCountryOfRes] = useState();
  const [countryOfIssue, setCountryOfIssue] = useState();
  const [updatedMaritalStatus, setMaritalStatus] = useState();
  const [updatedGender, setUpdatedGender] = useState();
  const [updatedStreet, setUpdatedStreet] = useState('');
  const [upPlaceOfBirth, setPlaceOfBirth] = useState('');
  const currentDate = new Date().toISOString().split('T')[0];
  const [updatedDateOfBirth, setUpdatedDateOfBirth] = useState(null);
  const [updatedLeadEntryDate, setLeadEntryDate] = useState(currentDate);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [upexpiryDate, setExpiryDate] = useState(null);
  const [issueDate, setIssueDate] = useState(null);

  const [StudentLeadsNotes, setStudentLeadsNotes] = useState([]);
  const [LanguageRecordsStuLeads, setLanguage] = useState([]);
  const [EducationRecordsStuLeads, setEducationRecordsStuLeads] = useState([]);
  const [studentLeadCloudFiles, setStudentLeadCloudFiles] = useState([]);
  const [showConvertDialog, setShowConvertDialog] = useState(false);
  const [convertSuccess, setConvertSuccess] = useState(false);
  const [convertError, setConvertError] = useState('');
  const [serviceChargeStatus, SetServiceChargeStatus] = useState(0);
  const [additionalEmail1, setAdditionalEmail1] = useState(null);
  const [additionalEmail2, setAdditionalEmail2] = useState(null);
  const [additionalPhoneNumber, setadditionalPhoneNumber] = useState(null);
  const [disqualifiedReason, setDisqualifiedReason] = useState(0);
  const [interestedCourseName, setInterestedCourseName] = useState(null);
  const [leadListName, setleadListName] = useState('');
  const [branchOfficeId, setBranch] = useState(null);
  const [externalNotes, setExternalNotes] = useState([]);
  const [accountsNotes, setAccountsNotes] = useState([]);
  const [convertResponse, setConvertResponse] = useState({
    success: false,
    message: '',
  });
  const navigate = useNavigate();
  // const [showSummary, setShowSummary] = useState(false);
  const [showConvertButton, setShowConvertButton] = useState(false);
  const [studentId,setStudentId]=useState();

  const { id } = useParams();
  const numId = parseInt(id);

  const { formatDateToISO } = useDateFormatter(); // using Custom hooks for formating the date to ISO
  const handleInactive = () => {
    setOpenConfirm(true);
  };
  const handleDisable = () => {
    Agent.StudentLeads.disabledLead(numId).then((res)=>{
      setOpenConfirm(false);
      setSuccessMsgOpen(true);
      navigate('/Leads');
    })
  };
  // useEffect(() => {
  //   if(numId){
  //     Agent.StudentLeads.getStudentByLeadId(numId).then((res)=>{
  //       setStudentId(res.id);
  //       console.log('student:',res);
  //     });
  //   }
  //   Agent.Users.list()
  //     .then((res) => {
  //       setUsers(res);
  //     })
  //     .catch((err) => {
  //       console.log('err', err);
  //     });
  // }, []);


  useEffect(() => {
    if (numId) {
      Agent.StudentLeads.getStudentByLeadId(numId).then((res) => {
        setStudentId(res.id);
        console.log('student:', res);
      });
    }
    
    // Dispatch the loadUsers action instead of making a direct API call
    dispatch(actions.loadUsers());
  }, [numId, dispatch]);



  useEffect(() => {
    Agent.StudentLeads.getLeadProfile(numId)
      .then((res) => {
        //console.log('res', res);
        setFirstName(res.firstName);
        setLastName(res.lastName);
        setEmail(res.email);
        setPhoneNumber(res.phoneNumber);
        setPlaceOfBirth(res.placeOfBirth);
        setCitizenship(res.citizenship);
        setUpdatedCountryOfRes(res.countryOfResidence);
        setCountryOfIssue(res.countryOfIssue);
        setPassportNumber(res.passportNumber);
        setUpdatedGender(res.gender);
        setMaritalStatus(res.maritalStatus);
        setBranch(res.branchOfficeId);
        setSelectedLeadStatus(res.status);
        setSelectedLeadActivity(res.leadActivity);
        setUpdatedStreet(res.streetAddress);
        SetServiceChargeStatus(res.serviceChargeStatus);
        setAdditionalEmail1(res.additionalEmail1);
        setAdditionalEmail2(res.additionalEmail2);
        setadditionalPhoneNumber(res.additionalPhoneNumber);
        setDisqualifiedReason(res.disqualifiedReason);
        setInterestedCourseName(res.interestedCourseName);
        setleadListName(res.leadListName);
        setEducationRecordsStuLeads(res.educationRecordsStuLeads);
        setStudentLeadCloudFiles(res.studentLeadCloudFiles);
        setExternalNotes(res.studentLeadsPublicNotes);
        setAccountsNotes(res.accountsNotes);
        setTeamLeader(res.adminName);
        setAssessmentTeam(res.assessmentTeamId);
        // Checking if EducationRecordsStuLeads is empty and the selectedLeadStatus is 5
        if (res.educationRecordsStuLeads.length === 0) {
          setShowConvertButton(false); // Disable the "Convert" button
        } else {
          setShowConvertButton(true); // Enable the "Convert" butto
        }

        setLanguage(res.languageRecordsStuLeads);

        setStudentLeadsNotes(res.studentLeadsNotes);

        //console.log('PROPS lang', res.studentLeadsNotes);

        // console.log('PROPS lang', res.languageRecordsStuLeads);
        setUpdatedDateOfBirth(res.dateOfBirth);
        setExpiryDate(res.expiryDate);
        setIssueDate(res.issueDate);
        setLeadEntryDate(res.leadEntryDate);
        setLeadChannel(res.channel);
        //console.log('PROPS channel', res.channel);

        setLeadOrigin(res.origin);

        //console.log('PROPS origin', res.origin);
        setLeadSource(res.source);
        //console.log('PROPS source', res.source);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [numId]);

  const filteredStudentLeadsNotes = StudentLeadsNotes.filter((note) => {
    // Check if the 'content' property of the note is a string
    if (typeof note.content === 'string') {
      // Check if the 'content' property includes the system-generated message text
      return !note.content.includes(
        'New student lead file registered (system generated note)'
      );
    }
    // If 'content' is not a string, include the note in the filtered result
    return true;
  });

  const handleUpdateStudentProfile = async () => {
    // setReload(true);

    try {
      const formattedDateOfBirth = formatDateToISO(updatedDateOfBirth);
      const formattedExpiryDate = formatDateToISO(upexpiryDate);
      const formattedLeadEntryDate = formatDateToISO(updatedLeadEntryDate);
      const formattedIssueDate = formatDateToISO(issueDate);
      // const formattedLeadQualifiedDate = formatDateToISO(leadQualifiedDate);
      const status = typeof selectedLeadStatus === 'string' ? parseInt(selectedLeadStatus) : selectedLeadStatus;

      const payload = {
        SaveStudentLeadsDto: {
          StudentLead: {
            id: numId,
            email: updatedEmail,
            phoneNumber: updatephonenumber,
            firstName: upfirstName,
            lastName: upLastName,
            status: status,
            gender: updatedGender,
            maritalStatus: updatedMaritalStatus,
            citizenship: updateCitizenship,
            countryOfResidence: updatedCountryOfRes,
            countryOfIssue: countryOfIssue,
            placeOfBirth: upPlaceOfBirth,
            passportNumber: updatePassportNumber,
            dateOfBirth: formattedDateOfBirth,
            expiryDate: formattedExpiryDate,
            issueDate: formattedIssueDate,
            LeadEntryDate: formattedLeadEntryDate,
            // leadQualifiedDate: formattedLeadQualifiedDate,
            streetAddress: updatedStreet,
            branchOfficeId: branchOfficeId,
            additionalEmail1: additionalEmail1,
            additionalEmail2: additionalEmail2,
            additionalPhoneNumber: additionalPhoneNumber,
            disqualifiedReason: disqualifiedReason,
            interestedCourseName: interestedCourseName,
            LeadActivity: selectedLeadActivity,
            ServiceChargeStatus: serviceChargeStatus,
            leadListName: leadListName,
            EducationRecordsStuLeads,
            LanguageRecordsStuLeads,
            studentLeadsNotes: StudentLeadsNotes,
            accountsNotes: accountsNotes,
            source: leadSource,
            origin: leadOrigin,
            channel: leadChannel,
            studentLeadCloudFiles: studentLeadCloudFiles,
          },
        },
      };
      await Agent.StudentLeads.saveStudentUpdateLead(payload);
      console.log('Paylaod FOR UPDATE', payload);
      setUpdateSuccess(true);
      setUpdateError(''); // Clear any previous error messages
    } catch (error) {
      console.error('Error updating lead:', error);
      setUpdateError(error);
    }
  };

  const handleConvertAndSubmit = () => {
    handleConvertLead();
    //handleUpdateStudentProfile();
  };

  const handleConvertLead = async () => {
    try {
      await Agent.StudentLeads.convertLead(numId);
      setConvertResponse({
        success: true,
        message: 'Lead converted successfully, check the student section!',
      });
      setTimeout(() => {
        setConvertResponse({ success: false, message: '' });
        setShowConvertDialog(false);
      }, 4000);
    } catch (error) {
      console.error('Error converting lead:', error);
      setConvertResponse({
        success: false,
        message: error,
      });
      setConvertError(error);
    }
  };

  const handleConvertButtonClick = () => {
    setShowConfirmDialog(true);
  };

  const handleEducationChange = (index, field, value) => {
    const updatedEducationRecords = [...EducationRecordsStuLeads];

    // Convert the Expected Graduation date to ISO format
    if (field === 'expectedGraduation') {
      const isoDate = new Date(value).toISOString();
      updatedEducationRecords[index][field] = isoDate;
    } else {
      updatedEducationRecords[index][field] = value;
    }

    // Convert the Expected Graduation date to ISO format
    if (field === 'startOfStudies') {
      const isoDate = new Date(value).toISOString();
      updatedEducationRecords[index][field] = isoDate;
    } else {
      updatedEducationRecords[index][field] = value;
    }
    setEducationRecordsStuLeads(updatedEducationRecords);
    handleEduProfile();
  };



  useEffect(() => {
    Agent.StudentLeads.getLeadEmailAndPhone(numId)
      .then((res) => {
        console.log('res', res);

        //console.log('PROPS source', res.source);
      })
      .catch((error) => {
        setUpdateError('Error updating lead:', error);
        // setUpdateError(error);
      });
  }, [numId]);





  const handleAddEducationRecord = () => {
    const newRecord = {
      educationLevel: 0,
      country: 0,
      order: 0,
      studentLeadId: numId,
      degree: null,
      description: null,
      startOfStudies: null,
      expectedGraduation: null,
      studyLanguage: null,
      gpa: null,
      maximumGpa: null,
      PassingYear: null,
    };
    setEducationRecordsStuLeads([...EducationRecordsStuLeads, newRecord]);
  };

  const handleEduProfile = async () => {
    try {
      const payload = {
        SaveStudentLeadsDto: {
          StudentLead: {
            id: numId,
            EducationRecordsStuLeads,
          },
          saveMode: 1, // Use saveMode 1 to update the records
        },
      };
      await Agent.StudentLeads.saveStudentUpdateLead(payload);
      setUpdateSuccess(true);
    } catch (error) {
      console.error('Error updating education records:', error);
      setUpdateError(error);
    }
  };

  const handleLanguageChange = (index, field, value) => {
    const updatedLanguageRecords = [...LanguageRecordsStuLeads];

    // Convert the Expected Graduation date to ISO format
    if (field === 'examDate') {
      const isoDate = new Date(value).toISOString();
      updatedLanguageRecords[index][field] = isoDate;
    } else {
      updatedLanguageRecords[index][field] = value;
    }
    setLanguage(updatedLanguageRecords);
    handleLanguageProfile();
  };

  const handleLanguageProfile = async () => {
    try {
      const payload = {
        SaveStudentLeadsDto: {
          StudentLead: {
            id: numId,
            LanguageRecordsStuLeads,
          },
          saveMode: 2, // Use saveMode 2 to update the Lang records
        },
      };
      await Agent.StudentLeads.saveStudentUpdateLead(payload);
      console.log('languageTEST', payload);
      setUpdateSuccess(true);
    } catch (error) {
      console.error('Error updating language records:', error);
      setUpdateError(error);
    }
  };

  const handleAddLanguageRecord = () => {
    const newRecord = {
      qualification: 0,
      description: null,
      examDate: null,
      band: '',
      reading: 0,
      listening: 0,
      writing: 0,
      speaking: 0,
      order: 0,
      studentLeadId: numId,
    };
    setLanguage([...LanguageRecordsStuLeads, newRecord]);
  };

  return (
    <div>
      <TopNavBar
        upfirstName={upfirstName}
        upLastName={upLastName}
        updatedCountryOfRes={updatedCountryOfRes}
        updateCitizenship={updateCitizenship}
        assessmentTeam={assessmentTeam}
        teamLeader={teamLeader}
        branchOfficeId={branchOfficeId}
        selectedLeadStatus={selectedLeadStatus}
        setSelectedLeadStatus={setSelectedLeadStatus}
        selectedLeadActivity={selectedLeadActivity}
        setSelectedLeadActivity={setSelectedLeadActivity}
        showConvertButton={showConvertButton}
        setShowConvertButton={setShowConvertButton}
        handleConvertButtonClick={handleConvertButtonClick}
        EducationRecordsStuLeads={EducationRecordsStuLeads}
        users={users}
        //showConvertButton={showConvertButton}
        handleUpdateStudentProfile={handleUpdateStudentProfile}
      />
        {studentId && user.isInternal ?(<Grid item container justifyContent="flex-end" className='pt-1 pr-2'>                            
         <Button className="bg-gradient-to-r from-cyan-500 via-cyan-700 to-cyan-800 hover:bg-cyan-600 text-gray-100 drop-shadow-md hover:shadow-md">
            <Link to={`/students/${studentId}/manage`}>Go to Student Profile</Link>
          </Button>
        </Grid>):''}
      <div className="flex flex-col md:flex-row min-h-screen bg-sky-100 p-4">
        <div className="w-full md:w-1/5 pr-0 md:pr-4 mb-4 md:mb-0">
          <SideNavgation setActiveComponent={setActiveComponent} 
          studentLeadCloudFiles={studentLeadCloudFiles}
          LanguageRecordsStuLeads={LanguageRecordsStuLeads}
          EducationRecordsStuLeads={EducationRecordsStuLeads}
          />
        </div>

        <div className="w-full md:w-4/5 pl-0 md:pl-4">
          <div>
            <main className="bg-white p-8 rounded-md shadow-lg">
              {activeComponent === 'basicinfo' && (
                <GeneralInfo
                  upfirstName={upfirstName}
                  setFirstName={setFirstName}
                  upLastName={upLastName}
                  setLastName={setLastName}
                  updatedEmail={updatedEmail}
                  setEmail={setEmail}
                  updatephonenumber={updatephonenumber}
                  setPhoneNumber={setPhoneNumber}
                  upPlaceOfBirth={upPlaceOfBirth}
                  setPlaceOfBirth={setPlaceOfBirth}
                  updateCitizenship={updateCitizenship}
                  setCitizenship={setCitizenship}
                  updatedCountryOfRes={updatedCountryOfRes}
                  setUpdatedCountryOfRes={setUpdatedCountryOfRes}
                  countryOfIssue={countryOfIssue}
                  setCountryOfIssue={setCountryOfIssue}
                  updatePassportNumber={updatePassportNumber}
                  setPassportNumber={setPassportNumber}
                  updatedGender={updatedGender}
                  setUpdatedGender={setUpdatedGender}
                  maritalStatus={updatedMaritalStatus}
                  setMaritalStatus={setMaritalStatus}
                  updatedStreet={updatedStreet}
                  setUpdatedStreet={setUpdatedStreet}
                  serviceChargeStatus={serviceChargeStatus}
                  setServiceChargeStatus={SetServiceChargeStatus}
                  additionalEmail1={additionalEmail1}
                  setAdditionalEmail1={setAdditionalEmail1}
                  additionalEmail2={additionalEmail2}
                  setAdditionalEmail2={setAdditionalEmail2}
                  additionalPhoneNumber={additionalPhoneNumber}
                  setAdditionalPhoneNumber={setadditionalPhoneNumber}
                  disqualifiedReason={disqualifiedReason}
                  setDisqualifiedReason={setDisqualifiedReason}
                  interestedCourseName={interestedCourseName}
                  setInterestedCourseName={setInterestedCourseName}
                  leadListName={leadListName}
                  setLeadListName={setleadListName}
                  updatedDateOfBirth={updatedDateOfBirth}
                  setUpdatedDateOfBirth={setUpdatedDateOfBirth}
                  updatedLeadEntryDate={updatedLeadEntryDate}
                  setLeadEntryDate={setLeadEntryDate}
                  updateExpiryDate={upexpiryDate}
                  setExpiryDate={setExpiryDate}
                  issueDate={issueDate}
                  setIssueDate={setIssueDate}
                  leadOrigin={leadOrigin}
                  setLeadOrigin={setLeadOrigin}
                  leadSource={leadSource}
                  setLeadSource={setLeadSource}
                  leadChannel={leadChannel}
                  setLeadChannel={setLeadChannel}
                />
              )}

              {activeComponent === 'phoneAndEmailInfo' && (
                <PhoneAndEmails
                  Email={updatedEmail}
                  setEmail={setEmail}
                  PhoneNumber={updatephonenumber}
                  setPhoneNumber={setPhoneNumber}
                  AdditionalEmail1={additionalEmail1}
                  setAdditionalEmail1={setAdditionalEmail1}
                  AdditionalEmail2={additionalEmail2}
                  setAdditionalEmail2={setAdditionalEmail2}
                  AdditionalPhoneNumber={additionalPhoneNumber}
                  setAdditionalPhoneNumber={setadditionalPhoneNumber}
                  leadId={numId}
                />
              )}
              {activeComponent === 'eduinfo' && (
                <EducationInfo
                  educationRecords={EducationRecordsStuLeads}
                  handleEducationChange={handleEducationChange}
                  handleAddEducationRecord={handleAddEducationRecord}
                />
              )}

              {activeComponent === 'languageInfo' && (
                <LanguageInfo
                  languageRecords={LanguageRecordsStuLeads}
                  handleLanguageChange={handleLanguageChange}
                  handleAddLanguageRecord={handleAddLanguageRecord}
                />
              )}

              {activeComponent === 'destinationInfo' && (
                <Preferences studentLeadId={numId} />
              )}

              {activeComponent === 'leadMessages' && (
                <LeadMessages
                  studentLeadId={numId}
                  studentLeadName={upfirstName}
                  users={users}
                  messageRecords={filteredStudentLeadsNotes.filter(
                    (item) => item.noteType == 1
                  )}
                  handleAddSummaryRecord={setStudentLeadsNotes}
                  setStudentLeadsNotes={setStudentLeadsNotes}
                />
              )}

              {activeComponent === 'summaryinfo' && (
                <SummaryInfo
                  handleAddSummaryRecord={setStudentLeadsNotes}
                  leadId={numId}
                  users={users}
                />
              )}

              {activeComponent === 'documents' && (
                <Documents
                  studentLeadId={numId}
                  studentLeadCloudFiles={studentLeadCloudFiles}
                  // profileCompleteStatus={profileCompleteStatus}
                  // permissions={permissions}
                />
              )}

              {activeComponent === 'chats' &&
                user.appUserId > 0 &&
                user.name && (
                  <Chat
                    recordId={numId}
                    recipientName={`${upfirstName} ${upLastName}`}
                    phone={updatephonenumber}
                    type="lead"
                    country={updatedCountryOfRes || updateCitizenship || 76}
                  />
                )}

              {activeComponent === 'activity' && (
                <LeadActivity
                  studentLeadsActivity={studentLeadsActivity}
                  leadId={numId}
                  users={users}
                />
              )}
              {activeComponent === 'externalNotes' && (
                <ExternalNotes
                  summmaryNoteRecords={externalNotes}
                  leadId={numId}
                />
              )}
              {activeComponent === 'accountsNotes' && (
                <LeadAccountNotes
                  summmaryNoteRecords={accountsNotes.filter(
                    (item) => item.noteType == 2
                  )}
                  handleAddSummaryRecord={setAccountsNotes}
                  leadId={numId}
                  users={users}
                />
              )}
               {user.role.isSuperAdmin && activeComponent == 'basicinfo' ? 
               <div className='flex justify-between'>
                <div className="flex justify-start mt-0">
                   <button
                   onClick={handleInactive}
                   size="small"
                 >
                   <Typography className='bg-red-800 hover:bg-red-700 px-4 py-1 rounded-md font-semibold text-gray-100 text-lg'>Disabled</Typography>
                 </button>
                 </div>
                  <div className="flex justify-end mb-8 mt-4">
                    <button
                      type="button"
                      className="min-w-fit py-2 px-4 bg-indigo-500 hover:bg-indigo-600 focus:ring-indigo-400 focus:ring-offset-indigo-200 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                      onClick={handleUpdateStudentProfile}
                    >
                      Update Profile
                    </button>
                  </div>
                </div> :
                <>
                { activeComponent !== 'documents' &&
                  // activeComponent !== 'summaryinfo' &&
                  activeComponent !== 'externalNotes' &&
                  // activeComponent !== 'accountsNotes' &&
                  activeComponent !== 'leadMessages' &&
                  activeComponent !== 'phoneAndEmailInfo' &&
                  activeComponent !== 'destinationInfo' &&
                  activeComponent !== 'activity' &&
                  activeComponent !== 'chats' && (
                    <div className="flex justify-end mb-8 mt-4">
                      <button
                        type="button"
                        className="min-w-fit py-2 px-4 bg-indigo-500 hover:bg-indigo-600 focus:ring-indigo-400 focus:ring-offset-indigo-200 text-white font-semibold rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                        onClick={handleUpdateStudentProfile}
                      >
                        Update Profile
                      </button>
                    </div>
                  )} </>
                }
              {successMsgOpen && (
                <MessageDisplay
                  type="success"
                  message="Lead Disabled successfully!"
                  duration={3000}
                  onClose={() => setSuccessMsgOpen(false)}
                />
              )}
              {updateSuccess && (
                <MessageDisplay
                  type="success"
                  message="Profile updated successfully!"
                  duration={3000}
                  onClose={() => setUpdateSuccess(false)}
                />
              )}
              {updateSuccess && (
                <MessageDisplay
                  type="success"
                  message="Profile updated successfully!"
                  duration={3000}
                  onClose={() => setUpdateSuccess(false)}
                />
              )}

              {updateError && (
                <MessageDisplay
                  type="error"
                  message={`Failed to update lead` + updateError}
                  duration={5000}
                  onClose={() => setUpdateError('')}
                />
              )}

              {convertSuccess && (
                <MessageDisplay
                  type="success"
                  message="Lead converted successfully!"
                  duration={4000}
                  onClose={() => setConvertSuccess(false)}
                />
              )}

              {convertError && (
                <MessageDisplay
                  type="error"
                  message={`Failed to convert lead` + convertError}
                  duration={5000}
                  onClose={() => setConvertError('')}
                />
              )}

              <ConfirmationModal
                open={openConfirm}
                cancel={setOpenConfirm}
                confirm={handleDisable}
                confirmText="Confirm Action"
                content="Are you sure, you would like to diabled this announcement!!"
              />
              <ConfirmConvertDialog
                showConfirmDialog={showConfirmDialog}
                setShowConfirmDialog={setShowConfirmDialog}
                handleConvertAndSubmit={handleConvertAndSubmit}
                convertResponse={convertResponse}
              />
            </main>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users,
    appUserId: state.auth.id,
    appUserDetailsId: state.auth.appUserDetailsId,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
    departments: state.departments,
    name: state.auth.name,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LeadProfile);
