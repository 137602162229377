/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import Appointment from '../../../Appointments/Appointment';
import AddIcon from '@mui/icons-material/Add';
import Agent from '../../../../api/agent';
import { APPOINTMENTSTATUS, MUI_LICENSE_KEY } from '../../../../Util/Constants';
import { LicenseInfo, DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import { format, addMinutes } from 'date-fns';
import { connect } from 'react-redux';
import { Countries } from '../../../../Util/Util';
import ProfileHeader from '../../common/profileHeader';
import LoadingView from '../../../Common/Loading/LoadingView';

const AppointmentList = ({ isInternal, role }) => {
  const [addMode, setAddMode] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Agent.Appointment.get()
      .then((records) => {
        setAppointments(records);
        setLoading(false);
      })
      .catch((error) => {
        debugger;
        console.log(error);
      });
  }, []);

  const handleAppointmentUpdate = (appt) => {
    setAddMode(false);
    let newItem = true;
    let temp = [];
    appointments.forEach((item) => {
      if (item.id == appt.id) {
        temp.push(appt);
        newItem = false;
      } else {
        temp.push(item);
      }
    });
    if (newItem) {
      temp.unshift(appt);
    }
    setAppointments(temp);
  };

  const addRecord = (record) => {
    if (record.id == 0 && role.isStudent) {
      Agent.Students.getStudent(0)
        .then((std) => {
          let stu = {
            studentId: std.id,
            name: std.firstName + ' ' + std.lastName,
            countryOfResidence: std.countryOfResidence,
            email: std.email,
          };
          setAppointment({ ...record, ...stu });
          setAddMode(true);
        })
        .catch((error) => {
          debugger;
          console.log(error);
        });
    } else {
      setAppointment(record);
      setAddMode(true);
    }
  };

  const editRecord = (row) => {
    let temp = { ...appointment, ...row };
    setAppointment(temp);
    setAddMode(true);
  };

  return (
    <>
      {role.isStudent && (
        <div className="w-full max-w-[1400px] pt-2">
          <div className="pb-5">
            <ProfileHeader
              studentFirstName={''}
              subTitle="View all your appointments here"
            />
          </div>
          <div className="w-full text-xl text-gray-700 shadow-xl font-semibold studentSidebarBgGradient1 rounded-lg p-4 cursor-default">
            Appointments Page
          </div>
          {/* APPOINTMENTS TABLE */}
          {loading ? (
            <div className="pb-10">
              <LoadingView />
            </div>
          ) : (
            <div className="p-6 px-2 bg-gray-200  mt-6 rounded-lg shadow-xl">
              <div className="overflow-x-auto app-scrollbar min-h-[400px]">
                <div className="w-full min-w-max ">
                  <div className="flex gap-1 m- p-2 h-12 w-full text-lg font-semibold px-2 ">
                    <div className={`w-[100px] `}>ID</div>
                    <div className={`w-[200px]`}>TCL Admin</div>
                    <div className={`w-[150px]`}>Status</div>
                    <div className={`w-[220px]`}>Date - Time</div>
                    <div className={`w-[150px]`}>Country</div>
                    <div className={`w-[300px] lg:w-[400px]`}>Subject</div>
                  </div>
                  {appointments.map((item, idx) => {
                    return (
                      <div key={idx} onClick={() => editRecord(item)}>
                        <div className="flex items-center gap-1 bg-white text-gray-700 rounded-lg p-2 my-2 h-12  hover:bg-sky-400 hover:text-white cursor-pointer transition-all duration-100 text-base ">
                          <div className={`w-[100px] truncate px-2`}>
                            {item.id}
                          </div>
                          <div className={`w-[200px] truncate`}>
                            {item.adminName}
                          </div>
                          <div className={`w-[150px]`}>
                            {
                              APPOINTMENTSTATUS.find(
                                ({ id }) => id == item.status
                              ).type
                            }
                          </div>
                          <div className={`w-[220px]`}>
                            {format(
                              addMinutes(
                                new Date(item.appointmentOn),
                                -new Date().getTimezoneOffset()
                              ),
                              'MMMM d, yyyy h:mm a'
                            )}
                          </div>
                          <div className={`w-[150px]`}>
                            {item.countryOfResidence
                              ? Countries.find(
                                  ({ id }) => id == item.countryOfResidence
                                ).name
                              : ''}
                          </div>
                          <div
                            className={`w-[300px] lg:w-[400px] xl:w-[440px] truncate`}
                          >
                            {item.subject}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {addMode && (
        <Appointment
          record={appointment}
          setRecordParent={handleAppointmentUpdate}
          setAddMode={setAddMode}
          id={0}
        />
      )}
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isInternal: state.auth.isInternal,
    role: state.auth.role,
  };
};

export default connect(mapStateToProps, null)(AppointmentList);
