import React, { useEffect, useState } from 'react';

import {
  Grid,
  Fab,
  Autocomplete,
  TextField,
  CircularProgress,
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import useInput from '../../../../Common/Form/use-input';
import MyInput from '../../../../Common/Form/MyInput';
import { connect } from 'react-redux';
import * as actions from '../../../../../store/actions/index';
import MyDateInput from '../../../../Common/Form/MyDateInput';

const isOptional = () => true;
const isNotEmptyTrim = (value) => value && value.trim() !== '';
const isNotEmpty = (value) => value && value !== '';
const isNotEmptyNum = (value) => value && value > 0;
const isEmail = (value) => !value || value.includes('@');
const isDateOptional = (value) => !value || Date.parse(value);

const WorkDetails = ({
  workDetails,
  setWorkDetails,
  managers,
  setManagers,
  saveInParent,
  users,
  loadUsers,
  submitting,
}) => {
  const [saving, setSaving] = useState(false);
  const [managerUsers, setManagerUsers] = useState([]);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    if (users.length == 0) {
      loadUsers()
        .then((usrs) => {
          setManagerUsers(
            usrs.filter(
              (user) => user.isDisabled == 0 && managers.includes(user.id)
            )
          );
        })
        .catch((error) => {
          console.log(error);
          debugger;
        });
    } else {
      setManagerUsers(users.filter((user) => managers.includes(user.id)));
    }
  }, [users]);

  const onBlurHandler = (name, value) => {
    setChanged(true);
    workDetails = {
      ...workDetails,
      ...{
        [name]: value,
      },
    };
    setWorkDetails(workDetails);
  };
  const { obj: emailAddress } = useInput(
    workDetails.emailAddress,
    isEmail,
    onBlurHandler
  );
  const { obj: phoneNumber } = useInput(
    workDetails.phoneNumber,
    isOptional,
    onBlurHandler
  );
  const { obj: skills } = useInput(
    workDetails.skills,
    isOptional,
    onBlurHandler
  );
  const { obj: jobTitle } = useInput(
    workDetails.jobTitle,
    isOptional,
    onBlurHandler
  );
  const { obj: dateJoined } = useInput(
    workDetails.dateJoined,
    isDateOptional,
    onBlurHandler
  );
  const { obj: dateResigned } = useInput(
    workDetails.dateResigned,
    isDateOptional,
    onBlurHandler
  );
  const { obj: dateLeft } = useInput(
    workDetails.dateLeft,
    isDateOptional,
    onBlurHandler
  );
  const save = () => {
    saveInParent(
      { ...workDetails, ...{ managers: managerUsers.map((user) => user.id) } },
      3
    ).then(() => {
      setChanged(false);
    });
  };

  return (
    <Grid container direction={'column'} spacing={1} xs={10}>
      <Grid item>
        <MyInput
          name="jobTitle"
          label="Job Title"
          value={jobTitle.value}
          handleChange={jobTitle.valueChangeHandler}
          handleBlur={jobTitle.valueInputBlurHandler}
          errorMessage="Job title must be valid"
          isInvalid={jobTitle.hasError}
          required
          inputProps={{
            maxLength: 100,
          }}
        />
      </Grid>
      {users && users.length > 0 && (
        <Grid item style={{ paddingTop: 0 }}>
          <Autocomplete
            multiple
            id="tags-outlined"
            options={users}
            getOptionLabel={(option) => `${option.name}`}
            value={managerUsers}
            onChange={(_, newVal) => {
              setManagerUsers(newVal);
              setChanged(true);
            }}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                label="Line Managers"
                placeholder="Select Line Manager"
              />
            )}
          />
        </Grid>
      )}
      <Grid item>
        <MyInput
          name="emailAddress"
          label="Work Email"
          value={emailAddress.value}
          handleChange={emailAddress.valueChangeHandler}
          handleBlur={emailAddress.valueInputBlurHandler}
          errorMessage="Email must be both valid"
          isInvalid={emailAddress.hasError}
          required
          inputProps={{
            maxLength: 100,
          }}
        />
      </Grid>
      <Grid item style={{ paddingTop: 0 }}>
        <MyInput
          name="phoneNumber"
          label="Phone Number"
          value={phoneNumber.value}
          handleChange={phoneNumber.valueChangeHandler}
          handleBlur={phoneNumber.valueInputBlurHandler}
          errorMessage="Phone number must be valid"
          isInvalid={phoneNumber.hasError}
          required
          inputProps={{
            maxLength: 25,
          }}
        />
      </Grid>
      <Grid item style={{ paddingTop: 0 }}>
        <MyInput
          name="skills"
          label="Skills"
          value={skills.value}
          handleChange={skills.valueChangeHandler}
          handleBlur={skills.valueInputBlurHandler}
          errorMessage="Skills must be valid"
          isInvalid={skills.hasError}
          rows={2}
          inputProps={{
            maxLength: 250,
          }}
        />
      </Grid>
      <Grid item style={{ paddingTop: 0 }}>
        <MyDateInput
          name="dateJoined"
          label="Date Joined"
          value={dateJoined.value}
          handleChange={dateJoined.valueChangeHandler}
          handleBlur={onBlurHandler}
          isInvalid={dateJoined.hasError}
          errorMessage="Date of birth is invalid"
          required={true}
        />
      </Grid>
      <Grid item>
        <MyDateInput
          name="dateResigned"
          label="Date Resigned"
          value={dateResigned.value}
          handleChange={dateResigned.valueChangeHandler}
          handleBlur={onBlurHandler}
          isInvalid={dateResigned.hasError}
          errorMessage="Date of resignation is invalid"
          required={true}
        />
      </Grid>
      <Grid item>
        <MyDateInput
          name="dateLeft"
          label="Date Left"
          value={dateLeft.value}
          handleChange={dateLeft.valueChangeHandler}
          handleBlur={onBlurHandler}
          isInvalid={dateLeft.hasError}
          errorMessage="Date of leaving is invalid"
          required={true}
        />
      </Grid>
      <Grid item container>
        <Grid item>
          <Fab
            size="small"
            variant="extended"
            color="primary"
            style={{ marginRight: 10 }}
            onClick={save}
            disabled={submitting || !changed}
          >
            <DoneIcon />
            {submitting && (
              <>
                <CircularProgress size={14} color="primary" />
                <span style={{ marginLeft: 5 }}>Saving</span>
              </>
            )}
            {!submitting && 'Save'}
          </Fab>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    users: state.users,
    role: state.auth.role,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
    isInternal: state.auth.isInternal,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(WorkDetails);
