/* eslint-disable react/react-in-jsx-scope */
import { CampaignOutlined } from '@mui/icons-material';
import AnnouncementCard from './AnnouncementCard';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { Announcement_TYPE } from '../../../Util/Constants';
import { useEffect, useState } from 'react';
import Agent from '../../../api/agent';

const ShowAnnouncementList = ({ announcementList, appUserDetails, role }) => {
  const roleToUserTypeId = (role) => {
    if (role.isSuperAdmin) return 1;
    else if (role.isSeniorAdmin) return 2;
    else if (role.isAdmin) return 3;
    else if (role.isAgentStaff) return 4;
    else if (role.isAgent) return 5;
    else if (role.isStudent) return 6;
    else if (role.isApplicationTeam) return 7;
    else if (role.isApplicationTeamLeader) return 8;
    else if (role.isReferral) return 10;
    else
    return 0;
  };
  const userTypeId = roleToUserTypeId(role);
  const [announcementTypeList, setAnnouncementTypeList] = useState([]);
  const currentDate = new Date();
  const listItems = [];
    announcementList.map(
      (announcement,index) =>{ 
          announcement.announcementAssignments.filter((assign)=>{
          if(assign.userTypeId ===userTypeId && 
            new Date(announcement.expiryDate) >= currentDate){
            listItems.push(index);
          } 
        })
       
      }
   );
   useEffect(()=>{
    Agent.Announcement.getAnnouncementType().then((res)=>{
     const temp= res.sort(function (a, b) {
      if (a.id < b.id) {
        return -1;
      }
      if (a.id > b.id) {
        return 1;
      }
      return 0;
    })
      setAnnouncementTypeList(temp);
    })
  },[]);
  const valueGetter=(value)=>{
    for(const val in listItems) {

      if (listItems.hasOwnProperty.call(listItems, val)) {
        const element = listItems[val];
        if (value==element) {
          return announcementList[value]
        }
      }
    }
   }
   const filtered_announcementList=announcementList.filter((announce,index)=>{
      return valueGetter(index)
   })
  //  const valueGetter=(stu_value)=>{
  //   for(const val in temp_studentLead_Status) {
  //     if (temp_studentLead_Status.hasOwnProperty.call(temp_studentLead_Status, val)) {
  //       const element = temp_studentLead_Status[val];
  //       if (stu_value==element) {
  //         return stu_value
  //       }
  //     }
  //   }
  //  }
  //  const filtered_StudentLead_Status=StudentLead_Status.filter(stu=>{
  //     return valueGetter(stu.id)
  //  })

  const hasAnnouncements = filtered_announcementList?.length > 0;
 
  return (
    <div className="w-full rounded-lg overflow-hidden my-1">
      <div className="flex flex-row justify-center items-center mb-2">
        <CampaignOutlined
          className={`text-5xl text-sky-500 mr-2 ${
            hasAnnouncements ? 'animate-pulse' : ''
          }`}
        />
        <h2 className="text-3xl font-bold text-slate-700">Announcements</h2>
      </div>

      {hasAnnouncements ? (
        <div className="space-y-4">
          {filtered_announcementList.filter((item) => !item.isInactive).map((announcement, index) => (
            <AnnouncementCard
              key={index}
              type={announcementTypeList.find((item)=>item.id==announcement.announcementTypeId).name}
              title={announcement.title}
              content={announcement.content}
              publishDate={announcement.publishDate}
            />
          ))}
        </div>
      ) : (
        <p className="text-gray-500 text-center">No announcement right now.</p>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    appUserDetails: state.auth.appUserDetails,
    role: state.auth.role,
    roleName: state.auth.roleName,
    isInternal: state.auth.isInternal,
    name: state.auth.name,
    permissions: state.auth.permissions,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateAgent: (count) => dispatch(actions.updateAgent(count)),
    updateStudent: (count) => dispatch(actions.updateStudent(count)),
    updateApplication: (count) => dispatch(actions.updateApplication(count)),
    updateAdmin: (count) => dispatch(actions.updateAdmin(count)),
    loadUsers: () => dispatch(actions.loadUsers()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowAnnouncementList);