import {
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
  } from '@mui/material';
  import { styled } from '@mui/material/styles';
  import ReplyIcon from '@mui/icons-material/Reply';
  import React, { useState, useEffect } from 'react';
  import { format, addMinutes } from 'date-fns';
  
  import parse from 'html-react-parser';
  import EditorDraftJs from '../../Common/Editor/EditorDraftJs';
  import Agent from '../../../api/agent';
  import { LoadingButton } from '@mui/lab';
  import Tooltip from '@mui/material/Tooltip';
  const PREFIX = 'Conversations';
  
  const classes = {
    header: `${PREFIX}-header`,
    content: `${PREFIX}-content`,
  };
  
  const StyledGrid = styled(Grid)(({ theme }) => ({
    [`& .${classes.header}`]: {
      backgroundColor: theme.palette.primary.light,
      color: 'white',
      padding: 5,
      borderTopRightRadius: 5,
      borderTopLeftRadius: 5,
    },
  
    [`& .${classes.content}`]: {
      paddingLeft: 5,
    },
  }));
  
  const GreenCheckbox = (props) => <Checkbox color="default" {...props} />;
  
  const AgentConversations = ({
    messages,
    mainMessageSubject = '',
    applicationId = 0,
    user,
    setNoteCount,
    setSendEmailParent,
    hideReplyOption = false,
  }) => {
    const [replyMode, setReplyMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reply, setReply] = useState({});
    const [editorStateValid, setEditorStateValid] = useState(false);
    const [allMessages, setAllMessages] = useState([]);
  
    const [sendEmail, setSendEmail] = useState(false);
  
    const editorOnChange = (rawHtml, valid) => {
      setReply({ ...reply, content: rawHtml });
      setEditorStateValid(valid);
    };
    console.log('allMessages',allMessages);
    useEffect(() => {
      setAllMessages(messages);
    }, [messages]);
  
    const showReplyOption = (message) => {
      setReplyMode(true);
      // need to work out who this is replying to
      let to = '';
      allMessages.map((msg) => {
        to=msg.appUserDetails.firstName +' ' +msg.appUserDetails.lastName;
        // if (msg.appUserDetails.firstName +' ' +msg.appUserDetails.lastName!= user) {
        //   to = msg.appUserDetails.firstName +' ' +msg.appUserDetails.lastNam;
        //   return;
        // }
        // if (msg.to != user) {
        //   to = msg.to;
        //   return;
        // }
      });
      setReply({
        from: user,
        to: to,
        subject: mainMessageSubject,
        toId: message.fromId,
        messageId: message.messageId,
        applicationId: applicationId,
        dateSent: format(
          addMinutes(new Date(), new Date().getTimezoneOffset()),
          'MMMM d, yyyy h:mm a'
        ),
      });
    };
    const sendReply = () => {
      setLoading(true);
      var replyMsg = { ...reply, sendEmail: sendEmail };
      Agent.Messages.add(replyMsg)
        .then(() => {
          setLoading(false);
          setReplyMode(false);
          let temp = [];
          replyMsg = {
            ...reply,
            ...{
              sendEmail: sendEmail,
              dateSent: format(
                addMinutes(new Date(), new Date().getTimezoneOffset()),
                'MMMM d, yyyy h:mm a'
              ),
            },
          };
          debugger;
          temp.push(replyMsg);
          allMessages.map((item) => temp.push(item));
          setAllMessages(temp);
          if (setNoteCount) {
            setNoteCount(temp.length);
          }
        })
        .catch((error) => {
          console.log(error);
          debugger;
          setLoading(false);
        });
    };
    return (
      <StyledGrid container direction="column">
        {replyMode && (
          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <EditorDraftJs editorOnChange={editorOnChange} height={100} />
            </Grid>
            {setSendEmailParent && (
              <Grid item>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={sendEmail}
                      onChange={() => {
                        setSendEmail((prev) => !prev);
                        setSendEmailParent((prev) => !prev);
                      }}
                    />
                  }
                  label="Click to confirm you want to send an email with this note as well"
                />
              </Grid>
            )}
  
            <Grid item container>
              <Grid item xs={6}>
                <Button onClick={() => setReplyMode(false)}>Cancel</Button>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end">
                <LoadingButton
                  loading={loading}
                  onClick={sendReply}
                  disabled={!editorStateValid}
                  variant="outlined"
                >
                  Send Reply
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
        )}
        {allMessages.map((message) => (
          <Grid
            className="bg-white rounded-md p-1 shadow-md"
            key={message.id}
            item
            container
            direction="column"
            style={{ marginBottom: 10, marginTop: 5 }}
          >
            <Grid
              item
              className=" font-medium text-indigo-700 p-2 w-full"
              container
            >
              <Grid item xs={6}>
                <div>
                  {message.subject
                    ? message.subject
                    : message.to
                    ? `${message.from} --> ${message.to}`
                    : message.from }
                </div>
              </Grid>
              <Grid item xs={hideReplyOption ? 6 : 5} sx={{ textAlign: 'right' }}>
                <div>
                  {message.subject
                    ? message.to
                      ? `${message.from} --> ${message.to}`
                      : message.from +
                        ', ' +
                        format(
                          addMinutes(
                            new Date(message.dateSent),
                            -new Date().getTimezoneOffset()
                          ),
                          'MMMM d, yyyy h:mm a'
                        )
                    : format(
                        addMinutes(
                          new Date(message.dateSent),
                          -new Date().getTimezoneOffset()
                        ),
                        'MMMM d, yyyy h:mm a'
                      )}
                </div>
              </Grid>
              {!hideReplyOption && (
                <Grid item xs={1} container justifyContent="flex-end">
                  <Tooltip
                    id="tooltip-top"
                    title="Click to reply"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton
                      aria-label="close"
                      onClick={() => showReplyOption(message)}
                      style={{ paddingTop: 0 }}
                      size="large"
                    >
                      <ReplyIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
            <Grid item className={classes.content}>
              <div className="text-lg font-medium text-gray-700">
                {parse(message.content)}
              </div>
            </Grid>
          </Grid>
        ))}
      </StyledGrid>
    );
  };
  
  export default AgentConversations;
  