import { Button, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from '@mui/lab';
import { format, addMinutes } from 'date-fns';
import Agent from '../../../../api/agent';
import EditorDraftJs from '../../../Common/Editor/EditorDraftJs';
import Conversations from '../../../Messages/Conversations';
import AccountsNoteConversation from '../../../Messages/AccountsNoteConversations';

const StudentAccountsNote = ({ records, setRecords, studentId, name }) => {
  const [addNodeMode, setAddNodeMode] = useState(false);
  const [rawHtml, setRawHtml] = useState();
  const [updating, setUpdating] = useState(false);
  const [accountsNotes, setAccountsNote] = useState([]);

  const [editorValidState, setEditorValidState] = useState(false);
  const editorOnChange = (rawHtml, valid) => {
    setRawHtml(rawHtml);
    setEditorValidState(valid);
  };


  useEffect(() => {
    Agent.Students.getStudent(studentId)
      .then((std) => {
        const { accountsNotes } = std;
        accountsNotes.sort(function (a, b) {
          return new Date(b.dateSent) - new Date(a.dateSent);
        });
        setAccountsNote(accountsNotes);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [studentId]);



  
  const addNote = () => {
    var msg = {
      content: rawHtml,
      studentId: studentId,
      sendEmail: false,
      noteType: 2,
    };
    setUpdating(true);
    Agent.Messages.addAccountsNote(msg)
      .then(() => {
        let temp = accountsNotes.concat({
          content: rawHtml,
          from: name,
          noteType: 2,
          dateSent: format(
            addMinutes(new Date(), new Date().getTimezoneOffset()),
            'MMMM d, yyyy h:mm a'
          ),
        });
        temp.sort(function (a, b) {
          return new Date(b.dateSent) - new Date(a.dateSent);
        });
        setAccountsNote(temp);
        setRecords(temp);
        setUpdating(false);
        setAddNodeMode(false);
        setEditorValidState(false);
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
    //    / debugger;
      });
  };
  return (
    <Grid container direction="column" spacing={2} style={{ marginTop: 5 }}>
      <Grid item container justifyContent="center">
        {' '}
        <Typography color="primary" variant="h5">
          Student Accounts Notes (Internal)
        </Typography>
      </Grid>
      <Grid item>
        <Button className='bg-white font-semibold hover:bg-gray-100'
          endIcon={!addNodeMode ? <AddIcon /> : null}
          onClick={() => {
            setAddNodeMode((prevState) => !prevState);
          }}
        >
          {addNodeMode ? 'Hide Note Option' : 'Add Accounts Note'}
        </Button>
      </Grid>
      {addNodeMode && (
        <>
          <Grid item>
            <EditorDraftJs editorOnChange={editorOnChange} height={100} />
          </Grid>
          <Grid item>
            <LoadingButton className='bg-cyan-700 font-semibold hover:bg-cyan-600'
              loading={updating}
              disabled={!editorValidState}
              onClick={addNote}
              variant="outlined"
            >
              <p className='text-gray-200'>Add Note</p>
            </LoadingButton>
          </Grid>
        </>
      )}
      <Grid item container>
        <AccountsNoteConversation
          messages={accountsNotes.filter((item) => item.noteType == 2)}
          user={name}
          hideReplyOption={true}
        />
      </Grid>
    </Grid>
  );
};

export default StudentAccountsNote;
