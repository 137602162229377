import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { useForm } from 'react-hook-form';

import bgImage from './../../../assets/images/Cover.png';
import { Countries } from '../../../Util/Util';
import Agent from '../../../api/agent';
import { GEO_LOCATION_URL } from '../../../Util/Constants';
import PhoneInput from 'react-phone-number-input';
// import LeadPhonInput from '../../Leads/LeadUtils/LeadPhonInput';

const NewAgentForm = () => {
  const [agentNationality, setAgentNationality] = useState(0);
  const [headquarter, setheadQuarter] = useState(0);
  const [countries, setCountries] = useState(Countries);
  const [nationality, setNationality] = useState(Countries);
  const [apiError, setApiError] = useState('');

  const navigate = useNavigate();
  const {
    setValue,
    register,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm();

  const [browserUserInfo, setBrowserUserInfo] = useState({
    ipAddress: '',
    city: '',
    country: '',
  });

  //creating function to load ip address from the API
  const getData = async () => {
    try {
      const res = await axios.get(GEO_LOCATION_URL);
      if (res.status === 200) {
        setBrowserUserInfo({
          ipAddress: res.data.IPv4,
          city: res.data.city,
          country: res.data.country_name,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onSubmit = async (data) => {
    try {
      await Agent.Agents.register({
        ...data,
        browserUserInfo,
      });
      navigate(`/registerSuccess?email=${data.Email}`);
    } catch (error) {
      console.error(error);
      setApiError(error);
    }
  };
  const handleAgentNationality = async (e) => {
    const selectedAgentNationality = e.target.value;
    setAgentNationality(selectedAgentNationality);
    setNationality(Countries);

    setValue('AgentNationality', selectedAgentNationality, {
      shouldValidate: true,
    });
    await trigger('AgentNationality'); // Trigger validation immediately
  };

  const handleHQ = async (e) => {
    const selectedHQ = e.target.value;
    setheadQuarter(selectedHQ);
    setCountries(Countries);
    // setheadQuarter(Countries);

    setValue('country', selectedHQ, {
      shouldValidate: true,
    });
    await trigger('country'); // Trigger validation immediately
  };

  useEffect(() => {
    register('AgentNationality', { required: 'Agent nationality is required' }); // Register the select element
    register('country', { required: 'Company head quarter is required' });
    register('ownerPhone', { required: true });
    register('Contact2', { required: false });
  }, [register]);

  return (
    <div
      className="flex justify-center items-center min-h-screen bg-cover bg-top bg-no-repeat bg-fixed"
      style={{ backgroundImage: `url(${bgImage})`, minHeight: '100vh' }}
    >
      <div className="w-full max-w-xl p-6 mt-2 bg-white rounded-lg shadow-lg lg:w-1/2 lg:ml-48  xl:ml-120 2xl:ml-120">
        {apiError && (
          <div className="mb-4 text-center text-xl text-red-600">
            <h2>{apiError}</h2>
          </div>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <h2 className="text-2xl font-semibold text-gray-800 mb-2">
              Register As Partner
            </h2>
            <p className="text-sm text-gray-600">
              Fill in the details below to create a new agent account.
            </p>
          </div>

          {/* Form fields begin here */}
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <div>
              <label
                className="block mb-2 text-sm font-bold text-gray-700"
                htmlFor="CompanyName"
              >
                Company Name <span className="text-red-500">*</span>
              </label>
              <input
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="CompanyName"
                type="text"
                placeholder="Enter company name"
                {...register('CompanyName', {
                  required: true,
                  maxLength: 100,
                  minLength: 2,
                })}
              />
              {errors.CompanyName && (
                <span className="text-xs text-red-500">
                  Please provide information for this field.The input should be
                  between 2 and 100 characters in length
                </span>
              )}
            </div>

            <div>
              <label
                htmlFor="CompanyHQ"
                className="block mb-2 text-sm font-bold text-gray-700"
              >
                Company HQ <span className="text-red-500">*</span>
              </label>
              <select
                id="CompanyHQ"
                onChange={handleHQ}
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              >
                <option value={0}>Select</option>
                {countries.filter((item)=>item.id!=0).map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}{' '}
              </select>
              {errors.country && (
                <span className="text-xs text-red-500">
                  {errors.country.message}
                </span>
              )}
            </div>

            <div>
              <label
                className="block mb-2 text-sm font-bold text-gray-700"
                htmlFor="firstName"
              >
                First Name<span className="text-red-500">*</span>
              </label>
              <input
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="firstName"
                type="text"
                placeholder="Owner's First name"
                {...register('firstName', {
                  required: true,
                  maxLength: 100,
                  minLength: 2,
                })}
              />
              {errors.firstName && (
                <span className="text-xs text-red-500">
                  Please provide information for this field.The input should be
                  between 2 and 100 characters in length
                </span>
              )}
            </div>

            <div>
              <label
                className="block mb-2 text-sm font-bold text-gray-700"
                htmlFor="lastName"
              >
                Last Name<span className="text-red-500">*</span>
              </label>
              <input
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="lastName"
                type="text"
                placeholder="Owner's Last name"
                {...register('lastName', {
                  required: true,
                  maxLength: 100,
                  minLength: 2,
                })}
              />
              {errors.lastName && (
                <span className="text-xs text-red-500">
                  Please provide information for this field.The input should be
                  between 2 and 100 characters in length
                </span>
              )}
            </div>

            <div>
              <label
                className="block mb-2 text-sm font-bold text-gray-700"
                htmlFor="agentEmail"
              >
                Email<span className="text-red-500">*</span>
              </label>
              <input
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="agentEmail"
                type="email"
                placeholder="Enter Owner's/Agent Email"
                {...register('agentEmail', {
                  required: true,
                  maxLength: 100,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, // Email pattern
                })}
              />
              {errors.agentEmail && (
                <span className="text-xs text-red-500">
                  {errors.agentEmail.type === 'required' &&
                    'This field is required'}
                  {errors.agentEmail.type === 'pattern' &&
                    "please use a valid email format like 'example@example.com"}
                </span>
              )}
            </div>

            <div>
              <label
                className="block mb-2 text-sm font-bold text-gray-700"
                htmlFor="Designation"
              >
                Designation<span className="text-red-500">*</span>
              </label>
              <input
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="Designation"
                type="text"
                placeholder="Owner's Designation"
                {...register('Designation', {
                  required: true,
                  minLength: 2,
                  maxLength: 150,
                })}
              />
              {errors.Designation && (
                <span className="text-xs text-red-500">
                  Please provide information for this field.The input should be
                  between 2 and 150 characters in length
                </span>
              )}
            </div>

            <div>
              <label
                htmlFor="AgentNationality"
                className="block mb-2 text-sm font-bold text-gray-700"
              >
                Agent Nationality <span className="text-red-500">*</span>
              </label>
              <select
                id="AgentNationality"
                onChange={handleAgentNationality}
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
              >
                <option value={0}>Select</option>
                {nationality.map((country) => (
                  <option key={country.id} value={country.id}>
                    {country.name}
                  </option>
                ))}{' '}
              </select>
              {errors.AgentNationality && (
                <span className="text-xs text-red-500">
                  {errors.AgentNationality.message}
                </span>
              )}
            </div>

            {/* 

            <div>
          <label htmlFor="phoneNumber" className="block font-bold mb-2">
            Phone Number
          </label>
          <PhoneInput
            // country={'US'}
            inputComponent={LeadPhonInput}
            // value={updatephonenumber || null}
            onChange={(value, country) => handlePhoneChange(value, country)}
            inputClass={`input input-bordered w-full ${
              phoneError ? 'border-red-500' : ''
            }`}
          />
          {phoneError && <p className="text-red-500">{phoneError}</p>}
        </div> */}

            <div>
              <label
                className="block mb-2 text-sm font-bold text-gray-700"
                htmlFor="ownerPhone"
              >
                Phone Number (Owner) <span className="text-red-500">*</span>
              </label>
              <PhoneInput
                className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                id="ownerPhone"
                placeholder="Enter owner's phone no."
                // value={ownerPhone}
                onChange={(value) => {
                  // Handle the phone number change here
                  // You can access the updated phone number from the 'value' argument
                  // For example, you can update a state variable with the new value
                  setValue('ownerPhone', value); // Assuming 'ownerPhone' is the name of the input field
                }}
              />
              {errors.ownerPhone && (
                <span className="text-xs text-red-500">
                  This field requires a valid international phone number with
                  the country code.
                </span>
              )}
            </div>
          </div>

          {/* second part of the form*/}

          <div className="mb-4 ">
            <h2 className="text-xl font-semibold text-gray-800 mb-2 mt-1">
              Second Point Of Contact
            </h2>
            {/* <p className="text-sm text-gray-600">Fill in the details below to create a new agent account.</p> */}

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div>
                <label
                  className="block mb-2 text-sm font-bold text-gray-700"
                  htmlFor="FirstName2"
                >
                  First Name
                </label>
                <input
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="FirstName2"
                  type="text"
                  placeholder="Enter First Name"
                  {...register('FirstName2', {
                    maxLength: 100,
                    minLength: 2,
                  })}
                />
                {errors.FirstName2 && (
                  <span className="text-xs text-red-500">
                    Minimum 2 and maximum 100 character.
                  </span>
                )}
              </div>

              <div>
                <label
                  className="block mb-2 text-sm font-bold text-gray-700"
                  htmlFor="lastName2"
                >
                  Last Name
                </label>
                <input
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="lastName2"
                  type="text"
                  placeholder="Enter last Name"
                  {...register('lastName2', {
                    maxLength: 100,
                    minLength: 2,
                  })}
                />
                {errors.lastName2 && (
                  <span className="text-xs text-red-500">
                    Minimum 2 and maximum 100 character.
                  </span>
                )}
              </div>

              <div>
                <label
                  className="block mb-2 text-sm font-bold text-gray-700"
                  htmlFor="Designation2"
                >
                  Designation
                </label>
                <input
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="Designation2"
                  type="text"
                  placeholder="Enter Designation"
                  {...register('Designation2', {
                    maxLength: 150,
                    minLength: 2,
                  })}
                />
                {errors.Designation2 && (
                  <span className="text-xs text-red-500">
                    Minimum 2 and maximum 150 character.
                  </span>
                )}
              </div>

              <div>
                <label
                  className="block mb-2 text-sm font-bold text-gray-700"
                  htmlFor="Contact2"
                >
                  Phone Number (Second Person)
                </label>
                <PhoneInput
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="Contact2"
                  placeholder="Enter valid phone no."
                  // value={ownerPhone}
                  onChange={(value) => {
                    setValue('Contact2', value);
                  }}
                />
                {errors.Contact2 && (
                  <span className="text-xs text-red-500">
                    This field requires a valid international phone number with
                    the country code.
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* Third part of the form */}

          <div className="mb-4">
            <h2 className="text-xl font-semibold text-gray-800 mb-2 mt-1">
              Create Agent Id
            </h2>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <div>
                <label
                  className="block mb-2 text-sm font-bold text-gray-700"
                  htmlFor="Email"
                >
                  Email<span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="Email"
                  type="email"
                  placeholder="Email for creating user login"
                  {...register('Email', {
                    required: true,
                    maxLength: 100,
                    pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, // Email pattern
                  })}
                />
                {errors.Email && (
                  <span className="text-xs text-red-500">
                    {errors.Email.type === 'required' &&
                      'This field is required'}
                    {errors.Email.type === 'pattern' &&
                      'please use a valid email format like example@example.com'}
                  </span>
                )}
              </div>

              <div>
                <label
                  className="block mb-2 text-sm font-bold text-gray-700"
                  htmlFor="password"
                >
                  Password <span className="text-red-500">*</span>
                </label>
                <input
                  className="w-full px-3 py-2 leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  id="password"
                  type="password"
                  placeholder="Enter password"
                  {...register('Password', {
                    required: true,
                    maxLength: 30,
                    minLength: 8,
                    pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8}.*$/i,
                  })}
                />
                {errors.Password && (
                  <span className="text-xs text-red-500">
                    Password must be at least 8 characters and include at least
                    one uppercase letter, one lowercase letter, and one number.
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between mt-4">
            <Link
              to="/"
              className="px-4 py-2 font-bold text-gray-700 bg-gray-300 rounded hover:bg-gray-400 focus:outline-none focus:shadow-outline"
            >
              Cancel
            </Link>

            <button
              className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700 focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewAgentForm;
