/* eslint-disable jsx-a11y/label-has-associated-control */

import React from 'react';
import { QUALIFICATIONS } from '../../../Util/Constants';

const LanguageInfo = ({
  languageRecords,
  handleLanguageChange,
  handleAddLanguageRecord,
}) => {
  return (
    <div id="language-info">
      {console.log(languageRecords)}

      <div>
        <h2 className="text-2xl text-center  mt-2 font-semibold mb-4 text-gray-700">
          Language Information
        </h2>

        {languageRecords.map((record, index) => (
          <div
            key={index}
            className="mb-6 p-4 border rounded grid grid-cols-2 gap-2"
          >
            <div className="mb-4">
              <label className="block font-bold mb-1">Qualification</label>
              <select
                className="input input-bordered w-full"
                value={record.qualification}
                onChange={(e) =>
                  handleLanguageChange(index, 'qualification', e.target.value)
                }
              >
                <option value="">Select Language Qualification </option>
                {QUALIFICATIONS.map((qualification) => (
                  <option key={qualification.id} value={qualification.id}>
                    {qualification.type}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label className="block font-bold mb-1">Description</label>
              <input
                type="text"
                className="input input-bordered w-full"
                value={record.description}
                onChange={(e) =>
                  handleLanguageChange(index, 'description', e.target.value)
                }
              />
            </div>

            {/* <div className="mb-4">
              <label className="block font-bold mb-1">Exam Date</label>
              <input
                type="date"
                className="input input-bordered w-full"
                value={record.examDate}
                onChange={(e) =>
                  handleLanguageChange(
                    index,
                    'examDate',
                    new Date(e.target.value).toISOString()
                  )
                }
              />
            </div> */}

            <div className="mb-4">
              <label className="block font-bold mb-1">Band</label>
              <input
                type="text"
                className="input input-bordered w-full"
                value={record.band}
                onChange={(e) =>
                  handleLanguageChange(index, 'band', e.target.value)
                }
              />
            </div>

            <div className="mb-4">
              <label className="block font-bold mb-1">Reading</label>
              <input
                type="number"
                className="input input-bordered w-full"
                value={record.reading}
                onChange={(e) =>
                  handleLanguageChange(index, 'reading', e.target.value)
                }
              />
            </div>

            <div className="mb-4">
              <label className="block font-bold mb-1">Listening</label>
              <input
               type="number"
                className="input input-bordered w-full"
                value={record.listening}
                onChange={(e) =>
                  handleLanguageChange(index, 'listening', e.target.value)
                }
              />
            </div>

            <div className="mb-4">
              <label className="block font-bold mb-1">Writing</label>
              <input
                  type="number"
                className="input input-bordered w-full"
                value={record.writing}
                onChange={(e) =>
                  handleLanguageChange(index, 'writing', e.target.value)
                }
              />
            </div>

            <div className="mb-4">
              <label className="block font-bold mb-1">Speaking</label>
              <input
                 type="number"
                className="input input-bordered w-full"
                value={record.speaking}
                onChange={(e) =>
                  handleLanguageChange(index, 'speaking', e.target.value)
                }
              />
            </div>
          </div>
        ))}

        <button
          type="button"
          onClick={handleAddLanguageRecord}
          className="bg-sky-600 text-white px-4 py-2 rounded-md hover:bg-sky-400"
        >
          Add language
        </button>
      </div>
    </div>
  );
};

export default LanguageInfo;
