import { Button, Grid, TextField, Typography,FormControl,FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Agent from '../../api/agent';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import LinearIndeterminate from '../Common/Progress/LinearIndeterminate';
import MySnackbarAlert from '../Common/AlertMessage/MySnackbarAlert';
import { emailIsValid } from '../../Util/Util';
import PhoneInput from 'react-phone-number-input';
import phoneInput from './phoneInput';

const PersonalDetails = (props) => {
  console.log('props', props);
  const nameParts = props.name.split(' ');
  const [firstName, setFirstName] = useState(nameParts[0]);
  const [lastName, setLastName] = useState(nameParts[nameParts.length - 1]);
  const [phoneNumber, setPhoneNumber] = useState(
    props.phoneNumber ? props.phoneNumber : ''
  );
  const [applicationUpdateEmail, setApplicationUpdateEmail] = useState(
    props.applicationUpdateEmail ? props.applicationUpdateEmail : ''
  );
  const [isReady, setIsReady] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [phoneError, setPhoneError] = useState('');
  //#snackbar stuff
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [alertMsg, setAlertMsg] = useState();
  //#endregion

  useEffect(() => {
    setIsReady(
      firstName.length > 0 || lastName.length > 0 ||  phoneNumber.length > 0
    );
  }, [firstName, lastName, phoneNumber, applicationUpdateEmail]);

  const updatedetails = () => {
    const name = firstName + ' ' + lastName;
    if (applicationUpdateEmail) {
      if (!emailIsValid(applicationUpdateEmail)) return;
    }
    const user = {
      editUserDto: {
        id: props.id,
        predicate: 'personal',
        firstName,
        lastName,
        phoneNumber,
        applicationUpdateEmail,
      },
    };
    setLoading(true);
    
    Agent.Users.editUser(user)
      .then(() => {
        setLoading(false);
        props.updateAuthName(name, phoneNumber, applicationUpdateEmail);
        setAlertMsg('Personal details saved successfully!');
        setOpenSuccess(true);
        setOpenFailure(false);
      })
      .then(() =>
        props.updateUserDetails(
          props.id,
          name,
          phoneNumber,
          applicationUpdateEmail
        )
      )
      .catch((error) => {
        debugger;
        setLoading(false);
        setAlertMsg('Failed to save. Check inputs and try again!');
        setOpenFailure(true);
        setOpenSuccess(false);
      });
  };
  const handlePhoneNumber = (value, country) => {
    fieldErrors.phoneNumber = '';

    if (value) {
      if (/^\+[1-9]\d{1,14}$/.test(value)) {
        setPhoneNumber(value);
        setFirstTime(false);
        setPhoneError('');
      } else {
        setPhoneNumber(null);
        setFirstTime(true);
        setPhoneError(
          'Invalid phone number format. Include country code (+) and digits.'
        );
      }
    } else {
      // Clear any previous error and reset the phone number here
      setPhoneNumber(null);
      setPhoneError('');
      setFirstTime(true);
    }
  };
  // const phoneHandler = (name, phoneNumber) => {
  //   setPhoneNumber(phoneNumber);
  //   setFirstTime(false);
  // };

  return (
    <>
      {' '}
      <div className="flex items-top p-4 justify-center min-h-screen min-w-screen">
        <div className="flex justify-center items-top h-full lg:w-1/2 xl:w-1/2">
          <Grid item xs={12} container direction="row" spacing={3}>
            <Grid item xs={3}>
              <Typography variant="h5" color={'primary'}>
                {' '}
                {props.roleName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                size="small"
                label="First Name"
                name="firstName"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  setFirstTime(false);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                size="small"
                label="Last Name"
                name="lastName"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  setFirstTime(false);
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                size="small"
                type="email"
                label="Portal Email Address"
                name="applicationUpdateEmail"
                value={applicationUpdateEmail}
                onChange={(e) => {
                  setApplicationUpdateEmail(e.target.value.trim());
                  setFirstTime(false);
                }}
                variant="outlined"
              />
            </Grid>
            {/* <Grid item xs={12}>
          <TextField
            required
            fullWidth
            size="small"
            label="Phone Number"
            name="phoneNumber"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value.trim());
              setFirstTime(false);
            }}
            variant="outlined"
          />
        </Grid> */}
            <Grid item xs={12}>
              <FormControl fullWidth>
                <PhoneInput
                  placeholder="Phone Number"
                  error={!!fieldErrors.phoneNumber}
                  helperText={fieldErrors.phoneNumber}
                  value={phoneNumber}
                  required
                  onChange={handlePhoneNumber}
                  inputComponent={phoneInput}
                />
                {phoneError && (
                  <FormHelperText error>{phoneError}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {loading && (
              <Grid item xs={12} style={{ paddingRight: 10 }}>
                <LinearIndeterminate />
              </Grid>
            )}
            <Grid item xs={3}>
              <Button
                variant="contained"
                className=" bg-cyan-700"
                disabled={!isReady || firstTime}
                onClick={updatedetails}
              >
                Update
              </Button>
            </Grid>
          </Grid>
          {(openSuccess || openFailure) && (
            <MySnackbarAlert
              opnSuccess={openSuccess}
              opnFailure={openFailure}
              message={alertMsg}
              opnSuccessParent={setOpenSuccess}
              opnFailureParent={setOpenFailure}
            />
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    roleName: state.auth.roleName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAuthName: (name, phoneNumber, applicationUpdateEmail) =>
      dispatch(
        actions.updateUserPersonalInfo(
          name,
          phoneNumber,
          applicationUpdateEmail
        )
      ),
    updateUserDetails: (id, name, phoneNumber, applicationUpdateEmail) =>
      dispatch(
        actions.updatePersonalDetailsAction(
          id,
          name,
          phoneNumber,
          applicationUpdateEmail
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
