/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Navigate, useNavigate, useParams } from 'react-router';
import * as actions from '../../../store/actions/index';
import { connect } from 'react-redux';
import { Button, Grid, Tab, Tabs, Typography, Box } from '@mui/material';
import LoadingView from '../../Common/Loading/LoadingView';
import Agent from '../../../api/agent';
import MyTabLabel from '../../Common/Tab/MyTabLabel';
import { newUniversity } from '../Constants/Constants';
import UniversityProfile from './Profile/UniversityProfile';
import UniversityImages from './Images/UniversityImages';
import UniversityCourses from './Courses/UniversityCourses';
import UniversityAction from './Actions/UniversityAction';
import ApplicationMethods from './Methods/ApplicationMethods';
import UniversitySummary from './UniversitySummary';
import LanguageTests from './Tests/LanguageTests';
import Territory from './Territory/Territory';
import MySnackbarAlert from '../../Common/AlertMessage/MySnackbarAlert';
import Communications from './Communications/Communications';
import { useSearchParams } from 'react-router-dom';

const PREFIX = 'University';
const classes = {
  // courseArea: `${PREFIX}-courseArea`,
  // studentArea: `${PREFIX}-studentArea`,
};

const StyledDiv = styled('div')(() => ({
  // [`& .${classes.courseArea}`]: {
  //   borderRight: '1px solid gray',
  //   marginTop: 5,
  // },
  // [`& .${classes.studentArea}`]: {
  //   marginTop: 5,
  //   paddingLeft: 24,
  // },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ paddingTop: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const University = ({ emptyCourseAllocations, role, permissions, name }) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');

  const [loading, setLoading] = useState(true);
  const [university, setUniversity] = useState();
  const [tabValue, setTabValue] = useState(tab ? parseInt(tab) : 0);
  const [showOverview, setShowOverview] = useState(false);
  //#snackbar stuff
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [alertMsg, setAlertMsg] = useState();
  //#endregion
  const [reload, setReload] = useState(false);
  const [notes, setNotes] = useState([]);

  useEffect(() => {
    if (id && id != 0) {
      emptyCourseAllocations();
      Agent.Universities.getUniversity(id)
        .then((uni) => {
          setUniversity(uni);
          var allNotes = uni.universityNotes.sort(function (a, b) {
            return new Date(b.dateSent) - new Date(a.dateSent);
          });
          setNotes(allNotes);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          debugger;
        });
    } else {
      setUniversity(newUniversity);
      setLoading(false);
    }
  }, [reload]);

  const handleDisable=()=>{
    setReload(false);
    Agent.Universities.disableUniversity(id).then(()=>{
      setAlert('Status Update Successfully!!');
      setReload(true);
    })
  }
  const setTab = (tabIndex) => {
    setTabValue(tabIndex);
  };

  const updateProfileParent = (child) => {
    setUniversity({ ...university, ...child });
  };

  const setAlert = (msg = 'Successfully updated', isSuccess = true) => {
    setAlertMsg(msg);
    setOpenSuccess(isSuccess);
    setOpenFailure(!isSuccess);
  };

  return loading ? (
    <LoadingView />
  ) : (
    <StyledDiv>
      <Grid
        container
        style={{ marginBottom: 20 }}
        sx={{
          border: '1px',
          p: 4,
          borderRadius: '20px',
          backgroundImage: 'linear-gradient(135deg, #9599E2 0%, #2BC6EC 100%)',
        }}
      >
        <Grid item xs={10}>
          <Typography
            textAlign="center"
            variant="h5"
            sx={{
              color: '#FFF',
              fontSize: '2em',
              fontWeight: 'medium',
            }}
          >
            {university.isInactive
              ? university.name + ' (Currently Inactive)'
              : university.name}
            <Typography textAlign="center">{`${university.city}`}</Typography>
          </Typography>
        </Grid>
        <Grid item xs={2} container justifyContent="end">
          <Button
            onClick={() => setShowOverview(true)}
            className="text-gray-50"
          >
            Overview
          </Button>
        </Grid> 
      </Grid>
      <Grid container>
        <Grid item xs={1}>
          <Tabs
            // TabIndicatorProps={{ style: { display: 'none' } }}
            orientation="vertical"
            variant="scrollable"
            value={tabValue}
            onChange={(_, newValue) => {
              setTab(newValue);
            }}
            aria-label="vertical tabs"
            className={classes.tabs}
            sx={{
              width: '100%',
              minWidth: '210px',
              marginTop: 2,
              marginLeft:0,
              textAlign:'center',
              color:'white',
            }}
          >
            <Tab
              classes={{ root: classes.selectedTab }}
              className={classes.tab}
              label={<MyTabLabel label="General Info" showWarning={false} />}
              {...a11yProps(0)}
            />
            <Tab
              classes={{ root: classes.selectedTab }}
              className={classes.tab}
              label={<MyTabLabel label={'Courses'} showWarning={false} />}
              {...a11yProps(1)}
            />
            <Tab
              classes={{ root: classes.selectedTab }}
              className={classes.tab}
              label={<MyTabLabel label={'Methods'} showWarning={false} />}
              {...a11yProps(2)}
            />
            <Tab
              disabled={!(role.isSuperAdmin || permissions.CanManageUniversity)}
              classes={{ root: classes.selectedTab }}
              className={classes.tab}
              label={<MyTabLabel label={'Actions'} showWarning={false} />}
              {...a11yProps(3)}
            />
            <Tab
              classes={{ root: classes.selectedTab }}
              className={classes.tab}
              label={<MyTabLabel label="Images" showWarning={false} />}
              {...a11yProps(4)}
            />
            <Tab
              classes={{ root: classes.selectedTab }}
              className={classes.tab}
              label={<MyTabLabel label={'English Tests'} showWarning={false} />}
              {...a11yProps(5)}
            />
            <Tab
              classes={{ root: classes.selectedTab }}
              className={classes.tab}
              label={<MyTabLabel label={'Territory'} showWarning={false} />}
              {...a11yProps(6)}
            />
            <Tab
              classes={{ root: classes.selectedTab }}
              className={classes.tab}
              label={
                <MyTabLabel label={'Communications'} showWarning={false} />
              }
              {...a11yProps(7)}
            />
          </Tabs>
          {permissions.CanDisableUniversities &&<Button
            onClick={handleDisable}
            className='ml-8 px-6 text-white bg-red-800 hover:bg-red-700'
          >
            {university.isInactive?'Active':'Inactive'}
          </Button>}
        </Grid>
        <Grid
          item
          xs={10}
          sx={{
            backgroundColor: 'white',
            borderRadius: '20px',
            marginLeft:12,
            boxShadow: `0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 6px 20px 0 rgba(0, 0, 0, 0.08)`,
          }}
        >
          <TabPanel
            value={tabValue}
            index={0}
            style={{
              paddingTop: 0,
              borderRadius: '24px',
              // backgroundColor: 'red',
              padding: '6px',
              margin: '6px',
             
            }}
          >
            <UniversityProfile
              profile={university}
              updateProfileParent={updateProfileParent}
              setAlert={setAlert}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1} style={{ marginTop: 15 }}>
            {university.id && university.id > 0 && (
              <UniversityCourses
                universityId={university.id}
                countryId={university.countryId}
                updateProfileParent={updateProfileParent}
              />
            )}
          </TabPanel>

          <TabPanel value={tabValue} index={2} style={{ marginTop: 15 }}>
            {university.id && university.id > 0 && (
              <ApplicationMethods universityId={university.id} />
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={3} style={{ marginTop: 15 }}>
            {university.id && university.id > 0 && (
              <UniversityAction
                profile={university}
                updateProfileParent={updateProfileParent}
              />
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={4} style={{ paddingTop: 0 }}>
            <UniversityImages
              profile={university}
              updateProfileParent={updateProfileParent}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={5} style={{ marginTop: 15 }}>
            {university.id && university.id > 0 && (
              <LanguageTests
                university={university}
                setAlert={setAlert}
                updateParent={updateProfileParent}
              />
            )}
          </TabPanel>
          <TabPanel value={tabValue} index={6} style={{ marginTop: 15 }}>
            {university.id && university.id > 0 && (
              <Territory
                university={university}
                updateParent={updateProfileParent}
                setAlert={setAlert}
              />
            )}
          </TabPanel>

          {/* <TabPanel value={tabValue} index={7} style={{ marginTop: 15 }}>
            {university.id && university.id > 0 && (
              <AgentTerritory
                university={university}
                updateParent={updateProfileParent}
                setAlert={setAlert}
              />
            )}
          </TabPanel> */}



          <TabPanel value={tabValue} index={7} style={{ marginTop: 15 }}>
            {university.id && university.id > 0 && (
              <Communications
                universityId={university.id}
                records={notes}
                setRecords={setNotes}
                name={name}
              />
            )}
          </TabPanel>
        </Grid>
      </Grid>
      {(openSuccess || openFailure) && (
        <MySnackbarAlert
          opnSuccess={openSuccess}
          opnFailure={openFailure}
          message={alertMsg}
          opnSuccessParent={setOpenSuccess}
          opnFailureParent={setOpenFailure}
        />
      )}
      {showOverview && <UniversitySummary id={id} setShow={setShowOverview} />}
    </StyledDiv>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    role: state.auth.role,
    name: state.auth.name,
    permissions: state.auth.permissions,
    emptyCourseAllocations: ownProps.emptyCourseAllocations,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    emptyCourseAllocations: () => dispatch(actions.emptyCourseAllocations()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(University);
