import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Box, Typography } from '@mui/material';
import LoadingView from '../../Common/Loading/LoadingView';
// import { classNames } from 'classnames';
// import StudentLead from './../StudentLead/StudentLead';
import { MailIcon } from '@mui/icons-material/Mail';
import UserActivity from '../UserActivity';

const ActivityMenu = () => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);

  const handleTabChange = (e, newVal) => {
    setTab(newVal);
  };

  return (
    <>
      {loading ? (
        <div style={{ marginTop: '0px', fontWeight: 'lighter' }}>
          <LoadingView />
        </div>
      ) : (
        <div>
          {/* <h3 style={{ fontWeight: 'bolder',marginTop:'-1%'}}>Reports and Analytics </h3> */}
          <Box>
            <Box>
              <Tabs
                value={tab}
                onChange={handleTabChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: '#0081C9',
                    height: '3px',
                    // fontSize:'20px',
                    borderRadius: '30px',
                  },
                }}
              >
                {tabItems.map((item, index) => {
                  return (
                    <Tab className="text-black" label={item} key={index}/>
                  );
                })}
              </Tabs>
              <Box>
                <TabPanel value={tab} index={0}>
                  <Typography
                    variant="h6"
                    component="h4"
                    align="center"
                    className="text-sky-900  text-xl mb-3"
                  >
                    {/* Students Lead Management */}
                  </Typography>
                  <UserActivity />
                </TabPanel>

                {/* <TabPanel value={tab} index={1} >
                 <Typography variant="h6" component="h4" align="center"  className='text-red-900'>
          This Module Under Development...
        </Typography>
                </TabPanel> */}
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};
export default ActivityMenu;

const TabPanel = (props) => {
  const { children, value, index } = props;

  return <div>{value === index && <Box>{children}</Box>}</div>;
};

const tabItems = ['User Activity Management'];