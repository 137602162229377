/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import * as actions from '../../../store/actions/index';
import {
  LicenseInfo,
  DataGridPro,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
import {
  Button,
  FormControl,
  Grid,
  Snackbar,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { connect } from 'react-redux';
import DatePicker, { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // the locale you want
registerLocale('en-GB', enGB); // register it with the name you want
import 'react-datepicker/dist/react-datepicker.css';
import { addHours, addMonths, startOfDay } from 'date-fns';
import LoadingView from '../../Common/Loading/LoadingView';
import { MUI_LICENSE_KEY } from '../../../Util/Constants';
import { useNavigate } from 'react-router';
import Agent from '../../../api/agent';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';

function CustomToolbar(show, isInternal, hide) {
  return (
    <GridToolbarContainer>
      {show && <GridToolbarColumnsButton />}
      {hide && <GridToolbarFilterButton />}
      <GridToolbarDensitySelector />
      {hide && <GridToolbarExport />}
    </GridToolbarContainer>
  );
}

const UnsubscribedEmailList = ({ users, loadUsers, isInternal }) => {
  const [activityLoaded, setStudentLoaded] = useState(false);
  const [startDate, setStartDate] = useState(addMonths(new Date(), -1));
  const [endDate, setEndDate] = useState(new Date());
  const [emailAddress, setEmailAddress] = useState('');
  const [templateRowList, settemplateRowList] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [pageSize, setPageSize] = useState(100);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const navigate = useNavigate();
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleDeleteClick = (id) => {
    setDeleteId(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = () => {
    setDeleteDialogOpen(false);
    Agent.Communication.deleteUnsubscribeMail(deleteId)
      .then(() => {
        setSnackbarSeverity('success');
        setSnackbarMessage('Unsubscribed email deleted successfully.');
        setSnackbarOpen(true);
        getData(true);
      })
      .catch((error) => {
        setSnackbarSeverity('error');
        setSnackbarMessage('Failed to delete unsubscribed email.');
        setSnackbarOpen(true);
        console.error(error);
      });
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setDeleteId(null);
  };

  const getParams = (filter) => {
    const params = new URLSearchParams();
    params.append('pageNumber', page.toString());
    params.append('pageSize', pageSize.toString());

    if (filter) {
      if (startDate) {
        params.append('StartDate', new Date(startDate).toDateString());
      }
    } else {
      params.append('StartDate', addMonths(new Date(), -1).toDateString());
    }
    if (filter) {
      if (endDate) {
        params.append('EndDate', new Date(endDate).toDateString());
      }
    } else {
      params.append('EndDate', new Date().toDateString());
    }

    params.append('EmailAddress', emailAddress.trim());

    return params;
  };

  const handleUnSubscribeMailRequest = () => {
    navigate('/unsubscribeemail');
  };

  const handlePageChange = (params) => {
    setPage(params + 1);
  };
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1); // Reset page when changing page size
  };

  const getRows = (rows) => {
    console.log('get list', rows);
    let templateRows = rows.map((template) => ({
      id: template.id,
      emailAddress: template.emailAddress,
      addedBy: template.addedBy,
      isBulk: template.isBulk,
      isNormal: template.isOneToOne,
      addedOn: template.addedOn
        ? new Date(template.addedOn).toLocaleString()
        : '',
      updatedBy: template.updatedBy,
      updatedOn: template.updatedOn
        ? new Date(template.updatedOn).toLocaleString()
        : '',
    }));
    settemplateRowList(templateRows);
    setStudentLoaded(true);
    setLoading(false);
  };

  useEffect(() => {
    if (isInternal) {
      if (users.length === 0) {
        loadUsers()
          .then(() => {
            getData(true);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        getData(true);
      }
    } else {
      getData(true);
    }
  }, [users, page]);

  const getData = (filter = false) => {
    setLoading(true);
    Agent.Communication.getUnsubscribedMailList(getParams(filter))
      .then((res) => {
        getRows(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetFilter = () => {
    setStartDate(addMonths(new Date(), -1));
    setEndDate(new Date());
    setEmailAddress('');
    getData(false);
  };

  let columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      align: 'center',
      headerAlign: 'center',
      hide: true,
    },
    {
      field: 'emailAddress',
      headerName: 'Email Address',
      hide: false,
      width: 250,
      align: 'left',
      headerAlign: 'center',
      renderCell: (params) => (
        <Link to={`/updateemail/${params.row.id}`}>{params.value}</Link>
      ),
    },
    {
      field: 'addedBy',
      headerName: 'Added By',
      width: 200,
      hide: false,
      valueGetter: (params) => {
        const getUserId = params.row.addedBy;
        const userName = users.find((user) => user.id == getUserId);
        return userName ? userName.name : null;
      },
    },
    {
      field: 'addedOn',
      headerName: 'Added On',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'updatedBy',
      headerName: 'Updated By',
      width: 200,
      hide: true,
      valueGetter: (params) => {
        const getUserId = params.row.updatedBy;
        const userName = users.find((user) => user.id == getUserId);
        return userName ? userName.name : null;
      },
    },
    {
      field: 'updatedOn',
      headerName: 'Updated On',
      width: 200,
      hide: false,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'isBulk',
      headerName: 'Marketing Mail (Bulk)',
      width: 150,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'isNormal',
      headerName: 'Single Mail',
      width: 150,
      align: 'center',
      hide: false,
      headerAlign: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          className="bg-red-700"
          onClick={() => handleDeleteClick(params.row.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return activityLoaded ? (
    <>
      <Grid container direction="column" spacing={1}>
        <Grid item container spacing={1}>
          <Grid item xs={2}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              error={true}
              selected={(startDate && new Date(startDate)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setStartDate(newVal);
              }}
              customInput={
                <TextField
                  label="Start Date"
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>

          <Grid item xs={2}>
            <DatePicker
              autoComplete="off"
              locale="enGB"
              dateFormat="dd-MM-yyyy"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              selected={(endDate && new Date(endDate)) || null}
              onChange={(newVal) => {
                if (newVal) {
                  newVal = addHours(startOfDay(newVal), 12);
                }
                setEndDate(newVal);
              }}
              customInput={
                <TextField
                  label="End Date"
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              }
            />
          </Grid>

          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                label="Email Address"
                variant="outlined"
                size="small"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item container spacing={3}>
          <Grid item xs={2}>
            <Button
              fullWidth
              onClick={() => getData(true)}
              variant="contained"
              className="bg-sky-400 hover:bg-sky-500"
            >
              Apply Filters
            </Button>
          </Grid>

          <Grid item xs={2}>
            <Button fullWidth onClick={resetFilter} variant="outlined">
              Reset Filters
            </Button>
          </Grid>
        </Grid>

        <Grid item container spacing={1} justifyContent="flex-end">
          <Grid item>
            <Button
              fullWidth
              onClick={handleUnSubscribeMailRequest}
              variant="contained"
              className="bg-sky-700"
            >
              UnSubscribe Now
            </Button>
          </Grid>
        </Grid>

        <Grid item style={{ height: 900, width: '100%' }}>
          <h2 className="text-lg text-center font-semibold mb-1">
            Unsubscribed Email List
          </h2>

          <DataGridPro
            paginationMode="client"
            pagination
            pageSize={pageSize}
            rowCount={totalItems}
            page={page - 1}
            pageSizeOptions={[25, 50, 100]}
            onPageChange={handlePageChange}
            rows={templateRowList}
            columns={columns}
            loading={loading}
            density="compact"
            rowHeight={70}
            components={{
              Toolbar: () => CustomToolbar(isInternal),
            }}
            onPageSizeChange={(newPageSize) =>
              handlePageSizeChange(newPageSize)
            }
          />
        </Grid>
      </Grid>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description" className="text-xl">
            Are you sure you want to delete this unsubscribed email?
          </DialogContentText>
        </DialogContent>
        <DialogActions className="flex justify-between mx-4">
          <Button onClick={handleDeleteCancel} className="btn-warning">
            No
          </Button>
          <Button onClick={handleDeleteConfirm} className="btn-success">
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      >
        <Alert
          elevation={16}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  ) : (
    <LoadingView />
  );
};

const mapStateToProps = (state) => {
  return {
    users: state.users,
    agents: state.agents,
    role: state.auth.role,
    permissions: state.auth.permissions,
    isInternal: state.auth.isInternal,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadUsers: () => dispatch(actions.loadUsers()),
    loadAgents: () => dispatch(actions.loadAgents()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsubscribedEmailList);
