import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Select,
  Snackbar,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Draggable from 'react-draggable';
import LinearIndeterminate from '../Common/Progress/LinearIndeterminate';
import { Typography } from '@mui/material';
import { Divider } from '@mui/material';
import { IconButton } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB'; // the locale you want
import Agent from '../../api/agent';
registerLocale('en-GB', enGB); // register it with the name you want
import { LoadingButton } from '@mui/lab';
import MyTooltip from '../Common/Tooltip/MyTooltip';
import {
  TASKS_GROUPS,
  TASKS_PRIORITIES,
  TASKS_STATUSES,
  TASKS_TYPES,
} from '../../Util/Constants';
import { useNavigate } from 'react-router';
import ConfirmationModal from '../Common/Modal/ConfirmationModal';
import { addHours, startOfDay } from 'date-fns';
import MsgAlertModal from './MsgAlertModal';

const PREFIX = 'Task';

const classes = {
  closeButton: `${PREFIX}-closeButton`,
  dateFiled: `${PREFIX}-dateFiled`,
  countryList: `${PREFIX}-countryList`,
  field: `${PREFIX}-field`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.closeButton}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  [`& .${classes.dateFiled}`]: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'red',
    backgroundColor: 'red',
  },
  [`& .${classes.countryList}`]: {
    ...theme.typography.body1,
  },
  [`& .${classes.field}`]: {
    margin: '10px 0',
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const Task = ({
  record,
  taskFor,
  setAddMode,
  id,
  cosmosAdminUsersParent,
  updateParent,
  path, //1 for student dashboard, 2 for task list
  tabToReturnTo,
}) => {
  const navigate = useNavigate();
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const [openConfirm, setOpenConfirm] = useState(false);
  // const [initialLoading, setInitialLoading] = useState(true)
  const [readyToSave, setReadyToSave] = useState(false);
  const [open, setOpen] = useState(true);
  const [updating, setUpdating] = useState(false);
  const [errors, setErrors] = useState();

  const [title, setTitle] = useState(record.title);
  const [description, setDescription] = useState(record.description);
  const [deadline, setDeadline] = useState(
    new Date().toISOString().split('T')[0]
  );
  const [priority, setPriority] = useState(record.priority || 2);
  const [status, setStatus] = useState(record.status || 1);
  const [group, setGroup] = useState(record.group || 0);
  const [type, setType] = useState(record.type || 25);
  const [cosmosAdminUsers, setCosmosAdminUsers] = useState(
    cosmosAdminUsersParent
  );
  const [admin1AppUserDetailsId, setAdmin1AppUserDetailsId] = useState(
    record.admin1AppUserDetailsId
      ? cosmosAdminUsersParent.find(
          ({ id }) => id == record.admin1AppUserDetailsId
        )
      : null
  );
  const [admin2AppUserDetailsId, setAdmin2AppUserDetailsId] = useState(
    record.admin2AppUserDetailsId
      ? cosmosAdminUsersParent.find(
          ({ id }) => id == record.admin2AppUserDetailsId
        )
      : null
  );
  const [admin3AppUserDetailsId, setAdmin3AppUserDetailsId] = useState(
    record.admin3AppUserDetailsId
      ? cosmosAdminUsersParent.find(
          ({ id }) => id == record.admin3AppUserDetailsId
        )
      : null
  );
  const [file, setFile] = useState();
  const [uploadMode, setUploadMode] = useState(true);
  const [fileActionType, setFileActionType] = useState(0); // 1=added new, 2=deleted, 0 no change
  const fileInput = useRef();
  const [task, setTask] = useState({
    id: record.id,
    admin1AppUserDetailsId: record.admin1AppUserDetailsId,
    admin2AppUserDetailsId: record.admin2AppUserDetailsId,
    admin3AppUserDetailsId: record.admin3AppUserDetailsId,
  });
  // Admin selection
  const [openAdmin1, setOpenAdmin1] = useState(false);
  const [openAdmin2, setOpenAdmin2] = useState(false);
  const [openAdmin3, setOpenAdmin3] = useState(false);

  const adminLoading = cosmosAdminUsers.length === 0;
  // admin end

  const initialFileUpload = () => {
    fileInput.current.click();
  };

  useEffect(() => {
    if (cosmosAdminUsers.length == 0) {
      Agent.Cosmos.getUsers()
        .then((usrs) => {
          setCosmosAdminUsers(
            usrs.filter((item) => {
              return (
                item.usertype == 1 ||
                item.usertype == 2 ||
                item.usertype == 3 ||
                item.usertype == 7 ||
                item.usertype == 8
              );
            })
          );
        })
        .catch((error) => {
          console.log(error);
          debugger;
        });
    }
  }, []);

  useEffect(() => {
    if (record.id > 0) {
      Agent.UserTask.getDetails(record.id).then((task) => {
        setTask(task);
        if (task.cloudFiles.length > 0) {
          setFile({
            ...task.cloudFiles[0],
            name: task.cloudFiles[0].fileNameLocal,
          });
          setUploadMode(false);
          setFileActionType(0);
        }
        setPriority(task.priority);
        setGroup(task.group);
        setType(task.type);
        setTitle(task.title);
        setDescription(task.description);
        setDeadline(
          task.deadline == null
            ? ''
            : new Date(task.deadline).toISOString().split('T')[0]
        );
        setStatus(task.status);
      });
    }
  }, []);

  useEffect(() => {
    setReadyToSave(title && admin1AppUserDetailsId);
  }, [title, admin1AppUserDetailsId]);

  function handleClose() {
    setOpen(false);
    setAddMode(false);
  }

  const handleFormCancel = () => {
    setOpen(false);
    setAddMode(false);
  };

  // const deleteTask = () => {
  //   Agent.UserTask.delete(record.id)
  //     .then((res) => {
  //       if (path == 1) {
  //         //go to student
  //         navigate(
  //           `/students/${record.studentId}/manage/?tab=${tabToReturnTo}`
  //         );
  //       } else {
  //         // go to general task list
  //         // console.log('test output msg for delete', res);
  //         setSuccessModalOpen(true);

  //         navigate('/tasks');
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       debugger;
  //     });
  // };


  const deleteTask = () => {
    Agent.UserTask.delete(record.id)
      .then((res) => {
        if (path == 1) {
          //go to student
          navigate(
            `/students/${record.studentId}/manage/?tab=${tabToReturnTo}`
          );
        } else {
          // go to general task list
          setSuccessModalOpen(true);
          setTimeout(() => setSuccessModalOpen(false), 3000); // Close the success message after 3 seconds
          navigate('/tasks');
          setOpenConfirm(false); 

        }
      })
      .catch((error) => {
        console.log(error);
//debugger;
      });
  };
  



  const save = () => {
    setErrors(null);
    setUpdating(true);
    let updatedTask = {
      ...task,
      ...{
        studentId: record.studentId || null,
        priority,
        group,
        type,
        status,
        title,
        description,
        deadline,
        fileActionType,
      },
    };
    Agent.UserTask.save(updatedTask, file)
      .then(() => {
        setUpdating(false);
        if (updateParent) {
          updateParent(updatedTask);
        } else {
          if (path == 1) {
            //go to student
            navigate(
              `/students/${record.studentId}/manage/?tab=${tabToReturnTo}`
            );
          } else {
            // go to general task list
            navigate('/tasks');
          }
          setAddMode(false);
        }
      })
      .catch((error) => {
        console.log(error);
        debugger;
      });
  };
  console.log('deadline', deadline);
  return (
    <Root>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          debugger;
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}
        aria-labelledby="draggable-dialog-title"
        PaperComponent={PaperComponent}
        maxWidth="md"
        fullWidth
      >
        <>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="h5" color="primary" align="center">
                  {taskFor
                    ? `Task (${taskFor})`
                    : record.id > 0
                    ? 'Task Details'
                    : 'New Task'}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  aria-label="close"
                  className={classes.closeButton}
                  onClick={handleFormCancel}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
          </DialogTitle>
          <DialogContent>
            {updating && (
              <Grid item>
                <LinearIndeterminate />
              </Grid>
            )}
            <Grid container direction={'column'} spacing={1}>
              <Grid item container justifyContent="space-between">
                <Grid item xs={5}>
                  {' '}
                  <TextField
                    style={{ marginTop: 5 }}
                    value={title}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    fullWidth
                    label="Task Title"
                    // placeholder="Task Title"
                    size="small"
                    inputProps={{
                      maxLength: 80,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Select
                    label="Priority"
                    placeholder="Priority"
                    fullWidth
                    size="small"
                    native
                    value={priority}
                    onChange={(e) => {
                      setPriority(e.target.value);
                    }}
                  >
                    {TASKS_PRIORITIES.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.type}
                      </option>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item xs={5}>
                  <Select
                    label="Task Group"
                    placeholder="Task Group"
                    fullWidth
                    size="small"
                    native
                    value={group}
                    onChange={(e) => {
                      setGroup(e.target.value);
                    }}
                  >
                    <option value="0">-Select Task Group-</option>
                    {TASKS_GROUPS.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.type}
                      </option>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid item container>
                <Grid item xs={5}>
                  <Select
                    label="Task Type"
                    placeholder="Task Type"
                    fullWidth
                    size="small"
                    native
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                    }}
                  >
                    <option value="0">-Select Task Type-</option>
                    {TASKS_TYPES.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.type}
                      </option>
                    ))}
                  </Select>
                </Grid>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item xs={4}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    // sx={{ width: 500 }}
                    open={openAdmin1}
                    onOpen={() => {
                      setOpenAdmin1(true);
                    }}
                    onClose={() => {
                      setOpenAdmin1(false);
                    }}
                    value={admin1AppUserDetailsId}
                    onChange={(_, newVal) => {
                      if (newVal) {
                        setAdmin1AppUserDetailsId(newVal);
                        setTask({
                          ...task,
                          admin1AppUserDetailsId: newVal.id,
                        });
                      } else {
                        setAdmin1AppUserDetailsId(null);
                        setTask({ ...task, admin1AppUserDetailsId: null });
                      }
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    options={cosmosAdminUsers}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Responsible Person"
                        placeholder="Responsible Person"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    // sx={{ width: 500 }}
                    open={openAdmin2}
                    onOpen={() => {
                      setOpenAdmin2(true);
                    }}
                    onClose={() => {
                      setOpenAdmin2(false);
                    }}
                    value={admin2AppUserDetailsId}
                    onChange={(_, newVal) => {
                      if (newVal) {
                        setAdmin2AppUserDetailsId(newVal);
                        setTask({
                          ...task,
                          admin2AppUserDetailsId: newVal.id,
                        });
                      } else {
                        setAdmin2AppUserDetailsId(null);
                        setTask({ ...task, admin2AppUserDetailsId: null });
                      }
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    options={cosmosAdminUsers}
                    loading={adminLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Assisting Person"
                        placeholder="Assisting Person"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {adminLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    // sx={{ width: 500 }}
                    open={openAdmin3}
                    onOpen={() => {
                      setOpenAdmin3(true);
                    }}
                    onClose={() => {
                      setOpenAdmin3(false);
                    }}
                    value={admin3AppUserDetailsId}
                    onChange={(_, newVal) => {
                      if (newVal) {
                        setAdmin3AppUserDetailsId(newVal);
                        setTask({
                          ...task,
                          admin3AppUserDetailsId: newVal.id,
                        });
                      } else {
                        setAdmin3AppUserDetailsId(null);
                        setTask({ ...task, admin3AppUserDetailsId: null });
                      }
                      // let admin1AppUserDetailsId = null;
                      // if (newVal) {
                      //   admin1AppUserDetailsId = parseInt(newVal.id);
                      // }
                    }}
                    getOptionLabel={(option) => `${option.name}`}
                    options={cosmosAdminUsers}
                    loading={adminLoading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tag Person"
                        placeholder="Tag Person"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {adminLoading ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  label="Task Description"
                  // placeholder="Task Description"
                  size="large"
                  inputProps={{
                    maxLength: 5000,
                  }}
                  multiline
                  rows={16}
                />
              </Grid>

              <Grid item container spacing={1}>
                <Grid item xs={3}>
                  <DatePicker
                    popperClassName={classes.reactDatepickerPopper}
                    autoComplete="off"
                    locale="enGB"
                    dateFormat="dd-MM-yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    error={true}
                    selected={(deadline && new Date(deadline)) || null}
                    onChange={(newVal) => {
                      if (newVal) {
                        newVal = addHours(startOfDay(newVal), 12);
                      }
                      setDeadline(newVal);
                    }}
                    customInput={
                      <TextField
                        label="Deadline"
                        fullWidth
                        // onChange={onChange}
                        value={deadline}
                        variant="outlined"
                        size="small"
                      />
                    }
                  />
                </Grid>
                {record.id > 0 && (
                  <Grid item xs={2}>
                    <Select
                      label="Status"
                      placeholder="Status"
                      fullWidth
                      size="small"
                      native
                      value={status}
                      onChange={(e) => {
                        setStatus(e.target.value);
                      }}
                    >
                      {TASKS_STATUSES.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.type}
                        </option>
                      ))}
                    </Select>
                  </Grid>
                )}

                {file && (
                  <Grid item xs={8} container>
                    <Grid item>
                      <MyTooltip title="Download file">
                        <IconButton
                          style={{ paddingTop: 0 }}
                          onClick={() => {
                            Agent.CloudFiles.download(
                              file.fileNameLocal,
                              file.fileName,
                              'general'
                            ).then((response) => {
                              var link = document.createElement('a');
                              link.href = window.URL.createObjectURL(
                                new Blob([response.data], {
                                  type: 'application/octet-stream',
                                })
                              );
                              link.download = file.fileNameLocal;
                              document.body.appendChild(link);
                              link.click();
                              setTimeout(function () {
                                window.URL.revokeObjectURL(link);
                              }, 200);
                            });
                          }}
                        >
                          <DownloadIcon fontSize="large" color="primary" />
                        </IconButton>
                      </MyTooltip>
                    </Grid>
                    <Grid item>
                      <Typography
                        textAlign="left"
                        as={fileActionType == 0 ? Button : null}
                        style={{
                          padding: 0,
                          textDecoration: 'underline',
                          textTransform: 'none',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(file.fileUrl, '_blank');
                        }}
                      >
                        {file.name}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <MyTooltip title="Delete file">
                        <IconButton
                          style={{ paddingTop: 0 }}
                          disabled={
                            id != record.createdBy && record.createdBy > 0
                          }
                          onClick={() => {
                            setFile(null);
                            setUploadMode(true);
                            setFileActionType(2);
                          }}
                        >
                          <CancelIcon fontSize="large" color="secondary" />
                        </IconButton>
                      </MyTooltip>
                    </Grid>
                    {/* <MyTooltip title="Download file">
                      <IconButton
                        onClick={() => {
                          Agent.Requirements.download(
                            task.downloadCloudFileId,
                            1
                          ).then((response) => {
                            var link = document.createElement('a');
                            link.href = window.URL.createObjectURL(
                              new Blob([response.data], {
                                type: 'application/octet-stream',
                              })
                            );
                            link.download = response.headers.filename;
                            document.body.appendChild(link);
                            link.click();
                            setTimeout(function () {
                              window.URL.revokeObjectURL(link);
                            }, 200);
                          });
                        }}
                      >
                        <DownloadIcon fontSize="large" />
                      </IconButton>
                    </MyTooltip> */}
                  </Grid>
                )}
                {uploadMode && (
                  <Grid item xs={3}>
                    <input
                      type="file"
                      style={{ display: 'none' }}
                      onChange={(e) => {
                        setFile(e.target.files[0]);
                        setFileActionType(1);
                        setUploadMode(false);
                      }}
                      ref={fileInput}
                    />
                    {(id == record.createdBy || !record.createdBy) && (
                      <MyTooltip title="Upload file">
                        <IconButton
                          onClick={initialFileUpload}
                          style={{ paddingTop: 0 }}
                        >
                          <CloudUploadIcon fontSize="large" />
                        </IconButton>
                      </MyTooltip>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container justifyContent="space-between">
              {errors && (
                <Grid item>
                  <Typography color={'error'}>{errors}</Typography>
                </Grid>
              )}
              <Grid item>
                <Button
                  disabled={id != record.createdBy}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  className="bg-red-800"
                  onClick={() => setOpenConfirm(true)}
                  loading={updating}
                >
                  Delete Task
                </Button>
              </Grid>
              <Grid item>
                <LoadingButton
                  disabled={
                    !readyToSave ||
                    (record.createdBy > 0 &&
                      id != record.createdBy &&
                      id != record.admin1AppUserDetailsId)
                  }
                  type="submit"
                  color="primary"
                  onClick={save}
                  loading={updating}
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      </Dialog>

      <MsgAlertModal
        open={successModalOpen}
        onClose={() => setSuccessModalOpen(false)}
        message="Task Deleted Successfully!"
      />

      <ConfirmationModal
        open={openConfirm}
        cancel={setOpenConfirm}
        confirm={deleteTask}
        confirmText="Confirm Action"
        content="Are you sure that you would like to delete this user tasks?"
      />
    </Root>
  );
};

Task.propTypes = {
  record: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  return {
    id: state.auth.id,
    role: state.auth.role,
    agentId: ownProps && ownProps.agentId ? ownProps.agentId : 0,
    isInternal: state.auth.isInternal,
    record: ownProps.record,
    setRecordParent: ownProps.setRecordParent,
    setAddMode: ownProps.setAddMode,
    cosmosAdminUsers: ownProps.cosmosAdminUsers,
    updateParent: ownProps.updateParent,
  };
};

//record, setRecordParent, setAddMode
export default connect(mapStateToProps, null)(Task);
