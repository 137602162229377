import {
  Campaign,
  CloudUpload,
  Description,
  ForwardToInbox,
  People,
  UnsubscribeRounded,
} from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const AdminPanelMenu = () => {
  const navigate = useNavigate();

  const handleBulkMenu = () => {
    navigate('/bulkuploadmenu');
  };

  const handleTemplatesMenu = () => {
    navigate('/templatsmenu');
  };

  const handleEmailListMenu = () => {
    navigate('/managefromemail');
  };
  const handleUserListMenu = () => {
    navigate('/users');
  };

  const handleUnSubscribedMenu = () => {
    navigate('/unsubscribedmenu');
  };
  const handleAnnouncementMenu = () => {
    navigate('/announcementmenu');
  };

  return (
    <>
      <div className="bg-gradient-to-b from-sky-200 to-sky-300 min-h-screen">
        <div className="py-16 px-2 sm:px-6 lg:px-auto">
          <div className="max-w-5xl mx-auto">
            {' '}
            <h1 className="text-2xl font-bold text-center mb-8 text-gray-800">
              Welcome, Super Admin!
            </h1>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
              <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between items-center">
                <People className="text-5xl text-sky-500 mb-4" />
                <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">
                  Manage Users
                </h2>
                <p className="text-base text-gray-700 text-center mb-4">
                  Manage users and access control.
                </p>
                <Button
                  onClick={handleUserListMenu}
                  variant="contained"
                  color="primary"
                  className="w-full bg-sky-500 hover:bg-sky-600"
                >
                  Go to User List
                </Button>
              </div>

              <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between items-center">
                <CloudUpload className="text-5xl text-blue-500 mb-4" />
                <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">
                  Bulk Uploads
                </h2>
                <p className="text-base text-gray-700 text-center mb-4">
                  Upload and manage bulk data for all sections.
                </p>
                <Button
                  onClick={handleBulkMenu}
                  variant="contained"
                  color="primary"
                  className="w-full bg-blue-500 hover:bg-blue-600"
                >
                  Go to Bulk Uploads
                </Button>
              </div>

              <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between items-center">
                <Description className="text-5xl text-green-500 mb-4" />
                <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">
                  Manage Templates
                </h2>
                <p className="text-base text-gray-700 text-center mb-4">
                  Manage and customize templates for email and WhatsApp
                  communication.
                </p>
                <Button
                  onClick={handleTemplatesMenu}
                  variant="contained"
                  color="primary"
                  className="w-full bg-green-500 hover:bg-green-600"
                >
                  Go to Templates
                </Button>
              </div>

              <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between items-center">
                <ForwardToInbox className="text-5xl text-blue-500 mb-4" />
                <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">
                  Manage From Email
                </h2>
                <p className="text-base text-gray-700 text-center mb-4">
                  Manage and customize the From Email List for mail
                  communication.
                </p>
                <Button
                  onClick={handleEmailListMenu}
                  variant="contained"
                  color="primary"
                  className="w-full bg-blue-500 hover:bg-blue-600"
                >
                  Go to Email List
                </Button>
              </div>

              <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between items-center">
                <UnsubscribeRounded className="text-5xl text-orange-500 mb-4" />
                <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">
                  Unsubscribed Email
                </h2>
                <p className="text-base text-gray-700 text-center mb-4">
                  Manage unsubscribed email
                </p>
                <Button
                  onClick={handleUnSubscribedMenu}
                  variant="contained"
                  color="primary"
                  className="w-full bg-orange-500 hover:bg-orange-600"
                >
                  Unsubscribed email list
                </Button>
              </div>

              <div className="bg-white rounded-lg shadow-md p-6 flex flex-col justify-between items-center">
                <Campaign className="text-5xl text-sky-300 mb-4" />
                <h2 className="text-lg font-semibold text-gray-800 mb-4 text-center">
                  Announcement
                </h2>
                <p className="text-base text-gray-700 text-center mb-4">
                   Post & Update  Announcement
                </p>
                <Button
                  onClick={handleAnnouncementMenu}
                  variant="contained"
                  color="primary"
                  className="w-full bg-sky-500 hover:bg-sky-600"
                >
                  Manage Announcement
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminPanelMenu;
