import {
  EditorState,
  ContentState,
  convertToRaw,
  convertFromHTML,
} from 'draft-js'
import {styled} from '@mui/material/styles'
import {Editor} from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import React, {useState} from 'react'

const PREFIX = 'EditorDraftJs'

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  editor: `${PREFIX}-editor`,
}

const StyledEditor = styled(Editor)({
  [`& .${classes.wrapper}`]: {
    border: '1px lightgray solid',
    paddingLeft: 5,
    paddingRight: 5,
  },
  [`& .${classes.editor}`]: {
    minHeight: (height) => height,
  },
})

const EditorDraftJs = ({
  editorOnChange,
  height = 200,
  placeHolder = 'Write something here',
  html,
}) => {
  const [editorState, setEditorState] = useState(() =>
    html
      ? EditorState.createWithContent(
          ContentState.createFromBlockArray(convertFromHTML(html))
        )
      : EditorState.createEmpty()
  )
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState)
    const rawHtml = draftToHtml(convertToRaw(editorState.getCurrentContent()))
    editorOnChange(rawHtml, editorState.getCurrentContent().hasText())
  }
  return (
    <StyledEditor
      wrapperStyle={{
        border: '1px lightgray solid',
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        background: "white" ,
        borderRadius: '3px',
      }}
      spellCheck
      // wrapperClassName={classes.wrapper}
      editorStyle={{minHeight: height}}
      //  editorClassName={classes.editor}
      toolbarClassName='toolbar-class'
      toolbar={{
        options: [
          'inline',
          'blockType',
          'fontSize',
          'list',
          'textAlign',
          'colorPicker',
          'link',
          'emoji',
        ],
      }}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      placeholder={`${placeHolder}`}
    />
  )
}

export default EditorDraftJs
