import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Agent from '../../api/agent';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Grid,
  Snackbar,
  Typography,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import MuiAlert from '@mui/material/Alert';

const PREFIX = 'VerifyEmail';

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  paper: `${PREFIX}-paper`,
  cardRoot: `${PREFIX}-cardRoot`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
    padding: 10,
  },

  [`& .${classes.content}`]: {
    flexGrow: 1,
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },

  [`& .${classes.cardRoot}`]: {
    width: 500,
    padding: 10,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const VerifyEmail = () => {
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openFailure, setOpenFailure] = React.useState(false);
  const handleSuccessClick = () => {
    setOpenSuccess(true);
  };
  const handleFailureClick = () => {
    setOpenSuccess(true);
  };
  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };
  const handleCloseFailure = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenFailure(false);
  };

  const navigate = useNavigate();
  // get the modal here to display this
  const Status = {
    Verifying: 'Verifying',
    Failed: 'Failed',
    Success: 'Success',
  };

  const [status, setStatus] = useState(Status.Verifying);

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const email = searchParams.get('email');

  console.log(token);
  console.log(email);
  useEffect(() => {
    Agent.Account.verifyEmail(token, email)
      .then(() => {
        setStatus(Status.Success);
      })
      .catch(() => {
        setStatus(Status.Failed);
      });
  }, [Status.Failed, Status.Success, token, email]);

  const handleLogIn = () => {
    navigate('/');
  };

  const handleConfirmEmailResend = () => {
    Agent.Account.resendVerificationEmail(email)
      .then(() => {
        handleSuccessClick(true);
      })
      .catch((error) => {
        handleFailureClick(true);
        console.log(error);
      });
  };

  const getBody = () => {
    return (
      <Root>
        <Grid item container justifyContent="center" alignItems="center">
          <Card className={classes.cardRoot}>
            <CardActionArea>
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  style={{ lineHeight: 2 }}
                >
                  {status == Status.Failed
                    ? 'Verification Failed'
                    : status == Status.Verifying
                    ? 'Verifying...'
                    : 'Email Successfully Verified'}
                </Typography>
                <hr />
                <Typography variant="body1" color="textPrimary" component="p">
                  {status == Status.Failed
                    ? 'It has failed to verify your account. You can try re-sending the verification email.'
                    : status == Status.Verifying
                    ? 'System is in the middle of verifying your email. Please bear with us!'
                    : 'Email has been verified - you can now login'}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              {status == Status.Failed ? (
                <Button
                  onClick={handleConfirmEmailResend}
                  to={{ pathname: '/register', role: 'Agent' }}
                  size="large"
                  color="primary"
                  variant="outlined"
                >
                  Resend Email
                  <SendIcon color="primary" style={{ marginLeft: 10 }} />
                </Button>
              ) : null}
              {status == Status.Success ? (
                <Button
                  onClick={handleLogIn}
                  size="large"
                  color="primary"
                  variant="outlined"
                >
                  Login
                </Button>
              ) : null}
            </CardActions>
          </Card>
        </Grid>
        <Snackbar
          open={openFailure}
          autoHideDuration={6000}
          onClose={handleCloseFailure}
        >
          <Alert onClose={handleCloseFailure} severity="error">
            Failed to send email. Please try again!
          </Alert>
        </Snackbar>
        <Snackbar
          open={openSuccess}
          autoHideDuration={6000}
          onClose={handleCloseSuccess}
        >
          <Alert onClose={handleCloseSuccess} severity="success">
            Email has been successfully sent to {email}
          </Alert>
        </Snackbar>
      </Root>
    );
  };

  return getBody();
};

export default VerifyEmail;
